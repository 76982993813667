import { getAxios, baseURL } from "./Api";

export async function getCities(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/cidades`;
  return api.get(url, { params: filters });
}

export async function getCitiesForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `cidades/para_menus`;
  return api.get(url, { params: filters });
}

export async function getCitiesByStateId(state_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`estados/${state_id}/cidades`);
}

export async function getCityById(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`cidades/${id}`);
}

export async function createCity(city) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/cidades", city);
}

export async function updateCity(id, city) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`cidades/${id}`, city);
}

export async function destroyCity(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`cidades/${id}`);
}
