<template>
  <BaseCard :class="liClass">
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4 flex">
        <div class="place-self-center">
          <div :class="iconClass">
            <img src="../assets/images/profile-vj.png" alt="Foto de Perfil" />
          </div>
        </div>
        <div class="justify-self-start place-self-center">
          <BaseText
            :text="user.name"
            mode="normal-case"
            color="quantum"
            typeText="custom"
            weight="bold"
            size="base"
          />
          <AppMaterialIcon
            v-tooltip="{ content: citiesTooltipContent, classes: 'custom-tooltip' }"
            v-if="true"
            iconName="location_city"
            class="icon-input text-quantum cursor-pointer inline-flex relative"
          />
          <AppMaterialIcon
            v-tooltip="{ content: areasTooltipContent, classes: 'custom-tooltip' }"
            v-if="true"
            iconName="map"
            class="icon-input text-quantum cursor-pointer inline-flex relative"
          />
        </div>
      </div>
      <div class="mt-4">
        <BaseText
          :text="user.email"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="user.cpf"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="user.ability_profile.name ? user.ability_profile.name : 'Não informado'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="user.active ? 'Ativo' : 'Desativado'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="permitUp"
          iconName="edit"
          :payload="user"
          dispatch="User/openEditModal"
          title="Atualizar Informações do Usuário(a)"
        />
        <AppButtonIcon
          v-if="permitResetPassword"
          iconName="vpn_key"
          :payload="user"
          dispatch="User/resetPassword"
          title="Resetar para Senha Padrão"
        />
        <AppButtonIcon
          v-if="permitUp"
          :iconName="chooseIcon()"
          :payload="user"
          dispatch="User/openModalActivation"
          title="Ativar/Desativar Usuário(a)"
        />
        <AppButtonIcon
          v-if="permitDes"
          iconName="delete"
          :payload="user"
          dispatch="User/openCloseDeleteUserConfirmation"
          title="Excluir Usuário(a)"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import AppMaterialIcon from "@/components/AppMaterialIcon.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    AppMaterialIcon,
    BaseText,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
  },

  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitResetPassword: false,
      permit: false,
    };
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    userAcces() {
      this.canAcces("user_index").then((resp) => {
        this.permit = resp;
      });
    },
    userDelete() {
      this.canAcces("user_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    userChange() {
      this.canAcces("user_update").then((resp) => {
        this.permitUp = resp;
      });
    },
    resetUserPassword() {
      this.canAcces("user_reset_password").then((resp) => {
        this.permitResetPassword = resp;
      });
    },
    chooseIcon() {
      if (this.user.active) {
        return "toggle_on";
      } else {
        return "toggle_off";
      }
    },
  },

  created() {
    this.userAcces();
    this.userChange();
    this.userDelete();
    this.resetUserPassword();
  },

  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        "p-6": true,
        "cursor-pointer": true,
      };
    },
    iconClass() {
      let css = [];
      css.push("rounded-full");
      css.push("rounded-lg");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");
      css.push("mr-3");
      css.push("items-center");
      css.push("justify-center");
      css.push("min-w-1");

      return css;
    },
    areasTooltipContent() {
      if (this.user.areas?.length > 0) {
        return (
          this.user.areas
            .map(
              (area) => `
      <div>|<span class="text-burnedYellow uppercase font-medium text-xs"> ${area?.name}</span></div>
    `
            )
            .join("") + "<div></div>"
        );
      } else {
        return `
      <div>|<span class="text-burnedYellow uppercase font-medium text-xs"> Nenhuma área vinculada</span></div>
      <div></div>
    `;
      }
    },
    citiesTooltipContent() {
      if (this.user.cities_attributes?.length > 0) {
        return (
          this.user.cities_attributes
            .map(
              (city) => `
      <div>|<span class="text-herbal-classic uppercase font-medium text-xs"> ${city?.name}</span></div>
    `
            )
            .join("") + "<div></div>"
        );
      } else {
        return `
      <div>|<span class="text-herbal-classic uppercase font-medium text-xs"> Nenhum município vinculado</span></div>
      <div></div>
    `;
      }
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 23% 21% 14% 15% 19% 6%;
}
.min-w-1 {
  min-width: 3rem;
}
.custom-tooltip {
  background-color: #333 !important;
}
</style>
