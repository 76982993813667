<template>
  <div class="m-5">
    <div id="person-data" class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-5">
        <BaseText text="Dados da Inserção" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Nome do Participante"
          :text="inscription.name"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Área"
          :text="inscription.neighborhood.area.name"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Data da Inserção"
          :text="formattedDate(spontaneous_productive_insertion.insertion_date)"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Tipo da Inserção"
          :text="insertion_type ? insertion_type.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="Observações"
          :text="spontaneous_productive_insertion.observations"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-5" v-if="insertionTypeIsJob()">
        <BaseText text="Dados - Tipo Emprego" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2" v-if="insertionTypeIsJob()">
        <AppTextField
          show-label
          label-text="Empresa"
          :text="company ? company.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="insertionTypeIsJob()">
        <AppTextField
          show-label
          label-text="Tipo da Contratação"
          :text="contracting_type ? contracting_type.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5" v-if="insertionTypeIsEntrepreneurship()">
        <BaseText text="Dados - Tipo Empreendedorismo" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2" v-if="insertionTypeIsEntrepreneurship()">
        <AppTextField
          show-label
          label-text="Segmento"
          :text="segment ? segment.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="insertionTypeIsEntrepreneurship()">
        <AppTextField
          show-label
          label-text="Ocupação"
          :text="occupation ? occupation.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="insertionTypeIsEntrepreneurship()">
        <AppTextField
          show-label
          label-text="Teve acesso à financiamento?"
          :text="spontaneous_productive_insertion.had_access_credit_for_financing === true ? 'Sim' : 'Não'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="insertionTypeIsEntrepreneurship()">
        <AppTextField
          show-label
          label-text="Tem estabelecimento?"
          :text="spontaneous_productive_insertion.has_establishment === true ? 'Sim' : 'Não'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5" v-if="insertionTypeIsEntrepreneurship() && hasEstablishment()">
        <BaseText text="Dados - Estabelecimento" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-5" v-if="insertionTypeIsEntrepreneurship() && hasEstablishment()">
        <AppTextField
          show-label
          label-text="Nome"
          :text="spontaneous_productive_insertion.establishment_name"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5" v-if="insertionTypeIsEntrepreneurship() && hasEstablishment()">
        <AppTextField
          show-label
          label-text="Endereço"
          :text="spontaneous_productive_insertion.establishment_address"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="insertionTypeIsEntrepreneurship() && hasEstablishment()">
        <AppTextField
          show-label
          label-text="Tem CNPJ?"
          :text="spontaneous_productive_insertion.has_cnpj === true ? 'Sim' : 'Não'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="insertionTypeIsEntrepreneurship() && hasEstablishment()">
        <AppTextField
          show-label
          label-text="Possui relação com o segmento do curso?"
          :text="spontaneous_productive_insertion.is_related_to_the_course_segment === true ? 'Sim' : 'Não'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5" v-if="insertionTypeIsEntrepreneurship() && hasEstablishment()">
        <AppTextField
          show-label
          label-text="Possui relação com o segmento do curso?"
          :text="spontaneous_productive_insertion.is_related_to_the_course_segment === true ? 'Sim' : 'Não'"
          type-text="topic-details"
        />
      </div>
      <div
        class="col-span-5"
        v-if="
          insertionTypeIsEntrepreneurship() && hasEstablishment() && photos_urls && photos_urls.length > 0
        "
      >
        <AppTextField show-label label-text="Fotos do Estabelecimento" type-text="topic-details" />
        <div class="photos-container">
          <a v-for="(photoUrl, index) in photos_urls" :key="index" :href="photoUrl.url" download>
            <img :src="photoUrl.url" alt="foto_estabelecimento" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppTextField from "../../components/AppTextField.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppTextField,
  },
  computed: {
    ...mapState({
      spontaneous_productive_insertion: (state) =>
        state.SpontaneousProductiveInsertions.spontaneous_productive_insertion,
      inscription: (state) => state.SpontaneousProductiveInsertions.inscription,
      season: (state) => state.SpontaneousProductiveInsertions.season,
      company: (state) => state.SpontaneousProductiveInsertions.company,
      segment: (state) => state.SpontaneousProductiveInsertions.segment,
      occupation: (state) => state.SpontaneousProductiveInsertions.occupation,
      contracting_type: (state) => state.SpontaneousProductiveInsertions.contracting_type,
      insertion_type: (state) => state.SpontaneousProductiveInsertions.insertion_type,
      photos_urls: (state) => state.SpontaneousProductiveInsertions.photos_urls,
    }),
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    insertionTypeIsJob() {
      return this.insertion_type && this.insertion_type.id === 0;
    },
    insertionTypeIsEntrepreneurship() {
      return this.insertion_type && this.insertion_type.id === 1;
    },
    hasEstablishment() {
      if (this.spontaneous_productive_insertion.has_establishment) {
        return this.spontaneous_productive_insertion.has_establishment;
      }
      return false;
    },
  },
};
</script>
<style>
.active_new {
  background-color: #fff7ed;
  color: #fff;
  font-size: 1.1em;
}
.photos-container {
  display: flex;
  flex-wrap: wrap;
}
.photos-container img {
  max-width: 300px;
  max-height: 300px;
  margin: 5px;
}
</style>
