<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-4">
        <div class="justify-self-start col-span-3">
          <Label
            text="Editar Data para/de Anexo de Comprovante de Matrícula"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon
            dispatch="SchoolReinsertions/closeModalSchoolReinsertionDeadlineAttachProofOfEnrollment"
            forClose
          />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações de Comprovante de Matrícula e Data"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <AppButtonUploadFile2
            id="file-declaration"
            :id_file="`${idFileStudentDeclaracao}`"
            name="declaração"
            text="+  Comprovante de Matrícula"
            dispatch="ModalInscription/setDocumentInscriptionDECLARACAO"
            type-document="declaration"
            label-text="Anexar Documento"
            show-label
            color="ashes-dark"
          />
          <div class="-mt-8">
            <AppButtonIcon
              iconName="delete"
              :payload="inscription.student"
              dispatch="ModalInscription/deleteStudentDocumentDECLARACAO"
            />
            <AppButtonIcon
              iconName="download"
              :payload="inscription.student"
              dispatch="ModalInscription/showStudentDocumentDECLARACAO"
            />
            <AppButtonIcon
              iconName="event"
              dispatch="SchoolReinsertions/toggleSchoolReinsertionDeadlineAttachProofOfEnrollment"
              title="Editar data para/de anexo de comprovante de matrícula"
            />
          </div>
        </div>
        <div class="col-span-2" v-if="toggleSchoolReinsertionDeadlineAttachProofOfEnrollment">
          <BaseInput
            active
            input-name="deadline_attach_proof_of_enrollment"
            input-type="date"
            :value="school_reinsertion.deadline_attach_proof_of_enrollment"
            show-label
            label-text="Data Para/De Anexo de Comprovante de Matrícula"
            dispatch="SchoolReinsertions/setDeadlineAttachProofOfEnrollment"
            required
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="SchoolReinsertions/closeModalSchoolReinsertionDeadlineAttachProofOfEnrollment"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty text="Salvar" typeButton="success" dispatch="SchoolReinsertions/update" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseModal from "../../components/BaseModal.vue";
import Label from "../../components/Label.vue";
import { mapState } from "vuex";
import AppButtonUploadFile2 from "../../components/AppButtonUploadFile2.vue";

export default {
  components: {
    AppButtonUploadFile2,
    BaseModal,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      school_reinsertion: (state) => state.SchoolReinsertions.school_reinsertion,
      inscription: (state) => state.SchoolReinsertions.inscription,
      idFileStudentDeclaracao: (state) => state.ModalInscription.idFileStudentDeclaracao,
      toggleSchoolReinsertionDeadlineAttachProofOfEnrollment: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionDeadlineAttachProofOfEnrollment,
    }),
  },
};
</script>
