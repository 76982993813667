<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="text-ashes-hover font-medium text-3xl w-max mb-7">
          {{ city.name }} | Remover Aluno(a) de Turma
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Season/closeModalRemoveStudentFromTeamOpen" forClose />
        </div>
      </div>
      <div class="w-auto mr-4">
        <div class="grid grid-cols-2">
          <Multiselect
            id="select-phase"
            show-label
            close-on-select
            label-text="Selecione a fase para que as turmas sejam carregadas"
            required
            select-placeholder="Selecione as opções"
            :clear-on-select="false"
            :multiple-option="false"
            :value="phase"
            :options="phases"
            :dispatch="setPhase"
          />
        </div>
      </div>
      <br />
      <br />
    </template>
    <template v-slot:body>
      <div v-if="phase?.id">
        <div class="justify-self-start col-span-2 mb-5" v-if="isLoadedTeam">
          <Label
            text="Selecione a Turma:"
            :icon="null"
            :icon-type="null"
            type="text-lg"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="grid grid-flow-row auto-rows-max" v-if="isLoadedTeam">
          <div class="grid grid-flow-row auto-rows-max">
            <RemoveStudentFromTeamCards :data="inscriptionStudentTeamsAttributes" />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="Season/closeModalRemoveStudentFromTeamOpen"
          />
        </div>
        <div class="justify-self-end">
          <a href="javascript:void(0)" @click="removeStudent()">
            <AppButtonEmpty text="Salvar Alterações" typeButton="success" />
          </a>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import RemoveStudentFromTeamCards from "./list/RemoveStudentFromTeamCards.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import { mapActions, mapState } from "vuex";
import Vue from "vue";

export default {
  components: {
    RemoveStudentFromTeamCards,
    BaseModal,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
    Multiselect,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    json_meta: [[{ key: "charset", value: "utf-8" }]],
    textTeamModal: "Turmas sendo carregadas, aguarde...",
  }),
  beforeUpdate: function () {
    this.$store.dispatch("Allocation/cleanStudentsAttributesMutation");
  },
  computed: {
    ...mapState({
      equipment: (state) => state.EquipmentAll.equipmentAll,
      teams: (state) => state.Teams.all,
      inscription: (state) => state.Inscription,
      inscriptions: (state) => state.Season.inscriptions,
      isLoadedTeam: (state) => state.Season.isLoadedTeam,
      inscriptionStudentTeamsAttributes: (state) => state.Season.inscription_student_teams_attributes,
      season: (state) => state.Season.season,
      phases: (state) => state.Season.phases,
      phase: (state) => state.Season.phase,
      city: (state) => state.City.city,
      team: (state) => state.Teams,
      teamToRemoveFiltered: (state) => state.Season.teamToRemoveFiltered,
      hasRemoveTeams: (state) => state.Season.hasRemoveTeams,
    }),
  },
  methods: {
    ...mapActions({
      setPhase: "Season/setPhase",
    }),
    removeStudent() {
      if (this.teamToRemoveFiltered) {
        this.$store.dispatch("Season/toggleModalRemoveStudent");
      } else {
        Vue.$toast.error("É necessário selecionar a turma!");
      }
    },
  },
  watch: {
    isLoadedTeam: function () {
      this.textTeamModal = "Transferir Aluno(a) - Selecione a Turma:";
    },
  },
};
</script>
