import {
  saveSeason as saveSeasonApi,
  updateSeason as updateSeasonApi,
  getSeasons,
} from "../../service/SeasonApi";
import Vue from "vue";
import { getCitiesForMenus } from "@/service/CityApi";
import { getStates } from "@/service/StateApi";

const state = {
  modalSeasonOpen: false,
  errorMessages: [],
  season: {
    id: null,
    name: null,
    date_inscription_start: null,
    date_inscription_end: null,
    date_execution_start: null,
    date_execution_end: null,
    // areas_attributes: [],
    cities_attributes: [],
    // areas: [],
    cities: [],
  },
  state: null,
  city: null,

  seasonsTotal: 0,
  seasonsTotalPerPage: 10,
  seasonsOffset: 0,

  emptySeason: {
    id: null,
    name: null,
    date_inscription_start: null,
    date_inscription_end: null,
    date_execution_start: null,
    date_execution_end: null,
    // areas_attributes: [],
    cities_attributes: [],
    // areas: [],
    cities: [],
  },
  params: {
    filter: {
      name: null,
      city: null,
    },
    cities: {
      state: null,
    },
  },
  seasons: [],
  cities: [],
  states: [],
  filteredSeasons: [],
};

const mutations = {
  loadStates: (state, states) => {
    state.states = states;
  },
  loadCities: (state, cities) => {
    state.cities = cities;
  },
  toggleModalSeason: (state) => {
    state.modalSeasonOpen = !state.modalSeasonOpen;
  },
  setSeasonName: (state, name) => {
    state.season.name = name.value;
  },
  setSeasons: (state, seasons) => {
    state.seasons = seasons;
  },
  setSeasonAreas: (state, areas) => {
    state.season.areas_attributes = areas;
  },
  setSeasonCities: (state, cities) => {
    state.season.cities_attributes = cities;
  },
  setDateInscriptionStart: (state, dateInscriptionStart) => {
    state.season.date_inscription_start = dateInscriptionStart.value;
  },
  setDateInscriptionEnd: (state, dateInscriptionEnd) => {
    state.season.date_inscription_end = dateInscriptionEnd.value;
  },
  setDateExecutionStart: (state, dateExecutionStart) => {
    state.season.date_execution_start = dateExecutionStart.value;
  },
  setDateExecutionEnd: (state, dateExecutionEnd) => {
    state.season.date_execution_end = dateExecutionEnd.value;
  },
  setState: (state, value) => {
    state.state = value;
  },
  setSeason: (state, season) => {
    state.season = Object.assign(state.season, season);
  },
  clearSeasonMutation: (state) => {
    state.season = Object.assign(state.season, state.emptySeason);
  },
  setErrorMessages: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
  // PARAMS - FILTER
  setFilterNameMutation: (state, name) => {
    state.params.filter.name = name;
  },
  setFilterCityMutation: (state, city) => {
    state.params.filter.city = city;
  },
  setFilterStateCitiesMutation: (state, value) => {
    state.params.cities.state = value;
  },
  // --> MUTATIONS - PAGINATION FIELDS <--
  setSeasonsTotalPerPage: (state, seasonsTotalPerPage) => {
    state.seasonsTotalPerPage = seasonsTotalPerPage;
  },
  setSeasonsOffset: (state, seasonsOffset) => {
    state.seasonsOffset = seasonsOffset;
  },
  setSeasonsTotal: (state, seasonsTotal) => {
    state.seasonsTotal = seasonsTotal;
  },
  // --> MUTATIONS - CLEARS <--
  clearQuery: (state) => {
    state.params.filter.name = null;
    state.params.filter.city = null;
    state.params.cities.state = null;
    state.state = null;
  },
};

const actions = {
  closeModal: ({ commit }) => {
    commit("setErrorMessages", []);
    commit("clearSeasonMutation");
    commit("toggleModalSeason");
    commit("clearQuery");
  },
  openModal: ({ commit }) => {
    commit("clearQuery");
    commit("toggleModalSeason");
  },
  openEditModal: ({ commit }, payload) => {
    commit("toggleModalSeason");
    commit("setSeason", payload);
  },
  setSeasonName: ({ commit }, payload) => {
    commit("setSeasonName", payload);
  },
  setSeasonAreas: ({ commit }, payload) => {
    commit("setSeasonAreas", payload);
  },
  setState: ({ commit }, payload) => {
    commit("setState", payload);
    commit("setFilterCityMutation", null);
  },
  setSeasonCities: ({ commit }, payload) => {
    commit("setSeasonCities", payload);
  },
  setDateInscriptionStart: ({ commit }, payload) => {
    commit("setDateInscriptionStart", payload);
  },
  setDateInscriptionEnd: ({ commit }, payload) => {
    commit("setDateInscriptionEnd", payload);
  },
  setDateExecutionStart: ({ commit }, payload) => {
    commit("setDateExecutionStart", payload);
  },
  setDateExecutionEnd: ({ commit }, payload) => {
    commit("setDateExecutionEnd", payload);
  },
  setSeasons: ({ commit }, payload) => {
    commit("setSeasons", payload);
  },
  // PARAMS - FILTER
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  setFilterCity: ({ commit }, payload) => {
    commit("setFilterCityMutation", payload);
  },
  setFilterStateCities: ({ commit }, payload) => {
    commit("setFilterStateCitiesMutation", payload);
  },
  // --> ACTIONS - PAGINATIONS <--
  setSeasonsTotalPerPage: ({ commit }, payload) => {
    commit("setSeasonsTotalPerPage", payload.totalPerPage);
  },
  setSeasonsOffset: ({ commit }, payload) => {
    commit("setSeasonsOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  loadSeasons: ({ commit, rootGetters }, payload) => {
    const params = {
      name: state.params.filter.name,
      city_id:
        state.params.filter.city && state.params.filter.city.id
          ? state.params.filter.city.id
          : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
          ? rootGetters["UtilFunctions/cityIds"]
          : null,
      type: payload,
      per_page: state.seasonsTotalPerPage,
      offset: state.seasonsOffset,
    };
    getSeasons(params)
      .then((response) => {
        commit("setSeasons", response.data.seasons);
        commit("setSeasonsTotal", response.data.total);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadStates: ({ commit }) => {
    getStates()
      .then((response) => {
        commit("loadStates", response.data.states);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadCities: ({ commit }) => {
    const params = {
      state_id: 1,
    };
    getCitiesForMenus(params)
      .then((response) => {
        console.log("response cities season", response);
        commit("loadCities", response.data.cities);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  updateSeason: ({ commit, state, dispatch }) => {
    updateSeasonApi(state.season.id, state.season)
      .then(() => {
        dispatch("closeModal");
        Vue.$toast.success("Edição Atualizada com sucesso!");
        dispatch("loadSeasons");
        dispatch("Season/loadDataSeason", null, { root: true });
        dispatch("loadSeasons");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.errors[0].message);
        commit("setErrorMessages", e.response.data.errors);
        console.error("errors", e.response.data.errors);
      });
  },
  saveSeason: ({ commit, state, dispatch }) => {
    saveSeasonApi(state.season)
      .then((response) => {
        console.log("response", response);
        Vue.$toast.success("Edição cadastrada com sucesso!");
        dispatch("closeModal");
        dispatch("loadSeasons");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.message);
        commit("setErrorMessages", e.response.data.errors);
        console.error(e);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key, field) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      switch (error.type) {
        case "blank":
          if (field === "" || field === " " || field === undefined || field === null || field.length === 0) {
            return error.message;
          } else {
            return null;
          }
        case "invalid":
          return error.message;

        default:
          return null;
      }
    } else {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
