<template>
  <div class="m-5">
    <div id="person-data" class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-5">
        <BaseText text="Dados do Planejamento" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Área"
          :text="area ? area.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-3">
        <AppTextField
          show-label
          label-text="Equipamento"
          :text="equipment ? equipment.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Articulador(a) Responsável"
          :text="
            planned_awareness_activity.articulator ? planned_awareness_activity.articulator : 'Não informado'
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Data da Atividade"
          :text="
            planned_awareness_activity.activity_date
              ? formattedDate(planned_awareness_activity.activity_date)
              : 'Não informada'
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="Objetivo"
          :text="planned_awareness_activity.purpose ? planned_awareness_activity.purpose : 'Não informado'"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="Metodologia"
          :text="
            planned_awareness_activity.methodology ? planned_awareness_activity.methodology : 'Não informada'
          "
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="Recursos Utilizados"
          :text="
            planned_awareness_activity.used_resources
              ? planned_awareness_activity.used_resources
              : 'Não informada'
          "
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="Avaliação da Atividade"
          :text="
            planned_awareness_activity.activity_evaluation
              ? planned_awareness_activity.activity_evaluation
              : 'Não informada'
          "
          type-text="topic-details"
          classe="break-words"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppTextField from "../../components/AppTextField.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppTextField,
  },
  computed: {
    ...mapState({
      planned_awareness_activity: (state) => state.PlannedAwarenessActivities.planned_awareness_activity,
      area: (state) => state.PlannedAwarenessActivities.area,
      equipment: (state) => state.PlannedAwarenessActivities.equipment,
      articulator: (state) => state.PlannedAwarenessActivities.articulator,
    }),
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
  },
};
</script>
<style>
.active_new {
  background-color: #fff7ed;
  color: #fff;
  font-size: 1.1em;
}
</style>
