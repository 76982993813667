<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="atendent.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="atendent.city ? atendent.city.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="atendent.cpf"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="atendent.number_cress_crp ? atendent.number_cress_crp : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="atendent.cellphone_number ? atendent.cellphone_number : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="atendent.email"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="permitUp"
          iconName="edit"
          :payload="atendent"
          dispatch="Atendents/openEditModalAtendent"
          title="Atualizar Informações do Técnico"
        />
        <AppButtonIcon
          v-if="permitResetPassword"
          iconName="vpn_key"
          :payload="atendent"
          dispatch="Atendents/openModalResetPassword"
          title="Alterar Senha"
        />
        <AppButtonIcon
          iconName="delete"
          :payload="atendent"
          dispatch="Atendents/openCloseDeleteAtendentConfirmation"
          v-if="permitDes"
          title="Remover Técnico"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitResetPassword: false,
      permit: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    atendent: {
      type: Object,
    },
  },
  created() {
    this.atendentAcces();
    this.atendentChange();
    this.atendentDelete();
    this.resetUserPassword();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    atendentAcces() {
      this.canAcces("psychosocial_attendant_index").then((resp) => {
        this.permit = resp;
      });
    },
    atendentDelete() {
      this.canAcces("psychosocial_attendant_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    atendentChange() {
      this.canAcces("psychosocial_attendant_update").then((resp) => {
        this.permitUp = resp;
      });
    },
    resetUserPassword() {
      this.canAcces("user_reset_password").then((resp) => {
        this.permitResetPassword = resp;
      });
    },
  },
  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-4": true,
        "justify-items-stretch": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
        "cursor-pointer": true,
      };
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 19% 10% 11% 10% 11% 33% 4%;
}
</style>
