<template>
  <div>
    <BaseModal headerColor="#f1f1f1" v-if="open">
      <template v-slot:header>
        <div class="flex flex-row-reverse">
          <AppButtonIcon for-close dispatch="ModalInscription/closeModal" />
        </div>
        <div class="ml-52 relative">
          <div class="absolute top-11 -left-1">
            <BaseText
              :text="`${getPhaseName(student.id)}`"
              typeText="custom"
              weight="normal"
              :italic="false"
              size="lg"
              class="text-burnedYellow"
            />
            <BaseText text=" | " typeText="custom" weight="normal" :italic="false" size="sm" />
            <BaseText
              :text="`${getDiscipline(student.id)}`"
              typeText="custom"
              weight="normal"
              :italic="false"
              size="lg"
              class="text-burnedYellow"
            />
            <BaseText text=" | " typeText="custom" weight="normal" :italic="false" size="sm" />
            <BaseText
              :text="`${getTeamCode(student.id)}`"
              typeText="custom"
              weight="normal"
              :italic="false"
              size="lg"
              class="text-burnedYellow"
            />
          </div>
        </div>
        <div class="flex flex-wrap mb-3 justify-center">
          <div id="avatar" class="mr-6">
            <img src="../assets/images/profile-vj.png" class="rounded-full w-44 h-44" alt="Foto Profile" />
          </div>
          <div id="options" class="md:w-full lg:w-full xl:w-5/6 self-center">
            <div id="name-student">
              <BaseText
                :text="`${student.name}`"
                type-text="custom"
                weight="normal"
                mode="capitalize"
                color="quantum"
                size="3xl"
              />
              <BaseText
                v-if="student.social_name"
                :text="` - ${student.social_name}`"
                type-text="custom"
                weight="normal"
                mode="capitalize"
                color="quantum"
                size="3xl"
              />
              <BaseText
                :text="`, ${String(student.age)}`"
                type-text="custom"
                weight="normal"
                color="quantum"
                size="3xl"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div class="container1">
          <div class="container2">
            <FormStudent v-if="showFormEditInscription" />
            <Student v-else />
          </div>
        </div>
      </template>
    </BaseModal>
    <UnderAnalysisConfirmation :open="modalUnderAnalysisConfirmationOpen" />
    <AcceptedConfirmation :open="modalAcceptedConfirmationOpen" />
    <NotAcceptedConfirmation :open="modalNotAcceptedConfirmationOpen" />
    <Alert :open="modalInscriptionAlertStatusOpen" />
  </div>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import BaseText from "../components/BaseText.vue";
import UnderAnalysisConfirmation from "./modal_inscription_status/UnderAnalysisConfirmation.vue";
import AcceptedConfirmation from "./modal_inscription_status/AcceptedConfirmation.vue";
import NotAcceptedConfirmation from "./modal_inscription_status/NotAcceptedConfirmation.vue";
import Alert from "./modal_inscription_status/Alert.vue";
import Student from "../views/Student.vue";
import { mapState } from "vuex";
import FormStudent from "./FormStudent.vue";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonIcon,
    UnderAnalysisConfirmation,
    AcceptedConfirmation,
    NotAcceptedConfirmation,
    Alert,
    Student,
    FormStudent,
  },
  props: {
    open: {
      type: Boolean,
    },
    options: {
      type: String,
    },
  },

  computed: {
    ...mapState({
      phase: (state) => state.Season.phase,
      modalInscriptionStatusOpen: (state) => state.ModalInscription.modalInscriptionStatusConfirmationOpen,
      modalUnderAnalysisConfirmationOpen: (state) =>
        state.ModalInscription.modalInscriptionStatusUnderAnalysisConfirmation,
      modalAcceptedConfirmationOpen: (state) =>
        state.ModalInscription.modalInscriptionStatusAcceptedConfirmation,
      modalNotAcceptedConfirmationOpen: (state) =>
        state.ModalInscription.modalInscriptionStatusNotAcceptedConfirmation,
      modalInscriptionAlertStatusOpen: (state) => state.ModalInscription.modalInscriptionAlertStatus,
      inscription: (state) => state.Inscription,
      inscriptions: (state) => state.Season.bodyTableStudent,
      student: (state) => state.Student,
      showFormEditInscription: (state) => state.ModalInscription.showFormEditInscription,
      modalDeleteDocumentOpen: (state) => state.ModalInscription.modalDeleteDocumentConfirmationOpen,
      idFileStudentRG: (state) => state.ModalInscription.idFileStudentRG,
      idFileStudentCPF: (state) => state.ModalInscription.idFileStudentCPF,
      //idFileStudentCN: (state) => state.ModalInscription.idFileStudentCN,
      idFileStudentCE: (state) => state.ModalInscription.idFileStudentCE,

      idFileStudentDeclaracao: (state) => state.ModalInscription.idFileStudentDeclaracao,
    }),
  },
  methods: {
    isActive(status) {
      return status === this.inscription.status;
    },
    getPhaseName(studentId) {
      let phaseName;
      this.inscriptions.forEach((item) => {
        if (studentId == item.id) {
          phaseName = item.phaseName.items[0].text;
        }
      });
      return phaseName;
    },
    getDiscipline(studentId) {
      let disciplineName = "Módulo - ";
      this.inscriptions.forEach((item) => {
        if (studentId == item.id && item.discipline != null) {
          disciplineName += item.discipline.items[0].text;
        }
      });
      return disciplineName;
    },
    getTeamCode(studentId) {
      let teamCode = "Turma - ";
      this.inscriptions.forEach((item) => {
        if (studentId == item.id) {
          teamCode += item.team_code.items[0].text;
        }
      });
      return teamCode;
    },
  },
};
</script>

<style scoped>
.container1 {
  height: 600px;
  width: 100%;
  overflow: hidden;
}

.container2 {
  width: 105%;
  height: 100%;
  overflow: auto;
  padding-right: 4%;
  padding-left: 4px;
}
</style>
<style>
.buttons-insc {
  margin-top: -10%;
}
.show {
  display: block;
}
.hide {
  display: none;
}
</style>
