<template>
  <div class="grid grid-cols-7 mt-8">
    <div class="flex justify-start col-span-2">
      <div :class="iconClass">
        <img src="../assets/images/profile-vj.png" alt="Maria Antônia Soares" />
      </div>
      <BaseText
        :text="
          student.teams_student.student.social_name
            ? student.teams_student.student.name + ' - ' + student.teams_student.student.social_name
            : student.teams_student.student.name
        "
        typeText="custom"
        mode="normal"
        weight="semibold"
        size="xl"
        class="mr-1"
      />
    </div>
    <div>
      <BaseText
        :text="student.team.code"
        typeText="custom"
        mode="normal"
        weight="normal"
        size="xl"
        class="mr-1"
      />
    </div>
    <div>
      <BaseText
        :text="student.phase.name"
        typeText="custom"
        mode="normal"
        weight="normal"
        size="xl"
        class="mr-1"
      />
    </div>
    <div>
      <BaseText
        :text="student.amount_meetings"
        typeText="custom"
        mode="normal"
        weight="normal"
        size="xl"
        class="mr-1"
      />
    </div>
    <div>
      <BaseText
        :text="student.presence_percent"
        typeText="custom"
        mode="normal"
        weight="normal"
        size="xl"
        class="mr-1"
        v-if="!(benefit.type_benefit === 'public_transport')"
      />
    </div>
    <div>
      <BaseText
        :text="'R$' + student.value"
        typeText="custom"
        mode="normal"
        weight="normal"
        size="xl"
        class="mr-1"
      />
    </div>
  </div>
</template>

<script>
import BaseText from "./BaseText.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseText,
  },

  props: {
    student: {
      type: Object,
    },
  },

  computed: {
    iconClass() {
      let css = [];
      css.push("rounded-md");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");
      css.push("mr-3");
      css.push("items-center");
      css.push("justify-center");
      css.push("min-w-1");

      return css;
    },
    ...mapState({
      benefit: (state) => state.Benefit.benefit,
    }),
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 30% 20% 20% 20% 20%;
}
</style>
