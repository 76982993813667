<template>
  <div v-if="open">
    <div id="tabs-button" class="grid grid-cols-3 justify-between p-4 items-end gap-4">
      <div>
        <Multiselect
          :close-on-select="true"
          show-label
          label-text="Tipo da Atividade"
          clear-on-select
          select-placeholder="Atividade"
          :multiple-option="false"
          :value="activity_type_filtered"
          :options="activity_types"
          :dispatch="setFilterActivityTypeNotReinsertedEvolution"
        />
      </div>
      <div>
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          label-text="Tipo do Resultado"
          select-placeholder="Resultado"
          :multiple-option="false"
          :value="result_types_filtered"
          :options="result_types"
          :dispatch="setFilterResultTypeNotReinsertedEvolution"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="filter_by_articulator"
          input-type="text"
          :value="articulator_filtered"
          show-label
          label-text="Articulador Responsável"
          dispatch="SchoolReinsertions/setFilterArticulatorNotReinsertedEvolution"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="filter_by_initial_date"
          input-type="date"
          :value="initial_date"
          show-label
          label-text="Data de Início da Atividade"
          dispatch="SchoolReinsertions/setFilterInitialDateNotReinsertedEvolution"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="filter_by_final_date"
          input-type="date"
          :value="final_date"
          show-label
          label-text="Data Final da Atividade"
          dispatch="SchoolReinsertions/setFilterFinalDateNotReinsertedEvolution"
        />
      </div>
      <div class="justify-self-end" v-if="permitCreate && toggleSchoolReinsertionNotReinserted">
        <AppButtonColorful
          text="Atividade"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="SchoolReinsertions/openModalAddActivity"
        />
      </div>
      <a
        href="javascript:void(0)"
        @click="exportToExcel()"
        v-if="school_reinsertions.length > 0 && permitExportSpreadsheet"
      >
        <img
          class="inline-block"
          src="../../assets/images/logout_black_24dp.svg"
          alt=""
          style="cursor: pointer"
        />
        <BaseText
          text="Exportar planilha"
          typeText="custom"
          :italic="false"
          size="xs"
          color="burnedYellow"
          style="cursor: pointer"
        />
      </a>
      <BaseModal
        v-if="
          school_reinsertions_evolution_spreadsheet.length > 0 &&
          modalToggleExportSchoolReinsertionsEvolutionSpreadsheet
        "
      >
        <template v-slot:header>
          <div class="grid grid-cols-3">
            <div class="justify-self-start col-span-2">
              <Label
                text="Exportar Planilha de Atividades"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon
                dispatch="SchoolReinsertions/closeExportSchoolReinsertionsEvolutionSpreadsheetModal"
                forClose
              />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="SchoolReinsertions/closeExportSchoolReinsertionsEvolutionSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data_school_reinsertion_evolution"
                  :before-finish="closeExportSchoolReinsertionsEvolutionSpreadsheetModal"
                  worksheet="Atividades"
                  name="atividades_por_inscricao.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetSchoolReinsertionsEvolutionResponse">
        <PreLoading />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-psychosocial_accompaniments"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="SchoolReinsertions/loadSchoolReinsertionsByInscription"
          payload="loadData"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="school_reinsertions.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-school-reinsertions"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-school-reinsertions">
      <div class="mx-12">
        <SchoolReinsertionsNotReinsertedEvolutionCards :data="school_reinsertions" />
      </div>
      <div id="school-reinsertion-pagination" class="mx-12 my-6 pb-3" v-if="school_reinsertions.length > 0">
        <BasePagination
          :total="schoolReinsertionsByInscriptionTotal"
          :total-per-page="schoolReinsertionsByInscriptionTotalPerPage"
          dispatch="SchoolReinsertions/setSchoolReinsertionsByInscriptionOffset"
        />
      </div>
    </div>
    <ModalAddActivity :open="modalToggleAddSchoolReinsertion" />
    <DeleteSchoolReinsertionNotReinsertedEvolutionConfirmation :open="modalToggleDeleteSchoolReinsertion" />
    <ModalViewSchoolReinsertionData :open="modalToggleViewSchoolReinsertion" />
    <ModalPostponeDeadlineAttachProofOfEnrollment
      :open="modalToggleEditSchoolReinsertionDeadlineAttachProofOfEnrollment"
    />
    <ModalPostponeReturnLetterDeadline :open="modalToggleEditSchoolReinsertionReturnLetterDeadline" />
  </div>
</template>

<script>
import SchoolReinsertionsNotReinsertedEvolutionCards from "../list/SchoolReinsertionsNotReinsertedEvolutionCards.vue";
import ModalViewSchoolReinsertionData from "../datas/ModalViewSchoolReinsertionData.vue";
import ModalAddActivity from "./not_reinserteds/ModalAddActivity.vue";
import BasePagination from "../../components/BasePagination.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import DeleteSchoolReinsertionNotReinsertedEvolutionConfirmation from "../alerts/DeleteSchoolReinsertionNotReinsertedEvolutionConfirmation.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseText from "../../components/BaseText.vue";
import BaseModal from "../../components/BaseModal.vue";
import Label from "../../components/Label.vue";
import Multiselect from "../../components/forms/Multiselect.vue";
import ModalPostponeDeadlineAttachProofOfEnrollment from "@/views/school_reinsertion_pages/ModalPostponeDeadlineAttachProofOfEnrollment.vue";
import ModalPostponeReturnLetterDeadline from "@/views/school_reinsertion_pages/ModalPostponeReturnLetterDeadline.vue";
import PreLoading from "../../components/PreLoading.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BasePagination,
    BaseModal,
    Label,
    BaseText,
    AppButtonColorful,
    DeleteSchoolReinsertionNotReinsertedEvolutionConfirmation,
    AppButtonEmpty,
    AppButtonIcon,
    BaseInput,
    SchoolReinsertionsNotReinsertedEvolutionCards,
    ModalAddActivity,
    ModalViewSchoolReinsertionData,
    ModalPostponeDeadlineAttachProofOfEnrollment,
    ModalPostponeReturnLetterDeadline,
    Multiselect,
    PreLoading,
  },
  data() {
    return {
      permitCreate: false,
      permitExportSpreadsheet: false,
      currentPage: 1,
      json_fields: {
        "Tipo da Atividade": "activity_type",
        "Tipo do Resultado": "result_type",
        "Data da Atividade": "reinsertion_date",
        "Data de Entrega da Carta": "letter_delivery_date",
        "Data de Retorno da Carta": "return_letter_deadline",
        "Articulador(a) Responsável": "articulator",
        "Razão para Não Reinserção": "reasons_for_non_reinsertion",
        Observações: "observations",
        Encaminhamentos: "forwardings",
        Escola: "facility",
        Turno: "shift",
        "Ano/Série": "serie",
        "Dependência Administrativa": "administrative_dependency",
        "Modalidade de Ensino": "teaching_modality",
        "Data da Matrícula": "enrollment_date",
        "Data para/de anexo comprovante matrícula": "deadline_attach_proof_of_enrollment",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      closeExportSchoolReinsertionsEvolutionSpreadsheetModal:
        "SchoolReinsertions/closeExportSchoolReinsertionsEvolutionSpreadsheetModal",
      setFilterActivityTypeNotReinsertedEvolution:
        "SchoolReinsertions/setFilterActivityTypeNotReinsertedEvolution",
      setFilterResultTypeNotReinsertedEvolution:
        "SchoolReinsertions/setFilterResultTypeNotReinsertedEvolution",
      setFilterArticulatorNotReinsertedEvolution:
        "SchoolReinsertions/setFilterArticulatorNotReinsertedEvolution",
    }),
    async clearFields() {
      await this.$store.dispatch("SchoolReinsertions/clearQueryNotReinsertedsEvolution");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    async exportToExcel() {
      await this.$store.dispatch(
        "SchoolReinsertions/loadSchoolReinsertionsByInscription",
        "loadDataSpreadsheet"
      );
    },
  },
  async created() {
    this.backToTop();
    const inscription_id = sessionStorage.inscription_id;
    await this.$store.dispatch("SchoolReinsertions/loadInscriptionByIdForSchoolReinsertion", inscription_id);

    this.canAcces("school_reinsertion_create").then((response) => {
      this.permitCreate = response;
    });
    this.canAcces("school_reinsertion_filter_by_inscription_spreadsheet").then((response) => {
      this.permitExportSpreadsheet = response;
    });
    await this.$store.dispatch("SchoolReinsertions/loadArticulators");
    await this.$store.dispatch("SchoolReinsertions/loadActivityTypes");
    await this.$store.dispatch("SchoolReinsertions/loadResultTypes");
    await this.$store.dispatch("SchoolReinsertions/loadSeries");
    await this.$store.dispatch("SchoolReinsertions/loadShifts");
    await this.$store.dispatch("SchoolReinsertions/loadTeachingModalities");
    await this.$store.dispatch("SchoolReinsertions/loadAdministrativeDependencies");
    await this.$store.dispatch("SchoolReinsertions/loadReasonsForNonReinsertions");
    await this.$store.dispatch("SchoolReinsertions/loadFacilities");
    await this.$store.dispatch("Student/load", this.inscription.student);
    await this.$store.dispatch("ModalInscription/updateAllIdDocumentStudent", this.inscription.student.id);
  },

  computed: {
    ...mapState({
      school_reinsertions: (state) => state.SchoolReinsertions.school_reinsertions,
      city: (state) => state.City.city,

      schoolReinsertionsByInscriptionOffset: (state) =>
        state.SchoolReinsertions.schoolReinsertionsByInscriptionOffset,
      schoolReinsertionsByInscriptionTotalPerPage: (state) =>
        state.SchoolReinsertions.schoolReinsertionsByInscriptionTotalPerPage,
      schoolReinsertionsByInscriptionTotal: (state) =>
        state.SchoolReinsertions.schoolReinsertionsByInscriptionTotal,

      inscription: (state) => state.SchoolReinsertions.inscription,
      activity_types: (state) => state.SchoolReinsertions.activity_types,
      activity_type_filtered: (state) =>
        state.SchoolReinsertions.params.not_reinserted_evolution.activity_type,
      result_types: (state) => state.SchoolReinsertions.result_types,
      result_types_filtered: (state) => state.SchoolReinsertions.params.not_reinserted_evolution.result_type,
      articulators: (state) => state.SchoolReinsertions.articulators,
      articulator_filtered: (state) => state.SchoolReinsertions.params.not_reinserted_evolution.articulator,
      initial_date: (state) => state.SchoolReinsertions.params.not_reinserted_evolution.initial_date,
      final_date: (state) => state.SchoolReinsertions.params.not_reinserted_evolution.final_date,
      school_reinsertions_evolution_spreadsheet: (state) =>
        state.SchoolReinsertions.school_reinsertions_evolution_spreadsheet,
      hasSpreadsheetSchoolReinsertionsEvolutionResponse: (state) =>
        state.SchoolReinsertions.hasSpreadsheetSchoolReinsertionsEvolutionResponse,
      json_data_school_reinsertion_evolution: (state) =>
        state.SchoolReinsertions.json_data_school_reinsertion_evolution,
      toggleSchoolReinsertionNotReinserted: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionNotReinserted,
      modalToggleAddSchoolReinsertion: (state) => state.SchoolReinsertions.modalToggleAddSchoolReinsertion,
      modalToggleDeleteSchoolReinsertion: (state) =>
        state.SchoolReinsertions.modalToggleDeleteSchoolReinsertion,
      modalToggleViewSchoolReinsertion: (state) => state.SchoolReinsertions.modalToggleViewSchoolReinsertion,
      modalToggleExportSchoolReinsertionsEvolutionSpreadsheet: (state) =>
        state.SchoolReinsertions.modalToggleExportSchoolReinsertionsEvolutionSpreadsheet,
      modalToggleEditSchoolReinsertionDeadlineAttachProofOfEnrollment: (state) =>
        state.SchoolReinsertions.modalToggleEditSchoolReinsertionDeadlineAttachProofOfEnrollment,
      modalToggleEditSchoolReinsertionReturnLetterDeadline: (state) =>
        state.SchoolReinsertions.modalToggleEditSchoolReinsertionReturnLetterDeadline,
    }),
    verifyIfHasActiveMonitoring() {
      return this.inscription.has_active_monitoring;
    },
  },
  watch: {
    schoolReinsertionsByInscriptionOffset: function () {
      this.$store.dispatch("SchoolReinsertions/loadSchoolReinsertionsByInscription", "loadData");
      this.backToTop();
    },
  },
};
</script>
