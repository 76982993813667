<template>
  <div class="font-system" v-if="permit">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">Encaminhamentos</div>
      <div class="text-ashes-hover font-medium text-xl mx-2 col-span-2" v-if="forwardings.length > 0">
        {{ getTotalRegisters }}
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 justify-between p-4 items-end mx-12 my-6 gap-4">
      <div class="w-52">
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          select-placeholder="Selecionar edição"
          :multiple-option="false"
          :value="season_filtered"
          :options="seasons"
          label-text="Edição"
          :dispatch="filterListBySeason"
          required
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="permitShowPaginate && season_filtered">
        <AppButtonColorful
          id="btn-add-forwarding"
          showIcon
          iconName="add"
          text="Cadastrar Encaminhamento"
          dispatch="Forwardings/toggleModalForwardings"
          typeButton="primary"
          v-if="permitCreate"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14" v-if="season_filtered">
      <FilterComponent>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            label-text="Pesquisar por Nome do Aluno(a)"
            show-label
            clear-on-select
            select-placeholder="Digite o nome do aluno(a) para busca"
            :multiple-option="false"
            :value="inscription_filtered"
            :options="inscriptionsForGeneralMenus"
            :dispatch="filterListByInscription"
            load-dispatch="Forwardings/loadInscriptionsForGeneralMenus"
          />
        </div>
        <div class="col-span-2">
          <BaseLabel
            text="Pesquisar por nome do Responsável pelo Cadastro"
            typeText="custom"
            weight="normal"
            :italic="false"
            size="xs"
          />
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por nome"
            dispatch="Forwardings/filterListByUserName"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione a opção"
            label-text="Pesquisar por Política de Encaminhamento"
            :multiple-option="false"
            :value="forwarding_policy_filtered"
            :options="forwarding_policies"
            :dispatch="filterListByForwardingPolicy"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione a opção"
            label-text="Pesquisar por Status de Encaminhamento"
            :multiple-option="false"
            :value="forwarding_status_filtered"
            :options="forwarding_statuses"
            :dispatch="filterListByForwardingStatus"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="filterListByCity"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione a opção"
            label-text="Pesquisar por Área"
            :multiple-option="false"
            :value="area_filtered"
            :options="user_areas"
            :dispatch="filterListByArea"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="filter_by_initial_date"
            input-type="date"
            :value="forwarding_initial_date"
            show-label
            label-text="Data do Encaminhamento - Data Inicial"
            dispatch="Forwardings/filterListByForwardingInitialDate"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="filter_by_final_date"
            input-type="date"
            :value="forwarding_final_date"
            show-label
            label-text="Data do Encaminhamento - Data Final"
            dispatch="Forwardings/filterListByForwardingFinalDate"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="filter_by_initial_date"
            input-type="date"
            :value="forwarding_expiration_initial_date"
            show-label
            label-text="Data de Expiração - Data Inicial"
            dispatch="Forwardings/filterListByForwardingExpirationInitialDate"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="filter_by_final_date"
            input-type="date"
            :value="forwarding_expiration_final_date"
            show-label
            label-text="Data de Expiração - Data Final"
            dispatch="Forwardings/filterListByForwardingExpirationFinalDate"
          />
        </div>
      </FilterComponent>
      <div class="mt-3">
        <a
          href="javascript:void(0)"
          @click="exportToExcel()"
          v-if="forwardings.length > 0 && permitExportSpreadsheet"
        >
          <img
            class="inline-block"
            src="../assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
      </div>
      <BaseModal
        v-if="forwardings_spreadsheet.length > 0 && modalToggleExportForwardingSpreadsheet && season_filtered"
      >
        <template v-slot:header>
          <div class="grid grid-cols-2">
            <div class="justify-self-start">
              <Label
                text="Exportar Planilha de Encaminhamentos"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon dispatch="Forwardings/closeExportSpreadsheetModal" forClose />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="Forwardings/closeExportSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data"
                  :before-finish="closeExportSpreadsheetModal"
                  worksheet="Encaminhamentos"
                  name="encaminhamentos.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetResponse">
        <PreLoading />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6" v-if="season_filtered">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-forwardings"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Forwardings/loadForwardings"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="forwardings.length > 0 && season_filtered">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-incident_reports"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-forwardings" v-if="season_filtered">
      <div class="mx-12">
        <ForwardingsCards :data="forwardings" />
      </div>
      <div id="forwarding-pagination" class="mx-12 my-6 pb-3" v-if="forwardings.length > 0">
        <BasePagination
          :total="totalForwardings"
          :total-per-page="totalPerPage"
          dispatch="Forwardings/setOffset"
        />
      </div>
      <ModalForwarding :open="modalToggleForwardings" />
      <ModalReturnForwarding :open="modalToggleReturnForwardings" />
      <ModalForwardingPostponeExpirationDate :open="modalToggleForwardingPostponeExpirationDate" />
      <ModalViewForwarding :open="modalViewForwarding" />
      <ModalViewForwardingPostponementHistoriesByForwarding :open="modalToggleViewHistoricForwarding" />
      <DeleteForwarding :open="modalConfirmDeleteForwarding" />
    </div>
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import ForwardingsCards from "./list/ForwardingsCards.vue";
import BasePagination from "../components/BasePagination.vue";
import BaseModal from "../components/BaseModal.vue";
import ModalForwarding from "./ModalForwarding.vue";
import ModalReturnForwarding from "./ModalReturnForwarding.vue";
import ModalForwardingPostponeExpirationDate from "./ModalForwardingPostponeExpirationDate.vue";
import ModalViewForwarding from "./datas/ModalViewForwarding.vue";
import ModalViewForwardingPostponementHistoriesByForwarding from "./datas/ModalViewForwardingPostponementHistoriesByForwarding.vue";
import DeleteForwarding from "./alerts/DeleteForwarding.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import Label from "../components/Label.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseLabel from "../components/BaseLabel.vue";
import BaseText from "../components/BaseText.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import PreLoading from "../components/PreLoading.vue";
import ProhibitedAccessPage from "../components/ProhibitedAccessPage.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    BasePagination,
    BaseLabel,
    BaseModal,
    BaseText,
    AppButtonColorful,
    AppButtonIcon,
    Label,
    AppButtonEmpty,
    ForwardingsCards,
    ModalForwarding,
    ModalReturnForwarding,
    Multiselect,
    ModalViewForwarding,
    ModalViewForwardingPostponementHistoriesByForwarding,
    ModalForwardingPostponeExpirationDate,
    DeleteForwarding,
    BaseInput,
    PreLoading,
    ProhibitedAccessPage,
  },
  data() {
    return {
      permitCreate: false,
      permit: false,
      permitUp: false,
      permitShowPaginate: false,
      permitExportSpreadsheet: false,
      json_fields: {
        Edicao: "season_name",
        Área: "student_area",
        "Nome do Aluno": "student_name",
        "Data do Encaminhamento": "forwarding_date",
        "Data de Expiração": "forwarding_expiration_date",
        "Descrição do Encaminhamento": "forwarding_description",
        Equipamento: "equipment",
        "Política de Encaminhamento": "forwarding_policy",
        "Status do Encaminhamento": "forwarding_status",
        "Motivo da Não Efetivação": "forwarding_reason_unrealized",
        "Usuário Responsável pelo Cadastro": "forwarding_user",
        "Usuário Responsável pelo Retorno": "forwarding_return_user",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      filterListByForwardingPolicy: "Forwardings/filterListByForwardingPolicy",
      filterListByForwardingStatus: "Forwardings/filterListByForwardingStatus",
      filterListByArea: "Forwardings/filterListByArea",
      filterListByState: "Forwardings/filterListByState",
      filterListByCity: "Forwardings/filterListByCity",
      filterListBySeason: "Forwardings/filterListBySeason",
      filterListByInscription: "Forwardings/filterListByInscription",
      closeExportSpreadsheetModal: "Forwardings/closeExportSpreadsheetModal",
      clearFields() {
        let payload = {
          name: "forwardings",
        };
        this.$store.dispatch("Forwardings/clearQuery", payload);
      },
    }),
    async loadData() {
      await this.$store.dispatch("Forwardings/loadForwardings");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    async exportToExcel() {
      await this.$store.dispatch("Forwardings/loadForwardingsSpreadsheet");
    },
    getLabelCity() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
  },

  created() {
    this.canAcces("forwarding_create").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("forwarding_create").then((resp) => {
      this.permitCreate = resp;
    });
    this.canAcces("forwarding_index").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("forwarding_filter_paginate").then((resp) => {
      this.permitShowPaginate = resp;
    });
    this.canAcces("forwarding_export_spreadsheet").then((resp) => {
      this.permitExportSpreadsheet = resp;
    });
    this.canAcces("forwarding_update").then((resp) => {
      this.permitUp = resp;
    });
    const user = JSON.parse(localStorage.userData);
    this.$store.dispatch("Forwardings/setUserAreas", user?.areas);
    this.$store.dispatch("Forwardings/loadSeasons");
    this.$store.dispatch("UtilFunctions/loadStates");
    this.$store.dispatch("UtilFunctions/loadCities");
    // this.$store.dispatch("PsychosocialAccompaniments/setTabPsychosocialAccompanimentType", "personal_data");
  },

  computed: {
    ...mapState({
      forwardings: (state) => state.Forwardings.forwardings,
      seasons: (state) => state.Forwardings.seasons,
      user_areas: (state) => state.Forwardings.user_areas,
      forwardings_spreadsheet: (state) => state.Forwardings.forwardings_spreadsheet,
      forwarding_policies: (state) => state.Forwardings.forwarding_policies,
      forwarding_statuses: (state) => state.Forwardings.forwarding_statuses,
      forwarding_policy_filtered: (state) => state.Forwardings.params.forwarding_policy,
      forwarding_status_filtered: (state) => state.Forwardings.params.forwarding_status,
      season_filtered: (state) => state.Forwardings.params.season,
      area_filtered: (state) => state.Forwardings.params.area,
      forwarding_initial_date: (state) => state.Forwardings.params.forwarding_initial_date,
      forwarding_final_date: (state) => state.Forwardings.params.forwarding_final_date,
      forwarding_expiration_initial_date: (state) =>
        state.Forwardings.params.forwarding_expiration_initial_date,
      forwarding_expiration_final_date: (state) => state.Forwardings.params.forwarding_expiration_final_date,
      inscriptionsForGeneralMenus: (state) => state.Forwardings.inscriptionsForGeneralMenus,
      inscription_filtered: (state) => state.Forwardings.params.inscription,
      state: (state) => state.Forwardings.params.state,
      city: (state) => state.Forwardings.params.city,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,

      modalConfirmDeleteForwarding: (state) => state.Forwardings.modalConfirmDeleteForwarding,
      modalToggleForwardings: (state) => state.Forwardings.modalToggleForwardings,
      modalToggleReturnForwardings: (state) => state.Forwardings.modalToggleReturnForwardings,
      modalViewForwarding: (state) => state.Forwardings.modalViewForwarding,
      modalToggleViewHistoricForwarding: (state) => state.Forwardings.modalToggleViewHistoricForwarding,
      modalToggleForwardingPostponeExpirationDate: (state) =>
        state.Forwardings.modalToggleForwardingPostponeExpirationDate,
      modalToggleExportForwardingSpreadsheet: (state) =>
        state.Forwardings.modalToggleExportForwardingSpreadsheet,

      hasSpreadsheetResponse: (state) => state.Forwardings.hasSpreadsheetResponse,
      json_data: (state) => state.Forwardings.json_data,

      offset: (state) => state.Forwardings.offset,
      totalPerPage: (state) => state.Forwardings.totalPerPage,
      totalForwardings: (state) => state.Forwardings.forwardingTotal,
      getTotalRegisters() {
        return `Quantidade de Registros Encontrados: ${this.totalForwardings}`;
      },
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Forwardings/loadForwardings");
      this.backToTop();
    },
    season_filtered: function () {
      this.$store.dispatch("Forwardings/setForwardings", []);
      this.$store.dispatch("Forwardings/loadInscriptionsForGeneralMenus");
    },
  },
};
</script>
