<template>
  <div class="font-system">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">Matrizes de Bolsas</div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-scholarship-matrix"
          showIcon
          iconName="add"
          text="Cadastrar Matriz"
          dispatch="ScholarshipMatrices/openModalAddScholarshipMatrix"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-4">
          <BaseLabel
            text="Pesquisar por nome da Matriz"
            typeText="custom"
            weight="normal"
            :italic="false"
            size="xs"
          />
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            :value="scholarship_matrix_filters.name"
            showIcon
            input-placeholder="Pesquisar por nome"
            dispatch="ScholarshipMatrices/setFilterName"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-scholarship-matrices"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="ScholarshipMatrices/loadScholarshipMatrices"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="scholarship_matrices.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-scholarship_matrices"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-scholarship_matrices">
      <div class="mx-12">
        <ScholarshipMatricesCards :data="scholarship_matrices" />
      </div>
      <div
        id="scholarship_matrices-pagination"
        class="mx-12 my-6 pb-3"
        v-if="scholarship_matrices.length > 0"
      >
        <BasePagination
          :total="totalScholarshipMatrices"
          :total-per-page="totalPerPage"
          dispatch="ScholarshipMatrices/setScholarshipMatricesOffset"
        />
      </div>
      <ModalScholarshipMatrix :open="modalToggleAddScholarshipMatrix" />
      <DeleteScholarshipMatrixConfirmation :open="modalToggleDeleteScholarshipMatrix" />
    </div>
  </div>
</template>

<script>
import ScholarshipMatricesCards from "@/views/list/ScholarshipMatricesCards.vue";
import BasePagination from "@/components/BasePagination.vue";
import ModalScholarshipMatrix from "@/views/benefits/scholarship_matrices/ModalScholarshipMatrix.vue";
import DeleteScholarshipMatrixConfirmation from "@/views/alerts/DeleteScholarshipMatrixConfirmation.vue";
import AppButtonColorful from "@/components/AppButtonColorful.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import BaseLabel from "@/components/BaseLabel.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    FilterComponent,
    BasePagination,
    BaseLabel,
    AppButtonColorful,
    ScholarshipMatricesCards,
    DeleteScholarshipMatrixConfirmation,
    BaseInput,
    ModalScholarshipMatrix,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      clearFields() {
        this.$store.dispatch("ScholarshipMatrices/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
  },

  created() {
    this.canAcces("scholarship_matrix_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("scholarship_matrix_create").then((response) => {
      this.permitCreate = response;
    });
  },

  computed: {
    ...mapState({
      scholarship_matrices: (state) => state.ScholarshipMatrices.scholarship_matrices,
      scholarship_matrix_filters: (state) => state.ScholarshipMatrices.params,
      modalToggleAddScholarshipMatrix: (state) => state.ScholarshipMatrices.modalToggleAddScholarshipMatrix,
      modalToggleDeleteScholarshipMatrix: (state) =>
        state.ScholarshipMatrices.modalToggleDeleteScholarshipMatrix,
      modalToggleViewScholarshipMatrix: (state) => state.ScholarshipMatrices.modalToggleViewScholarshipMatrix,
      offset: (state) => state.ScholarshipMatrices.scholarshipMatricesOffset,
      totalPerPage: (state) => state.ScholarshipMatrices.scholarshipMatricesTotalPerPage,
      totalScholarshipMatrices: (state) => state.ScholarshipMatrices.scholarshipMatricesTotal,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("ScholarshipMatrices/loadScholarshipMatrices");
      this.backToTop();
    },
  },
};
</script>
