<template>
  <BaseModal width="w-128" v-if="open">
    <template v-slot:header>
      <div class="flex flex-row-reverse">
        <AppButtonIcon for-close dispatch="Season/toggleModalRemoveStudent" />
      </div>
      <div class="flex">
        <img src="../../assets/images/icon-alerta.svg" class="mr-2" />
        <BaseText
          text="Remover Aluno de Turma"
          typeText="custom"
          weight="semibold"
          size="2xl"
          color="ashes-dark"
        />
      </div>
    </template>
    <template v-slot:body>
      <div class="mt-5">
        <BaseText :text="getTeamName" typeText="custom" weight="extrabold" color="quantum" size="lg" />
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-end">
        <AppButtonEmpty
          text="Não"
          type-button="danger"
          dispatch="Season/toggleModalRemoveStudent"
          class="mr-5"
        />
        <a href="javascript:void(0)" @click="removeStudent()">
          <AppButtonEmpty text="Sim" typeButton="success" />
        </a>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      inscriptionFiltered: (state) => state.Season.inscriptionFiltered,
      teamToRemoveFiltered: (state) => state.Season.teamToRemoveFiltered,
    }),
    getTeamName() {
      return `Tem certeza que deseja remover o aluno(a) da turma ${this.teamToRemoveFiltered.code} ?`;
    },
  },
  methods: {
    removeStudent() {
      let teamToRemove = this.teamToRemoveFiltered;
      let payload = { inscription: this.inscriptionFiltered, team: teamToRemove };
      this.$store.dispatch("Allocation/removeStudentFromTeam", payload);
      this.$store.dispatch("Season/toggleModalRemoveStudent");
      this.$store.dispatch("Season/closeModalRemoveStudentFromTeamOpen");
    },
  },
};
</script>
