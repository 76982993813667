<template>
  <BaseCard :class="liClass">
    <div class="grid grid-cols-2 items-center pt-2">
      <div>
        <div class="flex justify-start items-center">
          <div :class="iconClass">
            <AppMaterialIcon
              iconName="book"
              iconType="outlined"
              class="place-self-center text-white"
              iconSize="36"
            />
          </div>
          <div>
            <BaseText
              :text="getNameStudent()"
              typeText="custom"
              mode="underline"
              weight="bold"
              size="xl"
              class="mr-1"
            />
          </div>
        </div>
      </div>
      <div class="grid justify-self-stretch" v-if="!meeting.suspended">
        <div class="grid grid-cols-6 items-center">
          <div class="flex justify-start items-center col-span-5">
            <div class="w-40">
              <AppRadioButton
                v-if="
                  ((permit && season_date_manual_execution_end) || (permit && ability_profile)) &&
                  isTeamsInProgress()
                "
                :values="[
                  {
                    name: `presences${presence.id}`,
                    value: true,
                    presence: presence,
                    description: 'Sim',
                    checked: presence.presence === true,
                  },
                  {
                    name: `presences${presence.id}`,
                    value: false,
                    presence: presence,
                    description: 'Não',
                    checked: presence.presence === false,
                  },
                ]"
                :showLabel="false"
                dispatch="Presence/setIsPresent"
              />
              <BaseText
                v-if="
                  !permit || (!season_date_manual_execution_end && !ability_profile) || !isTeamsInProgress()
                "
                :text="getPresenceResponse()"
                typeText="custom"
                weight="normal"
                size="sm"
                class="mr-1"
              />
            </div>
            <div
              v-if="
                presence.presence != null &&
                presence.presence !== true &&
                !presence.justification &&
                permit &&
                (season_date_manual_execution_end || ability_profile) &&
                isTeamsInProgress()
              "
            >
              <a @click.prevent="openModa(presence)" class="cursor-pointer">
                <BaseText
                  text="Justificar"
                  typeText="custom"
                  mode="underline"
                  weight="bold"
                  size="sm"
                  class="mr-1"
                />
              </a>
            </div>
            <div v-else>
              <div class="break-all">
                <BaseText
                  :text="getJustificationResponse()"
                  typeText="custom"
                  mode="normal-case"
                  weight="bold"
                  size="sm"
                  class="mr-1"
                />
              </div>
            </div>
            <div :key="shouldShowDownloadButton">
              <AppButtonIcon
                v-if="shouldShowDownloadButton"
                iconName="download"
                :payload="presence.student_documents[0].id"
                dispatch="ModalInscription/showStudentDocumentJustificationDocument"
              />
            </div>
          </div>
          <div class="justify-self-end" v-if="isTeamsInProgress()">
            <AppButtonIcon
              v-if="presence.justification && permit"
              iconName="edit"
              dispatch="Presence/openJustificationModal"
              :payload="presence"
            />
            <AppButtonIcon
              v-if="presence.presence != null && permit_destroy"
              iconName="delete"
              dispatch="Presence/openCloseDeletePresenceConfirmation"
              :payload="presence"
            />
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import AppRadioButton from "./AppRadioButton.vue";
import BaseText from "./BaseText.vue";
import AppMaterialIcon from "./AppMaterialIcon.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    AppRadioButton,
    BaseText,
    AppMaterialIcon,
  },

  data() {
    return {
      permit: false,
      permit_destroy: false,
      season_date_manual_execution_end: true,
      ability_profile: null,
      presenceStudentDocuments: this.presence.student_documents,
    };
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    presence: {
      type: Object,
    },
  },

  computed: {
    ...mapState({
      season: (state) => state.Seasons.season,
      meeting: (state) => state.Meeting.meeting,
      tabTypeTeamView: (state) => state.Teams.tabTypeTeamView,
    }),
    shouldShowDownloadButton() {
      return this.presence.justification && this.presence.student_documents.length > 0;
    },
    presenceStudentDocumentsLength() {
      return this.presence.student_documents.length;
    },
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        "p-6": true,
      };
    },
    iconClass() {
      let css = [];
      let bgColor = "bg-yellow-300";

      css.push(bgColor);
      css.push("rounded-full");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");
      css.push("mr-3");
      css.push("items-center");
      css.push("justify-center");
      css.push("min-w-1");

      return css;
    },
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    permitUpdatePresence() {
      this.canAcces("presence_update").then((resp) => {
        this.permit = resp;
      });
    },
    permitDestroyPresence() {
      this.canAcces("presence_destroy").then((resp) => {
        this.permit_destroy = resp;
      });
    },
    openModa(payload) {
      this.$store.dispatch("Presence/openJustificationModal", payload);
    },
    getPresenceResponse() {
      if (this.presence.presence === true) {
        return "Sim";
      } else if (this.presence.presence === false) {
        return "Não";
      } else {
        return "Frequência não lançada";
      }
    },
    getJustificationResponse() {
      if (this.presence.justification == null) {
        return "-";
      }
      return this.presence.justification.length < 20
        ? this.presence.justification
        : this.presence.justification.slice(0, 20) + "...";
    },
    getSeasonDateManualExecutionEnd() {
      this.season_date_manual_execution_end =
        localStorage.getItem("season_date_manual_execution_end") === "null";
    },
    getNameStudent() {
      return this.presence.student.social_name
        ? `${this.presence.student.social_name} (nome social de: ${this.presence.student.name})`
        : this.presence.student.name;
    },
    isTeamsInProgress() {
      return this.tabTypeTeamView === "teams_in_progress";
    },
  },
  created() {
    let userData = JSON.parse(localStorage.getItem("userData"));
    this.ability_profile = userData.abilityProfileId === 3;

    this.permitUpdatePresence();
    this.permitDestroyPresence();
    this.getSeasonDateManualExecutionEnd();
  },
};
</script>
<style>
.break-text {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 5px 0 0 0;
}
</style>
