<template>
  <div class="font-system">
    <div class="mx-12 my-6">
      <Breadcrumbs :breadcrumbs="breadcrumbs" />
    </div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">Bolsas</div>
    </div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between p-4 items-end mx-12 my-6 gap-4">
      <div class="w-52">
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          select-placeholder="Selecionar edição"
          :multiple-option="false"
          :value="season"
          :options="seasons"
          label-text="Edição"
          :dispatch="setSeason"
          required
        />
      </div>
    </div>
    <div id="menus_productive_insertion_main_page" class="mx-16 flex" v-if="season">
      <div v-if="permitListScholarshipPaymentSheetTab">
        <a @click="changeTab('scholarship_payment_sheet')" href="javascript:void(0)">
          <BaseText
            text="Folha de Pagamento"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorScholarshipPaymentSheets"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div v-if="permitListScholarshipPaymentControlTab">
        <a @click="changeTab('scholarship_payment_control')" href="javascript:void(0)">
          <BaseText
            text="Controle de Pagamento"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorScholarshipPaymentControls"
          />
        </a>
      </div>
    </div>
    <div id="list-productive-insertion-types">
      <div v-if="season">
        <ScholarshipPaymentSheetMainPage :open="toggleScholarshipPaymentSheets" />
      </div>
      <!--      <div v-if="season">-->
      <!--        <ForwardingProductiveInsertion :open="toggleReferredParcipants" />-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import ScholarshipPaymentSheetMainPage from "@/views/benefits/scholarship/payment_sheet/ScholarshipPaymentSheetMainPage.vue";
import BaseText from "@/components/BaseText.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    ScholarshipPaymentSheetMainPage,
    Multiselect,
    Breadcrumbs,
  },
  data() {
    return {
      permitCreate: false,
      permit: false,
      permitListScholarshipPaymentSheetTab: false,
      permitListScholarshipPaymentControlTab: false,
      permitUp: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setSeason: "Advantages/setSeason",
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    changeTab(value) {
      this.$store.dispatch("Advantages/setScholarshipTabAdvantageView", value);
    },
  },
  created() {
    this.$store.dispatch("Advantages/loadSeasons");
    this.$store.dispatch("Advantages/setScholarshipTabAdvantageView", "scholarship_payment_sheet");
    const season = JSON.parse(localStorage.getItem("season"));
    if (season) {
      this.$store.dispatch("Advantages/setSeason", season);
    }

    this.canAcces("benefit_scholarship_payment_sheet_tab_permission").then((response) => {
      this.permitListScholarshipPaymentSheetTab = response;
    });
    this.canAcces("benefit_scholarship_payment_control_tab_permission").then((response) => {
      this.permitListScholarshipPaymentControlTab = response;
    });
  },
  computed: {
    ...mapState({
      seasons: (state) => state.Advantages.seasons,
      season: (state) => state.Advantages.season,
      tabScholarshipAdvantageView: (state) => state.Advantages.tabScholarshipAdvantageView,
      toggleScholarshipPaymentSheets: (state) => state.Advantages.toggleScholarshipPaymentSheets,
      toggleScholarshipPaymentControls: (state) => state.Advantages.toggleScholarshipPaymentControls,
    }),
    setColorScholarshipPaymentSheets() {
      return this.tabScholarshipAdvantageView === "scholarship_payment_sheet"
        ? "burnedYellow"
        : "ashes-hover";
    },
    setColorScholarshipPaymentControls() {
      return this.tabScholarshipAdvantageView === "scholarship_payment_control"
        ? "burnedYellow"
        : "ashes-hover";
    },
    breadcrumbs() {
      return [
        { name: "Home", path: "/admin" },
        { name: "Bolsas", path: "/bolsas" },
      ];
    },
  },
};
</script>
