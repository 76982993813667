import { baseURL, getAxios } from "@/service/Api";

export async function getInscriptionsForProductiveInsertion(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/inscricoes/lista_insercao_produtiva`;
  return api.get(url, { params: filters });
}

export async function getInscriptionsForExportProductiveInsertion(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/inscricoes/exportar_planilha_insercao_produtiva`;
  return api.get(url, { params: filters });
}
