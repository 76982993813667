<template>
  <div class="font-system" v-if="permit && open">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-2xl mx-2 col-span-2">Seleções</div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-opportunity"
          showIcon
          iconName="add"
          text="Cadastrar Seleção"
          dispatch="Opportunities/openModalAddOpportunity"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="name_filtered"
            label-text="Pesquisar por nome"
            input-placeholder="Pesquisar por nome"
            dispatch="Opportunities/setFilterName"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Empresa"
            select-placeholder="Digite o nome da empresa para buscar opção"
            :multiple-option="false"
            :value="company_filtered"
            :options="companies"
            :dispatch="setFilterCompany"
            load-dispatch="Opportunities/loadCompanies"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setFilterCity"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Parceiro"
            select-placeholder="Digite o nome do parceiro para buscar opção"
            :multiple-option="false"
            :value="partner_filtered"
            :options="partners"
            :dispatch="setFilterPartner"
            load-dispatch="Opportunities/loadPartners"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Segmento"
            select-placeholder="Digite o nome do segmento para buscar opção"
            :multiple-option="false"
            :value="segment_filtered"
            :options="segments"
            :dispatch="setFilterSegment"
            load-dispatch="Opportunities/loadSegments"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Ocupação"
            select-placeholder="Digite o nome da ocupação para buscar opção"
            :multiple-option="false"
            :value="occupation_filtered"
            :options="occupations"
            :dispatch="setFilterOccupation"
            load-dispatch="Opportunities/loadOccupations"
          />
        </div>
        <div class="col-span-4">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Tipo da Contratação"
            select-placeholder="Digite o tipo da ocupação para buscar opção"
            :multiple-option="false"
            :value="contracting_type_filtered"
            :options="contracting_types"
            :dispatch="setFilterContractingType"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="filter_by_initial_date"
            input-type="date"
            :value="initial_date_filtered"
            show-label
            label-text="Data da Seleção - Data Inicial"
            dispatch="Opportunities/setFilterInitialDate"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="filter_by_final_date"
            input-type="date"
            :value="final_date_filtered"
            show-label
            label-text="Data da Seleção - Data Final"
            dispatch="Opportunities/setFilterFinalDate"
          />
        </div>
      </FilterComponent>
      <div class="mt-3">
        <a
          href="javascript:void(0)"
          @click="exportToExcel()"
          v-if="opportunities.length > 0 && permitExportSpreadsheet"
        >
          <img
            class="inline-block"
            src="@/assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
      </div>
      <BaseModal v-if="opportunities_spreadsheet.length > 0 && modalToggleExportOpportunitiesSpreadsheet">
        <template v-slot:header>
          <div class="grid grid-cols-3">
            <div class="justify-self-start col-span-2">
              <Label
                text="Exportar Planilha de Seleções"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon dispatch="Opportunities/closeExportOpportunitiesSpreadsheetModal" forClose />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="Opportunities/closeExportOpportunitiesSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data_opportunity"
                  :before-finish="closeExportOpportunitiesSpreadsheetModal"
                  worksheet="Seleções"
                  name="oportunidades.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetOpportunitiesResponse">
        <PreLoading />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-opportunities"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Opportunities/loadOpportunities"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="opportunities.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-opportunities"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-opportunities">
      <div class="mx-12">
        <OpportunitiesCards :data="opportunities" />
      </div>
      <div id="opportunities-pagination" class="mx-12 my-6 pb-3" v-if="opportunities.length > 0">
        <BasePagination
          :total="totalOpportunities"
          :total-per-page="totalPerPage"
          dispatch="Opportunities/setOpportunitiesOffset"
        />
      </div>
      <ModalOpportunity :open="modalToggleAddOpportunity" />
      <ModalViewOpportunityData :open="modalToggleViewOpportunity" />
      <DeleteOpportunityConfirmation :open="modalToggleDeleteOpportunity" />
    </div>
  </div>
</template>

<script>
import OpportunitiesCards from "../list/OpportunitiesCards.vue";
import BasePagination from "../../components/BasePagination.vue";
import ModalOpportunity from "./modals/ModalOpportunity.vue";
import ModalViewOpportunityData from "../datas/ModalViewOpportunityData.vue";
import DeleteOpportunityConfirmation from "../alerts/DeleteOpportunityConfirmation.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";
import Multiselect from "../../components/forms/Multiselect.vue";
import BaseModal from "@/components/BaseModal.vue";
import PreLoading from "@/components/PreLoading.vue";
import Label from "@/components/Label.vue";
import BaseText from "@/components/BaseText.vue";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    AppButtonIcon,
    AppButtonEmpty,
    BaseText,
    Label,
    PreLoading,
    BaseModal,
    Multiselect,
    BasePagination,
    AppButtonColorful,
    OpportunitiesCards,
    DeleteOpportunityConfirmation,
    BaseInput,
    ModalOpportunity,
    ModalViewOpportunityData,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
      permitExportSpreadsheet: false,
      json_fields: {
        Data: "opportunity_date",
        Município: "city",
        Nome: "name",
        Empresa: "company",
        Parceiro: "partner",
        Segmento: "segment",
        Ocupação: "occupation",
        Contratação: "contracting_type",
        "Nº de Vagas": "vacancy_quantity",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
      showFilters: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterCompany: "Opportunities/setFilterCompany",
      setFilterPartner: "Opportunities/setFilterPartner",
      setFilterSegment: "Opportunities/setFilterSegment",
      setFilterCity: "Opportunities/setFilterCity",
      setFilterState: "Opportunities/setFilterState",
      setFilterOccupation: "Opportunities/setFilterOccupation",
      setFilterContractingType: "Opportunities/setFilterContractingType",
      closeExportOpportunitiesSpreadsheetModal: "Opportunities/closeExportOpportunitiesSpreadsheetModal",
      clearFields() {
        this.$store.dispatch("Opportunities/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    async exportToExcel() {
      await this.$store.dispatch("Opportunities/loadOpportunitiesForExport");
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
  },

  created() {
    this.canAcces("opportunity_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("opportunity_create").then((response) => {
      this.permitCreate = response;
    });
    this.canAcces("opportunity_export_spreadsheet").then((response) => {
      this.permitExportSpreadsheet = response;
    });
    this.$store.dispatch("Opportunities/loadContractingTypes");
    this.$store.dispatch("Opportunities/loadCompanies");
    this.$store.dispatch("Opportunities/loadPartners");
    this.$store.dispatch("Opportunities/loadSegments");
    this.$store.dispatch("Opportunities/loadOccupations");
  },

  computed: {
    ...mapState({
      opportunities: (state) => state.Opportunities.opportunities,
      modalToggleAddOpportunity: (state) => state.Opportunities.modalToggleAddOpportunity,
      modalToggleDeleteOpportunity: (state) => state.Opportunities.modalToggleDeleteOpportunity,
      modalToggleViewOpportunity: (state) => state.Opportunities.modalToggleViewOpportunity,
      offset: (state) => state.Opportunities.opportunitiesOffset,
      totalPerPage: (state) => state.Opportunities.opportunitiesTotalPerPage,
      totalOpportunities: (state) => state.Opportunities.opportunitiesTotal,
      opportunity: (state) => state.Opportunities.opportunity,
      companies: (state) => state.Opportunities.companies,
      company_filtered: (state) => state.Opportunities.params.company,
      partners: (state) => state.Opportunities.partners,
      partner_filtered: (state) => state.Opportunities.params.partner,
      segments: (state) => state.Opportunities.segments,
      segment_filtered: (state) => state.Opportunities.params.segment,
      occupations: (state) => state.Opportunities.occupations,
      occupation_filtered: (state) => state.Opportunities.params.occupation,
      contracting_types: (state) => state.Opportunities.contracting_types,
      contracting_type_filtered: (state) => state.Opportunities.params.contracting_type,
      initial_date_filtered: (state) => state.Opportunities.params.initial_date,
      final_date_filtered: (state) => state.Opportunities.params.final_date,
      name_filtered: (state) => state.Opportunities.params.name,
      opportunities_spreadsheet: (state) => state.Opportunities.opportunities_spreadsheet,
      hasSpreadsheetOpportunitiesResponse: (state) => state.Opportunities.hasSpreadsheetOpportunitiesResponse,
      json_data_opportunity: (state) => state.Opportunities.json_data_opportunity,
      modalToggleExportOpportunitiesSpreadsheet: (state) =>
        state.Opportunities.modalToggleExportOpportunitiesSpreadsheet,
      state: (state) => state.Opportunities.params.state,
      city: (state) => state.Opportunities.params.city,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Opportunities/loadOpportunities");
      this.backToTop();
    },
  },
};
</script>
<style scoped>
.filters-container {
  margin-top: 10px;
}
</style>
