<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="city.id"
            text="Editar Município"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Criar Município"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="City/closeModalAddCity" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2">
          <BaseInput
            id="Nome"
            input-name="name"
            input-type="text"
            show-label
            required
            label-text="Nome"
            :value="city.name"
            dispatch="City/setName"
            :error-message="getErrorMessage('nome')"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            id="filterState"
            selectPlaceholder="Selecionar Estado"
            label-text="Selecionar Estado"
            class="mr-5"
            closeOnSelect
            show-label
            :multipleOption="false"
            :options="states"
            :value="state"
            :dispatch="setState"
            :error-message="getErrorMessage('estado')"
          />
        </div>
        <!--        <div class="col-span-2" v-if="!city.id">-->
        <!--          <BaseTextArea-->
        <!--            active-->
        <!--            inputName="neighborhoods"-->
        <!--            inputPlaceholder="Digite o nome do Bairro e aperte 'Enter' para adicionar mais bairros"-->
        <!--            labelText="Criar Bairros [Opcional]"-->
        <!--            showLabel-->
        <!--            rows="4"-->
        <!--            :value="neighborhoods_names"-->
        <!--            :multiple="true"-->
        <!--            dispatch="City/setNeighborhoodsNames"-->
        <!--          />-->
        <!--        </div>-->
        <!--        <div class="col-span-2" v-if="!city.id">-->
        <!--          <Multiselect-->
        <!--            :close-on-select="true"-->
        <!--            show-label-->
        <!--            clear-on-select-->
        <!--            select-placeholder="Selecionar Área"-->
        <!--            :multiple-option="false"-->
        <!--            :value="neighborhood_area"-->
        <!--            :options="user_areas"-->
        <!--            label-text="Vincular Bairro(s) à Área [Opcional]"-->
        <!--            :dispatch="setNeighborhoodArea"-->
        <!--          />-->
        <!--        </div>-->
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="City/closeModalAddCity" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty v-if="city.id" text="Atualizar" typeButton="success" dispatch="City/update" />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="City/save" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import Label from "@/components/Label.vue";
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    Multiselect,
    BaseModal,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      setState: "City/setState",
      setNeighborhoodArea: "City/setNeighborhoodArea",
    }),
  },
  computed: {
    ...mapState({
      city: (state) => state.City.city_fields,
      state: (state) => state.City.state,
      neighborhoods_names: (state) => state.City.neighborhoods_names,
      neighborhood_area: (state) => state.City.neighborhood_area,
      states: (state) => state.UtilFunctions.states,
      user_areas: (state) => state.UtilFunctions.user_areas,
    }),
    ...mapGetters({
      getErrorMessage: "City/getErrorMessage",
    }),
  },
};
</script>
