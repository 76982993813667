<template>
  <div>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4 ml-5">
        <BaseText
          text="Nº"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-1">
        <BaseText
          text="Nome Completo"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-1">
        <BaseText
          text="CPF"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-1">
        <BaseText
          text="Valor do Crédito"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-1">
        <BaseText
          text="Status do Pagamento"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li :key="beneficiary.id" :data-index="index" v-for="(beneficiary, index) in data">
        <div>
          <AppCardTransportationVoucherBeneficiaries :beneficiary="beneficiary" :index="index" />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardTransportationVoucherBeneficiaries from "@/components/AppCardTransportationVoucherBeneficiaries.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";

export default {
  components: {
    AppCardTransportationVoucherBeneficiaries,
    BaseText,
    BaseStaggeredFade,
  },
  props: {
    data: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 14% 21% 12% 15% 8%;
}
</style>
