import { showTeam } from "../../service/TeamApi";
import { getMeetingByTeam } from "../../service/MeetingApi";
import { getPresenceByTeamStudentDate } from "../../service/PresenceApi";
import JsonExcel from "vue-json-excel";
import moment from "moment";
Vue.component("downloadExcel", JsonExcel);
import loader from "vue-ui-preloader";
Vue.use(loader);

import Vue from "vue";

const state = {
  loading: false,
  fileGenerated: false,
  modalOpen: false,
  teams: [],
  filteredTeams: [],
  teamsExport: [],
  filters: {
    area: {},
    neighborhood: {},
    equipment: {},
  },
  json_fields: null,
  arrayFieldsExport: [],
  arrayDataExport: [],
  meetings_dates_export: [],
  initial_date: null,
  final_date: null,
  min_percentage: null,
  max_percentage: null,
  percentage_initial_date: null,
  percentage_final_date: null,
  period_presence_options: [
    {
      id: 1,
      name: "Geral",
    },
    {
      id: 2,
      name: "Personalizado",
    },
  ],
  period_presence: null,
};

const mutations = {
  toggleModalMutation: (state) => {
    state.modalOpen = !state.modalOpen;
  },
  setFileGeneratedMutation: (state, payload) => {
    state.fileGenerated = payload;
  },
  setLoadingMutation: (state, loading) => {
    state.loading = loading;
  },
  setQtdLoadsMutation: (state, payload) => {
    state.qtd_loads = payload;
  },
  sumQtdLoadsMutation: (state) => {
    state.qtd_loads = state.qtd_loads + 1;
  },
  deductQtdLoadsMutation: (state) => {
    state.qtd_loads = state.qtd_loads - 1;
  },
  setFilterAreaMutation: (state, payload) => {
    state.filters.area = payload;
  },
  setFilterNeighborhoodMutation: (state, payload) => {
    state.filters.neighborhood = payload;
  },
  setFilterEquipmentMutation: (state, payload) => {
    state.filters.equipment = payload;
  },
  setTeamsMutation: (state, teams) => {
    state.teams = teams;
    state.filteredTeams = teams;
  },
  setFilteredTeamsMutation: (state, teams) => {
    state.filteredTeams = teams;
  },
  addTeamsExportMutation: (state, team) => {
    state.teamsExport.push(team);
  },
  removeTeamsExportMutation: (state, team) => {
    state.teamsExport = state.teamsExport.filter((obj) => {
      return obj.id !== team.id;
    });
  },
  setTeamsExportMutation: (state, teams) => {
    state.teamsExport = teams;
  },
  setArrayDataExportMutation: (state, arrayDataExport) => {
    state.arrayDataExport = arrayDataExport;
  },
  addArrayDataExportMutation: (state, arrayDataExport) => {
    state.arrayDataExport.push(arrayDataExport);
  },
  setArrayFieldsExportMutation: (state, meetings_dates) => {
    let fields = [];
    let dates =
      state.percentage_initial_date && state.percentage_final_date
        ? `[${moment(state.percentage_initial_date).format("DD/MM/YYYY")} à ${moment(
            state.percentage_final_date
          ).format("DD/MM/YYYY")}]`
        : "";
    fields["Edicao"] = "season_name";
    fields["Turma"] = "discipline";
    fields["Equipamento"] = "equipment";
    fields["Nome do Jovem"] = "student_name";
    fields["Nome Social do Jovem"] = "student_social_name";
    fields[`Porcentagem - ${state.period_presence?.name} ${dates}`] = "presence_percent";
    meetings_dates.forEach((element) => {
      fields[element.date] = element.date_field_export;
    });
    state.json_fields = { ...fields };
  },
  addMeetingsDatesExportMutation: (state, meeting_date) => {
    state.meetings_dates_export.push(meeting_date);
  },
  setInitialDateMutation: (state, initial_date) => {
    state.initial_date = initial_date;
  },
  setFinalDateMutation: (state, final_date) => {
    state.final_date = final_date;
  },
  setPeriodPresenceMutation: (state, period_presence) => {
    state.period_presence = period_presence;
  },
  setMinPercentageDateMutation: (state, min_percentage) => {
    state.min_percentage = min_percentage;
  },
  setMaxPercentageDateMutation: (state, max_percentage) => {
    state.max_percentage = max_percentage;
  },
  setPercentageInitialDateMutation: (state, percentage_initial_date) => {
    state.percentage_initial_date = percentage_initial_date;
  },
  setPercentageFinalDateMutation: (state, percentage_final_date) => {
    state.percentage_final_date = percentage_final_date;
  },
};

const actions = {
  openModal: async ({ commit, dispatch }) => {
    // let payload = {
    //   phase: rootState.Season.phase,
    //   season: rootState.Season.season,
    // };
    dispatch("Teams/getTeamsBySeasonExport", null, { root: true });
    commit("toggleModalMutation");
  },
  closeModal: async ({ commit, dispatch, rootState }) => {
    const phase_id = rootState.Season.phase;
    await dispatch("Season/setPhase", phase_id, { root: true });
    commit("toggleModalMutation");
    commit("setTeamsExportMutation", []);
    commit("setFileGeneratedMutation", false);
  },
  filterNeighborhood: ({ commit, dispatch }, neighborhood) => {
    commit("setFilterNeighborhoodMutation", neighborhood);
    dispatch("filterList");
  },
  filterArea: ({ commit, dispatch }, area) => {
    commit("setFilterAreaMutation", area);
    dispatch("filterList");
  },
  filterEquipment: ({ commit, dispatch }, equipment) => {
    commit("setFilterEquipmentMutation", equipment);
    dispatch("filterList");
  },
  filterList: ({ commit, state }) => {
    const neighborhood = state.filters.neighborhood;
    const filteredByNeighborhood = neighborhood
      ? state.filteredAll.filter((obj) => {
          return obj.neighborhood_id === neighborhood.id;
        })
      : state.filteredAll;
    const area = state.filters.area;
    const filteredByArea = area
      ? filteredByNeighborhood.filter((obj) => {
          return obj.areas_attributes.some((el) => el.id === area.id);
        })
      : filteredByNeighborhood;
    commit("setFilteredTeamsMutation", filteredByArea);
  },
  addTeamsExportMutation: ({ commit }, payload) => {
    commit("addTeamsExportMutation", payload);
  },
  removeTeamsExportMutation: ({ commit }, payload) => {
    commit("removeTeamsExportMutation", payload);
  },
  setArrayDataExport: ({ commit }, payload) => {
    commit("setArrayDataExportMutation", payload);
  },
  addArrayDataExport: ({ commit }, payload) => {
    commit("addArrayDataExportMutation", payload);
  },
  setInitialDate: ({ commit }, payload) => {
    commit("setInitialDateMutation", payload.value);
  },
  setFinalDate: ({ commit }, payload) => {
    commit("setFinalDateMutation", payload.value);
  },
  setTeamsExport: ({ commit }, payload) => {
    commit("setTeamsExportMutation", payload);
  },
  cancelExport: ({ commit }) => {
    commit("setFileGeneratedMutation", false);
  },
  generateJsonFieldsExport: () => {
    const meetings_dates = getters.getDatesToExport();
    meetings_dates.then((response) => {
      response.map(function (element) {
        console.log(element);
      });
    });
  },
  generatePresencesToExport: async ({ commit }) => {
    let qtd_loads = state.teamsExport.length;
    commit("setArrayDataExportMutation", []);
    commit("setLoadingMutation", true);
    const meetings_dates = Array();
    const meetings_presences = Array();
    let teams_export = {
      teams: getters.getTeamsForExport(),
    };
    teams_export.initial_date = state.initial_date ? state.initial_date : null;
    teams_export.final_date = state.final_date ? state.final_date : null;
    teams_export.period_presence = state.period_presence ? state.period_presence.id : null;
    teams_export.min_percentage = state.min_percentage ? state.min_percentage : null;
    teams_export.max_percentage = state.max_percentage ? state.max_percentage : null;
    teams_export.percentage_initial_date = state.percentage_initial_date
      ? state.percentage_initial_date
      : null;
    teams_export.percentage_final_date = state.percentage_final_date ? state.percentage_final_date : null;

    for (const teamId of teams_export.teams) {
      await getMeetingByTeam(teamId, teams_export)
        .then((response) => {
          for (const meeting of response.data.meetings) {
            let date = meeting.date;
            let date_field_export = meeting.date_field_export;
            let meeting_date = [];
            meeting_date["date"] = date;
            meeting_date["date_field_export"] = date_field_export;
            let index = meetings_dates
              .map(function (obj) {
                return obj.date;
              })
              .indexOf(date);
            if (index === -1) {
              meetings_dates.push(meeting_date);
              let presence = [];
              presence["date"] = date;
              presence["date_field_export"] = date_field_export;
              presence["team_id"] = meeting.team.id;
              presence["discipline_id"] = meeting.team.discipline.id;
              presence["presences"] = meeting.presences;
              meetings_presences.push(presence);
            }
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
    let sortedDates = await getters.sortMeetingsDates(meetings_dates);
    commit("setArrayFieldsExportMutation", sortedDates);
    for (const teamId of teams_export.teams) {
      await showTeam(teamId, teams_export)
        .then(async (response) => {
          let team = response.data.team;
          let team_id = team.id;
          let team_code = team.code;
          let season_name = team.season.name;
          let discipline = team.discipline.name;
          let equipment = team.equipment.name;
          for (const student of team.students) {
            let student_id = student.id;
            let student_name = student.name;
            let student_social_name = student.social_name;
            let presence_percent = student.presence_percent;
            let arrayItem = new Array();
            arrayItem["season_name"] = season_name;
            arrayItem["discipline"] = team_code + " - " + discipline;
            arrayItem["equipment"] = equipment;
            arrayItem["student_name"] = student_name;
            arrayItem["student_social_name"] = student_social_name;
            arrayItem["presence_percent"] = presence_percent;

            for (const mdate of meetings_dates) {
              let presencas = await getPresenceByTeamStudentDate(
                team_id,
                student_id,
                mdate.date_field_export
              );
              if (presencas.data.meeting.suspended) {
                arrayItem[mdate.date_field_export] = "S";
              } else {
                if (presencas.data.presences.length > 0) {
                  if (presencas.data.presences[0] != null) {
                    let presence_fault = "-";
                    if (presencas.data.presences[0].justification) {
                      presence_fault = "J";
                    } else {
                      if (presencas.data.presences[0].presence === true) {
                        presence_fault = "P";
                      } else if (presencas.data.presences[0].presence === false) {
                        presence_fault = "F";
                      }
                    }
                    arrayItem[mdate.date_field_export] = presence_fault;
                  } else {
                    arrayItem[mdate.date_field_export] = "-";
                    arrayItem["justification"] = "-";
                  }
                } else {
                  arrayItem[mdate.date_field_export] = "-";
                }
              }
            }
            commit("addArrayDataExportMutation", arrayItem);
          }
        })
        .catch((e) => {
          console.log("error", e.response); //TODO: tratar esse error
        });
      qtd_loads = qtd_loads - 1;
    }
    if (qtd_loads <= 0) {
      commit("setLoadingMutation", false);
      commit("setFileGeneratedMutation", true);
    }
    if (teams_export.teams?.length == 0) {
      Vue.$toast.error("Nenhuma turma selecionada!");
    }
    commit("setInitialDateMutation", null);
    commit("setFinalDateMutation", null);
  },
  setPeriodPresence: ({ commit }, payload) => {
    commit("setPeriodPresenceMutation", payload);
    if (payload?.id === 1) {
      commit("setPercentageInitialDateMutation", null);
      commit("setPercentageFinalDateMutation", null);
    }
  },
  setMinPercentageDate: ({ commit }, payload) => {
    commit("setMinPercentageDateMutation", payload.value);
  },
  setMaxPercentageDate: ({ commit }, payload) => {
    commit("setMaxPercentageDateMutation", payload.value);
  },
  setPercentageInitialDate: ({ commit }, payload) => {
    commit("setPercentageInitialDateMutation", payload.value);
  },
  setPercentageFinalDate: ({ commit }, payload) => {
    commit("setPercentageFinalDateMutation", payload.value);
  },
};

const getters = {
  getTeamsForExport: () => {
    let data = state.teamsExport.map((obj) => {
      return obj.id;
    });
    return data;
  },
  sortMeetingsDates: async (meetings_dates) => {
    const sortedDates = await meetings_dates.sort(function (a, b) {
      let x = moment(a.date_field_export, "YYYY-MM-DD");
      let y = moment(b.date_field_export, "YYYY-MM-DD");
      return x.diff(y);
    });
    return sortedDates;
  },
  getPresenceFault: (presenceMeet) => {
    if (presenceMeet.length > 0) {
      let student_presence = presenceMeet[0].student_presence;
      switch (student_presence) {
        case true:
          return "P";
        case false:
          return "F";
        default:
          return "-";
      }
    } else {
      return "-";
    }
  },
  getDatesToExport: () => {
    let promise = new Promise(function (resolve, reject) {
      const meetings_dates = [];
      let teams_export = {
        teams: getters.getTeamsForExport(),
      };
      teams_export.teams?.forEach((teamId) => {
        getMeetingByTeam(teamId)
          .then((response) => {
            let meetings = response.data.meetings;
            meetings.forEach((meeting) => {
              let date = meeting.date;
              let date_field_export = meeting.date_field_export;
              let meeting_date = [];
              meeting_date["date"] = date;
              meeting_date["date_field_export"] = date_field_export;
              let index = meetings_dates
                .map(function (obj) {
                  return obj.date;
                })
                .indexOf(date);
              if (index === -1) {
                meetings_dates.push(meeting_date);
              }
            });
          })
          .catch((e) => {
            console.log("error", e);
            reject([]);
          });
      });
      resolve(meetings_dates);
    });
    return promise;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
