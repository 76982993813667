<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="atendent.id"
            text="Editar Técnico(a)"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar Técnico(a)"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Atendents/closeModalAtendent" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2">
          <BaseInput
            id="name"
            input-name="name"
            input-placeholder="nome"
            input-type="text"
            show-label
            label-text="nome"
            required
            :value="atendent.name"
            :error-message="getErrorMessage('name', atendent.name)"
            dispatch="Atendents/setName"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Município"
            :dispatch="setCityAtendents"
            required
          />
        </div>
        <div>
          <BaseInput
            id="cpf"
            input-name="cpf"
            input-placeholder="000.000.000-00"
            input-type="text"
            show-label
            label-text="cpf"
            required
            mask="###.###.###-##"
            :value="atendent.cpf"
            :error-message="getErrorMessage('cpf', atendent.cpf)"
            dispatch="Atendents/setCpf"
          />
        </div>
        <div>
          <BaseInput
            id="cellphone_number"
            input-name="cellphone_number"
            input-placeholder="(00) 00000-0000"
            input-type="text"
            show-label
            label-text="telefone"
            required
            mask="(##) #####.####"
            :value="atendent.cellphone_number"
            :error-message="getErrorMessage('phone_number', atendent.cellphone_number)"
            dispatch="Atendents/setCellphoneNumber"
          />
        </div>
        <div class="col-span-1">
          <BaseInput
            id="email"
            input-name="email"
            input-placeholder="exemplo@email.com"
            input-type="text"
            show-label
            label-text="E-mail"
            required
            :value="atendent.email"
            :error-message="getErrorMessage('email', atendent.email)"
            dispatch="Atendents/setEmail"
          />
        </div>
        <div class="col-span-1">
          <BaseInput
            id="cress_crp"
            input-name="email"
            input-placeholder="Informe o CRESS ou CRP"
            input-type="text"
            show-label
            label-text="Código CRESS ou CRP"
            required
            :value="atendent.number_cress_crp"
            :error-message="getErrorMessage('number_cress_crp', atendent.number_cress_crp)"
            dispatch="Atendents/setCressCrp"
          />
        </div>
        <div class="col-span-1">
          <Multiselect
            id="areas"
            select-placeholder="Selecione as opções"
            label-text="Vincular Áreas"
            :close-on-select="false"
            show-label
            :value="atendent.areas_attributes"
            :options="user_areas"
            :dispatch="setAreas"
            :error-message="getErrorMessage('areas', equipment.areas_attributes)"
            required
          />
        </div>
        <div class="col-span-1">
          <Multiselect
            id="equipamentos"
            select-placeholder="Selecione as opções"
            label-text="Vincular Equipamentos"
            :close-on-select="false"
            show-label
            :value="atendent.equipments"
            :options="equipments"
            :dispatch="setEquip"
            required
          />
        </div>
        <div v-if="!atendent.id">
          <BaseInput
            id="password"
            input-name="password"
            input-type="password"
            show-label
            label-text="Senha"
            required
            :value="atendent.password"
            :error-message="getErrorMessage('password', atendent.password)"
            dispatch="Atendents/setPassword"
          />
        </div>
        <div v-if="!atendent.id">
          <BaseInput
            id="password_confirmation"
            input-name="password"
            input-type="password"
            show-label
            label-text="Confirmação de Senha"
            required
            :value="atendent.password"
            :error-message="getErrorMessage('password', atendent.password_confirmation)"
            dispatch="Atendents/setPasswordConfirmation"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Atendents/closeModalAtendent" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="atendent.id"
            text="Salvar"
            typeButton="success"
            dispatch="Atendents/updateAtendent"
          />
          <AppButtonEmpty v-else text="criar" typeButton="success" dispatch="Atendents/saveAtendent" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseModal,
    Multiselect,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("Atendents/loadAreas");
    if (this.atendent.areas_attributes) {
      // this.$store.dispatch("Atendents/loadEquipments", this.atendent.areas_attributes);
    }
    this.$store.dispatch("City/loadCities");
    this.$store.dispatch("Neighborhoods/loadNeighborhoods");
    this.$store.dispatch("EquipmentAll/loadEquipment");
  },

  computed: {
    ...mapGetters({
      getErrorMessage: "Atendents/getErrorMessage",
      getAreas: "Atendents/getAreas",
    }),

    ...mapState({
      equipment: (state) => state.Equipment.equipment,
      atendent: (state) => state.Atendents.atendent,
      area: (state) => state.Atendents.atendent.areas_attributes,
      name: (state) => state.Atendents.name,
      areas: (state) => state.Atendents.areasData,
      equipments: (state) => state.Atendents.equipmentsData,
      state: (state) => state.Atendents.state,
      city: (state) => state.Atendents.city,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
      user_areas: (state) => state.UtilFunctions.user_areas,
    }),
  },
  methods: {
    ...mapActions({
      setAreas: "Atendents/setArea",
      setEquip: "Atendents/setEquip",
      setState: "Equipment/setState",
      setCity: "Equipment/setCity",
      setStateAtendents: "Atendents/setState",
      setCityAtendents: "Atendents/setCity",
      setNeighborhood: "Equipment/setNeighborhood",
    }),
    getLabelCity() {
      return this.state ? "Município" : "Selecione o estado para listar os municípios";
    },
  },
  watch: {
    area: function () {
      this.$store.dispatch("Atendents/loadEquipments", this.area);
    },
  },
};
</script>
