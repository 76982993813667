<template>
  <div class="m-5">
    <div id="person-data" class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-5">
        <BaseText text="Dados do Aluno(a)" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2" v-if="inscription">
        <AppTextField
          show-label
          label-text="Nome do Aluno(a)"
          :text="inscription.student.name"
          type-text="topic-details"
        />
      </div>
      <div v-if="inscription">
        <AppTextField
          show-label
          label-text="CPF"
          :text="inscription.student.cpf | VMask('###.###.###-##')"
          type-text="topic-details"
        />
      </div>
      <div v-if="inscription">
        <AppTextField
          show-label
          label-text="Data de Nascimento"
          :text="formattedDate(inscription.student.birthdate)"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="inscription">
        <AppTextField
          show-label
          label-text="Email"
          :text="inscription.student.email"
          type-text="topic-details"
        />
      </div>
      <div v-if="inscription">
        <AppTextField
          show-label
          label-text="Telefone"
          :text="inscription.student.cellphone_number | VMask('(##) #####-####')"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5">
        <BaseText text="Dados da Matriz" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div id="matrix-data" class="col-span-5">
        <div
          :key="item.psychosocial_accompaniment_id"
          :data-index="index"
          v-for="(
            item, index
          ) in matrix_risk_stratifications_by_inscription_filtered.matrix_risk_stratifications"
          class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12"
        >
          <div class="col-span-5">
            <AppTextField
              show-label
              label-text="Problema Identificado"
              :text="item.matrix_problem"
              type-text="topic-details"
            />
          </div>
          <div class="col-span-3">
            <AppTextField show-label label-text="Situação" :text="item.situation" type-text="topic-details" />
          </div>
          <div class="col-span-2">
            <AppTextField
              show-label
              label-text="Risco"
              :text="item.situation_risk"
              type-text="topic-details"
            />
          </div>
          <div class="col-span-3">
            <AppTextField show-label label-text="Impacto" :text="item.impact" type-text="topic-details" />
          </div>
          <div class="col-span-2">
            <AppTextField
              show-label
              label-text="Nível de Impacto"
              :text="item.impact_level"
              type-text="topic-details"
            />
          </div>
          <div class="col-span-5">
            <hr class="border-t-0 border-b-2 border-ashes opacity-50 col-span-5" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppTextField from "../../components/AppTextField.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppTextField,
  },
  computed: {
    ...mapState({
      inscription: (state) => state.PsychosocialAccompaniments.inscription,
      matrix_risk_stratifications_by_inscription_filtered: (state) =>
        state.MatrixRiskStratifications.matrix_risk_stratifications_by_inscription_filtered,
    }),
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    verifyEmail() {
      return this.inscription.student.email ? this.inscription.student.email : "Email não informado";
    },
    verifyEquipment() {
      return this.inscription.student.equipment
        ? this.inscription.student.equipment.name
        : "Equipamento não cadastrado";
    },
    verifyStatus() {
      return this.$util_function.processInscriptionStatuses(this.inscription?.status);
    },
  },
};
</script>
<style>
.active_new {
  background-color: #fff7ed;
  color: #fff;
  font-size: 1.1em;
}
</style>
