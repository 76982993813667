import { getAxios, baseURL } from "./Api";

export async function getFacilities(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `rede_de_atendimento`;
  return api.get(url, { params: filters });
}

export async function getFacilitiesForExportSpreadsheet(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `rede_de_atendimento/exportar_planilha`;
  return api.get(url, { params: filters });
}

export async function getFacilitiesByForwardingPolicy(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `rede_de_atendimento/rede_de_atendimento_por_politica_de_encaminhamento`;
  return api.get(url, { params: filters });
}

export async function getFacilityById(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/rede_de_atendimento/${id}`);
}

export async function createFacility(facility) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/rede_de_atendimento", facility);
}

export async function updateFacility(id, facility) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`rede_de_atendimento/${id}`, facility);
}

export async function destroyFacility(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`rede_de_atendimento/${id}`);
}
