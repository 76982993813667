<template>
  <div>
    <div id="title-page" class="mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl">Benefícios {{ titleStatusBenefits }}</div>
      <div id="menus" class="my-5 flex">
        <div>
          <a @click="changeTab('scholarship')" href="javascript:void(0)">
            <BaseText
              text="bolsa"
              typeText="custom"
              weight="semibold"
              mode="uppercase"
              size="sm"
              :color="setColorScholharship"
            />
          </a>
        </div>
        <div class="border-l mx-10"></div>
        <div>
          <a @click="changeTab('publicTransport')" href="javascript:void(0)">
            <BaseText
              text="vale-transporte"
              typeText="custom"
              weight="semibold"
              mode="uppercase"
              size="sm"
              :color="setColorPublicTransport"
            />
          </a>
        </div>
      </div>
      <div id="fee-button" class="flex justify-between items-center my-6">
        <div>
          <div v-show="tabBenefitType === 'scholarship'">
            <a href="javascript:void(0)" class="flex items-center" @click="toggleModalMatrixInfoOpen">
              <AppMaterialIcon iconName="table_view" iconType="outlined" class="text-burnedYellow mr-1" />
              <BaseText
                text="Ver matriz de valores"
                typeText="custom"
                weight="semibold"
                mode="normal"
                size="sm"
                color="burnedYellow"
                class="hover:underline"
              />
            </a>
          </div>
        </div>
        <div v-if="this.$route.params.status === 'pendentes'">
          <AppButtonColorful
            showIcon
            iconName="add"
            text="Pagamento de Bolsa"
            dispatch="Benefit/openModal"
            typeButton="primary"
            v-if="tabBenefitType === 'scholarship' && permit"
          />
          <AppButtonColorful
            showIcon
            iconName="add"
            text="Pagamento de Transporte"
            dispatch="Benefit/openModal"
            typeButton="primary"
            v-if="tabBenefitType === 'publicTransport' && permit"
          />
        </div>
      </div>
      <div id="list-benefits">
        <div>
          <BenefitsCards :data="getBenefits" />
        </div>
      </div>
      <ModalBenefit :open="modalBenefitOpen" />
      <DeleteBenefitConfirmation :open="openModalDelete" />
      <ModalMatrix :open="modalMatrixInfoOpen" />
    </div>
  </div>
</template>

<script>
import BaseText from "../components/BaseText.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BenefitsCards from "./list/BenefitsCards.vue";
import ModalBenefit from "./ModalBenefit.vue";
import DeleteBenefitConfirmation from "./alerts/DeleteBenefitConfirmation.vue";
import AppMaterialIcon from "../components/AppMaterialIcon.vue";
import ModalMatrix from "./infos/ModalMatrix.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppButtonColorful,
    BenefitsCards,
    ModalBenefit,
    DeleteBenefitConfirmation,
    AppMaterialIcon,
    ModalMatrix,
  },

  data: () => ({
    benefits: null,
    permit: false,
  }),

  methods: {
    ...mapActions({
      toggleModalMatrixInfoOpen: "Benefits/toggleModalMatrixInfoOpen",
      canAcces: "AccessControl/canAcces",
    }),
    permitChangeBenefts() {
      this.canAcces("benefit_create").then((resp) => {
        this.permit = resp;
      });
    },
    changeTab(value) {
      this.$store.dispatch("Benefits/setTabBenefitType", value);
    },
    itsPaidBenefits() {
      return this.$route.params.status === "pendentes" ? false : true;
    },
  },

  created() {
    this.$store.dispatch("Benefits/loadBenefits", this.$route.params.status);
    this.$store.dispatch("Benefits/loadSeasons");
    this.$store.dispatch("Benefit/clearModal");
    this.permitChangeBenefts();
  },

  computed: {
    ...mapState({
      city: (state) => state.City.city,
      tabBenefitType: (state) => state.Benefits.tabBenefitType,
      benefitsScholarship: (state) => state.Benefits.benefitsScholarship,
      benefitsPublicTransport: (state) => state.Benefits.benefitsPublicTransport,
      modalBenefitOpen: (state) => state.Benefit.modalBenefitOpen,
      openModalDelete: (state) => state.Benefit.modalDeleteBenefitConfirmationOpen,
      modalMatrixInfoOpen: (state) => state.Benefits.modalMatrixInfoOpen,
    }),

    titleStatusBenefits() {
      this.$store.dispatch("Benefits/loadBenefits", this.$route.params.status);
      return this.$route.params.status === "pendentes" ? "a Pagar" : "Pagos";
    },

    setColorScholharship() {
      return this.tabBenefitType === "scholarship" ? "burnedYellow" : "ashes-hover";
    },

    setColorPublicTransport() {
      return this.tabBenefitType === "publicTransport" ? "burnedYellow" : "ashes-hover";
    },

    getBenefits() {
      if (this.tabBenefitType === "scholarship") {
        return this.benefitsScholarship;
      } else {
        if (this.tabBenefitType === "publicTransport") {
          return this.benefitsPublicTransport;
        }
      }
      return [];
    },
  },
};
</script>
