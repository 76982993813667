<template>
  <div>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          text="Data da Postergação"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4">
        <BaseText
          text="Data Original de Expiração"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4">
        <BaseText
          text="Usuário Responsável"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4">
        <BaseText
          text="Observações"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li
        :key="forwarding_postponement_history.id"
        :data-index="index"
        v-for="(forwarding_postponement_history, index) in data"
      >
        <div>
          <AppCardForwardingPostponementHistoryByForwarding
            :forwarding_postponement_history="forwarding_postponement_history"
          />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardForwardingPostponementHistoryByForwarding from "../../components/AppCardForwardingPostponementHistoryByForwarding.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";

export default {
  components: {
    AppCardForwardingPostponementHistoryByForwarding,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 15% 19% 16% 5%;
}
</style>
