<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            text="Alocar Alunos"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Allocation/closeModal" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-flow-row auto-rows-max">
        <div class="grid grid-flow-col auto-cols-max gap-10">
          <div class="self-center text-ashes-dark mt-4">
            <BaseText text="Filtrar:" typeText="custom" weight="base" :italic="false" size="sm" />
          </div>
          <div class="w-72 mt-4">
            <Multiselect
              id="filterArea"
              selectPlaceholder="Selecionar"
              label-text="Área"
              closeOnSelect
              show-label
              :multipleOption="false"
              :value="area"
              :options="user_areas"
              :dispatch="setArea"
            />
          </div>
          <div class="w-72 mt-4">
            <Multiselect
              id="list-equipment"
              label-text="Equipamento"
              closeOnSelect
              show-label
              :multiple-option="false"
              :value="equipments"
              :options="equipment"
              :dispatch="setEquipment"
            />
          </div>
          <div class="w-72 mt-4">
            <BaseInput
              active
              inputName=""
              inputType="text"
              iconName="search"
              showIcon
              show-label
              label-text="Nome"
              dispatch="Allocation/filterList"
            />
          </div>
        </div>
        <div class="grid grid-flow-row auto-rows-max">
          <div
            :key="index"
            v-for="(student, index) in phaseOneStudents"
            class="mt-3 mr-5 app-checkbox grid grid-cols-5 border-b pb-2"
          >
            <div class="grid grid-flow-col auto-cols-max gap-6 place-items-center col-span-2">
              <div>
                <input
                  :id="checkboxId(student.name, index)"
                  type="checkbox"
                  name="students"
                  :value="student.id"
                  @click="onCheck(student, $event)"
                  :checked="student.checked"
                />
              </div>
              <div>
                <img src="../assets/images/profile-vj.png" alt="Maria Antônia Soares" class="w-16 h-16" />
              </div>
              <div>
                <BaseText
                  class="flex flex-wrap w-96"
                  :text="student.social_name ? student.name + ' - ' + student.social_name : student.name"
                  typeText="custom"
                  weight="semibold"
                  :italic="false"
                  mode="capitalize"
                  size="lg"
                  :target="checkboxId(student.name, index)"
                />
              </div>
            </div>
            <div class="place-self-center">
              <BaseLabel
                :text="typeof student.equipment != 'undefined' ? student.equipment.name : 'SEM EQUIPAMENTO'"
              />
            </div>
            <div class="place-self-center">
              <BaseLabel :text="getShift(student)" />
            </div>
            <div class="place-self-center">
              <BaseLabel :text="student.cellphone_number" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Allocation/closeModal" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty text="Aloca" typeButton="success" dispatch="Allocation/allocatePhaseOneStudents" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseLabel from "../components/BaseLabel.vue";
import BaseText from "../components/BaseText.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseModal,
    BaseLabel,
    BaseText,
    BaseInput,
    Multiselect,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: Boolean,
  },
  computed: {
    ...mapState({
      equipments: (state) => state.Allocation.filter.equipment,
      equipment: (state) => state.EquipmentAll.filteredEquipmentAll,
      phaseOneStudents: (state) => state.Allocation.filteredphaseOneStudents,
      areas: (state) => state.Areas.areas,
      area: (state) => state.Allocation.filter.area,
      user_areas: (state) => (state.user_areas.areas ? state.user_areas.areas : null),
    }),
  },
  created() {
    this.$store.dispatch("Allocation/loadStudentsForAllocation");
    this.$store.dispatch("EquipmentAll/loadEquipment");
    this.$store.dispatch("Equipment/clearEquipment");
    this.$store.dispatch("Areas/loadAreas");
  },
  beforeUpdate: function () {
    this.$store.dispatch("Allocation/cleanStudentsAttributesMutation");
  },
  methods: {
    ...mapActions({
      filterPhaseOneStudentsByArea: "Allocation/filterPhaseOneStudentsByArea",
      filterPhaseOneStudentsByShift: "Allocation/filterPhaseOneStudentsByShift",
      setEquipment: "Allocation/setEquipment",
      setArea: "Allocation/setArea",
    }),
    checkboxId(name, index) {
      return `${name}-${index}`;
    },
    onCheck(student, event) {
      if (event.target.checked) {
        this.$store.dispatch("Allocation/addStudent", student);
      } else {
        this.$store.dispatch("Allocation/removeStudent", student);
      }
    },
    getShift(student) {
      if (student.afternon_shift) {
        return "Tarde";
      }
      if (student.night_shift) {
        return "Noite";
      }
      return "Manhã";
    },
    getPicture(picture_profile) {
      return "../assets/images/" + picture_profile;
    },
  },
};
</script>
