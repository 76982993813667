<template v-if="permit">
  <div class="font-system">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">{{ city.name }} | Articuladores</div>
      <div class="mx-2 items-center justify-self-end" v-if="permitCreate">
        <AppButtonColorful
          id="btn-add-articulator"
          showIcon
          iconName="add"
          text="Cadastrar Articulador(a)"
          dispatch="Articulators/openModalAddArticulator"
          typeButton="primary"
          v-if="permitCreate"
        />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-4 justify-between p-4 items-end mx-12 my-6 gap-4">
      <div>
        <BaseInput
          active
          inputName=""
          inputType="text"
          iconName="search"
          showIcon
          input-placeholder="Pesquisar por nome"
          dispatch="Articulators/setFilterName"
        />
      </div>
      <div>
        <BaseInput
          active
          inputName=""
          inputType="text"
          iconName="search"
          showIcon
          input-placeholder="Pesquisar por cpf"
          dispatch="Articulators/setFilterCpf"
        />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-forwardings"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Articulators/loadArticulators"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="list-atendent">
      <div class="ml-12">
        <ArticulatorsCards :data="articulators" />
      </div>
      <ModalArticulator :open="modalToggleAddArticulator" />
      <DeleteArticulator :open="modalToggleDeleteArticulator" />
    </div>
  </div>
</template>

<script>
import ArticulatorsCards from "./list/ArticulatorsCards.vue";
import ModalArticulator from "./ModalArticulator.vue";
import DeleteArticulator from "./alerts/DeleteArticulator.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AppButtonColorful,
    ArticulatorsCards,
    ModalArticulator,
    DeleteArticulator,
    BaseInput,
  },
  data() {
    return {
      permitCreate: false,
      permit: false,
      permitUp: false,
    };
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
  },

  created() {
    this.$store.dispatch("Articulators/loadArticulators");
    this.canAcces("articulator_index").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("articulator_create").then((resp) => {
      this.permitCreate = resp;
    });
  },

  computed: {
    ...mapState({
      articulators: (state) => state.Articulators.articulators,
      modalToggleDeleteArticulator: (state) => state.Articulators.modalToggleDeleteArticulator,
      modalToggleAddArticulator: (state) => state.Articulators.modalToggleAddArticulator,
      modalResetPassword: (state) => state.Atendents.modalResetPassword,
      city: (state) => state.City.city,
    }),
  },
};
</script>
