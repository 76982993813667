<template>
  <div v-if="open">TESTE Diretrizes</div>
</template>

<script>
// import PsychosocialAccompanimentsCards from "../list/PsychosocialAccompanimentsCards.vue";
// import BasePagination from "../components/BasePagination.vue";
// import ModalForwarding from "./ModalForwarding.vue";
// import ModalViewForwarding from "./datas/ModalViewForwarding.vue";
// import DeleteForwarding from "./alerts/DeleteForwarding.vue";
// import AppButtonColorful from "../components/AppButtonColorful.vue";
// import BaseInput from "../components/forms/BaseInput.vue";
// import BaseLabel from "../components/BaseLabel.vue";
// import Multiselect from "../../components/forms/Multiselect.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    // BasePagination,
    // BaseLabel,
    // AppButtonColorful,
    // PsychosocialAccompanimentsCards,
    // ModalForwarding,
    // Multiselect,
    // ModalViewForwarding,
    // DeleteForwarding,
    // BaseInput,
  },
  data() {
    return {
      permitCreate: false,
      permit: false,
      permitUp: false,
      currentPage: 1,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      filterListByForwardingPolicy: "Forwardings/filterListByForwardingPolicy",
      setSeason: "PsychosocialAccompaniments/setSeason",
    }),
    clearFields() {
      this.$store.dispatch("Forwardings/clearQuery");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("PsychosocialAccompaniments/loadSeasons");
    this.canAcces("forwarding_create").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("forwarding_create").then((resp) => {
      this.permitCreate = resp;
    });
    this.canAcces("forwarding_index").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("forwarding_update").then((resp) => {
      this.permitUp = resp;
    });
  },

  computed: {
    ...mapState({
      psychosocial_accompaniments: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniments,
      modalConfirmDeletePsychosocialAccompaniment: (state) =>
        state.PsychosocialAccompaniments.modalConfirmDeletePsychosocialAccompaniment,
      modalTogglePsychosocialAccompaniments: (state) =>
        state.PsychosocialAccompaniments.modalTogglePsychosocialAccompaniments,
      modalViewPsychosocialAccompaniment: (state) => state.PsychosocialAccompaniments.modalViewForwarding,
      city: (state) => state.City.city,
      seasons: (state) => state.PsychosocialAccompaniments.seasons,
      season: (state) => state.PsychosocialAccompaniments.season,
      inscriptions: (state) => state.PsychosocialAccompaniments.inscriptions,

      offset: (state) => state.PsychosocialAccompaniments.offset,
      totalPerPage: (state) => state.PsychosocialAccompaniments.totalPerPage,
      totalPsychosocialAccompaniments: (state) => state.PsychosocialAccompaniments.forwardingTotal,
    }),
  },
  //   watch: {
  //     offset: function () {
  //       this.$store.dispatch("Forwardings/loadForwardings");
  //       this.backToTop();
  //     },
  //   },
  watch: {
    season: function () {
      this.$store.dispatch("PsychosocialAccompaniments/loadInscriptions");
    },
  },
};
</script>
