<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            text="Exportar frequência"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="ModalExportTeamPresence/closeModal" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-flow-row auto-rows-max">
        <div class="grid grid-flow-row auto-rows-max" v-if="!fileGenerated">
          <ExportTeamsPresenceCards :data="teamsToExportPresences" />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="mx-12 my-6" v-if="!fileGenerated">
        <AppButtonEmpty
          text="Gerar Planilha"
          typeButton="success"
          dispatch="ModalExportTeamPresence/generatePresencesToExport"
          :active="!isPeriodPresenceValid"
        />
      </div>
      <div class="grid grid-rows-2" v-if="fileGenerated">
        <div class="grid justify-center items-center">
          <Label
            text="Planilha gerada com sucesso!"
            :icon="null"
            :icon-type="null"
            type="text-2x1"
            weight="normal"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="grid grid-cols-2">
          <div class="justify-self-start">
            <AppButtonEmpty
              text="descartar"
              typeButton="danger"
              dispatch="ModalExportTeamPresence/cancelExport"
            />
          </div>

          <div class="justify-self-end">
            <download-excel
              type="xls"
              :meta="json_meta"
              :fields="json_fields"
              :data="json_data"
              :before-finish="closeModal"
              worksheet="Presencas"
              name="presences.xls"
              :escapeCsv="false"
            >
              <AppButtonEmpty text="Download da planilha" typeButton="success" />
            </download-excel>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import ExportTeamsPresenceCards from "./list/ExportTeamsPresenceCards";
import { mapActions, mapState } from "vuex";
// import Vue from "vue";

export default {
  components: {
    ExportTeamsPresenceCards,
    BaseModal,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    json_meta: [[{ key: "charset", value: "utf-8" }]],
  }),
  created() {
    this.$store.dispatch("Areas/loadAreas");
    this.$store.dispatch("Neighborhoods/loadNeighborhoods");
    this.$store.dispatch("EquipmentAll/loadEquipment");

    this.$store.dispatch("ModalExportTeamPresence/setArrayDataExport", []);
  },
  computed: {
    ...mapState({
      areas: (state) => state.Areas.areas,
      neighborhoods: (state) => state.Neighborhoods.neighborhoods,
      equipment: (state) => state.EquipmentAll.equipmentAll,
      filters: (state) => state.ModalExportTeamPresence.filters,
      teams: (state) => state.Teams.filteredAll,
      json_fields: (state) => state.ModalExportTeamPresence.json_fields,
      json_data: (state) => state.ModalExportTeamPresence.arrayDataExport,
      teamsExport: (state) => state.ModalExportTeamPresence.teamsExport,
      teamsToExportPresences: (state) => state.Teams.teamsToExportPresences,
      fileGenerated: (state) => state.ModalExportTeamPresence.fileGenerated,
      period_presence: (state) => state.ModalExportTeamPresence.period_presence,
      percentage_initial_date: (state) => state.ModalExportTeamPresence.percentage_initial_date,
      percentage_final_date: (state) => state.ModalExportTeamPresence.percentage_final_date,
    }),
    isPeriodPresenceValid() {
      return this.period_presence !== null;
    },
    isPercentageValidDates() {
      return (
        this.period_presence === 2 &&
        this.percentage_initial_date !== null &&
        this.percentage_final_date !== null
      );
    },
  },
  methods: {
    ...mapActions({
      filterArea: "ModalExportTeamPresence/filterArea",
      filterNeighborhood: "ModalExportTeamPresence/filterNeighborhood",
      filterEquipment: "ModalExportTeamPresence/filterEquipment",
      exportTeamPresence: "ModalExportTeamPresence/exportTeamPresence",
      closeModal: "ModalExportTeamPresence/closeModal",
    }),
    startDownload() {
      alert("show loading");
    },
  },
};
</script>
