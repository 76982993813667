<template>
  <div v-if="open">
    <div id="tabs-button" class="mt-3 ml-2">
      <FilterComponent>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Filtrar por Aluno(a)"
            clear-on-select
            select-placeholder="Digite o nome do aluno(a) para busca"
            :multiple-option="false"
            :value="inscription_filtered"
            :options="inscriptionsForGeneralMenus"
            :dispatch="filterListByInscription"
            load-dispatch="PsychosocialAccompaniments/loadInscriptionsForGeneralMenus"
          />
        </div>
        <div>
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Digite o cpf"
            show-label
            label-text="Pesquisar por cpf"
            dispatch="PsychosocialAccompaniments/filterListByCPF"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Filtrar por Tipo do Atendimento"
            clear-on-select
            select-placeholder="Tipo do Atendimento"
            :multiple-option="false"
            :value="type_attendance_filtered"
            :options="psychosocial_accompaniments_types"
            :dispatch="filterListByTypeAttendanceMainPage"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Filtrar por Status do Acompanhamento"
            clear-on-select
            select-placeholder="Status do Acompanhamento"
            :multiple-option="false"
            :value="status_accompaniment_filtered"
            :options="psychosocial_accompaniments_status"
            :dispatch="filterListByStatusAccompaniment"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Filtrar por Continuidade do Acompanhamento"
            clear-on-select
            select-placeholder="Acompanhamento vai continuar?"
            :multiple-option="false"
            :value="accompaniment_will_continue_filtered"
            :options="yes_or_no_select"
            :dispatch="filterListByAccompanimentWillContinue"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Filtrar por Nível de Gravidade"
            clear-on-select
            select-placeholder="Situação"
            :multiple-option="false"
            :value="type_situation_filtered"
            :options="type_situation_select"
            :dispatch="filterListByTypeSituation"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="filterListByCity"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Filtrar por Área"
            clear-on-select
            select-placeholder="Área"
            :multiple-option="false"
            :value="user_areas_filtered"
            :options="user_areas"
            :dispatch="setUserAreasFiltered"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Equipamento"
            :multiple-option="false"
            :value="user_equipments_filtered"
            :options="equipments_psychosocial"
            :label-text="getLabelEquipmentFilter()"
            :dispatch="filterListByEquipment"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Filtrar por Técnico"
            clear-on-select
            select-placeholder="Técnico"
            :multiple-option="false"
            :value="user_filtered"
            :options="users"
            :dispatch="filterListByUser"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :closeOnSelect="false"
            selectPlaceholder="Pesquisar por Matriz (Problemas)"
            show-label
            label-text="Filtrar por Problema [Matriz]"
            multipleOption
            :options="matrix_problems"
            :value="matrix_problems_filtered"
            :dispatch="filterListByMatrixProblems"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :closeOnSelect="false"
            selectPlaceholder="Pesquisar por Matriz (Situações)"
            multipleOption
            :options="matrix_situations"
            show-label
            label-text="Filtrar por Situação [Matriz]"
            label="situation"
            :value="matrix_situations_filtered"
            :dispatch="filterListByMatrixSituations"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Pesquisar por Tipo de Encaminhamento Institucional"
            clear-on-select
            select-placeholder="Encaminhamentos Institucionais"
            :multiple-option="false"
            :value="institutional_referral_filtered"
            :options="institutional_referrals"
            :dispatch="filterListByInstitutionalReferral"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Pesquisar por Status de Gravidez"
            clear-on-select
            select-placeholder="Gravidez"
            :multiple-option="false"
            :value="is_pregnant_filtered"
            :options="yes_or_no_select"
            :dispatch="filterListByIsPregnant"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Pesquisar por Status de Violência Autoprovocada"
            clear-on-select
            select-placeholder="Violência Autoprovocada"
            :multiple-option="false"
            :value="show_signs_of_self_harm_filtered"
            :options="yes_or_no_select"
            :dispatch="filterListByShowSignsOfSelfHarm"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Pesquisar por Status de Uso de Substâncias"
            clear-on-select
            select-placeholder="Uso de substâncias"
            :multiple-option="false"
            :value="uses_substances_filtered"
            :options="yes_or_no_select"
            :dispatch="filterListByUsesSubstances"
          />
        </div>
      </FilterComponent>
      <div class="mt-3 mb-2">
        <a href="javascript:void(0)" @click="exportToExcel()" v-if="inscriptions.length > 0">
          <img
            class="inline-block"
            src="../../assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
      </div>
      <BaseModal
        v-if="
          psychosocial_accompaniments_in_accompaniment_spreadsheet.length > 0 &&
          modalToggleExportPsychosocialAccompanimentInAccompanimentSpreadsheet
        "
      >
        <template v-slot:header>
          <div class="grid grid-cols-3">
            <div class="justify-self-start col-span-2">
              <Label
                text="Exportar Planilha de Inscritos - Acompanhados"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon
                dispatch="PsychosocialAccompaniments/closeExportPsychosocialAccompanimentInAccompanimentSpreadsheetModal"
                forClose
              />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="PsychosocialAccompaniments/closeExportPsychosocialAccompanimentInAccompanimentSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data_psychosocial_in_accompaniment"
                  :before-finish="closeExportPsychosocialAccompanimentInAccompanimentSpreadsheetModal"
                  worksheet="Inscritos - Acompanhados"
                  name="inscritos_acompanhados.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetPsychosocialAccompanimentInAccompanimentResponse">
        <PreLoading />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-inscriptions"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="PsychosocialAccompaniments/loadInscriptions"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="inscriptions.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-incident_reports"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-psychosocials">
      <div class="mx-12">
        <PsychosocialAccompanimentsInAccompanimentCards :data="inscriptions" />
      </div>
      <div
        id="psychosocial-acommpaniment-pagination"
        class="mx-12 my-6 pb-3"
        v-if="
          inscriptions.length > 0 &&
          (togglePsychosocialAccompanimentPendingReception || togglePsychosocialAccompanimentInAccompaniment)
        "
      >
        <BasePagination
          :total="inscriptionsTotal"
          :total-per-page="totalPerPage"
          dispatch="PsychosocialAccompaniments/setOffset"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PsychosocialAccompanimentsInAccompanimentCards from "../list/PsychosocialAccompanimentsInAccompanimentCards.vue";
import BasePagination from "../../components/BasePagination.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseText from "../../components/BaseText.vue";
import BaseModal from "../../components/BaseModal.vue";
import Label from "../../components/Label.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import Multiselect from "../../components/forms/Multiselect.vue";
import PreLoading from "../../components/PreLoading.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    BasePagination,
    AppButtonColorful,
    PsychosocialAccompanimentsInAccompanimentCards,
    Multiselect,
    BaseInput,
    BaseModal,
    BaseText,
    Label,
    AppButtonEmpty,
    AppButtonIcon,
    PreLoading,
  },
  data() {
    return {
      currentPage: 1,
      yes_or_no_select: [
        {
          id: 0,
          name: "Sim",
        },
        {
          id: 1,
          name: "Não",
        },
      ],
      type_situation_select: [
        {
          id: 0,
          name: "Moderada",
        },
        {
          id: 1,
          name: "Grave",
        },
        {
          id: 2,
          name: "Gravíssima",
        },
      ],
      json_fields: {
        Status: "status",
        "Nome completo do Jovem": "student_name",
        Área: "student_area",
        "Equipamento de Origem do Jovem": "student_equipment",
        "Situação do Jovem quanto ao cumprimento de medida": "last_socio_educational_measure",
        Idade: "student_age",
        "Percentual de Frequência": "student_percent",
        Vulnerabilidade: "situation_risk",
        Problemas: "matrix_problems",
        Situações: "matrix_situations",
        "Está estudando": "is_studying",
        "Razão para não estar estudando": "reason_for_not_studying",
        "Última série em que estudou": "last_grade_studied",
        "Última escola em que estudou": "last_school_studied",
        "Razão para ter largado os estudos": "reason_for_dropout_study",
        "Necessita de reinserção escolar": "need_school_reinsertion",
        Gravidez: "is_pregnant",
        "Violência Autoprovocada": "show_signs_of_self_harm",
        "Uso de substâncias": "uses_psychoactive_substances",
        "Profissionais que acompanham": "attendant_team",
        "Usuário(s) que registrou atendimento": "user",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setSeason: "PsychosocialAccompaniments/setSeason",
      setUserAreasFiltered: "PsychosocialAccompaniments/setUserAreasFiltered",
      filterListByStatusAccompaniment: "PsychosocialAccompaniments/filterListByStatusAccompaniment",
      filterListByInscription: "PsychosocialAccompaniments/filterListByInscription",
      filterListByAccompanimentWillContinue:
        "PsychosocialAccompaniments/filterListByAccompanimentWillContinue",
      closeExportPsychosocialAccompanimentInAccompanimentSpreadsheetModal:
        "PsychosocialAccompaniments/closeExportPsychosocialAccompanimentInAccompanimentSpreadsheetModal",
      filterListByTypeAttendanceMainPage: "PsychosocialAccompaniments/filterListByTypeAttendanceMainPage",
      filterListByEquipment: "PsychosocialAccompaniments/filterListByEquipment",
      filterListByTypeSituation: "PsychosocialAccompaniments/filterListByTypeSituation",
      filterListByUser: "PsychosocialAccompaniments/filterListByUser",
      filterListByState: "PsychosocialAccompaniments/filterListByState",
      filterListByCity: "PsychosocialAccompaniments/filterListByCity",
      filterListByMatrixSituations: "PsychosocialAccompaniments/filterListByMatrixSituations",
      filterListByMatrixProblems: "PsychosocialAccompaniments/filterListByMatrixProblems",
      filterListByIsPregnant: "PsychosocialAccompaniments/filterListByIsPregnant",
      filterListByShowSignsOfSelfHarm: "PsychosocialAccompaniments/filterListByShowSignsOfSelfHarm",
      filterListByUsesSubstances: "PsychosocialAccompaniments/filterListByUsesSubstances",
      filterListByInstitutionalReferral: "PsychosocialAccompaniments/filterListByInstitutionalReferral",
    }),
    async clearFields() {
      await this.$store.dispatch("PsychosocialAccompaniments/clearQueryMainPage");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    changeTab(value) {
      this.$store.dispatch("PsychosocialAccompaniments/setTabPsychosocialAccompanimentView", value);
    },
    getLabelEquipmentFilter() {
      return this.user_areas_filtered
        ? "Filtrar por Equipamento"
        : "Selecione a área para listar os equipamentos";
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
    async exportToExcel() {
      await this.$store.dispatch("PsychosocialAccompaniments/loadPsychosocialMaingPageSpreadsheet");
    },
  },
  created() {
    this.$store.dispatch("PsychosocialAccompaniments/loadSeasons");
    this.$store.dispatch("UtilFunctions/loadCities");
    this.$store.dispatch("PsychosocialAccompaniments/loadPsychosocialAccompanimentsStatus");
    this.$store.dispatch("PsychosocialAccompaniments/loadPsychosocialAccompanimentsTypes");
  },

  computed: {
    ...mapState({
      psychosocial_accompaniments: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniments,
      modalConfirmDeletePsychosocialAccompaniment: (state) =>
        state.PsychosocialAccompaniments.modalConfirmDeletePsychosocialAccompaniment,
      modalTogglePsychosocialAccompaniments: (state) =>
        state.PsychosocialAccompaniments.modalTogglePsychosocialAccompaniments,
      modalViewPsychosocialAccompaniment: (state) => state.PsychosocialAccompaniments.modalViewForwarding,
      city: (state) => state.PsychosocialAccompaniments.params.main_page.city,
      state: (state) => state.PsychosocialAccompaniments.params.main_page.state,
      seasons: (state) => state.PsychosocialAccompaniments.seasons,
      season: (state) => state.PsychosocialAccompaniments.season,
      inscriptions: (state) => state.PsychosocialAccompaniments.inscriptions,
      users: (state) => state.PsychosocialAccompaniments.users,
      psychosocial_accompaniments_status: (state) =>
        state.PsychosocialAccompaniments.psychosocial_accompaniments_status,
      matrix_situations: (state) => state.MatrixRiskStratifications.matrix_situations,
      matrix_problems: (state) => state.MatrixRiskStratifications.matrix_problems,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,

      user_areas: (state) => state.PsychosocialAccompaniments.user_areas,
      psychosocial_accompaniments_types: (state) =>
        state.PsychosocialAccompaniments.psychosocial_accompaniments_types,
      equipments_psychosocial: (state) => state.PsychosocialAccompaniments.equipments_psychosocial,
      user_filtered: (state) => state.PsychosocialAccompaniments.params.main_page.user,
      is_pregnant_filtered: (state) => state.PsychosocialAccompaniments.params.main_page.is_pregnant,
      show_signs_of_self_harm_filtered: (state) =>
        state.PsychosocialAccompaniments.params.main_page.show_signs_of_self_harm,
      uses_substances_filtered: (state) => state.PsychosocialAccompaniments.params.main_page.uses_substances,
      institutional_referrals: (state) => state.PsychosocialAccompaniments.institutional_referrals,
      institutional_referral_filtered: (state) =>
        state.PsychosocialAccompaniments.params.main_page.institutional_referral,
      inscriptionsForGeneralMenus: (state) => state.PsychosocialAccompaniments.inscriptionsForGeneralMenus,
      inscription_filtered: (state) => state.PsychosocialAccompaniments.params.main_page.inscription,

      user_areas_filtered: (state) => state.PsychosocialAccompaniments.user_areas_filtered,
      user_equipments_filtered: (state) => state.PsychosocialAccompaniments.params.main_page.equipment,
      status_accompaniment_filtered: (state) =>
        state.PsychosocialAccompaniments.params.main_page.status_accompaniment,
      accompaniment_will_continue_filtered: (state) =>
        state.PsychosocialAccompaniments.params.main_page.accompaniment_will_continue,
      type_attendance_filtered: (state) => state.PsychosocialAccompaniments.params.main_page.type_attendance,
      type_situation_filtered: (state) => state.PsychosocialAccompaniments.params.main_page.type_situation,
      matrix_situations_filtered: (state) =>
        state.PsychosocialAccompaniments.params.main_page.matrix_situations,
      matrix_problems_filtered: (state) => state.PsychosocialAccompaniments.params.main_page.matrix_problems,

      psychosocial_accompaniments_in_accompaniment_spreadsheet: (state) =>
        state.PsychosocialAccompaniments.psychosocial_accompaniments_in_accompaniment_spreadsheet,
      json_data_psychosocial_in_accompaniment: (state) =>
        state.PsychosocialAccompaniments.json_data_psychosocial_in_accompaniment,
      hasSpreadsheetPsychosocialAccompanimentInAccompanimentResponse: (state) =>
        state.PsychosocialAccompaniments.hasSpreadsheetPsychosocialAccompanimentInAccompanimentResponse,

      tabPsychosocialAccompanimentView: (state) =>
        state.PsychosocialAccompaniments.tabPsychosocialAccompanimentView,
      togglePsychosocialAccompanimentPendingReception: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentPendingReception,
      togglePsychosocialAccompanimentInAccompaniment: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentInAccompaniment,
      modalToggleExportPsychosocialAccompanimentInAccompanimentSpreadsheet: (state) =>
        state.PsychosocialAccompaniments.modalToggleExportPsychosocialAccompanimentInAccompanimentSpreadsheet,

      offset: (state) => state.PsychosocialAccompaniments.offset,
      totalPerPage: (state) => state.PsychosocialAccompaniments.totalPerPage,
      inscriptionsTotal: (state) => state.PsychosocialAccompaniments.inscriptionsTotal,
    }),
  },
  watch: {
    user_areas_filtered: function () {
      this.$store.dispatch("PsychosocialAccompaniments/loadEquipments");
    },
    offset: function () {
      this.$store.dispatch("PsychosocialAccompaniments/loadInscriptions");
      this.backToTop();
    },
  },
};
</script>
