import { getAxios, baseURL } from "./Api";

export async function getMatrixSituations(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `matriz_situacoes`;
  return api.get(url, { params: filters });
}

export async function getMatrixSituationsForMenus() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`matriz_situacoes/para_menus`);
}

export async function getMatrixSituationById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`matriz_situacoes/${_id}`);
}

export async function createMatrixSituation(matrix_situation) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/matriz_situacoes", matrix_situation);
}

export async function updateMatrixSituation(id, matrix_situation) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`matriz_situacoes/${id}`, matrix_situation);
}

export async function destroyMatrixSituation(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`matriz_situacoes/${id}`);
}
