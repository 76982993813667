<template>
  <div>
    <div class="grid grid-cols-7 mt-8">
      <div class="col-span-2">
        <BaseText
          text="nome do jovem"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div>
        <BaseText
          text="turma"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div>
        <BaseText
          text="fase"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div>
        <BaseText
          text="nº de encontros"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div>
        <BaseText
          text="% de Presenças"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
          v-if="!(benefit.type_benefit === 'public_transport')"
        />
      </div>
      <div>
        <BaseText
          text="valor"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
    </div>
    <div v-for="student in students" :key="student.id">
      <AppCardStudentBenefit :student="student" />
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppCardStudentBenefit from "../../components/AppCardStudentBenefit.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseText,
    AppCardStudentBenefit,
  },

  props: {
    students: Array,
  },

  computed: {
    ...mapState({
      benefit: (state) => state.Benefit.benefit,
    }),
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 30% 20% 20% 20% 20%;
}
</style>
