import { getAxios, baseURL } from "./Api";

export async function getAttendantTeams() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`equipes_atendimento`);
}

export async function createAttendantTeam(attendant_team) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/equipes_atendimento", attendant_team);
}

export async function updateAttendantTeam(id, attendant_team) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`equipes_atendimento/${id}`, attendant_team);
}

export async function destroyAttendantTeam(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`equipes_atendimento/${id}`);
}
