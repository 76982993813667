<template>
  <div>
    <button class="menu-toggle" @click="toggleSidebar">
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
    </button>
    <div class="view-sidebar" :class="{ 'is-active': isSidebarActive || isDesktop }">
      <SideBar />
    </div>
    <div class="view-topbar">
      <TopBar />
    </div>
    <div class="view-container">
      <router-view class="view"></router-view>
    </div>
    <div v-if="!this.finished_after_login">
      <PreLoading text="Aguarde, carregando informações..." textColor="#ff9633" />
    </div>
  </div>
</template>

<script>
import SideBar from "../components/SideBar.vue";
import TopBar from "../components/TopBar.vue";
import PreLoading from "@/components/PreLoading.vue";
import { mapState } from "vuex";

export default {
  components: {
    PreLoading,
    SideBar,
    TopBar,
  },
  data() {
    return {
      isSidebarActive: false,
      isDesktop: window.innerWidth > 768,
    };
  },
  computed: {
    ...mapState({
      finished_after_login: (state) => state.UtilFunctions.finished_after_login,
    }),
  },
  methods: {
    toggleSidebar() {
      this.isSidebarActive = !this.isSidebarActive;
    },
    handleResize() {
      this.isDesktop = window.innerWidth > 768;
    },
  },
  created() {
    this.$store.dispatch("UtilFunctions/setUserAreas");
    this.$store.dispatch("UtilFunctions/setUserCities");
    this.$store.dispatch("UtilFunctions/setUserEquipments");
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
.view-container {
  margin-left: 255px;
  transition: margin-left 0.3s ease;
}

.view-topbar {
  margin-left: 255px;
  transition: margin-left 0.3s ease;
}

.view-sidebar {
  width: 255px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  transition: transform 0.3s ease;
  transform: translateX(0);
}

.view-sidebar.is-active {
  transform: translateX(0);
}

.menu-toggle {
  display: none;
  flex-direction: column;
  background: transparent;
  border: none;
  cursor: pointer;
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 1000;
  padding: 10px;
}

.bar {
  width: 30px;
  height: 3px;
  background-color: #000;
  margin: 4px 0;
}

@media (max-width: 768px) {
  .view-container,
  .view-topbar {
    margin-left: 0;
  }

  .menu-toggle {
    display: flex;
  }

  .view-sidebar {
    transform: translateX(-100%);
  }

  .view-sidebar.is-active {
    transform: translateX(0);
  }
}
</style>
