import Axios from "axios";

export const baseURL = {
  virando_jogo_api: process.env.VUE_APP_VIRANDO_JOGO_API,
  virando_jogo_api_auth: process.env.VUE_APP_VIRANDO_JOGO_API_AUTH,
  cep_api: process.env.VUE_APP_CEP_API,
};

export const getAxios = ({ token }, urlApi, formData = false) => {
  let options = {};
  if (token) {
    options.Authorization = `Bearer ${token}`;
  }

  if (formData) {
    options["Content-Type"] = "multipart/form-data";
  }
  const api = Axios.create({ baseURL: urlApi, headers: options });
  api.defaults.headers.common["Content-Type"] = "application/json";
  api.interceptors.response.use(function (response) {
    if (response.headers["access-token"] && response.headers["client"]) {
      const authHeaders = {
        "access-token": response.headers["access-token"],
        client: response.headers["client"],
        uid: response.headers["uid"],
        expiry: response.headers["expiry"],
        "token-type": response.headers["token-type"],
      };

      if (response.config.url == "/auth/sign_in") {
        localStorage.setItem("authHeaders", JSON.stringify(authHeaders));
      }
    }
    return response;
  });
  api.interceptors.request.use(
    function (config) {
      const authHeaders = JSON.parse(localStorage.getItem("authHeaders"));
      if (authHeaders) {
        config.headers[config.method] = {
          "access-token": authHeaders["access-token"],
          client: authHeaders["client"],
          uid: authHeaders["uid"],
        };
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  return api;
};

export const getHttp = ({ token }, urlApi, formData = false) => {
  let options = {};
  if (token) {
    options.Authorization = `Bearer ${token}`;
  }

  if (formData) {
    options["Content-Type"] = "multipart/form-data";
  }
  const api = Axios.create({ baseURL: urlApi, headers: options });
  api.defaults.headers.common["Content-Type"] = "application/json";

  return api;
};
