import {
  createNeighborhood,
  destroyNeighborhood,
  getNeighborhoodById,
  getNeighborhoods,
  getNeighborhoodsByCityIdWithoutArea,
  updateNeighborhood,
} from "../../service/NeighborhoodsApi";
import Vue from "vue";

const state = {
  neighborhood: {
    id: null,
    name: null,
    area_id: null,
    city_id: null,
  },

  neighborhoods_spreadsheet: [],
  errorMessages: [],
  neighborhoodId: null,
  city: null,
  state: null,
  area: null,

  modalToggleAddNeighborhood: false,
  modalToggleDeleteNeighborhood: false,
  modalToggleViewNeighborhood: false,

  neighborhoodsTotal: 0,
  neighborhoodsTotalPerPage: 10,
  neighborhoodsOffset: 0,

  params: {
    name: null,
    city: null,
    area: null,
    state: null,
  },
  neighborhoods: [],
  neighborhoodsWithoutArea: [],
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadNeighborhoods: (state, neighborhoods) => {
    state.neighborhoods = neighborhoods;
  },
  setNeighborhoods: (state, neighborhoods) => {
    state.neighborhoods = neighborhoods;
  },
  setNeighborhoodsWithoutArea: (state, neighborhoods) => {
    state.neighborhoodsWithoutArea = neighborhoods;
  },
  // MUTATIONS - FIELDS TO API
  setIdMutation: (state, id) => {
    state.neighborhood.id = id;
  },
  setNameMutation: (state, name) => {
    state.neighborhood.name = name;
  },
  setStateMutation: (state, value) => {
    state.state = value;
  },
  setCityMutation: (state, city) => {
    state.city = city;
    state.neighborhood.city_id = city ? city.id : null;
  },
  setAreaMutation: (state, area) => {
    state.area = area;
    state.neighborhood.area_id = area ? area.id : null;
  },
  // --> MUTATIONS - DELETION <--
  neighborhoodForDeletion: (state, payload) => {
    if (payload) {
      state.neighborhoodId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },
  setFilterCityMutation: (state, city) => {
    state.params.city = city;
  },
  setFilterStateMutation: (state, value) => {
    state.params.state = value;
  },
  setFilterAreaMutation: (state, area) => {
    state.params.area = area;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddNeighborhood: (state) => {
    state.modalToggleAddNeighborhood = !state.modalToggleAddNeighborhood;
  },
  toggleModalDeleteNeighborhood: (state) => {
    state.modalToggleDeleteNeighborhood = !state.modalToggleDeleteNeighborhood;
  },
  toggleModalViewNeighborhood: (state) => {
    state.modalToggleViewNeighborhood = !state.modalToggleViewNeighborhood;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setNeighborhoodsTotalPerPage: (state, neighborhoodsTotalPerPage) => {
    state.neighborhoodsTotalPerPage = neighborhoodsTotalPerPage;
  },
  setNeighborhoodsOffset: (state, neighborhoodsOffset) => {
    state.neighborhoodsOffset = neighborhoodsOffset;
  },
  setNeighborhoodsTotal: (state, neighborhoodsTotal) => {
    state.neighborhoodsTotal = neighborhoodsTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.neighborhood) {
      state.neighborhood[prop] = null;
    }
    state.city = null;
    state.state = null;
    state.area = null;
  },
  clearQuery: (state) => {
    state.params.name = null;
    state.params.city = null;
    state.params.area = null;
    state.params.state = null;
    state.neighborhoods = [];
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

function getFilterParams(rootGetters) {
  return {
    name: state.params.name,
    city_id:
      state.params.city && state.params.city.id
        ? state.params.city.id
        : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
        ? rootGetters["UtilFunctions/cityIds"]
        : null,
    area_id: state.params.area ? state.params.area.id : null,
    per_page: state.neighborhoodsTotalPerPage,
    offset: state.neighborhoodsOffset,
  };
}

const actions = {
  setNeighborhoodsWithoutArea: ({ commit }, payload) => {
    commit("setNeighborhoodsWithoutArea", payload);
  },
  setNeighborhoods: ({ commit }, payload) => {
    commit("setNeighborhoods", payload);
  },
  // ACTIONS - LOADS DATA FROM API
  loadNeighborhoods: ({ commit, rootGetters }, from) => {
    const params = getFilterParams(rootGetters);
    getNeighborhoods(params)
      .then((response) => {
        commit("loadNeighborhoods", response.data.neighborhoods);
        commit("setNeighborhoodsTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadNeighborhoodsWithoutArea: ({ commit }, city_id) => {
    getNeighborhoodsByCityIdWithoutArea(city_id)
      .then((response) => {
        commit("setNeighborhoodsWithoutArea", response.data.neighborhoods);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadData: async ({ commit }, payload) => {
    await getNeighborhoodById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.neighborhoods.id);
        commit("setNameMutation", response.data.neighborhoods.name);
        commit("setCityMutation", response.data.neighborhoods.city);
        commit("setAreaMutation", response.data.neighborhoods.area);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },
  // ACTIONS - FIELDS TO API
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setState: ({ commit }, payload) => {
    commit("setStateMutation", payload);
  },
  setCity: ({ commit }, payload) => {
    commit("setCityMutation", payload);
  },
  setArea: ({ commit }, payload) => {
    commit("setAreaMutation", payload);
  },
  // ACTIONS - TOGGLES AND MODALS
  openModalAddNeighborhood: ({ commit }) => {
    commit("toggleModalAddNeighborhood");
  },
  closeModalAddNeighborhood: ({ commit }) => {
    commit("toggleModalAddNeighborhood");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openEditModalAddNeighborhood: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddNeighborhood");
  },
  closeEditModalAddNeighborhood: ({ commit }) => {
    commit("toggleModalAddNeighborhood");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewNeighborhood");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewNeighborhood");
    commit("clearFields");
  },
  toggleModalDeleteNeighborhood: ({ commit }, payload) => {
    commit("toggleModalDeleteNeighborhood");
    if (state.modalToggleDeleteNeighborhood && payload) {
      commit("neighborhoodForDeletion", payload);
    } else {
      commit("neighborhoodForDeletion", null);
    }
  },
  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  setFilterState: ({ commit }, payload) => {
    commit("setFilterStateMutation", payload);
  },
  setFilterCity: ({ commit }, payload) => {
    commit("setFilterCityMutation", payload);
  },
  setFilterArea: ({ commit }, payload) => {
    commit("setFilterAreaMutation", payload);
  },

  // --> ACTIONS - PAGINATIONS <--
  setNeighborhoodsTotalPerPage: ({ commit }, payload) => {
    commit("setNeighborhoodsTotalPerPage", payload.totalPerPage);
  },
  setNeighborhoodsOffset: ({ commit }, payload) => {
    commit("setNeighborhoodsOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },

  //ACTIONS - CRUD
  save: ({ commit, dispatch }) => {
    createNeighborhood(state.neighborhood)
      .then(() => {
        Vue.$toast.success("Bairro cadastrado com sucesso!");
        dispatch("closeModalAddNeighborhood");
        dispatch("loadNeighborhoods");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar o bairro! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  update: ({ commit, dispatch, state }) => {
    updateNeighborhood(state.neighborhood.id, state.neighborhood)
      .then(() => {
        dispatch("loadNeighborhoods");
        dispatch("closeEditModalAddNeighborhood");
        Vue.$toast.success("Bairro atualizado com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar o bairro! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyNeighborhood(state.neighborhoodId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Bairro deletado com sucesso!");
        commit("toggleModalDeleteNeighborhood");
        dispatch("loadNeighborhoods", from);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
