<template>
  <div class="m-5">
    <div id="person-data" class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-5">
        <BaseText text="Dados da Atividade" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Tipo da Atividade"
          :text="activity_type ? activity_type.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Tipo do Resultado"
          :text="result_type ? result_type.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Data da Atividade"
          :text="formattedDate(school_reinsertion.reinsertion_date)"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Articulador(a) Responsável"
          :text="school_reinsertion.articulator ? school_reinsertion.articulator : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="result_type?.id === 1 || activity_type?.id === 2">
        <AppTextField
          show-label
          label-text="Data da Entrega da Carta"
          :text="formattedDate(school_reinsertion.letter_delivery_date)"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="result_type?.id === 1 || activity_type?.id === 2">
        <AppTextField
          show-label
          label-text="Data de Retorno da Carta"
          :text="formattedDate(school_reinsertion.return_letter_deadline)"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="result_type?.id === 0">
        <AppTextField
          show-label
          label-text="Motivo para Não Reinserção"
          :text="reasons_for_non_reinsertion ? reasons_for_non_reinsertion.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="result_type?.id === 2 || activity_type?.id === 3">
        <AppTextField
          show-label
          label-text="Data para/de Anexo de Comprovante de Matrícula"
          :text="formattedDate(school_reinsertion.deadline_attach_proof_of_enrollment)"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="result_type?.id === 0">
        <AppTextField
          show-label
          label-text="Encaminhamentos [Antigo]"
          :text="school_reinsertion.forwardings ? school_reinsertion.forwardings : 'Nada informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="mustShowFacilityField()">
        <AppTextField
          show-label
          :label-text="returnIfIsForwardingOrFacilityLabel()"
          :text="facility ? facility.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="result_type?.id === 2">
        <AppTextField
          show-label
          label-text="Turno"
          :text="shift ? shift.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="result_type?.id === 2">
        <AppTextField
          show-label
          label-text="Ano/Série"
          :text="serie ? serie.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="result_type?.id === 2">
        <AppTextField
          show-label
          label-text="Modalidade de Ensino"
          :text="teaching_modality ? teaching_modality.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="result_type?.id === 2">
        <AppTextField
          show-label
          label-text="Data da Matrícula"
          :text="formattedDate(school_reinsertion.enrollment_date)"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="Observações"
          :text="school_reinsertion.observations ? school_reinsertion.observations : 'Não informado'"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppTextField from "../../components/AppTextField.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppTextField,
  },
  computed: {
    ...mapState({
      inscription: (state) => state.SchoolReinsertions.inscription,
      school_reinsertion: (state) => state.SchoolReinsertions.school_reinsertion,
      activity_type: (state) => state.SchoolReinsertions.activity_type,
      result_type: (state) => state.SchoolReinsertions.result_type,
      serie: (state) => state.SchoolReinsertions.serie,
      shift: (state) => state.SchoolReinsertions.shift,
      teaching_modality: (state) => state.SchoolReinsertions.teaching_modality,
      articulator: (state) => state.SchoolReinsertions.articulator,
      reasons_for_non_reinsertion: (state) => state.SchoolReinsertions.reasons_for_non_reinsertion,
      facility: (state) => state.SchoolReinsertions.facility,
    }),
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    returnIfIsForwardingOrFacilityLabel() {
      return this.activity_type?.id === 4 || this.result_type?.id === 0 ? "Encaminhamentos" : "Escola";
    },
    mustShowFacilityField() {
      return (
        this.result_type?.id === 2 ||
        this.activity_type?.id === 3 ||
        this.activity_type?.id === 2 ||
        this.activity_type?.id === 4 ||
        this.result_type?.id === 0 ||
        this.result_type?.id === 1 ||
        this.result_type?.id === 3
      );
    },
    getStyles(type) {
      let headerColor = "";
      let colorText = "";
      switch (this.school_reinsertion.activity_type) {
        case 0:
          headerColor = "#FB7185";
          break;
        case 1:
          headerColor = "cyan";
          colorText = "cyan-clear";
          break;
        case 2:
          headerColor = "#475569";
          colorText = "ashes-dark";
          break;
        case 3:
          headerColor = "herbal-lemon";
          colorText = "herbal";
          break;
      }
      if (type === "headerColor") return headerColor;
      if (type === "colorText") return colorText;
    },
  },
};
</script>
<style>
.active_new {
  background-color: #fff7ed;
  color: #fff;
  font-size: 1.1em;
}
</style>
