<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            text="Retorno do Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Forwardings/closeReturnModal" forClose />
        </div>
        <div class="justify-self-start col-span-2">
          <BaseText
            :text="getUserName()"
            typeText="custom"
            weight="semibold"
            color="herbal"
            size="sm"
            class="mr-1"
          />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2 mt-4" v-if="forwarding.id && inscription">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Dados de Cadastro do Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <BaseText
            :text="getUserNameTitle()"
            typeText="custom"
            weight="semibold"
            color="burnedYellow-classic"
            size="sm"
            class="mr-1"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Nome Aluno(a)"
            :text="inscription.student.name"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Edição"
            :text="inscription.season.name"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="CPF"
            v-bind:text="inscription.student.cpf | VMask('###.###.###-##')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Status do Encaminhamento"
            :text="forwarding_status.name"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Política do Encaminhamento"
            :text="forwarding_policy.name"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Equipamento do Encaminhamento"
            :text="forwarding.forwarding_equipment"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Data de cadastro do Encaminhamento"
            :text="formattedDate(forwardingDate)"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Data de Expiração do Encaminhamento"
            :text="formattedDate(forwardingExpirationDate)"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2">
          <AppTextField
            show-label
            label-text="Descrição"
            :text="getDescription()"
            type-text="topic-details"
          />
        </div>
      </div>
      <!-- <div class="grid grid-cols-2 mt-4" v-if="forwarding.id && psychosocial_accompaniment">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações do Acompanhamento Psicossocial"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Data do Encaminhamento"
            :text="formattedDate(psychosocial_accompaniment.date_accompaniment)"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Encontro aconteceu?"
            :text="verifyMeetingHappened()"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Formato do Encontro"
            :text="psychosocial_accompaniment.meeting_format"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Local do Encontro"
            :text="psychosocial_accompaniment.meeting_place"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Encaminhado?"
            :text="verifyIsForwarded()"
            type-text="topic-details"
          />
        </div>
      </div> -->
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Editar informações de Retorno do Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione a opção"
            label-text="Status do Encaminhamento"
            :multiple-option="false"
            :value="forwarding_status"
            :options="getForwardingStatuses"
            required
            :dispatch="setForwardingStatus"
          />
        </div>
        <div v-if="forwarding_status.name === 'Não Efetivado'">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione a opção"
            label-text="Motivo da Não Efetivação"
            :multiple-option="false"
            :value="forwarding_return_reason_of_unrealized"
            :options="forwarding_return_reasons_of_unrealized"
            :dispatch="setForwardingReasonOfUnrealized"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="return_date"
            input-type="date"
            :value="forwarding.forwarding_return_date"
            show-label
            :readonly="true"
            label-text="Data de Retorno"
            dispatch="Forwardings/setForwardingReturnDate"
          />
        </div>
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Conclusão do Retorno do Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <BaseTextArea
            active
            inputName="comments"
            labelText="Comentários"
            showLabel
            rows="4"
            :value="forwarding.forwarding_return_comments"
            dispatch="Forwardings/setForwardingReturnComments"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Forwardings/closeReturnModal" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty text="Salvar" typeButton="success" dispatch="Forwardings/update" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import AppTextField from "../components/AppTextField.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseModal from "../components/BaseModal.vue";
import BaseText from "../components/BaseText.vue";
import BaseTextArea from "../components/forms/BaseTextArea.vue";
import Label from "../components/Label.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    AppTextField,
    BaseModal,
    BaseInput,
    BaseText,
    BaseTextArea,
    Label,
    Multiselect,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("Forwardings/loadForwardingPolicies");
    this.$store.dispatch("Forwardings/loadForwardingStatuses");
    this.$store.dispatch("Forwardings/loadForwardingReasonsOfUnrealized");
    this.$store.dispatch("Forwardings/loadSeasons");
  },
  computed: {
    ...mapState({
      forwarding: (state) => state.Forwardings.forwarding,
      forwardingDate: (state) => state.Forwardings.forwarding.forwarding_date,
      forwardingExpirationDate: (state) => state.Forwardings.forwarding.forwarding_expiration_date,
      differenceBetweenDates: (state) => state.Forwardings.differenceBetweenDates,
      forwarding_policies: (state) => state.Forwardings.forwarding_policies,
      forwarding_policy: (state) => state.Forwardings.forwarding_policy,
      inscriptions: (state) => state.Forwardings.inscriptions,
      inscription: (state) => state.Forwardings.inscription,
      seasons: (state) => state.Forwardings.seasons,
      season: (state) => state.Forwardings.season,
      psychosocial_accompaniments: (state) => state.Forwardings.psychosocial_accompaniments,
      psychosocial_accompaniment: (state) => state.Forwardings.psychosocial_accompaniment,
      forwarding_user: (state) => state.Forwardings.forwarding_user,
      forwarding_return_user: (state) => state.Forwardings.forwarding_return_user,
      forwarding_statuses: (state) => state.Forwardings.forwarding_statuses,
      forwarding_status: (state) => state.Forwardings.forwarding_status,
      forwarding_return_reasons_of_unrealized: (state) =>
        state.Forwardings.forwarding_return_reasons_of_unrealized,
      forwarding_return_reason_of_unrealized: (state) =>
        state.Forwardings.forwarding_return_reason_of_unrealized,
    }),
    getForwardingStatuses() {
      let forwarding_statuses = [];
      this.forwarding_statuses.forEach((item) => {
        if (item.id != 0) {
          forwarding_statuses.push(item);
        }
      });
      return forwarding_statuses;
    },
  },
  methods: {
    ...mapActions({
      setForwardingPolicy: "Forwardings/setForwardingPolicy",
      setInscription: "Forwardings/setInscription",
      setSeason: "Forwardings/setSeason",
      setForwardingStatus: "Forwardings/setForwardingStatus",
      setForwardingReasonOfUnrealized: "Forwardings/setForwardingReasonOfUnrealized",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    verifyMeetingHappened() {
      return this.psychosocial_accompaniment.meeting_happened ? "Sim" : "Não";
    },
    getDescription() {
      if (this.forwarding.forwarding_description) {
        return this.forwarding.forwarding_description;
      } else {
        return "Não informado.";
      }
    },
    verifyIsForwarded() {
      return this.psychosocial_accompaniment.forwarded ? "Sim" : "Não";
    },
    getUserName() {
      if (this.forwarding_return_user.userName) {
        return `Usário(a) responsável pelo retorno: ${this.forwarding_return_user.userName}`;
      } else {
        return `Usário(a) responsável pelo retorno: ${this.forwarding_return_user.name}`;
      }
    },
    getUserNameTitle() {
      if (this.forwarding_user.userName) {
        return ` - Cadastrado por: ${this.forwarding_user.userName}`;
      } else {
        return ` - Cadastrado por: ${this.forwarding_user.name}`;
      }
    },
    verifyStatus() {
      return this.$util_function.processInscriptionStatuses(this.inscription?.status);
    },
    verifyInscription(type) {
      if (this.inscription) {
        if (type === "cpf" && this.inscription.cpf) {
          return this.inscription.cpf;
        } else if (type === "birthdate" && this.inscription.birthdate) {
          return this.formattedDate(this.inscription.birthdate);
        } else if (type === "cellphone_number" && this.inscription.cellphone_number) {
          return this.inscription.cellphone_number;
        } else if (type === "age" && this.inscription.age) {
          return this.inscription.age + " anos";
        } else if (type === "equipment" && this.inscription.equipment) {
          return this.inscription.equipment.name;
        }
      }
    },
    addOrRemoveDays() {
      const currentDate = moment(this.forwardingDate)
        .add(this.differenceBetweenDates, "days")
        .format("YYYY-MM-DD");
      this.$store.commit("Forwardings/setForwardingExpirationDateMutation", currentDate);
    },
    calculateDifferenceBetweenDates() {
      const forwardingDate = moment(this.forwardingDate);
      const forwardingExpirationDate = moment(this.forwardingExpirationDate);

      let days = forwardingExpirationDate.diff(forwardingDate, "days");
      this.$store.commit("Forwardings/setDifferenceBetweenDatesMutation", days);
    },
    getFullDate() {
      moment.locale("pt");
      return moment(this.forwardingExpirationDate).format("LLLL");
    },
  },

  watch: {
    differenceBetweenDates: function () {
      this.addOrRemoveDays();
    },
    forwardingExpirationDate: function () {
      this.calculateDifferenceBetweenDates();
    },
  },
};
</script>
