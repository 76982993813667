import { getAxios, baseURL } from "./Api";

export async function updatePresence(id, presence) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`presencas/${id}`, { presence: presence });
}

export async function getPresenceByTeamStudentDate(team_id, student_id, date) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let url = `/presencas`;

  return api.get(url, { params: { team_id: team_id, student_id: student_id, date: date } });
}

export async function destroyPresence(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`presencas/${id}`);
}
