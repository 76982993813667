import Vue from "vue";
import { isEmpty } from "lodash";
import { getEquipmentBySeasonId } from "../../service/EquipmentApi";
import { createTeam, updateTeam, showTeam, getShifts } from "../../service/TeamApi";
import { getTeachersByCityId } from "../../service/TeacherApi";
import router from "../../router/index";

const teamAllocate = {
  id: null,
  code: null,
  start: null,
  closing: null,
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  shift: null,
  vacancy: null,
  discipline: null,
  equipment: null,
  teacher: null,
};

const state = {
  ...teamAllocate,
  season: null,

  modalTeamAllocateOpen: false,
  equipmentAll: [],
  shifts: [],
  teachers: [],
  errorMessages: [],
};

const mutations = {
  setIdMutation: (state, id) => {
    state.id = id;
  },
  setCodeMutation: (state, code) => {
    state.code = code;
  },
  setStartMutation: (state, start) => {
    state.start = start;
  },
  setClosingMutation: (state, closing) => {
    state.closing = closing;
  },
  setMondayMutation: (state, monday) => {
    state.monday = monday;
  },
  setTuesdayMutation: (state, tuesday) => {
    state.tuesday = tuesday;
  },
  setWednesdayMutation: (state, wednesday) => {
    state.wednesday = wednesday;
  },
  setThursdayMutation: (state, thursday) => {
    state.thursday = thursday;
  },
  setFridayMutation: (state, friday) => {
    state.friday = friday;
  },
  setShiftMutation: (state, shift) => {
    state.shift = shift;
  },
  setVacancyMutation: (state, vacancy) => {
    state.vacancy = vacancy;
  },
  setSeasonMutation: (state, season) => {
    state.season = season;
  },
  toggleModalMutation: (state) => {
    state.modalTeamAllocateOpen = !state.modalTeamAllocateOpen;
  },
  setDisciplineMutation: (state, discipline) => {
    state.discipline = discipline;
  },
  setEquipmentMutation: (state, equipment) => {
    state.equipment = equipment;
  },
  setTeacherMutation: (state, teacher) => {
    state.teacher = teacher;
  },
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
  loadEquipmentMutation: (state, equipment) => {
    state.equipmentAll = equipment;
  },
  loadShiftsMutation: (state, shifts) => {
    state.shifts = shifts;
  },
  loadTeachersMutation: (state, teachers) => {
    state.teachers = teachers;
  },
};

const actions = {
  setStart: ({ commit }, payload) => {
    commit("setStartMutation", payload.value);
  },
  setClosing: ({ commit }, payload) => {
    commit("setClosingMutation", payload.value);
  },
  setDiscipline: ({ commit }, payload) => {
    commit("setDisciplineMutation", payload);
  },
  setDayWeek: ({ commit, state }, payload) => {
    switch (payload.value) {
      case "monday":
        commit("setMondayMutation", !state.monday);
        break;
      case "tuesday":
        commit("setTuesdayMutation", !state.tuesday);
        break;
      case "wednesday":
        commit("setWednesdayMutation", !state.wednesday);
        break;
      case "thursday":
        commit("setThursdayMutation", !state.thursday);
        break;
      case "friday":
        commit("setFridayMutation", !state.friday);
        break;
    }
  },
  setShift: ({ commit }, payload) => {
    commit("setShiftMutation", payload);
  },
  setVacancy: ({ commit }, payload) => {
    commit("setVacancyMutation", payload.value);
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },
  setEquipment: ({ commit }, payload) => {
    commit("setEquipmentMutation", payload);
  },
  setTeacher: ({ commit }, payload) => {
    commit("setTeacherMutation", payload);
  },
  showTeam: ({ dispatch }, id) => {
    showTeam(id)
      .then((response) => {
        dispatch("load", response.data.team);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadEquipment: ({ commit, rootState }) => {
    getEquipmentBySeasonId(rootState.Season.season.id)
      .then((response) => {
        commit("loadEquipmentMutation", response.data.equipment);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadShifts: ({ commit }) => {
    getShifts()
      .then((response) => {
        commit("loadShiftsMutation", response.data.shifts);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadTeachers: ({ commit, rootState }) => {
    getTeachersByCityId(rootState.City.city.id)
      .then((response) => {
        commit("loadTeachersMutation", response.data.teachers);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  load: ({ commit }, payload) => {
    if (payload && !isEmpty(payload)) {
      commit("setIdMutation", payload.id);
      commit("setCodeMutation", payload.code);
      commit("setStartMutation", payload.start);
      commit("setClosingMutation", payload.closing);
      commit("setMondayMutation", payload.monday);
      commit("setTuesdayMutation", payload.tuesday);
      commit("setWednesdayMutation", payload.wednesday);
      commit("setThursdayMutation", payload.thursday);
      commit("setFridayMutation", payload.friday);
      commit("setShiftMutation", payload.shift);
      commit("setVacancyMutation", payload.vacancy);
      commit("setDisciplineMutation", payload.discipline);
      commit("setEquipmentMutation", payload.equipment);
      commit("setTeacherMutation", payload.teacher);
      commit("setSeasonMutation", payload.season);
    } else {
      commit("setIdMutation", null);
      commit("setCodeMutation", null);
      commit("setStartMutation", null);
      commit("setClosingMutation", null);
      commit("setMondayMutation", null);
      commit("setTuesdayMutation", null);
      commit("setWednesdayMutation", null);
      commit("setThursdayMutation", null);
      commit("setFridayMutation", null);
      commit("setShiftMutation", null);
      commit("setVacancyMutation", null);
      commit("setDisciplineMutation", null);
      commit("setEquipmentMutation", null);
      commit("setTeacherMutation", null);
    }
  },
  openModal: ({ commit, dispatch }) => {
    dispatch("load", teamAllocate);
    commit("toggleModalMutation");
  },
  openEditModal: ({ commit, dispatch }, payload) => {
    dispatch("load", payload);
    commit("toggleModalMutation");
  },
  closeModal: ({ commit, dispatch }) => {
    commit("toggleModalMutation");
    dispatch("load", teamAllocate);
  },
  saveTeam: ({ commit, dispatch, getters, rootState }) => {
    const team = getters.getTeamForApi;

    const teamFinish = {
      id: rootState.Team.id,
    };

    createTeam(team, teamFinish)
      .then((response) => {
        Vue.$toast.success(response.data.message);
        dispatch("Teams/addTeam", response.data.team, { root: true });
        dispatch("Teams/removeTeam", teamFinish, { root: true });
        dispatch("closeModal");
        router.push({ name: "season", params: { id: rootState.Team.season.id } });
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel cadastrar a turma! " +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );

        console.log(e.response.data.errors);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  updateTeam: ({ commit, dispatch, getters }) => {
    const team = getters.getTeamForApi;

    updateTeam(team.id, team)
      .then((response) => {
        dispatch("Teams/updateTeam", response.data.team, { root: true });

        Vue.$toast.success(response.data.message);
        dispatch("closeModal");
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel cadastrar a turma! " +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );

        console.log(e.response.data.errors);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
};

const getters = {
  getTeamForApi: (state, getters, rootState) => {
    let team = {
      ...rootState.TeamAllocate,
      shift: rootState.TeamAllocate.shift?.id,
      discipline_id: rootState.TeamAllocate.discipline?.id,
      equipment_id: rootState.TeamAllocate.equipment?.id,
      teacher_id: rootState.TeamAllocate.teacher?.id,
      season_id: rootState.Team.season?.id,
    };

    delete team.id;
    delete team.discipline;
    delete team.equipment;
    delete team.teacher;
    delete team.code;
    delete team.equipmentAll;
    delete team.teachers;
    delete team.shifts;
    delete team.modalTeamOpen;
    delete team.errorMessages;

    return team;
  },
  getErrorMessage: (state) => (key, field) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      if (error.type === "blank") {
        if (field === "" || field === " " || field === undefined || field === null) {
          return error.message;
        } else {
          return null;
        }
      } else {
        return error.message;
      }
    } else {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
