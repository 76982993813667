import { getAxios, baseURL } from "./Api";

export async function getInscriptionsBySeasonId(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/inscricoes/pesquisar`;
  return api.post(url, filters);
}

export async function getInscriptionsBySeasonIdToExport(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/inscricoes/exportar_planilha`;
  return api.post(url, filters);
}

export async function updateInscription(id, inscription) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`/inscricoes/${id}`, { inscriptions: inscription });
}

export async function getInscriptionByFilters(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `inscricoes/filters`;
  return api.get(url, { params: filters });
}

export async function getInscriptionBySeasonAndArea(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `inscricoes/filtro_por_edicao_area`;
  return api.get(url, { params: filters });
}

export async function getInscriptionsForPsychosocialAccompanimentsSpreadsheet(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `inscricoes/exportar_planilha_inscritos_psicossocial`;
  return api.get(url, { params: filters });
}

export async function getInscriptionsForSchoolReinsertionSpreadsheet(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `inscricoes/exportar_planilha_inscritos_reinsercao_escolar`;
  return api.get(url, { params: filters });
}

export async function getInscriptionByIdAndResource(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/inscricoes/inscricao_por_recurso`;
  return api.get(url, { params: filters });
}

export async function getInscriptionForProductiveInsertion(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/inscricoes/filtro_insercao_produtiva`;
  return api.get(url, { params: filters });
}

export async function getInscriptionForGeneralMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/inscricoes/filtro_menus_geral`;
  return api.get(url, { params: filters });
}

export async function getYears() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get("/inscricoes/anos");
}

export async function getYearsForLastEnrollment() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get("/inscricoes/anos_para_ultima_matricula");
}

export async function createInscription(inscription) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/inscricoes", { inscriptions: inscription });
}

export async function activeMonitoringSchoolReinsertion(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `inscricoes/${filters.id}/reabilitar_acompanhamento`;
  return api.put(url, { params: filters });
}
