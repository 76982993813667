import { getAxios, baseURL } from "./Api";

export async function getPartners(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `parceiros`;
  return api.get(url, { params: filters });
}

export async function getPartnersForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `parceiros/para_menus`;
  return api.get(url, { params: filters });
}

export async function getPartnersForExport(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `parceiros/exportar_planilha`;
  return api.get(url, { params: filters });
}

export async function getPartnerById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`parceiros/${_id}`);
}

export async function createPartner(partner) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/parceiros", partner);
}

export async function updatePartner(id, partner) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`parceiros/${id}`, partner);
}

export async function destroyPartner(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`parceiros/${id}`);
}
