<template>
  <BaseCard :class="liClass">
    <div class="grid grid-cols-custom gap-10 items-center pt-2">
      <div class="flex justify-start items-center gap-4">
        <div class="place-self-center">
          <div :class="iconClass">
            <AppMaterialIcon
              iconName="bookmark_border"
              iconType="outlined"
              class="place-self-center text-white"
              iconSize="36"
            />
          </div>
        </div>
        <div class="justify-self-start place-self-center">
          <BaseText :text="getMeetingName()" typeText="custom" weight="bold" :italic="false" size="xl" />
        </div>
        <div
          class="flex items-center ml-1 pr-1 col-span-2 tooltip"
          v-if="meeting.has_unregistered_presence && !meeting.suspended"
        >
          <button ref="btnRef" v-on:mouseenter="toggleTooltip()" v-on:mouseleave="toggleTooltip()">
            <span class="material-icons mt-2 text-yellow-400">
              <span class="material-icons"> warning </span>
            </span>
          </button>
          <div
            ref="tooltipRef"
            v-bind:class="{ hidden: !tooltipShow, block: tooltipShow }"
            class="bg-white border border-black-600 mr-3 block font-normal leading-normal text-sm text-left break-words rounded-lg tooltiptext shadow-xl w-auto"
          >
            <div class="w-full">
              <div class="text-red-500 font-semibold p-2">
                <span class="tolltiptext-position"> Encontro com presença(s) pendente(s) de registro! </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <BaseText
          :text="meeting.date + ' - ' + meeting.shifts.name"
          typeText="custom"
          :italic="false"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="">
        <BaseText :text="getTeacherName()" typeText="custom" :italic="false" size="sm" color="ashes-dark" />
      </div>
      <div class="ml-1">
        <BaseText :text="getEquipmentName()" typeText="custom" :italic="false" size="sm" color="ashes-dark" />
      </div>
      <div class="justify-self-end grid grid-flow-col auto-cols-max" v-if="isTeamsInProgress()">
        <div v-if="permitUp && season_date_manual_execution_end">
          <AppButtonIcon iconName="edit" dispatch="Meeting/editModal" :payload="meeting" />
        </div>
        <div v-if="permitToSuspend && !meeting.suspended && season_date_manual_execution_end">
          <AppButtonIcon
            iconName="block"
            dispatch="Meeting/toggleModalSuspendMeeting"
            title="Suspender Encontro"
            :payload="meeting"
          />
        </div>
        <div v-if="permitToDisableSuspension && meeting.suspended && season_date_manual_execution_end">
          <AppButtonIcon
            iconName="check_circle"
            dispatch="Meeting/toggleModalDisableSuspensionMeeting"
            title="Desativar Suspensão"
            :payload="meeting"
          />
        </div>
        <div v-if="permitDes && season_date_manual_execution_end">
          <AppButtonIcon
            iconName="delete"
            dispatch="Meetings/openCloseDeleteMeetingConfirmation"
            :payload="meeting"
          />
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import AppMaterialIcon from "./AppMaterialIcon.vue";
import { mapState, mapActions } from "vuex";
import { createPopper } from "@popperjs/core";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
    AppMaterialIcon,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitToSuspend: false,
      permitToDisableSuspension: false,
      permit: false,
      tooltipShow: false,
      season_date_manual_execution_end: true,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    meeting: {
      type: Object,
    },
  },

  computed: {
    ...mapState({
      season: (state) => state.Seasons.season,
      tabTypeTeamView: (state) => state.Teams.tabTypeTeamView,
    }),
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-flow-col": true,
        "justify-items-stretch": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
        "cursor-pointer": true,
      };
    },
    iconClass() {
      let css = [];
      let bgColor = "";
      if (this.meeting.team.discipline.id == 1) {
        bgColor = "bg-yellow-300";
      }
      if (this.meeting.team.discipline.id == 2) {
        bgColor = "bg-burnedYellow-classic";
      }
      if (this.meeting.team.discipline.id == 3) {
        bgColor = "bg-green-primary";
      }
      if (this.meeting.team.discipline.id == 4) {
        bgColor = "bg-blue-primary";
      }
      if (this.meeting.team.discipline.id == 5) {
        bgColor = "bg-rose-primary";
      }

      css.push(bgColor);
      css.push("rounded-full");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");

      return css;
    },
  },

  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    permitEditMeeting() {
      this.canAcces("meeting_update").then((resp) => {
        this.permitUp = resp;
      });
    },
    permitDeleteMeeting() {
      this.canAcces("meeting_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    permitSuspend() {
      this.canAcces("meeting_suspend").then((resp) => {
        this.permitToSuspend = resp;
      });
    },
    permitDisableSuspesion() {
      this.canAcces("meeting_disable_suspension").then((resp) => {
        this.permitToDisableSuspension = resp;
      });
    },
    getTeacherName() {
      return this.meeting.teacher ? this.meeting.teacher.name : "Instrutor não informado";
    },
    getMeetingName() {
      return this.meeting.suspended ? `${this.meeting.name} [Suspenso]` : this.meeting.name;
    },
    getEquipmentName() {
      return this.meeting.equipment ? this.meeting.equipment.name : "Equipamento não informado";
    },
    getSeasonDateManualExecutionEnd() {
      this.season_date_manual_execution_end =
        localStorage.getItem("season_date_manual_execution_end") === "null" ? true : false;
    },
    toggleTooltip: function () {
      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "top",
        });
      }
    },
    isTeamsInProgress() {
      return this.tabTypeTeamView === "teams_in_progress";
    },
  },
  created() {
    this.permitEditMeeting();
    this.permitDeleteMeeting();
    this.permitSuspend();
    this.permitDisableSuspesion();
    this.getSeasonDateManualExecutionEnd();
  },
};
</script>

<style>
.grid-cols-custom {
  grid-template-columns: 23% 22% 23% 10% 10%;
}

.bg-rose-primary {
  background-color: #f04d63;
}
.bg-green-primary {
  background-color: #2e8535;
}
.bg-blue-primary {
  background-color: #65c6db;
}
</style>
