import { getAxios, baseURL } from "./Api";

export async function getForwardingReservationRegistrations(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `encaminhamentos_cadastro_reserva`;
  return api.get(url, { params: filters });
}

export async function getForwardingReservationRegistrationsForExport(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `encaminhamentos_cadastro_reserva/exportar_planilha`;
  return api.get(url, { params: filters });
}

export async function getForwardingReservationRegistrationById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`encaminhamentos_cadastro_reserva/${_id}`);
}

export async function createForwardingReservationRegistration(forwarding_reservation_registration) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/encaminhamentos_cadastro_reserva", forwarding_reservation_registration);
}

export async function updateForwardingReservationRegistration(id, forwarding_reservation_registration) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`encaminhamentos_cadastro_reserva/${id}`, forwarding_reservation_registration);
}

export async function destroyForwardingReservationRegistration(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`encaminhamentos_cadastro_reserva/${id}`);
}
