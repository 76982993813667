import {
  getForwardingPostponementHistoryById,
  getForwardingPostponementHistories,
  getForwardingPostponementHistoryByForwarding,
  createForwardingPostponementHistory,
} from "../../service/ForwardingPostponementHistoriesApi";
import Vue from "vue";

const state = {
  forwarding_postponement_history: {
    id: null,
    forwarding_id: null,
    forwarding_expiration_date_original: null,
    forwarding_postponement_comments: null,
    postponement_user_id: null,
  },
  forwarding_postponement_histories: [],
  forwarding_postponement_histories_by_forwarding: [],
  filterForwardingPostponementeHistories: [],
  forwardingPostponementHistoryId: 0,
  postponement_user: null,
  forwardingTotal: 0,
  totalPerPage: 10,
  offset: 0,
  params: {
    name: null,
    forwarding_policy: null,
    equipment: null,
  },
};

const mutations = {
  // <--- PARA PAGINACAO - FIM
  setForwardingPostponementHistoriesMutation: (state, forwarding_postponement_histories) => {
    state.forwarding_postponement_histories = forwarding_postponement_histories;
    state.filterForwardingPostponementeHistories = forwarding_postponement_histories;
  },
  setForwardingPostponementHistoriesByForwardingMutation: (
    state,
    forwarding_postponement_histories_by_forwarding
  ) => {
    state.forwarding_postponement_histories_by_forwarding = forwarding_postponement_histories_by_forwarding;
  },
  forwardingPostponementHistoryForDeletion: (state, payload) => {
    state.forwardingPostponementHistoryId = payload.id;
  },
  setIdMutation: (state, id) => {
    state.forwarding_postponement_history.id = id;
  },
  setForwardingIdMutation: (state, forwarding_id) => {
    state.forwarding_postponement_history.forwarding_id = forwarding_id;
  },
  setForwardingExpirationDateOriginalMutation: (state, forwarding_expiration_date_original) => {
    state.forwarding_postponement_history.forwarding_expiration_date_original =
      forwarding_expiration_date_original;
  },
  setForwardingPostponementCommentsMutation: (state, forwarding_postponement_comments) => {
    state.forwarding_postponement_history.forwarding_postponement_comments = forwarding_postponement_comments;
  },
  setPostponementUserMutation: (state, postponement_user) => {
    state.postponement_user = postponement_user;
    if (postponement_user != null) {
      if (postponement_user.id) {
        state.forwarding_postponement_history.postponement_user_id = postponement_user.id;
      } else {
        state.forwarding_postponement_history.postponement_user_id = postponement_user.userId;
      }
    }
  },
  setTotalPerPage: (state, totalPerPage) => {
    state.totalPerPage = totalPerPage;
  },
  setOffset: (state, offset) => {
    state.offset = offset;
  },
  setForwardingTotal: (state, forwardingTotal) => {
    state.forwardingTotal = forwardingTotal;
  },
  clearQuery: (state) => {
    state.forwardings = [];
    state.filterForwardings = [];
    state.params.name = null;
    state.params.forwarding_policy = null;
    state.params.equipment = null;
  },
  clearFields: (state) => {
    state.forwarding_policy = null;
    state.forwarding_postponement_history.forwarding_id = null;
    state.forwarding_postponement_history.forwarding_expiration_date_original = null;
    state.forwarding_postponement_history.forwarding_postponement_comments = null;
    state.forwarding_postponement_history.postponement_user_id = null;
  },
};

const actions = {
  loadForwardingPostponementHistories: ({ commit }) => {
    getForwardingPostponementHistories()
      .then((response) => {
        commit("setForwardingPostponementHistoriesMutation", response.data.forwarding_postponement_histories);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadForwardingPostponementHistoriesByForwarding: async ({ commit }, forwarding_id) => {
    const params = {
      forwarding_id: forwarding_id,
    };
    await getForwardingPostponementHistoryByForwarding(params)
      .then((response) => {
        commit(
          "setForwardingPostponementHistoriesByForwardingMutation",
          response.data.forwarding_postponement_histories
        );
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadData: ({ commit }, payload) => {
    getForwardingPostponementHistoryById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.forwarding.id);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  setId: ({ commit }, payload) => {
    commit("setIdMutation", payload);
  },
  setForwardingId: ({ commit }, payload) => {
    commit("setForwardingIdMutation", payload);
  },
  setForwardingExpirationDateOriginal: ({ commit }, payload) => {
    commit("setForwardingExpirationDateOriginalMutation", payload);
  },
  setForwardingPostponementComments: ({ commit }, payload) => {
    commit("setForwardingPostponementCommentsMutation", payload.value);
  },
  setPostponementUser: ({ commit }, payload) => {
    commit("setPostponementUserMutation", payload);
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },
  setTotalPerPage: ({ commit }, payload) => {
    commit("setTotalPerPage", payload.totalPerPage);
  },
  setOffset: ({ commit }, payload) => {
    commit("setOffset", payload.offset);
  },
  save: ({ commit }) => {
    createForwardingPostponementHistory(state.forwarding_postponement_history)
      .then(() => {
        commit("clearFields");
      })
      .catch((e) => {
        console.log(e);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
