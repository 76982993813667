import { getAxios, baseURL } from "./Api";

export async function getOccupations(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `ocupacoes`;
  return api.get(url, { params: filters });
}

export async function getOccupationsForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `ocupacoes/para_menus`;
  return api.get(url, { params: filters });
}

export async function getOccupationById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`ocupacoes/${_id}`);
}

export async function createOccupation(occupation) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/ocupacoes", occupation);
}

export async function updateOccupation(id, occupation) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`ocupacoes/${id}`, occupation);
}

export async function destroyOccupation(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`ocupacoes/${id}`);
}
