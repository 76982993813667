import { getAxios, baseURL } from "./Api";

export async function getMatrixProblems(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `matriz_problemas`;
  return api.get(url, { params: filters });
}

export async function getMatrixProblemsForMenus() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`matriz_problemas/para_menus`);
}

export async function getMatrixProblemById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`matriz_problemas/${_id}`);
}

export async function createMatrixProblem(matrix_problem) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/matriz_problemas", matrix_problem);
}

export async function updateMatrixProblem(id, matrix_problem) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`matriz_problemas/${id}`, matrix_problem);
}

export async function destroyMatrixProblem(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`matriz_problemas/${id}`);
}
