<template>
  <div>
    <div :class="gridClass" :style="gridStyle">
      <div class="mt-10 ml-5" v-if="!togglePsychosocialAccompanimentUnaccompanied">
        <BaseText
          text="Data Criação"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1" v-if="!togglePsychosocialAccompanimentUnaccompanied">
        <BaseText
          text="Data Agend. Encont."
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1" v-if="!togglePsychosocialAccompanimentUnaccompanied">
        <BaseText
          text="Data Últ. Atualiz."
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1" v-if="!togglePsychosocialAccompanimentUnaccompanied">
        <BaseText
          text="Atendimento"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1" v-if="!togglePsychosocialAccompanimentUnaccompanied">
        <BaseText
          text="Status"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1" v-if="!togglePsychosocialAccompanimentUnaccompanied">
        <BaseText
          text="Tem Encaminhamento?"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1" v-if="!togglePsychosocialAccompanimentUnaccompanied">
        <BaseText
          text="Tem Situação de Vulner. ?"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1" v-if="!togglePsychosocialAccompanimentUnaccompanied">
        <BaseText
          text="Situação de Vulner."
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1" v-if="!togglePsychosocialAccompanimentUnaccompanied">
        <BaseText
          text="Origin. de outro atendiment.?"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-7" v-if="togglePsychosocialAccompanimentUnaccompanied">
        <BaseText
          text="Data da Justificativa"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1" v-if="togglePsychosocialAccompanimentUnaccompanied">
        <BaseText
          text="Justificativa"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul" class="-mx-12">
      <li
        :key="psychosocial_accompaniment.id"
        :data-index="index"
        v-for="(psychosocial_accompaniment, index) in data"
      >
        <div>
          <AppCardPsychosocialAccompanimentEvolution
            :psychosocial_accompaniment="psychosocial_accompaniment"
          />
        </div>
        <div class="border-ashes w-full border ml-5"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardPsychosocialAccompanimentEvolution from "../../components/AppCardPsychosocialAccompanimentEvolution.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";
import { mapState } from "vuex";

export default {
  components: {
    AppCardPsychosocialAccompanimentEvolution,
    BaseText,
    BaseStaggeredFade,
  },
  props: {
    data: {
      type: Array,
    },
  },
  computed: {
    ...mapState({
      togglePsychosocialAccompanimentUnaccompanied: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentUnaccompanied,
    }),
    gridClass() {
      return "grid items-center -mx-12";
    },
    gridStyle() {
      return {
        gridTemplateColumns: this.columnDefinitions,
      };
    },
    columnDefinitions() {
      return this.togglePsychosocialAccompanimentUnaccompanied
        ? "15% 10%"
        : "10% 12% 13% 15% 8% 13% 9% 7% 9%";
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 10% 12% 13% 15% 8% 13% 9% 7% 9%;
}
.bg-blue-primary {
  background-color: #24c2ce;
}
.bg-green-primary {
  background-color: #3cbb66;
}
.bg-white-primary {
  background-color: #ffffff;
}
</style>
