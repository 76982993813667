import { getAxios, baseURL } from "./Api";

export async function getBenefits(status) {
  const token = localStorage.token;

  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let city_id = localStorage.city_id;

  if (status === "pendentes") {
    return api.get("/beneficios/pendentes", { params: { city_id: city_id } });
  } else {
    if (status === "pagos") {
      return api.get("/beneficios/pagos", { params: { city_id: city_id } });
    }
  }
}

export async function getBenefitPayment(id) {
  const token = localStorage.token;

  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get(`/beneficios/${id}/pagamento_beneficios`);
}

export async function createBenefit(benefit) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.post("/beneficios", { benefits: benefit });
}

export async function createBenefitPayment(benefit) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.post("/pagamento_beneficios", { benefit_payments: benefit });
}

export async function updateBenefit(id, benefit) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.put(`/beneficios/${id}`, { benefits: benefit });
}

export async function destroyBenefit(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`/beneficios/${id}`);
}

export async function getBenefit(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/beneficios/${id}`);
}
