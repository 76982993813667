<template>
  <div class="font-system">
    <div id="title-page" class="mx-12 my-6 w-80">
      <div>
        <Multiselect
          id="select-season"
          show-label
          close-on-select
          select-placeholder="Selecione as opções"
          label-text="Selecione a edição"
          :clear-on-select="false"
          :multiple-option="false"
          :value="season"
          :options="seasonsAll"
          :dispatch="setSeasonPage"
        />
      </div>
      <br />
      <br />
      <div class="text-ashes-hover font-medium text-3xl w-max">{{ city.name }} | Alunos [Desligados]</div>
      <div id="menus_youngstudent" class="my-10 flex">
        <div>
          <router-link to="/estudantes">
            <a href="javascript:void(0)">
              <BaseText
                text="ativos"
                class=""
                typeText="custom"
                weight="semibold"
                mode="uppercase"
                size="sm"
                color="ashes-hover"
              />
            </a>
          </router-link>
        </div>
        <div class="border-l mx-10"></div>
        <div>
          <a href="javascript:void(0)">
            <BaseText
              text="desligados"
              class="underline"
              typeText="custom"
              weight="semibold"
              mode="uppercase"
              size="sm"
              color="burnedYellow"
            />
          </a>
        </div>
      </div>
    </div>
    <div id="tabs-button" class="flex justify-between mx-12 my-6">
      <div class="flex">
        <div class="w-104 teste">
          <BaseInput
            active
            id="filter-student"
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por nome ou cpf"
            dispatch="Season/filterList"
          />
        </div>
      </div>
      <div class="float-right">
        <AppButtonColorful
          id="btn-add-student"
          showIcon
          iconName="add"
          text="Adicionar aluno"
          dispatch="Default/toggleModalPreInscription"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="table-young-student-disconnected" class="mx-14 my-6 text-sm">
      <BaseTableStudent
        :header="mountHeader()"
        :body="bodyTable"
        :total="inscriptionTotal"
        :total-per-page="totalPerPage"
        dispatch="Season/setOffset"
      />
    </div>
    <ModalInscriptionStudent :open="modalInscriptionStudentOpen" />
    <ModalViewStudent :open="modalViewStudentOpen" />
    <ModalStudentDropdown :open="modalStudentDropdownOpen" />
  </div>
</template>

<script>
import BaseText from "../components/BaseText.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseTableStudent from "../components/BaseTableStudent.vue";
import ModalViewStudent from "./ModalViewStudent.vue";
import ModalInscriptionStudent from "./ModalInscriptionStudent.vue";
import ModalStudentDropdown from "./ModalStudentDropdown.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "studentsDisconnected",

  components: {
    AppButtonColorful,
    BaseText,
    BaseTableStudent,
    ModalViewStudent,
    ModalInscriptionStudent,
    BaseInput,
    ModalStudentDropdown,
    Multiselect,
  },

  created() {
    if (!this.phase) {
      this.$store.dispatch("Season/setPartial", "inscriptions");
    }
    this.$store.dispatch("Areas/loadAreas");
    this.$store.dispatch("Season/loadDataSeason");
    this.$store.dispatch("Season/loadPhasesSeason");
  },

  methods: {
    ...mapActions({
      setArea: "Season/setArea",
      setPartial: "Season/setPartial",
      setPhase: "Season/setPhase",
      clearFilter: "Season/clearFilter",
    }),
    setSeasonPage(season) {
      this.$store.dispatch("Season/setSeason", season);
      this.$store.dispatch("Season/loadDataSeason");
      this.$store.dispatch("Areas/loadAreas");
      this.$store.dispatch("Season/loadPhasesSeason");
    },
    mountHeader() {
      return [
        { key: "student_name", label: "nome do jovem" },
        { key: "phone_number", label: "Telefone de contato" },
        { key: "actionButton" },
        { key: "actionDropdown" },
      ];
    },
    isActive(status) {
      return status === this.statusInscription;
    },
    getNamesDisciplines(phase) {
      return phase.disciplines
        .map((discipline) => {
          return discipline.name;
        })
        .join(" & ");
    },
    classButtonPhases(name) {
      return {
        "shadow-md": true,
        border: true,
        "rounded-full": true,
        "m-2": true,
        "text-ashes-hover": this.partial !== name,
        "text-burnedYellow": this.partial === name,
      };
    },
  },

  computed: {
    ...mapState({
      filterList: (state) => state.Season.filterList,
      students: (state) => state.Season.filterStudents,
      city: (state) => state.City.city,
      statusInscription: (state) => state.Season.filter.statusInscriptions,
      modalViewStudentOpen: (state) => state.ModalInscription.modalOpen,
      modalStudentDropdownOpen: (state) => state.Season.showStudentDropdown,
      season: (state) => state.Season.season,
      seasons: (state) => state.Season,
      seasonsAll: (state) => state.Seasons.seasons,
      offset: (state) => state.Season.offset,
      totalPerPage: (state) => state.Season.totalPerPage,
      bodyTable: (state) => state.Season.bodyTableStudentDisconnected,
      areas: (state) => state.Areas.areas,
      area: (state) => state.Season.filter.area,
      inscriptionTotal: (state) => state.Season.inscriptionTotal,
      phases: (state) => state.Season.phases,
      phase: (state) => state.Season.phase,
      partial: (state) => state.Season.partial,
      modalInscriptionStudentOpen: (state) => state.Default.modalPreInscriptionOpen,
    }),

    classButtonAssignment() {
      return {
        border: true,
        "shadow-md": true,
        "m-2": true,
        "rounded-full": true,
        "h-24": true,
        "w-24": true,
        flex: true,
        "items-center": true,
        "justify-center": true,
        "text-ashes-hover": this.partial !== "inscriptions",
        "text-burnedYellow": this.partial === "inscriptions",
      };
    },
  },

  watch: {
    statusInscription: function () {
      this.$store.dispatch("Season/loadDataSeason");
    },
    area: function () {
      this.$store.dispatch("Season/loadDataSeason");
    },
    offset: function () {
      this.$store.dispatch("Season/loadDataSeason");
    },
    phase: function () {
      this.$store.dispatch("Teams/clearFilter");
    },
  },
};
</script>

<style>
#table-young-student-disconnected {
  height: 550px;
  overflow-y: scroll;
  position: relative;
}
#table-young-student-disconnected thead {
  position: sticky;
  top: 0;
  background-color: white;
}
#table-young-student-disconnected td {
  padding-top: 30px;
}
</style>
