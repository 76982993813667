import { getAxios, baseURL } from "./Api";

export async function getForwardings() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`encaminhamentos`);
}

export async function getForwardingsPaginate(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `encaminhamentos/filtro_paginacao`;
  return api.get(url, { params: filters });
}

export async function getForwardingsSpreadsheet(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `encaminhamentos/exportar_planilha_encaminhamentos`;
  return api.get(url, { params: filters });
}

export async function getForwardingStatuses() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`encaminhamentos/status_encaminhamentos`);
}

export async function getForwardingReasonsOfUnrealized() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`encaminhamentos/razaoes_nao_efetivacao`);
}

export async function getForwardingById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`encaminhamentos/${_id}`);
}

export async function createForwarding(forwarding) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/encaminhamentos", forwarding);
}

export async function updateForwarding(id, forwarding) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`encaminhamentos/${id}`, forwarding);
}

export async function destroyForwarding(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`encaminhamentos/${id}`);
}
