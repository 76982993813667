<template>
  <div>
    <BaseModal v-if="dataSpreadsheet?.length > 0 && modalToggleExport">
      <template v-slot:header>
        <div class="grid grid-cols-2">
          <div class="justify-self-start">
            <Label
              :text="titleModal"
              :icon="null"
              :icon-type="null"
              type="text-2xl"
              weight="bold"
              normal
              mode="normal-case"
              class="text-ashes-dark"
            />
          </div>
          <div class="justify-self-end">
            <AppButtonIcon :dispatch="dispatchToCloseModal" forClose />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="grid grid-rows-2">
          <div class="grid justify-center items-center">
            <Label
              text="Planilha gerada com sucesso!"
              :icon="null"
              :icon-type="null"
              type="text-2x1"
              weight="normal"
              normal
              mode="normal-case"
              class="text-ashes-dark"
            />
          </div>
          <div class="grid grid-cols-2">
            <div class="justify-self-start">
              <AppButtonEmpty text="descartar" typeButton="danger" :dispatch="dispatchToCloseModal" />
            </div>

            <div class="justify-self-end">
              <download-excel
                type="xls"
                :meta="json_meta"
                :fields="formattedFields"
                :data="json_data"
                :before-finish="closeExportSpreadsheetModal"
                :worksheet="sheetTabName"
                :name="fileName"
                :escapeCsv="false"
              >
                <AppButtonEmpty text="Download da planilha" typeButton="success" />
              </download-excel>
            </div>
          </div>
        </div>
      </template>
    </BaseModal>
    <div v-if="!variablePreloading">
      <PreLoading />
    </div>
    <ModalSelectFieldsToExport
      :open="modalToggleSelectFieldsToExport"
      :fields="json_fields"
      :openModalDispatch="dispatchOpenModal"
    />
  </div>
</template>

<script>
import BaseModal from "./BaseModal.vue";
import AppButtonEmpty from "./AppButtonEmpty.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import Label from "./Label.vue";
import PreLoading from "./PreLoading.vue";
import ModalSelectFieldsToExport from "@/views/ModalSelectFieldsToExport.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseModal,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
    PreLoading,
    ModalSelectFieldsToExport,
  },
  props: {
    dataSpreadsheet: {
      type: Array,
      require: true,
    },
    modalToggleExport: {
      type: Boolean,
      default: false,
      require: true,
    },
    titleModal: {
      type: String,
      default: "Exportar Planilha",
      require: true,
    },
    dispatchToCloseModal: {
      type: String,
      require: true,
    },
    json_data: {
      type: Array,
      require: true,
    },
    sheetTabName: {
      type: String,
      default: "planilha",
    },
    fileName: {
      type: String,
      default: "planilha.xls",
    },
    variablePreloading: {
      type: Boolean,
      default: true,
      require: true,
    },
    json_fields: {
      type: Object,
      require: true,
    },
    dispatchOpenModal: {
      type: String,
      require: true,
    },
  },
  data: () => ({
    json_meta: [[{ key: "charset", value: "utf-8" }]],
  }),
  methods: {
    closeExportSpreadsheetModal() {
      if (this.dispatchToCloseModal) {
        this.$store.dispatch(this.dispatchToCloseModal);
      }
    },
  },
  computed: {
    ...mapState({
      modalToggleSelectFieldsToExport: (state) => state.SelectFieldsToExport.modalToggleSelectFieldsToExport,
      fields: (state) => state.SelectFieldsToExport.fields,
    }),
    formattedFields() {
      return Object.keys(this.fields).reduce((acc, key) => {
        acc[key] = this.fields[key];
        return acc;
      }, {});
    },
  },
};
</script>
