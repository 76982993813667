<template>
  <BaseModal width="w-160" v-if="open">
    <template v-slot:header>
      <div class="flex flex-row-reverse">
        <AppButtonIcon for-close dispatch="Beneficiaries/closeModalConfirmUpdateAllBeneficiariesStatuses" />
      </div>
      <div class="flex">
        <img src="../../assets/images/icon-alerta.svg" class="mr-2" />
        <BaseText
          text="Alterar Status de Todos os Beneficiários"
          typeText="custom"
          weight="semibold"
          size="2xl"
          color="ashes-dark"
        />
      </div>
    </template>
    <template v-slot:body>
      <div class="mt-5">
        <BaseText :text="getTitle" typeText="custom" weight="extrabold" color="quantum" size="lg" />
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-end">
        <AppButtonEmpty
          text="Não"
          type-button="danger"
          dispatch="Beneficiaries/closeModalConfirmUpdateAllBeneficiariesStatuses"
          class="mr-5"
        />
        <AppButtonEmpty
          text="Sim"
          type-button="success"
          dispatch="Beneficiaries/updateAllBeneficiariesStatus"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      status_for_update_all: (state) => state.Beneficiaries.status_for_update_all,
    }),
    getTitle() {
      let title = "";
      title = this.status_for_update_all
        ? `Tem certeza de que deseja alterar todos os status de pagamento
          para ${this.status_for_update_all?.name} ?`
        : "Carregando";
      return title;
    },
  },
};
</script>
