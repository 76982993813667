import { getAxios, baseURL } from "./Api";

export async function getForwardingPostponementHistories() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`historicos_adiamento_encaminhamento`);
}

export async function getForwardingPostponementHistoryById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`historicos_adiamento_encaminhamento/${_id}`);
}

export async function getForwardingPostponementHistoryByForwarding(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `historicos_adiamento_encaminhamento/filtrar_por_encaminhamento`;
  return api.get(url, { params: filters });
}

export async function createForwardingPostponementHistory(forwarding_postponement_history) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/historicos_adiamento_encaminhamento", forwarding_postponement_history);
}

export async function updateForwardingPostponementHistory(id, forwarding_postponement_history) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`historicos_adiamento_encaminhamento/${id}`, forwarding_postponement_history);
}

export async function destroyForwardingPostponementHistory(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`historicos_adiamento_encaminhamento/${id}`);
}
