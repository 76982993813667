import {
  getScholarshipMatrices,
  getScholarshipMatrixById,
  createScholarshipMatrix,
  updateScholarshipMatrix,
  destroyScholarshipMatrix,
} from "@/service/ScholarshipMatricesApi";
import Vue from "vue";

const state = {
  scholarship_matrix: {
    id: null,
    name: null,
    scholarship_matrix_intervals_attributes: [
      {
        frequency_min: null,
        frequency_max: null,
        value: null,
        _destroy: false,
      },
    ],
  },
  scholarship_matrices: [],
  scholarship_matrices_for_menus: [],
  errorMessages: [],
  scholarshipMatrixId: null,

  modalToggleAddScholarshipMatrix: false,
  modalToggleDeleteScholarshipMatrix: false,
  modalToggleViewScholarshipMatrix: false,

  scholarshipMatricesTotal: 0,
  scholarshipMatricesTotalPerPage: 10,
  scholarshipMatricesOffset: 0,
  loading: false,

  params: {
    name: null,
    address: null,
    modality: null,
    city: null,
    state: null,
  },
};

const mutations = {
  loadScholarshipMatrices(state, scholarship_matrices) {
    state.scholarship_matrices = scholarship_matrices;
  },
  setScholarshipMatrix(state, scholarship_matrix) {
    state.scholarship_matrix = scholarship_matrix;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setIdMutation(state, id) {
    state.scholarship_matrix.id = id;
  },
  setNameMutation(state, name) {
    state.scholarship_matrix.name = name;
  },
  setIntervalsMutation(state, intervals) {
    state.scholarship_matrix.scholarship_matrix_intervals_attributes = intervals;
  },
  addIntervalMutation(state) {
    state.scholarship_matrix.scholarship_matrix_intervals_attributes.push({
      frequency_min: null,
      frequency_max: null,
      value: null,
      _destroy: false,
    });
  },
  updateIntervalMutation(state, { index, field, value }) {
    if (state.scholarship_matrix.scholarship_matrix_intervals_attributes[index]) {
      state.scholarship_matrix.scholarship_matrix_intervals_attributes[index][field] = value;
    }
  },
  removeIntervalMutation(state, index) {
    const interval = state.scholarship_matrix.scholarship_matrix_intervals_attributes[index];
    if (interval.id) {
      Vue.set(interval, "_destroy", true);
    }
  },

  // --> MUTATIONS - DELETION <--
  scholarshipMatrixForDeletion: (state, payload) => {
    if (payload) {
      state.scholarshipMatrixId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddScholarshipMatrix: (state) => {
    state.modalToggleAddScholarshipMatrix = !state.modalToggleAddScholarshipMatrix;
  },
  toggleModalDeleteScholarshipMatrix: (state) => {
    state.modalToggleDeleteScholarshipMatrix = !state.modalToggleDeleteScholarshipMatrix;
  },
  toggleModalViewScholarshipMatrix: (state) => {
    state.modalToggleViewScholarshipMatrix = !state.modalToggleViewScholarshipMatrix;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setScholarshipMatricesTotalPerPage: (state, scholarshipMatricesTotalPerPage) => {
    state.scholarshipMatricesTotalPerPage = scholarshipMatricesTotalPerPage;
  },
  setScholarshipMatricesOffset: (state, scholarshipMatricesOffset) => {
    state.scholarshipMatricesOffset = scholarshipMatricesOffset;
  },
  setScholarshipMatricesTotal: (state, scholarshipMatricesTotal) => {
    state.scholarshipMatricesTotal = scholarshipMatricesTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.scholarship_matrix) {
      state.scholarship_matrix[prop] = null;
    }
    state.scholarship_matrix.scholarship_matrix_intervals_attributes = [
      {
        frequency_min: null,
        frequency_max: null,
        value: null,
        _destroy: false,
      },
    ];
  },
  clearQuery: (state) => {
    state.params.name = null;
    state.scholarship_matrices = [];
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

function getFilterParams() {
  return {
    name: state.params.name,
  };
}

const actions = {
  loadScholarshipMatrices: ({ commit }, from) => {
    const params = getFilterParams();
    getScholarshipMatrices(params)
      .then((response) => {
        commit("loadScholarshipMatrices", response.data.scholarship_matrices);
        commit("setScholarshipMatricesTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getScholarshipMatrixById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.scholarship_matrices.id);
        commit("setNameMutation", response.data.scholarship_matrices.name);
        commit(
          "setIntervalsMutation",
          response.data.scholarship_matrices.scholarship_matrix_intervals_attributes
        );
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },
  // ACTIONS - FIELDS TO API
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  addInterval: ({ commit }, payload) => {
    commit("addIntervalMutation", payload);
  },
  updateInterval: ({ commit }, { index, field, value }) => {
    commit("updateIntervalMutation", { index, field, value });
  },
  removeInterval: ({ commit }, index) => {
    commit("removeIntervalMutation", index);
  },
  // ACTIONS - TOGGLES AND MODALS
  openModalAddScholarshipMatrix: async ({ commit }) => {
    commit("toggleModalAddScholarshipMatrix");
  },
  closeModalAddScholarshipMatrix: ({ commit }) => {
    commit("toggleModalAddScholarshipMatrix");
    commit("setErrorMessagesMutation", []);
    commit("clearFields");
  },
  openEditModalAddScholarshipMatrix: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddScholarshipMatrix");
  },
  closeEditModalAddScholarshipMatrix: ({ commit }) => {
    commit("toggleModalAddScholarshipMatrix");
    commit("setErrorMessagesMutation", []);
    commit("clearFields");
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewScholarshipMatrix");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewScholarshipMatrix");
    commit("clearFields");
  },
  toggleModalDeleteScholarshipMatrix: ({ commit }, payload) => {
    commit("toggleModalDeleteScholarshipMatrix");
    if (state.modalToggleDeleteScholarshipMatrix && payload) {
      commit("scholarshipMatrixForDeletion", payload);
    } else {
      commit("scholarshipMatrixForDeletion", null);
    }
  },
  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  // --> ACTIONS - PAGINATIONS <--
  setScholarshipMatricesTotalPerPage: ({ commit }, payload) => {
    commit("setScholarshipMatricesTotalPerPage", payload.totalPerPage);
  },
  setScholarshipMatricesOffset: ({ commit }, payload) => {
    commit("setScholarshipMatricesOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },

  //ACTIONS - CRUD
  save: ({ commit, dispatch }) => {
    createScholarshipMatrix(state.scholarship_matrix)
      .then(() => {
        Vue.$toast.success("Matriz cadastrada com sucesso!");
        dispatch("closeModalAddScholarshipMatrix");
        dispatch("loadScholarshipMatrices");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar a matriz! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  update: ({ commit, dispatch, state }) => {
    updateScholarshipMatrix(state.scholarship_matrix.id, state.scholarship_matrix)
      .then(() => {
        dispatch("loadScholarshipMatrices");
        dispatch("closeEditModalAddScholarshipMatrix");
        Vue.$toast.success("Matriz atualizada com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar a matriz! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyScholarshipMatrix(state.scholarshipMatrixId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Matriz deletada com sucesso!");
        commit("toggleModalDeleteScholarshipMatrix");
        dispatch("loadScholarshipMatrices", from);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
