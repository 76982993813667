<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center container1">
      <div class="mt-4">
        <BaseText
          :text="formattedDate(matrix_risk_stratification_by_inscription.date_accompaniment)"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getSituation('situation')"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getSituation('impact')"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <!-- <div class="mt-4">
        <BaseText
          :text="matrix_risk_stratification_by_inscription.user"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div> -->
      <div class="mt-4 flex">
        <AppButtonIcon
          iconName="visibility"
          dispatch="MatrixRiskStratifications/openModalViewMatrixRiskStratification"
          :payload="matrix_risk_stratification_by_inscription"
          title="Visualizar informações"
          v-if="permitShow"
        />
        <AppButtonIcon
          iconName="edit"
          :payload="matrix_risk_stratification_by_inscription"
          dispatch="MatrixRiskStratifications/openEditModalMatrixRiskStratification"
          title="Atualizar Matriz"
          v-if="permitUp"
        />
        <AppButtonIcon
          iconName="delete"
          :payload="matrix_risk_stratification_by_inscription"
          dispatch="MatrixRiskStratifications/toggleModalDeleteMatrixRiskStratification"
          title="Excluir Matriz"
          v-if="permitDes"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    matrix_risk_stratification_by_inscription: {
      type: Object,
    },
    full_matrix_risk_stratification_by_inscription: {
      type: Object,
    },
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitShow: false,
    };
  },
  created() {
    this.canAcces("matrix_risk_stratification_update").then((response) => {
      this.permitUp = response;
    });
    this.canAcces("index_by_inscription").then((response) => {
      this.permitShow = response;
    });
    this.canAcces("matrix_risk_stratification_destroy").then((response) => {
      this.permitDes = response;
    });
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    getSituation(type) {
      let situations_risk = [];
      let situation_risk_filtered = null;
      let impact_level_filtered = null;
      if (this.matrix_risk_stratification_by_inscription.matrix_risk_stratifications.length > 0) {
        this.matrix_risk_stratification_by_inscription.matrix_risk_stratifications.forEach((item) => {
          situations_risk.push(item.situation_risk);
        });

        if (situations_risk.includes("Gravíssima")) {
          situation_risk_filtered = "Gravíssima";
          impact_level_filtered = "Alto";
        } else if (situations_risk.includes("Grave")) {
          situation_risk_filtered = "Grave";
          impact_level_filtered = "Médio";
        } else {
          situation_risk_filtered = "Moderada";
          impact_level_filtered = "Baixo";
        }

        if (type === "situation") return situation_risk_filtered;
        if (type === "impact") return impact_level_filtered;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 23% 21% 45% 50% 2%;
}
</style>
