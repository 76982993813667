<template>
  <div class="font-system" v-if="permit">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">
        Psicossocial - Atendimento Individual
      </div>
      <div class="text-ashes-hover font-medium text-xl mx-2 col-span-2" v-if="inscriptions.length > 0">
        {{ getTotalRegisters }}
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between p-4 items-end mx-12 my-6 gap-4">
      <div class="w-52">
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          select-placeholder="Selecionar edição"
          :multiple-option="false"
          :value="season"
          :options="seasons"
          label-text="Edição"
          :dispatch="setSeason"
          required
        />
      </div>
    </div>
    <div id="menus_psychosocial_main_page" class="mx-16 flex" v-if="season">
      <div>
        <a @click="changeTab('inAccompaniment')" href="javascript:void(0)">
          <BaseText
            text="Acolhidos"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorInAccompaniment"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div>
        <a @click="changeTab('pendingReception')" href="javascript:void(0)">
          <BaseText
            text="Acolhimentos pendentes"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorPendingReception"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div>
        <a @click="changeTab('unaccompanied')" href="javascript:void(0)">
          <BaseText
            text="Não acolhidos"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorUnaccompanied"
          />
        </a>
      </div>
    </div>
    <ModalMatrixRiskStratifications :open="modalToggleMatrixRiskStratifications" />
    <div id="list-psychosocials" v-if="season">
      <div class="mx-14">
        <PsychosocialAccompanimentPendingReception
          :data="inscriptions"
          :open="togglePsychosocialAccompanimentPendingReception"
        />
        <PsychosocialAccompanimentUnaccompanied
          :data="inscriptions"
          :open="togglePsychosocialAccompanimentUnaccompanied"
        />
        <PsychosocialAccompanimentInAccompaniment
          :data="inscriptions"
          :open="togglePsychosocialAccompanimentInAccompaniment"
        />
      </div>
    </div>
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import PsychosocialAccompanimentPendingReception from "../views/psychosocialaccompanimentpages/PsychosocialAccompanimentPendingReception.vue";
import PsychosocialAccompanimentUnaccompanied from "../views/psychosocialaccompanimentpages/PsychosocialAccompanimentUnaccompanied.vue";
import PsychosocialAccompanimentInAccompaniment from "../views/psychosocialaccompanimentpages/PsychosocialAccompanimentInAccompaniment.vue";
import BaseText from "../components/BaseText.vue";
import ModalMatrixRiskStratifications from "../views/ModalMatrixRiskStratifications.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import ProhibitedAccessPage from "../components/ProhibitedAccessPage.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    PsychosocialAccompanimentInAccompaniment,
    PsychosocialAccompanimentPendingReception,
    PsychosocialAccompanimentUnaccompanied,
    ModalMatrixRiskStratifications,
    Multiselect,
    ProhibitedAccessPage,
  },
  data() {
    return {
      permitCreate: false,
      permit: false,
      permitUp: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setSeason: "PsychosocialAccompaniments/setSeason",
    }),
    clearFields() {
      this.$store.dispatch("PsychosocialAccompaniments/clearQueryMainPage");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    changeTab(value) {
      this.clearFields();
      this.$store.dispatch("PsychosocialAccompaniments/setTabPsychosocialAccompanimentView", value);
    },
  },
  created() {
    if (sessionStorage.season_information) {
      const season_information = JSON.parse(sessionStorage.season_information);
      this.$store.dispatch("PsychosocialAccompaniments/setSeason", season_information);
    }

    const user = JSON.parse(localStorage.userData);
    this.$store.dispatch("PsychosocialAccompaniments/setUserAreas", user.areas);
    this.$store.dispatch("PsychosocialAccompaniments/loadUsers");
    this.$store.dispatch("PsychosocialAccompaniments/loadInstitutionalReferrals");
    this.$store.dispatch("UtilFunctions/loadStates");
    if (user.equipments.length > 0) {
      this.$store.dispatch("PsychosocialAccompaniments/setUserEquipments", user.equipments);
    }

    this.$store.dispatch("PsychosocialAccompaniments/loadSeasons");
    this.canAcces("psychosocial_accompaniment_create").then((resp) => {
      this.permitCreate = resp;
    });
    this.canAcces("psychosocial_accompaniment_index").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("psychosocial_accompaniment_update").then((resp) => {
      this.permitUp = resp;
    });
    this.$store.dispatch("PsychosocialAccompaniments/loadInscriptionsForGeneralMenus");
  },

  computed: {
    ...mapState({
      psychosocial_accompaniments: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniments,
      modalConfirmDeletePsychosocialAccompaniment: (state) =>
        state.PsychosocialAccompaniments.modalConfirmDeletePsychosocialAccompaniment,
      modalTogglePsychosocialAccompaniments: (state) =>
        state.PsychosocialAccompaniments.modalTogglePsychosocialAccompaniments,
      modalViewPsychosocialAccompaniment: (state) => state.PsychosocialAccompaniments.modalViewForwarding,
      city: (state) => state.City.city,
      seasons: (state) => state.PsychosocialAccompaniments.seasons,
      season: (state) => state.PsychosocialAccompaniments.season,
      inscriptions: (state) => state.PsychosocialAccompaniments.inscriptions,

      tabPsychosocialAccompanimentView: (state) =>
        state.PsychosocialAccompaniments.tabPsychosocialAccompanimentView,
      togglePsychosocialAccompanimentPendingReception: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentPendingReception,
      togglePsychosocialAccompanimentUnaccompanied: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentUnaccompanied,
      togglePsychosocialAccompanimentInAccompaniment: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentInAccompaniment,
      modalToggleMatrixRiskStratifications: (state) =>
        state.PsychosocialAccompaniments.modalToggleMatrixRiskStratifications,

      offset: (state) => state.PsychosocialAccompaniments.offset,
      totalPerPage: (state) => state.PsychosocialAccompaniments.totalPerPage,
      inscriptionsTotal: (state) => state.PsychosocialAccompaniments.inscriptionsTotal,
    }),
    setColorPendingReception() {
      return this.tabPsychosocialAccompanimentView === "pendingReception" ? "burnedYellow" : "ashes-hover";
    },
    setColorUnaccompanied() {
      return this.tabPsychosocialAccompanimentView === "unaccompanied" ? "burnedYellow" : "ashes-hover";
    },
    setColorInAccompaniment() {
      return this.tabPsychosocialAccompanimentView === "inAccompaniment" ? "burnedYellow" : "ashes-hover";
    },
    getTotalRegisters() {
      return `Quantidade de Registros Encontrados: ${this.inscriptionsTotal}`;
    },
  },
  watch: {
    offset: function () {
      this.$store.dispatch("PsychosocialAccompaniments/loadInscriptions");
      this.backToTop();
    },
  },
};
</script>
