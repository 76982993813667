import Vue from "vue";
import Router from "vue-router";
import Season from "../views/Season.vue";
import Seasons from "../views/Seasons.vue";
import HomeAdmin from "../views/HomeAdmin.vue";
import Area from "../views/Area.vue";
import Areas from "../views/Areas.vue";
import Course from "../views/Course.vue";
import Courses from "../views/Courses.vue";
import Forwardings from "../views/Forwardings.vue";
import Users from "../views/Users.vue";
import Equipment from "../views/Equipment.vue";
import EquipmentAll from "../views/EquipmentAll.vue";
import Team from "../views/Team";
import Teams from "../views/Teams";
import Meeting from "../views/Meeting";
import Teachers from "../views/Teachers";
import HomeTeacher from "../views/HomeTeacher.vue";
import Default from "../views/Default.vue";
import About from "../views/About.vue";
import Atendents from "../views/Atendents.vue";
import Benefits from "../views/Benefits.vue";
import Benefit from "../views/Benefit.vue";
import Body from "../components/Body.vue";
import SignInAdmin from "../views/SignInAdmin.vue";
import SignInTeacher from "../views/SignInTeacher.vue";
import InternalInscription from "../views/InternalInscription.vue";
import Cities from "@/views/Cities.vue";
import Neighborhoods from "@/views/Neighborhoods.vue";
// import YoungStudent from "../views/YoungStudent.vue";
import YoungStudentDisconnected from "../views/YoungStudentDisconnected.vue";
import Profiles from "../views/ProfilesView.vue";
import Permissions from "../views/PermissionsView.vue";
import PsychosocialAccompaniment from "../views/PsychosocialAccompaniment.vue";
import PsychosocialAccompanimentMainPage from "../views/psychosocialaccompanimentpages/PsychosocialAccompanimentMainPage.vue";
import Facilities from "../views/Facilities.vue";
import Articulators from "../views/Articulators.vue";
import MatrixProblemView from "../views/MatrixProblemView.vue";
import MatrixSituationView from "../views/MatrixSituationView.vue";
import SchoolReinsertions from "../views/SchoolReinsertions.vue";
import NotReinsertedInfo from "../views/school_reinsertion_pages/not_reinserteds/NotReinsertedInfo";
import ReinsertedInfo from "../views/school_reinsertion_pages/reinserteds_pages/ReinsertedInfo";
import ProductiveInsertion from "../views/productive_insertion_pages/ProductiveInsertion";
import Company from "../views/productive_insertion_pages/Company";
import Occupation from "../views/productive_insertion_pages/Occupation";
import Partner from "../views/productive_insertion_pages/Partner";
import Segment from "../views/productive_insertion_pages/Segment";
import ScholarshipMatrix from "@/views/benefits/scholarship_matrices/ScholarshipMatrix.vue";
import ScholarshipMainPage from "@/views/benefits/scholarship/ScholarshipMainPage.vue";
import ScholarshipPaymentSheetDetails from "@/views/benefits/scholarship/payment_sheet/ScholarshipPaymentSheetDetails.vue";
import TransportationVoucherMainPage from "@/views/benefits/transportation_voucher/TransportationVoucherMainPage.vue";
import TransportationVoucherPaymentSheetDetails from "@/views/benefits/transportation_voucher/payment_sheet/TransportationVoucherPaymentSheetDetails.vue";
import UpdateUserPassword from "@/views/resets/UpdateUserPassword.vue";

Vue.use(Router);

const routes = [
  {
    path: "",
    name: "default",
    component: Default,
  },
  // {
  //   path: "/estudantes",
  //   component: Body,
  //   children: [
  //     {
  //       path: "/estudantes",
  //       name: "students",
  //       component: YoungStudent,
  //       meta: {
  //         requiresAuth: true,
  //         login: "sign_in_admin",
  //       },
  //     },
  //   ],
  // },
  {
    path: "/encaminhamentos",
    component: Body,
    children: [
      {
        path: "/encaminhamentos",
        name: "forwardgins",
        component: Forwardings,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/psicossocial",
    component: Body,
    children: [
      {
        path: "/psicossocial",
        name: "psicossocial",
        component: PsychosocialAccompaniment,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/matriz_problema",
    component: Body,
    children: [
      {
        path: "/matriz_problema",
        name: "matriz_problema",
        component: MatrixProblemView,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/matriz_situacao",
    component: Body,
    children: [
      {
        path: "/matriz_situacao",
        name: "matriz_situacao",
        component: MatrixSituationView,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/rede_de_atendimento",
    component: Body,
    children: [
      {
        path: "/rede_de_atendimento",
        name: "rede_de_atendimento",
        component: Facilities,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/psicossocial/pagina_principal",
    component: Body,
    children: [
      {
        path: "/psicossocial/pagina_principal",
        name: "psicossocial_main_page",
        component: PsychosocialAccompanimentMainPage,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/estudantes/desligados",
    component: Body,
    children: [
      {
        path: "/estudantes/desligados",
        name: "studentsDisconnected",
        component: YoungStudentDisconnected,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/sobre",
    name: "about",
    component: About,
  },
  {
    path: "/admin",
    component: Body,
    children: [
      {
        path: "/admin",
        name: "admin",
        component: HomeAdmin,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/edicoes",
    component: Body,
    children: [
      {
        path: ":status",
        name: "seasons",
        component: Seasons,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/edicao",
    component: Body,
    children: [
      {
        path: ":id",
        name: "season",
        component: Season,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/areas",
    component: Body,
    children: [
      {
        path: "/areas",
        name: "areas",
        component: Areas,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/municipios",
    component: Body,
    children: [
      {
        path: "/municipios",
        name: "municipios",
        component: Cities,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/bairros",
    component: Body,
    children: [
      {
        path: "/bairros",
        name: "bairros",
        component: Neighborhoods,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/area",
    component: Body,
    children: [
      {
        path: ":id",
        name: "area",
        component: Area,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/equipamentos",
    component: Body,
    children: [
      {
        path: "/equipamentos",
        name: "equipamentos",
        component: EquipmentAll,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/equipamento",
    component: Body,
    children: [
      {
        path: ":id",
        name: "equipamento",
        component: Equipment,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/cursos",
    component: Body,
    children: [
      {
        path: "/cursos",
        name: "cursos",
        component: Courses,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/usuarios",
    component: Body,
    children: [
      {
        path: "/usuarios",
        name: "usuarios",
        component: Users,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/usuarios",
    component: Body,
    children: [
      {
        path: "/usuarios/redefinir_senha",
        name: "UpdateUserPassword",
        component: UpdateUserPassword,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/permissoes",
    component: Body,
    children: [
      {
        path: "/permissoes",
        name: "permissoes",
        component: Permissions,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/perfis",
    component: Body,
    children: [
      {
        path: "/perfis",
        name: "perfis",
        component: Profiles,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },

  {
    path: "/curso",
    component: Body,
    children: [
      {
        path: ":id",
        name: "curso",
        component: Course,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/turma",
    component: Body,
    children: [
      {
        path: ":id",
        name: "turma",
        component: Team,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/turmas",
    component: Body,
    children: [
      {
        path: "ativas",
        name: "teams_in_progress",
        component: Teams,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
      {
        path: "finalizadas",
        name: "teams_finished",
        component: Teams,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/encontro",
    component: Body,
    children: [
      {
        path: ":id",
        name: "encontro",
        component: Meeting,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/admin/entrar",
    component: SignInAdmin,
    name: "sign_in_admin",
  },
  {
    path: "/instrutor/entrar",
    component: SignInTeacher,
    name: "sign_in_teacher",
  },
  {
    path: "/instrutor",
    component: Body,
    children: [
      {
        path: "/instrutor",
        name: "home_instrutor",
        component: HomeTeacher,
        meta: {
          requiresAuth: true,
          login: "sign_in_teacher",
        },
      },
    ],
  },
  {
    path: "/admin/instrutores",
    component: Body,
    children: [
      {
        path: "/admin/instrutores",
        name: "list_teachers",
        component: Teachers,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/admin/beneficios",
    component: Body,
    children: [
      {
        path: ":status",
        name: "benefits",
        component: Benefits,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/admin/beneficio",
    component: Body,
    children: [
      {
        path: ":id",
        name: "benefit",
        component: Benefit,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/atendentes",
    component: Body,
    children: [
      {
        path: "/atendentes",
        name: "list_atendents",
        component: Atendents,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/articuladores",
    component: Body,
    children: [
      {
        path: "/articuladores",
        name: "articuladores",
        component: Articulators,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/reinsercao_escolar",
    component: Body,
    children: [
      {
        path: "/reinsercao_escolar",
        name: "reinsercao_escolar",
        component: SchoolReinsertions,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/reinsercao_escolar/nao_reinserido_info",
    component: Body,
    children: [
      {
        path: "/reinsercao_escolar/nao_reinserido_info",
        name: "not_reinserted_info",
        component: NotReinsertedInfo,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/reinsercao_escolar/reinserido_info",
    component: Body,
    children: [
      {
        path: "/reinsercao_escolar/reinserido_info",
        name: "reinserted_info",
        component: ReinsertedInfo,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/insercoes_produtivas",
    component: Body,
    children: [
      {
        path: "/insercoes_produtivas",
        name: "insercoes_produtivas",
        component: ProductiveInsertion,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/empresas",
    component: Body,
    children: [
      {
        path: "/empresas",
        name: "empresas",
        component: Company,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/parceiros",
    component: Body,
    children: [
      {
        path: "/parceiros",
        name: "parceiros",
        component: Partner,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/segmentos",
    component: Body,
    children: [
      {
        path: "/segmentos",
        name: "segmentos",
        component: Segment,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/ocupacoes",
    component: Body,
    children: [
      {
        path: "/ocupacoes",
        name: "ocupacoes",
        component: Occupation,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/matriz_bolsas",
    component: Body,
    children: [
      {
        path: "/matriz_bolsas",
        name: "matriz_bolsas",
        component: ScholarshipMatrix,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/bolsas",
    component: Body,
    children: [
      {
        path: "/bolsas",
        name: "bolsas",
        component: ScholarshipMainPage,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
      {
        path: "detalhes/:id",
        name: "ScholarshipPaymentSheetDetail",
        component: ScholarshipPaymentSheetDetails,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/vale_transporte",
    component: Body,
    children: [
      {
        path: "/vale_transporte",
        name: "vale_transporte",
        component: TransportationVoucherMainPage,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
      {
        path: "detalhes/:id",
        name: "TransportationVoucherPaymentSheetDetails",
        component: TransportationVoucherPaymentSheetDetails,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
  {
    path: "/inscricao_interna",
    component: Body,
    children: [
      {
        path: "/inscricao_interna",
        name: "inscricao_interna",
        component: InternalInscription,
        meta: {
          requiresAuth: true,
          login: "sign_in_admin",
        },
      },
    ],
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    const authHeaders = JSON.parse(localStorage.getItem("authHeaders"));
    if (authHeaders) {
      next();
    } else {
      next({ name: to.meta.login, query: { redirect: to.fullPath } });
    }
  } else {
    next({ name: to.meta.login, query: { redirect: to.fullPath } });
  }
});

export default router;
