<template>
  <div>
    <div class="grid grid-cols-custom items-center -mx-12">
      <div class="mt-10 ml-5">
        <BaseText
          text="Data do Registro"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Data da Atividade"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Município"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Atividade"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Resultado"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Articulador(a) Responsável"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul" class="-mx-12">
      <li :key="school_reinsertion.id" :data-index="index" v-for="(school_reinsertion, index) in data">
        <div>
          <AppCardSchoolReinsertionNotReinsertedEvolution :school_reinsertion="school_reinsertion" />
        </div>
        <div class="border-ashes w-full border ml-5"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardSchoolReinsertionNotReinsertedEvolution from "../../components/AppCardSchoolReinsertionNotReinsertedEvolution.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";

export default {
  components: {
    AppCardSchoolReinsertionNotReinsertedEvolution,
    BaseText,
    BaseStaggeredFade,
  },
  props: {
    data: {
      type: Array,
    },
  },
  computed: {
    teste() {
      let css = [];
      let bgColor = "";
      if (this.psychosocial_accompaniment.accompaniment_type.name === "Visita Domiciliar") {
        bgColor = "bg-yellow-300";
      }
      if (this.psychosocial_accompaniment.accompaniment_type.name === "Atendimento Individual") {
        bgColor = "bg-burnedYellow-classic";
      }
      if (this.psychosocial_accompaniment.accompaniment_type.name === "Acolhimento Inicial") {
        bgColor = "bg-green-primary";
      }
      css.push(bgColor);
      return css;
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 11% 12% 10% 19% 16% 21% 6%;
}
.bg-blue-primary {
  background-color: #24c2ce;
}
.bg-green-primary {
  background-color: #3cbb66;
}
.bg-white-primary {
  background-color: #ffffff;
}
</style>
