<template>
  <div>
    <div class="grid grid-cols-custom gap-10 items-center mt-4">
      <div class="ml-7">
        <BaseText
          text="Nome"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div>
        <BaseText
          text="Quantidade de Alunos(a)"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div>
        <BaseText
          text="Período"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div>
        <BaseText
          text="Instrutor"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div>
        <BaseText
          text="Equipamento"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div class="flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li :key="index" :data-index="index" v-for="(team, index) in data" @click.prevent="gotToTeam(team)">
        <div>
          <AppCardTeams :team="team" />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardTeams from "../../components/AppCardTeam.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";
import router from "../../router/index";

export default {
  components: {
    AppCardTeams,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },

  methods: {
    gotToTeam(params) {
      router.push({ name: "turma", params: { id: params.id } });
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  /* grid-template-columns: 45% 19% 10% 10% 15%; */
  grid-template-columns: 25% 20% 14% 11% 10%;
}
</style>
