<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            text="Suspender Encontros"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Meeting/closeModalSuspendMeetingsByTeamAndDate" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-flow-row auto-rows-max">
        <div class="grid grid-flow-row auto-rows-max">
          <ExportTeamsPresenceCards :data="teamsToExportPresences" />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="Meeting/closeModalSuspendMeetingsByTeamAndDate"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            text="Suspender"
            typeButton="success"
            dispatch="Meeting/suspendMeetingsByTeamAndDate"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import ExportTeamsPresenceCards from "./list/ExportTeamsPresenceCards";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    ExportTeamsPresenceCards,
    BaseModal,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    json_meta: [[{ key: "charset", value: "utf-8" }]],
  }),
  created() {
    this.$store.dispatch("Areas/loadAreas");
    this.$store.dispatch("Neighborhoods/loadNeighborhoods");
    this.$store.dispatch("EquipmentAll/loadEquipment");

    this.$store.dispatch("ModalExportTeamPresence/setArrayDataExport", []);
    this.$store.dispatch("Teams/getTeamsBySeasonExport");
  },
  computed: {
    ...mapState({
      areas: (state) => state.Areas.areas,
      neighborhoods: (state) => state.Neighborhoods.neighborhoods,
      equipment: (state) => state.EquipmentAll.equipmentAll,
      filters: (state) => state.ModalExportTeamPresence.filters,
      teams: (state) => state.Teams.filteredAll,
      json_fields: (state) => state.ModalExportTeamPresence.json_fields,
      json_data: (state) => state.ModalExportTeamPresence.arrayDataExport,
      teamsExport: (state) => state.ModalExportTeamPresence.teamsExport,
      teamsToExportPresences: (state) => state.Teams.teamsToExportPresences,
      fileGenerated: (state) => state.ModalExportTeamPresence.fileGenerated,
    }),
  },
  methods: {
    ...mapActions({
      filterArea: "ModalExportTeamPresence/filterArea",
      filterNeighborhood: "ModalExportTeamPresence/filterNeighborhood",
      filterEquipment: "ModalExportTeamPresence/filterEquipment",
      exportTeamPresence: "ModalExportTeamPresence/exportTeamPresence",
      closeModal: "ModalExportTeamPresence/closeModal",
    }),
    startDownload() {
      alert("show loading");
    },
  },
};
</script>
