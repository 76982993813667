<template>
  <div>
    <BaseModal headerColor="#DE5262" v-if="open">
      <template v-slot:header>
        <div class="grid grid-cols-2">
          <div class="mb-3 justify-self-start">
            <div id="options" class="md:w-full lg:w-full">
              <div id="matrix">
                <BaseText
                  text="Matriz de Problema-Impacto"
                  type-text="custom"
                  weight="bold"
                  mode="capitalize"
                  color="white"
                  size="xl"
                />
              </div>
            </div>
          </div>
          <div class="justify-self-end">
            <AppButtonIcon
              for-close
              dispatch="MatrixRiskStratifications/closeModalViewMatrixRiskStratification"
            />
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <div>
            <MatrixRiskStratificationByInscriptionData />
          </div>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "../../components/BaseModal.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import BaseText from "../../components/BaseText.vue";
import MatrixRiskStratificationByInscriptionData from "./MatrixRiskStratificationByInscriptionData.vue";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonIcon,
    MatrixRiskStratificationByInscriptionData,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.container1 {
  height: 600px;
  width: 100%;
  overflow: visible;
}

.container2 {
  width: 105%;
  height: 100%;
  overflow: auto;
  padding-right: 4%;
  padding-left: 4px;
}
</style>
<style>
.buttons-insc {
  margin-top: -10%;
}
.show {
  display: block;
}
.hide {
  display: none;
}
</style>
