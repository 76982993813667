import { getAreasByCity } from "../../service/AreaApi";
import {
  getPlannedAwarenessActivities,
  getPlannedAwarenessActivityById,
  createPlannedAwarenessActivity,
  updatePlannedAwarenessActivity,
  destroyPlannedAwarenessActivity,
} from "../../service/PlannedAwarenessActivitiesApi";
import { getEquipmentByAreaId } from "../../service/EquipmentApi";
import { getArticulators } from "../../service/ArticulatorsApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
import moment from "moment";

const state = {
  planned_awareness_activity: {
    id: null,
    articulator: null,
    equipment_id: null,
    city_id: localStorage.city_id,
    user_id: null,
    area_id: null,
    activity_date: null,
    purpose: null,
    methodology: null,
    used_resources: null,
    activity_evaluation: null,
  },
  planned_awareness_activities: [],
  user_areas: [],
  user_equipments: [],
  equipments_planned_awareness_activities: [],
  articulators: [],
  planned_awareness_activities_spreadsheet: [],

  plannedAwarenessActivityId: null,
  area: null,
  equipment: null,
  user: null,
  articulator: null,
  city: null,

  modalToggleAddPlannedAwarenessActivity: false,
  modalToggleDeletePlannedAwarenessActivity: false,
  modalToggleViewPlannedAwarenessActivity: false,
  modalToggleExportPlannedAwarenessActivitiesSpreadsheet: false,

  hasSpreadsheetPlannedAwarenessActivitiesResponse: true,
  json_data_planned_awareness_activity: null,

  plannedAwarenessActivitiesTotal: 0,
  plannedAwarenessActivitiesTotalPerPage: 10,
  plannedAwarenessActivitiesOffset: 0,

  params: {
    area: null,
    equipment: null,
    articulator: null,
    initial_date: null,
    final_date: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadPlannedAwarenessActivities: (state, planned_awareness_activities) => {
    state.planned_awareness_activities = planned_awareness_activities;
  },
  loadArticulators: (state, articulators) => {
    state.articulators = articulators;
  },
  setUserAreasMutation: (state, user_areas) => {
    state.user_areas = user_areas;
  },
  setEquipmentsMutation: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },
  setEquipmentsForPlannedAwarenessActivitiesMutation: (state, equipments_planned_awareness_activities) => {
    state.equipments_planned_awareness_activities = equipments_planned_awareness_activities;
  },

  // MUTATIONS - FIELDS TO API
  setIdMutation: (state, id) => {
    state.planned_awareness_activity.id = id;
  },
  setArticulatorMutation: (state, articulator) => {
    state.planned_awareness_activity.articulator = articulator;
  },
  setCityMutation: (state, city) => {
    state.city = city;
    state.planned_awareness_activity.city_id = city ? city.id : null;
  },
  setAreaMutation: (state, area) => {
    state.area = area;
    state.planned_awareness_activity.area_id = area ? area.id : null;
  },
  setEquipmentMutation: (state, equipment) => {
    state.equipment = equipment;
    state.planned_awareness_activity.equipment_id = equipment ? equipment.id : null;
  },
  setActivityDateMutation: (state, activity_date) => {
    state.planned_awareness_activity.activity_date = activity_date;
  },
  setPurposeMutation: (state, purpose) => {
    state.planned_awareness_activity.purpose = purpose;
  },
  setMethodologyMutation: (state, methodology) => {
    state.planned_awareness_activity.methodology = methodology;
  },
  setUsedResourcesMutation: (state, used_resources) => {
    state.planned_awareness_activity.used_resources = used_resources;
  },
  setActivityEvaluationMutation: (state, activity_evaluation) => {
    state.planned_awareness_activity.activity_evaluation = activity_evaluation;
  },
  setUserEquipmentsMutation: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },
  setUserMutation: (state, user) => {
    state.user = user;
    if (user) {
      if (user.id) {
        state.planned_awareness_activity.user_id = user.id;
      } else {
        state.planned_awareness_activity.user_id = user.userId;
      }
    }
  },

  // --> MUTATIONS - DELETION <--
  plannedAwarenessActivityForDeletion: (state, payload) => {
    if (payload) {
      state.plannedAwarenessActivityId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterAreaMutation: (state, area) => {
    state.params.area = area;
  },
  setFilterEquipmentMutation: (state, equipment) => {
    state.params.equipment = equipment;
  },
  setFilterArticulatorMutation: (state, articulator) => {
    state.params.articulator = articulator;
  },
  setFilterInitialDateMutation: (state, initial_date) => {
    state.params.initial_date = initial_date;
  },
  setFilterFinalDateMutation: (state, final_date) => {
    state.params.final_date = final_date;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddPlannedAwarenessActivity: (state) => {
    state.modalToggleAddPlannedAwarenessActivity = !state.modalToggleAddPlannedAwarenessActivity;
  },
  toggleModalDeletePlannedAwarenessActivity: (state) => {
    state.modalToggleDeletePlannedAwarenessActivity = !state.modalToggleDeletePlannedAwarenessActivity;
  },
  toggleModalViewPlannedAwarenessActivity: (state) => {
    state.modalToggleViewPlannedAwarenessActivity = !state.modalToggleViewPlannedAwarenessActivity;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportPlannedAwarenessActivitiesSpreadsheet: (state) => {
    state.modalToggleExportPlannedAwarenessActivitiesSpreadsheet =
      !state.modalToggleExportPlannedAwarenessActivitiesSpreadsheet;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetPlannedAwarenessActivitiesResponseMutation: (
    state,
    hasSpreadsheetPlannedAwarenessActivitiesResponse
  ) => {
    state.hasSpreadsheetPlannedAwarenessActivitiesResponse = hasSpreadsheetPlannedAwarenessActivitiesResponse;
  },
  setPlannedAwarenessActivitiesPageToExportMutation: (state, planned_awareness_activity) => {
    state.json_data_planned_awareness_activity = null;
    const arrayData = new Array();
    planned_awareness_activity.forEach((element, index, array) => {
      let activity_date = array[index].activity_date
        ? moment(array[index].activity_date).format("DD/MM/YYYY")
        : "";
      let activity_evaluation = array[index].activity_evaluation ? array[index].activity_evaluation : "";
      let area = array[index].area ? array[index].area.name : "";
      let articulator = array[index].articulator ? array[index].articulator : "";
      let created_at = array[index].created_at ? moment(array[index].created_at).format("DD/MM/YYYY") : "";
      let equipment = array[index].equipment ? array[index].equipment.name : "";
      let methodology = array[index].methodology ? array[index].methodology : "";
      let purpose = array[index].purpose ? array[index].purpose : "";
      let used_resources = array[index].used_resources ? array[index].used_resources : "";

      const arrayItem = new Array();
      arrayItem["created_at"] = created_at;
      arrayItem["area"] = area;
      arrayItem["equipment"] = equipment;
      arrayItem["articulator"] = articulator;
      arrayItem["activity_date"] = activity_date;
      arrayItem["purpose"] = purpose;
      arrayItem["methodology"] = methodology;
      arrayItem["used_resources"] = used_resources;
      arrayItem["activity_evaluation"] = activity_evaluation;
      arrayData.push(arrayItem);
    });
    state.json_data_planned_awareness_activity = arrayData;
    state.hasSpreadsheetPlannedAwarenessActivitiesResponse = true;
    state.modalToggleExportPlannedAwarenessActivitiesSpreadsheet = true;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setPlannedAwarenessActivitiesTotalPerPage: (state, plannedAwarenessActivitiesTotalPerPage) => {
    state.plannedAwarenessActivitiesTotalPerPage = plannedAwarenessActivitiesTotalPerPage;
  },
  setPlannedAwarenessActivitiesOffset: (state, plannedAwarenessActivitiesOffset) => {
    state.plannedAwarenessActivitiesOffset = plannedAwarenessActivitiesOffset;
  },
  setPlannedAwarenessActivitiesTotal: (state, plannedAwarenessActivitiesTotal) => {
    state.plannedAwarenessActivitiesTotal = plannedAwarenessActivitiesTotal;
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setPlannedAwarenessActivitiesSpreadsheetMutation: (state, planned_awareness_activities_spreadsheet) => {
    state.planned_awareness_activities_spreadsheet = planned_awareness_activities_spreadsheet;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.planned_awareness_activity) {
      if (prop !== "user_id" && prop !== "city_id") {
        state.planned_awareness_activity[prop] = null;
      }
    }
    state.area = null;
    state.equipment = null;
    state.articulator = null;
    state.city = null;
    state.equipments_planned_awareness_activities = [];
  },
  clearQuery: (state) => {
    state.params.area = null;
    state.params.equipment = null;
    state.params.articulator = null;
    state.params.initial_date = null;
    state.params.final_date = null;
    state.planned_awareness_activities = [];
    state.equipments_planned_awareness_activities = [];
  },
};

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadPlannedAwarenessActivities: ({ commit }, type) => {
    if (type === "loadDataSpreadsheet") {
      commit("hasSpreadsheetPlannedAwarenessActivitiesResponseMutation", false);
    }
    const params = {
      city: localStorage.city_id,
      area: state.params.area ? state.params.area.id : null,
      equipment: state.params.equipment ? state.params.equipment.id : null,
      articulator: state.params.articulator,
      type: type,
      initial_date: state.params.initial_date,
      final_date: state.params.final_date,
      perPage: state.plannedAwarenessActivitiesTotalPerPage,
      offset: state.plannedAwarenessActivitiesOffset,
    };
    getPlannedAwarenessActivities(params)
      .then(async (response) => {
        if (type == "loadData") {
          if (response.data.planned_awareness_activities_total < 1) {
            Vue.$toast.warning("Nenhum resultado para o filtro informado!");
          }
          await commit("loadPlannedAwarenessActivities", response.data.planned_awareness_activities);
          await commit(
            "setPlannedAwarenessActivitiesTotal",
            response.data.planned_awareness_activities_total
          );
        } else {
          commit(
            "setPlannedAwarenessActivitiesSpreadsheetMutation",
            response.data.planned_awareness_activities
          );
          if (state.planned_awareness_activities_spreadsheet.length > 0) {
            await commit(
              "setPlannedAwarenessActivitiesPageToExportMutation",
              response.data.planned_awareness_activities
            );
          }
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadArticulators: ({ commit }) => {
    getArticulators()
      .then((response) => {
        commit("loadArticulators", response.data.articulators);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadAreas: ({ commit }) => {
    const city_id = localStorage.city_id;
    getAreasByCity(city_id)
      .then((response) => {
        commit("loadAreas", response.data.areas);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadEquipments: ({ commit }) => {
    const params = {
      city_id: localStorage.city_id,
      area_id: state.params.area ? state.params.area.id : state.planned_awareness_activity.area_id,
    };
    getEquipmentByAreaId(params)
      .then((response) => {
        let equipments = [];
        if (state.user_equipments.length > 0) {
          response.data.equipment.forEach((item) => {
            state.user_equipments.forEach((element) => {
              if (item.id === element.id) {
                equipments.push(item);
              }
            });
          });
        } else {
          equipments = response.data.equipment;
        }
        commit("setEquipmentsForPlannedAwarenessActivitiesMutation", equipments);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getPlannedAwarenessActivityById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.planned_awareness_activity.id);
        commit("setActivityDateMutation", response.data.planned_awareness_activity.activity_date);
        commit("setActivityEvaluationMutation", response.data.planned_awareness_activity.activity_evaluation);
        commit("setAreaMutation", response.data.planned_awareness_activity.area);
        commit("setCityMutation", response.data.planned_awareness_activity.city);
        commit("setEquipmentMutation", response.data.planned_awareness_activity.equipment);
        commit("setMethodologyMutation", response.data.planned_awareness_activity.methodology);
        commit("setPurposeMutation", response.data.planned_awareness_activity.purpose);
        commit("setUsedResourcesMutation", response.data.planned_awareness_activity.used_resources);
        commit("setUserMutation", response.data.planned_awareness_activity.user);
        commit("setArticulatorMutation", response.data.planned_awareness_activity.articulator);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setArea: ({ commit }, payload) => {
    commit("setAreaMutation", payload);
    if (!payload) {
      commit("setEquipmentsForPlannedAwarenessActivitiesMutation", []);
      commit("setEquipmentMutation", null);
    }
  },
  setEquipment: ({ commit }, payload) => {
    commit("setEquipmentMutation", payload);
  },
  setArticulator: ({ commit }, payload) => {
    commit("setArticulatorMutation", payload.value);
  },
  setUser: ({ commit }, payload) => {
    commit("setUserMutation", payload);
  },
  setActivityDate: ({ commit }, payload) => {
    commit("setActivityDateMutation", payload.value);
  },
  setPurpose: ({ commit }, payload) => {
    commit("setPurposeMutation", payload.value);
  },
  setMethodology: ({ commit }, payload) => {
    commit("setMethodologyMutation", payload.value);
  },
  setUsedResources: ({ commit }, payload) => {
    commit("setUsedResourcesMutation", payload.value);
  },
  setActivityEvaluation: ({ commit }, payload) => {
    commit("setActivityEvaluationMutation", payload.value);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddPlannedAwarenessActivity: async ({ commit }) => {
    commit("toggleModalAddPlannedAwarenessActivity");
  },
  closeModalAddPlannedAwarenessActivity: ({ commit }) => {
    commit("toggleModalAddPlannedAwarenessActivity");
    commit("clearFields");
  },
  openEditModalAddPlannedAwarenessActivity: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddPlannedAwarenessActivity");
  },
  closeEditModalAddPlannedAwarenessActivity: ({ commit }) => {
    commit("toggleModalAddPlannedAwarenessActivity");
    commit("clearFields");
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewPlannedAwarenessActivity");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewPlannedAwarenessActivity");
    commit("clearFields");
  },
  toggleModalDeletePlannedAwarenessActivity: ({ commit }, payload) => {
    commit("toggleModalDeletePlannedAwarenessActivity");
    if (state.modalToggleDeletePlannedAwarenessActivity && payload) {
      commit("plannedAwarenessActivityForDeletion", payload);
    } else {
      commit("plannedAwarenessActivityForDeletion", null);
    }
  },

  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportPlannedAwarenessActivitiesSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPlannedAwarenessActivitiesSpreadsheet");
  },
  closeExportPlannedAwarenessActivitiesSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPlannedAwarenessActivitiesSpreadsheet");
  },

  // --> ACTIONS - FILTERLIST
  setFilterArea: ({ commit }, payload) => {
    commit("setFilterAreaMutation", payload);
    if (!payload) {
      commit("setEquipmentsForPlannedAwarenessActivitiesMutation", []);
      commit("setFilterEquipmentMutation", null);
    }
  },
  setFilterEquipment: ({ commit }, payload) => {
    commit("setFilterEquipmentMutation", payload);
  },
  setFilterArticulator: ({ commit }, payload) => {
    commit("setFilterArticulatorMutation", payload.value);
  },
  setFilterInitialDate: ({ commit }, payload) => {
    commit("setFilterInitialDateMutation", payload.value);
  },
  setFilterFinalDate: ({ commit }, payload) => {
    commit("setFilterFinalDateMutation", payload.value);
  },

  // --> ACTIONS - PAGINATIONS <--
  setPlannedAwarenessActivitiesTotalPerPage: ({ commit }, payload) => {
    commit("setPlannedAwarenessActivitiesTotalPerPage", payload.totalPerPage);
  },
  setPlannedAwarenessActivitiesOffset: ({ commit }, payload) => {
    commit("setPlannedAwarenessActivitiesOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  setUserAreas: ({ commit }, payload) => {
    commit("setUserAreasMutation", payload);
  },
  setEquipmentsForPlannedAwarenessActivities: ({ commit }, payload) => {
    commit("setEquipmentsForPlannedAwarenessActivitiesMutation", payload);
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },
  setUserEquipments: ({ commit }, payload) => {
    commit("setUserEquipmentsMutation", payload);
  },
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },

  //ACTIONS - CRUD
  save: ({ dispatch }) => {
    createPlannedAwarenessActivity(state.planned_awareness_activity)
      .then(() => {
        Vue.$toast.success("Planejamento cadastrado com sucesso!");
        dispatch("closeModalAddPlannedAwarenessActivity");
        dispatch("loadPlannedAwarenessActivities", "loadData");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar o planejamento! " + e.response.data.error);
        console.log(e.response);
      });
  },
  update: ({ state, dispatch }) => {
    updatePlannedAwarenessActivity(state.planned_awareness_activity.id, state.planned_awareness_activity)
      .then(() => {
        dispatch("loadPlannedAwarenessActivities", "loadData");
        dispatch("closeEditModalAddPlannedAwarenessActivity");
        Vue.$toast.success("Planejamento atualizado com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar o planejamento! ");
        console.log(e.response.data.errors);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyPlannedAwarenessActivity(state.plannedAwarenessActivityId)
      .then(() => {
        Vue.$toast.success("Planejamento deletado com sucesso!");
        commit("toggleModalDeletePlannedAwarenessActivity");
        dispatch("loadPlannedAwarenessActivities", "loadData");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
