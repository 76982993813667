<template>
  <div class="m-5">
    <div id="person-data" class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-5">
        <BaseText text="Dados da Solicitação da Vaga" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Nome do Participante"
          :text="inscription_filtered?.name ? inscription_filtered?.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Tipo da Vaga Solicitada"
          :text="vacancy_type ? vacancy_type.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Articulador(a) Responsável"
          :text="vacancy_request.articulator ? vacancy_request.articulator : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1" v-if="vacancy_type?.id == 0">
        <AppTextField
          show-label
          label-text="CPF DA MÃE OU RESPONSÁVEL DO JOVEM"
          :text="
            vacancy_request.cpf_responsible_young_person
              ? vacancy_request.cpf_responsible_young_person
              : 'Não informado' | VMask('###.###.###-##')
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="vacancy_type?.id == 0">
        <AppTextField
          show-label
          label-text="ENDEREÇO DA MÃE OU RESPONSÁVEL DO JOVEM"
          :text="
            vacancy_request.address_responsible_young_person
              ? vacancy_request.address_responsible_young_person
              : 'Não informado'
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1" v-if="vacancy_type?.id == 0">
        <AppTextField
          show-label
          label-text="CONTATO ATUALIZADO DO JOVEM
          "
          :text="
            vacancy_request.updated_contact_young_person
              ? vacancy_request.updated_contact_young_person
              : 'Não informado' | VMask('(##) #####-####')
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="vacancy_type?.id == 0">
        <AppTextField
          show-label
          label-text="ESCOLA DE INTERESSE"
          :text="facility ? facility.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1" v-if="vacancy_type?.id == 0">
        <AppTextField
          show-label
          label-text="TURNO DE INTERESSE"
          :text="interest_shift ? interest_shift.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="vacancy_type?.id == 0">
        <AppTextField
          show-label
          label-text="SÉRIE DE INTERESSE"
          :text="interest_serie ? interest_serie.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1" v-if="vacancy_type?.id == 0">
        <AppTextField
          show-label
          label-text="REDE A QUE PERTENCE A ESCOLA"
          :text="school_network ? school_network.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5" v-if="vacancy_type?.id == 0">
        <AppTextField
          show-label
          label-text="DESCRIÇÃO DO CASO"
          :text="vacancy_request.case_description ? vacancy_request.case_description : 'Não informado'"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-5" v-if="vacancy_type?.id === 0">
        <AppTextField
          show-label
          label-text="INTERVENÇÕES JÁ REALIZADAS"
          :text="
            vacancy_request.interventions_performed
              ? vacancy_request.interventions_performed
              : 'Não informado'
          "
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-3" v-if="vacancy_type?.id === 0">
        <AppTextField
          show-label
          label-text="TIPO DE ENCAMINHAMENTO"
          :text="vacancy_request.forwarding_type ? vacancy_request.forwarding_type : 'Não informado'"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-2" v-if="vacancy_type?.id === 1">
        <AppTextField
          show-label
          label-text="NOME COMPLETO DO RESPONSÁVEL PELA CRIANÇA"
          :text="
            vacancy_request.full_name_responsible_child
              ? vacancy_request.full_name_responsible_child
              : 'Não informado'
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="vacancy_type?.id == 1">
        <AppTextField
          show-label
          label-text="NOME COMPLETO DA CRIANÇA"
          :text="vacancy_request.child_full_name ? vacancy_request.child_full_name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1" v-if="vacancy_type?.id == 1">
        <AppTextField
          show-label
          label-text="CPF DA MÃE OU RESPONSÁVEL PELA CRIANÇA"
          :text="
            vacancy_request.cpf_responsible_child
              ? vacancy_request.cpf_responsible_child
              : 'Não informado' | VMask('###.###.###-##')
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1" v-if="vacancy_type?.id == 1">
        <AppTextField
          show-label
          label-text="DATA DE NASCIMENTO DA CRIANÇA"
          :text="
            vacancy_request.child_birthdate ? formattedDate(vacancy_request.child_birthdate) : 'Não informado'
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="vacancy_type?.id == 1">
        <AppTextField
          show-label
          label-text="ENDEREÇO DO RESPONSÁVEL PELA CRIANÇA"
          :text="
            vacancy_request.address_responsible_child
              ? vacancy_request.address_responsible_child
              : 'Não informado'
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1" v-if="vacancy_type?.id == 1">
        <AppTextField
          show-label
          label-text="CONTATO DO RESPONSÁVEL PELA CRIANÇA"
          :text="
            vacancy_request.contact_responsible_child
              ? vacancy_request.contact_responsible_child
              : 'Não informado' | VMask('(##) #####-####')
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="SOLICITAÇÃO JÁ FOI ATENDIDA?"
          :text="vacancy_request.accepted_request ? 'Sim' : 'Não'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="DEVOLUTIVA"
          :text="vacancy_request.feedback ? vacancy_request.feedback : 'Não informado'"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppTextField from "../../components/AppTextField.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppTextField,
  },
  computed: {
    ...mapState({
      inscription: (state) => state.PsychosocialAccompaniments.inscription,
      vacancy_request: (state) => state.VacancyRequests.vacancy_request,
      vacancy_type: (state) => state.VacancyRequests.vacancy_type,
      school_network: (state) => state.VacancyRequests.school_network,
      interest_serie: (state) => state.VacancyRequests.interest_serie,
      interest_shift: (state) => state.VacancyRequests.interest_shift,
      shift: (state) => state.VacancyRequests.shift,
      articulator: (state) => state.VacancyRequests.articulator,
      facility: (state) => state.VacancyRequests.facility,
      inscription_filtered: (state) => state.VacancyRequests.inscription,
    }),
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
  },
};
</script>
<style>
.active_new {
  background-color: #fff7ed;
  color: #fff;
  font-size: 1.1em;
}
</style>
