<template>
  <div class="font-system" v-if="permit && open">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-2xl mx-2 col-span-2">
        Encaminhamento para Cadastro Reserva
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-forwarding_reservation_registration"
          showIcon
          iconName="add"
          text="Cadastrar Encaminhamento"
          dispatch="ForwardingReservationRegistrations/openModalAddForwardingReservationRegistration"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Status da Matrícula"
            select-placeholder="Escolha a opção para buscar"
            :multiple-option="false"
            :value="inscription_status_filtered"
            :options="incriptions_status"
            :dispatch="setFilterInscriptionStatus"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            label-text="Filtrar por Nome do Aluno(a)"
            show-label
            clear-on-select
            select-placeholder="Digite o nome do aluno(a) para busca"
            :multiple-option="false"
            :value="inscription_filtered"
            :options="inscriptions"
            :dispatch="setFilterInscription"
            load-dispatch="SpontaneousProductiveInsertions/loadInscriptions"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setFilterCity"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Área"
            label-text="Pesquisar por Área"
            :multiple-option="false"
            :value="user_areas_filtered"
            :options="user_areas"
            :dispatch="setUserAreasFiltered"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Equipamento"
            :multiple-option="false"
            :value="user_equipments_filtered"
            :options="equipments_forwarding_reservation_registrations"
            :label-text="getLabelEquipmentFilter()"
            :dispatch="setFilterEquipment"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Empresa"
            select-placeholder="Digite o nome da empresa para buscar"
            :multiple-option="false"
            :value="company_filtered"
            :options="companies"
            :dispatch="setFilterCompany"
            load-dispatch="ForwardingReservationRegistrations/loadCompanies"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Parceiro"
            select-placeholder="Digite o nome do parceiro para buscar"
            :multiple-option="false"
            :value="partner_filtered"
            :options="partners"
            :dispatch="setFilterPartner"
            load-dispatch="ForwardingReservationRegistrations/loadPartners"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Status Qualificação"
            label-text="Pesquisar por Status Qualificação"
            :multiple-option="false"
            :value="qualification_status_filtered"
            :options="qualification_statuses"
            :dispatch="setFilterQualificationStatus"
          />
        </div>
        <div class="col-span-4">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Usuário(a) responsável pelo cadastro"
            select-placeholder="Digite o nome do usuário para buscar"
            :multiple-option="false"
            :value="user_filtered"
            :options="users"
            :dispatch="setFilterUser"
            load-dispatch="ForwardingReservationRegistrations/loadUsers"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="filter_by_initial_date"
            input-type="date"
            :value="initial_date_filtered"
            show-label
            label-text="Data do Encaminhamento - Data Inicial"
            dispatch="ForwardingReservationRegistrations/setFilterInitialDate"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="filter_by_final_date"
            input-type="date"
            :value="final_date_filtered"
            show-label
            label-text="Data do Encaminhamento - Data Final"
            dispatch="ForwardingReservationRegistrations/setFilterFinalDate"
          />
        </div>
      </FilterComponent>
      <div class="mt-3">
        <a
          href="javascript:void(0)"
          @click="exportToExcel()"
          v-if="forwarding_reservation_registrations?.length > 0 && permitExportSpreadsheet"
        >
          <img
            class="inline-block"
            src="@/assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
      </div>
      <BaseModal
        v-if="
          forwarding_reservation_registrations_spreadsheet.length > 0 &&
          modalToggleExportForwardingReservationRegistrationsSpreadsheet
        "
      >
        <template v-slot:header>
          <div class="grid grid-cols-3">
            <div class="justify-self-start col-span-2">
              <Label
                text="Exportar Planilha de Encaminhamentos para Cadastro Reserva"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon
                dispatch="ForwardingReservationRegistrations/closeExportForwardingReservationRegistrationsSpreadsheetModal"
                forClose
              />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="ForwardingReservationRegistrations/closeExportForwardingReservationRegistrationsSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data_forwarding_reservation_registration"
                  :before-finish="closeExportForwardingReservationRegistrationsSpreadsheetModal"
                  worksheet="Encaminhamentos_Cadastro_Reserva"
                  name="encaminhamentos_cadastro_reserva.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetForwardingReservationRegistrationsResponse">
        <PreLoading />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-forwarding_reservation_registrations"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="ForwardingReservationRegistrations/loadForwardingReservationRegistrations"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="forwarding_reservation_registrations.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-forwarding_reservation_registrations"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-forwarding_reservation_registrations">
      <div class="mx-12">
        <ForwardingReservationRegistrationsCards :data="forwarding_reservation_registrations" />
      </div>
      <div
        id="forwarding_reservation_registrations-pagination"
        class="mx-12 my-6 pb-3"
        v-if="forwarding_reservation_registrations.length > 0"
      >
        <BasePagination
          :total="totalForwardingReservationRegistrations"
          :total-per-page="totalPerPage"
          dispatch="ForwardingReservationRegistrations/setForwardingReservationRegistrationsOffset"
        />
      </div>
      <ModalForwardingReservationRegistration :open="modalToggleAddForwardingReservationRegistration" />
      <ModalViewForwardingReservationRegistrationData
        :open="modalToggleViewForwardingReservationRegistration"
      />
      <DeleteForwardingReservationRegistrationConfirmation
        :open="modalToggleDeleteForwardingReservationRegistration"
      />
    </div>
  </div>
</template>

<script>
import ForwardingReservationRegistrationsCards from "../list/ForwardingReservationRegistrationsCards.vue";
import BasePagination from "../../components/BasePagination.vue";
import ModalForwardingReservationRegistration from "./modals/ModalForwardingReservationRegistration.vue";
import ModalViewForwardingReservationRegistrationData from "../datas/ModalViewForwardingReservationRegistrationData.vue";
import DeleteForwardingReservationRegistrationConfirmation from "../alerts/DeleteForwardingReservationRegistrationConfirmation.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";
import Multiselect from "../../components/forms/Multiselect.vue";
import BaseModal from "@/components/BaseModal.vue";
import PreLoading from "@/components/PreLoading.vue";
import Label from "@/components/Label.vue";
import BaseText from "@/components/BaseText.vue";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    AppButtonIcon,
    AppButtonEmpty,
    BaseText,
    Label,
    BaseModal,
    Multiselect,
    BasePagination,
    AppButtonColorful,
    ForwardingReservationRegistrationsCards,
    DeleteForwardingReservationRegistrationConfirmation,
    BaseInput,
    ModalForwardingReservationRegistration,
    ModalViewForwardingReservationRegistrationData,
    PreLoading,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
      permitExportSpreadsheet: false,
      json_fields: {
        Status: "participant_status",
        Área: "area",
        Equipamento: "equipment",
        Nome: "participant_name",
        Parceiro: "partner",
        Empresa: "company",
        Usuário: "user",
        "Data do Encaminhamento": "forwarding_date",
        "Nº da vez do Encaminhamento": "forwarding_position",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterOpportunity: "ForwardingReservationRegistrations/setFilterOpportunity",
      setFilterCompany: "ForwardingReservationRegistrations/setFilterCompany",
      setFilterInscriptionStatus: "ForwardingReservationRegistrations/setFilterInscriptionStatus",
      setFilterInscription: "ForwardingReservationRegistrations/setFilterInscription",
      setFilterPartner: "ForwardingReservationRegistrations/setFilterPartner",
      setFilterSegment: "ForwardingReservationRegistrations/setFilterSegment",
      setFilterOccupation: "ForwardingReservationRegistrations/setFilterOccupation",
      setFilterContractingType: "ForwardingReservationRegistrations/setFilterContractingType",
      setFilterFeedbackType: "ForwardingReservationRegistrations/setFilterFeedbackType",
      setUserAreasFiltered: "ForwardingReservationRegistrations/setUserAreasFiltered",
      setFilterEquipment: "ForwardingReservationRegistrations/setFilterEquipment",
      setFilterUser: "ForwardingReservationRegistrations/setFilterUser",
      setFilterCity: "ForwardingReservationRegistrations/setFilterCity",
      setFilterState: "ForwardingReservationRegistrations/setFilterState",
      setFilterQualificationStatus: "ForwardingReservationRegistrations/setFilterQualificationStatus",
      closeExportForwardingReservationRegistrationsSpreadsheetModal:
        "ForwardingReservationRegistrations/closeExportForwardingReservationRegistrationsSpreadsheetModal",
      clearFields() {
        this.$store.dispatch("ForwardingReservationRegistrations/clearQuery");
      },
      getLabelCityFilter() {
        return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    getLabelEquipmentFilter() {
      return this.user_areas_filtered ? "" : "Selecione a área para listar os equipamentos";
    },
    async exportToExcel() {
      await this.$store.dispatch(
        "ForwardingReservationRegistrations/loadForwardingReservationRegistrationsForExport"
      );
    },
  },

  created() {
    this.canAcces("forwarding_reservation_registration_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("forwarding_reservation_registration_create").then((response) => {
      this.permitCreate = response;
    });
    this.canAcces("forwarding_reservation_registration_export_spreadsheet").then((response) => {
      this.permitExportSpreadsheet = response;
    });

    const user = JSON.parse(localStorage.userData);
    this.$store.dispatch("ForwardingReservationRegistrations/setUser", user);
    this.$store.dispatch("ForwardingReservationRegistrations/setUserAreas", user.areas);

    const season = JSON.parse(localStorage.season);
    this.$store.dispatch("ForwardingReservationRegistrations/setSeason", season);

    if (user.equipments.length > 0) {
      this.$store.dispatch("ForwardingReservationRegistrations/setUserEquipments", user.equipments);
    }

    this.$store.dispatch("ForwardingReservationRegistrations/loadQualificationStatuses");
    this.$store.dispatch("ForwardingReservationRegistrations/loadCompanies");
    this.$store.dispatch("ForwardingReservationRegistrations/loadPartners");
    this.$store.dispatch("ForwardingReservationRegistrations/loadInscriptions");
    this.$store.dispatch("ForwardingReservationRegistrations/loadUsers");
  },

  computed: {
    ...mapState({
      forwarding_reservation_registrations: (state) =>
        state.ForwardingReservationRegistrations.forwarding_reservation_registrations,
      modalToggleAddForwardingReservationRegistration: (state) =>
        state.ForwardingReservationRegistrations.modalToggleAddForwardingReservationRegistration,
      modalToggleDeleteForwardingReservationRegistration: (state) =>
        state.ForwardingReservationRegistrations.modalToggleDeleteForwardingReservationRegistration,
      modalToggleViewForwardingReservationRegistration: (state) =>
        state.ForwardingReservationRegistrations.modalToggleViewForwardingReservationRegistration,
      offset: (state) => state.ForwardingReservationRegistrations.forwarding_reservation_registrationsOffset,
      totalPerPage: (state) =>
        state.ForwardingReservationRegistrations.forwarding_reservation_registrationsTotalPerPage,
      totalForwardingReservationRegistrations: (state) =>
        state.ForwardingReservationRegistrations.forwarding_reservation_registrationsTotal,
      forwarding_reservation_registration: (state) =>
        state.ForwardingReservationRegistrations.forwarding_reservation_registration,
      companies: (state) => state.ForwardingReservationRegistrations.companies,
      company_filtered: (state) => state.ForwardingReservationRegistrations.params.company,
      opportunities: (state) => state.ForwardingReservationRegistrations.opportunities,
      opportunity_filtered: (state) => state.ForwardingReservationRegistrations.params.opportunity,
      incriptions_status: (state) => state.ForwardingReservationRegistrations.incriptions_status,
      inscription_status_filtered: (state) =>
        state.ForwardingReservationRegistrations.params.inscription_status,
      partners: (state) => state.ForwardingReservationRegistrations.partners,
      partner_filtered: (state) => state.ForwardingReservationRegistrations.params.partner,
      segments: (state) => state.ForwardingReservationRegistrations.segments,
      segment_filtered: (state) => state.ForwardingReservationRegistrations.params.segment,
      occupations: (state) => state.ForwardingReservationRegistrations.occupations,
      occupation_filtered: (state) => state.ForwardingReservationRegistrations.params.occupation,
      qualification_statuses: (state) => state.ForwardingReservationRegistrations.qualification_statuses,
      qualification_status_filtered: (state) =>
        state.ForwardingReservationRegistrations.params.qualification_status,
      contracting_types: (state) => state.ForwardingReservationRegistrations.contracting_types,
      contracting_type_filtered: (state) => state.ForwardingReservationRegistrations.params.contracting_type,
      feedback_types: (state) => state.ForwardingReservationRegistrations.feedback_types,
      feedback_type_filtered: (state) => state.ForwardingReservationRegistrations.params.feedback_type,
      initial_date_filtered: (state) => state.ForwardingReservationRegistrations.params.initial_date,
      final_date_filtered: (state) => state.ForwardingReservationRegistrations.params.final_date,
      participant_name_filtered: (state) => state.ForwardingReservationRegistrations.params.participant_name,
      user_areas: (state) => state.ForwardingReservationRegistrations.user_areas,
      user_areas_filtered: (state) => state.ForwardingReservationRegistrations.user_areas_filtered,
      equipments_forwarding_reservation_registrations: (state) =>
        state.ForwardingReservationRegistrations.equipments_forwarding_reservation_registrations,
      user_equipments_filtered: (state) => state.ForwardingReservationRegistrations.user_equipments_filtered,
      users: (state) => state.ForwardingReservationRegistrations.users,
      user_filtered: (state) => state.ForwardingReservationRegistrations.params.user,
      forwarding_reservation_registrations_spreadsheet: (state) =>
        state.ForwardingReservationRegistrations.forwarding_reservation_registrations_spreadsheet,
      hasSpreadsheetForwardingReservationRegistrationsResponse: (state) =>
        state.ForwardingReservationRegistrations.hasSpreadsheetForwardingReservationRegistrationsResponse,
      json_data_forwarding_reservation_registration: (state) =>
        state.ForwardingReservationRegistrations.json_data_forwarding_reservation_registration,
      modalToggleExportForwardingReservationRegistrationsSpreadsheet: (state) =>
        state.ForwardingReservationRegistrations
          .modalToggleExportForwardingReservationRegistrationsSpreadsheet,
      inscriptions: (state) => state.ForwardingReservationRegistrations.inscriptions,
      inscription_filtered: (state) => state.ForwardingReservationRegistrations.params.inscription,
      state: (state) => state.ForwardingReservationRegistrations.params.state,
      city: (state) => state.ForwardingReservationRegistrations.params.city,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
    }),
  },
  watch: {
    user_areas_filtered: function () {
      this.$store.dispatch("ForwardingReservationRegistrations/loadEquipments");
    },
    offset: function () {
      this.$store.dispatch("ForwardingReservationRegistrations/loadForwardingReservationRegistrations");
      this.backToTop();
    },
  },
};
</script>
