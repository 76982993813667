<template>
  <div>
    <div class="grid grid-cols-custom items-center -mx-12">
      <div class="mt-10 ml-5">
        <BaseText
          text="Última atualização"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Situação"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Impacto"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <!-- <div class="mt-10 ml-1">
        <BaseText
          text="Autor"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div> -->
      <div class="flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul" class="-mx-12">
      <li
        :key="matrix_risk_stratification_by_inscription.token_group"
        :data-index="index"
        v-for="(matrix_risk_stratification_by_inscription, index) in data"
      >
        <div>
          <AppCardMatrixRiskStratificationByInscription
            :matrix_risk_stratification_by_inscription="matrix_risk_stratification_by_inscription"
          />
        </div>
        <div class="border-ashes w-full border ml-5"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardMatrixRiskStratificationByInscription from "../../components/AppCardMatrixRiskStratificationByInscription.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";

export default {
  components: {
    AppCardMatrixRiskStratificationByInscription,
    BaseText,
    BaseStaggeredFade,
  },
  data() {
    return {
      teste: [],
    };
  },
  props: {
    data: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 24% 19% 16%;
}
.bg-blue-primary {
  background-color: #24c2ce;
}
.bg-green-primary {
  background-color: #3cbb66;
}
.bg-white-primary {
  background-color: #ffffff;
}
</style>
