<template>
  <div class="grid auto-grid-auto p-10">
    <div>
      <BaseText
        :text="area.city.name + ' | Áreas'"
        typeText="custom"
        weight="semibold"
        :italic="false"
        size="3xl"
        color="ashes-hover"
      />
    </div>
    <div class="my-8">
      <router-link to="/areas">
        <BaseText
          text="<- Voltar"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="capitalize"
          size="sm"
          color="carmesim"
        />
      </router-link>
    </div>
    <div>
      <BaseText
        :text="area.name + ' - ' + area.city.name"
        typeText="custom"
        weight="semibold"
        :italic="false"
        mode="capitalize"
        size="3xl"
        color="ashes-dark"
      />
      <AppButtonIcon iconName="edit" dispatch="Area/editModal" :payload="area" v-if="permitUp" />
      <AppButtonIcon
        iconName="delete"
        :payload="area"
        dispatch="Areas/openCloseDeleteAreaConfirmation"
        v-if="permitDes"
      />
    </div>
    <div class="border-b border-ashes-hover my-8">
      <BaseText
        text="Bairros de Abrangência"
        typeText="custom"
        weight="medium"
        :italic="false"
        mode="uppercase"
        size="md"
        color="carmesim"
      />
    </div>
    <ul>
      <li v-for="neighborhood in this.area.neighborhoods_attributes" :key="neighborhood.id">
        -
        <BaseText
          :text="neighborhood.name"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="capitalize"
          size="md"
        />
      </li>
    </ul>
    <div class="border-b border-ashes-hover my-8">
      <BaseText
        text="Equipamentos"
        typeText="custom"
        weight="medium"
        :italic="false"
        mode="uppercase"
        size="md"
        color="carmesim"
      />
    </div>
    <ul>
      <li v-for="equipment in this.area.equipment" :key="equipment.id">
        -
        <BaseText
          :text="equipment.name"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="capitalize"
          size="md"
        />
        -
        <BaseText
          :text="equipment.neighborhood.name"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="capitalize"
          size="md"
        />
      </li>
    </ul>
    <ModalArea :open="openModal" />
    <DeleteAreaConfirmation :open="openModalDelete" />
  </div>
</template>

<script>
import BaseText from "../components/BaseText.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import DeleteAreaConfirmation from "./alerts/DeleteAreaConfirmation.vue";
import ModalArea from "./ModalArea.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    DeleteAreaConfirmation,
    ModalArea,
    AppButtonIcon,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
    };
  },
  created() {
    let id = this.$route.params.id;
    this.areaAcces();
    this.areaChange();
    this.areaDelete();
    this.$store.dispatch("Area/loadArea", id);
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    areaAcces() {
      this.canAcces("area_index").then((resp) => {
        this.permit = resp;
      });
    },
    areaDelete() {
      this.canAcces("area_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    areaChange() {
      this.canAcces("area_update").then((resp) => {
        this.permitUp = resp;
      });
    },
  },
  computed: {
    ...mapState({
      area: (state) => state.Area.insideArea,
      openModal: (state) => state.Area.toggleModal,
      openModalDelete: (state) => state.Areas.modalDeleteAreaConfirmationOpen,
    }),
  },
};
</script>
