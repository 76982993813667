<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            text="Falta Justificada"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Presence/closeJustificationModal" :payload="justification" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div>
        <BaseTextArea
          active
          inputName="descrição"
          required
          labelText="Descrição"
          showLabel
          rows="4"
          :value="justification"
          dispatch="Presence/setJustification"
        />
      </div>
      <div :key="componentKey">
        <AppButtonUploadFile2
          id="file-justificationdocument"
          :id_file="`${idFileStudentJustificationDocument}`"
          name="justificationdocument"
          text="+  Anexar Justificativa"
          dispatch="ModalInscription/setDocumentInscriptionJustificationDocument"
          type-document="JustificationDocument"
          label-text="Anexar Documento"
          show-label
          color="ashes-dark"
        />
        <div class="-mt-8" :key="componentKey">
          <AppButtonIcon
            iconName="delete"
            :payload="idFileStudentJustificationDocument"
            dispatch="ModalInscription/deleteStudentDocumentJustificationDocument"
          />
          <AppButtonIcon
            iconName="download"
            :payload="idFileStudentJustificationDocument"
            dispatch="ModalInscription/showStudentDocumentJustificationDocument"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <a href="javascript:void(0)" @click="closeJustificationModal()">
          <div class="justify-self-start">
            <AppButtonEmpty text="descartar" typeButton="danger" :payload="justification" />
          </div>
        </a>
        <a href="javascript:void(0)" @click="updatePresence()">
          <div class="justify-self-end">
            <AppButtonEmpty text="Salvar" typeButton="success" :payload="justification" />
          </div>
        </a>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseTextArea from "../components/forms/BaseTextArea.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import AppButtonUploadFile2 from "../components/AppButtonUploadFile2.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseModal,
    BaseTextArea,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
    AppButtonUploadFile2,
  },
  props: {
    open: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    justification: "",
    componentKey: 0,
  }),
  created() {
    this.justification = this.presence.justification;
  },
  updated() {
    this.justification = this.presence.justification;
  },
  computed: {
    ...mapState({
      presence: (state) => state.Presence.presence,
      student: (state) => state.Presence.student,
      idFileStudentJustificationDocument: (state) =>
        state.ModalInscription.idFileStudentJustificationDocument,
    }),
  },
  methods: {
    async updatePresence() {
      await this.$store.dispatch("Presence/updatePresence", this.justification);
      let id = this.$route.params.id;
      this.$store.dispatch("Meeting/loadMeeting", id);
      // this.updateComponentKey();
    },
    async closeJustificationModal() {
      await this.$store.dispatch("Presence/closeJustificationModal", this.justification);
      let id = this.$route.params.id;
      this.$store.dispatch("Meeting/loadMeeting", id);
      // this.updateComponentKey();
    },
    updateComponentKey() {
      this.componentKey++;
    },
  },
};
</script>
