<template>
  <div class="grid auto-grid-auto p-10">
    <div class="container1 h-[80vh] md:h-[500px] w-full overflow-hidden">
      <div class="px-8 pb-7 text-center md:text-left">
        <img class="w-3/4 md:w-1/2 mx-auto md:mx-0" src="../assets/images/logo-name-green.png" alt="" />
      </div>
      <div class="container2 w-full h-full overflow-y-auto px-4">
        <div class="m-5">
          <BaseText
            text="Inscrição Interna"
            type-text="custom"
            weight="medium"
            mode="capitalize"
            color="burnedYellow"
            size="2xl md:3xl"
            class="text-center md:text-left"
          />
        </div>
        <div class="m-5 text-sm md:text-base leading-relaxed">
          <BaseText type-text="custom" weight="normal" color="quantum" size="base">
            Preencha os dados abaixo e fique atento aos dados obrigatórios. Vale lembrar que a
            <BaseText type-text="custom" weight="bold" color="quantum" size="base">
              faixa etária para participar do programa é de 15 a 22 anos,
            </BaseText>
            e que
            <BaseText type-text="custom" weight="bold" color="quantum" size="base">
              o jovem não deve estar estudando ou trabalhando. <br />
            </BaseText>
            Vamos começar?
          </BaseText>
        </div>
        <div class="mr-6 flex justify-center items-center" v-if="false">
          <AppButtonUploadImage
            id="foto"
            text="Fotos"
            typeDocument="cpf"
            name="foto"
            dispatch="Student/setAvatar"
          />
        </div>
        <div class="flex justify-end items-center">
          <div class="mr-5">
            <a
              href="/docs/FICHA_DE_PRE_CADASTRO_ATUALIZADA_2024.pdf"
              target="_blank"
              class="text-ashes-dark underline hover:text-burnedYellow"
            >
              <BaseText
                text="Imprimir formulário em branco"
                type-text="custom"
                weight="medium"
                size="sm"
                class="align-middle mr-2"
              />
              <AppMaterialIcon icon-name="print" class="align-middle" />
            </a>
          </div>
        </div>
        <FormStudent />
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mx-5">
      <div class="justify-self-start">
        <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Default/toggleModalPreInscription" />
      </div>
      <div class="justify-self-end">
        <AppButtonEmpty text="Finalizar" typeButton="success" dispatch="Default/saveInscription" />
      </div>
    </div>
  </div>
</template>

<script>
import FormStudent from "./FormStudent.vue";
import AppButtonUploadImage from "../components/AppButtonUploadImage.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import AppMaterialIcon from "../components/AppMaterialIcon.vue";
import BaseText from "../components/BaseText.vue";

export default {
  components: {
    AppButtonEmpty,
    AppButtonUploadImage,
    AppMaterialIcon,
    BaseText,
    FormStudent,
  },
  async created() {
    window.scrollTo(0, 0);
    await this.$store.dispatch("Inscription/load", null);
  },
  props: {
    open: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.container1 {
  width: 100%;
}

.container2 {
  width: 100%;
}
</style>
