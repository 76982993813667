<template>
  <div>
    <div class="grid grid-cols-1">
      <div class="justify-self-end" v-if="permit && season_date_manual_execution_end && isTeamsInProgress()">
        <AppButtonColorful
          text="Adicionar Encontro"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="Meeting/toggleModal"
        />
      </div>
    </div>
    <MeetingsCards :data="meetings" />
    <ModalMeeting :open="modalOpen" :data="meetings" />
  </div>
</template>

<script>
import MeetingsCards from "../list/MeetingsCards.vue";
import ModalMeeting from "../ModalMeeting.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    MeetingsCards,
    ModalMeeting,
    AppButtonColorful,
  },
  data() {
    return {
      permit: false,
      season_date_manual_execution_end: true,
    };
  },
  computed: {
    ...mapState({
      meetings: (state) => state.Meetings.meetings,
      modalOpen: (state) => state.Meeting.toggleModal,
      season: (state) => state.Seasons.season,
      tabTypeTeamView: (state) => state.Teams.tabTypeTeamView,
    }),
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    permitAddMeeting() {
      this.canAcces("meeting_create").then((resp) => {
        this.permit = resp;
      });
    },
    getSeasonDateManualExecutionEnd() {
      this.season_date_manual_execution_end =
        localStorage.getItem("season_date_manual_execution_end") === "null" ? true : false;
    },
    isTeamsInProgress() {
      return this.tabTypeTeamView === "teams_in_progress";
    },
  },
  created() {
    this.permitAddMeeting();
    this.getSeasonDateManualExecutionEnd();
  },
};
</script>
