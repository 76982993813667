<template>
  <div>
    <div class="grid grid-cols-2 items-center mt-4">
      <div>
        <div class="ml-7">
          <BaseText
            text="Nome"
            typeText="custom"
            weight="medium"
            :italic="false"
            mode="uppercase"
            size="xs"
            color="quantum"
          />
        </div>
      </div>
      <div class="flex justify-start gap-20">
        <div>
          <BaseText
            text="Presença"
            typeText="custom"
            weight="medium"
            :italic="false"
            mode="uppercase"
            size="xs"
            color="quantum"
          />
        </div>
        <div>
          <BaseText
            text="Falta Justificada"
            typeText="custom"
            weight="medium"
            :italic="false"
            mode="uppercase"
            size="xs"
            color="quantum"
          />
        </div>
      </div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li :key="presence.id" :data-index="index" v-for="(presence, index) in data">
        <div>
          <AppCardPresence :presence="presence" />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardPresence from "../../components/AppCardPresence.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";

export default {
  components: {
    AppCardPresence,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },
};
</script>
