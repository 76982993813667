import { getRoles } from "../../service/RoleApi";

const state = {
  roles: [],
};

const mutations = {
  setRolesMutation: (state, roles) => {
    state.roles = roles;
  },
};

const actions = {
  setRoles: ({ commit }, payload) => {
    commit("setRolesMutation", payload);
  },
  loadRoles: ({ dispatch }) => {
    getRoles()
      .then((response) => {
        dispatch("setRoles", response.data.roles);
      })
      .catch((e) => {
        console.log(e);
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
