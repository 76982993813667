<template>
  <div class="font-system" v-if="permit && open">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-2xl mx-2 col-span-2">
        Encaminhamento de Inserções Produtivas
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-forwarding_productive_insertion"
          showIcon
          iconName="add"
          text="Cadastrar Encaminhamento"
          dispatch="ForwardingProductiveInsertions/openModalAddForwardingProductiveInsertion"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Status da Matrícula"
            select-placeholder="Escolha a opção para buscar"
            :multiple-option="false"
            :value="inscription_status_filtered"
            :options="incriptions_status"
            :dispatch="setFilterInscriptionStatus"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            label-text="Filtrar por Nome do Aluno(a)"
            show-label
            clear-on-select
            select-placeholder="Digite o nome do aluno(a) para busca"
            :multiple-option="false"
            :value="inscription_filtered"
            :options="inscriptions"
            :dispatch="setFilterInscription"
            load-dispatch="ForwardingProductiveInsertions/loadInscriptions"
          />
        </div>
        <div class="col-span-4">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setFilterCity"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Área"
            label-text="Pesquisar por Área"
            :multiple-option="false"
            :value="user_areas_filtered"
            :options="user_areas"
            :dispatch="setUserAreasFiltered"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Equipamento"
            :multiple-option="false"
            :value="user_equipments_filtered"
            :options="equipments_forwarding_productive_insertions"
            :label-text="getLabelEquipmentFilter()"
            :dispatch="setFilterEquipment"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Empresa"
            select-placeholder="Digite o nome da empresa para buscar"
            :multiple-option="false"
            :value="company_filtered"
            :options="companies"
            :dispatch="setFilterCompany"
            load-dispatch="ForwardingProductiveInsertions/loadCompanies"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Parceiro"
            select-placeholder="Digite o nome do parceiro para buscar"
            :multiple-option="false"
            :value="partner_filtered"
            :options="partners"
            :dispatch="setFilterPartner"
            load-dispatch="ForwardingProductiveInsertions/loadPartners"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Segmento"
            select-placeholder="Digite o nome do segmento para buscar"
            :multiple-option="false"
            :value="segment_filtered"
            :options="segments"
            :dispatch="setFilterSegment"
            load-dispatch="ForwardingProductiveInsertions/loadSegments"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Ocupação"
            select-placeholder="Digite o nome da ocupação para buscar"
            :multiple-option="false"
            :value="occupation_filtered"
            :options="occupations"
            :dispatch="setFilterOccupation"
            load-dispatch="ForwardingProductiveInsertions/loadOccupations"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Seleção"
            select-placeholder="Digite o nome da seleção para buscar"
            :multiple-option="false"
            :value="opportunity_filtered"
            :options="opportunities"
            :dispatch="setFilterOpportunity"
            load-dispatch="ForwardingProductiveInsertions/loadOpportunities"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Status Qualificação"
            label-text="Pesquisar por Status Qualificação"
            :multiple-option="false"
            :value="qualification_status_filtered"
            :options="qualification_statuses"
            :dispatch="setFilterQualificationStatus"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Tipo da Contratação"
            select-placeholder="Selecione o tipo da ocupação"
            :multiple-option="false"
            :value="contracting_type_filtered"
            :options="contracting_types"
            :dispatch="setFilterContractingType"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Devolutiva"
            select-placeholder="Selecione o tipo da devolutiva"
            :multiple-option="false"
            :value="feedback_type_filtered"
            :options="feedback_types"
            :dispatch="setFilterFeedbackType"
          />
        </div>
        <div class="col-span-4">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Usuário(a) responsável pelo cadastro"
            select-placeholder="Digite o nome do usuário para buscar"
            :multiple-option="false"
            :value="user_filtered"
            :options="users"
            :dispatch="setFilterUser"
            load-dispatch="ForwardingProductiveInsertions/loadUsers"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="filter_by_initial_date"
            input-type="date"
            :value="initial_date_filtered"
            show-label
            label-text="Data do Encaminhamento - Data Inicial"
            dispatch="ForwardingProductiveInsertions/setFilterInitialDate"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="filter_by_final_date"
            input-type="date"
            :value="final_date_filtered"
            show-label
            label-text="Data do Encaminhamento - Data Final"
            dispatch="ForwardingProductiveInsertions/setFilterFinalDate"
          />
        </div>
      </FilterComponent>
      <div class="mt-3">
        <a
          href="javascript:void(0)"
          @click="exportToExcel()"
          v-if="forwarding_productive_insertions.length > 0 && permitExportSpreadsheet"
        >
          <img
            class="inline-block"
            src="@/assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
      </div>
      <BaseModal
        v-if="
          forwarding_productive_insertions_spreadsheet.length > 0 &&
          modalToggleExportForwardingProductiveInsertionsSpreadsheet
        "
      >
        <template v-slot:header>
          <div class="grid grid-cols-3">
            <div class="justify-self-start col-span-2">
              <Label
                text="Exportar Planilha de Encaminhamentos Produtivos"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon
                dispatch="ForwardingProductiveInsertions/closeExportForwardingProductiveInsertionsSpreadsheetModal"
                forClose
              />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="ForwardingProductiveInsertions/closeExportForwardingProductiveInsertionsSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data_forwarding_productive_insertion"
                  :before-finish="closeExportForwardingProductiveInsertionsSpreadsheetModal"
                  worksheet="Encaminhamentos_Produtivos"
                  name="encaminhamentos_produtivos.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetForwardingProductiveInsertionsResponse">
        <PreLoading />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-forwarding_productive_insertions"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="ForwardingProductiveInsertions/loadForwardingProductiveInsertions"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="forwarding_productive_insertions.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-forwarding_productive_insertions"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-forwarding_productive_insertions">
      <div class="mx-12">
        <ForwardingProductiveInsertionsCards :data="forwarding_productive_insertions" />
      </div>
      <div
        id="forwarding_productive_insertions-pagination"
        class="mx-12 my-6 pb-3"
        v-if="forwarding_productive_insertions.length > 0"
      >
        <BasePagination
          :total="totalForwardingProductiveInsertions"
          :total-per-page="totalPerPage"
          dispatch="ForwardingProductiveInsertions/setForwardingProductiveInsertionsOffset"
        />
      </div>
      <ModalForwardingProductiveInsertion :open="modalToggleAddForwardingProductiveInsertion" />
      <ModalViewForwardingProductiveInsertionData :open="modalToggleViewForwardingProductiveInsertion" />
      <DeleteForwardingProductiveInsertionConfirmation
        :open="modalToggleDeleteForwardingProductiveInsertion"
      />
    </div>
  </div>
</template>

<script>
import ForwardingProductiveInsertionsCards from "../list/ForwardingProductiveInsertionsCards.vue";
import BasePagination from "../../components/BasePagination.vue";
import ModalForwardingProductiveInsertion from "./modals/ModalForwardingProductiveInsertion.vue";
import ModalViewForwardingProductiveInsertionData from "../datas/ModalViewForwardingProductiveInsertionData.vue";
import DeleteForwardingProductiveInsertionConfirmation from "../alerts/DeleteForwardingProductiveInsertionConfirmation.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";
import Multiselect from "../../components/forms/Multiselect.vue";
import BaseModal from "@/components/BaseModal.vue";
import PreLoading from "@/components/PreLoading.vue";
import Label from "@/components/Label.vue";
import BaseText from "@/components/BaseText.vue";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    AppButtonIcon,
    AppButtonEmpty,
    BaseText,
    Label,
    BaseModal,
    Multiselect,
    BasePagination,
    AppButtonColorful,
    ForwardingProductiveInsertionsCards,
    DeleteForwardingProductiveInsertionConfirmation,
    BaseInput,
    ModalForwardingProductiveInsertion,
    ModalViewForwardingProductiveInsertionData,
    PreLoading,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
      permitExportSpreadsheet: false,
      json_fields: {
        Status: "participant_status",
        Área: "area",
        Equipamento: "equipment",
        Seleção: "opportunity",
        Nome: "participant_name",
        Parceiro: "partner",
        Empresa: "company",
        Segmento: "segment",
        Ocupação: "occupation",
        Contratação: "contracting_type",
        Usuário: "user",
        Devolutiva: "feedback_type",
        "Data do Encaminhamento": "forwarding_date",
        "Nº da vez do Encaminhamento": "forwarding_position",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterOpportunity: "ForwardingProductiveInsertions/setFilterOpportunity",
      setFilterCompany: "ForwardingProductiveInsertions/setFilterCompany",
      setFilterInscriptionStatus: "ForwardingProductiveInsertions/setFilterInscriptionStatus",
      setFilterPartner: "ForwardingProductiveInsertions/setFilterPartner",
      setFilterSegment: "ForwardingProductiveInsertions/setFilterSegment",
      setFilterCity: "ForwardingProductiveInsertions/setFilterCity",
      setFilterState: "ForwardingProductiveInsertions/setFilterState",
      setFilterInscription: "ForwardingProductiveInsertions/setFilterInscription",
      setFilterOccupation: "ForwardingProductiveInsertions/setFilterOccupation",
      setFilterContractingType: "ForwardingProductiveInsertions/setFilterContractingType",
      setFilterFeedbackType: "ForwardingProductiveInsertions/setFilterFeedbackType",
      setUserAreasFiltered: "ForwardingProductiveInsertions/setUserAreasFiltered",
      setFilterEquipment: "ForwardingProductiveInsertions/setFilterEquipment",
      setFilterUser: "ForwardingProductiveInsertions/setFilterUser",
      setFilterQualificationStatus: "ForwardingProductiveInsertions/setFilterQualificationStatus",
      closeExportForwardingProductiveInsertionsSpreadsheetModal:
        "ForwardingProductiveInsertions/closeExportForwardingProductiveInsertionsSpreadsheetModal",
      clearFields() {
        this.$store.dispatch("ForwardingProductiveInsertions/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    getLabelEquipmentFilter() {
      return this.user_areas_filtered ? "" : "Selecione a área para listar os equipamentos";
    },
    async exportToExcel() {
      await this.$store.dispatch(
        "ForwardingProductiveInsertions/loadForwardingProductiveInsertionsForExport"
      );
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
  },

  created() {
    this.canAcces("forwarding_productive_insertion_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("forwarding_productive_insertion_create").then((response) => {
      this.permitCreate = response;
    });
    this.canAcces("forwarding_productive_insertion_export_spreadsheet").then((response) => {
      this.permitExportSpreadsheet = response;
    });

    const user = JSON.parse(localStorage.userData);
    this.$store.dispatch("ForwardingProductiveInsertions/setUser", user);
    this.$store.dispatch("ForwardingProductiveInsertions/setUserAreas", user.areas);

    const season = JSON.parse(localStorage.season);
    this.$store.dispatch("ForwardingProductiveInsertions/setSeason", season);

    if (user.equipments.length > 0) {
      this.$store.dispatch("ForwardingProductiveInsertions/setUserEquipments", user.equipments);
    }

    this.$store.dispatch("ForwardingProductiveInsertions/loadContractingTypes");
    this.$store.dispatch("ForwardingProductiveInsertions/loadFeedbackTypes");
    this.$store.dispatch("ForwardingProductiveInsertions/loadQualificationStatuses");
    this.$store.dispatch("ForwardingProductiveInsertions/loadCompanies");
    this.$store.dispatch("ForwardingProductiveInsertions/loadPartners");
    this.$store.dispatch("ForwardingProductiveInsertions/loadSegments");
    this.$store.dispatch("ForwardingProductiveInsertions/loadOccupations");
    this.$store.dispatch("ForwardingProductiveInsertions/loadInscriptions");
    this.$store.dispatch("ForwardingProductiveInsertions/loadUsers");
    this.$store.dispatch("ForwardingProductiveInsertions/loadOpportunities");
    this.$store.dispatch("UtilFunctions/loadCities");
  },

  computed: {
    ...mapState({
      forwarding_productive_insertions: (state) =>
        state.ForwardingProductiveInsertions.forwarding_productive_insertions,
      modalToggleAddForwardingProductiveInsertion: (state) =>
        state.ForwardingProductiveInsertions.modalToggleAddForwardingProductiveInsertion,
      modalToggleDeleteForwardingProductiveInsertion: (state) =>
        state.ForwardingProductiveInsertions.modalToggleDeleteForwardingProductiveInsertion,
      modalToggleViewForwardingProductiveInsertion: (state) =>
        state.ForwardingProductiveInsertions.modalToggleViewForwardingProductiveInsertion,
      offset: (state) => state.ForwardingProductiveInsertions.forwarding_productive_insertionsOffset,
      totalPerPage: (state) =>
        state.ForwardingProductiveInsertions.forwarding_productive_insertionsTotalPerPage,
      totalForwardingProductiveInsertions: (state) =>
        state.ForwardingProductiveInsertions.forwarding_productive_insertionsTotal,
      forwarding_productive_insertion: (state) =>
        state.ForwardingProductiveInsertions.forwarding_productive_insertion,
      companies: (state) => state.ForwardingProductiveInsertions.companies,
      company_filtered: (state) => state.ForwardingProductiveInsertions.params.company,
      opportunities: (state) => state.ForwardingProductiveInsertions.opportunities,
      opportunity_filtered: (state) => state.ForwardingProductiveInsertions.params.opportunity,
      incriptions_status: (state) => state.ForwardingProductiveInsertions.incriptions_status,
      inscription_status_filtered: (state) => state.ForwardingProductiveInsertions.params.inscription_status,
      partners: (state) => state.ForwardingProductiveInsertions.partners,
      partner_filtered: (state) => state.ForwardingProductiveInsertions.params.partner,
      segments: (state) => state.ForwardingProductiveInsertions.segments,
      segment_filtered: (state) => state.ForwardingProductiveInsertions.params.segment,
      occupations: (state) => state.ForwardingProductiveInsertions.occupations,
      occupation_filtered: (state) => state.ForwardingProductiveInsertions.params.occupation,
      qualification_statuses: (state) => state.ForwardingProductiveInsertions.qualification_statuses,
      qualification_status_filtered: (state) =>
        state.ForwardingProductiveInsertions.params.qualification_status,
      contracting_types: (state) => state.ForwardingProductiveInsertions.contracting_types,
      contracting_type_filtered: (state) => state.ForwardingProductiveInsertions.params.contracting_type,
      feedback_types: (state) => state.ForwardingProductiveInsertions.feedback_types,
      feedback_type_filtered: (state) => state.ForwardingProductiveInsertions.params.feedback_type,
      initial_date_filtered: (state) => state.ForwardingProductiveInsertions.params.initial_date,
      final_date_filtered: (state) => state.ForwardingProductiveInsertions.params.final_date,
      participant_name_filtered: (state) => state.ForwardingProductiveInsertions.params.participant_name,
      user_areas: (state) => state.ForwardingProductiveInsertions.user_areas,
      user_areas_filtered: (state) => state.ForwardingProductiveInsertions.user_areas_filtered,
      equipments_forwarding_productive_insertions: (state) =>
        state.ForwardingProductiveInsertions.equipments_forwarding_productive_insertions,
      user_equipments_filtered: (state) => state.ForwardingProductiveInsertions.user_equipments_filtered,
      users: (state) => state.ForwardingProductiveInsertions.users,
      user_filtered: (state) => state.ForwardingProductiveInsertions.params.user,
      forwarding_productive_insertions_spreadsheet: (state) =>
        state.ForwardingProductiveInsertions.forwarding_productive_insertions_spreadsheet,
      hasSpreadsheetForwardingProductiveInsertionsResponse: (state) =>
        state.ForwardingProductiveInsertions.hasSpreadsheetForwardingProductiveInsertionsResponse,
      json_data_forwarding_productive_insertion: (state) =>
        state.ForwardingProductiveInsertions.json_data_forwarding_productive_insertion,
      modalToggleExportForwardingProductiveInsertionsSpreadsheet: (state) =>
        state.ForwardingProductiveInsertions.modalToggleExportForwardingProductiveInsertionsSpreadsheet,
      inscriptions: (state) => state.ForwardingProductiveInsertions.inscriptions,
      inscription_filtered: (state) => state.ForwardingProductiveInsertions.params.inscription,
      state: (state) => state.ForwardingProductiveInsertions.params.state,
      city: (state) => state.ForwardingProductiveInsertions.params.city,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
    }),
  },
  watch: {
    user_areas_filtered: function () {
      this.$store.dispatch("ForwardingProductiveInsertions/loadEquipments");
    },
    offset: function () {
      this.$store.dispatch("ForwardingProductiveInsertions/loadForwardingProductiveInsertions");
      this.backToTop();
    },
  },
};
</script>
