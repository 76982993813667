import { getAxios, baseURL } from "./Api";

export async function getArticulators(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `articuladores`;
  return api.get(url, { params: filters });
}

export async function getArticulatorById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`articuladores/${_id}`);
}

export async function createArticulator(articulator) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/articuladores", articulator);
}

export async function updateArticulator(id, articulator) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`articuladores/${id}`, articulator);
}

export async function destroyArticulator(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`articuladores/${id}`);
}
