<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-4">
        <div class="justify-self-start col-span-3">
          <Label
            v-if="psychosocial_accompaniment.id"
            text="Editar Justificativa Para Não Acolhimento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar Justificativa Para Não Acolhimento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon
            dispatch="PsychosocialAccompaniments/closeEditModalPsychosocialAccompanimentJustifyNonReception"
            forClose
          />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-4 gap-4 mt-4" v-if="psychosocial_accompaniment.id">
        <div class="col-span-4 text-burnedYellow pb-2">
          <Label
            text="Informações do Aluno(a)"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <AppTextField
            show-label
            label-text="Nome"
            :text="inscription.student.name"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Status do Aluno(a) no sistema"
            v-bind:text="verifyStatus()"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="CPF"
            v-bind:text="verifyInscription('cpf') | VMask('###.###.###-##')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Data de Nascimento"
            v-bind:text="verifyInscription('birthdate')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Idade"
            v-bind:text="verifyInscription('age')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Número Contato"
            v-bind:text="verifyInscription('cellphone_number') | VMask('(##) #####-####')"
            type-text="topic-details"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Justificativa"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <BaseTextArea
            active
            inputName="Justificar Não Acolhimento"
            labelText="Justificar Não Acolhimento"
            showLabel
            rows="4"
            :value="psychosocial_accompaniment?.justification_for_non_acceptance"
            dispatch="PsychosocialAccompaniments/setJustificationForNonAcceptance"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="PsychosocialAccompaniments/closeEditModalPsychosocialAccompanimentJustifyNonReception"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="psychosocial_accompaniment.id"
            text="Salvar"
            typeButton="success"
            dispatch="PsychosocialAccompaniments/update"
          />
          <AppButtonEmpty
            v-else
            text="criar"
            typeButton="success"
            dispatch="PsychosocialAccompaniments/save"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import AppTextField from "../../components/AppTextField.vue";
import BaseModal from "../../components/BaseModal.vue";
import BaseTextArea from "../../components/forms/BaseTextArea.vue";
import Label from "../../components/Label.vue";
import { mapState } from "vuex";

export default {
  components: {
    AppTextField,
    BaseModal,
    BaseTextArea,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },
  data() {
    return {};
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      inscription: (state) => state.PsychosocialAccompaniments.inscription,
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
    }),
  },
  methods: {
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    verifyStatus() {
      return this.$util_function.processInscriptionStatuses(this.inscription?.status);
    },
    verifyInscription(type) {
      if (this.inscription.student) {
        if (type === "cpf" && this.inscription.student.cpf) {
          return this.inscription.student.cpf;
        } else if (type === "birthdate" && this.inscription.student.birthdate) {
          return this.formattedDate(this.inscription.student.birthdate);
        } else if (type === "cellphone_number" && this.inscription.student.cellphone_number) {
          return this.inscription.student.cellphone_number;
        } else if (type === "age" && this.inscription.student.age) {
          return this.inscription.student.age + " anos";
        } else if (type === "equipment" && this.inscription.student.equipment) {
          return this.inscription.student.equipment.name;
        }
      }
    },
  },
};
</script>
<style>
.tooltip-text {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  font-size: 0.75rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  z-index: 1;
  transition: opacity 0.3s;
}

.opacity-100 {
  visibility: visible;
  opacity: 1;
}
</style>
