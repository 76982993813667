import { render, staticRenderFns } from "./AppCardSchoolReinsertionNotReinserteds.vue?vue&type=template&id=304661df&scoped=true"
import script from "./AppCardSchoolReinsertionNotReinserteds.vue?vue&type=script&lang=js"
export * from "./AppCardSchoolReinsertionNotReinserteds.vue?vue&type=script&lang=js"
import style0 from "./AppCardSchoolReinsertionNotReinserteds.vue?vue&type=style&index=0&id=304661df&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "304661df",
  null
  
)

export default component.exports