import { getAxios, baseURL } from "./Api";

export async function getForwardingProductiveInsertions(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `encaminhamentos_insercoes_produtiva`;
  return api.get(url, { params: filters });
}

export async function getForwardingProductiveInsertionsForExport(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `encaminhamentos_insercoes_produtiva/exportar_planilha`;
  return api.get(url, { params: filters });
}

export async function getForwardingProductiveInsertionFeedbackTypes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`encaminhamentos_insercoes_produtiva/tipos_devolutivas`);
}

export async function getForwardingProductiveInsertionById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`encaminhamentos_insercoes_produtiva/${_id}`);
}

export async function createForwardingProductiveInsertion(forwarding_productive_insertion) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/encaminhamentos_insercoes_produtiva", forwarding_productive_insertion);
}

export async function updateForwardingProductiveInsertion(id, forwarding_productive_insertion) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`encaminhamentos_insercoes_produtiva/${id}`, forwarding_productive_insertion);
}

export async function destroyForwardingProductiveInsertion(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`encaminhamentos_insercoes_produtiva/${id}`);
}
