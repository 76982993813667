<template>
  <BaseModal width="w-128" v-if="open">
    <template v-slot:header>
      <div class="flex flex-row-reverse">
        <AppButtonIcon
          for-close
          :payload="{ mutation: 'toggleModalInscriptionStatusNotAcceptedConfirmation' }"
          dispatch="ModalInscription/toggleModalInscriptionStatus"
        />
      </div>
      <div class="flex">
        <AppMaterialIcon iconName="cancel" class="text-carmesim-hover mr-2" />
        <BaseText
          text="Não Aceitar Jovem"
          typeText="custom"
          weight="semibold"
          size="2xl"
          color="ashes-dark"
        />
      </div>
    </template>
    <template v-slot:body>
      <div class="text-quantum mb-4">
        Ao clicar em <b>"Desligado"</b>, a ficha de inscrição do aluno sai da lista de status
        <b>"EM ABERTO"</b> para a lista de status <b>"DESLIGADO"</b>.
      </div>

      <div class="text-quantum">
        <b>Tem certeza que deseja reprovar o aluno?</b>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-end">
        <AppButtonEmpty
          text="Não"
          type-button="danger"
          :payload="{ mutation: 'toggleModalInscriptionStatusNotAcceptedConfirmation' }"
          dispatch="ModalInscription/toggleModalInscriptionStatus"
          class="mr-5"
        />
        <AppButtonEmpty
          text="Sim"
          type-button="success"
          :payload="{
            mutation: 'toggleModalInscriptionStatusNotAcceptedConfirmation',
            status: 'not_accepted',
          }"
          dispatch="ModalInscription/setStatusAndCloseModal"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import AppMaterialIcon from "../../components/AppMaterialIcon.vue";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonIcon,
    AppMaterialIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
    },
  },
};
</script>
