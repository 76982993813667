<template>
  <div class="font-system">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">Bairros</div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-city"
          showIcon
          iconName="add"
          text="Cadastrar Bairro"
          dispatch="Neighborhoods/openModalAddNeighborhood"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-4">
          <BaseLabel
            text="Pesquisar por nome do Bairro"
            typeText="custom"
            weight="normal"
            :italic="false"
            size="xs"
          />
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por nome"
            dispatch="Neighborhoods/setFilterName"
            :value="filter_params.name"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setFilterCity"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Área"
            :multiple-option="false"
            :value="area"
            :options="user_areas"
            label-text="Filtrar por Área"
            :dispatch="setFilterArea"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-neighborhoods"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Neighborhoods/loadNeighborhoods"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="neighborhoods.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-neighborhoods"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-neighborhoods">
      <div class="mx-12">
        <NeighborhoodsCards :data="neighborhoods" />
      </div>
      <div id="neighborhoods-pagination" class="mx-12 my-6 pb-3" v-if="neighborhoods.length > 0">
        <BasePagination
          :total="totalNeighborhoods"
          :total-per-page="totalPerPage"
          dispatch="Neighborhoods/setNeighborhoodsOffset"
        />
      </div>
      <ModalNeighborhood :open="modalToggleAddNeighborhood" />
      <DeleteNeighborhoodsConfirmation :open="modalToggleDeleteNeighborhood" />
    </div>
  </div>
</template>

<script>
import BasePagination from "@/components/BasePagination.vue";
import AppButtonColorful from "@/components/AppButtonColorful.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import BaseLabel from "@/components/BaseLabel.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import ModalNeighborhood from "@/views/ModalNeighborhood.vue";
import NeighborhoodsCards from "@/views/list/NeighborhoodsCards.vue";
import DeleteNeighborhoodsConfirmation from "@/views/alerts/DeleteNeighborhoodsConfirmation.vue";

export default {
  components: {
    DeleteNeighborhoodsConfirmation,
    ModalNeighborhood,
    NeighborhoodsCards,
    Multiselect,
    FilterComponent,
    BasePagination,
    BaseLabel,
    AppButtonColorful,
    BaseInput,
  },
  data() {
    return {
      permitCreate: false,
      permitExportSpreadsheet: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterState: "Neighborhoods/setFilterState",
      setFilterCity: "Neighborhoods/setFilterCity",
      setFilterArea: "Neighborhoods/setFilterArea",
      clearFields() {
        this.$store.dispatch("Neighborhoods/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
  },

  created() {
    this.canAcces("neighborhood_create").then((response) => {
      this.permitCreate = response;
    });
    this.$store.dispatch("UtilFunctions/loadCities");
  },

  computed: {
    ...mapState({
      neighborhoods: (state) => state.Neighborhoods.neighborhoods,
      neighborhood: (state) => state.Neighborhoods.neighborhood,
      filter_params: (state) => state.Neighborhoods.params,
      modalToggleAddNeighborhood: (state) => state.Neighborhoods.modalToggleAddNeighborhood,
      modalToggleDeleteNeighborhood: (state) => state.Neighborhoods.modalToggleDeleteNeighborhood,
      offset: (state) => state.Neighborhoods.neighborhoodsOffset,
      totalPerPage: (state) => state.Neighborhoods.neighborhoodsTotalPerPage,
      totalNeighborhoods: (state) => state.Neighborhoods.neighborhoodsTotal,
      state: (state) => state.Neighborhoods.params.state,
      city: (state) => state.Neighborhoods.params.city,
      area: (state) => state.Neighborhoods.params.area,
      states: (state) => state.UtilFunctions.states,
      cities: (state) => state.UtilFunctions.cities,
      user_areas: (state) => state.UtilFunctions.user_areas,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Neighborhoods/loadNeighborhoods");
      this.backToTop();
    },
  },
};
</script>
