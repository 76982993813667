import { getRaces } from "../../service/RacesApi";

const state = {
  races: [],
};

const mutations = {
  loadRaces: (state, races) => {
    state.races = races;
  },
};

const actions = {
  loadRaces: ({ commit }) => {
    let racesFiltered = [];
    getRaces()
      .then((response) => {
        response.data.races.forEach((item) => {
          if (item.id != 4) {
            racesFiltered.push(item);
          }
        });
        commit("loadRaces", racesFiltered);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
