import {
  addBeneficiaries,
  closePaymentSheet,
  createPaymentSheet,
  destroyPaymentSheet,
  exportPaymentSheetPdf,
  getPaymentSheetById,
  getPaymentSheets,
  getPaymentSheetsForExport,
  openPaymentSheet,
  updatePaymentSheet,
} from "../../service/PaymentSheetsApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);

const state = {
  payment_sheet: {
    id: null,
    payment_sheet_type: null,
    process_number: null,
    reference: null,
    start_date: null,
    end_date: null,
    installment: null,
    remittance: null,
    status: null,
    scholarship_matrix_id: null,
    season_id: null,
    closed: false,
    total_beneficiaries: false,
    total_payment_value: false,
  },

  payment_sheets: [],
  payment_sheets_spreadsheet: [],
  teams_student_ids: [],
  errorMessages: [],
  payment_sheetId: null,
  installment: null,
  remittance: null,
  status: null,
  scholarship_matrix: null,
  payment_sheet_type: null,

  modalToggleAddPaymentSheet: false,
  modalToggleDeletePaymentSheet: false,
  modalToggleViewPaymentSheet: false,
  modalToggleExportPaymentSheetsSpreadsheet: false,
  modalToggleAddBeneficiariesScholarship: false,

  hasSpreadsheetPaymentSheetsResponse: true,
  json_data_payment_sheet: null,

  loading_variables: {
    add_beneficiaries: false,
    export_pdf: false,
  },

  payment_sheetsTotal: 0,
  payment_sheetsTotalPerPage: 10,
  payment_sheetsOffset: 0,

  params: {
    payment_sheet_type: null,
    status: null,
    process_number: null,
    reference: null,
    scholarship_matrix: null,
    installment: null,
    remittance: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadPaymentSheets: (state, payment_sheets) => {
    state.payment_sheets = payment_sheets;
  },

  // MUTATIONS - FIELDS TO API
  setIdMutation: (state, id) => {
    state.payment_sheet.id = id;
  },
  setPaymentSheetTypeMutation: (state, payment_sheet_type) => {
    console.log("payment_sheet_type mutation", payment_sheet_type);
    state.payment_sheet_type = payment_sheet_type;
    state.payment_sheet.payment_sheet_type = payment_sheet_type ? payment_sheet_type.id : null;
  },
  setProcessNumberMutation: (state, process_number) => {
    state.payment_sheet.process_number = process_number;
  },
  setReferenceMutation: (state, reference) => {
    state.payment_sheet.reference = reference;
  },
  setStartDateMutation: (state, start_date) => {
    state.payment_sheet.start_date = start_date;
  },
  setEndDateMutation: (state, end_date) => {
    state.payment_sheet.end_date = end_date;
  },
  setInstallmentMutation: (state, installment) => {
    state.installment = installment;
    state.payment_sheet.installment = installment ? installment.id : null;
  },
  setRemittanceMutation: (state, remittance) => {
    state.remittance = remittance;
    state.payment_sheet.remittance = remittance ? remittance.id : null;
  },
  setStatusMutation: (state, status) => {
    state.status = status;
    state.payment_sheet.status = status ? status.id : null;
  },
  setScholarshipMatrixMutation: (state, scholarship_matrix) => {
    state.scholarship_matrix = scholarship_matrix;
    state.payment_sheet.scholarship_matrix_id = scholarship_matrix ? scholarship_matrix.id : null;
  },
  setSeasonMutation: (state, season) => {
    state.payment_sheet.season_id = season ? season.id : null;
  },
  setClosedMutation: (state, closed) => {
    state.payment_sheet.closed = closed;
  },
  setTotalBeneficiariesMutation: (state, total_beneficiaries) => {
    state.payment_sheet.total_beneficiaries = total_beneficiaries;
  },
  setTotalPaymentValueMutation: (state, total_payment_value) => {
    state.payment_sheet.total_payment_value = total_payment_value;
  },
  addStudentId(state, studentId) {
    if (!state.teams_student_ids.includes(studentId)) {
      state.teams_student_ids.push(studentId);
    }
  },
  removeStudentId(state, studentId) {
    const index = state.teams_student_ids.indexOf(studentId);
    if (index !== -1) {
      state.teams_student_ids.splice(index, 1);
    }
  },
  // --> MUTATIONS - DELETION <--
  payment_sheetForDeletion: (state, payload) => {
    if (payload) {
      state.payment_sheetId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterPaymentSheetTypeMutation: (state, payment_sheet_type) => {
    state.params.payment_sheet_type = payment_sheet_type;
  },
  setFilterStatusMutation: (state, status) => {
    state.params.status = status;
  },
  setFilterProcessNumberMutation: (state, process_number) => {
    state.params.process_number = process_number;
  },
  setFilterReferenceMutation: (state, reference) => {
    state.params.reference = reference;
  },
  setFilterScholarshipMatrixMutation: (state, scholarship_matrix) => {
    state.params.scholarship_matrix = scholarship_matrix;
  },
  setFilterInstallmentMutation: (state, installment) => {
    state.params.installment = installment;
  },
  setFilterRemittanceMutation: (state, remittance) => {
    state.params.remittance = remittance;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddPaymentSheet: (state) => {
    state.modalToggleAddPaymentSheet = !state.modalToggleAddPaymentSheet;
  },
  toggleModalDeletePaymentSheet: (state) => {
    state.modalToggleDeletePaymentSheet = !state.modalToggleDeletePaymentSheet;
  },
  toggleModalViewPaymentSheet: (state) => {
    state.modalToggleViewPaymentSheet = !state.modalToggleViewPaymentSheet;
  },
  toggleModalAddBeneficiariesScholarship: (state) => {
    state.modalToggleAddBeneficiariesScholarship = !state.modalToggleAddBeneficiariesScholarship;
  },

  // MUTATIONS - TOGGLES AND MODALS [LOADINGS]
  toggleLoadingVariablesAddBeneficiaries: (state) => {
    state.loading_variables.add_beneficiaries = !state.loading_variables.add_beneficiaries;
  },
  toggleLoadingVariablesExportPdf: (state) => {
    state.loading_variables.export_pdf = !state.loading_variables.export_pdf;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportPaymentSheetsSpreadsheet: (state) => {
    state.modalToggleExportPaymentSheetsSpreadsheet = !state.modalToggleExportPaymentSheetsSpreadsheet;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setPaymentSheetsTotalPerPage: (state, payment_sheetsTotalPerPage) => {
    state.payment_sheetsTotalPerPage = payment_sheetsTotalPerPage;
  },
  setPaymentSheetsOffset: (state, payment_sheetsOffset) => {
    state.payment_sheetsOffset = payment_sheetsOffset;
  },
  setPaymentSheetsTotal: (state, payment_sheetsTotal) => {
    state.payment_sheetsTotal = payment_sheetsTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.payment_sheet) {
      if (prop !== "payment_sheet_type") {
        state.payment_sheet[prop] = null;
      }
    }
    state.installment = null;
    state.remittance = null;
    state.status = null;
    state.scholarship_matrix = null;
    state.payment_sheet.closed = false;

    const season = JSON.parse(localStorage.getItem("season"));
    state.payment_sheet.season_id = season ? season.id : null;
  },
  clearTeamsStudentField: (state) => {
    state.teams_student_ids = [];
  },
  clearQuery: (state) => {
    state.params.status = null;
    state.params.process_number = null;
    state.params.reference = null;
    state.params.scholarship_matrix = null;
    state.params.installment = null;
    state.params.remittance = null;
    state.payment_sheets = [];
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setPaymentSheetsSpreadsheetMutation: (state, payment_sheets_spreadsheet) => {
    state.payment_sheets_spreadsheet = payment_sheets_spreadsheet;
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

function getFilterParams() {
  return {
    name: state.params.name,
    status: state.params.status ? state.params.status.id : null,
    payment_sheet_type: state.params.payment_sheet_type ? state.params.payment_sheet_type.id : null,
    process_number: state.params.process_number,
    reference: state.params.reference,
    remittance: state.params.remittance ? state.params.remittance.id : null,
    scholarship_matrix: state.params.scholarship_matrix ? state.params.scholarship_matrix.id : null,
    installment: state.params.installment ? state.params.installment.id : null,
    per_page: state.payment_sheetsTotalPerPage,
    offset: state.payment_sheetsOffset,
  };
}

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadPaymentSheets: ({ commit }, from) => {
    const params = getFilterParams();
    getPaymentSheets(params)
      .then((response) => {
        commit("loadPaymentSheets", response.data.payment_sheets);
        commit("setPaymentSheetsTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadPaymentSheetsForExport: ({ commit }) => {
    commit("hasSpreadsheetPaymentSheetsResponseMutation", false);
    const params = getFilterParams();
    getPaymentSheetsForExport(params)
      .then(async (response) => {
        commit("setPaymentSheetsSpreadsheetMutation", response.data.payment_sheets);
        if (state.payment_sheets_spreadsheet.length > 0) {
          await commit("setPaymentSheetsPageToExportMutation", response.data.payment_sheets);
        } else {
          commit("hasSpreadsheetPaymentSheetsResponseMutation", true);
          commit("toggleModalToggleExportPaymentSheetsSpreadsheet");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadData: async ({ commit }, payload) => {
    console.log("payload", payload);
    return getPaymentSheetById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.payment_sheets.id);
        commit("setPaymentSheetTypeMutation", response.data.payment_sheets.payment_sheet_type);
        commit("setProcessNumberMutation", response.data.payment_sheets.process_number);
        commit("setReferenceMutation", response.data.payment_sheets.reference);
        commit("setStartDateMutation", response.data.payment_sheets.start_date);
        commit("setEndDateMutation", response.data.payment_sheets.end_date);
        commit("setScholarshipMatrixMutation", response.data.payment_sheets.scholarship_matrix);
        commit("setStatusMutation", response.data.payment_sheets.status);
        commit("setInstallmentMutation", response.data.payment_sheets.installment);
        commit("setRemittanceMutation", response.data.payment_sheets.remittance);
        commit("setClosedMutation", response.data.payment_sheets.closed);
        commit("setSeasonMutation", response.data.payment_sheets.season);
        commit("setTotalBeneficiariesMutation", response.data.payment_sheets.total_beneficiaries);
        commit("setTotalPaymentValueMutation", response.data.payment_sheets.total_payment_value);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setPaymentSheetType: ({ commit }, payload) => {
    console.log("payload", payload);
    commit("setPaymentSheetTypeMutation", payload);
  },
  setProcessNumber: ({ commit }, payload) => {
    commit("setProcessNumberMutation", payload.value);
  },
  setReference: ({ commit }, payload) => {
    commit("setReferenceMutation", payload.value);
  },
  setStartDate: ({ commit }, payload) => {
    commit("setStartDateMutation", payload.value);
  },
  setEndDate: ({ commit }, payload) => {
    commit("setEndDateMutation", payload.value);
  },
  setInstallment: ({ commit }, payload) => {
    commit("setInstallmentMutation", payload);
  },
  setRemittance: ({ commit }, payload) => {
    commit("setRemittanceMutation", payload);
  },
  setStatus: ({ commit }, payload) => {
    commit("setStatusMutation", payload);
  },
  setScholarshipMatrix: ({ commit }, payload) => {
    commit("setScholarshipMatrixMutation", payload);
  },
  setClosed: ({ commit }, payload) => {
    commit("setClosedMutation", payload);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddPaymentSheet: ({ commit }) => {
    commit("toggleModalAddPaymentSheet");
  },
  closeModalAddPaymentSheet: ({ commit }) => {
    commit("toggleModalAddPaymentSheet");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openModalAddBeneficiariesScholarship: ({ commit }) => {
    commit("toggleModalAddBeneficiariesScholarship");
  },
  closeModalAddBeneficiariesScholarship: ({ commit, dispatch }) => {
    commit("toggleModalAddBeneficiariesScholarship");
    commit("clearTeamsStudentField");
    dispatch("Advantages/clearTeams", null, { root: true });
  },
  openEditModalAddPaymentSheet: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddPaymentSheet");
  },
  closeEditModalAddPaymentSheet: ({ commit }) => {
    commit("toggleModalAddPaymentSheet");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewPaymentSheet");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewPaymentSheet");
    commit("clearFields");
  },
  toggleModalDeletePaymentSheet: ({ commit }, payload) => {
    commit("toggleModalDeletePaymentSheet");
    if (state.modalToggleDeletePaymentSheet && payload) {
      commit("payment_sheetForDeletion", payload);
    } else {
      commit("payment_sheetForDeletion", null);
    }
  },
  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportPaymentSheetsSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPaymentSheetsSpreadsheet");
  },
  closeExportPaymentSheetsSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPaymentSheetsSpreadsheet");
  },

  // --> ACTIONS - FILTERLIST
  setFilterPaymentSheetType: ({ commit }, payload) => {
    commit("setFilterPaymentSheetTypeMutation", payload);
  },
  setFilterStatus: ({ commit }, payload) => {
    commit("setFilterStatusMutation", payload);
  },
  setFilterProcessNumber: ({ commit }, payload) => {
    commit("setFilterProcessNumberMutation", payload.value);
  },
  setFilterReference: ({ commit }, payload) => {
    commit("setFilterReferenceMutation", payload.value);
  },
  setFilterScholarshipMatrix: ({ commit }, payload) => {
    commit("setFilterScholarshipMatrixMutation", payload);
  },
  setFilterInstallment: ({ commit }, payload) => {
    commit("setFilterInstallmentMutation", payload);
  },
  setFilterRemittance: ({ commit }, payload) => {
    commit("setFilterRemittanceMutation", payload);
  },

  // --> ACTIONS - PAGINATIONS <--
  setPaymentSheetsTotalPerPage: ({ commit }, payload) => {
    commit("setPaymentSheetsTotalPerPage", payload.totalPerPage);
  },
  setPaymentSheetsOffset: ({ commit }, payload) => {
    commit("setPaymentSheetsOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },
  addStudentId({ commit }, studentId) {
    commit("addStudentId", studentId);
  },
  removeStudentId({ commit }, studentId) {
    commit("removeStudentId", studentId);
  },
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  clearFields: ({ commit }) => {
    commit("clearFields");
  },

  //ACTIONS - CRUD
  save: ({ commit, dispatch }) => {
    createPaymentSheet(state.payment_sheet)
      .then(() => {
        Vue.$toast.success("Folha de Pagamento cadastrada com sucesso!");
        dispatch("closeModalAddPaymentSheet");
        dispatch("loadPaymentSheets");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar a folha de pagamento! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  update: ({ commit, dispatch, state }) => {
    updatePaymentSheet(state.payment_sheet.id, state.payment_sheet)
      .then(() => {
        dispatch("loadPaymentSheets");
        dispatch("closeEditModalAddPaymentSheet");
        Vue.$toast.success("Folha de pagamento atualizada com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar a folha de pagamento! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyPaymentSheet(state.payment_sheetId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Folha de pagamento deletada com sucesso!");
        commit("toggleModalDeletePaymentSheet");
        dispatch("loadPaymentSheets", from);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  //ACTIONS - BEYOND CRUD
  addBeneficiaries: ({ commit, dispatch }) => {
    commit("toggleLoadingVariablesAddBeneficiaries");
    const params = {
      id: state.payment_sheet?.id,
      teams_student_ids: state.teams_student_ids,
    };
    addBeneficiaries(params)
      .then(() => {
        Vue.$toast.success("Beneficiários adicionados com sucesso!");
        dispatch("closeModalAddBeneficiariesScholarship");
        dispatch("Beneficiaries/loadBeneficiaries", null, { root: true });
        dispatch("loadData", state.payment_sheet);
        commit("toggleLoadingVariablesAddBeneficiaries");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel adicionar os beneficiários! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
        commit("toggleLoadingVariablesAddBeneficiaries");
      });
  },
  closePaymentSheet: ({ commit, dispatch }) => {
    const params = {
      id: state.payment_sheet?.id,
    };
    closePaymentSheet(params)
      .then(() => {
        Vue.$toast.success("Folha de Pagamento fechada com sucesso!");
        dispatch("loadPaymentSheets");
        dispatch("loadData", state.payment_sheet);
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel fechar a folha de pagamento! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  openPaymentSheet: ({ commit, dispatch }) => {
    const params = {
      id: state.payment_sheet?.id,
    };
    openPaymentSheet(params)
      .then(() => {
        Vue.$toast.success("Folha de Pagamento aberta com sucesso!");
        dispatch("loadPaymentSheets");
        dispatch("loadData", state.payment_sheet);
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel abrir a folha de pagamento! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  exportPaymentSheetPdf: ({ commit, state }) => {
    commit("toggleLoadingVariablesExportPdf");
    const params = {
      id: state.payment_sheet?.id,
    };
    exportPaymentSheetPdf(params)
      .then(() => {
        Vue.$toast.success("PDF exportado com sucesso!");
        commit("toggleLoadingVariablesExportPdf");
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possível exportar o PDF da folha de pagamento! " +
            (e.response?.data?.userMessage || e.message)
        );
        commit("setErrorMessagesMutation", e.response?.data || { message: e.message });
        commit("toggleLoadingVariablesExportPdf");
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
