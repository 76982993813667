import {
  exportBeneficiariesSpreadSheet,
  getBeneficiariesFromPaymentSheet,
  setBeneficiariesValue,
  updateAllBeneficiariesStatus,
} from "../../service/PaymentSheetsApi";
import { getBeneficiaryById, updateBeneficiary } from "@/service/BeneficiariesApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import { getEquipmentByAreaId } from "@/service/EquipmentApi";

Vue.component("downloadExcel", JsonExcel);

const state = {
  beneficiary: {
    id: null,
    teams_student_id: null,
    payment_sheet_id: null,
    value: null,
    status: null,
  },
  status_for_update_all: null,
  value_for_update_all: null,
  beneficiaries: [],
  all_teams_student_ids: [],
  equipments: [],
  beneficiaries_spreadsheet: [],
  errorMessages: [],

  modalToggleUpdateAllBeneficiaresStatuses: false,
  modalToggleUpdateAllBeneficiaresValues: false,
  modalToggleConfirmUpdateAllBeneficiaresStatuses: false,
  modalToggleExportBeneficiariesSpreadsheet: false,

  hasSpreadsheetBeneficiariesResponse: true,
  json_data_beneficiary: null,

  beneficiariesTotal: 0,
  beneficiariesTotalPerPage: 10,
  beneficiariesOffset: 0,

  loading_variables: {
    load_beneficiaries: false,
    update_all_beneficiaries_statuses: false,
    update_all_beneficiaries_values: false,
  },

  params: {
    name: null,
    cpf: null,
    agency: null,
    account: null,
    status: null,
    city: null,
    area: null,
    equipment: null,
    team: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadBeneficiaries: (state, beneficiaries) => {
    state.beneficiaries = beneficiaries;
  },
  loadAllTeamsStudentIds: (state, all_teams_student_ids) => {
    state.all_teams_student_ids = all_teams_student_ids;
  },
  setEquipmentsMutation: (state, equipments) => {
    state.equipments = equipments;
  },
  setBeneficiaryMutation: (state, beneficiary) => {
    state.beneficiary = beneficiary;
  },

  // MUTATIONS - FIELDS TO API
  setStatusMutation: (state, status) => {
    state.beneficiary.status = status;
  },
  setStatusForUpdateAllMutation: (state, status_for_update_all) => {
    state.status_for_update_all = status_for_update_all;
  },
  setValueForUpdateAllMutation: (state, value_for_update_all) => {
    state.value_for_update_all = value_for_update_all;
  },

  // --> MUTATIONS - FILTERLIST
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },
  setFilterStatusMutation: (state, status) => {
    state.params.status = status;
  },
  setFilterCpfMutation: (state, cpf) => {
    state.params.cpf = cpf;
  },
  setFilterAgencyMutation: (state, agency) => {
    state.params.agency = agency;
  },
  setFilterAccountMutation: (state, account) => {
    state.params.account = account;
  },
  setFilterCityMutation: (state, city) => {
    state.params.city = city;
  },
  setFilterAreaMutation: (state, area) => {
    state.params.area = area;
  },
  setFilterEquipmentMutation: (state, equipment) => {
    state.params.equipment = equipment;
  },
  setFilterTeamMutation: (state, team) => {
    state.params.team = team;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalUpdateAllBeneficiariesStatuses: (state) => {
    state.modalToggleUpdateAllBeneficiaresStatuses = !state.modalToggleUpdateAllBeneficiaresStatuses;
  },
  toggleModalConfirmUpdateAllBeneficiariesStatuses: (state) => {
    state.modalToggleConfirmUpdateAllBeneficiaresStatuses =
      !state.modalToggleConfirmUpdateAllBeneficiaresStatuses;
  },
  toggleModalUpdateAllBeneficiariesValues: (state) => {
    state.modalToggleUpdateAllBeneficiaresValues = !state.modalToggleUpdateAllBeneficiaresValues;
  },

  // MUTATIONS - TOGGLES AND MODALS [LOADINGS]
  toggleLoadingVariablesLoadBeneficiaries: (state) => {
    state.loading_variables.load_beneficiaries = !state.loading_variables.load_beneficiaries;
  },
  toggleLoadingVariablesUpdateAllBeneficiariesStatuses: (state) => {
    state.loading_variables.update_all_beneficiaries_statuses =
      !state.loading_variables.update_all_beneficiaries_statuses;
  },
  toggleLoadingVariablesUpdateAllBeneficiariesValues: (state) => {
    state.loading_variables.update_all_beneficiaries_values =
      !state.loading_variables.update_all_beneficiaries_values;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setBeneficiariesTotalPerPage: (state, beneficiariesTotalPerPage) => {
    state.beneficiariesTotalPerPage = beneficiariesTotalPerPage;
  },
  setBeneficiariesOffset: (state, beneficiariesOffset) => {
    state.beneficiariesOffset = beneficiariesOffset;
  },
  setBeneficiariesTotal: (state, beneficiariesTotal) => {
    state.beneficiariesTotal = beneficiariesTotal;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportBeneficiariesSpreadsheet: (state) => {
    state.modalToggleExportBeneficiariesSpreadsheet = !state.modalToggleExportBeneficiariesSpreadsheet;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetBeneficiariesResponseMutation: (state, hasSpreadsheetBeneficiariesResponse) => {
    state.hasSpreadsheetBeneficiariesResponse = hasSpreadsheetBeneficiariesResponse;
  },

  setBeneficiariesPageToExportMutation: (state, beneficiary) => {
    state.json_data_beneficiary = null;
    console.log("beneficiary", beneficiary);
    const arrayData = [];

    if (beneficiary?.payment_sheet_type === "scholarship") {
      beneficiary.forEach((element, index, array) => {
        let number = index + 1;
        let name = array[index].student.name ? array[index].student.name : "-";
        let cpf = array[index].student.cpf ? array[index].student.cpf : "-";
        let agency = array[index].student.agency ? array[index].student.agency : "-";
        let account = array[index].student.account ? array[index].student.account : "-";
        let frequency = array[index].student.frequency ? array[index].student.frequency : "-";
        let value = array[index].value ? array[index].value : "-";
        let status = array[index].status ? array[index].status.name : "-";

        const arrayItem = [];
        arrayItem["number"] = number;
        arrayItem["name"] = name;
        arrayItem["cpf"] = cpf;
        arrayItem["agency"] = agency;
        arrayItem["account"] = account;
        arrayItem["frequency"] = frequency;
        arrayItem["value"] = value;
        arrayItem["status"] = status;
        arrayData.push(arrayItem);
      });
    } else {
      beneficiary.forEach((element, index, array) => {
        let number = index + 1;
        let name = array[index].student.name ? array[index].student.name : "-";
        let cpf = array[index].student.cpf ? array[index].student.cpf : "-";
        let value = array[index].value ? array[index].value : "-";
        let status = array[index].status ? array[index].status.name : "-";

        const arrayItem = [];
        arrayItem["number"] = number;
        arrayItem["name"] = name;
        arrayItem["cpf"] = cpf;
        arrayItem["value"] = value;
        arrayItem["status"] = status;
        arrayData.push(arrayItem);
      });
    }

    state.json_data_beneficiary = arrayData;
    state.hasSpreadsheetBeneficiariesResponse = true;
    state.modalToggleExportBeneficiariesSpreadsheet = true;
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setBeneficiariesSpreadsheetMutation: (state, beneficiaries_spreadsheet) => {
    state.beneficiaries_spreadsheet = beneficiaries_spreadsheet;
  },

  clearQuery: (state) => {
    state.params.name = null;
    state.params.cpf = null;
    state.params.agency = null;
    state.params.account = null;
    state.params.status = null;
    state.params.city = null;
    state.params.area = null;
    state.params.equipment = null;
    state.params.team = null;
    state.beneficiaries = [];
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

function getFilterParams(rootState) {
  return {
    id: rootState.PaymentSheets.payment_sheet.id,
    name: state.params.name,
    cpf: state.params.cpf,
    agency: state.params.agency,
    account: state.params.account,
    status: state.params.status ? state.params.status.id : null,
    city: state.params.city ? state.params.city.id : null,
    area: state.params.area ? state.params.area.id : null,
    equipment: state.params.equipment ? state.params.equipment.id : null,
    team: state.params.team ? state.params.team.id : null,
    per_page: state.beneficiariesTotalPerPage,
    offset: state.beneficiariesOffset,
  };
}

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadBeneficiaries: ({ commit, rootState }, from) => {
    commit("toggleLoadingVariablesLoadBeneficiaries");
    const params = getFilterParams(rootState);
    getBeneficiariesFromPaymentSheet(params)
      .then((response) => {
        commit("loadBeneficiaries", response.data.beneficiaries);
        commit("loadAllTeamsStudentIds", response.data.all_team_students_ids);
        commit("setBeneficiariesTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
        commit("toggleLoadingVariablesLoadBeneficiaries");
      })
      .catch((e) => {
        console.log("error", e.response);
        commit("toggleLoadingVariablesLoadBeneficiaries");
      });
  },
  loadBeneficiariesForExport: ({ commit, rootState }) => {
    commit("hasSpreadsheetBeneficiariesResponseMutation", false);
    const params = getFilterParams(rootState);
    exportBeneficiariesSpreadSheet(params)
      .then(async (response) => {
        commit("setBeneficiariesSpreadsheetMutation", response.data.beneficiaries);
        if (state.beneficiaries_spreadsheet.length > 0) {
          await commit("setBeneficiariesPageToExportMutation", response.data.beneficiaries);
        } else {
          commit("hasSpreadsheetBeneficiariesResponseMutation", true);
          commit("toggleModalToggleExportBeneficiariesSpreadsheet");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadEquipments: ({ commit }) => {
    const params = {
      area_id: state.params.area?.id,
    };
    getEquipmentByAreaId(params)
      .then((response) => {
        commit("setEquipmentsMutation", response.data.equipment);
        commit("setFilterEquipmentMutation", null);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadData: async ({ commit }, payload) => {
    return getBeneficiaryById(payload.id)
      .then((response) => {
        commit("setBeneficiaryMutation", response.data.beneficiaries);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  setFilterStatus: ({ commit }, payload) => {
    commit("setFilterStatusMutation", payload);
  },
  setFilterCpf: ({ commit }, payload) => {
    commit("setFilterCpfMutation", payload.value);
  },
  setFilterAgency: ({ commit }, payload) => {
    commit("setFilterAgencyMutation", payload.value);
  },
  setFilterAccount: ({ commit }, payload) => {
    commit("setFilterAccountMutation", payload.value);
  },
  setFilterCity: ({ commit }, payload) => {
    commit("setFilterCityMutation", payload);
  },
  setFilterArea: ({ commit }, payload) => {
    commit("setFilterAreaMutation", payload);
  },
  setFilterEquipment: ({ commit }, payload) => {
    commit("setFilterEquipmentMutation", payload);
  },
  setFilterTeam: ({ commit }, payload) => {
    commit("setFilterTeamMutation", payload);
  },
  setStatusForUpdateAll: ({ commit }, payload) => {
    commit("setStatusForUpdateAllMutation", payload);
  },
  updateBeneficiaryStatus: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    await commit("setStatusMutation", payload?.status?.id);
    dispatch("update");
  },

  // --> ACTIONS - PAGINATIONS <--
  setBeneficiariesTotalPerPage: ({ commit }, payload) => {
    commit("setBeneficiariesTotalPerPage", payload.totalPerPage);
  },
  setBeneficiariesOffset: ({ commit }, payload) => {
    commit("setBeneficiariesOffset", payload.offset);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalUpdateAllBeneficiariesStatuses: ({ commit }) => {
    commit("toggleModalUpdateAllBeneficiariesStatuses");
  },
  closeModalUpdateAllBeneficiariesStatuses: ({ commit }) => {
    commit("toggleModalUpdateAllBeneficiariesStatuses");
  },
  openModalConfirmUpdateAllBeneficiariesStatuses: ({ commit }) => {
    commit("toggleModalConfirmUpdateAllBeneficiariesStatuses");
  },
  closeModalConfirmUpdateAllBeneficiariesStatuses: ({ commit }) => {
    commit("toggleModalConfirmUpdateAllBeneficiariesStatuses");
    commit("setStatusForUpdateAllMutation", null);
  },
  saveUpdateAllBeneficiariesStatuses: ({ dispatch }) => {
    dispatch("closeModalUpdateAllBeneficiariesStatuses");
    dispatch("openModalConfirmUpdateAllBeneficiariesStatuses");
  },
  openModalUpdateAllBeneficiariesValues: ({ commit }) => {
    commit("toggleModalUpdateAllBeneficiariesValues");
  },
  closeModalUpdateAllBeneficiariesValues: ({ commit }) => {
    commit("toggleModalUpdateAllBeneficiariesValues");
    commit("setValueForUpdateAllMutation", null);
  },

  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportBeneficiariesSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportBeneficiariesSpreadsheet");
  },
  closeExportBeneficiariesSpreadsheetModal: ({ commit, dispatch }) => {
    commit("toggleModalToggleExportBeneficiariesSpreadsheet");
    dispatch("SelectFieldsToExport/clearFields", null, { root: true });
  },

  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  setValueForUpdateAllMutation: ({ commit }, payload) => {
    commit("setValueForUpdateAllMutation", payload.value);
  },
  update: ({ commit, dispatch, state }) => {
    updateBeneficiary(state.beneficiary.id, state.beneficiary)
      .then(() => {
        dispatch("loadBeneficiaries");
        Vue.$toast.success("Beneficiário atualizado com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar o beneficiário! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  updateAllBeneficiariesStatus: ({ commit, dispatch, state, rootState }) => {
    commit("toggleLoadingVariablesUpdateAllBeneficiariesStatuses");
    const params = {
      id: rootState.PaymentSheets.payment_sheet.id,
      status: state.status_for_update_all?.id,
    };
    updateAllBeneficiariesStatus(params)
      .then(() => {
        dispatch("loadBeneficiaries");
        dispatch("closeModalConfirmUpdateAllBeneficiariesStatuses");
        Vue.$toast.success("Status atualizados com sucesso!");
        commit("toggleLoadingVariablesUpdateAllBeneficiariesStatuses");
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possível atualizar os status dos beneficiários! " + e.response.data.userMessage
        );
        commit("setErrorMessagesMutation", e.response.data);
        commit("toggleLoadingVariablesUpdateAllBeneficiariesStatuses");
      });
  },
  updateAllBeneficiariesValue: ({ commit, dispatch, state, rootState }) => {
    commit("toggleLoadingVariablesUpdateAllBeneficiariesValues");
    const params = {
      id: rootState.PaymentSheets.payment_sheet.id,
      value: state.value_for_update_all,
    };
    setBeneficiariesValue(params)
      .then(() => {
        dispatch("loadBeneficiaries");
        dispatch("closeModalUpdateAllBeneficiariesValues");
        dispatch("PaymentSheets/loadData", rootState.PaymentSheets.payment_sheet, { root: true });
        Vue.$toast.success("Valores atualizados com sucesso!");
        commit("toggleLoadingVariablesUpdateAllBeneficiariesValues");
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possível atualizar os valores de pagamento dos beneficiários! " +
            e.response.data.userMessage
        );
        commit("setErrorMessagesMutation", e.response.data);
        commit("toggleLoadingVariablesUpdateAllBeneficiariesValues");
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
