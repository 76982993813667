import {
  getMatrixProblems,
  getMatrixProblemById,
  createMatrixProblem,
  updateMatrixProblem,
  destroyMatrixProblem,
} from "../../service/MatrixProblemsApi";
import Vue from "vue";

const state = {
  matrix_problem: {
    id: null,
    name: null,
  },
  matrix_problems: [],

  modalToggleMatrixProblems: false,
  modalToggleViewMatrixProblems: false,
  modalConfirmDeleteMatrixProblem: false,

  matrixProblemId: 0,

  matrixProblemTotal: 0,
  matrixProblemTotalPerPage: 10,
  matrixProblemOffset: 0,

  params: {
    name: null,
  },
};

const mutations = {
  // --> MUTATIONS - DATA COMING FROM API <--
  setMatrixProblemsMutation: (state, matrix_problems) => {
    state.matrix_problems = matrix_problems;
  },

  // --> MUTATIONS - FIELDS TO THE TABLE <--
  setIdMutation: (state, id) => {
    state.matrix_problem.id = id;
  },
  setNameMutation: (state, name) => {
    state.matrix_problem.name = name;
  },

  // --> MUTATIONS - PAGINATION <--
  setMatrixProblemTotalPerPage: (state, matrixProblemTotalPerPage) => {
    state.matrixProblemTotalPerPage = matrixProblemTotalPerPage;
  },
  setMatrixProblemOffset: (state, matrixProblemOffset) => {
    state.matrixProblemOffset = matrixProblemOffset;
  },
  setMatrixProblemTotal: (state, matrixProblemTotal) => {
    state.matrixProblemTotal = matrixProblemTotal;
  },

  // --> MUTATIONS - FILTERS <--
  filterListByName: (state, input) => {
    state.params.name = input.value;
  },

  // --> MUTATIONS - TOGGLES FROM MODALS AND SCREENS <--
  toggleModalMatrixProblems: (state) => {
    state.modalToggleMatrixProblems = !state.modalToggleMatrixProblems;
  },
  toggleModalViewFactoggleModalMatrixProblems: (state) => {
    state.modalToggleViewMatrixProblems = !state.modalToggleViewMatrixProblems;
  },
  toggleModalDeleteMatrixProblem: (state) => {
    state.modalConfirmDeleteMatrixProblem = !state.modalConfirmDeleteMatrixProblem;
  },

  // --> MUTATIONS - DELETION <--
  matrixProblemForDeletion: (state, payload) => {
    if (payload) {
      state.matrixProblemId = payload.id;
    }
  },

  // --> MUTATIONS - SPREADSHEET <--

  // --> MUTATIONS - CLEARS <--
  clearQuery: (state) => {
    state.params.name = null;
    state.matrix_problems = [];
  },
  clearFields: (state) => {
    state.matrix_problem.id = null;
    state.matrix_problem.name = null;
  },
};

const actions = {
  // --> ACTIONS & LOADS - DATA COMING FROM API <--
  loadMatrixProblems: ({ commit }, from) => {
    const params = {
      name: state.params.name,
      perPage: state.matrixProblemTotalPerPage,
      offset: state.matrixProblemOffset,
      by: "matrix_problems.name",
      order: "ASC",
    };

    getMatrixProblems(params)
      .then((response) => {
        commit("setMatrixProblemsMutation", response.data.matrix_problems);
        commit("setMatrixProblemTotal", response.data.matrix_problems_total);
        if (parseInt(response.data.matrix_problems_total, 10) < 1 && from != "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getMatrixProblemById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.matrix_problem.id);
        commit("setNameMutation", response.data.matrix_problem.name);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },

  // --> ACTIONS - FILTERS <--
  filterListByName: ({ commit }, payload) => {
    commit("filterListByName", payload);
  },

  // --> ACTIONS - CLEAR <--
  clearQuery: ({ commit }, payload) => {
    commit("clearQuery", payload);
  },

  // --> ACTIONS - TOGGLES FROM MODALS AND SCREENS <--
  toggleModalMatrixProblems: ({ commit }) => {
    commit("toggleModalMatrixProblems");
  },
  openModalMatrixProblems: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalMatrixProblems");
  },
  closeModalMatrixProblems: ({ commit }) => {
    commit("toggleModalMatrixProblems");
    commit("clearFields");
  },
  openEditModalMatrixProblems: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalMatrixProblems");
  },
  closeEditModalMatrixProblems: ({ commit }) => {
    commit("toggleModalMatrixProblems");
    commit("clearFields");
  },
  toggleModalDeleteMatrixProblem: ({ commit }, payload) => {
    commit("toggleModalDeleteMatrixProblem");
    if (state.modalConfirmDeleteMatrixProblem) {
      commit("matrixProblemForDeletion", payload);
    } else {
      commit("matrixProblemForDeletion", null);
    }
  },

  // --> ACTIONS - FIELDS TO THE TABLE <--
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },

  // --> ACTIONS - PAGINATION <--
  setMatrixProblemOffset: ({ commit }, payload) => {
    commit("setMatrixProblemOffset", payload.offset);
  },
  setMatrixProblemTotalPerPage: ({ commit }, payload) => {
    commit("setMatrixProblemTotalPerPage", payload.totalPerPage);
  },

  // --> ACTIONS - EXPORT SPREADSHEET <--

  // --> ACTIONS - CRUD <--
  save: ({ commit, dispatch }) => {
    createMatrixProblem(state.matrix_problem)
      .then(() => {
        Vue.$toast.success("Problema cadastrado com sucesso!");
        commit("toggleModalMatrixProblems");
        commit("clearFields");
        dispatch("loadMatrixProblems");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar o problema! " + e.response.data.error);
        console.log(e);
      });
  },
  update: ({ dispatch, commit }) => {
    updateMatrixProblem(state.matrix_problem.id, state.matrix_problem)
      .then(() => {
        Vue.$toast.success("Problema atualizado com sucesso!");
        commit("toggleModalMatrixProblems");
        dispatch("loadMatrixProblems");
        commit("clearFields");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyMatrixProblem(state.matrixProblemId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Problema deletado com sucesso!");
        commit("toggleModalDeleteMatrixProblem");
        dispatch("loadMatrixProblems", from);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
