<template>
  <BaseModal width="w-128" v-if="open">
    <template v-slot:header>
      <div class="flex flex-row-reverse">
        <AppButtonIcon
          for-close
          :payload="{ mutation: 'toggleModalInscriptionStatusUnderAnalysisConfirmation' }"
          dispatch="ModalInscription/toggleModalInscriptionStatus"
        />
      </div>
      <div class="flex">
        <AppMaterialIcon iconName="check_circle" class="text-ashes-dark mr-2" />
        <BaseText text="Jovem em análise" typeText="custom" weight="semibold" size="2xl" color="ashes-dark" />
      </div>
    </template>
    <template v-slot:body>
      <div class="text-quantum mb-4">
        A ficha de pré-inscrição do jovem sairá da lista do status
        <b>"EM ABERTO"</b> para a lista de status <b>"EM ANÁLISE"</b>.
      </div>
      <div class="text-quantum">
        <b>Tem certeza que deseja mudar o status do jovem?</b>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-end">
        <AppButtonEmpty
          text="Não"
          type-button="danger"
          :payload="{ mutation: 'toggleModalInscriptionStatusUnderAnalysisConfirmation' }"
          dispatch="ModalInscription/toggleModalInscriptionStatus"
          class="mr-5"
        />
        <AppButtonEmpty
          text="Sim"
          type-button="success"
          :payload="{
            mutation: 'toggleModalInscriptionStatusUnderAnalysisConfirmation',
            status: 'under_analysis',
          }"
          dispatch="ModalInscription/setStatusAndCloseModal"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import AppMaterialIcon from "../../components/AppMaterialIcon.vue";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonIcon,
    AppMaterialIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
    },
  },
};
</script>
