<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-3">
        <div class="justify-self-start col-span-2">
          <Label
            text="Editar Data de Retorno de Carta"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon
            dispatch="SchoolReinsertions/closeModalSchoolReinsertionReturnLetterDeadline"
            forClose
          />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações de Data"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="return_letter_deadline"
            input-type="date"
            :value="school_reinsertion.return_letter_deadline"
            show-label
            label-text="Data de Retorno da Carta"
            dispatch="SchoolReinsertions/setReturnLetterDeadline"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="SchoolReinsertions/closeModalSchoolReinsertionReturnLetterDeadline"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty text="Salvar" typeButton="success" dispatch="SchoolReinsertions/update" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseModal from "../../components/BaseModal.vue";
import Label from "../../components/Label.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseModal,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      school_reinsertion: (state) => state.SchoolReinsertions.school_reinsertion,
    }),
  },
};
</script>
