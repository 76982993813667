import { getAxios, baseURL } from "./Api";

export async function getMeetingByTeam(id, teamExport) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/turmas/${id}/encontros/`;
  return api.get(url, { params: teamExport });
}

export async function getMeeting(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/encontros/${id}`;
  return api.get(url);
}

export async function createMeeting(meeting) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.post("/encontros", { meetings: meeting });
}

export async function destroyMeeting(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`encontros/${id}`);
}

export async function updateMeeting(id, meeting) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`encontros/${id}`, { meetings: meeting });
}

export async function suspend(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/encontros/suspender`;
  return api.post(url, null, { params: id });
}

export async function suspendMeetingsByTeamAndDate(teamId, initialDate, finalDate) {
  const filters = {};
  if (initialDate) {
    filters.initial_date = initialDate;
  }
  if (finalDate) {
    filters.final_date = finalDate;
  }
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/turmas/${teamId}/encontros/suspender`;
  return api.post(url, null, { params: filters });
}

export async function disableSuspension(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/encontros/desabilitar_suspensao`;
  return api.post(url, null, { params: id });
}
