<template>
  <div class="m-5">
    <div id="person-data" class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-5">
        <BaseText text="Dados da Seleção" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2">
        <AppTextField show-label label-text="Nome" :text="opportunity.name" type-text="topic-details" />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Data da Seleção"
          :text="formattedDate(opportunity.opportunity_date)"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Empresa"
          :text="company ? company.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Parceiro"
          :text="partner ? partner.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Segmento"
          :text="segment ? segment.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Ocupação"
          :text="occupation ? occupation.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Tipo da Contratação"
          :text="contracting_type ? contracting_type.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Quantidade de Vagas"
          :text="opportunity.vacancy_quantity === 0 ? '0' : opportunity.vacancy_quantity"
          type-text="topic-details"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppTextField from "../../components/AppTextField.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppTextField,
  },
  computed: {
    ...mapState({
      opportunity: (state) => state.Opportunities.opportunity,
      company: (state) => state.Opportunities.company,
      companies: (state) => state.Opportunities.companies,
      partner: (state) => state.Opportunities.partner,
      partners: (state) => state.Opportunities.partners,
      segment: (state) => state.Opportunities.segment,
      segments: (state) => state.Opportunities.segments,
      occupation: (state) => state.Opportunities.occupation,
      occupations: (state) => state.Opportunities.occupations,
      contracting_type: (state) => state.Opportunities.contracting_type,
      contracting_types: (state) => state.Opportunities.contracting_types,
    }),
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
  },
};
</script>
<style>
.active_new {
  background-color: #fff7ed;
  color: #fff;
  font-size: 1.1em;
}
</style>
