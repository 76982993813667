import { getAxios, baseURL } from "./Api";

export async function getPaymentSheets(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `folhas_de_pagamento`;
  return api.get(url, { params: filters });
}

export async function getPaymentSheetsForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `folhas_de_pagamento/para_menus`;
  return api.get(url, { params: filters });
}

export async function getPaymentSheetsForExport(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `folhas_de_pagamento/exportar_planilha`;
  return api.get(url, { params: filters });
}

export async function getBeneficiariesFromPaymentSheet(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `folhas_de_pagamento/${filters.id}/listar_beneficiarios`;
  return api.get(url, { params: filters });
}

export async function getPaymentSheetTypes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`folhas_de_pagamento/tipos_folha`);
}

export async function getPaymentSheetStatus() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`folhas_de_pagamento/status`);
}

export async function getPaymentSheetInstallments() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`folhas_de_pagamento/parcelas`);
}

export async function getPaymentSheetRemittances() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`folhas_de_pagamento/remessas`);
}

export async function getPaymentSheetById(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`folhas_de_pagamento/${id}`);
}

export async function createPaymentSheet(payment_sheet) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/folhas_de_pagamento", payment_sheet);
}

export async function updatePaymentSheet(id, payment_sheet) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`folhas_de_pagamento/${id}`, payment_sheet);
}

export async function destroyPaymentSheet(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`folhas_de_pagamento/${id}`);
}

export async function closePaymentSheet(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `folhas_de_pagamento/${filters.id}/fechar`;
  return api.put(url, { params: filters });
}

export async function openPaymentSheet(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `folhas_de_pagamento/${filters.id}/abrir`;
  return api.put(url, { params: filters });
}

export async function addBeneficiaries(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `folhas_de_pagamento/${filters.id}/adicionar_beneficiarios`;
  return api.post(url, { params: filters });
}

export async function updateAllBeneficiariesStatus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `folhas_de_pagamento/${filters.id}/atualizar_status_beneficiarios`;
  return api.put(url, { params: filters });
}

export async function setBeneficiariesValue(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `folhas_de_pagamento/${filters.id}/definir_valor_beneficiarios`;
  return api.put(url, { params: filters });
}

export async function exportBeneficiariesSpreadSheet(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `folhas_de_pagamento/${filters.id}/exportar_planilha_beneficiarios`;
  return api.get(url, { params: filters });
}

export async function exportPaymentSheetPdf(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  const endpoint = `folhas_de_pagamento/${filters.id}/exportar_pdf`;

  try {
    const response = await api.get(endpoint, {
      params: filters,
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });
    const pdfUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", "folha_de_pagamento.pdf");
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Erro ao baixar o PDF:", error);
    throw error;
  }
}
