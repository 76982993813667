<template>
  <BaseCard :bg_color="getbgType">
    <div :class="gridClass" :style="gridStyle">
      <div
        class="mt-4"
        v-if="
          !togglePsychosocialAccompanimentUnaccompanied &&
          psychosocial_accompaniment.accompaniment_type.id !== 3
        "
      >
        <BaseText
          :text="formattedDate(psychosocial_accompaniment.created_at)"
          mode="normal-case"
          :color="getColorText"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div
        class="mt-4"
        v-if="
          !togglePsychosocialAccompanimentUnaccompanied &&
          psychosocial_accompaniment.accompaniment_type.id !== 3
        "
      >
        <BaseText
          :text="formattedDate(psychosocial_accompaniment.date_accompaniment)"
          mode="normal-case"
          :color="getColorText"
          typeText="custom"
          weight="semibold"
          size="base"
        />
      </div>
      <div
        class="mt-4"
        v-if="
          !togglePsychosocialAccompanimentUnaccompanied &&
          psychosocial_accompaniment.accompaniment_type.id !== 3
        "
      >
        <BaseText
          :text="formattedDate(psychosocial_accompaniment.updated_at)"
          mode="normal-case"
          :color="getColorText"
          typeText="custom"
          weight="semibold"
          size="base"
        />
      </div>
      <div
        class="mt-4"
        v-if="
          !togglePsychosocialAccompanimentUnaccompanied &&
          psychosocial_accompaniment.accompaniment_type.id !== 3
        "
      >
        <BaseText
          :text="psychosocial_accompaniment.accompaniment_type.name"
          mode="normal-case"
          :color="getColorText"
          typeText="custom"
          weight="semibold"
          size="base"
        />
      </div>
      <div
        class="mt-4"
        v-if="
          !togglePsychosocialAccompanimentUnaccompanied &&
          psychosocial_accompaniment.accompaniment_type.id !== 3
        "
      >
        <BaseText
          :text="psychosocial_accompaniment.accompaniment_status.name"
          :color="getColorText"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div
        class="mt-4"
        v-if="
          !togglePsychosocialAccompanimentUnaccompanied &&
          psychosocial_accompaniment.accompaniment_type.id !== 3
        "
      >
        <BaseText
          :text="registeredForwarding()"
          mode="normal-case"
          :color="getColorText"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div
        class="mt-4"
        v-if="
          !togglePsychosocialAccompanimentUnaccompanied &&
          psychosocial_accompaniment.accompaniment_type.id !== 3
        "
      >
        <BaseText
          :text="hasSituation()"
          :color="getColorText"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div
        class="mt-4"
        v-if="
          !togglePsychosocialAccompanimentUnaccompanied &&
          psychosocial_accompaniment.accompaniment_type.id !== 3
        "
      >
        <BaseText
          :text="getSituation()"
          :color="getColorText"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div
        class="mt-4"
        v-if="
          !togglePsychosocialAccompanimentUnaccompanied &&
          psychosocial_accompaniment.accompaniment_type.id !== 3
        "
      >
        <BaseText
          :text="isOriginedFromAnotherAccompaniment()"
          :color="getColorText"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div
        class="mt-4 ml-1"
        v-if="
          togglePsychosocialAccompanimentUnaccompanied &&
          psychosocial_accompaniment.accompaniment_type.id === 3
        "
      >
        <BaseText
          :text="formattedDate(psychosocial_accompaniment?.justification_for_non_acceptance_date)"
          :color="getColorText"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div
        class="mt-4"
        v-if="
          togglePsychosocialAccompanimentUnaccompanied &&
          psychosocial_accompaniment.accompaniment_type.id === 3
        "
      >
        <BaseText
          :text="psychosocial_accompaniment?.justification_for_non_acceptance"
          :color="getColorText"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-5 flex">
        <AppButtonIcon
          iconName="visibility"
          dispatch="PsychosocialAccompaniments/openViewModal"
          :payload="psychosocial_accompaniment"
          :iconColor="getIconColor"
          v-if="
            psychosocial_accompaniment.accompaniment_status.name !== 'Agendado' &&
            permitShow &&
            !togglePsychosocialAccompanimentUnaccompanied &&
            psychosocial_accompaniment.accompaniment_type.id !== 3
          "
        />
        <AppButtonIcon
          iconName="restart_alt"
          :payload="psychosocial_accompaniment"
          :dispatch="getDispatchType"
          :iconColor="getIconColor"
          title="Realizar Atendimento"
          v-if="psychosocial_accompaniment.accompaniment_status.name === 'Agendado' && permitUp"
        />
        <AppButtonIcon
          iconName="update"
          :payload="psychosocial_accompaniment"
          :iconColor="getIconColor"
          dispatch="PsychosocialAccompaniments/openModalPsychosocialAccompanimentPostponeDate"
          title="Reagendar Atendimento"
          v-if="psychosocial_accompaniment.accompaniment_status.name === 'Agendado' && permitUp"
        />
        <AppButtonIcon
          iconName="edit"
          :payload="psychosocial_accompaniment"
          :iconColor="getIconColor"
          :dispatch="getDispatchType"
          title="Editar Atendimento"
          v-if="permitUp"
        />
        <AppButtonIcon
          iconName="delete"
          :iconColor="getIconColor"
          :payload="psychosocial_accompaniment"
          dispatch="PsychosocialAccompaniments/toggleModalDeletePsychosocialAccompaniment"
          v-if="permitDes"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitResetPassword: false,
      permitShow: false,
      permit: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    psychosocial_accompaniment: {
      type: Object,
    },
  },
  created() {
    this.canAcces("psychosocial_accompaniment_update").then((response) => {
      this.permitUp = response;
    });
    this.canAcces("psychosocial_accompaniment_show").then((response) => {
      this.permitShow = response;
    });
    this.canAcces("psychosocial_accompaniment_destroy").then((response) => {
      this.permitDes = response;
    });
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    registeredForwarding() {
      if (this.psychosocial_accompaniment.forwarding.length > 0) {
        return "Sim";
      } else {
        return "Não";
      }
    },
    isOriginedFromAnotherAccompaniment() {
      if (this.psychosocial_accompaniment.psychosocial_accompaniment_origin) {
        return "Sim";
      } else {
        return "Não";
      }
    },
    hasSituation() {
      if (this.psychosocial_accompaniment.matrix_risk_stratifications) {
        return "Sim";
      } else {
        return "Não";
      }
    },
    getSituation() {
      let situations_risk = [];
      if (this.psychosocial_accompaniment.matrix_risk_stratifications) {
        this.psychosocial_accompaniment.matrix_risk_stratifications.forEach((item) => {
          situations_risk.push(item.situation_risk);
        });

        if (situations_risk.includes("Gravíssima")) {
          return "Gravíssima";
        } else if (situations_risk.includes("Grave")) {
          return "Grave";
        } else {
          return "Moderada";
        }
      } else {
        return "-";
      }
    },
  },
  computed: {
    ...mapState({
      togglePsychosocialAccompanimentUnaccompanied: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentUnaccompanied,
    }),
    getDispatchType() {
      let dispatchType = "";
      switch (this.psychosocial_accompaniment.accompaniment_type.name) {
        case "Acolhimento Inicial":
          dispatchType = "PsychosocialAccompaniments/openEditModalFirstPsychosocialAccompaniment";
          break;
        case "Atendimento Individual":
          dispatchType = "PsychosocialAccompaniments/openEditModalPsychosocialIndividualMonitoring";
          break;
        case "Visita Domiciliar":
          dispatchType = "PsychosocialAccompaniments/openEditModalPsychosocialHomeVisit";
          break;
        case "Não Acolhido":
          dispatchType =
            "PsychosocialAccompaniments/openEditModalPsychosocialAccompanimentJustifyNonReception";
          break;
      }
      return dispatchType;
    },
    getbgType() {
      let bgType = "";
      switch (this.psychosocial_accompaniment.accompaniment_type.name) {
        case "Acolhimento Inicial":
          bgType = "bg-ashes";
          break;
        case "Atendimento Individual":
          bgType = "bg-herbal-lemon";
          break;
        case "Visita Domiciliar":
          bgType = "bg-cyan";
          break;
      }
      return bgType;
    },
    getColorText() {
      let colortext = "";
      switch (this.psychosocial_accompaniment.accompaniment_type.name) {
        case "Acolhimento Inicial":
          colortext = "ashes-dark";
          break;
        case "Atendimento Individual":
        case "Visita Domiciliar":
          colortext = "white";
          break;
      }
      return colortext;
    },
    getIconColor() {
      let iconColor = "";
      switch (this.psychosocial_accompaniment.accompaniment_type.name) {
        case "Acolhimento Inicial":
          iconColor = "ashes-dark";
          break;
        case "Atendimento Individual":
        case "Visita Domiciliar":
          iconColor = "white";
          break;
      }
      return iconColor;
    },
    gridClass() {
      return "grid items-center container1";
    },
    gridStyle() {
      return {
        gridTemplateColumns: this.columnDefinitions,
      };
    },
    columnDefinitions() {
      return this.togglePsychosocialAccompanimentUnaccompanied
        ? "14% 82% 5%"
        : "10% 12% 10% 18% 12% 11% 8% 9% 4% 3%";
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 10% 12% 10% 18% 12% 11% 8% 9% 7% 3%;
}
</style>
