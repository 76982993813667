<template>
  <div>
    <div class="grid grid-cols-5">
      <div class="mt-4 ml-5 col-span-2">
        <BaseText
          text="Nome"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-1">
        <BaseText
          text="Nº de Alunos"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-0">
        <BaseText
          text="Total"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li
        :key="benefit.id"
        :data-index="index"
        v-for="(benefit, index) in data"
        @click.prevent="gotToBenefit(benefit)"
      >
        <div>
          <AppCardBenefit :benefit="benefit" />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardBenefit from "../../components/AppCardBenefit.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";
import router from "../../router/index";

export default {
  components: {
    AppCardBenefit,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },

  methods: {
    gotToBenefit(params) {
      router.push({ name: "benefit", params: { id: params.id } });
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 56% 18% 26% 0%;
}
</style>
