<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-4">
        <div class="justify-self-start col-span-3">
          <Label
            v-if="planned_awareness_activity.id"
            text="Editar Planejamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar Planejamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon
            dispatch="PlannedAwarenessActivities/closeModalAddPlannedAwarenessActivity"
            forClose
          />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Dados cadastrais do Planejamento da Atividade"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <Multiselect
            id="areas"
            select-placeholder="Selecionar a opção"
            label-text="Selecionar Área"
            :close-on-select="true"
            show-label
            :multiple-option="false"
            :value="area"
            :options="user_areas"
            :dispatch="setArea"
            required
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            id="equipamentos"
            select-placeholder="Selecionar a opção"
            :label-text="getLabelEquipmentFilter()"
            :close-on-select="true"
            show-label
            :multiple-option="false"
            :value="equipment"
            :options="equipments_planned_awareness_activities"
            :dispatch="setEquipment"
            required
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="articulator"
            input-type="text"
            :value="planned_awareness_activity.articulator"
            show-label
            label-text="Articulador(a) Responsável"
            dispatch="PlannedAwarenessActivities/setArticulator"
          />
        </div>
        <div>
          <BaseInput
            active
            required
            input-name="activity_date"
            input-type="date"
            :value="planned_awareness_activity.activity_date"
            show-label
            label-text="Data da Atividade"
            dispatch="PlannedAwarenessActivities/setActivityDate"
          />
        </div>
        <div class="col-span-2">
          <BaseTextArea
            active
            input-name="purpose"
            input-type="text"
            :value="planned_awareness_activity.purpose"
            show-label
            rows="4"
            label-text="Objetivo"
            dispatch="PlannedAwarenessActivities/setPurpose"
            required
          />
        </div>
        <div class="col-span-2">
          <BaseTextArea
            active
            input-name="methodology"
            input-type="text"
            :value="planned_awareness_activity.methodology"
            show-label
            rows="4"
            label-text="Metodologia"
            dispatch="PlannedAwarenessActivities/setMethodology"
            required
          />
        </div>
        <div class="col-span-2">
          <BaseTextArea
            active
            input-name="used_resources"
            input-type="text"
            :value="planned_awareness_activity.used_resources"
            show-label
            rows="4"
            label-text="Recursos Utilizados"
            dispatch="PlannedAwarenessActivities/setUsedResources"
            required
          />
        </div>
        <div class="col-span-2" v-if="isDateExpired() && planned_awareness_activity.id">
          <BaseTextArea
            active
            input-name="activity_evaluation"
            input-type="text"
            :value="planned_awareness_activity.activity_evaluation"
            show-label
            rows="4"
            label-text="Avaliação da Atividade"
            dispatch="PlannedAwarenessActivities/setActivityEvaluation"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="PlannedAwarenessActivities/closeModalAddPlannedAwarenessActivity"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="planned_awareness_activity.id"
            text="Salvar"
            typeButton="success"
            dispatch="PlannedAwarenessActivities/update"
          />
          <AppButtonEmpty
            v-else
            text="criar"
            typeButton="success"
            dispatch="PlannedAwarenessActivities/save"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import AppButtonIcon from "../../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../../components/AppButtonEmpty.vue";
import BaseInput from "../../../components/forms/BaseInput.vue";
import BaseTextArea from "../../../components/forms/BaseTextArea.vue";
import BaseModal from "../../../components/BaseModal.vue";
import Label from "../../../components/Label.vue";
import Multiselect from "../../../components/forms/Multiselect.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseModal,
    BaseTextArea,
    BaseInput,
    Label,
    Multiselect,
    AppButtonIcon,
    AppButtonEmpty,
  },
  data() {
    return {
      input: 1,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const user = JSON.parse(localStorage.userData);
    this.$store.dispatch("PlannedAwarenessActivities/setUser", user);
    this.$store.dispatch("PlannedAwarenessActivities/setEquipmentsForPlannedAwarenessActivities", []);
  },
  computed: {
    ...mapState({
      planned_awareness_activity: (state) => state.PlannedAwarenessActivities.planned_awareness_activity,
      articulators: (state) => state.PlannedAwarenessActivities.articulators,
      articulator: (state) => state.PlannedAwarenessActivities.articulator,
      user_areas: (state) => state.PlannedAwarenessActivities.user_areas,
      area: (state) => state.PlannedAwarenessActivities.area,
      equipments_planned_awareness_activities: (state) =>
        state.PlannedAwarenessActivities.equipments_planned_awareness_activities,
      equipment: (state) => state.PlannedAwarenessActivities.equipment,
    }),
  },
  methods: {
    ...mapActions({
      setArea: "PlannedAwarenessActivities/setArea",
      setArticulator: "PlannedAwarenessActivities/setArticulator",
      setEquipment: "PlannedAwarenessActivities/setEquipment",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    getLabelEquipmentFilter() {
      return this.area_filtered ? "Selecionar Equipamento" : "Selecione a área para listar os equipamentos";
    },
    isDateExpired() {
      if (this.planned_awareness_activity.activity_date <= new Date().toISOString().split("T")[0]) {
        return true;
      }
      return false;
    },
  },
  watch: {
    area: function () {
      this.$store.dispatch("PlannedAwarenessActivities/loadEquipments");
    },
  },
};
</script>
