<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center container1">
      <div class="mt-4">
        <BaseText
          :text="
            planned_awareness_activity.created_at ? formattedDate(planned_awareness_activity.created_at) : '-'
          "
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="planned_awareness_activity.area ? planned_awareness_activity.area.name : '-'"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="planned_awareness_activity.equipment ? planned_awareness_activity.equipment.name : '-'"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="planned_awareness_activity.articulator ? planned_awareness_activity.articulator : '-'"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="
            planned_awareness_activity.activity_date
              ? formattedDate(planned_awareness_activity.activity_date)
              : '-'
          "
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="permitShow"
          iconName="visibility"
          dispatch="PlannedAwarenessActivities/openViewModal"
          :payload="planned_awareness_activity"
          title="Visualizar Planejamento"
        />
        <AppButtonIcon
          v-if="permitUp"
          iconName="edit"
          :payload="planned_awareness_activity"
          dispatch="PlannedAwarenessActivities/openEditModalAddPlannedAwarenessActivity"
          title="Editar Planejamento"
        />
        <AppButtonIcon
          v-if="permitDes"
          iconName="delete"
          :payload="planned_awareness_activity"
          dispatch="PlannedAwarenessActivities/toggleModalDeletePlannedAwarenessActivity"
          title="Excluir Planejamento"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import BaseText from "./BaseText.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import { mapActions } from "vuex";

export default {
  components: {
    AppButtonIcon,
    BaseCard,
    BaseText,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitResetPassword: false,
      permitShow: false,
      permit: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    planned_awareness_activity: {
      type: Object,
    },
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    getEquipmentName() {
      if (this.inscription.student.equipment) {
        return this.inscription.student.equipment.name;
      } else {
        return "Sem equipamento cadastrado";
      }
    },
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    setInscription() {
      this.$store.dispatch("SchoolReinsertions/setInscription", this.inscription);
    },
  },
  created() {
    this.canAcces("planned_awareness_activity_update").then((response) => {
      this.permitUp = response;
    });
    this.canAcces("planned_awareness_activity_show").then((response) => {
      this.permitShow = response;
    });
    this.canAcces("planned_awareness_activity_destroy").then((response) => {
      this.permitDes = response;
    });
    this.canAcces("planned_awareness_activity_create").then((response) => {
      this.permit = response;
    });
  },
  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-4": true,
        "justify-items-stretch": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
        "cursor-pointer": true,
      };
    },
    classButtonAssignment() {
      return {
        border: true,
        "shadow-md": true,
        "m-2": true,
        "rounded-full": true,
        "h-15": true,
        "w-20": true,
        flex: true,
        "items-center": true,
        "justify-center": true,
        "text-herbal": true,
      };
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 12% 10% 38% 20% 12% 10%;
}
</style>
