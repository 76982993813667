<template>
  <div v-if="open" class="static">
    <div
      class="container1 bg-white text-left border border-black-600 mr-3 block font-normal leading-normal text-sm rounded-lg tooltiptext shadow-xl w-40"
    >
      <ul class="text-gray-400 font-semibold p-2">
        <li v-if="isAllocatedInClass()">
          <button @click="openModalTransferStudent()" class="hover:bg-ashes mt-2">Transferir</button>
        </li>
        <li><button @click="getOption(1)" class="hover:bg-ashes mt-2">Desligar</button></li>
        <li v-if="isAllocatedInClass()">
          <button @click="getOption(2)" class="hover:bg-ashes mt-2">Remover da Turma</button>
        </li>
      </ul>
      <ModalTransferStudentToClass :open="showModalTransferStudent" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import ModalTransferStudentToClass from "./ModalTransferStudentToClass.vue";

export default {
  components: {
    ModalTransferStudentToClass,
  },
  props: {
    open: {
      type: Boolean,
    },
  },
  beforeUpdate: function () {
    this.$store.dispatch("Allocation/cleanStudentsAttributesMutation");
  },
  created() {
    this.$store.dispatch("Allocation/setAllTeams");
  },
  methods: {
    ...mapActions({
      setStatus: "Inscription/setStatus",
    }),
    isAllocatedInClass() {
      if (this.inscriptionFiltered.student.teams_attributes.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    openModalTransferStudent() {
      this.$store.dispatch("Season/toggleModalTransferStudentOpen");
    },
    getOption(option) {
      if (option === 1) {
        this.inscriptions.forEach((item) => {
          if (item.id == this.inscription.id) {
            this.$store.dispatch("Inscription/setStatus", "disconnected");
            this.$store.dispatch("ModalInscription/updateInscription");
            Vue.$toast.success(
              `Status do(a) estudante ${item.student.name} foi mudado para Desligado com sucesso!`
            );
          }
        });
        this.$store.dispatch("Season/toggleShowStudentDropdown");
      } else if (option === 2) {
        this.inscriptions.forEach((item) => {
          if (item.id == this.inscription.id) {
            let idPreviousTeam = item.student.teams_attributes[0].id;
            let payload = { inscription: item, id: idPreviousTeam };
            this.$store.dispatch("Allocation/addStudent", item.student);
            this.$store.dispatch("Allocation/removeStudentFromTeam", payload);
            Vue.$toast.success(
              `O(a) estudante ${item.student.name} foi removido da turma ${item.student.teams_attributes[0].code} e está disponível para alocação em outras turmas!`
            );
          }
        });
        this.$store.dispatch("Season/toggleShowStudentDropdown");
      }
    },
  },

  computed: {
    ...mapState({
      inscriptionFiltered: (state) => state.Season.inscriptionFiltered,
      inscription: (state) => state.Inscription,
      inscriptions: (state) => state.Season.inscriptions,
      showModalTransferStudent: (state) => state.Season.modalTransferStudentOpen,
    }),
  },
};
</script>

<style scoped>
.container1 {
  position: relative;
  top: 10px;
  right: 44px;
}
</style>
