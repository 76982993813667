<template>
  <div class="font-system">
    <div class="mx-12 my-8 text-burnedYellow hover:text-burnedYellow-hover grid grid-flow-col auto-cols-max">
      <router-link to="/reinsercao_escolar" class="flex items-center">
        <a @click="goBack('personal_data')" href="javascript:void(0)">
          <AppMaterialIcon iconName="arrow_back" iconType="outlined" iconSize="18" class="mr-1" />
          <BaseText text="Voltar" type-text="custom" weight="semibold" class="hover:underline" />
        </a>
      </router-link>
    </div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-3">
        Reinsercão Escolar | Não Reinseridos
      </div>
    </div>
    <div
      id="tabs-button"
      class="grid grid-cols-1 justify-between p-4 items-end mx-12 my-6 gap-4"
      v-if="inscription"
    >
      <div>
        <span v-if="inscription.student">
          <Label
            :text="inscription.student.season.name"
            :icon="null"
            :iconType="null"
            type="text-xs"
            weight="medium"
            mode="uppercase"
            class="text-burnedYellow"
          />
        </span>
        |
        <span v-if="inscription">
          <Label
            :text="getEquipmentName()"
            :icon="null"
            :iconType="null"
            type="text-xs"
            weight="medium"
            class="text-burnedYellow"
          />
        </span>
        |
        <span v-if="inscription.student">
          <Label
            :text="getFrequency()"
            :icon="null"
            :iconType="null"
            type="text-xs"
            weight="medium"
            class="text-burnedYellow"
          />
        </span>
        |
        <span v-if="inscription?.student">
          <Label
            :text="getLastPresence()"
            :icon="null"
            :iconType="null"
            type="text-xs"
            weight="medium"
            class="text-burnedYellow"
          />
        </span>
        <div v-if="inscription.student">
          <BaseText
            :text="inscription.student.name"
            typeText="custom"
            weight="medium"
            size="2xl"
            class="mr-1"
          />
          <span> - </span>
          <BaseText
            :text="inscription.student.cpf | VMask('###.###.###-##')"
            typeText="custom"
            weight="medium"
            size="2xl"
            class="mr-1"
          />
        </div>
        <div class="w-full" v-if="inscription">
          <BaseText text="Situação -" typeText="custom" weight="normal" size="sm" class="mr-1" />
          <Label
            :text="getSeverity()"
            :icon="null"
            :iconType="null"
            type="text-xs"
            weight="medium"
            class="text-burnedYellow"
          />
        </div>
      </div>
    </div>
    <div id="menus_psychosocial_main_page" class="my-10 mx-16 my-6 flex">
      <div>
        <a @click="changeTab('personal_data')" href="javascript:void(0)">
          <BaseText
            text="Dados Pessoais"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorPersonalData"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div>
        <a @click="changeTab('evolution')" href="javascript:void(0)">
          <BaseText
            text="Evolução"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorEvolution"
          />
        </a>
      </div>
    </div>
    <div id="list-psychosocial">
      <div class="mx-12">
        <PersonalData :open="toggleSchoolReinsertionNotReinsertedPersonalData" />
        <Evolution :open="toggleSchoolReinsertionNotReinsertedEvolution" />
      </div>
    </div>
  </div>
</template>

<script>
import Label from "../../../components/Label.vue";
import BaseText from "../../../components/BaseText.vue";
// import NotReinsertedPersonalData from "./NotReinsertedPersonalData.vue";
import PersonalData from "../PersonalData.vue";
import Evolution from "../Evolution.vue";
import AppMaterialIcon from "../../../components/AppMaterialIcon.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AppMaterialIcon,
    PersonalData,
    Evolution,
    BaseText,
    Label,
  },
  data() {
    return {
      permitShowEvolution: false,
      permitShowVulnerability: false,
      permitShowForwarding: false,
    };
  },
  async created() {
    this.backToTop();
    const inscription_id = sessionStorage.inscription_id;
    await this.$store.dispatch("SchoolReinsertions/loadInscriptionByIdForSchoolReinsertion", inscription_id);
    this.canAcces("psychosocial_filter_by_inscription").then((response) => {
      this.permitShowEvolution = response;
    });
    this.canAcces("index_by_inscription").then((response) => {
      this.permitShowVulnerability = response;
    });
    this.canAcces("forwarding_filter_paginate").then((response) => {
      this.permitShowForwarding = response;
    });
  },
  computed: {
    ...mapState({
      city: (state) => state.City.city,
      inscription: (state) => state.SchoolReinsertions.inscription,
      tabSchoolReinsertionNotReinsertedView: (state) =>
        state.SchoolReinsertions.tabSchoolReinsertionNotReinsertedView,

      toggleSchoolReinsertionNotReinsertedPersonalData: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionNotReinsertedPersonalData,
      toggleSchoolReinsertionNotReinsertedEvolution: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionNotReinsertedEvolution,
      toggleSchoolReinsertionNotReinsertedVacancyRequest: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionNotReinsertedVacancyRequest,
    }),
    setColorPersonalData() {
      return this.tabSchoolReinsertionNotReinsertedView === "personal_data" ? "burnedYellow" : "ashes-hover";
    },
    setColorEvolution() {
      return this.tabSchoolReinsertionNotReinsertedView === "evolution" ? "burnedYellow" : "ashes-hover";
    },
    setColorVacancyRequest() {
      return this.tabSchoolReinsertionNotReinsertedView === "vacancy_request"
        ? "burnedYellow"
        : "ashes-hover";
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      filterListByForwardingPolicy: "Forwardings/filterListByForwardingPolicy",
      setSeason: "PsychosocialAccompaniments/setSeason",
    }),
    async goBack(value) {
      this.$store.dispatch("SchoolReinsertions/setTabSchoolReinsertionNotReinsertedView", value);
      this.backToTop();
      await this.$store.dispatch("SchoolReinsertions/clearQueryNotReinsertedsEvolution");
      await this.$store.dispatch("VacancyRequests/clearQuery");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    getEquipmentName() {
      if (this.inscription) {
        if (this.inscription.student.equipment) {
          return this.inscription.student.equipment.name;
        } else {
          return "Sem equipamento cadastrado";
        }
      }
    },
    getFrequency() {
      if (this.inscription) {
        if (
          this.inscription.student.teams_attributes &&
          this.inscription.student.teams_attributes.length > 0
        ) {
          if (this.inscription.student.teams_attributes[0].presence_percent) {
            return "Frequência: " + this.inscription.student.teams_attributes[0].presence_percent + "%";
          } else {
            return "Frequência não registrada";
          }
        } else {
          return "Jovem não alocado(a) em turma";
        }
      }
    },
    getLastPresence() {
      let array_dates = [];
      let string_return = "Última Presença: ";
      if (this.inscription?.student?.teams_attributes) {
        this.inscription?.student.teams_attributes.forEach((team) => {
          if (team.last_presence.updated_at) {
            array_dates.push(team.last_presence.updated_at);
          }
        });
      }
      return array_dates.length > 0
        ? (string_return += this.$util_function.getLastPresence(array_dates))
        : (string_return += "-");
    },
    getSeverity() {
      if (this.inscription) {
        if (this.inscription.situation_risk) {
          return this.inscription.situation_risk;
        } else {
          return "Nenhuma situação informada";
        }
      }
    },
    changeTab(value) {
      this.$store.dispatch("SchoolReinsertions/setTabSchoolReinsertionNotReinsertedView", value);
      this.backToTop();
    },
  },
};
</script>
