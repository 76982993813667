<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-3">
        <div class="justify-self-start col-span-2">
          <Label
            text="Reagendar Data do Atendimento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon
            dispatch="PsychosocialAccompaniments/closeModalPsychosocialAccompanimentPostponeDate"
            forClose
          />
        </div>
        <div class="justify-self-start col-span-2">
          <BaseText
            :text="getUserName()"
            typeText="custom"
            weight="semibold"
            color="herbal"
            size="sm"
            class="mr-1"
          />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div div class="grid grid-cols-4 gap-4 mt-4" v-if="psychosocial_accompaniment.id">
        <div class="col-span-4 text-burnedYellow pb-2">
          <Label
            text="Informações do Aluno(a)"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <AppTextField
            show-label
            label-text="Nome"
            :text="inscription.student.name"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Status do Aluno(a) no sistema"
            v-bind:text="verifyStatus()"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="CPF"
            v-bind:text="verifyInscription('cpf') | VMask('###.###.###-##')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Data de Nascimento"
            v-bind:text="verifyInscription('birthdate')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Idade"
            v-bind:text="verifyInscription('age')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Número Contato"
            v-bind:text="verifyInscription('cellphone_number') | VMask('(##) #####-####')"
            type-text="topic-details"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações de Data"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <BaseInput
            active
            input-name="birthdate"
            input-type="date"
            :value="psychosocial_accompaniment.date_accompaniment"
            show-label
            label-text="Reagendar Data do Atendimento"
            required
            dispatch="PsychosocialAccompaniments/setDateAccompaniment"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Data nominal"
            :text="getFullDate()"
            color="carmesim"
            type-text="custom"
            weight="semibold"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="PsychosocialAccompaniments/closeModalPsychosocialAccompanimentPostponeDate"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty text="Salvar" typeButton="success" dispatch="PsychosocialAccompaniments/update" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import AppTextField from "../../components/AppTextField.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import Label from "../../components/Label.vue";
import { mapState } from "vuex";
import moment from "moment";

export default {
  components: {
    AppTextField,
    BaseModal,
    BaseInput,
    BaseText,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
      inscription: (state) => state.PsychosocialAccompaniments.inscription,
      date_accompaniment: (state) =>
        state.PsychosocialAccompaniments.psychosocial_accompaniment.date_accompaniment,
      user: (state) => state.PsychosocialAccompaniments.user,
    }),
  },
  methods: {
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    getUserName() {
      if (this.user.userName) {
        return `Usário(a) responsável: ${this.user.userName}`;
      } else {
        return `Usário(a) responsável: ${this.user.name}`;
      }
    },
    verifyStatus() {
      return this.$util_function.processInscriptionStatuses(this.inscription?.status);
    },
    verifyInscription(type) {
      if (this.inscription.student) {
        if (type === "cpf" && this.inscription.student.cpf) {
          return this.inscription.student.cpf;
        } else if (type === "birthdate" && this.inscription.student.birthdate) {
          return this.formattedDate(this.inscription.student.birthdate);
        } else if (type === "cellphone_number" && this.inscription.student.cellphone_number) {
          return this.inscription.student.cellphone_number;
        } else if (type === "age" && this.inscription.student.age) {
          return this.inscription.student.age + " anos";
        } else if (type === "equipment" && this.inscription.student.equipment) {
          return this.inscription.student.equipment.name;
        }
      }
    },
    getFullDate() {
      moment.locale("pt");
      return moment(this.date_accompaniment).format("LLLL");
    },
  },

  watch: {
    date_accompaniment: function () {
      this.getFullDate();
    },
  },
};
</script>
