<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="payment_sheet.id"
            text="Editar Folha de Pagamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Cadastrar Folha de Pagamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="PaymentSheets/closeModalAddPaymentSheet" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2" v-if="isScholarshipPage">
          <Multiselect
            :close-on-select="true"
            label-text="Matriz"
            show-label
            clear-on-select
            required
            select-placeholder="Selecione a matriz"
            :multiple-option="false"
            :value="scholarship_matrix"
            :options="scholarship_matrices_for_menus"
            :dispatch="setScholarshipMatrix"
            :error-message="getErrorMessage('matriz de bolsa')"
          />
        </div>
        <div v-if="isScholarshipPage">
          <BaseInput
            id="payment_sheet_start_date"
            input-name="start_date"
            input-type="date"
            show-label
            required
            label-text="Data Inicial"
            :value="payment_sheet.start_date"
            dispatch="PaymentSheets/setStartDate"
            :error-message="getErrorMessage('data inicial')"
          />
        </div>
        <div v-if="isScholarshipPage">
          <BaseInput
            id="payment_sheet_end_date"
            input-name="end_date"
            input-type="date"
            show-label
            required
            label-text="Data Final"
            :value="payment_sheet.end_date"
            dispatch="PaymentSheets/setEndDate"
            :error-message="getErrorMessage('data final')"
          />
        </div>
        <div class="col-span-2" v-if="isTransportationVoucherPage">
          <BaseInput
            id="payment_sheet_reference"
            input-name="reference"
            input-type="text"
            show-label
            required
            label-text="Referência"
            :value="payment_sheet.reference"
            dispatch="PaymentSheets/setReference"
            :error-message="getErrorMessage('referência')"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            id="payment_sheet_process_number"
            input-name="process_number"
            input-type="text"
            show-label
            required
            label-text="Nº do Processo"
            :value="payment_sheet.process_number"
            dispatch="PaymentSheets/setProcessNumber"
            :error-message="getErrorMessage('nº do processo')"
          />
        </div>
        <div v-if="isScholarshipPage">
          <Multiselect
            :close-on-select="true"
            label-text="Parcela"
            show-label
            clear-on-select
            required
            select-placeholder="Selecione a parcela"
            :multiple-option="false"
            :value="installment"
            :options="payment_sheet_installment_options"
            :dispatch="setInstallment"
            :error-message="getErrorMessage('parcela')"
          />
        </div>
        <div v-if="isScholarshipPage">
          <Multiselect
            :close-on-select="true"
            label-text="Remessa"
            show-label
            clear-on-select
            required
            select-placeholder="Selecione a remessa"
            :multiple-option="false"
            :value="remittance"
            :options="payment_sheet_remittance_options"
            :dispatch="setRemittance"
            :error-message="getErrorMessage('remessa')"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="PaymentSheets/closeModalAddPaymentSheet"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="payment_sheet.id"
            text="Atualizar"
            typeButton="success"
            dispatch="PaymentSheets/update"
          />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="PaymentSheets/save" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import Label from "@/components/Label.vue";
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import Multiselect from "@/components/forms/Multiselect.vue";

export default {
  components: {
    Multiselect,
    BaseModal,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      payment_sheet: (state) => state.PaymentSheets.payment_sheet,
      scholarship_matrix: (state) => state.PaymentSheets.scholarship_matrix,
      installment: (state) => state.PaymentSheets.installment,
      remittance: (state) => state.PaymentSheets.remittance,
      scholarship_matrices_for_menus: (state) => state.UtilFunctions.scholarship_matrices_for_menus,
      payment_sheet_installment_options: (state) => state.UtilFunctions.payment_sheet_installment_options,
      payment_sheet_remittance_options: (state) => state.UtilFunctions.payment_sheet_remittance_options,
      payment_sheet_statuses_options: (state) => state.UtilFunctions.payment_sheet_statuses_options,
      payment_sheet_types_options: (state) => state.UtilFunctions.payment_sheet_types_options,
    }),
    ...mapGetters({
      getErrorMessage: "PaymentSheets/getErrorMessage",
    }),
    isScholarshipPage() {
      return window.location.pathname === "/bolsas";
    },
    isTransportationVoucherPage() {
      return window.location.pathname === "/vale_transporte";
    },
  },
  methods: {
    ...mapActions({
      setScholarshipMatrix: "PaymentSheets/setScholarshipMatrix",
      setInstallment: "PaymentSheets/setInstallment",
      setRemittance: "PaymentSheets/setRemittance",
    }),
  },
};
</script>
