<template>
  <div>
    <div class="mr-8 font-system uppercase font-medium text-xs text-ashes-dark" v-if="this.data">
      <input class="mr-2" type="checkbox" v-model="allChecked" @change="onCheckAll" />
      <label for="checkAll">Marcar todas as turmas</label>
    </div>
    <div id="tabs-button" class="my-6">
      <FilterComponent>
        <BaseInput
          active
          input-name="initial_date"
          input-type="date"
          show-label
          label-text="Data Inicial"
          dispatch="ModalExportTeamPresence/setInitialDate"
        />
        <BaseInput
          active
          input-name="initial_date"
          input-type="date"
          show-label
          label-text="Data Final"
          dispatch="ModalExportTeamPresence/setFinalDate"
        />
        <Multiselect
          v-if="showExport"
          id="filterArea"
          selectPlaceholder="Selecionar"
          label-text="% de Frequência"
          class="mr-5"
          closeOnSelect
          required
          show-label
          :multipleOption="false"
          :options="period_presence_options"
          :value="period_presence"
          :dispatch="setPeriodPresence"
        />
        <BaseInput
          v-if="period_presence?.id === 2 && showExport"
          active
          input-name="initial_date"
          input-type="date"
          show-label
          label-text="Porcentagem - Data Inicial"
          dispatch="ModalExportTeamPresence/setPercentageInitialDate"
        />
        <BaseInput
          v-if="period_presence?.id === 2 && showExport"
          active
          input-name="initial_date"
          input-type="date"
          show-label
          label-text="Porcentagem - Data Final"
          dispatch="ModalExportTeamPresence/setPercentageFinalDate"
        />
        <BaseInput
          v-if="showExport"
          active
          input-name="min_percentage"
          input-type="number"
          show-label
          inputMin="0"
          inputMax="100"
          label-text="% Inicial"
          dispatch="ModalExportTeamPresence/setMinPercentageDate"
        />
        <BaseInput
          v-if="showExport"
          active
          input-name="max_percentage"
          input-type="number"
          show-label
          inputMin="0"
          inputMax="100"
          label-text="% Final"
          dispatch="ModalExportTeamPresence/setMaxPercentageDate"
        />
      </FilterComponent>
    </div>
    <div class="grid grid-cols-2 items-center">
      <div class="ml-20">
        <BaseText
          text="Nome"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div>
        <div class="grid grid-cols-2">
          <div class="justify-self-end">
            <BaseText
              text="Nº de Estudantes"
              typeText="custom"
              weight="medium"
              :italic="false"
              mode="uppercase"
              size="xs"
              color="quantum"
            />
          </div>
          <div class="justify-self-center">
            <BaseText
              text="Período"
              typeText="custom"
              weight="medium"
              :italic="false"
              mode="uppercase"
              size="xs"
              color="quantum"
            />
          </div>
        </div>
      </div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li :key="item.id" :data-index="index" v-for="(item, index) in data">
        <BaseCard :class="liClass">
          <div class="grid grid-cols-2 items-center pt-2">
            <div>
              <div class="flex justify-start items-center">
                <div class="mr-10">
                  <input
                    :id="item.code"
                    type="checkbox"
                    name="teamsAllocations"
                    :value="item.id"
                    v-model="selectedItems"
                    @click="onCheck(item, $event)"
                  />
                </div>
                <div :class="iconClass">
                  <AppMaterialIcon
                    iconName="book"
                    iconType="outlined"
                    class="place-self-center text-white"
                    iconSize="36"
                  />
                </div>
                <BaseText
                  :text="item.code"
                  typeText="custom"
                  mode="underline"
                  weight="bold"
                  size="xl"
                  class="mr-1"
                />
                <BaseText text="|" typeText="custom" weight="normal" size="2xl" class="mr-1" />
                <BaseText
                  :text="item.discipline.name"
                  typeText="custom"
                  mode="underline"
                  weight="bold"
                  size="xl"
                  class="mr-1"
                />
              </div>
            </div>
            <div class="justify-self-end">
              <div class="grid grid-cols-2">
                <div>
                  <BaseText :text="`${item.qtd_students}`" typeText="custom" size="sm" color="ashes-dark" />
                </div>
                <div>
                  <BaseText
                    :text="`${item.start} - ${item.closing}`"
                    typeText="custom"
                    size="sm"
                    color="ashes-dark"
                  />
                </div>
              </div>
            </div>
          </div>
        </BaseCard>
      </li>
    </BaseStaggeredFade>
    <div v-if="loading">
      <PreLoading />
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";
import AppMaterialIcon from "../../components/AppMaterialIcon.vue";
import BaseCard from "../../components/BaseCard.vue";
import PreLoading from "../../components/PreLoading.vue";
import { mapActions, mapState } from "vuex";
import Multiselect from "@/components/forms/Multiselect.vue";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    Multiselect,
    BaseInput,
    BaseText,
    BaseStaggeredFade,
    AppMaterialIcon,
    BaseCard,
    PreLoading,
  },
  computed: {
    ...mapState({
      loading: (state) => state.ModalExportTeamPresence.loading,
      period_presence_options: (state) => state.ModalExportTeamPresence.period_presence_options,
      period_presence: (state) => state.ModalExportTeamPresence.period_presence,
      min_percentage: (state) => state.ModalExportTeamPresence.min_percentage,
      max_percentage: (state) => state.ModalExportTeamPresence.max_percentage,
      showExport: (state) => state.ModalExportTeamPresence.modalOpen,
    }),
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        "p-6": true,
      };
    },
    iconClass() {
      let css = [];
      let bgColor = "bg-burnedYellow-classic";

      css.push(bgColor);
      css.push("rounded-full");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");
      css.push("mr-3");
      css.push("items-center");
      css.push("justify-center");
      css.push("min-w-1");

      return css;
    },
  },
  data() {
    return {
      allChecked: false,
      selectedItems: [],
      isPeriodPresenceValidMessage: "",
    };
  },
  props: {
    data: {
      type: Array,
    },
  },
  created() {
    this.$store.dispatch("ModalExportTeamPresence/setPeriodPresence", this.period_presence_options[0]);
  },
  methods: {
    onCheck(student, event) {
      if (event.target.checked) {
        this.$store.commit("ModalExportTeamPresence/addTeamsExportMutation", student);
        this.$store.commit("ModalExportTeamPresence/sumQtdLoadsMutation");
        //this.$store.dispatch("ModalExportTeamPresence/exportTeamPresence");
      } else {
        this.$store.commit("ModalExportTeamPresence/removeTeamsExportMutation", student);
        this.$store.commit("ModalExportTeamPresence/deductQtdLoadsMutation");
        //this.$store.dispatch("ModalExportTeamPresence/exportTeamPresence");
      }
    },
    onCheckAll() {
      if (this.allChecked) {
        this.selectedItems = this.data.map((item) => item.id);
        this.data.forEach((item) => {
          this.$store.commit("ModalExportTeamPresence/addTeamsExportMutation", item);
          this.$store.commit("ModalExportTeamPresence/sumQtdLoadsMutation");
        });
      } else {
        this.data.forEach((item) => {
          this.$store.commit("ModalExportTeamPresence/removeTeamsExportMutation", item);
          this.$store.commit("ModalExportTeamPresence/deductQtdLoadsMutation");
        });
        this.selectedItems = [];
      }
    },
    ...mapActions({
      setPeriodPresence: "ModalExportTeamPresence/setPeriodPresence",
    }),
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 56% 18% 26% 0%;
}
</style>
