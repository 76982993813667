<template>
  <div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-2xl col-span-2">
        <Label
          text="Cursos"
          :icon="null"
          :iconType="null"
          type="text-3xl"
          weight="semibold"
          normal
          mode="normal-case"
          :class="title"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          text="Adicionar Curso"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="Course/toggleModal"
          v-if="permit"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-12">
      <FilterComponent>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            label-text="Pesquisar por Nome do Curso"
            :value="name"
            input-placeholder="Pesquisar por Nome do Curso"
            dispatch="Courses/setFilterName"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setFilterCity"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div>
        <AppButtonColorful
          id="btn-consult-courses"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Courses/loadCourses"
          typeButton="primary"
        />
      </div>
      <div class="items-center justify-self-end" v-if="courses.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-clear-filters"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div class="mx-12 my-6">
      <div><CoursesCards :data="courses" /></div>
    </div>
    <ModalCourse :open="toggleModal" />
    <DeleteCourseConfirmation :open="modalToggleDeleteCourse" />
  </div>
</template>

<script>
import ModalCourse from "./ModalCourse.vue";
import CoursesCards from "./list/CoursesCards.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import DeleteCourseConfirmation from "@/views/alerts/DeleteCourseConfirmation.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import { mapState, mapActions } from "vuex";
import Label from "@/components/Label.vue";

export default {
  components: {
    Label,
    BaseInput,
    FilterComponent,
    Multiselect,
    CoursesCards,
    AppButtonColorful,
    ModalCourse,
    DeleteCourseConfirmation,
  },
  data() {
    return {
      permitList: false,
      permit: false,
    };
  },
  computed: {
    ...mapState({
      disciplines: (state) => state.Courses.disciplines,
      discipline: (state) => state.Courses.filter.discipline,
      courses: (state) => state.Courses.filteredCourses,
      toggleModal: (state) => state.Course.toggleModal,
      modalToggleDeleteCourse: (state) => state.Course.modalToggleDeleteCourse,
      state: (state) => state.Courses.filter.state,
      city: (state) => state.Courses.filter.city,
      name: (state) => state.Courses.filter.name,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
    }),
    title() {
      return {
        "text-ashes-hover": true,
      };
    },
  },
  methods: {
    ...mapActions({
      clearFilter: "Courses/clearFilter",
      canAcces: "AccessControl/canAcces",
      setFilterCity: "Courses/setFilterCity",
      setFilterState: "Courses/setFilterState",
    }),
    permitListCourses() {
      this.canAcces("course_index").then((resp) => {
        this.permitList = resp;
      });
    },
    permitChangeCourses() {
      this.canAcces("course_create").then((resp) => {
        this.permit = resp;
      });
    },
    isActive(discipline) {
      if (this.discipline) {
        return this.discipline.id === discipline.id;
      }
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
    clearFields() {
      this.$store.dispatch("Courses/clearFilter");
    },
  },
  created() {
    this.$store.dispatch("Courses/loadCourses");
    this.$store.dispatch("Courses/loadDisciplines");
    this.$store.dispatch("UtilFunctions/loadCities");
    this.permitChangeCourses();
    this.permitListCourses();
  },
};
</script>
