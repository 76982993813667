import { getAxios, baseURL } from "./Api";

export async function getParticipantPresences() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`participantes_presentes`);
}

export async function getRelationshipDegrees() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`participantes_presentes/graus_parentesco`);
}

export async function createParticipantPresence(participant_presence) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/participantes_presentes", participant_presence);
}

export async function updateParticipantPresence(id, participant_presence) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`participantes_presentes/${id}`, participant_presence);
}

export async function destroyParticipantPresence(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`participantes_presentes/${id}`);
}
