<template>
  <BaseModal width="w-128" v-if="open">
    <template v-slot:header>
      <div class="flex flex-row-reverse">
        <AppButtonIcon for-close dispatch="ModalInscription/toggleModalInscriptionAlertStatus" />
      </div>
      <div class="flex">
        <img src="../../assets/images/icon-alerta.svg" class="mr-2" />
        <BaseText text="Atenção" type-text="custom" weight="semibold" size="2xl" color="ashes-dark" />
      </div>
    </template>
    <template v-slot:body>
      <div class="text-quantum mb-4">
        O jovem só poderá ser aprovado após o atendimento presencial. Marque o atendimento presencial para
        poder aceitar o jovem na edição.
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-end">
        <AppButtonColorful
          text="ok"
          type-button="success"
          dispatch="ModalInscription/toggleModalInscriptionAlertStatus"
          class="mr-5"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonIcon,
    AppButtonColorful,
  },

  props: {
    open: {
      type: Boolean,
    },
  },
};
</script>
