<template>
  <div class="grid auto-grid-auto p-10">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-6 my-6">
      <div class="text-ashes-hover font-medium text-2xl col-span-2">
        <Label
          text="Técnicos"
          :icon="null"
          :iconType="null"
          type="text-3xl"
          weight="semibold"
          normal
          mode="normal-case"
          :class="title"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          text="Adicionar Técnico"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="Atendents/openModalAtendent"
          v-if="permitCreate"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-6 mb-2">
      <FilterComponent>
        <div>
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="name"
            label-text="Filtrar por Nome do Técnico"
            input-placeholder="Digite o Nome do Técnico"
            dispatch="Atendents/setFilterName"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="email"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="email"
            label-text="Filtrar por Email do Técnico"
            input-placeholder="Digite o Email do Técnico"
            dispatch="Atendents/setFilterEmail"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="cpf"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="cpf"
            label-text="Filtrar por CPF do Técnico"
            input-placeholder="Digite o CPF do Técnico"
            dispatch="Atendents/setFilterCpf"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="number_cress_crp"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="number_cress_crp"
            label-text="Filtrar por Código CRESS ou CRP do Técnico"
            input-placeholder="Digite o Código CRESS ou CRP"
            dispatch="Atendents/setFilterNumberCressCrp"
          />
        </div>

        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setFilterCity"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-6 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-atendents"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Atendents/loadAtendents"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="atendents.length > 0">
        <a href="javascript:void(0)" @click="clearQuery()">
          <AppButtonColorful
            id="btn-consult-atendents"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-teachers">
      <div class="mx-6"><AtendentsCards :data="atendents" /></div>
      <div id="teachers-pagination" class="mx-12 my-6 pb-3" v-if="atendents.length > 0">
        <BasePagination
          :total="totalAtendents"
          :total-per-page="totalPerPage"
          dispatch="Atendents/setAtendentsOffset"
        />
      </div>
      <ModalAtendent :open="modalAtendentOpen" />
      <ModalResetAtendentPassword :open="modalResetPassword" />
      <DeleteAtendentConfirmation :open="modalDeleteAtendentOpen" />
    </div>
  </div>
</template>

<script>
import AtendentsCards from "./list/AtendentsCards.vue";
import ModalAtendent from "./ModalAtendent.vue";
import ModalResetAtendentPassword from "./resets/ModalResetAtendentPassword.vue";
import DeleteAtendentConfirmation from "./alerts/DeleteAtendentConfirmation.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";
import BasePagination from "@/components/BasePagination.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import Label from "@/components/Label.vue";

export default {
  components: {
    BasePagination,
    FilterComponent,
    Multiselect,
    Label,
    AppButtonColorful,
    AtendentsCards,
    ModalAtendent,
    ModalResetAtendentPassword,
    DeleteAtendentConfirmation,
    BaseInput,
  },
  data() {
    return {
      permitCreate: false,
      permit: false,
      permitUp: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterCity: "Atendents/setFilterCity",
      setFilterState: "Atendents/setFilterState",
    }),
    clearQuery() {
      this.$store.dispatch("Atendents/clearQuery");
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
  },

  created() {
    // this.$store.dispatch("Atendents/loadAtendents", city);
    // this.$store.dispatch("Teacher/loadProfiles");
    this.$store.dispatch("Atendents/loadAtendents");
    this.$store.dispatch("UtilFunctions/loadCities");
    this.canAcces("psychosocial_attendant_create").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("psychosocial_attendant_create").then((resp) => {
      this.permitCreate = resp;
    });
    this.canAcces("psychosocial_attendant_index").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("psychosocial_attendant_update").then((resp) => {
      this.permitUp = resp;
    });
  },

  computed: {
    ...mapState({
      atendents: (state) => state.Atendents.atendents,
      modalDeleteAtendentOpen: (state) => state.Atendents.modalDeleteAtendentConfirmationOpen,
      modalAtendentOpen: (state) => state.Atendents.modalAtendentOpen,
      modalResetPassword: (state) => state.Atendents.modalResetPassword,
      name: (state) => state.Atendents.params.name,
      email: (state) => state.Atendents.params.email,
      cpf: (state) => state.Atendents.params.cpf,
      city: (state) => state.Atendents.params.city,
      number_cress_crp: (state) => state.Atendents.params.number_cress_crp,
      state: (state) => state.Atendents.params.state,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
      offset: (state) => state.Atendents.atendentsOffset,
      totalPerPage: (state) => state.Atendents.atendentsTotalPerPage,
      totalAtendents: (state) => state.Atendents.atendentsTotal,
    }),
    title() {
      return {
        "text-ashes-hover": true,
      };
    },
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Teachers/loadTeachers");
      this.backToTop();
    },
  },
};
</script>
