import Vue from "vue";
import JsonExcel from "vue-json-excel";
import { getSeasonsForMenus } from "@/service/SeasonApi";
import { getTeamsForAdvantages } from "@/service/TeamApi";
import { getEquipmentByAreaId } from "@/service/EquipmentApi";
Vue.component("downloadExcel", JsonExcel);

const state = {
  season: null,
  user: null,

  seasons: [],
  user_areas: [],
  user_equipments: [],
  equipments: [],
  teams: [],

  menuAdvantage: null,
  tabScholarshipAdvantageView: "scholarship_payment_sheet",
  tabTransportationVoucherAdvantageView: "transportation_voucher_payment_sheet",
  toggleScholarshipPaymentSheets: true,
  toggleScholarshipPaymentControls: false,
  toggleTransportationVoucherPaymentSheets: true,
  toggleTransportationVoucherPaymentControls: false,

  loading_variables: {
    load_teams: false,
  },

  params: {
    team: {
      city: null,
      area: null,
      equipment: null,
      code: null,
    },
    payment_control: {
      city: null,
      area: null,
      equipment: null,
      team: null,
      name: null,
      installment: null,
      remittance: null,
      status: null,
      reference: null,
      pending_scholarship: null,
    },
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  setSeasonsMutation: (state, seasons) => {
    state.seasons = seasons;
  },
  setSeasonMutation: (state, season) => {
    state.season = season;
    localStorage.setItem("season", JSON.stringify(season));
  },
  setUserMutation: (state, user) => {
    state.user = user;
  },
  setUserAreasMutation: (state, user_areas) => {
    state.user_areas = user_areas;
  },
  setUserEquipmentsMutation: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },
  setEquipmentsMutation: (state, equipments) => {
    state.equipments = equipments;
  },
  loadTeams: (state, teams) => {
    state.teams = teams;
  },

  // MUTATIONS - TOGGLES
  toggleScholarshipPaymentSheets: (state, value) => {
    state.toggleScholarshipPaymentSheets = value;
  },
  toggleScholarshipPaymentControls: (state, value) => {
    state.toggleScholarshipPaymentControls = value;
  },
  toggleTransportationVoucherPaymentSheets: (state, value) => {
    state.toggleTransportationVoucherPaymentSheets = value;
  },
  toggleTransportationVoucherPaymentControls: (state, value) => {
    state.toggleTransportationVoucherPaymentControls = value;
  },

  // MUTATIONS - TOGGLES AND MODALS [LOADINGS]
  toggleLoadingVariablesLoadTeams: (state) => {
    state.loading_variables.load_teams = !state.loading_variables.load_teams;
  },

  // --> MUTATIONS - FILTER TEAMS <--
  setFilterTeamCodeMutation: (state, code) => {
    state.params.team.code = code;
  },
  setFilterTeamCityMutation: (state, city) => {
    state.params.team.city = city;
  },
  setFilterTeamAreaMutation: (state, area) => {
    state.params.team.area = area;
  },
  setFilterTeamEquipmentMutation: (state, equipment) => {
    state.params.team.equipment = equipment;
  },

  // --> MUTATIONS - FILTER PAYMENT CONTROL <--
  setFilterPaymentControlCityMutation: (state, city) => {
    state.params.payment_control.city = city;
  },
  setFilterPaymentControlAreaMutation: (state, area) => {
    state.params.payment_control.area = area;
  },
  setFilterPaymentControlEquipmentMutation: (state, equipment) => {
    state.params.payment_control.equipment = equipment;
  },
  setFilterPaymentControlTeamMutation: (state, team) => {
    state.params.payment_control.team = team;
  },
  setFilterPaymentControlNameMutation: (state, name) => {
    state.params.payment_control.name = name;
  },
  setFilterPaymentControlInstallmentMutation: (state, installment) => {
    state.params.payment_control.installment = installment;
  },
  setFilterPaymentControlRemittanceMutation: (state, remittance) => {
    state.params.payment_control.remittance = remittance;
  },
  setFilterPaymentControlStatusMutation: (state, status) => {
    state.params.payment_control.status = status;
  },
  setFilterPaymentControlReferenceMutation: (state, reference) => {
    state.params.payment_control.reference = reference;
  },
  setFilterPaymentControlPendingScholarshipMutation: (state, pending_scholarship) => {
    state.params.payment_control.pending_scholarship = pending_scholarship;
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setScholarshipTabAdvantageViewMutation: (state, type) => {
    state.tabScholarshipAdvantageView = type;
  },
  setTransportationVoucherTabAdvantageViewMutation: (state, type) => {
    state.tabTransportationVoucherAdvantageView = type;
  },
  setMenuAdvantageViewMutation: (state, menu) => {
    state.menuAdvantage = menu;
  },

  clearTeams: (state) => {
    state.teams = [];
  },
};

function getFilterParamsForLoadTeams(rootState) {
  console.log("rootState.Beneficiaries.beneficiaries", rootState.Beneficiaries.all_teams_student_ids);
  return {
    payment_sheet_id: rootState.PaymentSheets.payment_sheet?.id,
    team_students_ids: rootState.Beneficiaries.all_teams_student_ids,
    code: state.params.team.code,
    city: state.params.team.city ? state.params.team.city.id : null,
    area: state.params.team.area ? state.params.team.area.id : null,
    season_id: state.season ? state.season.id : null,
    equipment: state.params.team.equipment ? state.params.team.equipment.id : null,
    in_progress: true,
  };
}

const actions = {
  // --> DATA COMING FROM API
  loadSeasons: ({ commit }) => {
    getSeasonsForMenus()
      .then((response) => {
        commit("setSeasonsMutation", response.data.seasons);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadTeams: ({ commit, rootState }) => {
    commit("toggleLoadingVariablesLoadTeams");
    const params = getFilterParamsForLoadTeams(rootState);
    getTeamsForAdvantages(params)
      .then((response) => {
        commit("loadTeams", response.data.teams);
        commit("toggleLoadingVariablesLoadTeams");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
        commit("toggleLoadingVariablesLoadTeams");
      });
  },
  loadEquipments: ({ commit }) => {
    const params = {
      area_id: state.params.payment_control?.area?.id,
    };
    getEquipmentByAreaId(params)
      .then((response) => {
        commit("setEquipmentsMutation", response.data.equipment);
        commit("setFilterPaymentControlEquipmentMutation", null);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },

  // --> ACTIONS - SETTING TABS <--
  setScholarshipTabAdvantageView: ({ commit }, payload) => {
    commit("setScholarshipTabAdvantageViewMutation", payload);
    switch (payload) {
      case "scholarship_payment_sheet":
        commit("toggleScholarshipPaymentSheets", true);
        commit("toggleScholarshipPaymentControls", false);
        break;
      case "scholarship_payment_control":
        commit("toggleScholarshipPaymentSheets", false);
        commit("toggleScholarshipPaymentControls", true);
        break;
    }
  },
  setTransportationVoucherTabAdvantageView: ({ commit }, payload) => {
    commit("setTransportationVoucherTabAdvantageViewMutation", payload);
    switch (payload) {
      case "transportation_voucher_payment_sheet":
        commit("toggleTransportationVoucherPaymentSheets", true);
        commit("toggleTransportationVoucherPaymentControls", false);
        break;
      case "transportation_voucher_payment_control":
        commit("toggleTransportationVoucherPaymentSheets", false);
        commit("toggleTransportationVoucherPaymentControls", true);
        break;
    }
  },

  // --> ACTIONS - OTHER ACTIONS <--
  setSeason: ({ commit, dispatch }, payload) => {
    commit("setSeasonMutation", payload);
    dispatch("PaymentSheets/setSeason", payload, { root: true });
  },
  setUser: ({ commit }, payload) => {
    commit("setUserMutation", payload);
  },
  setUserAreas: ({ commit }, payload) => {
    commit("setUserAreasMutation", payload);
  },
  setUserEquipments: ({ commit }, payload) => {
    commit("setUserEquipmentsMutation", payload);
  },

  // --> ACTIONS - FILTER TEAMS <--
  setFilterTeamCode: ({ commit }, payload) => {
    commit("setFilterTeamCodeMutation", payload.value);
  },
  setFilterTeamCity: ({ commit }, payload) => {
    commit("setFilterTeamCityMutation", payload);
  },
  setFilterTeamArea: ({ commit }, payload) => {
    commit("setFilterTeamAreaMutation", payload);
  },
  setFilterTeamEquipment: ({ commit }, payload) => {
    commit("setFilterTeamEquipmentMutation", payload);
  },

  // --> ACTIONS - FILTER PAYMENT CONTROL <--
  setFilterPaymentControlCity: ({ commit }, payload) => {
    commit("setFilterPaymentControlCityMutation", payload);
  },
  setFilterPaymentControlArea: ({ commit }, payload) => {
    commit("setFilterPaymentControlAreaMutation", payload);
  },
  setFilterPaymentControlEquipment: ({ commit }, payload) => {
    commit("setFilterPaymentControlEquipmentMutation", payload);
  },
  setFilterPaymentControlTeam: ({ commit }, payload) => {
    commit("setFilterPaymentControlTeamMutation", payload);
  },
  setFilterPaymentControlName: ({ commit }, payload) => {
    commit("setFilterPaymentControlNameMutation", payload.value);
  },
  setFilterPaymentControlInstallment: ({ commit }, payload) => {
    commit("setFilterPaymentControlInstallmentMutation", payload);
  },
  setFilterPaymentControlRemittance: ({ commit }, payload) => {
    commit("setFilterPaymentControlRemittanceMutation", payload);
  },
  setFilterPaymentControlStatus: ({ commit }, payload) => {
    commit("setFilterPaymentControlStatusMutation", payload);
  },
  setFilterPaymentControlReference: ({ commit }, payload) => {
    commit("setFilterPaymentControlReferenceMutation", payload.value);
  },
  setFilterPaymentControlPendingScholarship: ({ commit }, payload) => {
    commit("setFilterPaymentControlPendingScholarshipMutation", payload);
  },

  clearTeams: ({ commit }) => {
    commit("clearTeams");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
