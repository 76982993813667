import { getAxios, baseURL } from "./Api";

export async function getUserById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`usuarios/${_id}`);
}

export async function getUsers(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/usuarios`;
  return api.get(url, { params: filters });
}

export async function getUsersForSpreadsheet(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/usuarios/exportar_planilha`;
  return api.get(url, { params: filters });
}

export async function getUsersForPsychosocial(params) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `usuarios/listar_usuarios_psicossocial`;
  return api.get(url, { params: params });
}

export async function getUsersForProductiveInsertion(params) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `usuarios/para_insercoes_produtivas`;
  return api.get(url, { params: params });
}

export async function createUser(user) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/usuarios", { user: user });
}

export async function destroyUser(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`usuarios/${id}`);
}

export async function updateUser(id, user) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`usuarios/${id}`, { user: user });
}
