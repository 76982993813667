<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="area.id"
            text="Editar área"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar área"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Area/closeModal" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2">
          <BaseInput
            id="name"
            input-name="name"
            input-placeholder="Área XX ..."
            input-type="text"
            show-label
            label-text="nome"
            :value="area.name"
            :error-message="getErrorMessage('name', area.name)"
            required
            dispatch="Area/setName"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Município"
            :dispatch="setCity"
            required
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            id="bairros"
            select-placeholder="Selecione as opções"
            :label-text="getLabelNeighborhoods()"
            :close-on-select="false"
            show-label
            multipleOption
            :value="area.neighborhoods_attributes"
            :options="getNeighborhoods(neighborhoods)"
            :error-message="getErrorMessage('has_neighborhoods', area.neighborhoods_attributes)"
            :dispatch="setNeighborhoods"
            no-options-message="Todos os bairros já foram vinculados"
            required
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Area/closeModal" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty v-if="area.id" text="Salvar" typeButton="success" dispatch="Area/updateArea" />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="Area/saveArea" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseModal,
    BaseInput,
    Multiselect,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: Boolean,
  },
  computed: {
    ...mapState({
      neighborhoods: (state) => state.Neighborhoods.neighborhoodsWithoutArea,
      area: (state) => state.Area.area,
      state: (state) => state.Area.area.state,
      city: (state) => state.Area.area.city,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
    }),
    ...mapGetters({
      getNeighborhoods: "Area/getNeighborhoods",
      getErrorMessage: "Area/getErrorMessage",
    }),
  },
  created() {
    this.$store.dispatch("UtilFunctions/loadStates");
  },
  methods: {
    ...mapActions({
      setNeighborhoods: "Area/setNeighborhoods",
      setCity: "Area/setCity",
      setState: "Area/setState",
    }),
    getLabelCity() {
      return this.state ? "Município" : "Selecione o estado para listar os municípios";
    },
    getLabelNeighborhoods() {
      if (this.city === null) {
        return "Selecione o município para carregar os bairros";
      } else {
        return this.neighborhoods?.length > 0
          ? "Vincular Bairros"
          : `Todos os bairros do Município de ${this.city.name} já foram vinculados`;
      }
    },
  },
  watch: {
    city: function () {
      if (this.city != null) {
        this.$store.dispatch("Neighborhoods/loadNeighborhoodsWithoutArea", this.city?.id);
      }
    },
  },
};
</script>
