<template>
  <div>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4 ml-5">
        <BaseText
          text="Área de Abrangência"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Município"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="N° de Bairros"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="N° De Equipamentos"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li :key="area.id" :data-index="index" @click.prevent="gotToArea(area)" v-for="(area, index) in data">
        <div>
          <AppCardArea :area="area" />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardArea from "../../components/AppCardArea.vue";
import router from "../../router/index";
import BaseText from "@/components/BaseText.vue";
import BaseStaggeredFade from "@/components/BaseStaggeredFade.vue";

export default {
  components: {
    BaseStaggeredFade,
    BaseText,
    AppCardArea,
  },
  props: {
    data: Array,
  },
  methods: {
    gotToArea(params) {
      router.push({ name: "area", params: { id: params.id } });
    },
  },
};
</script>
<style scoped>
.grid-cols-custom {
  grid-template-columns: 18% 18% 19% 10%;
}
</style>
