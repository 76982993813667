import { getAxios, baseURL } from "./Api";

export async function getScholarshipMatrices(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `matriz_de_bolsas`;
  return api.get(url, { params: filters });
}

export async function getScholarshipMatricesForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `matriz_de_bolsas/para_menus`;
  return api.get(url, { params: filters });
}

export async function getScholarshipMatrixById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`matriz_de_bolsas/${_id}`);
}

export async function createScholarshipMatrix(scholarship_matrix) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/matriz_de_bolsas", scholarship_matrix);
}

export async function updateScholarshipMatrix(id, scholarship_matrix) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`matriz_de_bolsas/${id}`, scholarship_matrix);
}

export async function destroyScholarshipMatrix(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`matriz_de_bolsas/${id}`);
}
