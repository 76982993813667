<template>
  <div class="mx-12">
    <div id="title-page" class="my-6">
      <div class="text-ashes-hover font-medium text-3xl">Benefícios {{ titleStatusBenefits() }}</div>
    </div>
    <div id="back" class="my-6">
      <router-link class="text-burnedYellow hover:text-burnedYellow-hover flex items-center" :to="linkReturn">
        <AppMaterialIcon iconName="arrow_back" iconType="outlined" iconSize="18" class="mr-1" />
        <BaseText text="Voltar" type-text="custom" weight="semibold" class="hover:underline" />
      </router-link>
    </div>
    <div id="subtitle" class="my-6">
      <div class="text-ashes-hover font-medium text-3xl">
        <BaseText
          :text="benefit.description"
          type-text="custom"
          weight="semibold"
          size="3xl"
          color="ashes-dark"
        />
        <AppButtonIcon
          v-if="!itsPaidBenefits() && permitUp"
          iconName="edit"
          :payload="benefit"
          dispatch="Benefit/openEditModal"
        />
        <AppButtonIcon
          v-if="!itsPaidBenefits() && permitDes"
          iconName="delete"
          :payload="benefit"
          dispatch="Benefit/openCloseModalDeleteBenefitConfirmation"
        />
        <ModalBenefit :open="modalBenefitOpen" />
        <DeleteBenefitConfirmation :open="openModalDelete" />
        <ModalMatrix :open="modalMatrixInfoOpen" />
      </div>
    </div>
    <div id="filter" class="my-10 flex justify-between">
      <div class="flex items-center">
        <div v-if="false">
          <div class="text-ashes-dark font-medium text-sm mr-4">FILTRAR</div>
          <div class="w-52 mr-4">
            <Multiselect
              id="filter-team"
              select-placeholder="Turma..."
              close-on-select
              :multiple-option="false"
              value=""
              :options="teams"
              dispatch=""
            />
          </div>
          <div class="w-52 mr-4">
            <Multiselect
              id="filter-fase"
              select-placeholder="Fase..."
              close-on-select
              :multiple-option="false"
              :value="filteredFase"
              :options="fases"
              :dispatch="setFases"
            />
          </div>
          <a href="javascript:void(0)" @click="clearFilter()">
            <img
              class="inline-block"
              src="../assets/images/filter_alt_black_24dp.svg"
              alt=""
              style="cursor: pointer"
            />
            <BaseText
              text="Limpar"
              typeText="custom"
              weight="normal"
              :italic="false"
              mode="underline"
              size="xs"
              color="burnedYellow"
            />
          </a>
        </div>
      </div>

      <div>
        <AppButtonColorful
          v-if="!itsPaidBenefits()"
          showIcon
          iconName="add"
          text="Importar Alunos"
          dispatch="ModalAllocationTeamBenefit/openModal"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="list-benefit-payments" class="my-6">
      <BenefitsPaymentsCards :students="listStudents" />
    </div>
    <div id="footer" class="relative inset-x-0 bottom-0 h-64">
      <div class="grid grid-flow-col">
        <div class="flex justify-start items-center">
          <div class="mx-12 my-6">
            <download-excel
              type="xls"
              v-bind:name="benefit.description + '.xls'"
              :escapeCsv="false"
              :meta="json_meta"
              :fields="json_fields"
              :data="json_data"
              worksheet="Beneficios"
            >
              <img
                class="inline-block"
                src="../assets/images/logout_black_24dp.svg"
                alt=""
                style="cursor: pointer"
              />
              <BaseText
                text="Exportar planilha"
                typeText="custom"
                :italic="false"
                color="burnedYellow"
                style="cursor: pointer"
              />
            </download-excel>
          </div>
        </div>
        <div class="flex justify-end gap-10">
          <div class="flex justify-end gap-10" v-if="!itsPaidBenefits()">
            <div v-if="benefit.type_benefit === 'public_transport'">
              <BaseInput
                active
                inputName="request_number"
                inputType="text"
                labelText="nº do pedido (SINDIÔNIBUS)"
                dispatch="Benefit/setRequestNumber"
                showLabel
              />
            </div>
            <div>
              <BaseInput
                active
                inputName="payday"
                :value="benefit.payday"
                inputType="date"
                labelText="Data de Pagamento *"
                showLabel
                dispatch="Benefit/setPayday"
              />
            </div>
            <div class="place-self-center" @click="goToBenefits()">
              <AppButtonColorful
                text="Finalizar Pagamento"
                dispatch="Benefit/closeBenefit"
                typeButton="primary"
              />
            </div>
          </div>
          <div class="flex justify-end gap-10" v-else>
            <div class="place-self-center" v-if="benefit.type_benefit === 'public_transport'">
              <BaseText
                text="nº do pedido (SINDIÔNIBUS): "
                typeText="custom"
                :italic="false"
                color="burnedYellow"
              />
              <BaseText
                :text="benefit.request_number"
                typeText="custom"
                :italic="false"
                color="burnedYellow"
              />
            </div>
            <div class="place-self-center">
              <BaseText text="Data de Pagamento: " typeText="custom" :italic="false" color="burnedYellow" />
              <BaseText :text="benefit.payday" typeText="custom" :italic="false" color="burnedYellow" />
            </div>
            <div class="place-self-center">
              <BaseText text="Pagamento Finalizado" typeText="custom" :italic="false" color="burnedYellow" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalAllocationTeamBenefit :open="modalAloccationsOpen" />
  </div>
</template>
<script>
import BaseText from "../components/BaseText.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import AppMaterialIcon from "../components/AppMaterialIcon.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import ModalAllocationTeamBenefit from "./ModalAllocationTeamBenefit.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import BenefitsPaymentsCards from "./list/BenefitsPaymentsCards.vue";
import router from "../router/index.js";
import { mapState, mapActions } from "vuex";
import ModalBenefit from "./ModalBenefit.vue";
import DeleteBenefitConfirmation from "./alerts/DeleteBenefitConfirmation.vue";
import ModalMatrix from "./infos/ModalMatrix.vue";

export default {
  components: {
    BaseText,
    AppMaterialIcon,
    AppButtonIcon,
    ModalAllocationTeamBenefit,
    Multiselect,
    AppButtonColorful,
    BenefitsPaymentsCards,
    BaseInput,
    ModalBenefit,
    DeleteBenefitConfirmation,
    ModalMatrix,
  },

  data: () => ({
    permitUp: false,
    permitDes: false,
    permit: false,
    json_fields: {
      Edicao: "season_name",
      Beneficio: "benefit_description",
      Status: "benefit_status",
      "Data de Pagamento": "benefit_payday",
      "Nome do Jovem": "student_name",
      "Nome Social do Jovem": "student_social_name",
      Turma: "team_code",
      Fase: "phase_name",
      "Nº de Encontros": "benefit_amount_meetings",
      "% de presenças": "presence_percent",
      Valor: "benefit_value",
    },
    json_fields_transport: {
      Edicao: "season_name",
      Beneficio: "benefit_description",
      Status: "benefit_status",
      "Nº do Pedido (SINDIÔNIBUS)": "benefit_request_number",
      "Data de Pagamento": "benefit_payday",
      "Nome do Jovem": "student_name",
      "Nome Social do Jovem": "student_social_name",
      Turma: "team_code",
      Fase: "phase_name",
      "Nº de Encontros": "benefit_amount_meetings",
      Valor: "benefit_value",
    },
    json_data: [
      {
        season_name: null,
        benefit_description: null,
        benefit_status: null,
        benefit_payday: null,
        student_name: null,
        student_social_name: null,
        team_code: null,
        phase_name: null,
        benefit_amount_meetings: null,
        presence_percent: null,
        benefit_value: null,
      },
    ],
    json_data_transport: [
      {
        season_name: null,
        benefit_description: null,
        benefit_status: null,
        benefit_request_number: null,
        benefit_payday: null,
        student_name: null,
        student_social_name: null,
        team_code: null,
        phase_name: null,
        benefit_amount_meetings: null,
        benefit_value: null,
      },
    ],
    json_meta: [[{ key: "charset", value: "utf-8" }]],
    arrayData: new Array(),
    linkReturn: "/admin/beneficios/pendentes",
  }),

  created() {
    let id = this.$route.params.id;
    this.$store.dispatch("Benefit/loadBenefit", id);
    this.$store.dispatch("Benefit/loadStudentsForPayment", id);
    this.$store.dispatch("ModalAllocationTeamBenefit/loadTeams", id);
    this.canAcces("benefit_create").then((resp) => {
      this.permit = resp;
    });
  },

  beforeUpdate: function () {
    this.exportToExcel();
  },

  computed: {
    ...mapState({
      city: (state) => state.City.city,
      benefit: (state) => state.Benefit.benefit,
      listStudents: (state) => state.Benefit.studentsForPayment,
      modalAloccationsOpen: (state) => state.ModalAllocationTeamBenefit.openModal,
      paid_benefit: (state) => state.Benefit.paid_benefit,
      modalBenefitOpen: (state) => state.Benefit.modalBenefitOpen,
      openModalDelete: (state) => state.Benefit.modalDeleteBenefitConfirmationOpen,
      modalMatrixInfoOpen: (state) => state.Benefits.modalMatrixInfoOpen,
    }),
  },

  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    benefitAcces() {
      this.canAcces("benefit_index").then((resp) => {
        this.permit = resp;
      });
    },
    benefitDelete() {
      this.canAcces("benefit_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    benefitChange() {
      this.canAcces("benefit_update").then((resp) => {
        this.permitUp = resp;
      });
    },

    titleStatusBenefits() {
      if (this.paid_benefit) {
        this.linkReturn = "/admin/beneficios/pagos";
      } else {
        this.linkReturn = "/admin/beneficios/pendentes";
      }
      return this.paid_benefit ? "Pagos" : "a Pagar";
    },
    itsPaidBenefits() {
      return this.paid_benefit;
    },
    goToBenefits() {
      let status = this.paid_benefit ? "pagos" : "pendentes";
      router.push({ name: "benefits", params: { status: status } });
    },
    exportToExcel() {
      this.json_data = null;
      this.arrayData = new Array();
      if (this.benefit.type_benefit === "public_transport") {
        this.json_fields = this.json_fields_transport;
      }
      this.listStudents.forEach((element, index, array) => {
        let season_name = this.benefit?.season?.name;
        let phase_name = array[index].phase.name;
        let team_code = array[index].team.code;
        let student_name = array[index].teams_student.student?.name;
        let student_social_name = array[index].teams_student.student.social_name;
        let benefit_amount_meetings = array[index].amount_meetings;
        let presence_percent = array[index].presence_percent;
        let benefit_value = array[index].value;
        let benefit_request_number = this.benefit.request_number;
        let benefit_payday = this.benefit.payday;
        let benefit_description = this.benefit.description;
        let benefit_status = this.titleStatusBenefits();
        const arrayItem = new Array();
        arrayItem["season_name"] = season_name;
        arrayItem["benefit_description"] = benefit_description;
        arrayItem["benefit_status"] = benefit_status;
        if (this.benefit.type_benefit == "public_transport") {
          arrayItem["benefit_request_number"] = benefit_request_number;
        }
        arrayItem["benefit_payday"] = benefit_payday;
        arrayItem["student_name"] = student_name;
        arrayItem["student_social_name"] = student_social_name;
        arrayItem["team_code"] = team_code;
        arrayItem["phase_name"] = phase_name;
        arrayItem["benefit_amount_meetings"] = benefit_amount_meetings;
        arrayItem["presence_percent"] = presence_percent;
        arrayItem["benefit_value"] = benefit_value;
        this.arrayData.push(arrayItem);
      });
      this.json_data = this.arrayData;
    },
  },
};
</script>

<style></style>
