<template>
  <div>
    <div class="toggle-container">
      <button @click="toggleFilters" :class="{ 'toggle-button': true, 'full-width': showFilters }">
        <Label
          :text="showFilters ? 'Esconder filtros' : 'Mostrar filtros'"
          :icon="null"
          :icon-type="null"
          type="text-base"
          weight="medium"
          normal
          mode="normal-case"
          class="label-text"
        />
        <span :class="{ 'toggle-icon': true, 'full-width-icon': showFilters }">
          {{ showFilters ? "▲" : "▼" }}
        </span>
      </button>
    </div>
    <transition name="fade">
      <div v-show="showFilters" class="filters-container">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import Label from "./Label.vue";

export default {
  components: {
    Label,
  },
  data() {
    return {
      showFilters: false,
    };
  },
  methods: {
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
  },
};
</script>

<style scoped>
.filter-component {
  margin: 1rem 3.5rem;
}

.toggle-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.toggle-button {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s, width 0.3s;
  justify-content: center;
}

.toggle-button:hover {
  background-color: #e0e0e0;
}

.full-width {
  width: 100%;
}

.label-text {
  color: #333;
}

.toggle-icon {
  margin-left: 0.5rem;
  color: #555;
}

.full-width-icon {
  margin-left: auto;
}

.filters-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: flex-start;
  padding: 1rem;
  gap: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
