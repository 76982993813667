import {
  getSegments,
  getSegmentById,
  createSegment,
  updateSegment,
  destroySegment,
} from "../../service/SegmentsApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

const state = {
  segment: {
    id: null,
    name: null,
  },

  segments: [],
  errorMessages: [],
  segmentId: null,

  modalToggleAddSegment: false,
  modalToggleDeleteSegment: false,
  modalToggleViewSegment: false,

  segmentsTotal: 0,
  segmentsTotalPerPage: 10,
  segmentsOffset: 0,

  params: {
    name: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadSegments: (state, segments) => {
    state.segments = segments;
  },

  // MUTATIONS - FIELDS TO API
  setIdMutation: (state, id) => {
    state.segment.id = id;
  },
  setNameMutation: (state, name) => {
    state.segment.name = name;
  },

  // --> MUTATIONS - DELETION <--
  segmentForDeletion: (state, payload) => {
    if (payload) {
      state.segmentId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddSegment: (state) => {
    state.modalToggleAddSegment = !state.modalToggleAddSegment;
  },
  toggleModalDeleteSegment: (state) => {
    state.modalToggleDeleteSegment = !state.modalToggleDeleteSegment;
  },
  toggleModalViewSegment: (state) => {
    state.modalToggleViewSegment = !state.modalToggleViewSegment;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setSegmentsTotalPerPage: (state, segmentsTotalPerPage) => {
    state.segmentsTotalPerPage = segmentsTotalPerPage;
  },
  setSegmentsOffset: (state, segmentsOffset) => {
    state.segmentsOffset = segmentsOffset;
  },
  setSegmentsTotal: (state, segmentsTotal) => {
    state.segmentsTotal = segmentsTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.segment) {
      state.segment[prop] = null;
    }
  },
  clearQuery: (state) => {
    state.params.name = null;
    state.segments = [];
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadSegments: ({ commit }, from) => {
    const params = {
      name: state.params.name,
      per_page: state.segmentsTotalPerPage,
      offset: state.segmentsOffset,
    };
    getSegments(params)
      .then((response) => {
        commit("loadSegments", response.data.segments);
        commit("setSegmentsTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getSegmentById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.segments.id);
        commit("setNameMutation", response.data.segments.name);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddSegment: async ({ commit }) => {
    commit("toggleModalAddSegment");
  },
  closeModalAddSegment: ({ commit }) => {
    commit("toggleModalAddSegment");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openEditModalAddSegment: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddSegment");
  },
  closeEditModalAddSegment: ({ commit }) => {
    commit("toggleModalAddSegment");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewSegment");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewSegment");
    commit("clearFields");
  },
  toggleModalDeleteSegment: ({ commit }, payload) => {
    commit("toggleModalDeleteSegment");
    if (state.modalToggleDeleteSegment && payload) {
      commit("segmentForDeletion", payload);
    } else {
      commit("segmentForDeletion", null);
    }
  },

  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },

  // --> ACTIONS - PAGINATIONS <--
  setSegmentsTotalPerPage: ({ commit }, payload) => {
    commit("setSegmentsTotalPerPage", payload.totalPerPage);
  },
  setSegmentsOffset: ({ commit }, payload) => {
    commit("setSegmentsOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },

  //ACTIONS - CRUD
  save: ({ commit, dispatch }) => {
    createSegment(state.segment)
      .then(() => {
        Vue.$toast.success("Segmento cadastrado com sucesso!");
        dispatch("closeModalAddSegment");
        dispatch("loadSegments");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar o segmento! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  update: ({ commit, dispatch, state }) => {
    updateSegment(state.segment.id, state.segment)
      .then(() => {
        dispatch("loadSegments");
        dispatch("closeEditModalAddSegment");
        Vue.$toast.success("Segmento atualizado com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar o segmento! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroySegment(state.segmentId)
      .then(() => {
        Vue.$toast.success("Segmento deletado com sucesso!");
        commit("toggleModalDeleteSegment");
        dispatch("loadSegments");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
