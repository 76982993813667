<template>
  <div class="font-system">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">Interface de Inserção Produtiva</div>
    </div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between p-4 items-end mx-12 my-6 gap-4">
      <div class="w-52">
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          select-placeholder="Selecionar edição"
          :multiple-option="false"
          :value="season"
          :options="seasons"
          label-text="Edição"
          :dispatch="setSeason"
          required
        />
      </div>
    </div>
    <div id="menus_productive_insertion_main_page" class="mx-16 flex" v-if="season">
      <div v-if="permitListOpportunities">
        <a @click="changeTab('opportunities')" href="javascript:void(0)">
          <BaseText
            text="Seleções"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorOpportunities"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div v-if="permitListForwardingProductiveInsertion">
        <a @click="changeTab('referredParcipants')" href="javascript:void(0)">
          <BaseText
            text="Participantes Encaminhados"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorReferredParcipants"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div v-if="permitListSpontaneousProductiveInsertion">
        <a @click="changeTab('spontaneousInsertions')" href="javascript:void(0)">
          <BaseText
            text="Inserções Espontâneas"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorSpontaneousInsertions"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div v-if="permitListForwardingReservationRegistration">
        <a @click="changeTab('forwardingReservationRegistration')" href="javascript:void(0)">
          <BaseText
            text="Encaminhamentos para Cadastro Reserva"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorForwardingReservationRegistrations"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div>
        <a @click="changeTab('profileSearch')" href="javascript:void(0)">
          <BaseText
            text="Busca por Perfil"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorProfileSearch"
          />
        </a>
      </div>
    </div>
    <div id="list-productive-insertion-types">
      <div v-if="season">
        <Opportunity :open="toggleOpportunities" />
      </div>
      <div v-if="season">
        <ForwardingProductiveInsertion :open="toggleReferredParcipants" />
      </div>
      <div v-if="season">
        <ProfileSearch :open="toggleProfileSearch" />
      </div>
      <div v-if="season">
        <SpontaneousProductiveInsertion :open="toggleSpontaneousInsertions" />
      </div>
      <div v-if="season">
        <ForwardingReservationRegistration :open="toggleForwardingReservationRegistration" />
      </div>
    </div>
  </div>
</template>

<script>
import ForwardingProductiveInsertion from "./ForwardingProductiveInsertion.vue";
import Opportunity from "./Opportunity.vue";
import BaseText from "../../components/BaseText.vue";
import Multiselect from "../../components/forms/Multiselect.vue";
import { mapState, mapActions } from "vuex";
import ProfileSearch from "@/views/productive_insertion_pages/ProfileSearch.vue";
import SpontaneousProductiveInsertion from "@/views/productive_insertion_pages/SpontaneousProductiveInsertion.vue";
import ForwardingReservationRegistration from "@/views/productive_insertion_pages/ForwardingReservationRegistration.vue";

export default {
  components: {
    ForwardingReservationRegistration,
    SpontaneousProductiveInsertion,
    ProfileSearch,
    BaseText,
    ForwardingProductiveInsertion,
    Opportunity,
    Multiselect,
  },
  data() {
    return {
      permitCreate: false,
      permit: false,
      permitListOpportunities: false,
      permitListForwardingProductiveInsertion: false,
      permitListProfileSearch: false,
      permitListSpontaneousProductiveInsertion: false,
      permitListForwardingReservationRegistration: false,
      permitUp: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setSeason: "ProductiveInsertions/setSeason",
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    changeTab(value) {
      this.$store.dispatch("ProductiveInsertions/setTabProductiveInsertionView", value);
    },
  },
  created() {
    this.$store.dispatch("ProductiveInsertions/loadSeasons");
    this.$store.dispatch("UtilFunctions/loadStates");

    this.canAcces("opportunity_index").then((response) => {
      this.permitListOpportunities = response;
    });
    this.canAcces("forwarding_productive_insertion_index").then((response) => {
      this.permitListForwardingProductiveInsertion = response;
    });
    this.canAcces("spontaneous_productive_insertion_index").then((response) => {
      this.permitListSpontaneousProductiveInsertion = response;
    });
    this.canAcces("forwarding_reservation_registration_index").then((response) => {
      this.permitListForwardingReservationRegistration = response;
    });
    this.canAcces("inscription_load_for_productive_insertion").then((response) => {
      this.permitListProfileSearch = response;
    });

    const user = JSON.parse(localStorage.userData);
    this.$store.dispatch("ProductiveInsertions/setUser", user);
    this.$store.dispatch("ProductiveInsertions/setUserAreas", user.areas);

    if (user.equipments.length > 0) {
      this.$store.dispatch("ProductiveInsertions/setUserEquipments", user.equipments);
    }

    const season = JSON.parse(localStorage.season);
    if (season) {
      this.$store.dispatch("ProductiveInsertions/setSeason", season);
    }
  },
  computed: {
    ...mapState({
      city: (state) => state.City.city,
      seasons: (state) => state.ProductiveInsertions.seasons,
      season: (state) => state.ProductiveInsertions.season,
      tabProductiveInsertionView: (state) => state.ProductiveInsertions.tabProductiveInsertionView,
      toggleOpportunities: (state) => state.ProductiveInsertions.toggleOpportunities,
      toggleReferredParcipants: (state) => state.ProductiveInsertions.toggleReferredParcipants,
      toggleSpontaneousInsertions: (state) => state.ProductiveInsertions.toggleSpontaneousInsertions,
      toggleProfileSearch: (state) => state.ProductiveInsertions.toggleProfileSearch,
      toggleForwardingReservationRegistration: (state) =>
        state.ProductiveInsertions.toggleForwardingReservationRegistration,
    }),
    setColorOpportunities() {
      return this.tabProductiveInsertionView === "opportunities" ? "burnedYellow" : "ashes-hover";
    },
    setColorReferredParcipants() {
      return this.tabProductiveInsertionView === "referredParcipants" ? "burnedYellow" : "ashes-hover";
    },
    setColorSpontaneousInsertions() {
      return this.tabProductiveInsertionView === "spontaneousInsertions" ? "burnedYellow" : "ashes-hover";
    },
    setColorForwardingReservationRegistrations() {
      return this.tabProductiveInsertionView === "forwardingReservationRegistration"
        ? "burnedYellow"
        : "ashes-hover";
    },
    setColorProfileSearch() {
      return this.tabProductiveInsertionView === "profileSearch" ? "burnedYellow" : "ashes-hover";
    },
  },
};
</script>
