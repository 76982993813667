<template>
  <div class="m-5" v-if="open">
    <div id="person-data" class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-5">
        <BaseText text="Edição & Equipamento" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Edição"
          :text="inscription.student?.season?.name"
          type-text="topic-details"
        />
      </div>
      <div v-if="inscription.student?.equipment" class="col-span-3">
        <AppTextField
          show-label
          label-text="Equipamento"
          :text="inscription.student.equipment?.name"
          type-text="topic-details"
        />
      </div>
      <div v-else class="col-span-3">
        <AppTextField show-label label-text="Equipamento" text="NÃO INFORMADO" type-text="topic-details" />
      </div>

      <div class="col-span-5">
        <BaseText text="Dados Pessoais" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div>
        <AppTextField show-label label-text="rg" :text="inscription.student?.rg" type-text="topic-details" />
      </div>
      <div v-if="inscription.student">
        <AppTextField
          show-label
          label-text="Orgão Expedidor"
          :text="inscription.student?.dispatching_agency"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-3">
        <AppTextField
          show-label
          label-text="Expedição"
          :text="inscription.student?.expedition"
          type-text="topic-details"
        />
      </div>

      <div>
        <AppTextField
          show-label
          label-text="CPF"
          v-bind:text="inscription.student?.cpf | VMask('###.###.###-##')"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="cidade onde nasceu"
          :text="inscription.student?.city_of_birth"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="certidão de nascimento"
          :text="inscription.student?.birth_certificate | VMask('###### ## ## #### # ##### ### ####### ##')"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          id="book"
          show-label
          label-text="livro"
          :text="inscription.student?.book"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          id=""
          show-label
          label-text="folha"
          :text="inscription.student?.page"
          type-text="topic-details"
        />
      </div>

      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="email"
          :text="inscription.student?.email"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="telefone"
          :text="inscription.student?.cellphone_number | VMask('(##) #####-####')"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="whatsapp de contato"
          :text="inscription.student?.whatszap | VMask('(##) #####-####')"
          type-text="topic-details"
        />
      </div>

      <div>
        <AppTextField
          show-label
          label-text="data de nascimento"
          :text="formattedBirthdate(inscription.student?.birthdate)"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="gênero"
          :text="inscription.student?.gender"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Se identifica como LGBTQIAPN+"
          :text="inscription.student?.identifies_as_lgbtqiapn"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Pertence a povos originários"
          :text="inscription.student?.belongs_to_indigenous_peoples"
          type-text="topic-details"
        />
      </div>
      <div class="">
        <AppTextField
          show-label
          label-text="estado civil"
          :text="getCivilStateName()"
          type-text="topic-details"
        />
      </div>

      <div class="col-span-5">
        <BaseText text="Endereço" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="cep"
          :text="inscription.student?.cep | VMask('#####-###')"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="rua"
          :text="inscription.student?.street"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="número"
          :text="inscription.student?.street_number"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="bairro"
          :text="getNeighborhoodName()"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="comunidade"
          :text="inscription.student?.community"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField show-label label-text="município" :text="getCityName()" type-text="topic-details" />
      </div>
      <div class="col-span-2">
        <AppTextField show-label label-text="estado" :text="getStateName()" type-text="topic-details" />
      </div>
      <div class="col-span-4">
        <AppTextField
          show-label
          label-text="complemento (opcional)"
          :text="inscription.student?.complement"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5">
        <BaseText text="Dados Escolares" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="frequenta a escola?"
          :text="studying()"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="se não frequenta a escola qual a sua última série concluída?"
          :text="getCompletedSeriesName()"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Última escola em que estudou?"
          :text="
            inscription.student?.last_school_studied
              ? inscription.student.last_school_studied?.name
              : 'Não informado'
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Ano da última matrícula"
          :text="
            inscription.student?.year_of_last_enrollment
              ? inscription.student.year_of_last_enrollment?.name
              : 'Não informado'
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Status Escolar"
          :text="
            inscription.student?.educational_status
              ? inscription.student.educational_status?.name
              : 'Não informado'
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Status - Reinserção Escolar"
          :text="inscription.student?.school_reinsertion ? inscription.student.school_reinsertion?.name : '-'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5">
        <BaseText text="Dados Complementares" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-3">
        <AppTextField show-label label-text="tem ocupação" :text="busy()" type-text="topic-details" />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Em qual Área tem interesse na sua qualificação profissional?"
          :text="professionalQualificationNames()"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Qual turno você tem disponível para particapação do projeto?"
          :text="shift()"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Altura(m)"
          :text="inscription.student?.height"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Peso(Kg)"
          :text="inscription.student?.weight"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="tam. do tênis"
          :text="getShoeSize1()"
          type-text="topic-details"
        />
      </div>

      <div class="col-span-5">
        <BaseText text="Dados Do Responsável e Familiares" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
    </div>
    <div>
      <div
        :key="index"
        v-for="(relative, index) in inscription.student?.relatives_attributes"
        id="responsible-data"
        class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12"
      >
        <div class="col-span-4">
          <div class="flex items-end">
            <AppTextField
              show-label
              label-text="grau de parentesco"
              :text="getDegreeKinshipName(relative?.degree_kinship)"
              type-text="topic-details"
            />
            <div class="text-container" v-if="relative?.responsible || relative?.reference_person">
              <BaseText
                v-if="relative?.responsible"
                color="burnedYellow"
                text="Responsável Atual"
                type-text="custom"
              />
              <BaseText
                v-if="relative?.reference_person"
                color="burnedYellow"
                text="Pessoa de referência"
                type-text="custom"
              />
            </div>
          </div>
        </div>
        <div class="col-span-2">
          <AppTextField
            show-label
            label-text="nome completo"
            :text="relative?.name"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField show-label label-text="rg" :text="relative?.rg" type-text="topic-details" />
        </div>
        <div>
          <AppTextField show-label label-text="cpf" :text="relative?.cpf | VMask('###.###.###-##')" />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="telefone"
            :text="relative?.cellphone_number | VMask('(##) #####-####')"
          />
        </div>
        <div class="col-span-2">
          <AppTextField show-label label-text="e-mail" :text="relative?.email" />
        </div>
        <div>
          <AppTextField show-label label-text="data de nascimento" :text="relative?.birth_date" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppTextField from "../../components/AppTextField.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseText,
    AppTextField,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    this.backToTop();
    const inscription_id = sessionStorage.inscription_id;
    await this.$store.dispatch("SchoolReinsertions/loadInscriptionByIdForSchoolReinsertion", inscription_id);
  },
  computed: {
    ...mapState({
      inscription: (state) => state.SchoolReinsertions.inscription,
    }),
  },
  methods: {
    formattedBirthdate: (birthdate) => {
      return birthdate.split("-").reverse().join("/");
    },
    studying() {
      return this.inscription.student.studying ? "Sim" : "Não";
    },
    busy() {
      return this.inscription.student.busy ? "Sim" : "Não tenho ocupacão formal";
    },
    professionalQualificationNames() {
      return this.inscription.student.professional_qualifications_attributes
        .map((professional_qualification) => professional_qualification.description)
        .join(", ");
    },
    sportNames() {
      return this.inscription.student.sports_attributes.map((sport) => sport.description).join(", ");
    },
    shift() {
      let shifts = [];

      if (this.inscription.student.morning_shift) {
        shifts.push("Manhã");
      }

      if (this.inscription.student.afternoon_shift) {
        shifts.push("Tarde");
      }

      if (this.inscription.student.night_shift) {
        shifts.push("Noite");
      }

      return shifts.join(", ");
    },
    getDeficiencies() {
      let txt;

      txt = this.inscription.student.deficiencies_attributes.map((obj) => {
        return obj.name;
      });

      return txt.join(", ");
    },
    getStateName() {
      if (this.inscription.student.state) {
        return this.inscription.student.state.name;
      } else {
        return "";
      }
    },
    getCivilStateName() {
      if (this.inscription.student.civil_state) {
        return this.inscription.student.civil_state.name;
      } else {
        return "";
      }
    },
    getCityName() {
      if (this.inscription.student.city) {
        return this.inscription.student.city.name;
      } else {
        return "";
      }
    },
    getNeighborhoodName() {
      if (this.inscription.student.neighborhood) {
        return this.inscription.student.neighborhood.name;
      } else {
        return "";
      }
    },
    getCompletedSeriesName() {
      if (this.inscription.student.completed_series) {
        return this.inscription.student.completed_series.name;
      } else {
        return "";
      }
    },
    getShoeSize1() {
      if (this.inscription.student.shoe_size1) {
        return String(this.inscription.student.shoe_size1.name);
      } else {
        return "";
      }
    },
    getShoeSize2() {
      if (this.inscription.student.shoe_size2) {
        return String(this.inscription.student.shoe_size2.name);
      } else {
        return "";
      }
    },
    getShirtSize() {
      if (this.inscription.student.shirt_size) {
        return this.inscription.student.shirt_size.name;
      } else {
        return "";
      }
    },
    getPantsSize() {
      if (this.inscription.student.pants_size) {
        return this.inscription.student.pants_size.name;
      } else {
        return "";
      }
    },
    getBankName() {
      if (this.inscription.student.bank_name) {
        return this.inscription.student.bank_name.name;
      } else {
        return "";
      }
    },
    getAccountType() {
      if (this.inscription.student.account_type) {
        return this.inscription.student.account_type.name;
      } else {
        return "";
      }
    },
    getWeight() {
      if (this.inscription.student.weight) {
        return String(this.inscription.student.weight);
      } else {
        return "";
      }
    },
    getDegreeKinshipName(degreeKinship) {
      if (degreeKinship) {
        return degreeKinship.name;
      } else {
        return "";
      }
    },
    getNumberSons() {
      let nr_sons = 0;
      this.inscription.student.relatives_attributes.forEach((std) => {
        if (std.degree_kinship.id == 5 || std.degree_kinship.id == 6) {
          nr_sons++;
        }
      });
      return String(nr_sons);
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style>
.text-container {
  display: flex;
  gap: 16px;
}
</style>
