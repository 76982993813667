<template>
  <div class="grid auto-grid-auto p-10 gap-5">
    <div>
      <BaseText
        :text="course.name"
        typeText="custom"
        weight="semibold"
        mode="capitalize"
        :italic="false"
        size="3xl"
        color="ashes-hover"
      />
    </div>
    <div>
      <router-link to="/cursos">
        <BaseText
          text="<- Voltar"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="capitalize"
          size="sm"
          color="carmesim"
        />
      </router-link>
    </div>
    <div class="grid grid-cols-2">
      <div class="grid grid-flow-col auto-cols-max gap-2">
        <div class="place-self-center">
          <div :class="iconClass">
            <AppMaterialIcon
              iconName="book"
              iconType="outlined"
              class="place-self-center text-white"
              iconSize="36"
            />
          </div>
        </div>
        <div class="gridf grid-rols-3">
          <div>
            <BaseText
              :text="course.name"
              typeText="custom"
              weight="extrabold"
              :italic="false"
              size="2xl"
              color="ashes-dark"
            />
          </div>
          <div class="grid grid-flow-col auto-cols-max gap-2 text-burnedYellow">
            <div>
              <BaseText text="Fase 02 | " typeText="custom" weight="medium" :italic="false" size="sm" />
            </div>
            <div>
              <BaseText
                :text="course.discipline.name"
                typeText="custom"
                weight="bold"
                :italic="false"
                size="sm"
              />
            </div>
          </div>
          <div class="grid grid-flow-col auto-cols-max gap-2">
            <div>
              <BaseText
                text="Turmas Vinculadas |"
                typeText="custom"
                weight="medium"
                :italic="false"
                size="sm"
              />
            </div>
            <div class="text-burnedYellow">
              <BaseText text="05" typeText="custom" weight="bold" :italic="false" size="sm" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-flow-row auto-rows-max">
      <div>
        <BaseText
          text="Descrição"
          typeText="custom"
          weight="semibold"
          mode="uppercase"
          :italic="false"
          size="sm"
          color="burnedYellow"
        />
      </div>
      <div class="border-t bg-ashes-hover"></div>
      <div>
        <BaseText
          v-if="course.description"
          :text="course.description"
          typeText="custom"
          weight="semibold"
          mode="uppercase"
          :italic="false"
          size="md"
          color="ashes-dark"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../components/BaseText.vue";
import AppMaterialIcon from "../components/AppMaterialIcon.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppMaterialIcon,
  },
  data: () => ({
    permitUp: false,
    permitDes: false,
    permit: false,
  }),
  created() {
    let id = this.$route.params.id;
    this.$store.dispatch("Course/loadCourse", id);
  },
  ...mapActions({ canAcces: "AccessControl/canAcces" }),
  benefitAcces() {
    this.canAcces("course_index").then((resp) => {
      this.permit = resp;
    });
  },
  computed: {
    ...mapState({
      course: (state) => state.Course.course,
    }),
    iconClass() {
      let css = [];
      let bgColor = "bg-burnedYellow-classic";

      css.push(bgColor);
      css.push("rounded-full");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");

      return css;
    },
  },
  methods: {},
};
</script>
