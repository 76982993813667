<template>
  <div v-if="open && season">
    <div id="tabs-button" class="mt-3 ml-2">
      <FilterComponent>
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Área"
            show-label
            clear-on-select
            select-placeholder="Área"
            :multiple-option="false"
            :value="area_filtered"
            :options="user_areas"
            :dispatch="setFilterArea"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Equipamento"
            :multiple-option="false"
            :value="equipment_filtered"
            :options="equipments_planned_awareness_activities"
            :label-text="getLabelEquipmentFilter()"
            :dispatch="setFilterEquipment"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="articulator"
            input-type="text"
            :value="articulator_filtered"
            show-label
            label-text="Articulador(a) Responsável"
            dispatch="PlannedAwarenessActivities/setFilterArticulator"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="filter_by_initial_date"
            input-type="date"
            :value="params.initial_date"
            show-label
            label-text="Data de Matrícula [Início]"
            dispatch="PlannedAwarenessActivities/setFilterInitialDate"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="filter_by_final_date"
            input-type="date"
            :value="params.final_date"
            show-label
            label-text="Data de Matrícula [Final]"
            dispatch="PlannedAwarenessActivities/setFilterFinalDate"
          />
        </div>
      </FilterComponent>
      <div class="mt-3 mb-2">
        <a
          href="javascript:void(0)"
          @click="exportToExcel()"
          v-if="planned_awareness_activities.length > 0 && permitExportSpreadsheet"
        >
          <img
            class="inline-block"
            src="../../../assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
      </div>
      <BaseModal
        v-if="
          planned_awareness_activities_spreadsheet.length > 0 &&
          modalToggleExportPlannedAwarenessActivitiesSpreadsheet
        "
      >
        <template v-slot:header>
          <div class="grid grid-cols-3">
            <div class="justify-self-start col-span-2">
              <Label
                text="Exportar Planilha de Planejamentos"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon
                dispatch="PlannedAwarenessActivities/closeExportPlannedAwarenessActivitiesSpreadsheetModal"
                forClose
              />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="PlannedAwarenessActivities/closeExportPlannedAwarenessActivitiesSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data_planned_awareness_activity"
                  :before-finish="closeExportPlannedAwarenessActivitiesSpreadsheetModal"
                  worksheet="Planejamentos"
                  name="planejamentos.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetPlannedAwarenessActivitiesResponse">
        <PreLoading />
      </div>
    </div>
    <div class="grid grid-cols-2 justify-between p-4">
      <div></div>
      <div class="justify-self-end mb-4" v-if="permitCreate">
        <AppButtonColorful
          text="Planejamento"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="PlannedAwarenessActivities/openModalAddPlannedAwarenessActivity"
        />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-planned-awareness-activities"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="PlannedAwarenessActivities/loadPlannedAwarenessActivities"
          payload="loadData"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="planned_awareness_activities.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-incident_reports"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-planned-awareness-activities">
      <div class="mx-12">
        <PlannedAwarenessActivitiesCards :data="planned_awareness_activities" />
      </div>
      <div
        id="planned-awareness-activity-pagination"
        class="mx-12 my-6 pb-3"
        v-if="planned_awareness_activities.length > 0"
      >
        <BasePagination
          :total="plannedAwarenessActivitiesTotal"
          :total-per-page="plannedAwarenessActivitiesTotalPerPage"
          dispatch="PlannedAwarenessActivities/setPlannedAwarenessActivitiesOffset"
        />
      </div>
    </div>
    <ModalAddPlannedAwarenessActivity :open="modalToggleAddPlannedAwarenessActivity" />
    <ModalViewPlannedAwarenessActivityData :open="modalToggleViewPlannedAwarenessActivity" />
    <DeletePlannedAwarenessActivityConfirmation :open="modalToggleDeletePlannedAwarenessActivity" />
  </div>
</template>

<script>
import ModalAddPlannedAwarenessActivity from "./ModalAddPlannedAwarenessActivity.vue";
import DeletePlannedAwarenessActivityConfirmation from "../../alerts/DeletePlannedAwarenessActivityConfirmation.vue";
import ModalViewPlannedAwarenessActivityData from "../../datas/ModalViewPlannedAwarenessActivityData.vue";
import PlannedAwarenessActivitiesCards from "../../list/PlannedAwarenessActivitiesCards.vue";
import BasePagination from "../../../components/BasePagination.vue";
import AppButtonColorful from "../../../components/AppButtonColorful.vue";
import AppButtonIcon from "../../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../../components/AppButtonEmpty.vue";
import BaseInput from "../../../components/forms/BaseInput.vue";
import Multiselect from "../../../components/forms/Multiselect.vue";
import PreLoading from "../../../components/PreLoading.vue";
import BaseModal from "../../../components/BaseModal.vue";
import BaseText from "../../../components/BaseText.vue";
import Label from "../../../components/Label.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    Label,
    BasePagination,
    AppButtonIcon,
    AppButtonEmpty,
    BaseText,
    DeletePlannedAwarenessActivityConfirmation,
    ModalViewPlannedAwarenessActivityData,
    AppButtonColorful,
    PlannedAwarenessActivitiesCards,
    ModalAddPlannedAwarenessActivity,
    Multiselect,
    BaseInput,
    PreLoading,
    BaseModal,
  },
  data() {
    return {
      currentPage: 1,
      permitCreate: false,
      permitExportSpreadsheet: false,
      json_fields: {
        "Data do Registro": "created_at",
        Área: "area",
        Equipamento: "equipment",
        "Articulador(a) Responsável": "articulator",
        "Data da Atividade": "activity_date",
        Objetivo: "purpose",
        Metodologia: "methodology",
        "Recursos Utilizados": "used_resources",
        "Avaliação da Atividade": "activity_evaluation",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterArea: "PlannedAwarenessActivities/setFilterArea",
      setFilterArticulator: "PlannedAwarenessActivities/setFilterArticulator",
      setFilterEquipment: "PlannedAwarenessActivities/setFilterEquipment",
      closeExportPlannedAwarenessActivitiesSpreadsheetModal:
        "PlannedAwarenessActivities/closeExportPlannedAwarenessActivitiesSpreadsheetModal",
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    getLabelEquipmentFilter() {
      return this.area_filtered ? "" : "Selecione a área para listar os equipamentos";
    },
    async clearFields() {
      await this.$store.dispatch("PlannedAwarenessActivities/clearQuery");
    },
    async exportToExcel() {
      await this.$store.dispatch(
        "PlannedAwarenessActivities/loadPlannedAwarenessActivities",
        "loadDataSpreadsheet"
      );
    },
  },
  async created() {
    this.$store.dispatch("PlannedAwarenessActivities/loadArticulators");
    this.canAcces("planned_awareness_activity_index_spreadsheet").then((response) => {
      this.permitExportSpreadsheet = response;
    });
    this.canAcces("planned_awareness_activity_create").then((response) => {
      this.permitCreate = response;
    });
  },

  computed: {
    ...mapState({
      city: (state) => state.City.city,
      season: (state) => state.SchoolReinsertions.season,
      planned_awareness_activities: (state) => state.PlannedAwarenessActivities.planned_awareness_activities,
      articulators: (state) => state.PlannedAwarenessActivities.articulators,
      articulator_filtered: (state) => state.PlannedAwarenessActivities.params.articulator,
      params: (state) => state.PlannedAwarenessActivities.params,
      final_date_enrollment: (state) =>
        state.SchoolReinsertions.params.reinserted_main_page.final_date_enrollment,
      user_areas: (state) => state.PlannedAwarenessActivities.user_areas,
      area_filtered: (state) => state.PlannedAwarenessActivities.params.area,
      equipments_planned_awareness_activities: (state) =>
        state.PlannedAwarenessActivities.equipments_planned_awareness_activities,
      equipment_filtered: (state) => state.PlannedAwarenessActivities.params.equipment,
      plannedAwarenessActivitiesOffset: (state) =>
        state.PlannedAwarenessActivities.plannedAwarenessActivitiesOffset,
      plannedAwarenessActivitiesTotalPerPage: (state) =>
        state.PlannedAwarenessActivities.plannedAwarenessActivitiesTotalPerPage,
      plannedAwarenessActivitiesTotal: (state) =>
        state.PlannedAwarenessActivities.plannedAwarenessActivitiesTotal,
      modalToggleAddPlannedAwarenessActivity: (state) =>
        state.PlannedAwarenessActivities.modalToggleAddPlannedAwarenessActivity,
      modalToggleDeletePlannedAwarenessActivity: (state) =>
        state.PlannedAwarenessActivities.modalToggleDeletePlannedAwarenessActivity,
      modalToggleViewPlannedAwarenessActivity: (state) =>
        state.PlannedAwarenessActivities.modalToggleViewPlannedAwarenessActivity,
      planned_awareness_activities_spreadsheet: (state) =>
        state.PlannedAwarenessActivities.planned_awareness_activities_spreadsheet,
      hasSpreadsheetPlannedAwarenessActivitiesResponse: (state) =>
        state.PlannedAwarenessActivities.hasSpreadsheetPlannedAwarenessActivitiesResponse,
      json_data_planned_awareness_activity: (state) =>
        state.PlannedAwarenessActivities.json_data_planned_awareness_activity,
      modalToggleExportPlannedAwarenessActivitiesSpreadsheet: (state) =>
        state.PlannedAwarenessActivities.modalToggleExportPlannedAwarenessActivitiesSpreadsheet,
    }),
  },
  watch: {
    area_filtered: function () {
      this.$store.dispatch("PlannedAwarenessActivities/loadEquipments");
    },
    offset: function () {
      this.$store.dispatch("PlannedAwarenessActivities/loadPlannedAwarenessActivities", "loadData");
      this.backToTop();
    },
  },
};
</script>
