import { getTeamsForBenefits } from "../../service/TeamApi";
import { createBenefitPayment } from "../../service/BenefitApi";

import Vue from "vue";

const state = {
  openModal: false,
  teams: [],
  filteredTeams: [],
  teamsAllocation: [],
  filters: {
    area: {},
    neighborhood: {},
    equipment: {},
  },
};

const mutations = {
  toggleModalMutation: (state) => {
    state.openModal = !state.openModal;
  },
  setFilterAreaMutation: (state, payload) => {
    state.filters.area = payload;
  },
  setFilterNeighborhoodMutation: (state, payload) => {
    state.filters.neighborhood = payload;
  },
  setFilterEquipmentMutation: (state, payload) => {
    state.filters.equipment = payload;
  },
  setTeamsMutation: (state, teams) => {
    state.teams = teams;
    state.filteredTeams = teams;
  },
  setFilteredTeamsMutation: (state, teams) => {
    state.filteredTeams = teams;
  },
  addTeamsAllocationMutation: (state, team) => {
    state.teamsAllocation.push(team);
  },
  setTeamsAllocationMutation: (state, teams) => {
    state.teamsAllocation = teams;
  },
};

const actions = {
  openModal: ({ commit }) => {
    commit("toggleModalMutation");
  },
  closeModal: ({ commit }) => {
    commit("toggleModalMutation");
    commit("setTeamsAllocationMutation", []);
  },
  filterNeighborhood: ({ commit, dispatch }, neighborhood) => {
    commit("setFilterNeighborhoodMutation", neighborhood);
    dispatch("filterList");
  },
  filterArea: ({ commit, dispatch }, area) => {
    commit("setFilterAreaMutation", area);
    dispatch("filterList");
  },
  filterEquipment: ({ commit, dispatch }, equipment) => {
    commit("setFilterEquipmentMutation", equipment);
    dispatch("filterList");
  },
  filterList: ({ commit, state }) => {
    const neighborhood = state.filters.neighborhood;
    const filteredByNeighborhood = neighborhood
      ? state.teams.filter((obj) => {
          return obj.neighborhood_id == neighborhood.id;
        })
      : state.teams;

    const area = state.filters.area;
    const filteredByArea = area
      ? filteredByNeighborhood.filter((obj) => {
          return obj.areas_attributes.some((el) => el.id == area.id);
        })
      : filteredByNeighborhood;

    commit("setFilteredTeamsMutation", filteredByArea);
  },
  addTeamsAllocation: ({ commit }, payload) => {
    commit("addTeamsAllocationMutation", payload);
  },
  loadTeams: ({ commit }, payload) => {
    getTeamsForBenefits(payload)
      .then((response) => {
        commit("setTeamsMutation", response.data.teams);
      })
      .catch((e) => {
        Vue.$toast.error("Erro ao carregar a lista. Favor, informar a área responsável!");
        commit("setErrorMessagesMutation", e.response.data.errors);
        console.log("error", e.response);
      });
  },
  addStudentForPayment: ({ commit, dispatch, rootState }) => {
    const id = rootState.Benefit.benefit.id;
    let benefit_payments = {
      benefit_id: id,
      teams: getters.getTeamsForPayment(),
    };
    createBenefitPayment(benefit_payments)
      .then((response) => {
        dispatch("closeModal");
        commit("setTeamsMutation", response.data.teams);
        dispatch("Benefit/loadStudentsForPayment", id, { root: true });
        Vue.$toast.success("Estudantes alocados com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Erro ao registrar. Favor, informar a área responsável!");
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
};

const getters = {
  getTeamsForPayment: () => {
    let data = state.teamsAllocation.map((obj) => {
      return obj.id;
    });
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
