<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="payment_sheet.status ? payment_sheet.status.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="payment_sheet.process_number ? payment_sheet.process_number : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="payment_sheet.reference ? payment_sheet.reference : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="payment_sheet.total_beneficiaries ? payment_sheet.total_beneficiaries : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="payment_sheet.total_payment_value ? payment_sheet.total_payment_value : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="permitUpdate"
          iconName="edit"
          :payload="payment_sheet"
          dispatch="PaymentSheets/openEditModalAddPaymentSheet"
          title="Atualizar Folha de Pagamento"
        />
        <AppButtonIcon
          v-if="permitDelete"
          iconName="delete"
          :payload="payment_sheet"
          dispatch="PaymentSheets/toggleModalDeletePaymentSheet"
          title="Deletar Folha de Pagamento"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permit: false,
      permitUpdate: false,
      permitDelete: false,
      permitShow: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    payment_sheet: {
      type: Object,
    },
  },
  created() {
    this.paymentSheetAccess();
    this.paymentSheetDelete();
    this.paymentSheetUpdate();
    this.paymentSheetShow();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    paymentSheetAccess() {
      this.canAcces("payment_sheet_index").then((response) => {
        this.permit = response;
      });
    },
    paymentSheetDelete() {
      this.canAcces("payment_sheet_destroy").then((response) => {
        this.permitDelete = response;
      });
    },
    paymentSheetShow() {
      this.canAcces("payment_sheet_show").then((response) => {
        this.permitShow = response;
      });
    },
    paymentSheetUpdate() {
      this.canAcces("payment_sheet_update").then((response) => {
        this.permitUpdate = response;
      });
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 15% 13% 13% 16% 35% 4%;
}
</style>
