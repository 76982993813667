<template>
  <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
    <slot></slot>
    <transition name="fade">
      <div v-if="visible" class="tooltip-content" :style="tooltipStyle">
        {{ text }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: "#333",
    },
    textColor: {
      type: String,
      default: "#fff",
    },
    borderRadius: {
      type: String,
      default: "8px",
    },
    padding: {
      type: String,
      default: "8px",
    },
    position: {
      type: String,
      default: "top",
    },
    maxWidth: {
      type: String,
      default: "600px",
    },
    minWidth: {
      type: String,
      default: "300px",
    },
    fadeDuration: {
      type: Number,
      default: 300,
    },
    fontSize: {
      type: String,
      default: "14px",
    },
    maxHeight: {
      type: String,
      default: "200px",
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    tooltipStyle() {
      return {
        backgroundColor: this.backgroundColor,
        color: this.textColor,
        borderRadius: this.borderRadius,
        padding: this.padding,
        maxWidth: this.maxWidth,
        minWidth: this.minWidth,
        whiteSpace: "pre-wrap",
        overflowY: "auto",
        fontSize: this.fontSize,
        maxHeight: this.maxHeight,
      };
    },
  },
  methods: {
    showTooltip() {
      this.visible = true;
    },
    hideTooltip() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  position: absolute;
  text-align: left;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
  overflow: auto;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 10px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
