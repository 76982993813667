import { getAxios, baseURL } from "./Api";

export async function getPhasesBySeasonId(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get(`edicoes/${id}/fases`);
}

export async function getPhaseByIdAndSeasonId(id, seasonId) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`edicoes/${seasonId}/fases/${id}`);
}

export async function getPhaseById(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`fases/${id}`);
}

export async function getPhase() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`fases/`);
}
