<template>
  <div class="grid gap-6 px-12 bg-gray-50 rounded-lg shadow-lg">
    <div class="grid grid-cols-1 pt-4">
      <Label
        text="Redefinir Senha"
        :icon="null"
        :icon-type="null"
        type="text-2xl"
        weight="bold"
        class="text-gray-800"
      />
    </div>

    <div>
      <Label
        text="Informações Gerais do Usuário(a)"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        class="text-burnedYellow uppercase"
      />
      <div class="border-b-2 border-gray-300 w-full mt-1"></div>
    </div>

    <div class="grid grid-cols-2 gap-6">
      <AppTextField show-label label-text="Nome" :text="user.name" type-text="topic-details" />
      <AppTextField
        show-label
        label-text="Perfil de Acesso"
        :text="user.roles_attributes.name"
        type-text="topic-details"
      />
      <AppTextField show-label label-text="CPF" :text="user.cpf" type-text="topic-details" />
      <AppTextField show-label label-text="Telefone" :text="user.phone_number" type-text="topic-details" />
      <AppTextField show-label label-text="E-mail" :text="user.email" type-text="topic-details" />
      <AppTextField
        show-label
        label-text="Usuário(a) Ativo(a)?"
        :text="isUserActive"
        type-text="topic-details"
      />
    </div>

    <div>
      <Label
        text="Informações de Senha"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        class="text-burnedYellow uppercase"
      />
      <div class="border-b-2 border-gray-300 w-full mt-1"></div>
    </div>

    <div class="grid grid-cols-2 gap-6">
      <BaseInput
        id="password_user"
        input-name="password_user"
        input-type="password"
        show-label
        label-text="Senha"
        :value="password"
        required
        dispatch="User/setPassword"
      />
      <BaseInput
        id="password_confirmation_user"
        input-name="password_confirmation_user"
        input-type="password"
        show-label
        label-text="Confirme a Senha"
        required
        :value="password_confirmation"
        dispatch="User/setPasswordConfirmation"
      />
    </div>

    <div class="validation-feedback space-y-3 p-4 rounded-md bg-white border border-gray-200 shadow-sm">
      <div class="flex items-center space-x-2 transition-transform transform hover:scale-105">
        <span :class="{ 'text-green-500': validations.minLength, 'text-red-500': !validations.minLength }">
          <span class="material-icons">
            {{ validations.minLength ? "check_circle" : "cancel" }}
          </span>
        </span>
        <p
          :class="{
            'text-green-600 font-semibold': validations.minLength,
            'text-red-600': !validations.minLength,
          }"
        >
          Mínimo de 8 caracteres
        </p>
      </div>

      <div class="flex items-center space-x-2 transition-transform transform hover:scale-105">
        <span :class="{ 'text-green-500': validations.uppercase, 'text-red-500': !validations.uppercase }">
          <span class="material-icons">
            {{ validations.uppercase ? "check_circle" : "cancel" }}
          </span>
        </span>
        <p
          :class="{
            'text-green-600 font-semibold': validations.uppercase,
            'text-red-600': !validations.uppercase,
          }"
        >
          Pelo menos uma letra maiúscula
        </p>
      </div>

      <div class="flex items-center space-x-2 transition-transform transform hover:scale-105">
        <span :class="{ 'text-green-500': validations.number, 'text-red-500': !validations.number }">
          <span class="material-icons">
            {{ validations.number ? "check_circle" : "cancel" }}
          </span>
        </span>
        <p
          :class="{ 'text-green-600 font-semibold': validations.number, 'text-red-600': !validations.number }"
        >
          Pelo menos um número
        </p>
      </div>

      <div class="flex items-center space-x-2 transition-transform transform hover:scale-105">
        <span :class="{ 'text-green-500': validations.match, 'text-red-500': !validations.match }">
          <span class="material-icons">
            {{ validations.match ? "check_circle" : "cancel" }}
          </span>
        </span>
        <p :class="{ 'text-green-600 font-semibold': validations.match, 'text-red-600': !validations.match }">
          Senhas coincidem
        </p>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 mt-6 pb-4">
      <AppButtonEmpty
        text="Descartar"
        typeButton="danger"
        dispatch="User/closeModalResetPassword"
        class="justify-self-start"
      />
      <AppButtonEmpty
        text="Salvar"
        typeButton="success"
        dispatch="User/updatePassword"
        class="justify-self-end"
        :disabled="!isFormValid"
      />
    </div>
  </div>
</template>

<script>
import BaseInput from "../../components/forms/BaseInput.vue";
import Label from "../../components/Label.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import AppTextField from "../../components/AppTextField.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseInput,
    Label,
    AppButtonEmpty,
    AppTextField,
  },
  data() {
    return {
      validations: {
        minLength: false,
        uppercase: false,
        number: false,
        match: false,
      },
    };
  },
  async created() {
    const user = await JSON.parse(localStorage.getItem("userData"));
    await this.$store.dispatch("User/loadUser", user);
    await this.$store.dispatch("AccessControl/getProfiles");
  },
  computed: {
    ...mapGetters({
      getErrorMessage: "User/getErrorMessage",
    }),
    ...mapState({
      user: (state) => state.User.user,
      password: (state) => state.User.user.password,
      password_confirmation: (state) => state.User.user.password_confirmation,
      arrayProf: (state) => state.AccessControl.profArray,
      profiles: (state) => state.AccessControl.profiles,
      isUserActive() {
        return this.user.active ? "Sim" : "Não";
      },
    }),
    isFormValid() {
      return Object.values(this.validations).every(Boolean);
    },
  },
  methods: {
    ...mapActions({
      setRole: "User/setRole",
    }),
    validatePassword() {
      if (this.password) {
        this.validations.minLength = this.password.length >= 8;
        this.validations.uppercase = /[A-Z]/.test(this.password);
        this.validations.number = /\d/.test(this.password);
      } else {
        this.validations.minLength = false;
        this.validations.uppercase = false;
        this.validations.number = false;
      }
      this.validatePasswordConfirmation();
    },
    validatePasswordConfirmation() {
      if (!this.password && !this.password_confirmation) {
        this.validations.match = false;
        return;
      }
      this.validations.match = this.password === this.password_confirmation;
    },
  },
  watch: {
    password: function () {
      this.validatePassword();
      this.validatePasswordConfirmation();
    },
    password_confirmation: function () {
      this.validatePassword();
      this.validatePasswordConfirmation();
    },
  },
};
</script>

<style scoped>
.validation-feedback p {
  margin: 0;
  font-size: 0.875rem;
}
</style>
