<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="partner.id"
            text="Editar Parceiro"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Criar Parceiro"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Partners/closeModalAddPartner" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2">
          <BaseInput
            id="Nome"
            input-name="name"
            input-type="text"
            show-label
            required
            label-text="Nome"
            :value="partner.name"
            dispatch="Partners/setName"
            :error-message="getErrorMessage('nome')"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Município"
            :dispatch="setCity"
            :error-message="getErrorMessage('município')"
            required
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Partners/closeModalAddPartner" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="partner.id"
            text="Atualizar"
            typeButton="success"
            dispatch="Partners/update"
          />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="Partners/save" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../../components/BaseModal.vue";
import BaseInput from "../../../components/forms/BaseInput.vue";
import Label from "../../../components/Label.vue";
import AppButtonIcon from "../../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../../components/AppButtonEmpty.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    Multiselect,
    BaseModal,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      setState: "Partners/setState",
      setCity: "Partners/setCity",
    }),
    getLabelCity() {
      return this.state ? "Município" : "Selecione o estado para listar os municípios";
    },
  },
  computed: {
    ...mapState({
      partner: (state) => state.Partners.partner,
      state: (state) => state.Partners.state,
      city: (state) => state.Partners.city,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
    }),
    ...mapGetters({
      getErrorMessage: "Partners/getErrorMessage",
    }),
  },
};
</script>
