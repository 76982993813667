<template>
  <BaseCard bg_color="bg-burnedYellow-soft">
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="formattedDate(forwarding_postponement_history.created_at)"
          mode="normal-case"
          typeText="custom"
          weight="semibold"
          size="sm"
          color="quantum"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="formattedDate(forwarding_postponement_history.forwarding_expiration_date_original)"
          mode="normal-case"
          typeText="custom"
          weight="normal"
          color="ashes-dark"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="forwarding_postponement_history.postponement_user.name"
          mode="normal-case"
          typeText="custom"
          weight="normal"
          color="ashes-dark"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="forwarding_postponement_history.forwarding_postponement_comments"
          mode="normal-case"
          typeText="custom"
          weight="normal"
          color="ashes-dark"
          size="sm"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    BaseCard,
    BaseText,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    forwarding_postponement_history: {
      type: Object,
    },
  },
  created() {
    this.forwardingAccess();
    this.forwardingShow();
    this.forwardingChange();
    this.forwardingDelete();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    formattedDate: (date) => {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 19% 15% 17% 50%;
}
</style>
