<template>
  <BaseCard :bg_color="getColors('bg_color')">
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="forwarding.inscription.student.name"
          mode="normal-case"
          :color="getColors('color_text')"
          typeText="custom"
          weight="bold"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="forwarding.forwarding_user.name"
          mode="normal-case"
          :color="getColors('color_text')"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="forwarding.inscription.season.name"
          mode="normal-case"
          :color="getColors('color_text')"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="forwarding?.city?.name"
          mode="normal-case"
          :color="getColors('color_text')"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="formattedBirthdate(forwarding.forwarding_date)"
          mode="normal-case"
          :color="getColors('color_text')"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="formattedBirthdate(forwarding.forwarding_expiration_date)"
          mode="normal-case"
          :color="getColors('color_text')"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="forwarding.forwarding_policy.name"
          mode="normal-case"
          :color="getColors('color_text')"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="forwarding.forwarding_status.name"
          mode="normal-case"
          :color="getColors('color_text')"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          iconName="visibility"
          dispatch="Forwardings/openViewModal"
          :payload="forwarding"
          :iconColor="getColors('icon_color')"
          :hover="getColors('hover_color')"
          title="Visualizar informações do encaminhamento"
          v-if="permitShow"
        />
        <AppButtonIcon
          iconName="restart_alt"
          :payload="forwarding"
          :iconColor="getColors('icon_color')"
          :hover="getColors('hover_color')"
          dispatch="Forwardings/openReturnModal"
          title="Realizar retorno do encaminhamento"
          v-if="permitUp && forwarding.forwarding_status.name === 'Encaminhado'"
        />
        <AppButtonIcon
          iconName="update"
          :payload="forwarding"
          :iconColor="getColors('icon_color')"
          :hover="getColors('hover_color')"
          dispatch="Forwardings/openModalForwardingPostponeExpirationDate"
          title="Realizar Postergação de Data de Expiração"
          v-if="permitUp && forwarding.forwarding_status.name === 'Encaminhado'"
        />
        <AppButtonIcon
          iconName="delete"
          :payload="forwarding"
          :iconColor="getColors('icon_color')"
          :hover="getColors('hover_color')"
          dispatch="Forwardings/toggleModalDeleteForwarding"
          title="Deletar Encaminhamento"
          v-if="permitDes"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitResetPassword: false,
      permitShow: false,
      permitShowHistoric: false,
      permit: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    forwarding: {
      type: Object,
    },
  },
  created() {
    this.forwardingAccess();
    this.forwardingAccessHistoric();
    this.forwardingShow();
    this.forwardingChange();
    this.forwardingDelete();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    forwardingAccess() {
      this.canAcces("forwarding_index").then((resp) => {
        this.permit = resp;
      });
    },
    forwardingAccessHistoric() {
      this.canAcces("forwarding_postponement_history_by_forwarding").then((resp) => {
        this.permitShowHistoric = resp;
      });
    },
    forwardingShow() {
      this.canAcces("forwarding_show").then((resp) => {
        this.permitShow = resp;
      });
    },
    forwardingDelete() {
      this.canAcces("forwarding_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    forwardingChange() {
      this.canAcces("forwarding_update").then((resp) => {
        this.permitUp = resp;
      });
    },
    formattedBirthdate: (date) => {
      return date.split("-").reverse().join("/");
    },
    getColors(type) {
      let colortext = "";
      let iconColor = "";
      let bgColor = "";
      let hoverColor = "text-burnedYellow";
      let today = moment().format("YYYY-MM-DD");

      if (
        this.forwarding.forwarding_status.name === "Encaminhado" &&
        this.forwarding.forwarding_expiration_date > today
      ) {
        bgColor = "bg-ashes-neutral";
        colortext = "ashes-dark";
        iconColor = "ashes-dark";
      } else if (
        this.forwarding.forwarding_status.name === "Encaminhado" &&
        this.forwarding.forwarding_expiration_date < today
      ) {
        bgColor = "bg-burnedYellow-yellow";
        colortext = "ashes-dark";
        iconColor = "ashes-dark";
      } else if (this.forwarding.forwarding_status.name === "Efetivado") {
        bgColor = "bg-herbal-lemon";
        colortext = "white";
        iconColor = "white";
      } else if (this.forwarding.forwarding_status.name === "Não Efetivado") {
        bgColor = "bg-carmesim";
        colortext = "white";
        iconColor = "white";
      }

      if (type === "color_text") return colortext;
      if (type === "icon_color") return iconColor;
      if (type === "hover_color") return hoverColor;
      if (type === "bg_color") return bgColor;
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 21% 14% 9% 9% 10% 10% 11% 9% 7%;
}
</style>
