<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="matrix_situation.id"
            text="Editar Situação"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Cadastrar Situação"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="MatrixSituation/closeModalMatrixSituations" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2">
          <Multiselect
            id="select-problem"
            select-placeholder="Problema"
            label-text="Problema"
            close-on-select
            show-label
            required
            :value="matrix_problem"
            :options="matrix_problems"
            :dispatch="setMatrixProblem"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            id="situação"
            input-name="situation"
            input-type="text"
            show-label
            required
            label-text="situação"
            :value="matrix_situation.situation"
            dispatch="MatrixSituation/setSituation"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            id="select-situation-risk"
            select-placeholder="Nível da Situação"
            label-text="Nível da Situação"
            close-on-select
            show-label
            required
            :value="situation_risk"
            :options="situations_risks"
            :dispatch="setSituationRisk"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            id="impact"
            input-name="Impacto"
            input-type="text"
            show-label
            required
            label-text="Impacto"
            :value="matrix_situation.impact"
            dispatch="MatrixSituation/setImpact"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            id="select-impact-level"
            select-placeholder="Nível do Impacto"
            label-text="Nível do Impacto"
            close-on-select
            show-label
            required
            :value="impact_level"
            :options="impact_levels"
            :dispatch="setImpactLevel"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="MatrixSituation/closeModalMatrixSituations"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="matrix_situation.id"
            text="Atualizar"
            typeButton="success"
            dispatch="MatrixSituation/update"
          />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="MatrixSituation/save" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseModal,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
    Multiselect,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      situations_risks: [
        {
          id: 0,
          name: "Gravíssima",
        },
        {
          id: 1,
          name: "Grave",
        },
        {
          id: 2,
          name: "Moderada",
        },
      ],
      impact_levels: [
        {
          id: 0,
          name: "Alto",
        },
        {
          id: 1,
          name: "Médio",
        },
        {
          id: 2,
          name: "Baixo",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      matrix_situation: (state) => state.MatrixSituation.matrix_situation,
      matrix_problems: (state) => state.MatrixSituation.matrix_problems,
      matrix_problem: (state) => state.MatrixSituation.matrix_problem,
      situation_risk: (state) => state.MatrixSituation.situation_risk,
      impact_level: (state) => state.MatrixSituation.impact_level,
    }),
  },
  methods: {
    ...mapActions({
      setMatrixProblem: "MatrixSituation/setMatrixProblem",
      setSituationRisk: "MatrixSituation/setSituationRisk",
      setImpactLevel: "MatrixSituation/setImpactLevel",
    }),
  },
};
</script>
