<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="forwarding_productive_insertion.id"
            text="Editar Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Criar Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon
            dispatch="ForwardingProductiveInsertions/closeModalAddForwardingProductiveInsertion"
            forClose
          />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Seleção"
            show-label
            clear-on-select
            required
            select-placeholder="Digite o nome da seleção para a busca"
            :multiple-option="false"
            :value="opportunity"
            :options="opportunities"
            :dispatch="setOpportunity"
            load-dispatch="ForwardingProductiveInsertions/loadOpportunities"
            :error-message="getErrorMessage('oportunidade')"
          />
        </div>
        <div>
          <BaseInput
            id="forwarding_date"
            input-name="forwarding_date"
            input-type="date"
            show-label
            required
            label-text="Data do Encaminhamento"
            :value="forwarding_productive_insertion.forwarding_date"
            dispatch="ForwardingProductiveInsertions/setForwardingDate"
            :error-message="getErrorMessage('data do encaminhamento')"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Município"
            :dispatch="setCity"
            required
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Nome do Participante"
            show-label
            clear-on-select
            required
            select-placeholder="Digite o nome do participante para busca"
            :multiple-option="false"
            :value="inscription"
            :options="inscriptions"
            :dispatch="setInscription"
            load-dispatch="ForwardingProductiveInsertions/loadInscriptions"
            :error-message="getErrorMessage('inscrição')"
          />
        </div>
        <div v-if="inscription">
          <AppTextField
            show-label
            label-text="Encaminhamentos registrados"
            :text="inscription.forwardings_quantity === 0 ? '0' : inscription.forwardings_quantity"
            type-text="topic-details"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Tipo da Devolução"
            show-label
            clear-on-select
            required
            select-placeholder="Escolha o Tipo da Devolução"
            :multiple-option="false"
            :value="feedback_type"
            :options="feedback_types"
            :dispatch="setFeedbackType"
            :error-message="getErrorMessage('tipo da devolução')"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="ForwardingProductiveInsertions/closeModalAddForwardingProductiveInsertion"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="forwarding_productive_insertion.id"
            text="Atualizar"
            typeButton="success"
            dispatch="ForwardingProductiveInsertions/update"
          />
          <AppButtonEmpty
            v-else
            text="Criar"
            typeButton="success"
            dispatch="ForwardingProductiveInsertions/save"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../../components/BaseModal.vue";
import BaseInput from "../../../components/forms/BaseInput.vue";
import Label from "../../../components/Label.vue";
import AppButtonIcon from "../../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../../components/AppButtonEmpty.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import Multiselect from "../../../components/forms/Multiselect.vue";
import AppTextField from "@/components/AppTextField.vue";

export default {
  components: {
    AppTextField,
    Multiselect,
    BaseModal,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("ForwardingProductiveInsertions/loadInscriptions");
  },
  computed: {
    ...mapState({
      forwarding_productive_insertion: (state) =>
        state.ForwardingProductiveInsertions.forwarding_productive_insertion,
      feedback_type: (state) => state.ForwardingProductiveInsertions.feedback_type,
      feedback_types: (state) => state.ForwardingProductiveInsertions.feedback_types,
      opportunity: (state) => state.ForwardingProductiveInsertions.opportunity,
      opportunities: (state) => state.ForwardingProductiveInsertions.opportunities,
      inscription: (state) => state.ForwardingProductiveInsertions.inscription,
      inscriptions: (state) => state.ForwardingProductiveInsertions.inscriptions,
      state: (state) => state.ForwardingProductiveInsertions.state,
      city: (state) => state.ForwardingProductiveInsertions.city,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
    }),
    ...mapGetters({
      getErrorMessage: "ForwardingProductiveInsertions/getErrorMessage",
    }),
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setOpportunity: "ForwardingProductiveInsertions/setOpportunity",
      setInscription: "ForwardingProductiveInsertions/setInscription",
      setCity: "ForwardingProductiveInsertions/setCity",
      setState: "ForwardingProductiveInsertions/setState",
      setFeedbackType: "ForwardingProductiveInsertions/setFeedbackType",
    }),
    getLabelCity() {
      return this.state ? "Município" : "Selecione o estado para listar os municípios";
    },
  },
};
</script>
