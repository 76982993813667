<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="company.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="company.city.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="company.modality ? company.modality.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="company.total_opportunities_offered === 0 ? '0' : company.total_opportunities_offered"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="company.total_vacancies_offered === 0 ? '0' : company.total_vacancies_offered"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="company.total_registered_referrals === 0 ? '0' : company.total_registered_referrals"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="
            company.total_registered_referrals_made === 0 ? '0' : company.total_registered_referrals_made
          "
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="permitUpdate"
          iconName="edit"
          :payload="company"
          dispatch="Companies/openEditModalAddCompany"
          title="Atualizar Empresa"
        />
        <AppButtonIcon
          v-if="permitShow"
          iconName="visibility"
          dispatch="Companies/openViewModal"
          :payload="company"
          title="Visualizar Empresa"
        />
        <AppButtonIcon
          v-if="permitDelete"
          iconName="delete"
          :payload="company"
          dispatch="Companies/toggleModalDeleteCompany"
          title="Deletar Empresa"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permit: false,
      permitUpdate: false,
      permitDelete: false,
      permitShow: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object,
    },
  },
  created() {
    this.companyAccess();
    this.companyDelete();
    this.companyUpdate();
    this.companyShow();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    companyAccess() {
      this.canAcces("company_index").then((response) => {
        this.permit = response;
      });
    },
    companyDelete() {
      this.canAcces("company_destroy").then((response) => {
        this.permitDelete = response;
      });
    },
    companyShow() {
      this.canAcces("company_show").then((response) => {
        this.permitShow = response;
      });
    },
    companyUpdate() {
      this.canAcces("company_update").then((response) => {
        this.permitUpdate = response;
      });
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 12% 12% 11% 12% 14% 18% 14% 5%;
}
</style>
