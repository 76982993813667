<template>
  <div>
    <div class="grid grid-cols-2 items-center">
      <div class="ml-20">
        <BaseText
          text="Nome"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div>
        <div class="grid grid-cols-2">
          <div class="justify-self-end">
            <BaseText
              text="Nº de Encontros"
              typeText="custom"
              weight="bold"
              :italic="false"
              mode="uppercase"
              size="xs"
              color="ashes-dark"
            />
          </div>
          <div class="justify-self-center">
            <BaseText
              text="Período"
              typeText="custom"
              weight="bold"
              :italic="false"
              mode="uppercase"
              size="xs"
              color="ashes-dark"
            />
          </div>
        </div>
      </div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li :key="item.id" :data-index="index" v-for="(item, index) in data">
        <div>
          <AppCardAllocationBenefitTeam
            :team="item"
            :benefit_type="item.benefit_type"
            dispatch="ModalAllocationTeamBenefit/addTeamsAllocation"
          />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardAllocationBenefitTeam from "../../components/AppCardAllocationBenefitTeam.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";

export default {
  components: {
    AppCardAllocationBenefitTeam,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },

  methods: {},
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 56% 18% 26% 0%;
}
</style>
