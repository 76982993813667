<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="forwarding.id"
            text="Editar Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Cadastrar Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Forwardings/closeModal" forClose />
        </div>
        <div class="justify-self-start col-span-2">
          <BaseText
            :text="getUserName()"
            typeText="custom"
            weight="semibold"
            color="herbal"
            size="sm"
            class="mr-1"
          />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2 gap-4" v-if="!forwarding.id && !getTypePage()">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Selecionar Aluno(a)"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="-mt-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione a Edição"
            label-text="Edição"
            :multiple-option="false"
            :value="season_filtered"
            :options="seasons"
            required
            :dispatch="setSeason"
            :readonly="true"
          />
        </div>
        <div class="-mt-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione a Área"
            label-text="Área"
            :multiple-option="true"
            :value="user_areas_filtered"
            :options="user_areas"
            required
            :dispatch="setUserAreasFiltered"
          />
        </div>
        <div class="-mt-2 col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione o aluno(a)"
            label-text="Aluno(a)"
            :multiple-option="false"
            :value="inscription"
            :options="inscriptions"
            required
            :dispatch="setInscription"
          />
        </div>
      </div>
      <div
        div
        class="grid grid-cols-2 gap-4 mt-4"
        v-if="!forwarding.id && inscription && !togglePsychosocialAccompanimentForwardings"
      >
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações do Aluno(a) Selecionado"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <AppTextField show-label label-text="Nome" :text="inscription.name" type-text="topic-details" />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Status do Aluno(a) no sistema"
            v-bind:text="verifyStatus()"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="CPF"
            v-bind:text="verifyInscription('cpf') | VMask('###.###.###-##')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Data de Nascimento"
            v-bind:text="verifyInscription('birthdate')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Idade"
            v-bind:text="verifyInscription('age')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Número Contato"
            v-bind:text="verifyInscription('cellphone_number') | VMask('(##) #####-####')"
            type-text="topic-details"
          />
        </div>
      </div>
      <!-- <div
        class="grid grid-cols-2 gap-4"
        v-if="!forwarding.id && inscription && psychosocial_accompaniments != null"
      >
        <div class="col-span-2 text-burnedYellow pb-2 mt-2">
          <Label
            text="Selecionar Acompanhamento Psicossocial [Opcional]"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div
          class="h-16 my-2 col-span-2"
          :key="item.id"
          :psychosocial_accompaniments-index="index"
          v-for="(item, index) in psychosocial_accompaniments"
        >
          <div>
            <div class="items-center pt-2">
              <div class="col-span-2">
                <div class="flex justify-start items-center">
                  <div class="mr-10">
                    <input
                      :id="item.id"
                      type="radio"
                      name="psychosocialAccompaniment"
                      :value="item.id"
                      @click="onCheck(item)"
                    />
                  </div>
                  <BaseText
                    :text="formattedDate(item.date_accompaniment)"
                    typeText="custom"
                    mode="underline"
                    weight="bold"
                    size="xl"
                    class="mr-1"
                  />
                  <BaseText text="|" typeText="custom" weight="normal" size="2xl" class="mr-1" />
                  <BaseText :text="item.meeting_format" typeText="custom" size="base" color="ashes-dark" />
                  <BaseText text="|" typeText="custom" weight="normal" size="2xl" class="mr-1" />
                  <BaseText :text="item.meeting_place" typeText="custom" size="base" color="ashes-dark" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="grid grid-cols-2 mt-4" v-if="forwarding.id && inscription">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações do Aluno(a)"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Nome Aluno(a)"
            :text="inscription.student.name"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Edição"
            :text="inscription.season.name"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Equipamento"
            :text="verifyInscription('equipment')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Status do Encaminhamento"
            :text="forwarding.forwarding_status.name"
            type-text="topic-details"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 mt-4" v-if="forwarding.id && psychosocial_accompaniment">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações do Acompanhamento Psicossocial"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Data do Encaminhamento"
            :text="formattedDate(psychosocial_accompaniment.date_accompaniment)"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Encontro aconteceu?"
            :text="verifyMeetingHappened()"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Formato do Encontro"
            :text="psychosocial_accompaniment.meeting_format"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Local do Encontro"
            :text="psychosocial_accompaniment.meeting_place"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Encaminhado?"
            :text="verifyIsForwarded()"
            type-text="topic-details"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações do Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione as opções"
            label-text="Políticas de Encaminhamento"
            :multiple-option="false"
            :value="forwarding_policy"
            :options="forwarding_policies"
            required
            :dispatch="setForwardingPolicy"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione as opções"
            label-text="Equipamentos"
            :multiple-option="false"
            :value="facility"
            :options="facilities"
            :dispatch="setForwardingFacility"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Município"
            :dispatch="setCity"
            required
          />
        </div>
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações de Data"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <BaseInput
            active
            input-name="expedition"
            input-type="date"
            :value="forwardingDate"
            show-label
            required
            label-text="Data do Encaminhamento"
            dispatch="Forwardings/setForwardingDate"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="expedition"
            input-type="date"
            :value="forwardingExpirationDate"
            show-label
            required
            label-text="Data de Expiração"
            dispatch="Forwardings/setForwardingExpirationDate"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="dif"
            input-type="number"
            :value="differenceBetweenDates"
            show-label
            label-text="Total de Dias entre as Datas"
            dispatch="Forwardings/setDifferenceBetweenDates"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Data nominal de Expiração"
            :text="getFullDate()"
            color="carmesim"
            type-text="custom"
            weight="semibold"
          />
        </div>
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Descrição do Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <BaseTextArea
            active
            inputName="description"
            labelText="Descrição"
            showLabel
            rows="4"
            :value="forwarding.forwarding_description"
            dispatch="Forwardings/setForwardingDescription"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Forwardings/closeModal" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="forwarding.id"
            text="Salvar"
            typeButton="success"
            dispatch="Forwardings/update"
          />
          <AppButtonEmpty v-else text="criar" typeButton="success" dispatch="Forwardings/save" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import AppTextField from "../components/AppTextField.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseModal from "../components/BaseModal.vue";
import BaseText from "../components/BaseText.vue";
import BaseTextArea from "../components/forms/BaseTextArea.vue";
import Label from "../components/Label.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    AppTextField,
    BaseModal,
    BaseInput,
    BaseText,
    BaseTextArea,
    Label,
    Multiselect,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    url: window.location.href,
  }),
  created() {
    let status = {
      id: 0,
      name: "Encaminhado",
    };
    this.$store.dispatch("Forwardings/setForwardingStatus", status);

    this.$store.dispatch("Forwardings/loadForwardingPolicies");
    this.$store.dispatch("Forwardings/loadSeasons");
    if (this.togglePsychosocialAccompanimentForwardings) {
      this.$store.dispatch("Forwardings/setInscription", this.inscriptionPsychosocial);
    }
  },
  computed: {
    ...mapState({
      forwarding: (state) => state.Forwardings.forwarding,
      forwardingDate: (state) => state.Forwardings.forwarding.forwarding_date,
      forwardingExpirationDate: (state) => state.Forwardings.forwarding.forwarding_expiration_date,
      differenceBetweenDates: (state) => state.Forwardings.differenceBetweenDates,
      forwarding_policies: (state) => state.Forwardings.forwarding_policies,
      forwarding_policy: (state) => state.Forwardings.forwarding_policy,
      inscriptions: (state) => state.Forwardings.inscriptions,
      inscription: (state) => state.Forwardings.inscription,
      seasons: (state) => state.Forwardings.seasons,
      season: (state) => state.Forwardings.season,
      season_filtered: (state) => state.Forwardings.params.season,
      psychosocial_accompaniments: (state) => state.Forwardings.psychosocial_accompaniments,
      psychosocial_accompaniment: (state) => state.Forwardings.psychosocial_accompaniment,
      facilities: (state) => state.Forwardings.facilities,
      facility: (state) => state.Forwardings.facility,
      forwarding_user: (state) => state.Forwardings.forwarding_user,
      user_areas: (state) => state.Forwardings.user_areas,
      user_areas_filtered: (state) => state.Forwardings.user_areas_filtered,
      modalToggleForwardings: (state) => state.Forwardings.modalToggleForwardings,
      togglePsychosocialAccompanimentForwardings: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentForwardings,
      inscriptionPsychosocial: (state) => state.PsychosocialAccompaniments.inscription,
      state: (state) => state.Forwardings.state,
      city: (state) => state.Forwardings.city,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
    }),
  },
  methods: {
    ...mapActions({
      setForwardingPolicy: "Forwardings/setForwardingPolicy",
      setInscription: "Forwardings/setInscription",
      setSeason: "Forwardings/setSeason",
      setState: "Forwardings/setState",
      setCity: "Forwardings/setCity",
      setUserAreasFiltered: "Forwardings/setUserAreasFiltered",
      setForwardingFacility: "Forwardings/setForwardingFacility",
    }),
    // onCheck(psychosocial_accompaniment) {
    //   this.$store.dispatch("Forwardings/setPsychosocialAccompaniment", psychosocial_accompaniment);
    // },
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    verifyMeetingHappened() {
      return this.psychosocial_accompaniment.meeting_happened ? "Sim" : "Não";
    },
    verifyIsForwarded() {
      return this.psychosocial_accompaniment.forwarded ? "Sim" : "Não";
    },
    getUserName() {
      if (this.forwarding_user.userName) {
        return `Usário(a) responsável pelo cadastro: ${this.forwarding_user.userName}`;
      } else {
        return `Usário(a) responsável pelo cadastro: ${this.forwarding_user.name}`;
      }
    },
    verifyStatus() {
      return this.$util_function.processInscriptionStatuses(this.inscription?.status);
    },
    verifyInscription(type) {
      if (this.inscription) {
        if (type === "cpf" && this.inscription.cpf) {
          return this.inscription.cpf;
        } else if (type === "birthdate" && this.inscription.birthdate) {
          return this.formattedDate(this.inscription.birthdate);
        } else if (type === "cellphone_number" && this.inscription.cellphone_number) {
          return this.inscription.cellphone_number;
        } else if (type === "age" && this.inscription.age) {
          return this.inscription.age + " anos";
        } else if (type === "equipment" && this.inscription.equipment) {
          return this.inscription.equipment.name;
        }
      }
    },
    addOrRemoveDays() {
      const currentDate = moment(this.forwardingDate)
        .add(this.differenceBetweenDates, "days")
        .format("YYYY-MM-DD");
      this.$store.commit("Forwardings/setForwardingExpirationDateMutation", currentDate);
    },
    calculateDifferenceBetweenDates() {
      const forwardingDate = moment(this.forwardingDate);
      const forwardingExpirationDate = moment(this.forwardingExpirationDate);

      let days = forwardingExpirationDate.diff(forwardingDate, "days");
      this.$store.commit("Forwardings/setDifferenceBetweenDatesMutation", days);
    },
    getFullDate() {
      moment.locale("pt");
      return moment(this.forwardingExpirationDate).format("LLLL");
    },
    getLabelCity() {
      return this.state ? "Município" : "Selecione o estado para listar os municípios";
    },
    getTypePage() {
      return this.url.includes("/psicossocial");
    },
  },

  watch: {
    user_areas_filtered: function () {
      this.$store.dispatch("Forwardings/loadInscriptions");
    },
    differenceBetweenDates: function () {
      this.addOrRemoveDays();
    },
    forwardingExpirationDate: function () {
      this.calculateDifferenceBetweenDates();
    },
    forwarding_policy: function () {
      if (this.modalToggleForwardings) {
        this.$store.dispatch("Forwardings/loadFacilitiesByForwardingPolicy");
      }
    },
  },
};
</script>
