<template>
  <div class="flex flex-col md:flex-row ml-5 md:ml-20">
    <div class="w-full md:w-168">
      <div class="flex flex-col justify-center h-screen p-5 md:p-0">
        <div>
          <BaseText type-text="custom" weight="semibold" size="2xl md:3xl" color="herbal">
            <p>Olá!</p>
            <p>Bem-vindo(a) ao sistema</p>
          </BaseText>
          <BaseText
            type-text="custom"
            text="Virando o Jogo!"
            weight="extrabold"
            size="2xl md:3xl"
            color="herbal"
          />
          <div class="mt-5">
            <BaseText type-text="custom" text="Vamos começar?" weight="normal" size="lg" color="herbal" />
          </div>
        </div>
      </div>
    </div>
    <div class="background w-full md:w-screen h-64 md:h-auto"></div>
  </div>
</template>

<script>
import BaseText from "../components/BaseText.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseText,
  },
  methods: {
    ...mapActions({
      getPermits: "AccessControl/getPermits",
      getProfPermits: "AccessControl/getProfilePermits",
    }),
  },

  computed: {
    ...mapState({
      perm: (state) => state.AccessControl.perm,
    }),
  },
  created() {
    this.$store.dispatch("AccessControl/getPermits");
  },
};
</script>

<style scoped>
.background {
  background-image: url("../assets/images/background-home-admin.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%; /* Garante que a imagem de fundo cubra a altura total */
}

.height {
  min-height: 120vh;
}

/* Adicionando regras de responsividade */
@media (max-width: 768px) {
  .background {
    height: 64vh; /* Ajusta a altura da imagem de fundo em dispositivos móveis */
  }
}
</style>
