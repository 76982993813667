<template>
  <div class="m-5">
    <div id="person-data" class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-5">
        <BaseText text="Dados da Empresa" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Nome"
          :text="company.name ? company.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Endereço"
          :text="company.address ? company.address : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Modalidade"
          :text="modality ? modality.name : 'Não informada'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Nº de Seleções Ofertadas"
          :text="total_opportunities_offered === 0 ? '0' : total_opportunities_offered"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Nº Total de Vagas Ofertadas"
          :text="total_vacancies_offered === 0 ? '0' : total_vacancies_offered"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Nº de Encaminhamentos Registrados"
          :text="total_registered_referrals === 0 ? '0' : total_registered_referrals"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Nº de Efetivados"
          :text="total_registered_referrals_made === 0 ? '0' : total_registered_referrals_made"
          type-text="topic-details"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppTextField from "../../components/AppTextField.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppTextField,
  },
  computed: {
    ...mapState({
      company: (state) => state.Companies.company,
      modality: (state) => state.Companies.modality,
      total_opportunities_offered: (state) => state.Companies.total_opportunities_offered,
      total_vacancies_offered: (state) => state.Companies.total_vacancies_offered,
      total_registered_referrals: (state) => state.Companies.total_registered_referrals,
      total_registered_referrals_made: (state) => state.Companies.total_registered_referrals_made,
    }),
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
  },
};
</script>
<style>
.active_new {
  background-color: #fff7ed;
  color: #fff;
  font-size: 1.1em;
}
</style>
