var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-5"},[_c('div',{staticClass:"grid grid-cols-5 gap-x-1 gap-y-6 mb-12",attrs:{"id":"person-data"}},[_c('div',{staticClass:"col-span-5"},[_c('BaseText',{attrs:{"text":"Dados do Planejamento","type-text":"title-forms"}}),_c('hr',{staticClass:"border-t-0 border-b-2 border-ashes opacity-50"})],1),_c('div',{staticClass:"col-span-1"},[_c('AppTextField',{attrs:{"show-label":"","label-text":"Área","text":_vm.area ? _vm.area.name : 'Não informada',"type-text":"topic-details"}})],1),_c('div',{staticClass:"col-span-3"},[_c('AppTextField',{attrs:{"show-label":"","label-text":"Equipamento","text":_vm.equipment ? _vm.equipment.name : 'Não informado',"type-text":"topic-details"}})],1),_c('div',{staticClass:"col-span-2"},[_c('AppTextField',{attrs:{"show-label":"","label-text":"Articulador(a) Responsável","text":_vm.planned_awareness_activity.articulator ? _vm.planned_awareness_activity.articulator : 'Não informado',"type-text":"topic-details"}})],1),_c('div',{staticClass:"col-span-1"},[_c('AppTextField',{attrs:{"show-label":"","label-text":"Data da Atividade","text":_vm.planned_awareness_activity.activity_date
            ? _vm.formattedDate(_vm.planned_awareness_activity.activity_date)
            : 'Não informada',"type-text":"topic-details"}})],1),_c('div',{staticClass:"col-span-5"},[_c('AppTextField',{attrs:{"show-label":"","label-text":"Objetivo","text":_vm.planned_awareness_activity.purpose ? _vm.planned_awareness_activity.purpose : 'Não informado',"type-text":"topic-details","classe":"break-words"}})],1),_c('div',{staticClass:"col-span-5"},[_c('AppTextField',{attrs:{"show-label":"","label-text":"Metodologia","text":_vm.planned_awareness_activity.methodology ? _vm.planned_awareness_activity.methodology : 'Não informada',"type-text":"topic-details","classe":"break-words"}})],1),_c('div',{staticClass:"col-span-5"},[_c('AppTextField',{attrs:{"show-label":"","label-text":"Recursos Utilizados","text":_vm.planned_awareness_activity.used_resources
            ? _vm.planned_awareness_activity.used_resources
            : 'Não informada',"type-text":"topic-details","classe":"break-words"}})],1),_c('div',{staticClass:"col-span-5"},[_c('AppTextField',{attrs:{"show-label":"","label-text":"Avaliação da Atividade","text":_vm.planned_awareness_activity.activity_evaluation
            ? _vm.planned_awareness_activity.activity_evaluation
            : 'Não informada',"type-text":"topic-details","classe":"break-words"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }