<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center container1">
      <div class="mt-4">
        <BaseText
          :text="verifyStatus()"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription?.student?.city?.name"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription.student.neighborhood.area.name"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getEquipmentName()"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription.student.name"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription.teaching_modality ? inscription.teaching_modality.name : '-'"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription.facility ? inscription.facility.name : '-'"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription.serie ? inscription.serie.name : '-'"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription.enrollment_date ? formattedDate(inscription.enrollment_date) : '-'"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4 flex">
        <router-link to="/reinsercao_escolar/reinserido_info">
          <a href="javascript:void(0)" @click="setInscription()">
            <div :class="classButtonAssignment">
              <AppMaterialIcon iconName="school" iconType="round" iconSize="36" />
            </div>
          </a>
        </router-link>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppMaterialIcon from "./AppMaterialIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppMaterialIcon,
    BaseText,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitResetPassword: false,
      permitShow: false,
      permit: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    inscription: {
      type: Object,
    },
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    getEquipmentName() {
      if (this.inscription.student.equipment) {
        return this.inscription.student.equipment.name;
      } else {
        return "Sem equipamento cadastrado";
      }
    },
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    setInscription() {
      this.$store.dispatch("SchoolReinsertions/setInscription", this.inscription);
    },
    verifyStatus() {
      return this.$util_function.processInscriptionStatuses(this.inscription?.status);
    },
  },
  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-4": true,
        "justify-items-stretch": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
        "cursor-pointer": true,
      };
    },
    classButtonAssignment() {
      return {
        border: true,
        "shadow-md": true,
        "m-2": true,
        "rounded-full": true,
        "h-15": true,
        "w-20": true,
        flex: true,
        "items-center": true,
        "justify-center": true,
        "text-herbal": true,
      };
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 8% 7% 9% 12% 16% 8% 12% 9% 15% 4%;
}
</style>
