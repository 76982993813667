import Vue from "vue";
import router from "../../router/index.js";
import {
  createBenefit,
  updateBenefit,
  destroyBenefit,
  getBenefit,
  getBenefitPayment,
} from "../../service/BenefitApi";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

const state = {
  payday: null,
  benefit: {
    id: null,
    description: null,
    date_benefit_start: null,
    date_benefit_end: null,
    type_benefit: null,
    payday: null,
    public_transport_fee: null,
    request_number: null,
    competency_date: null,
    total: null,
    student_amount: null,
    season: {},
    season_id: null,
  },
  emptyBenefit: {
    id: null,
    description: null,
    date_benefit_start: null,
    date_benefit_end: null,
    type_benefit: null,
    payday: null,
    public_transport_fee: null,
    request_number: null,
    competency_date: null,
    total: null,
    student_amount: null,
    season: {},
    season_id: null,
  },

  paid_benefit: false,

  studentsForPayment: [],

  errorMessages: [],
  modalBenefitOpen: false,
  modalDeleteBenefitConfirmationOpen: false,
  benefitIdForDeletion: null,
};

const mutations = {
  setBenefitMutation: (state, payload) => {
    state.benefit = payload;
    state.benefit.season_id = payload.season.id;
    state.paid_benefit = payload.payday != null ? true : false;
  },
  setStudentsForPaymentMutation: (state, payload) => {
    state.studentsForPayment = payload;
  },
  clearBenefitMutation: (state) => {
    state.benefit = Object.assign(state.benefit, state.emptyBenefit);
  },
  setDescriptionMutation: (state, description) => {
    state.benefit.description = description;
  },
  setDateBenefitStartMutation: (state, dateBenefitStart) => {
    state.benefit.date_benefit_start = dateBenefitStart;
  },
  setDateBenefitEndMutation: (state, dateBenefitEnd) => {
    state.benefit.date_benefit_end = dateBenefitEnd;
  },
  setTypeBenefitMutation: (state, typeBenefit) => {
    state.benefit.type_benefit = typeBenefit;
  },
  setPaydayMutation: (state, payday) => {
    state.payday = payday;
    state.benefit.payday = payday;
  },
  setPublicTransportFeeMutation: (state, publicTransportFee) => {
    state.benefit.public_transport_fee = publicTransportFee;
  },
  setRequestNumberMutation: (state, requestNumber) => {
    state.benefit.request_number = requestNumber;
  },
  setCompetencyDateMutation: (state, competencyDate) => {
    state.benefit.competency_date = competencyDate;
  },
  setSeasonMutation: (state, season) => {
    state.benefit.season = season;
    state.benefit.season_id = season.id;
  },
  toggleModalMutation: (state) => {
    state.modalBenefitOpen = !state.modalBenefitOpen;
  },
  toggleModalDeleteBenefitConfirmation: (state) => {
    state.modalDeleteBenefitConfirmationOpen = !state.modalDeleteBenefitConfirmationOpen;
  },
  benefitForDeletion: (state, benefit_id) => {
    state.benefitIdForDeletion = benefit_id;
  },
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

const actions = {
  setDescription: ({ commit }, payload) => {
    commit("setDescriptionMutation", payload);
  },
  setDateBenefitStart: ({ commit }, payload) => {
    commit("setDateBenefitStartMutation", payload.value);
  },
  setDateBenefitEnd: ({ commit }, payload) => {
    commit("setDateBenefitEndMutation", payload.value);
  },
  setTypeBenefit: ({ commit }, payload) => {
    commit("setTypeBenefitMutation", payload);
  },
  setPayday: ({ commit }, payload) => {
    commit("setPaydayMutation", payload.value);
  },
  setPublicTransportFee: ({ commit }, payload) => {
    commit("setPublicTransportFeeMutation", payload.value);
  },
  setRequestNumber: ({ commit }, payload) => {
    commit("setRequestNumberMutation", payload.value);
  },
  setCompetencyDate: ({ commit }, payload) => {
    commit("setCompetencyDateMutation", payload);
  },
  setSeason: ({ commit }, payload) => {
    let season = null;
    if (payload) {
      season = { id: payload.id, name: payload.name };
    }
    commit("setSeasonMutation", season);
  },
  openModal: ({ commit }) => {
    commit("toggleModalMutation");
  },
  openEditModal: ({ commit }, payload) => {
    commit("setBenefitMutation", payload);
    commit("toggleModalMutation");
  },
  closeModal: ({ commit }) => {
    commit("toggleModalMutation");
    commit("setErrorMessagesMutation", []);
  },
  clearModal: ({ commit }) => {
    commit("clearBenefitMutation");
  },
  saveBenefit: ({ commit, dispatch, rootState }) => {
    if (rootState.Benefits.tabBenefitType === "publicTransport") {
      dispatch("setTypeBenefit", 0);
    } else {
      if (rootState.Benefits.tabBenefitType === "scholarship") {
        dispatch("setTypeBenefit", 1);
      }
    }
    createBenefit(state.benefit)
      .then((response) => {
        Vue.$toast.success(response.data.message);
        dispatch("Benefits/addBenefit", response.data.benefit, { root: true });

        dispatch("closeModal");
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel cadastrar o benefício! " +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );

        console.error(e.response.data.errors);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  updateBenefit: ({ commit, dispatch, state }) => {
    updateBenefit(state.benefit.id, state.benefit)
      .then((response) => {
        Vue.$toast.success(response.data.message);
        dispatch("Benefits/updateBenefit", response.data.benefit, { root: true });
        commit("setDescriptionMutation", response.data.benefit.description);
        dispatch("closeModal");
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel atualizar o benefício! " +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );

        console.error(e.response.data.errors);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  closeBenefit: ({ commit, dispatch, state }) => {
    if (state.benefit.payday == null) {
      Vue.$toast.error("Data de Pagamento Obrigatório");
      return;
    }
    updateBenefit(state.benefit.id, state.benefit)
      .then((response) => {
        Vue.$toast.success(response.data.message);
        dispatch("Benefits/updateBenefit", response.data.benefit, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel atualizar o benefício! " +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );

        console.error(e.response.data.errors);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  loadBenefit: ({ commit }, id) => {
    getBenefit(id)
      .then((response) => {
        commit("setBenefitMutation", response.data.benefit);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  loadStudentsForPayment: ({ commit }, id) => {
    getBenefitPayment(id)
      .then((response) => {
        commit("setStudentsForPaymentMutation", response.data.benefit_payments);
      })
      .catch((e) => {
        console.error(e.response.data);
      });
  },
  openCloseModalDeleteBenefitConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteBenefitConfirmation");
    if (state.modalDeleteBenefitConfirmationOpen) {
      commit("benefitForDeletion", payload.id);
    } else {
      commit("benefitForDeletion", null);
    }
  },
  deleteBenefit: ({ state, dispatch, rootState }) => {
    const benefit_id = state.benefitIdForDeletion;
    let status = state.paid_benefit ? "pagos" : "pendentes";

    destroyBenefit(benefit_id)
      .then((response) => {
        const benefits = rootState.Benefits.all.filter((e) => {
          return e.id !== benefit_id;
        });

        if (rootState.Benefits.tabBenefitType === "publicTransport") {
          const benefitsPublicTransport = rootState.Benefits.benefitsPublicTransport.filter((e) => {
            return e.id !== benefit_id;
          });

          dispatch("Benefits/setBenefitsPublicTransport", benefitsPublicTransport, { root: true });
        } else {
          const benefitsScholarship = rootState.Benefits.benefitsScholarship.filter((e) => {
            return e.id !== benefit_id;
          });

          dispatch("Benefits/setBenefitsScholarship", benefitsScholarship, { root: true });
        }

        dispatch("Benefits/setAll", benefits, { root: true });

        Vue.$toast.success(response.data.message);
        router.push({ name: "benefits", params: { status: status } });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
    dispatch("openCloseModalDeleteBenefitConfirmation");
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      if (error.type === "blank") {
        return error.message;
      } else {
        return error.message;
      }
    } else {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
