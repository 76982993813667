<template>
  <div :class="editionsRow">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-6 my-6">
      <div class="text-ashes-hover font-medium text-2xl col-span-2">
        <Label
          text="Edições"
          :icon="null"
          :iconType="null"
          type="text-3xl"
          weight="semibold"
          normal
          mode="normal-case"
          :class="title"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          text="Adicionar edição"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="Seasons/openModal"
          v-if="permitCreate"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-6">
      <FilterComponent>
        <div class="col-span-4">
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="nameFiltered"
            label-text="Pesquisar por Nome da Edição"
            input-placeholder="Pesquisar por Nome da Edição"
            dispatch="Seasons/setFilterName"
          />
        </div>
        <div class="col-span-4">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city_filtered"
            :options="cities"
            label-text="Município"
            :dispatch="setFilterCity"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-6 my-6">
      <div>
        <AppButtonColorful
          id="btn-consult-seasons"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Seasons/loadSeasons"
          :payload="typeRequest"
          typeButton="primary"
        />
      </div>
      <div class="items-center justify-self-end" v-if="seasons.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-seasons"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div class="grid grid-cols-custom mt-12 ml-6 text-ashes-dark">
      <div>
        <Label
          text="Edição"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Status"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Período de inscrição"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Período de execução"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Nº de Matrículas"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Nº de Ativos"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Nº de Desligados"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Nº de Concludentes"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <!-- Cada elemento requer um atributo data-index para que a transição funcione corretamente -->
      <div
        v-for="(season, index) in filteredSeasons"
        :data-index="index"
        :key="season.id"
        class="mt-6"
        @click.prevent="goToSeason(season)"
      >
        <AppEdition :edition="season" />
        <div class="my-6 border-ashes w-full border"></div>
      </div>
    </BaseStaggeredFade>
    <div id="seasons-pagination" class="mx-12 my-6 pb-3" v-if="seasons.length > 0">
      <BasePagination
        :total="seasonsTotal"
        :total-per-page="seasonsTotalPerPage"
        dispatch="Seasons/setSeasonsOffset"
      />
    </div>
    <ModalSeason :open="showModal" />
  </div>
</template>

<script>
import AppEdition from "../components/content/AppEdition.vue";
import Label from "../components/Label.vue";
import BaseStaggeredFade from "../components/BaseStaggeredFade.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import ModalSeason from "./ModalSeason.vue";
import router from "../router/index.js";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import BasePagination from "@/components/BasePagination.vue";
import Multiselect from "@/components/forms/Multiselect.vue";

export default {
  name: "seasons",

  components: {
    Multiselect,
    BasePagination,
    // BaseModal,
    BaseInput,
    // PreLoading,
    // Multiselect,
    // BaseText,
    // AppButtonIcon,
    FilterComponent,
    // AppButtonEmpty,
    AppEdition,
    Label,
    AppButtonColorful,
    ModalSeason,
    BaseStaggeredFade,
  },
  data: () => ({
    searchEdition: "",
    edit: false,
    edition: null,
    permit: false,
    permitUp: false,
    permitCreate: false,
    type_request: null,
    url: window.location.href,
  }),
  computed: {
    ...mapState({
      showModal: (state) => state.Seasons.modalSeasonOpen,
      seasons: (state) => state.Seasons.seasons,
      seasonsTotal: (state) => state.Seasons.seasonsTotal,
      seasonsTotalPerPage: (state) => state.Seasons.seasonsTotalPerPage,
      seasonsOffset: (state) => state.Seasons.seasonsOffset,
      nameFiltered: (state) => state.Seasons.params.filter.name,
      states: (state) => state.Seasons.states,
      cities: (state) => state.UtilFunctions.cities,
      state: (state) => state.Seasons.state,
      city_filtered: (state) => state.Seasons.params.filter.city,
    }),
    editionsRow() {
      return {
        "p-10": true,
      };
    },
    title() {
      return {
        "text-ashes-hover": true,
      };
    },
    filteredSeasons() {
      return this.seasons.filter((obj) => {
        return obj.name.toLowerCase().indexOf(this.searchEdition.toLowerCase()) > -1;
      });
    },
    typeRequest() {
      return this.$route.path === "/edicoes/finalizada" ? "closed" : null;
    },
  },
  created() {
    let type_request = null;
    type_request = this.url.includes("/edicoes/finalizada") ? "closed" : null;
    this.$store.dispatch("Seasons/loadSeasons", type_request);
    this.$store.dispatch("Seasons/loadStates");
    this.$store.dispatch("Seasons/loadCities");

    this.seasonAcces();
    this.seasonCreate();
    this.seasonChange();
  },
  beforeRouteUpdate(to, from, next) {
    let type_request = null;
    type_request = to.path === "/edicoes/finalizada" ? "closed" : null;
    this.$store.dispatch("Seasons/loadSeasons", type_request);

    next();
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setState: "Seasons/setState",
      setFilterCity: "Seasons/setFilterCity",
    }),
    seasonAcces() {
      this.canAcces("season_index").then((resp) => {
        this.permit = resp;
      });
    },
    seasonCreate() {
      this.canAcces("season_create").then((resp) => {
        this.permitCreate = resp;
      });
    },
    seasonChange() {
      this.canAcces("season_update").then((resp) => {
        this.permitUp = resp;
      });
    },
    goToSeason(params) {
      router.push({ name: "season", params: { id: params.id } });
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
    clearFields() {
      this.$store.dispatch("Seasons/clearQuery");
    },
  },
  watch: {
    seasonsOffset: function () {
      this.$store.dispatch("Seasons/loadSeasons");
      this.backToTop();
    },
  },
};
</script>
<style scoped>
.grid-cols-custom {
  grid-template-columns: 11% 9% 14% 15% 10% 8% 11% 20%;
}
</style>
