<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-4">
        <div class="justify-self-start col-span-3">
          <Label
            v-if="school_reinsertion.id"
            text="Editar Atividade"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar Atividade"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="SchoolReinsertions/closeModalAddActivity" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Dados cadastrais da atividade"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Tipo da Atividade"
            label-text="Tipo da Atividade"
            :multiple-option="false"
            :value="activity_type"
            :options="activity_types"
            :dispatch="setActivityType"
            required
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Município"
            :dispatch="setCity"
            required
          />
        </div>
        <div class="col-span-2" v-if="activity_type?.id === 0 || activity_type?.id === 1">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Resultado da Atividade"
            label-text="Resultado da Atividade"
            :multiple-option="false"
            :value="result_type"
            :options="result_types"
            :dispatch="setResultType"
            required
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="reinsertion_date"
            input-type="date"
            :value="school_reinsertion.reinsertion_date"
            show-label
            label-text="Data da Atividade"
            dispatch="SchoolReinsertions/setReinsertionDate"
            required
          />
        </div>
        <div v-if="result_type?.id === 0 || activity_type?.id === 4" class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Motivo da Não Reinserção"
            label-text="Motivo da Não Reinserção"
            :multiple-option="false"
            :value="reasons_for_non_reinsertion"
            :options="reasons_for_non_reinsertions"
            :dispatch="setReasonsForNorReinsertion"
            required
          />
        </div>
        <div class="col-span-2">
          <BaseTextArea
            active
            :showIcon="true"
            iconName="help"
            inputName="observations"
            labelText="Observações"
            :tooltipMessage="tooltipObservationMessage"
            required
            showLabel
            rows="4"
            :value="school_reinsertion.observations"
            dispatch="SchoolReinsertions/setObservations"
          />
        </div>
        <div class="col-span-2" v-if="result_type?.id === 1 || activity_type?.id === 2">
          <BaseInput
            active
            input-name="letter_delivery_date"
            input-type="date"
            :value="school_reinsertion.letter_delivery_date"
            show-label
            label-text="Data da Entrega da Carta"
            dispatch="SchoolReinsertions/setLetterDeliveryDate"
            required
          />
        </div>
        <div class="col-span-2" v-if="mustShowFacilityField()">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            :select-placeholder="returnIfIsForwardingOrFacilityLabel()"
            :label-text="returnIfIsForwardingOrFacilityLabel()"
            :multiple-option="false"
            :value="facility"
            :options="facilities"
            :dispatch="setFacility"
            required
          />
        </div>
        <div class="col-span-2" v-if="result_type?.id === 1 || activity_type?.id === 2">
          <BaseInput
            active
            input-name="return_letter_deadline"
            input-type="date"
            :value="school_reinsertion.return_letter_deadline"
            show-label
            label-text="Data de Retorno da Carta"
            dispatch="SchoolReinsertions/setReturnLetterDeadline"
            required
          />
        </div>
        <div v-if="result_type?.id === 2 || activity_type?.id === 3">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Turno"
            label-text="Turno"
            :multiple-option="false"
            :value="shift"
            :options="shifts"
            :dispatch="setShift"
            required
          />
        </div>
        <div v-if="result_type?.id === 2 || activity_type?.id === 3">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Ano/Série"
            label-text="Ano/Série"
            :multiple-option="false"
            :value="serie"
            :options="series"
            :dispatch="setSerie"
            required
          />
        </div>
        <div v-if="result_type?.id === 2 || activity_type?.id === 3">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Modalidade de Ensino"
            label-text="Modalidade de Ensino"
            :multiple-option="false"
            :value="teaching_modality"
            :options="teaching_modalities"
            :dispatch="setTeachingModality"
            required
          />
        </div>
        <div v-if="result_type?.id === 2 || activity_type?.id === 3">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Dependência Administrativa"
            label-text="Dependência Administrativa"
            :multiple-option="false"
            :value="administrative_dependency"
            :options="administrative_dependencies"
            :dispatch="setAdministrativeDependency"
          />
        </div>
        <div class="col-span-2" v-if="result_type?.id === 2 || activity_type?.id === 3">
          <BaseInput
            active
            input-name="enrollment_date"
            input-type="date"
            :value="school_reinsertion.enrollment_date"
            show-label
            label-text="Data da Matrícula"
            dispatch="SchoolReinsertions/setEnrollmentDate"
            required
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="articulator"
            input-type="text"
            :value="school_reinsertion.articulator"
            show-label
            label-text="Articulador(a) Responsável"
            dispatch="SchoolReinsertions/setArticulator"
            required
          />
        </div>
        <div class="col-span-2" v-if="result_type?.id === 2 || activity_type?.id === 3">
          <AppButtonUploadFile2
            id="file-declaration"
            :id_file="`${idFileStudentDeclaracao}`"
            name="declaração"
            text="+  Comprovante de Matrícula"
            dispatch="ModalInscription/setDocumentInscriptionDECLARACAO"
            type-document="declaration"
            label-text="Anexar Documento"
            show-label
            color="ashes-dark"
          />
          <div class="-mt-8">
            <AppButtonIcon
              iconName="delete"
              :payload="inscription.student"
              dispatch="ModalInscription/deleteStudentDocumentDECLARACAO"
            />
            <AppButtonIcon
              iconName="download"
              :payload="inscription.student"
              dispatch="ModalInscription/showStudentDocumentDECLARACAO"
            />
            <AppButtonIcon
              iconName="event"
              dispatch="SchoolReinsertions/toggleSchoolReinsertionDeadlineAttachProofOfEnrollment"
              title="Informar data para/de anexo de comprovante de matrícula"
            />
          </div>
        </div>
        <div
          class="col-span-2"
          v-if="
            (result_type?.id === 2 || activity_type?.id === 3) &&
            toggleSchoolReinsertionDeadlineAttachProofOfEnrollment
          "
        >
          <BaseInput
            active
            input-name="deadline_attach_proof_of_enrollment"
            input-type="date"
            :value="school_reinsertion.deadline_attach_proof_of_enrollment"
            show-label
            label-text="Data Para/De Anexo de Comprovante de Matrícula"
            dispatch="SchoolReinsertions/setDeadlineAttachProofOfEnrollment"
            required
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="SchoolReinsertions/closeModalAddActivity"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="school_reinsertion.id"
            text="Salvar"
            typeButton="success"
            dispatch="SchoolReinsertions/update"
          />
          <AppButtonEmpty v-else text="criar" typeButton="success" dispatch="SchoolReinsertions/save" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import AppButtonIcon from "../../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../../components/AppButtonEmpty.vue";
import BaseInput from "../../../components/forms/BaseInput.vue";
import BaseModal from "../../../components/BaseModal.vue";
import Label from "../../../components/Label.vue";
import Multiselect from "../../../components/forms/Multiselect.vue";
import AppButtonUploadFile2 from "../../../components/AppButtonUploadFile2.vue";
import { mapState, mapActions } from "vuex";
import BaseTextArea from "../../../components/forms/BaseTextArea.vue";

export default {
  components: {
    BaseTextArea,
    AppButtonUploadFile2,
    BaseModal,
    BaseInput,
    Label,
    Multiselect,
    AppButtonIcon,
    AppButtonEmpty,
  },
  data() {
    return {
      input: 1,
      tooltipHealthSituationObservations: false,
      tooltipObservationMessage:
        "Neste campo devem ser registrados os detalhes e eventuais obstáculos para a efetivação da matrícula do participante, incluindo as articulações realizadas no território para que\n" +
        "fosse efetivada a matrícula (Exemplo: visitas institucionais, contatos realizados etc.)",
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const user = JSON.parse(localStorage.userData);
    this.$store.dispatch("SchoolReinsertions/setUser", user);
  },
  computed: {
    ...mapState({
      school_reinsertion: (state) => state.SchoolReinsertions.school_reinsertion,
      inscription: (state) => state.SchoolReinsertions.inscription,
      activity_types: (state) => state.SchoolReinsertions.activity_types,
      activity_type: (state) => state.SchoolReinsertions.activity_type,
      result_types: (state) => state.SchoolReinsertions.result_types,
      result_type: (state) => state.SchoolReinsertions.result_type,
      articulators: (state) => state.SchoolReinsertions.articulators,
      articulator: (state) => state.SchoolReinsertions.articulator,
      reasons_for_non_reinsertions: (state) => state.SchoolReinsertions.reasons_for_non_reinsertions,
      reasons_for_non_reinsertion: (state) => state.SchoolReinsertions.reasons_for_non_reinsertion,
      facilities: (state) => state.SchoolReinsertions.facilities,
      facility: (state) => state.SchoolReinsertions.facility,
      series: (state) => state.SchoolReinsertions.series,
      serie: (state) => state.SchoolReinsertions.serie,
      shifts: (state) => state.SchoolReinsertions.shifts,
      shift: (state) => state.SchoolReinsertions.shift,
      teaching_modalities: (state) => state.SchoolReinsertions.teaching_modalities,
      teaching_modality: (state) => state.SchoolReinsertions.teaching_modality,
      administrative_dependencies: (state) => state.SchoolReinsertions.administrative_dependencies,
      administrative_dependency: (state) => state.SchoolReinsertions.administrative_dependency,
      idFileStudentDeclaracao: (state) => state.ModalInscription.idFileStudentDeclaracao,
      toggleSchoolReinsertionDeadlineAttachProofOfEnrollment: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionDeadlineAttachProofOfEnrollment,
      city: (state) => state.SchoolReinsertions.city,
      state: (state) => state.SchoolReinsertions.state,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
    }),
  },
  methods: {
    ...mapActions({
      setActivityType: "SchoolReinsertions/setActivityType",
      setResultType: "SchoolReinsertions/setResultType",
      setReasonsForNorReinsertion: "SchoolReinsertions/setReasonsForNorReinsertion",
      setFacility: "SchoolReinsertions/setFacility",
      setSerie: "SchoolReinsertions/setSerie",
      setShift: "SchoolReinsertions/setShift",
      setTeachingModality: "SchoolReinsertions/setTeachingModality",
      setAdministrativeDependency: "SchoolReinsertions/setAdministrativeDependency",
      setCity: "SchoolReinsertions/setCity",
      setState: "SchoolReinsertions/setState",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    returnIfIsForwardingOrFacilityLabel() {
      return this.activity_type?.id === 4 || this.result_type?.id === 0 ? "Encaminhamentos" : "Escola";
    },
    mustShowFacilityField() {
      return (
        this.result_type?.id === 2 ||
        this.activity_type?.id === 3 ||
        this.activity_type?.id === 2 ||
        this.activity_type?.id === 4 ||
        this.result_type?.id === 0 ||
        this.result_type?.id === 1 ||
        this.result_type?.id === 3
      );
    },
    getActivityType() {
      if (this.activity_type) {
        return this.activity_type.id === 0 || this.activity_type.id === 1;
      } else {
        return false;
      }
    },
    getLabelCity() {
      return this.state ? "Selecionar Município" : "Selecione o estado para listar os municípios";
    },
  },
};
</script>
