<template>
  <BaseModal width="w-128" v-if="open">
    <template v-slot:header>
      <div class="flex flex-row-reverse">
        <AppButtonIcon
          for-close
          :payload="{ mutation: 'toggleModalInscriptionStatusOpenConfirmation' }"
          dispatch="ModalInscription/toggleModalInscriptionStatus"
        />
      </div>
      <div class="flex">
        <AppMaterialIcon iconName="check_circle" class="text-burnedYellow mr-2" />
        <BaseText
          text="Mudar para Em Aberto"
          typeText="custom"
          weight="semibold"
          size="2xl"
          color="burnedYellow"
        />
      </div>
    </template>
    <template v-slot:body>
      <div class="text-quantum mb-4">
        A ficha de pré-inscrição do jovem voltará para o status
        <b>"EM ABERTO"</b>.
      </div>
      <div class="text-quantum">
        <b>Tem certeza que deseja mudar o status do jovem?</b>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-end">
        <AppButtonEmpty
          text="Não"
          type-button="danger"
          :payload="{ mutation: 'toggleModalInscriptionStatusOpenConfirmation' }"
          dispatch="ModalInscription/toggleModalInscriptionStatus"
          class="mr-5"
        />
        <AppButtonEmpty
          text="Sim"
          type-button="success"
          :payload="{
            mutation: 'toggleModalInscriptionStatusOpenConfirmation',
            status: 'open',
          }"
          dispatch="ModalInscription/setStatusAndCloseModal"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import AppMaterialIcon from "../../components/AppMaterialIcon.vue";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonIcon,
    AppMaterialIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
    },
  },
};
</script>
