import { getAxios, baseURL } from "./Api";

export async function getNeighborhoods(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/bairros`;
  return api.get(url, { params: filters });
}

export async function getNeighborhoodsForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `bairros/para_menus`;
  return api.get(url, { params: filters });
}

export async function getNeighborhoodById(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`bairros/${id}`);
}

export async function createNeighborhood(neighborhood) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/bairros", neighborhood);
}

export async function updateNeighborhood(id, neighborhood) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`bairros/${id}`, neighborhood);
}

export async function destroyNeighborhood(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`bairros/${id}`);
}

export async function getNeighborhoodsByCityId(city_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`cidades/${city_id}/bairros`);
}

export async function getNeighborhoodsByCityIdWithoutArea(city_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`cidades/${city_id}/bairros/sem_areas`);
}

export async function getNeighborhoodsByAreaId(area_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`areas/${area_id}/bairros`);
}
