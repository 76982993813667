import { getDeficiencies } from "../../service/DeficiencyApi";

const state = {
  deficiencies: [],
};

const mutations = {
  loadDeficiencies: (state, deficiencies) => {
    state.deficiencies = deficiencies;
  },
};

const actions = {
  loadDeficiencies: ({ commit }) => {
    getDeficiencies()
      .then((response) => {
        commit("loadDeficiencies", response.data.deficiencies);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
