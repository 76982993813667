<template>
  <div>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4 ml-5">
        <BaseText
          text="Status"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Município"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Área"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Nome do Participante"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Data"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Seleção"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Parceiro"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Empresa"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Devolutiva"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li
        :key="forwarding_productive_insertion.id"
        :data-index="index"
        v-for="(forwarding_productive_insertion, index) in data"
      >
        <div>
          <AppCardForwardingProductiveInsertion
            :forwarding_productive_insertion="forwarding_productive_insertion"
          />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardForwardingProductiveInsertion from "../../components/AppCardForwardingProductiveInsertion.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";

export default {
  components: {
    AppCardForwardingProductiveInsertion,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 8% 10% 7% 15% 7% 10% 10% 10% 6%;
}
</style>
