import { render, staticRenderFns } from "./TransportationVoucherPaymentSheetDetails.vue?vue&type=template&id=2b44fda0&scoped=true"
import script from "./TransportationVoucherPaymentSheetDetails.vue?vue&type=script&lang=js"
export * from "./TransportationVoucherPaymentSheetDetails.vue?vue&type=script&lang=js"
import style0 from "./TransportationVoucherPaymentSheetDetails.vue?vue&type=style&index=0&id=2b44fda0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b44fda0",
  null
  
)

export default component.exports