import {
  getAllTeams,
  getTeamsBySeasonExport,
  getTeamsByTeacher,
  getTeamsForExport,
  getTeamsForExportPendings,
} from "../../service/TeamApi";
import Vue from "vue";
import moment from "moment";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

const state = {
  all: [],
  filteredAll: [],
  teamsToExportPresences: [],
  teams_spreadsheet: [],
  teams_pendings_spreadsheet: [],
  teamsTotal: 0,
  totalPerPage: 10,
  offset: 0,
  tabTypeTeamView: "teams_in_progress",
  toggleTeamsInProgress: true,
  toggleTeamsFinished: false,
  modalToggleExportTeamsSpreadsheet: false,
  hasSpreadsheetTeamsResponse: true,
  json_data_team: null,
  modalToggleExportTeamsPendingsSpreadsheet: false,
  hasSpreadsheetTeamsPendingsResponse: true,
  json_data_team_pendings: null,
  filter: {
    discipline: {},
    season: {},
    phase: {},
    areas_equipment: [],
    start: null,
    closing: null,
    code: null,
    equipment: null,
    course: null,
    teacher: null,
    in_progress: true,
  },
};

const mutations = {
  setAllMutation: (state, teams) => {
    state.all = teams;
  },
  setFilteredAllMutation: (state, teams) => {
    state.filteredAll = teams;
  },
  setTeamsToExportPresencesMutation: (state, teams) => {
    state.teamsToExportPresences = teams;
  },
  setDisciplineIdMutation: (state, discipline) => {
    state.filter.discipline = discipline;
  },
  setSeasonIdMutation: (state, season) => {
    state.filter.season = season;
  },
  setPhaseIdMutation: (state, phase) => {
    state.filter.phase = phase;
  },
  setAreaMutation: (state, area) => {
    state.filter.areas_equipment = area;
  },
  addTeamMutation: (state, team) => {
    state.filteredAll = [team, ...state.filteredAll];
    state.all = state.filteredAll;
  },
  removeTeamMutation: (state, team) => {
    state.filteredAll = state.filteredAll.filter((item) => {
      return item.id !== team.id;
    });
  },
  updateTeamMutation: (state, team) => {
    state.all = state.all.map((item) => {
      return item.id === team.id ? team : item;
    });
  },
  setStartMutation: (state, start) => {
    state.filter.start = start;
  },
  setClosingMutation: (state, closing) => {
    state.filter.closing = closing;
  },
  // --> MUTATIONS - PAGINATION <--
  setTotalPerPage: (state, totalPerPage) => {
    state.totalPerPage = totalPerPage;
  },
  setOffset: (state, offset) => {
    state.offset = offset;
  },
  setTeamsTotal: (state, teamsTotal) => {
    state.teamsTotal = teamsTotal;
  },
  filterByTeamCode: (state, code) => {
    state.filter.code = code;
  },
  filterByTeamEquipment: (state, equipment) => {
    state.filter.equipment = equipment;
  },
  filterByTeamCourse: (state, course) => {
    state.filter.course = course;
  },
  filterByTeamTeacher: (state, teacher) => {
    state.filter.teacher = teacher;
  },
  filterByInProgress: (state, in_progress) => {
    state.filter.in_progress = in_progress;
  },
  toggleTeamsInProgress: (state, value) => {
    state.toggleTeamsInProgress = value;
  },
  toggleTeamsFinished: (state, value) => {
    state.toggleTeamsFinished = value;
  },
  // --> MUTATIONS - OTHER MUTATIONS <--
  setTabTypeTeamViewMutation: (state, type) => {
    state.tabTypeTeamView = type;
  },
  setTeamsSpreadsheetMutation: (state, teams_spreadsheet) => {
    state.teams_spreadsheet = teams_spreadsheet;
  },
  setTeamsPendingsSpreadsheetMutation: (state, teams_pendings_spreadsheet) => {
    state.teams_pendings_spreadsheet = teams_pendings_spreadsheet;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportTeamsSpreadsheet: (state) => {
    state.modalToggleExportTeamsSpreadsheet = !state.modalToggleExportTeamsSpreadsheet;
  },

  toggleModalToggleExportTeamsPendingsSpreadsheet: (state) => {
    state.modalToggleExportTeamsPendingsSpreadsheet = !state.modalToggleExportTeamsPendingsSpreadsheet;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetTeamsResponseMutation: (state, hasSpreadsheetTeamsResponse) => {
    state.hasSpreadsheetTeamsResponse = hasSpreadsheetTeamsResponse;
  },
  hasSpreadsheetTeamsPendingsResponseMutation: (state, hasSpreadsheetTeamsPendingsResponse) => {
    state.hasSpreadsheetTeamsPendingsResponse = hasSpreadsheetTeamsPendingsResponse;
  },

  setTeamsPageToExportMutation: (state, team) => {
    state.json_data_team = null;
    const arrayData = [];
    team.forEach((element, index, array) => {
      let area = array[index].areas_equipment ? array[index].areas_equipment.name : "-";
      let city = array[index].season.city ? array[index].season.city.name : "-";
      let code = array[index].code ? array[index].code : "-";
      let equipment = array[index].equipment ? array[index].equipment.name : "-";
      let course = array[index].course ? array[index].course.name : "-";
      let shift = array[index].shift ? array[index].shift.name : "-";
      let teacher = array[index].teacher ? array[index].teacher.name : "-";
      let qtd_students = array[index].qtd_students ? array[index].qtd_students : "-";
      let start = array[index].start ? array[index].start : "-";
      let closing = array[index].closing ? array[index].closing : "-";
      let daysWeek = [];

      if (array[index].monday) {
        daysWeek.push("Segunda Feira");
      }
      if (array[index].tuesday) {
        daysWeek.push("Terça Feira");
      }
      if (array[index].wednesday) {
        daysWeek.push("Quarta Feira");
      }
      if (array[index].thursday) {
        daysWeek.push("Quinta Feira");
      }
      if (array[index].friday) {
        daysWeek.push("Sexta Feira");
      }

      const arrayItem = [];
      arrayItem["city"] = city;
      arrayItem["area"] = area;
      arrayItem["code"] = code;
      arrayItem["equipment"] = equipment;
      arrayItem["course"] = course;
      arrayItem["daysWeek"] = daysWeek;
      arrayItem["shift"] = shift;
      arrayItem["teacher"] = teacher;
      arrayItem["qtd_students"] = qtd_students;
      arrayItem["start"] = start;
      arrayItem["closing"] = closing;
      arrayData.push(arrayItem);
    });
    state.json_data_team = arrayData;
    state.hasSpreadsheetTeamsResponse = true;
    state.modalToggleExportTeamsSpreadsheet = true;
  },
  setTeamsPendingsPageToExportMutation: (state, team_pendings) => {
    state.json_data_team_pendings = null;
    const arrayData = [];
    team_pendings.forEach((element, index, array) => {
      let area = array[index].areas_equipment ? array[index].areas_equipment.name : "-";
      let city = array[index].season.city ? array[index].season.city.name : "-";
      let code = array[index].code ? array[index].code : "-";
      let equipment = array[index].equipment ? array[index].equipment.name : "-";
      let teacher = array[index].teacher ? array[index].teacher.name : "-";
      let sorted_unregistered_presence_dates =
        array[index].sorted_unregistered_presence_dates?.length > 0
          ? array[index].sorted_unregistered_presence_dates
          : "-";

      const arrayItem = [];
      arrayItem["city"] = city;
      arrayItem["area"] = area;
      arrayItem["code"] = code;
      arrayItem["equipment"] = equipment;
      arrayItem["teacher"] = teacher;
      arrayItem["sorted_unregistered_presence_dates"] = sorted_unregistered_presence_dates;
      arrayData.push(arrayItem);
    });
    state.json_data_team_pendings = arrayData;
    state.hasSpreadsheetTeamsPendingsResponse = true;
    state.modalToggleExportTeamsPendingsSpreadsheet = true;
  },
};

function getLoadParams(rootState) {
  return {
    city_id: localStorage.city_id,
    perPage: state.totalPerPage,
    offset: state.offset,
    area_id: state.filter.areas_equipment.id ? state.filter.areas_equipment.id : null,
    code: state.filter.code ? state.filter.code : null,
    season_id: rootState?.Season?.season?.id,
    in_progress: state.filter.in_progress,
    equipment: state.filter.equipment ? state.filter.equipment : null,
    course: state.filter.course ? state.filter.course : null,
    teacher: state.filter.teacher ? state.filter.teacher.id : null,
    start: state.filter.start ? state.filter.start : null,
    closing: state.filter.closing ? state.filter.closing : null,
    phase_id: state.filter.phase.id ? state.filter.phase.id : null,
  };
}

const actions = {
  setAll: ({ commit }, payload) => {
    commit("setAllMutation", payload);
    commit("setFilteredAllMutation", payload);
  },
  addTeam: ({ commit }, payload) => {
    commit("addTeamMutation", payload);
  },
  removeTeam: ({ commit }, payload) => {
    commit("removeTeamMutation", payload);
  },
  updateTeam: ({ commit }, payload) => {
    commit("updateTeamMutation", payload);
  },
  filterByTeamCode: ({ commit }, payload) => {
    commit("filterByTeamCode", payload.value);
  },
  filterByTeamEquipment: ({ commit }, payload) => {
    commit("filterByTeamEquipment", payload.value);
  },
  filterByTeamCourse: ({ commit }, payload) => {
    commit("filterByTeamCourse", payload.value);
  },
  filterByTeamTeacher: ({ commit }, payload) => {
    commit("filterByTeamTeacher", payload);
  },
  filterByInProgress: ({ commit }, payload) => {
    commit("filterByInProgress", payload);
  },
  filterTeamsByDiscipline: ({ commit, dispatch }, payload) => {
    commit("setDisciplineIdMutation", payload);
    dispatch("filterTeams");
  },
  filterTeamsBySeason: ({ commit, state }, payload) => {
    const teams = state.filteredAll.filter((obj) => {
      return obj.season.id === payload.id;
    });
    commit("setFilteredAllMutation", teams);
    commit("setSeasonIdMutation", payload);
  },
  filterTeamsByPhase: ({ commit, state }, payload) => {
    const teams = state.filteredAll.filter((obj) => {
      return obj.discipline.phase.id === payload.id;
    });
    commit("setFilteredAllMutation", teams);
    commit("setPhaseIdMutation", payload);
  },
  filterTeamsByArea: ({ commit, dispatch }, payload) => {
    commit("setAreaMutation", payload);
    dispatch("filterTeams");
  },
  filterTeamsByPeriodStart: ({ commit, dispatch }, payload) => {
    commit("setStartMutation", payload.value);
    dispatch("filterTeams");
  },
  filterTeamsByPeriodClosing: ({ commit, dispatch }, payload) => {
    commit("setClosingMutation", payload.value);
    dispatch("filterTeams");
  },
  setTotalPerPage: ({ commit }, payload) => {
    payload && payload.totalPerPage
      ? commit("setTotalPerPage", payload.totalPerPage)
      : commit("setTotalPerPage", null);
  },
  setOffset: ({ commit }, payload) => {
    commit("setOffset", payload.offset);
  },
  setTeamsTotal: ({ commit }, payload) => {
    commit("setTeamsTotal", payload);
  },
  setTeamsToExportPresencesMutation: ({ commit }, payload) => {
    commit("setTeamsToExportPresencesMutation", payload);
  },
  clearFilter: ({ commit, state }) => {
    commit("setFilteredAllMutation", state.all);
    commit("setPhaseIdMutation", {});
    commit("setSeasonIdMutation", {});
    commit("setAreaMutation", {});
    commit("setStartMutation", null);
    commit("setClosingMutation", null);
    commit("setDisciplineIdMutation", {});
  },
  loadTeams: ({ commit, rootState }) => {
    const params = getLoadParams(rootState);
    const getRole = rootState.AccessControl.profileInfo.description;
    if (getRole === "Instrutor") {
      const user_id = rootState.Session.loggedUser.id;
      getTeamsByTeacher(user_id, params)
        .then((response) => {
          commit("setTeamsTotal", response.data.teams_count);
          commit("setAllMutation", response.data.teams);
          commit("setFilteredAllMutation", response.data.teams);
        })
        .catch((e) => {
          Vue.$toast.error(e.response.data.error);
        });
    } else
      getAllTeams(params)
        .then((response) => {
          commit("setTeamsTotal", response.data.teams_count);
          commit("setAllMutation", response.data.teams);
          commit("setFilteredAllMutation", response.data.teams);
        })
        .catch((e) => {
          Vue.$toast.error(e.response.data.error);
        });
  },
  loadTeamsForExport: ({ commit, rootState }) => {
    commit("hasSpreadsheetTeamsResponseMutation", false);
    const params = getLoadParams(rootState);
    getTeamsForExport(params)
      .then(async (response) => {
        commit("setTeamsSpreadsheetMutation", response.data.teams);
        if (state.teams_spreadsheet.length > 0) {
          await commit("setTeamsPageToExportMutation", response.data.teams);
        } else {
          commit("hasSpreadsheetTeamsResponseMutation", true);
          commit("toggleModalToggleExportTeamsSpreadsheet");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadTeamsPendingsForExport: ({ commit, rootState }) => {
    commit("hasSpreadsheetTeamsPendingsResponseMutation", false);
    const params = getLoadParams(rootState);
    getTeamsForExportPendings(params)
      .then(async (response) => {
        commit("setTeamsPendingsSpreadsheetMutation", response.data.teams);
        if (state.teams_pendings_spreadsheet.length > 0) {
          await commit("setTeamsPendingsPageToExportMutation", response.data.teams);
        } else {
          commit("hasSpreadsheetTeamsPendingsResponseMutation", true);
          commit("toggleModalToggleExportTeamsPendingsSpreadsheet");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  getTeamsBySeasonExport: ({ dispatch, rootState }) => {
    const params = getLoadParams(rootState);
    getTeamsBySeasonExport(params)
      .then((response) => {
        dispatch("setTeamsToExportPresencesMutation", response.data.teams);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  filterTeams: ({ commit, state }) => {
    commit("setFilteredAllMutation", state.all);

    let discipline = state.filter.discipline;
    let areas_equipment = state.filter.areas_equipment;
    let start = state.filter.start;
    let closing = state.filter.closing;

    if (discipline != null && discipline.id != null) {
      let filtered = state.filteredAll.filter((obj) => {
        return obj.discipline.id === discipline.id;
      });
      commit("setFilteredAllMutation", filtered);
    }

    if (areas_equipment != null && areas_equipment.id != null) {
      let filtered = state.filteredAll.filter((obj) => {
        return obj.areas_equipment.area_id === areas_equipment.id;
      });
      commit("setFilteredAllMutation", filtered);
    }

    if (start != null && start !== "") {
      let filtered = state.filteredAll.filter((obj) => {
        let start_selected = moment(start, "YYYY-MM-DD");
        let team_start = moment(obj.start, "DD/MM/YYYY");
        if (team_start.diff(start_selected) >= 0) {
          return obj;
        }
      });
      commit("setFilteredAllMutation", filtered);
    }

    if (closing != null && closing !== "") {
      let filtered = state.filteredAll.filter((obj) => {
        let closing_selected = moment(closing, "YYYY-MM-DD");
        let team_closing = moment(obj.closing, "DD/MM/YYYY");
        if (closing_selected.diff(team_closing) >= 0) {
          return obj;
        }
      });
      commit("setFilteredAllMutation", filtered);
    }
  },
  transferStudent: ({ commit }, payload) => {
    commit("transferStudentMutation", payload);
  },
  removeStudentFromTeam: ({ commit }, payload) => {
    commit("removeStudentFromTeamMutation", payload);
  },
  setTabTypeTeamView: async ({ commit, dispatch }, payload) => {
    commit("setTabTypeTeamViewMutation", payload);
    switch (payload) {
      case "teams_in_progress":
        await commit("filterByInProgress", true);
        break;
      case "teams_finished":
        await commit("filterByInProgress", false);
        break;
    }
    dispatch("loadTeams");
  },
  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportTeamsSpreadsheetModal: ({ commit }) => {
    if (!state.modalToggleExportTeamsSpreadsheet) {
      commit("toggleModalToggleExportTeamsSpreadsheet");
    }
  },
  closeExportTeamsSpreadsheetModal: ({ commit, dispatch }) => {
    if (state.modalToggleExportTeamsSpreadsheet) {
      commit("toggleModalToggleExportTeamsSpreadsheet");
      dispatch("SelectFieldsToExport/clearFields", null, { root: true });
    }
  },
  openExportTeamsPendingsSpreadsheetModal: ({ commit }) => {
    if (!state.modalToggleExportTeamsPendingsSpreadsheet) {
      commit("toggleModalToggleExportTeamsPendingsSpreadsheet");
    }
  },
  closeExportTeamsPendingsSpreadsheetModal: ({ commit, dispatch }) => {
    if (state.modalToggleExportTeamsPendingsSpreadsheet) {
      commit("toggleModalToggleExportTeamsPendingsSpreadsheet");
      dispatch("SelectFieldsToExport/clearFields", null, { root: true });
    }
  },
};

const getters = {
  teamById: (state) => (id) => {
    return state.all.find((team) => team.id === id);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
