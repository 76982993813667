<template>
  <div class="flex items-center ml-1 pr-1 col-span-1 tooltip">
    <button
      ref="btnRef"
      v-on:mouseenter="toggleTooltip()"
      v-on:mouseleave="toggleTooltip()"
      type="button"
      :class="buttonClass()"
    >
      <AppMaterialIconStudent :iconName="whatIsMyName()" :iconType="iconType" :iconSize="iconSize" />
    </button>
    <div
      v-if="whatIsMyName() == 'warning'"
      ref="tooltipRef"
      v-bind:class="{ hidden: !tooltipShow, block: tooltipShow }"
      class="bg-white text-center border border-black-600 mr-3 block font-normal leading-normal text-xs text-left break-words rounded-lg tooltiptext shadow-xl w-52"
    >
      <div>
        <div class="text-gray-400 font-semibold p-2">
          <span>
            Aluno(a) em risco de evasão! <br />
            Frequência abaixo de 25% !</span
          >
        </div>
      </div>
    </div>
    <div
      v-if="whatIsMyName() == 'more_vert'"
      ref="tooltipRef"
      v-bind:class="{ hidden: !tooltipShow, block: tooltipShow }"
      class="bg-white text-left border border-black-600 mr-3 block font-normal leading-normal text-sm rounded-lg tooltiptext shadow-xl w-40"
    >
      <div>
        <div>
          <ul class="text-gray-400 font-semibold p-3">
            <li><button>Transferir</button></li>
            <li><button>Desligar</button></li>
            <li><button>Remover de Turma</button></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppMaterialIconStudent from "./AppMaterialIconStudent.vue";
import { createPopper } from "@popperjs/core";

export default {
  components: { AppMaterialIconStudent },

  data() {
    return {
      tooltipShow: false,
    };
  },

  props: {
    iconName: {
      type: String,
    },
    iconType: {
      type: String,
      default: "material",
    },
    iconSize: {
      type: String,
      default: "24",
    },
    forClose: {
      type: Boolean,
      default: false,
    },
    dispatch: {
      type: String,
    },
    payload: {
      type: [String, Object],
    },
  },

  methods: {
    buttonClass() {
      let css = [];
      css.push("w-12");
      css.push("h-90");
      css.push("mr-3");
      switch (this.whatIsMyName()) {
        case "warning":
          css.push("text-yellow-400");
          break;
        default:
          css.push("text-ashes-dark");
          break;
      }

      if (!this.forClose || (this.forClose && this.iconSize === "36")) {
        css = Object.assign(css, {
          "h-10": true,
          "w-10": true,
        });
      } else {
        css = Object.assign(css, {
          "h-7": true,
          "w-7": true,
        });
      }

      return css;
    },

    whatIsMyName() {
      if (this.forClose) {
        return "close";
      }

      return this.iconName;
    },
    toggleTooltip: function () {
      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "top",
        });
      }
    },
  },
};
</script>
