import { getAxios, baseURL } from "./Api";

// export async function userIsAuthenticated() {
//   const token = localStorage.token;
//   const api = getAxios({ token }, baseURL["guardiao_api"]);

//   let url = "/users/validate_token";

//   return api.get(url);
// }

// export async function singInUser(user) {
//   const api = getAxios({}, baseURL["guardiao_api"]);

//   let url = "/users/sign_in";

//   return api.post(url, { user });
// }

export async function login(userDetail) {
  const api = getAxios({}, baseURL["virando_jogo_api_auth"]);
  return api.post("/auth/sign_in", { email: userDetail.login, password: userDetail.password });
}
