import { getPhaseById } from "../../service/PhaseApi";
import { getCourses } from "../../service/CourseApi";
import Vue from "vue";

const state = {
  courses: [],
  filteredCourses: [],
  modalDeleteCourseConfirmationOpen: false,
  courseIdForDeletion: null,
  disciplines: [],
  filter: {
    discipline: null,
    city: null,
    name: null,
    state: null,
  },
};

const mutations = {
  setCoursesMutation: (state, courses) => {
    state.courses = courses;
    state.filteredCourses = courses;
  },
  setFilteredCoursesMutation: (state, courses) => {
    state.filteredCourses = courses;
  },
  setDisciplinesMutation: (state, disciplines) => {
    state.disciplines = disciplines;
  },
  setDisciplineIdMutation: (state, discipline) => {
    state.filter.discipline = discipline;
  },
  setFilterCityMutation: (state, city) => {
    state.filter.city = city;
  },
  setFilterStateMutation: (state, value) => {
    state.filter.state = value;
  },
  setFilterNameMutation: (state, name) => {
    state.filter.name = name;
  },
  toggleModalDeleteAreaConfirmation: (state) => {
    state.modalDeleteAreaConfirmationOpen = !state.modalDeleteAreaConfirmationOpen;
  },
  addCourseMutation: (state, course) => {
    state.courses.push(course);
  },
};

const actions = {
  canAcces({ commit }, value) {
    commit("loadingMutation");
    if (localStorage.perms) {
      const permJson = JSON.parse(localStorage.perms);
      const found = permJson.find((element) => element.permit === value);
      return found.value;
    }
  },
  filterCourses: ({ commit, state }, payload) => {
    const courses = state.courses.filter((obj) => {
      if (payload) {
        return obj.discipline.id === payload.id;
      }
    });
    commit("setFilteredCoursesMutation", courses);
    commit("setDisciplineIdMutation", payload);
  },
  clearFilter: ({ commit, state }) => {
    commit("setFilteredCoursesMutation", state.courses);
    commit("setDisciplineIdMutation", null);
    commit("setFilterCityMutation", null);
    commit("setFilterStateMutation", null);
    commit("setFilterNameMutation", null);
    commit("setCoursesMutation", []);
  },
  addCourse: ({ commit }, payload) => {
    commit("addCourseMutation", payload);
  },
  setFilterCity: ({ commit }, payload) => {
    commit("setFilterCityMutation", payload);
  },
  setFilterState: ({ commit }, payload) => {
    commit("setFilterStateMutation", payload);
  },
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  loadDisciplines: ({ commit }) => {
    const permJson = JSON.parse(localStorage.perms);
    const res = permJson.find((ele) => {
      if (ele.permit === "phase_show") {
        return ele;
      }
    });
    if (res.value) {
      getPhaseById(2)
        .then((response) => {
          commit("setDisciplinesMutation", response.data.phase.disciplines);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
  loadCourses: ({ commit, rootGetters }) => {
    const params = {
      name: state.filter.name,
      city_id:
        state.filter.city && state.filter.city.id
          ? state.filter.city.id
          : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
          ? rootGetters["UtilFunctions/cityIds"]
          : null,
    };
    getCourses(params)
      .then((response) => {
        commit("setCoursesMutation", response.data.courses);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
