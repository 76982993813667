<template>
  <nav aria-label="breadcrumb" class="breadcrumb-container">
    <ol class="breadcrumb">
      <li v-for="(crumb, index) in breadcrumbs" :key="index" class="breadcrumb-item">
        <router-link v-if="index < breadcrumbs.length - 1" :to="crumb.path" class="breadcrumb-link">
          <span>{{ crumb.name }}</span>
          <i class="material-icons">chevron_right</i>
        </router-link>
        <span v-else class="breadcrumb-current">{{ crumb.name }}</span>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.breadcrumb-container {
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.breadcrumb {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  color: #007bff;
  font-weight: 500;
}

.breadcrumb-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  transition: color 0.3s;
}

.breadcrumb-link:hover {
  color: #0056b3;
}

.breadcrumb-current {
  color: #6c757d;
  font-weight: bold;
}

.material-icons {
  font-size: 18px;
  margin: 0 5px;
  color: #6c757d;
}
</style>
