<template>
  <div class="font-system" v-if="permit">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">Segmentos</div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-segment"
          showIcon
          iconName="add"
          text="Cadastrar Segmento"
          dispatch="Segments/openModalAddSegment"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-4">
          <BaseLabel
            text="Pesquisar por nome do Segmento"
            typeText="custom"
            weight="normal"
            :italic="false"
            size="xs"
          />
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por nome"
            dispatch="Segments/setFilterName"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-segments"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Segments/loadSegments"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="segments.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-segments"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-segments">
      <div class="mx-12">
        <SegmentsCards :data="segments" />
      </div>
      <div id="segments-pagination" class="mx-12 my-6 pb-3" v-if="segments.length > 0">
        <BasePagination
          :total="totalSegments"
          :total-per-page="totalPerPage"
          dispatch="Segments/setSegmentsOffset"
        />
      </div>
      <ModalSegment :open="modalToggleAddSegment" />
      <DeleteSegmentConfirmation :open="modalToggleDeleteSegment" />
    </div>
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import SegmentsCards from "../list/SegmentsCards.vue";
import BasePagination from "../../components/BasePagination.vue";
import ModalSegment from "./modals/ModalSegment.vue";
import DeleteSegmentConfirmation from "../alerts/DeleteSegmentConfirmation.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseLabel from "../../components/BaseLabel.vue";
import ProhibitedAccessPage from "../../components/ProhibitedAccessPage.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    BasePagination,
    BaseLabel,
    AppButtonColorful,
    SegmentsCards,
    DeleteSegmentConfirmation,
    BaseInput,
    ProhibitedAccessPage,
    ModalSegment,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      clearFields() {
        this.$store.dispatch("Segments/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
  },

  created() {
    this.canAcces("segment_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("segment_create").then((response) => {
      this.permitCreate = response;
    });
  },

  computed: {
    ...mapState({
      segments: (state) => state.Segments.segments,
      modalToggleAddSegment: (state) => state.Segments.modalToggleAddSegment,
      modalToggleDeleteSegment: (state) => state.Segments.modalToggleDeleteSegment,
      offset: (state) => state.Segments.segmentsOffset,
      totalPerPage: (state) => state.Segments.segmentsTotalPerPage,
      totalSegments: (state) => state.Segments.segmentsTotal,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Segments/loadSegments");
      this.backToTop();
    },
  },
};
</script>
