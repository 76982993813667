<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="articulator.name"
          mode="underline"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="lg"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="articulator.reference_name"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="articulator.cpf"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="articulator.cellphone_number"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="articulator.email"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="permitUp"
          iconName="edit"
          :payload="articulator"
          dispatch="Articulators/openModalEdit"
        />
        <AppButtonIcon
          v-if="permitDes"
          iconName="delete"
          :payload="articulator"
          dispatch="Articulators/toggleModalDeleteArticulator"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitResetPassword: false,
      permit: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    articulator: {
      type: Object,
    },
  },
  created() {
    this.articulatorAccess();
    this.articulatorChange();
    this.articulatorDelete();
    this.resetUserPassword();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    articulatorAccess() {
      this.canAcces("articulator_index").then((resp) => {
        this.permit = resp;
      });
    },
    articulatorDelete() {
      this.canAcces("articulator_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    articulatorChange() {
      this.canAcces("articulator_update").then((resp) => {
        this.permitUp = resp;
      });
    },
    resetUserPassword() {
      this.canAcces("user_reset_password").then((resp) => {
        this.permitResetPassword = resp;
      });
    },
  },
  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-4": true,
        "justify-items-stretch": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
        "cursor-pointer": true,
      };
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 2fr 1fr 1fr 1fr 2fr 1fr;
}
</style>
