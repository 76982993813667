<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <BaseText
            v-if="benefit.id"
            :text="`Editar folha de pagamento - ${benefitType}`"
            typeText="custom"
            weight="bold"
            mode="normal"
            size="2xl"
            color="ashes-dark"
          />
          <BaseText
            v-else
            :text="`Criar folha de pagamento - ${benefitType}`"
            typeText="custom"
            weight="bold"
            mode="normal"
            size="2xl"
            color="ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Benefit/closeModal" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <BaseText text="Informações Gerais" typeText="custom" weight="medium" mode="uppercase" size="sm" />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div>
          <BaseInput
            active
            input-name="date_benefit_start"
            :value="benefit.date_benefit_start"
            input-type="date"
            show-icon
            icon-name="today"
            show-label
            label-text="Período Inicial"
            required
            :error-message="getErrorMessage('date_benefit_start', benefit.date_benefit_start)"
            dispatch="Benefit/setDateBenefitStart"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="date_benefit_end"
            :value="benefit.date_benefit_end"
            input-type="date"
            show-icon
            icon-name="today"
            show-label
            label-text="Período Final"
            required
            :error-message="getErrorMessage('date_benefit_end', benefit.date_benefit_end)"
            dispatch="Benefit/setDateBenefitEnd"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            selectPlaceholder="Edição"
            :multiple-option="false"
            :options="seasons"
            :show-label="true"
            label-text="Edição"
            required
            :value="benefit.season"
            :error-message="getErrorMessage('season', benefit.season)"
            :dispatch="setSeason"
          />
        </div>
        <div>
          <BaseInput
            v-if="tabBenefitType === 'publicTransport'"
            id="public_transport_fee"
            input-name="public_transport_fee"
            input-placeholder="0,00"
            input-type="text"
            mask="#,##"
            show-label
            label-text="Tarifa"
            required
            :value="String(benefit.public_transport_fee)"
            :error-message="getErrorMessage('public_transport_fee', benefit.public_transport_fee)"
            dispatch="Benefit/setPublicTransportFee"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Benefit/closeModal" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="benefit.id"
            text="salvar"
            typeButton="success"
            dispatch="Benefit/updateBenefit"
          />
          <AppButtonEmpty v-else text="criar" typeButton="success" dispatch="Benefit/saveBenefit" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseText from "../components/BaseText.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseModal,
    BaseInput,
    BaseText,
    AppButtonIcon,
    AppButtonEmpty,
    Multiselect,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      getErrorMessage: "Benefit/getErrorMessage",
    }),

    ...mapState({
      benefit: (state) => state.Benefit.benefit,
      seasons: (state) => state.Benefits.seasons,
      tabBenefitType: (state) => state.Benefits.tabBenefitType,
    }),

    benefitType() {
      return this.tabBenefitType === "scholarship" ? "Bolsa" : "Transporte";
    },
  },

  methods: {
    ...mapActions({
      setSeason: "Benefit/setSeason",
    }),
  },
};
</script>
