<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="area.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="area.city.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="area.qtdNeighborhoods + ' bairros'"
          typeText="custom"
          :italic="false"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="area.qtdEquipment + ' equipamentos'"
          typeText="custom"
          :italic="false"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon iconName="edit" dispatch="Area/editModal" v-if="permitUp" :payload="area" />
        <AppButtonIcon
          iconName="delete"
          :payload="area"
          v-if="permitDes"
          dispatch="Areas/openCloseDeleteAreaConfirmation"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    area: {
      type: Object,
    },
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
    }),
    areaAcces() {
      this.canAcces("area_index").then((resp) => {
        this.permit = resp;
      });
    },
    areaDelete() {
      this.canAcces("area_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    areaChange() {
      this.canAcces("area_update").then((resp) => {
        this.permitUp = resp;
      });
    },
  },

  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-4": true,
        "justify-items-stretch": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
        "cursor-pointer": true,
      };
    },
  },
  created() {
    this.areaAcces();
    this.areaChange();
    this.areaDelete();
  },
};
</script>
<style scoped>
.grid-cols-custom {
  grid-template-columns: 18% 20% 19% 38% 3%;
}
</style>
