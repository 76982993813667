<template>
  <div class="m-5">
    <div id="person-data" class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-5">
        <BaseText text="Dados do Encaminhamento" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Nome do Participante"
          :text="inscription.name"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Área"
          :text="inscription.neighborhood.area.name"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Data do Encaminhamento"
          :text="formattedDate(forwarding_reservation_registration.forwarding_date)"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Empresa"
          :text="company ? company.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Parceiro"
          :text="partner ? partner.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppTextField from "../../components/AppTextField.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppTextField,
  },
  computed: {
    ...mapState({
      forwarding_reservation_registration: (state) =>
        state.ForwardingReservationRegistrations.forwarding_reservation_registration,
      inscription: (state) => state.ForwardingReservationRegistrations.inscription,
      season: (state) => state.ForwardingReservationRegistrations.season,
      company: (state) => state.ForwardingReservationRegistrations.company,
      partner: (state) => state.ForwardingReservationRegistrations.partner,
    }),
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
  },
};
</script>
<style>
.active_new {
  background-color: #fff7ed;
  color: #fff;
  font-size: 1.1em;
}
</style>
