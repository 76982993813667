<template>
  <BaseCard class="cursor-pointer">
    <div class="grid grid-cols-5">
      <div class="mt-4 col-span-2">
        <BaseText
          :text="benefit.description"
          mode="underline"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="xl"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="`${benefit.student_amount}`"
          mode="normal"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4 ml-2">
        <BaseText
          :text="`R$ ${benefit.total}`"
          mode="normal"
          color="burnedYellow"
          typeText="custom"
          weight="extrabold"
          size="sm"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="!benefit.payday && permitUp"
          iconName="edit"
          :payload="benefit"
          dispatch="Benefit/openEditModal"
        />
        <AppButtonIcon
          v-if="!benefit.payday && permitDes"
          iconName="delete"
          :payload="benefit"
          dispatch="Benefit/openCloseModalDeleteBenefitConfirmation"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    benefit: {
      type: Object,
    },
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
    };
  },
  created() {
    this.benefitChange();
    this.benefitDelete();
    this.$store.dispatch("Areas/loadAreas");
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    benefitAcces() {
      this.canAcces("benefit_index").then((resp) => {
        this.permit = resp;
      });
    },
    benefitDelete() {
      this.canAcces("benefit_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    benefitChange() {
      this.canAcces("benefit_update").then((resp) => {
        this.permitUp = resp;
      });
    },
  },

  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-4": true,
        "justify-items-stretch": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
        "cursor-pointer": true,
      };
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 3fr 1fr 1fr 0fr;
}
</style>
