<template>
  <BaseCard :class="liClass">
    <div class="grid grid-cols-2 items-center pt-2">
      <div>
        <div class="flex justify-start items-center">
          <div class="mr-10">
            <input
              :id="team.code"
              type="checkbox"
              name="teamsAllocations"
              :value="team.id"
              @click="onClick(team, $event)"
            />
          </div>
          <div :class="iconClass">
            <AppMaterialIcon
              iconName="book"
              iconType="outlined"
              class="place-self-center text-white"
              iconSize="36"
            />
          </div>
          <BaseText
            :text="team.code"
            typeText="custom"
            mode="underline"
            weight="bold"
            size="xl"
            class="mr-1"
          />
          <BaseText text="|" typeText="custom" weight="normal" size="2xl" class="mr-1" />
          <BaseText
            :text="team.discipline.name"
            typeText="custom"
            mode="underline"
            weight="bold"
            size="xl"
            class="mr-1"
          />
        </div>
      </div>
      <div class="justify-self-end">
        <div class="grid grid-cols-2">
          <div>
            <!-- VALE TRANSPORTE -->
            <BaseText v-if="checkBenefitType" text="5" typeText="custom" size="sm" color="ashes-dark" />
            <BaseText v-else text="20" typeText="custom" size="sm" color="ashes-dark" />
          </div>
          <div>
            <BaseText
              :text="`${team.start} - ${team.closing}`"
              typeText="custom"
              size="sm"
              color="ashes-dark"
            />
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import BaseText from "./BaseText.vue";
import AppMaterialIcon from "./AppMaterialIcon.vue";

export default {
  components: {
    BaseCard,
    BaseText,
    AppMaterialIcon,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    team: {
      type: Object,
    },
    benefit_type: String,
    dispatch: String,
  },

  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        "p-6": true,
      };
    },
    iconClass() {
      let css = [];
      let bgColor = this.team.discipline.id == 1 ? "bg-yellow-300" : "bg-burnedYellow-classic";

      css.push(bgColor);
      css.push("rounded-full");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");
      css.push("mr-3");
      css.push("items-center");
      css.push("justify-center");
      css.push("min-w-1");

      return css;
    },
    checkBenefitType() {
      return this.benefit_type == "vale-transporte" ? true : false;
    },
  },
  methods: {
    onClick(item) {
      if (this.dispatch) {
        this.$store.dispatch(this.dispatch, item);
      }
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 45% 15% 10% 15% 10%;
}
.min-w-1 {
  min-width: 3rem;
}
</style>
