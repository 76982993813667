<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid 4">
        <div class="justify-self-start">
          <Label
            text="Atualizar Valores de Todos os Beneficiários"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="PaymentSheets/closeModalUpdateAllBeneficiariesValues" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Valor Padrão"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2">
          <BaseInput
            active
            input-name="value_beneficiaries"
            input-type="number"
            icon-name="search"
            showIcon
            show-label
            inputMin="1"
            inputMax="10000000000000"
            :value="value_for_update_all"
            label-text="Valor Crédito"
            input-placeholder="Insira o valor do crédito"
            dispatch="Beneficiaries/setValueForUpdateAllMutation"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="Beneficiaries/closeModalUpdateAllBeneficiariesValues"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            text="Salvar"
            typeButton="success"
            dispatch="Beneficiaries/updateAllBeneficiariesValue"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";
import Label from "@/components/Label.vue";
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import BaseInput from "@/components/forms/BaseInput.vue";

export default {
  components: {
    BaseInput,
    BaseModal,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      payment_sheet: (state) => state.PaymentSheets.payment_sheet,
      scholarship_matrix: (state) => state.PaymentSheets.scholarship_matrix,
      installment: (state) => state.PaymentSheets.installment,
      remittance: (state) => state.PaymentSheets.remittance,
      value_for_update_all: (state) => state.Beneficiaries.value_for_update_all,
    }),
    ...mapGetters({
      getErrorMessage: "PaymentSheets/getErrorMessage",
    }),
  },
  methods: {
    ...mapActions({
      setStatusForUpdateAll: "Beneficiaries/setStatusForUpdateAll",
    }),
  },
};
</script>
