import {
  getMatrixSituations,
  getMatrixSituationById,
  createMatrixSituation,
  updateMatrixSituation,
  destroyMatrixSituation,
} from "../../service/MatrixSituationsApi";
import { getMatrixProblemsForMenus } from "../../service/MatrixProblemsApi";
import Vue from "vue";

const state = {
  matrix_situation: {
    id: null,
    situation: null,
    matrix_problem_id: null,
    impact: null,
    impact_level: null,
    situation_risk: null,
  },
  matrix_situations: [],
  matrix_problems: [],

  matrix_problem: null,
  situation_risk: null,
  impact_level: null,

  modalToggleMatrixSituations: false,
  modalToggleViewMatrixSituations: false,
  modalConfirmDeleteMatrixSituation: false,

  matrixSituationId: 0,

  matrixSituationTotal: 0,
  matrixSituationTotalPerPage: 10,
  matrixSituationOffset: 0,

  params: {
    situation: null,
    impact: null,
    situation_risk: null,
    impact_level: null,
    problem: null,
  },
};

const mutations = {
  // --> MUTATIONS - DATA COMING FROM API <--
  setMatrixSituationsMutation: (state, matrix_situations) => {
    state.matrix_situations = matrix_situations;
  },
  setMatrixProblemsMutation: (state, matrix_problems) => {
    state.matrix_problems = matrix_problems;
  },

  // --> MUTATIONS - FIELDS TO THE TABLE <--
  setIdMutation: (state, id) => {
    state.matrix_situation.id = id;
  },
  setSituationMutation: (state, situation) => {
    state.matrix_situation.situation = situation;
  },
  setImpactMutation: (state, impact) => {
    state.matrix_situation.impact = impact;
  },
  setImpactLevelMutation: (state, impact_level) => {
    state.impact_level = impact_level;
    state.matrix_situation.impact_level = impact_level ? impact_level.name : null;
  },
  setSituationRiskMutation: (state, situation_risk) => {
    state.situation_risk = situation_risk;
    state.matrix_situation.situation_risk = situation_risk ? situation_risk.name : null;
  },
  setMatrixProblemMutation: (state, matrix_problem) => {
    state.matrix_problem = matrix_problem;
    state.matrix_situation.matrix_problem_id = matrix_problem ? matrix_problem.id : null;
  },

  // --> MUTATIONS - PAGINATION <--
  setMatrixSituationTotalPerPage: (state, matrixSituationTotalPerPage) => {
    state.matrixSituationTotalPerPage = matrixSituationTotalPerPage;
  },
  setMatrixSituationOffset: (state, matrixSituationOffset) => {
    state.matrixSituationOffset = matrixSituationOffset;
  },
  setMatrixSituationTotal: (state, matrixSituationTotal) => {
    state.matrixSituationTotal = matrixSituationTotal;
  },

  // --> MUTATIONS - FILTERS <--
  filterListBySituation: (state, input) => {
    state.params.situation = input.value;
  },
  filterListBySituationRisk: (state, input) => {
    state.params.situation_risk = input;
  },
  filterListByImpact: (state, input) => {
    state.params.impact = input.value;
  },
  filterListByImpactLevel: (state, input) => {
    state.params.impact_level = input;
  },
  filterListByProblem: (state, input) => {
    state.params.problem = input;
  },

  // --> MUTATIONS - TOGGLES FROM MODALS AND SCREENS <--
  toggleModalMatrixSituations: (state) => {
    state.modalToggleMatrixSituations = !state.modalToggleMatrixSituations;
  },
  toggleModalViewMatrixSituations: (state) => {
    state.modalToggleViewMatrixSituations = !state.modalToggleViewMatrixSituations;
  },
  toggleModalDeleteMatrixSituation: (state) => {
    state.modalConfirmDeleteMatrixSituation = !state.modalConfirmDeleteMatrixSituation;
  },

  // --> MUTATIONS - DELETION <--
  matrixSituationForDeletion: (state, payload) => {
    if (payload) {
      state.matrixSituationId = payload.id;
    }
  },

  // --> MUTATIONS - SPREADSHEET <--

  // --> MUTATIONS - CLEARS <--
  clearQuery: (state) => {
    state.params.situation = null;
    state.params.situation_risk = null;
    state.params.impact = null;
    state.params.impact_level = null;
    state.params.problem = null;
    state.matrix_situations = [];
  },
  clearFields: (state) => {
    state.matrix_situation.id = null;
    state.matrix_situation.situation = null;
    state.matrix_situation.matrix_problem_id = null;
    state.matrix_situation.impact = null;
    state.matrix_situation.impact_level = null;
    state.matrix_situation.situation_risk = null;
    state.matrix_problem = null;
    state.situation_risk = null;
    state.impact_level = null;
  },
};

const actions = {
  // --> ACTIONS & LOADS - DATA COMING FROM API <--
  loadMatrixSituations: ({ commit }, from) => {
    const params = {
      situation: state.params.situation,
      situation_risk: state.params.situation_risk ? state.params.situation_risk.name : null,
      impact: state.params.impact,
      impact_level: state.params.impact_level ? state.params.impact_level.name : null,
      problem: state.params.problem ? state.params.problem.name : null,
      perPage: state.matrixSituationTotalPerPage,
      offset: state.matrixSituationOffset,
      by: "matrix_problems.name",
      order: "ASC",
    };

    getMatrixSituations(params)
      .then((response) => {
        commit("setMatrixSituationsMutation", response.data.matrix_situations);
        commit("setMatrixSituationTotal", response.data.matrix_situations_total);
        if (parseInt(response.data.matrix_situations_total, 10) < 1 && from != "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadMatrixProblems: ({ commit }) => {
    const params = {
      by: "matrix_problems.name",
      order: "ASC",
    };
    getMatrixProblemsForMenus(params)
      .then((response) => {
        commit("setMatrixProblemsMutation", response.data.matrix_problems);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getMatrixSituationById(payload.id)
      .then((response) => {
        let situation_risk = {
          id: null,
          name: response.data.matrix_situation.situation_risk,
        };

        let impact_level = {
          id: null,
          name: response.data.matrix_situation.impact_level,
        };
        commit("setIdMutation", response.data.matrix_situation.id);
        commit("setSituationMutation", response.data.matrix_situation.situation);
        commit("setImpactMutation", response.data.matrix_situation.impact);
        commit("setMatrixProblemMutation", response.data.matrix_situation.matrix_problem);

        switch (response.data.matrix_situation.situation_risk) {
          case "Gravíssima":
            situation_risk.id = 0;
            break;
          case "Grave":
            situation_risk.id = 1;
            break;
          case "Moderada":
            situation_risk.id = 2;
            break;
        }
        commit("setSituationRiskMutation", situation_risk);

        switch (response.data.matrix_situation.impact_level) {
          case "Alto":
            impact_level.id = 0;
            break;
          case "Médio":
            impact_level.id = 1;
            break;
          case "Baixo":
            impact_level.id = 2;
            break;
        }
        commit("setImpactLevelMutation", impact_level);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },

  // --> ACTIONS - FILTERS <--
  filterListBySituation: ({ commit }, payload) => {
    commit("filterListBySituation", payload);
  },
  filterListBySituationRisk: ({ commit }, payload) => {
    commit("filterListBySituationRisk", payload);
  },
  filterListByImpact: ({ commit }, payload) => {
    commit("filterListByImpact", payload);
  },
  filterListByImpactLevel: ({ commit }, payload) => {
    commit("filterListByImpactLevel", payload);
  },
  filterListByProblem: ({ commit }, payload) => {
    commit("filterListByProblem", payload);
  },

  // --> ACTIONS - CLEAR <--
  clearQuery: ({ commit }, payload) => {
    commit("clearQuery", payload);
  },

  // --> ACTIONS - TOGGLES FROM MODALS AND SCREENS <--
  toggleModalMatrixSituations: ({ commit }) => {
    commit("toggleModalMatrixSituations");
  },
  openModalMatrixSituations: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalMatrixSituations");
  },
  closeModalMatrixSituations: ({ commit }) => {
    commit("toggleModalMatrixSituations");
    commit("clearFields");
  },
  openEditModalMatrixSituations: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalMatrixSituations");
  },
  closeEditModalMatrixSituations: ({ commit }) => {
    commit("toggleModalMatrixSituations");
    commit("clearFields");
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewMatrixSituations");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewMatrixSituations");
    commit("clearFields");
  },
  toggleModalDeleteMatrixSituation: ({ commit }, payload) => {
    commit("toggleModalDeleteMatrixSituation");
    if (state.modalConfirmDeleteMatrixSituation) {
      commit("matrixSituationForDeletion", payload);
    } else {
      commit("matrixSituationForDeletion", null);
    }
  },

  // --> ACTIONS - FIELDS TO THE TABLE <--
  setSituation: ({ commit }, payload) => {
    commit("setSituationMutation", payload.value);
  },
  setImpact: ({ commit }, payload) => {
    commit("setImpactMutation", payload.value);
  },
  setImpactLevel: ({ commit }, payload) => {
    commit("setImpactLevelMutation", payload);
  },
  setSituationRisk: ({ commit }, payload) => {
    commit("setSituationRiskMutation", payload);
  },
  setMatrixProblem: ({ commit }, payload) => {
    commit("setMatrixProblemMutation", payload);
  },

  // --> ACTIONS - PAGINATION <--
  setMatrixSituationOffset: ({ commit }, payload) => {
    commit("setMatrixSituationOffset", payload.offset);
  },
  setMatrixSituationTotalPerPage: ({ commit }, payload) => {
    commit("setMatrixSituationTotalPerPage", payload.totalPerPage);
  },

  // --> ACTIONS - EXPORT SPREADSHEET <--

  // --> ACTIONS - CRUD <--
  save: ({ commit, dispatch }) => {
    createMatrixSituation(state.matrix_situation)
      .then(() => {
        Vue.$toast.success("Situação cadastrada com sucesso!");
        commit("toggleModalMatrixSituations");
        commit("clearFields");
        dispatch("loadMatrixSituations");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar a situação! " + e.response.data.error);
        console.log(e);
      });
  },
  update: ({ dispatch, commit }) => {
    updateMatrixSituation(state.matrix_situation.id, state.matrix_situation)
      .then(() => {
        Vue.$toast.success("Situação atualizada com sucesso!");
        commit("toggleModalMatrixSituations");
        dispatch("loadMatrixSituations");
        commit("clearFields");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyMatrixSituation(state.matrixSituationId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Situação deletada com sucesso!");
        commit("toggleModalDeleteMatrixSituation");
        dispatch("loadMatrixSituations", from);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
