import { getAxios, baseURL } from "./Api";

export async function getMatrixRiskStratifications() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`matriz_estratificacoes_risco`);
}

export async function getMatrixRiskStratificationsByInscription(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `matriz_estratificacoes_risco/listar_por_inscricao`;
  return api.get(url, { params: filters });
}

export async function createMatrixRiskStratification(matrix_risk_stratification) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/matriz_estratificacoes_risco", matrix_risk_stratification);
}

export async function updateMatrixRiskStratification(id, matrix_risk_stratification) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`matriz_estratificacoes_risco/${id}`, matrix_risk_stratification);
}

export async function destroyMatrixRiskStratification(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`matriz_estratificacoes_risco/${id}`);
}
