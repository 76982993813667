<template>
  <div class="grid auto-grid-auto p-10 gap-5">
    <div>
      <BaseText
        :text="`Turma ${team.code}`"
        typeText="custom"
        weight="semibold"
        :italic="false"
        size="3xl"
        color="ashes-hover"
      />
    </div>
    <div>
      <router-link :to="returnBtn()">
        <BaseText
          text="<- Voltar"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="capitalize"
          size="sm"
          color="carmesim"
        />
      </router-link>
    </div>
    <div class="grid grid-cols-2">
      <div class="grid grid-flow-col auto-cols-max gap-2">
        <div class="place-self-center">
          <div :class="iconClass">
            <AppMaterialIcon
              iconName="book"
              iconType="outlined"
              class="place-self-center text-white"
              iconSize="36"
            />
          </div>
        </div>
        <div class="gridf grid-rols-4">
          <!--<div class="text-burnedYellow">
            <BaseText
              :text="team.discipline.name"
              typeText="custom"
              weight="medium"
              :italic="false"
              size="sm"
            />
          </div>-->
          <div>
            <BaseText
              :text="
                team.discipline.phase_id == 2
                  ? team.discipline.name + ' - ' + team.course.name
                  : team.discipline.name
              "
              typeText="custom"
              weight="extrabold"
              :italic="false"
              size="xl"
            />
          </div>
          <div class="grid grid-flow-col auto-cols-max gap-2">
            <div>
              <BaseText text="Instrutor |" typeText="custom" weight="medium" :italic="false" size="sm" />
            </div>
            <div class="text-burnedYellow">
              <BaseText :text="getTeacherName()" typeText="custom" weight="bold" :italic="false" size="sm" />
            </div>
          </div>
          <div class="grid grid-flow-col auto-cols-max gap-2">
            <div>
              <BaseText text="Equipamento |" typeText="custom" weight="medium" :italic="false" size="sm" />
            </div>
            <div class="text-burnedYellow">
              <BaseText
                :text="getEquipmentName()"
                typeText="custom"
                weight="bold"
                :italic="false"
                size="sm"
              />
            </div>
          </div>
          <div class="grid grid-flow-col auto-cols-max gap-2">
            <div>
              <BaseText text="Turno |" typeText="custom" weight="medium" :italic="false" size="sm" />
            </div>
            <div class="text-burnedYellow">
              <BaseText :text="team.shift.name" typeText="custom" weight="bold" :italic="false" size="sm" />
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-flow-col auto-cols-max gap-2 place-items-center">
        <div>
          <BaseInput
            :active="false"
            inputName="nome"
            inputType="date"
            :required="false"
            labelText="Início"
            showLabel
            :value="formatDate(team.start)"
            :showIcon="false"
          />
        </div>
        <div>
          <BaseInput
            :active="false"
            inputName="nome"
            inputType="date"
            :required="false"
            labelText="Encerramento"
            showLabel
            :value="formatDate(team.closing)"
            :showIcon="false"
          />
        </div>
        <div class="mt-5" v-if="permit && season_date_manual_execution_end">
          <AppButtonEmpty
            v-if="showButtonNewTeam(discipline)"
            text=" >> Ação Comunitária"
            typeButton="primary"
            dispatch="TeamAllocate/openModal"
          />
        </div>
        <div class="flex flex-wrap" v-if="team.discipline.id == 1 && season_date_manual_execution_end">
          <div class="w-full" v-if="permit">
            <button
              ref="btnRef"
              v-on:mouseenter="toggleTooltip()"
              v-on:mouseleave="toggleTooltip()"
              class="uppercase rounded outline-none ease-linear transition-all duration-150"
            >
              <span class="material-icons mt-6 text-burnedYellow">
                <span class="material-icons"> info </span>
              </span>
            </button>
            <div
              ref="tooltipRef"
              v-bind:class="{ hidden: !tooltipShow, block: tooltipShow }"
              class="bg-burnedYellow border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
            >
              <div>
                <div
                  class="text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg"
                >
                  Ao clicar em “>> Ação Comunitária“ você encerrará esta turma de Formação Cidadã e a
                  transformará em uma turma de Ação Comunitária. Todos os alunos desta turma de Formação
                  Cidadã irão se manter na turma de Ação Comunitária..
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <AppButtonEmpty
            v-if="showButtonFinishTeam(discipline) && team.in_progress && permitFinishTeam"
            text="Encerrar"
            typeButton="primary"
            dispatch="Team/finishTeam"
          />
          <AppButtonEmpty
            v-if="!team.in_progress && permitReactivateTeam"
            text="Reativar"
            typeButton="primary"
            dispatch="Team/reactivateTeam"
          />
        </div>
        <!-- <div class="mt-5"><AppButtonIcon iconName="edit" /></div> -->
      </div>
    </div>
    <div class="grid grid-flow-col auto-cols-max">
      <div>
        <a @click="activeTab('meeting')" class="cursor-pointer">
          <BaseText
            text="Encontros"
            typeText="custom"
            weight="medium"
            :italic="false"
            mode="uppercase"
            size="lg"
            :color="colorSubmenu('meeting')"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div>
        <a @click="activeTab('student')" class="cursor-pointer">
          <BaseText
            text="Alunos"
            typeText="custom"
            weight="medium"
            :italic="false"
            mode="uppercase"
            size="lg"
            :color="colorSubmenu('student')"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
    </div>
    <Meetings v-if="page === 'meeting'" />
    <Allocations v-else />
    <ModalTeamAllocate :open="modalTeamAllocateOpen" />
    <SuspendMeetingConfirmation :open="modalSuspendMeetingConfirmationOpen" />
    <DisableSuspensionMeetingConfirmation :open="modalDisableSuspensionMeetingConfirmationOpen" />
  </div>
</template>

<script>
import BaseText from "../components/BaseText.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import AppMaterialIcon from "../components/AppMaterialIcon.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import ModalTeamAllocate from "./ModalTeamAllocate.vue";
import Meetings from "./partials/Meetings.vue";
import Allocations from "./partials/Allocations.vue";
import SuspendMeetingConfirmation from "./alerts/SuspendMeetingConfirmation.vue";
import DisableSuspensionMeetingConfirmation from "./alerts/DisableSuspensionMeetingConfirmation.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { createPopper } from "@popperjs/core";

export default {
  name: "left-pink-tooltip",
  data() {
    return {
      tooltipShow: false,
      permitUp: false,
      permitDes: false,
      permit: false,
      permitFinishTeam: false,
      permitReactivateTeam: false,
      season_date_manual_execution_end: true,
    };
  },
  components: {
    BaseText,
    AppMaterialIcon,
    BaseInput,
    AppButtonEmpty,
    ModalTeamAllocate,
    Meetings,
    Allocations,
    DisableSuspensionMeetingConfirmation,
    SuspendMeetingConfirmation,
  },
  created() {
    this.permitStartCommunitAcion();
    let id = this.$route.params.id;
    const team = this.getTeamById(id);

    if (team) {
      this.$store.dispatch("Team/load", team);
    } else {
      this.$store.dispatch("Team/showTeam", id);
    }
    this.$store.dispatch("Meetings/loadMeetings", id);
    this.canAcces("team_finish_team").then((response) => {
      this.permitFinishTeam = response;
    });
    this.canAcces("team_reactivate_team").then((response) => {
      this.permitReactivateTeam = response;
    });
    this.getSeasonDateManualExecutionEnd();
  },
  computed: {
    ...mapGetters({
      getTeamById: "Teams/teamById",
    }),
    ...mapState({
      // role: (state) => state.Session.role.name,
      team: (state) => state.Team,
      discipline: (state) => state.Team.discipline,
      modalTeamAllocateOpen: (state) => state.TeamAllocate.modalTeamAllocateOpen,
      page: (state) => state.Team.page,
      seasonId: (state) => state.Team.season?.id,
      season: (state) => state.Seasons.season,
      alunos: (state) => state.Allocation.students,
      profileInfo: (state) => state.AccessControl.profileInfo,
      modalSuspendMeetingConfirmationOpen: (state) => state.Meeting.modalSuspendMeetingConfirmationOpen,
      modalDisableSuspensionMeetingConfirmationOpen: (state) =>
        state.Meeting.modalDisableSuspensionMeetingConfirmationOpen,
    }),
    iconClass() {
      let css = [];
      let bgColor = "";
      if (this.team.discipline.id == 1) {
        bgColor = "bg-yellow-300";
      }
      if (this.team.discipline.id == 2) {
        bgColor = "bg-burnedYellow-classic";
      }
      if (this.team.discipline.id == 3) {
        bgColor = "bg-green-primary";
      }
      if (this.team.discipline.id == 4) {
        bgColor = "bg-blue-primary";
      }
      if (this.team.discipline.id == 5) {
        bgColor = "bg-rose-primary";
      }

      css.push(bgColor);
      css.push("rounded-full");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");

      return css;
    },
  },
  // updated: function () {
  //   this.exportToExcel();
  // },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    permitStartCommunitAcion() {
      this.canAcces("team_update").then((resp) => {
        this.permit = resp;
      });
    },
    // permitFinishTeam() {
    //   const role = this.$store.state.Session.role.name;
    //   if (role == "Administrador" || role == "Gerente de Área") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    returnBtn() {
      if (this.profileInfo.description === "Instrutor") return `/turmas`;
      else return `/edicao/${this.seasonId}`;
    },
    formatDate(date) {
      return date?.split("/").reverse().join("-");
    },
    colorSubmenu(pageMenu) {
      return this.page === pageMenu ? "carmesim" : "ashes-hover";
    },
    activeTab(page) {
      this.$store.dispatch("Team/changePage", page);
    },
    showButtonNewTeam(discipline) {
      return ["F1M1"].includes(discipline?.code);
    },
    showButtonFinishTeam(discipline) {
      return !["F1M1"].includes(discipline?.code);
    },
    toggleTooltip: function () {
      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "left",
        });
      }
    },
    getEquipmentName() {
      return this.team.equipment ? this.team.equipment.name : "Equipamento não informado";
    },
    getTeacherName() {
      return this.team.teacher ? this.team.teacher.name : "Instrutor não informado";
    },
    getSeasonDateManualExecutionEnd() {
      this.season_date_manual_execution_end =
        localStorage.getItem("season_date_manual_execution_end") === "null";
    },
  },
};
</script>
<style>
.bg-rose-primary {
  background-color: #f04d63;
}
.bg-green-primary {
  background-color: #2e8535;
}
.bg-blue-primary {
  background-color: #65c6db;
}
</style>
