<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="scholarship_matrix.id"
            text="Editar Matriz"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Criar Matriz"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="ScholarshipMatrices/closeModalAddScholarshipMatrix" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2">
          <BaseInput
            id="Nome"
            input-name="name"
            input-type="text"
            show-label
            required
            label-text="Nome"
            :value="scholarship_matrix.name"
            dispatch="ScholarshipMatrices/setName"
            :error-message="getErrorMessage('nome')"
          />
        </div>
      </div>
      <div class="grid grid-cols-1 gap-4 mt-4">
        <div class="col-span-1 text-burnedYellow pb-2">
          <Label
            text="Intervalos de Frequência e Valores"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div
          v-for="(interval, index) in visibleIntervals"
          :key="index"
          class="grid grid-cols-3 gap-4 items-center"
        >
          <BaseInput
            class="col-span-1"
            input-name="frequency_min"
            input-type="number"
            show-label
            inputMin="0"
            inputMax="100"
            required
            label-text="Frequência Mínima"
            :value="interval.frequency_min"
            :payload="{ index, field: 'frequency_min' }"
            dispatch="ScholarshipMatrices/updateInterval"
            :error-message="getErrorMessage('intervalo de frequência mínimo')"
          />
          <BaseInput
            class="col-span-1"
            input-name="frequency_max"
            input-type="number"
            show-label
            inputMin="0"
            inputMax="100"
            required
            label-text="Frequência Máxima"
            :value="interval.frequency_max"
            :payload="{ index, field: 'frequency_max' }"
            dispatch="ScholarshipMatrices/updateInterval"
            :error-message="getErrorMessage('intervalo de frequência máximo')"
          />
          <div class="col-span-1 flex items-end">
            <BaseInput
              input-name="value"
              input-type="number"
              show-label
              inputMin="0"
              required
              label-text="Valor"
              :value="interval.value"
              :payload="{ index, field: 'value' }"
              dispatch="ScholarshipMatrices/updateInterval"
              class="flex-grow"
              :error-message="getErrorMessage('valor')"
            />
            <a
              v-if="index >= 1"
              href="javascript:void(0)"
              @click="removeInterval(index)"
              class="ml-2 text-burnedYellow underline"
            >
              <AppMaterialIcon icon-name="delete" icon-size="36" />
            </a>
          </div>
        </div>
        <div class="text-left mt-4">
          <a href="javascript:void(0)" class="text-burnedYellow underline" @click="addInterval">
            <AppMaterialIcon icon-name="add_circle" icon-size="36" class="align-bottom" />
          </a>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="ScholarshipMatrices/closeModalAddScholarshipMatrix"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="scholarship_matrix.id"
            text="Atualizar"
            typeButton="success"
            dispatch="ScholarshipMatrices/update"
          />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="ScholarshipMatrices/save" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../../components/BaseModal.vue";
import BaseInput from "../../../components/forms/BaseInput.vue";
import Label from "../../../components/Label.vue";
import AppButtonIcon from "../../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../../components/AppButtonEmpty.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import AppMaterialIcon from "@/components/AppMaterialIcon.vue";

export default {
  components: {
    AppMaterialIcon,
    BaseModal,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      scholarship_matrix: (state) => state.ScholarshipMatrices.scholarship_matrix,
    }),
    ...mapGetters({
      getErrorMessage: "ScholarshipMatrices/getErrorMessage",
    }),
    visibleIntervals() {
      return this.scholarship_matrix.scholarship_matrix_intervals_attributes.filter(
        (interval) => !interval._destroy
      );
    },
  },
  methods: {
    ...mapActions({
      addInterval: "ScholarshipMatrices/addInterval",
      updateInterval: "ScholarshipMatrices/updateInterval",
    }),
    removeInterval(index) {
      this.$store.dispatch("ScholarshipMatrices/removeInterval", index);
    },
  },
};
</script>
