<template>
  <div class="font-system" v-if="permit">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">Municípios</div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-city"
          showIcon
          iconName="add"
          text="Cadastrar Município"
          dispatch="City/openModalAddCity"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-2">
          <BaseLabel
            text="Pesquisar por nome do Município"
            typeText="custom"
            weight="normal"
            :italic="false"
            size="xs"
          />
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por nome"
            dispatch="City/setFilterName"
            :value="filter_params.name"
          />
        </div>
        <div>
          <Multiselect
            id="filterState"
            selectPlaceholder="Selecionar Estado"
            label-text="Selecionar Estado"
            class="mr-5"
            closeOnSelect
            show-label
            :multipleOption="false"
            :options="states"
            :value="state"
            :dispatch="setFilterState"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-cities"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="City/loadCities"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="cities?.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-cities"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-cities">
      <div class="mx-12">
        <CityCards :data="cities" />
      </div>
      <div id="cities-pagination" class="mx-12 my-6 pb-3" v-if="cities?.length > 0">
        <BasePagination :total="totalCities" :total-per-page="totalPerPage" dispatch="City/setCitiesOffset" />
      </div>
      <ModalCity :open="modalToggleAddCity" />
      <DeleteCityConfirmation :open="modalToggleDeleteCity" />
    </div>
  </div>
</template>

<script>
import BasePagination from "@/components/BasePagination.vue";
import AppButtonColorful from "@/components/AppButtonColorful.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import BaseLabel from "@/components/BaseLabel.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import CityCards from "@/views/list/CityCards.vue";
import ModalCity from "@/views/ModalCity.vue";
import DeleteCityConfirmation from "./alerts/DeleteCityConfirmation.vue";

export default {
  components: {
    DeleteCityConfirmation,
    ModalCity,
    CityCards,
    Multiselect,
    FilterComponent,
    BasePagination,
    BaseLabel,
    AppButtonColorful,
    BaseInput,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
      permitExportSpreadsheet: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterState: "City/setFilterState",
      clearFields() {
        this.$store.dispatch("City/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
  },

  created() {
    this.canAcces("city_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("city_create").then((response) => {
      this.permitCreate = response;
    });
    this.$store.dispatch("UtilFunctions/loadStates");
  },

  computed: {
    ...mapState({
      cities: (state) => state.City.cities,
      filter_params: (state) => state.City.params,
      modalToggleAddCity: (state) => state.City.modalToggleAddCity,
      modalToggleDeleteCity: (state) => state.City.modalToggleDeleteCity,
      offset: (state) => state.City.citiesOffset,
      totalPerPage: (state) => state.City.citiesTotalPerPage,
      totalCities: (state) => state.City.citiesTotal,
      state: (state) => state.City.params.state,
      states: (state) => state.UtilFunctions.states,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("City/loadCities");
      this.backToTop();
    },
    state: function () {
      if (this.state != null) {
        const payload = {
          state: this.state,
        };
        this.$store.dispatch("UtilFunctions/loadCities", payload);
      }
    },
  },
};
</script>
