import { destroyMeeting, getMeetingByTeam } from "../../service/MeetingApi";
import Vue from "vue";

const state = {
  meetings: [],
  filterMettings: [],
  modalDeleteMeetingConfirmationOpen: false,
  team_id: null,
  meetingForDeletation_id: null,
};

const mutations = {
  setMeetings: (state, meetings) => {
    state.meetings = meetings;
    state.filterMettings = meetings;
  },
  filterList: (state, input) => {
    let meetings = state.meetings;
    state.filterAreas = meetings.filter((obj) => {
      return obj.name.toLowerCase().indexOf(input.value.toLowerCase()) > -1;
    });
  },
  toggleModalDeleteMeetingConfirmation: (state) => {
    state.modalDeleteMeetingConfirmationOpen = !state.modalDeleteMeetingConfirmationOpen;
  },
  addMeeting: (state, meeting) => {
    state.meetings.push(meeting);
  },
  setTeam: (state, team) => {
    state.team_id = team;
  },
  meetingForDeletation: (state, team) => {
    state.meetingForDeletation_id = team;
  },
};

const actions = {
  filterList: ({ commit }, payload) => {
    commit("filterList", payload);
  },
  loadMeetings: ({ commit }, payload) => {
    let id = payload;

    commit("setTeam", id);

    getMeetingByTeam(id)
      .then((response) => {
        commit("setMeetings", response.data.meetings);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
        console.log("error", e);
      });
  },
  addMeeting: ({ commit }, payload) => {
    commit("addMeeting", payload);
  },
  openCloseDeleteMeetingConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteMeetingConfirmation");
    if (state.modalDeleteMeetingConfirmationOpen) {
      commit("meetingForDeletation", payload.id);
    } else {
      commit("meetingForDeletation", null);
    }
  },
  deleteMeeting: ({ commit, state, dispatch }) => {
    const meeting_id = state.meetingForDeletation_id;
    destroyMeeting(meeting_id)
      .then(() => {
        commit("toggleModalDeleteMeetingConfirmation");
        dispatch("loadMeetings", state.team_id);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
