<template>
  <div class="grid auto-grid-auto p-10">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-6 my-6">
      <div class="text-ashes-hover font-medium text-2xl col-span-2">
        <Label
          text="Instrutores"
          :icon="null"
          :iconType="null"
          type="text-3xl"
          weight="semibold"
          normal
          mode="normal-case"
          :class="title"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          text="Adicionar Instrutor"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="Teachers/openModalTeacher"
          v-if="permitCreate"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-6 mb-2">
      <FilterComponent>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="name"
            label-text="Filtrar por Nome do Instrutor"
            input-placeholder="Digite o Nome do Instrutor"
            dispatch="Teachers/setFilterName"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="email"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="email"
            label-text="Filtrar por Email do Instrutor"
            input-placeholder="Digite o Email do Instrutor"
            dispatch="Teachers/setFilterEmail"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="cpf"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="cpf"
            label-text="Filtrar por CPF do Instrutor"
            input-placeholder="Digite o CPF do Instrutor"
            dispatch="Teachers/setFilterCpf"
          />
        </div>
        <!--        <div class="col-span-2">-->
        <!--          <Multiselect-->
        <!--            id="filterState"-->
        <!--            selectPlaceholder="Selecionar Estado"-->
        <!--            label-text="Selecionar Estado"-->
        <!--            class="mr-5"-->
        <!--            closeOnSelect-->
        <!--            show-label-->
        <!--            :multipleOption="false"-->
        <!--            :options="states"-->
        <!--            :value="state"-->
        <!--            :dispatch="setFilterState"-->
        <!--          />-->
        <!--        </div>-->
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setFilterCity"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-6 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-teachers"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Teachers/loadTeachers"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="teachers.length > 0">
        <a href="javascript:void(0)" @click="clearQuery()">
          <AppButtonColorful
            id="btn-consult-teachers"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-teachers">
      <div class="mx-6"><TeachersCards :data="teachers" /></div>
      <div id="teachers-pagination" class="mx-12 my-6 pb-3" v-if="teachers.length > 0">
        <BasePagination
          :total="totalTeachers"
          :total-per-page="totalPerPage"
          dispatch="Teachers/setTeachersOffset"
        />
      </div>
      <ModalTeacher :open="modalTeacherOpen" />
      <DeleteTeacherConfirmation :open="modalDeleteTeacherOpen" />
      <ModalResetTeacherPassword :open="modalResetPassword" />
    </div>
  </div>
</template>

<script>
import TeachersCards from "./list/TeachersCards.vue";
import ModalTeacher from "./ModalTeacher.vue";
import ModalResetTeacherPassword from "./resets/ModalResetTeacherPassword.vue";
import DeleteTeacherConfirmation from "./alerts/DeleteTeacherConfirmation.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";
import BasePagination from "@/components/BasePagination.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import Label from "@/components/Label.vue";

export default {
  components: {
    BasePagination,
    Label,
    Multiselect,
    FilterComponent,
    AppButtonColorful,
    TeachersCards,
    ModalTeacher,
    ModalResetTeacherPassword,
    DeleteTeacherConfirmation,
    BaseInput,
  },
  data() {
    return {
      permitCreate: false,
      permit: false,
      permitUp: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterCity: "Teachers/setFilterCity",
      setFilterState: "Teachers/setFilterState",
    }),
    clearQuery() {
      this.$store.dispatch("Teachers/clearQuery");
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.$store.dispatch("Teachers/loadTeachers");
    this.$store.dispatch("Teacher/loadProfiles");
    this.$store.dispatch("UtilFunctions/loadCities");
    this.canAcces("teacher_index").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("teacher_create").then((resp) => {
      this.permitCreate = resp;
    });
    this.canAcces("teacher_update").then((resp) => {
      this.permitUp = resp;
    });
  },
  computed: {
    ...mapState({
      teachers: (state) => state.Teachers.teachers,
      modalDeleteTeacherOpen: (state) => state.Teachers.modalDeleteTeacherConfirmationOpen,
      modalTeacherOpen: (state) => state.Teachers.modalTeacherOpen,
      modalResetPassword: (state) => state.Teacher.modalResetPassword,
      name: (state) => state.Teachers.params.name,
      email: (state) => state.Teachers.params.email,
      cpf: (state) => state.Teachers.params.cpf,
      city: (state) => state.Teachers.params.city,
      state: (state) => state.Teachers.params.state,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
      offset: (state) => state.Teachers.teachersOffset,
      totalPerPage: (state) => state.Teachers.teachersTotalPerPage,
      totalTeachers: (state) => state.Teachers.teachersTotal,
    }),
    title() {
      return {
        "text-ashes-hover": true,
      };
    },
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Teachers/loadTeachers");
      this.backToTop();
    },
  },
};
</script>
