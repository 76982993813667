<template>
  <div v-if="open">
    <div id="tabs-button" class="grid grid-cols-3 justify-between p-4 items-end gap-4">
      <div>
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          select-placeholder="Atendimento"
          :multiple-option="false"
          :value="type_attendance_filtered"
          :options="psychosocial_accompaniments_types"
          :dispatch="filterListByTypeAttendance"
        />
      </div>
      <div>
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          select-placeholder="Status do Atendimento"
          :multiple-option="false"
          :value="type_status_filtered"
          :options="psychosocial_accompaniments_status"
          :dispatch="filterListByTypeStatusEvolution"
        />
      </div>
      <div class="justify-self-end" v-if="permitCreate && !togglePsychosocialAccompanimentUnaccompanied">
        <AppButtonColorful
          text="Atendimento"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="PsychosocialAccompaniments/openModalAddAttendance"
        />
      </div>
      <a
        href="javascript:void(0)"
        @click="exportToExcel()"
        v-if="psychosocial_accompaniments.length > 0 && permitExportSpreadsheet"
      >
        <img
          class="inline-block"
          src="../../assets/images/logout_black_24dp.svg"
          alt=""
          style="cursor: pointer"
        />
        <BaseText
          text="Exportar planilha"
          typeText="custom"
          :italic="false"
          size="xs"
          color="burnedYellow"
          style="cursor: pointer"
        />
      </a>
      <BaseModal
        v-if="
          psychosocial_accompaniments_evolution_spreadsheet.length > 0 &&
          modalToggleExportPsychosocialAccompanimentEvolutionSpreadsheet
        "
      >
        <template v-slot:header>
          <div class="grid grid-cols-3">
            <div class="justify-self-start col-span-2">
              <Label
                text="Exportar Planilha de Acompanhamentos"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon
                dispatch="PsychosocialAccompaniments/closeExportPsychosocialAccompanimentEvolutionSpreadsheetModal"
                forClose
              />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="PsychosocialAccompaniments/closeExportPsychosocialAccompanimentEvolutionSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data_psychosocial_evolution"
                  :before-finish="closeExportPsychosocialAccompanimentEvolutionSpreadsheetModal"
                  worksheet="Acompanhamentos"
                  name="acompanhamentos_por_inscricao.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetPsychosocialAccompanimentEvolutionResponse">
        <PreLoading />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-psychosocial_accompaniments"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="PsychosocialAccompaniments/loadPsychosocialAccompanimentsByInscription"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="psychosocial_accompaniments.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-psychosocial_accompaniments"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-psychosocials">
      <div class="mx-12">
        <PsychosocialAccompanimentEvolutionCards :data="psychosocial_accompaniments" />
      </div>
      <div
        id="psychosocial-acommpaniment-pagination"
        class="mx-12 my-6 pb-3"
        v-if="psychosocial_accompaniments.length > 0"
      >
        <BasePagination
          :total="psychosocialAccompanimentsByInscriptionTotal"
          :total-per-page="psychosocialAccompanimentsByInscriptionTotalPerPage"
          dispatch="PsychosocialAccompaniments/setPsychosocialAccompanimentByInscriptionOffset"
        />
      </div>
    </div>
    <ModalAddAttendance :open="modalToggleAddAttendance" />
    <ModalFirstPsychosocialAccompaniment :open="modalToggleFirstPsychosocialAccompaniment" />
    <ModalJustifyNonReception :open="modalTogglePsychosocialAccompanimentJustifyNonReception" />
    <ModalPsychosocialndividualMonitoring :open="modalTogglePsychosocialIndividualMonitoring" />
    <ModalPsychosocialHomeVisit :open="modalTogglePsychosocialHomeVisit" />
    <!-- <ModalMatrixRiskStratifications :open="modalToggleMatrixRiskStratifications" /> -->
    <ModalPsychosocialAccompanimentPostponeDate :open="modalTogglePsychosocialAccompanimentPostponeDate" />
    <ModalViewPsychosocialAccompanimentsByInscription
      :open="modalViewPsychosocialAccompanimentByInscription"
    />
    <DeletePsychosocialAccompanimentConfirmation :open="modalConfirmDeletePsychosocialAccompaniment" />
  </div>
</template>

<script>
import PsychosocialAccompanimentEvolutionCards from "../list/PsychosocialAccompanimentEvolutionCards.vue";
import ModalFirstPsychosocialAccompaniment from "./ModalFirstPsychosocialAccompaniment.vue";
import ModalPsychosocialndividualMonitoring from "./ModalPsychosocialndividualMonitoring.vue";
import ModalPsychosocialHomeVisit from "./ModalPsychosocialHomeVisit.vue";
import ModalPsychosocialAccompanimentPostponeDate from "./ModalPsychosocialAccompanimentPostponeDate.vue";
import ModalViewPsychosocialAccompanimentsByInscription from "../datas/ModalViewPsychosocialAccompanimentsByInscription.vue";
import BasePagination from "../../components/BasePagination.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import BaseText from "../../components/BaseText.vue";
import BaseModal from "../../components/BaseModal.vue";
import Label from "../../components/Label.vue";
import Multiselect from "../../components/forms/Multiselect.vue";
import ModalAddAttendance from "./ModalAddAttendance.vue";
import PreLoading from "../../components/PreLoading.vue";
import DeletePsychosocialAccompanimentConfirmation from "../alerts/DeletePsychosocialAccompanimentConfirmation.vue";
import { mapState, mapActions } from "vuex";
import ModalJustifyNonReception from "@/views/psychosocialaccompanimentpages/ModalJustifyNonReception.vue";

export default {
  components: {
    ModalJustifyNonReception,
    BasePagination,
    BaseModal,
    Label,
    BaseText,
    AppButtonColorful,
    AppButtonEmpty,
    AppButtonIcon,
    PsychosocialAccompanimentEvolutionCards,
    Multiselect,
    ModalAddAttendance,
    ModalFirstPsychosocialAccompaniment,
    ModalPsychosocialndividualMonitoring,
    ModalPsychosocialHomeVisit,
    ModalPsychosocialAccompanimentPostponeDate,
    ModalViewPsychosocialAccompanimentsByInscription,
    PreLoading,
    DeletePsychosocialAccompanimentConfirmation,
  },
  data() {
    return {
      permitCreate: false,
      permitExportSpreadsheet: false,
      currentPage: 1,
      json_fields: {
        "Nome do Aluno": "student_name",
        "CPF do Aluno": "student_cpf",
        "Email do Aluno": "student_email",
        "Tipo de Acompanhamento": "accompaniment_type",
        "Status do Acompanhamento": "accompaniment_status",
        "Data do Acompanhamento": "date_accompaniment",
        "Encontro aconteceu?": "meeting_happened",
        "Motivo do encontro não ter ocorrido": "meeting_not_happened_reason",
        "Formato do encontro": "meeting_format",
        "Local do encontro": "meeting_place",
        "Recurso remoto do encontro": "meeting_remote_resource",
        "Situação de Moradia": "housing_situation",
        "Estrutura do Imóvel": "housing_building_material",
        "Situação do Imóvel": "housing_building_situation",
        "Imóvel tem banheiro?": "housing_has_bathroom",
        "Tem coleta de lixo?": "housing_has_garbage_collection",
        "Fornecimento de Gás": "housing_has_gas_supply",
        "Imóvel tem saneamento básico?": "housing_has_latrine",
        "Fornecimento de Água": "housing_has_water_supply",
        "Fornecimento de Energia": "housing_has_electricity",
        "Comentários sobre moradia": "housing_situation_comments",
        "Está estudando?": "is_studying",
        "Última série estudada": "last_grade_studied",
        "Última escola em que estudou": "last_school_studied",
        "Razão para ter largado os estudos": "reason_for_dropout_study",
        "Razão para não estar estudando": "reason_for_not_studying",
        "Necessita de reinserção escolar?": "need_school_reinsertion",
        "Usa frequentemente a rede de saúde?": "frequently_uses_health_network",
        "Local de saúde que usa com frequência": "health_place_frequently",
        "Razão para utilizar a rede de saúde com frequência": "motivation_uses_health_frequently",
        "Está grávida?": "is_pregnant",
        "Está em período puerpério?": "is_puerperium",
        "Tempo de Gestação": "gestational_age",
        "Obedece medida socioeducativa?": "obey_socio_educational_measure",
        "Já obedeceu medida socioeducativa?": "obeyed_socio_educational_measure",
        "Acompanhamento vai continuar?": "accompaniment_will_continue",
        "Tem encaminhamentos vinculados?": "has_forwardings",
        "Tem situação de vulnerabilidade vinculada?": "has_matrix",
        "É atendimento espontâneo?": "spontaneous_attendance",
        "Data de Justificativa": "justification_for_non_acceptance_date",
        "Justificativa para Não Acolhimento": "justification_for_non_acceptance",
        "Usuário responsável pelo cadastro": "user",
        "Profissionais que acompanhamm o atendimento": "attendant_team",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setSeason: "PsychosocialAccompaniments/setSeason",
      filterListByTypeAttendance: "PsychosocialAccompaniments/filterListByTypeAttendance",
      filterListByTypeStatusEvolution: "PsychosocialAccompaniments/filterListByTypeStatusEvolution",
      closeExportPsychosocialAccompanimentEvolutionSpreadsheetModal:
        "PsychosocialAccompaniments/closeExportPsychosocialAccompanimentEvolutionSpreadsheetModal",
    }),
    async clearFields() {
      await this.$store.dispatch("PsychosocialAccompaniments/clearQueryEvolutionPage");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    async exportToExcel() {
      await this.$store.dispatch(
        "PsychosocialAccompaniments/loadPsychosocialAccompanimentsByInscriptionSpreadsheet"
      );
    },
  },
  created() {
    this.backToTop();
    this.$store.dispatch("PsychosocialAccompaniments/loadPsychosocialAccompanimentsByInscription");
    this.canAcces("psychosocial_accompaniment_create").then((response) => {
      this.permitCreate = response;
    });
    this.canAcces("psychosocial_filter_by_inscription_spreadsheet").then((response) => {
      this.permitExportSpreadsheet = response;
    });

    this.$store.dispatch("PsychosocialAccompaniments/loadPsychosocialAccompanimentsStatus");
    const inscription_id = sessionStorage.inscription_id;
    this.$store.dispatch(
      "PsychosocialAccompaniments/loadInscriptionByIdForPsychosocialAccompaniment",
      inscription_id
    );

    let status = {
      id: 0,
      name: "Encaminhado",
    };
    this.$store.dispatch("Forwardings/setForwardingStatus", status);
    this.$store.dispatch("Forwardings/loadForwardingPolicies");

    this.$store.dispatch("PsychosocialAccompaniments/loadHousingSituations");
    this.$store.dispatch("PsychosocialAccompaniments/loadHousingBuildingSituations");
    this.$store.dispatch("PsychosocialAccompaniments/loadHousingBuildingMaterials");
    this.$store.dispatch("PsychosocialAccompaniments/loadHousingHasElectricities");
    this.$store.dispatch("PsychosocialAccompaniments/loadHousingHasWaterSupplies");
    this.$store.dispatch("PsychosocialAccompaniments/loadHousingHasGasSupplies");
    this.$store.dispatch("PsychosocialAccompaniments/loadHousingBathroomDrainTypes");
    this.$store.dispatch("PsychosocialAccompaniments/loadMeetingNotHappenedReasons");
    this.$store.dispatch("PsychosocialAccompaniments/loadSocioEducationalMeasureSituations");
    this.$store.dispatch("PsychosocialAccompaniments/loadGenders");
    this.$store.dispatch("PsychosocialAccompaniments/loadRelationshipDegrees");
    this.$store.dispatch("PsychosocialAccompaniments/loadPsychosocialAttendantsForPsychosocial");
    this.$store.dispatch("PsychosocialAccompaniments/loadIdentityGendersOptions");
    this.$store.dispatch("Inscription/load", this.inscription);
    // this.$store.dispatch("Student/load", this.inscription.student);
    console.log("this.inscription", this.inscription);
  },

  computed: {
    ...mapState({
      psychosocial_accompaniments: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniments,
      modalConfirmDeletePsychosocialAccompaniment: (state) =>
        state.PsychosocialAccompaniments.modalConfirmDeletePsychosocialAccompaniment,
      modalTogglePsychosocialAccompaniments: (state) =>
        state.PsychosocialAccompaniments.modalTogglePsychosocialAccompaniments,
      modalViewPsychosocialAccompaniment: (state) => state.PsychosocialAccompaniments.modalViewForwarding,
      city: (state) => state.City.city,
      seasons: (state) => state.PsychosocialAccompaniments.seasons,
      season: (state) => state.PsychosocialAccompaniments.season,
      inscriptions: (state) => state.PsychosocialAccompaniments.inscriptions,
      inscription: (state) => state.PsychosocialAccompaniments.inscription,
      type_attendance_filtered: (state) =>
        state.PsychosocialAccompaniments.params.evolution_page.type_attendance,
      type_status_filtered: (state) => state.PsychosocialAccompaniments.params.evolution_page.type_status,

      tabPsychosocialAccompanimentView: (state) =>
        state.PsychosocialAccompaniments.tabPsychosocialAccompanimentView,
      togglePsychosocialAccompanimentPendingReception: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentPendingReception,
      togglePsychosocialAccompanimentInAccompaniment: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentInAccompaniment,

      togglePsychosocialAccompanimentUnaccompanied: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentUnaccompanied,

      psychosocial_accompaniments_evolution_spreadsheet: (state) =>
        state.PsychosocialAccompaniments.psychosocial_accompaniments_evolution_spreadsheet,
      json_data_psychosocial_evolution: (state) =>
        state.PsychosocialAccompaniments.json_data_psychosocial_evolution,
      hasSpreadsheetPsychosocialAccompanimentEvolutionResponse: (state) =>
        state.PsychosocialAccompaniments.hasSpreadsheetPsychosocialAccompanimentEvolutionResponse,

      modalToggleAddAttendance: (state) => state.PsychosocialAccompaniments.modalToggleAddAttendance,
      modalToggleFirstPsychosocialAccompaniment: (state) =>
        state.PsychosocialAccompaniments.modalToggleFirstPsychosocialAccompaniment,
      modalTogglePsychosocialAccompanimentJustifyNonReception: (state) =>
        state.PsychosocialAccompaniments.modalTogglePsychosocialAccompanimentJustifyNonReception,
      modalTogglePsychosocialIndividualMonitoring: (state) =>
        state.PsychosocialAccompaniments.modalTogglePsychosocialIndividualMonitoring,
      modalTogglePsychosocialHomeVisit: (state) =>
        state.PsychosocialAccompaniments.modalTogglePsychosocialHomeVisit,
      modalToggleExportPsychosocialAccompanimentEvolutionSpreadsheet: (state) =>
        state.PsychosocialAccompaniments.modalToggleExportPsychosocialAccompanimentEvolutionSpreadsheet,
      modalToggleMatrixRiskStratifications: (state) =>
        state.PsychosocialAccompaniments.modalToggleMatrixRiskStratifications,
      modalTogglePsychosocialAccompanimentPostponeDate: (state) =>
        state.PsychosocialAccompaniments.modalTogglePsychosocialAccompanimentPostponeDate,
      modalViewPsychosocialAccompanimentByInscription: (state) =>
        state.PsychosocialAccompaniments.modalViewPsychosocialAccompanimentByInscription,

      psychosocialAccompanimentsByInscriptionOffset: (state) =>
        state.PsychosocialAccompaniments.psychosocialAccompanimentsByInscriptionOffset,
      psychosocialAccompanimentsByInscriptionTotalPerPage: (state) =>
        state.PsychosocialAccompaniments.psychosocialAccompanimentsByInscriptionTotalPerPage,
      psychosocialAccompanimentsByInscriptionTotal: (state) =>
        state.PsychosocialAccompaniments.psychosocialAccompanimentsByInscriptionTotal,
      psychosocial_accompaniments_types: (state) =>
        state.PsychosocialAccompaniments.psychosocial_accompaniments_types,
      psychosocial_accompaniments_status: (state) =>
        state.PsychosocialAccompaniments.psychosocial_accompaniments_status,
    }),
  },
  watch: {
    psychosocialAccompanimentsByInscriptionOffset: function () {
      this.$store.dispatch("PsychosocialAccompaniments/loadPsychosocialAccompanimentsByInscription");
      this.backToTop();
    },
  },
};
</script>
