import { getAxios, baseURL } from "./Api";

export async function getMenus(uId) {
  const api = getAxios({}, baseURL["virando_jogo_api"]);
  return api.post(`/usuarios/${uId}/user_menu`);
}

export async function can(uId, action) {
  const api = getAxios({}, baseURL["virando_jogo_api"]);
  return api.post(`/usuarios/${uId}/can`, { action_code: action });
}

export async function logOut() {
  const api = getAxios({}, baseURL["virando_jogo_api_auth"]);
  return api.delete("/auth/sign_out");
}

export async function permits(profileId) {
  const api = getAxios({}, baseURL["virando_jogo_api"]);
  return api.get(`/permissoes/${profileId}`);
}

export async function profiles(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/perfis`;
  return api.get(url, { params: filters });
}

export async function getAbilityProfilesForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `perfis/para_menus`;
  return api.get(url, { params: filters });
}

export async function getProfile(profileId) {
  const api = getAxios({}, baseURL["virando_jogo_api"]);
  return api.get(`/perfis/${profileId}`);
}

export async function deleteProfile(profileId) {
  const api = getAxios({}, baseURL["virando_jogo_api"]);
  return api.delete(`/perfis/${profileId}`);
}

export async function createProfile(data) {
  const api = getAxios({}, baseURL["virando_jogo_api"]);
  return api.post("/perfis", { description: data.value });
}

export async function sendPermits(profileId, data) {
  const api = getAxios({}, baseURL["virando_jogo_api"]);
  return api.put(`/permissoes/${profileId}`, { data });
}
