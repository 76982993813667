<template>
  <BaseCard :bg_color="getbgType">
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="getMatrixProblemName()"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getSituation()"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="matrix_situation.situation_risk"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getImpact()"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="matrix_situation.impact_level"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="permitShow"
          iconName="visibility"
          dispatch="MatrixSituation/openViewModal"
          :payload="matrix_situation"
          title="Visualizar mais informações"
        />
        <AppButtonIcon
          v-if="permitUpdate"
          iconName="edit"
          :payload="matrix_situation"
          dispatch="MatrixSituation/openEditModalMatrixSituations"
          title="Editar informações da situação"
        />
        <AppButtonIcon
          v-if="permitDelete"
          iconName="delete"
          :payload="matrix_situation"
          dispatch="MatrixSituation/toggleModalDeleteMatrixSituation"
          title="Excluir situação"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permit: false,
      permitUpdate: false,
      permitDelete: false,
      permitShow: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    matrix_situation: {
      type: Object,
    },
  },
  created() {
    this.matrixSituationAccess();
    this.matrixSituationDelete();
    this.matrixSituationUpdate();
    this.matrixSituationShow();
  },
  computed: {
    getbgType() {
      let bgType = "";
      switch (this.matrix_situation.situation_risk) {
        case "Gravíssima":
          bgType = "bg-situation_colors-very_serious";
          break;
        case "Grave":
          bgType = "bg-situation_colors-serious";
          break;
        case "Moderada":
          bgType = "bg-situation_colors-moderate";
          break;
      }
      return bgType;
    },
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    matrixSituationAccess() {
      this.canAcces("matrix_situation_index").then((response) => {
        this.permit = response;
      });
    },
    matrixSituationShow() {
      this.canAcces("matrix_situation_show").then((response) => {
        this.permitShow = response;
      });
    },
    matrixSituationDelete() {
      this.canAcces("matrix_situation_destroy").then((response) => {
        this.permitDelete = response;
      });
    },
    matrixSituationUpdate() {
      this.canAcces("matrix_situation_update").then((response) => {
        this.permitUpdate = response;
      });
    },
    getMatrixProblemName() {
      if (this.matrix_situation.matrix_problem) {
        return this.matrix_situation.matrix_problem.name.length < 50
          ? this.matrix_situation.matrix_problem.name
          : this.matrix_situation.matrix_problem.name.slice(0, 50) + "...";
      } else {
        return "-";
      }
    },
    getSituation() {
      return this.matrix_situation.situation.length < 50
        ? this.matrix_situation.situation
        : this.matrix_situation.situation.slice(0, 50) + "...";
    },
    getImpact() {
      return this.matrix_situation.impact.length < 50
        ? this.matrix_situation.impact
        : this.matrix_situation.impact.slice(0, 50) + "...";
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 23% 23% 11% 22% 12% 12%;
}
</style>
