<template>
  <div class="font-system" v-if="permit">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">Ocupações</div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-occupation"
          showIcon
          iconName="add"
          text="Cadastrar Ocupação"
          dispatch="Occupations/openModalAddOccupation"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-4">
          <BaseLabel
            text="Pesquisar por nome da Ocupação"
            typeText="custom"
            weight="normal"
            :italic="false"
            size="xs"
          />
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por nome"
            dispatch="Occupations/setFilterName"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-occupations"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Occupations/loadOccupations"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="occupations.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-occupations"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-occupations">
      <div class="mx-12">
        <OccupationsCards :data="occupations" />
      </div>
      <div id="occupations-pagination" class="mx-12 my-6 pb-3" v-if="occupations.length > 0">
        <BasePagination
          :total="totalOccupations"
          :total-per-page="totalPerPage"
          dispatch="Occupations/setOccupationsOffset"
        />
      </div>
      <ModalOccupation :open="modalToggleAddOccupation" />
      <DeleteOccupationConfirmation :open="modalToggleDeleteOccupation" />
    </div>
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import OccupationsCards from "../list/OccupationsCards.vue";
import BasePagination from "../../components/BasePagination.vue";
import ModalOccupation from "./modals/ModalOccupation.vue";
import DeleteOccupationConfirmation from "../alerts/DeleteOccupationConfirmation.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseLabel from "../../components/BaseLabel.vue";
import ProhibitedAccessPage from "../../components/ProhibitedAccessPage.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    BasePagination,
    BaseLabel,
    AppButtonColorful,
    OccupationsCards,
    DeleteOccupationConfirmation,
    BaseInput,
    ProhibitedAccessPage,
    ModalOccupation,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      clearFields() {
        this.$store.dispatch("Occupations/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
  },

  created() {
    this.canAcces("occupation_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("occupation_create").then((response) => {
      this.permitCreate = response;
    });
  },

  computed: {
    ...mapState({
      occupations: (state) => state.Occupations.occupations,
      modalToggleAddOccupation: (state) => state.Occupations.modalToggleAddOccupation,
      modalToggleDeleteOccupation: (state) => state.Occupations.modalToggleDeleteOccupation,
      offset: (state) => state.Occupations.occupationsOffset,
      totalPerPage: (state) => state.Occupations.occupationsTotalPerPage,
      totalOccupations: (state) => state.Occupations.occupationsTotal,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Occupations/loadOccupations");
      this.backToTop();
    },
  },
};
</script>
