<template>
  <button
    :id="id"
    :class="['button', size, variant, color, { 'with-icon': iconName, compact: compact }]"
    @click="onClick"
  >
    <span v-if="iconName" class="icon">
      <i :class="`material-icons`">{{ iconName }}</i>
    </span>
    <span class="text">{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    id: String,
    text: String,
    iconName: String,
    size: {
      type: String,
      default: "medium", // small, medium, large
    },
    variant: {
      type: String,
      default: "filled", // filled, outline, text
    },
    color: {
      type: String,
      default: "primary", // primary, secondary, success, danger, warning, info
    },
    dispatch: {
      type: String,
    },
    payload: {
      type: [Object, String, Array],
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (this.dispatch) {
        this.$store.dispatch(this.dispatch, this.payload);
      }
    },
  },
};
</script>

<style scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px; /* Aumentado para bordas mais arredondadas */
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  border: none;
}

.button.with-icon .icon {
  margin-right: 8px;
}

.button.small {
  padding: 4px 10px;
  font-size: 0.75rem;
}

.button.medium {
  padding: 10px 20px;
  font-size: 1rem;
}

.button.large {
  padding: 14px 28px;
  font-size: 1.125rem;
}

.button.compact {
  padding: 2px 8px;
  font-size: 0.7rem;
}

.button.filled {
  border: none;
}

.button.outline {
  background-color: transparent;
  border: 2px solid;
}

.button.text {
  background-color: transparent;
  border: none;
}

.button.primary {
  background-color: #007bff;
  color: #fff;
}

.button.secondary {
  background-color: #6c757d;
  color: #fff;
}

.button.success {
  background-color: #28a745;
  color: #fff;
}

.button.danger {
  background-color: #dc3545;
  color: #fff;
}

.button.warning {
  background-color: #ffc107;
  color: #212529;
}

.button.info {
  background-color: #17a2b8;
  color: #fff;
}

.button.outline.primary {
  color: #007bff;
}

.button.outline.secondary {
  color: #6c757d;
}

.button.outline.success {
  color: #28a745;
}

.button.outline.danger {
  color: #dc3545;
}

.button.outline.warning {
  color: #ffc107;
}

.button.outline.info {
  color: #17a2b8;
}

.button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button.filled.primary:hover {
  background-color: #0056b3;
}

.button.filled.secondary:hover {
  background-color: #5a6268;
}

.button.filled.success:hover {
  background-color: #218838;
}

.button.filled.danger:hover {
  background-color: #c82333;
}

.button.filled.warning:hover {
  background-color: #e0a800;
}

.button.filled.info:hover {
  background-color: #138496;
}

.button.outline:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.button.text:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
