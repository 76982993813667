<template>
  <div class="m-5">
    <div id="person-data" class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12">
      <div :class="[{ active_new: forwardingData }, 'tab_new', 'col-span-2']">
        <a href="javascript:void(0)" @click="openTab('forwarding_data')">
          <BaseText
            text="Dados do Encaminhamento"
            type-text="custom"
            weight="semibold"
            color="burnedYellow-classic"
            size="lg"
          />
          <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
        </a>
      </div>
      <div
        :class="[{ active_new: forwardingPostponementData }, 'tab_new', 'col-span-3']"
        v-if="permitShowHistoric"
      >
        <a href="javascript:void(0)" @click="openTab('forwarding_postponemente_data')">
          <BaseText
            text="Histórico de Postergação"
            type-text="custom"
            weight="semibold"
            color="burnedYellow-classic"
            size="lg"
          />
          <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
        </a>
      </div>
      <div class="col-span-5" v-if="forwardingData">
        <BaseText text="Dados do Aluno(a)" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2" v-if="forwardingData">
        <AppTextField
          show-label
          label-text="Nome do Aluno(a)"
          :text="inscription.student.name"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1" v-if="forwardingData">
        <AppTextField
          show-label
          label-text="Contato"
          :text="inscription.student.cellphone_number"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1" v-if="forwardingData">
        <AppTextField show-label label-text="Email" :text="verifyEmail()" type-text="topic-details" />
      </div>
      <div class="col-span-2" v-if="forwardingData">
        <AppTextField
          show-label
          label-text="Edição"
          :text="inscription.season.name"
          type-text="topic-details"
        />
      </div>
      <div v-if="forwardingData">
        <AppTextField
          show-label
          label-text="Status do Aluno(a)"
          :text="verifyStatus()"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="forwardingData">
        <AppTextField
          show-label
          label-text="Equipamento"
          :text="verifyEquipment()"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5" v-if="forwardingData">
        <BaseText text="Dados do Encaminhamento" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-1" v-if="forwardingData">
        <AppTextField
          show-label
          label-text="Data do Cadastro do Encaminhamento"
          :text="formattedDate(forwarding.forwarding_date)"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1" v-if="forwardingData">
        <AppTextField
          show-label
          label-text="Data de Expiração Atual"
          :text="formattedDate(forwarding.forwarding_expiration_date)"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="forwardingData">
        <AppTextField
          show-label
          label-text="Política de Encaminhamento"
          :text="forwarding_policy.name"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1" v-if="forwardingData">
        <AppTextField
          show-label
          label-text="Status Atual"
          :text="forwarding_status.name"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-4" v-if="forwardingData">
        <AppTextField
          show-label
          label-text="Equipamento do Encaminhamento"
          :text="facility.name"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-4" v-if="forwardingData">
        <AppTextField
          show-label
          label-text="Endereço do Equipamento"
          :text="facility.address"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-3" v-if="forwardingData">
        <AppTextField
          show-label
          label-text="Cadastrado pelo Usuário(a)"
          :text="forwarding_user.name"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-5" v-if="forwardingData">
        <AppTextField
          show-label
          label-text="Descrição"
          :text="forwarding.forwarding_description"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-5" v-if="forwarding.forwarding_return_date && forwardingData">
        <BaseText text="Dados de Retorno" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-1" v-if="forwarding.forwarding_return_date && forwardingData">
        <AppTextField
          show-label
          label-text="Data do Retorno Atual"
          :text="formattedDate(forwarding.forwarding_return_date)"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2" v-if="forwarding_return_user && forwardingData">
        <AppTextField
          show-label
          label-text="Usuário(a) responsável pelo retorno"
          :text="forwarding_return_user.name"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1" v-if="forwarding.forwarding_return_date && forwardingData">
        <AppTextField
          show-label
          label-text="Status Atual"
          :text="forwarding_status.name"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1" v-if="forwarding_return_reason_of_unrealized && forwardingData">
        <AppTextField
          show-label
          label-text="Motivo da não efetivação"
          :text="forwarding_return_reason_of_unrealized.name"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5" v-if="forwarding.forwarding_return_date && forwardingData">
        <AppTextField
          show-label
          label-text="Observações"
          :text="forwarding.forwarding_return_comments"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
    </div>
    <div v-if="forwardingData">
      <div class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12" v-if="this.psychosocial_accompaniment">
        <div class="col-span-5">
          <BaseText text="Dados do Acompanhamento Psicossocial" type-text="title-forms" />
          <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Data do Acompanhamento"
            :text="formattedDate(psychosocial_accompaniment.date_accompaniment)"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Tipo do Atendimento"
            :text="psychosocial_accompaniment.accompaniment_type.name"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Status do Acompanhamento"
            :text="psychosocial_accompaniment.accompaniment_status.name"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Encontro aconteceu?"
            :text="verifyMeetingHappened()"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Formato do Encontro"
            :text="psychosocial_accompaniment.meeting_format"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment.meeting_format != 'Remoto'">
          <AppTextField
            show-label
            label-text="Local do Encontro"
            :text="psychosocial_accompaniment.meeting_place"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment.meeting_format != 'Presencial'">
          <AppTextField
            show-label
            label-text="Recurso do Encontro Remoto"
            :text="psychosocial_accompaniment.meeting_remote_resource"
            type-text="topic-details"
          />
        </div>
      </div>
    </div>
    <div
      class="col-span-5"
      v-if="
        forwardingPostponementData &&
        forwarding_postponement_histories_by_forwarding.length > 0 &&
        permitShowHistoric
      "
    >
      <BaseText text="Dados do Histórico" type-text="title-forms" />
      <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
    </div>
    <div
      class="col-span-5"
      v-if="
        forwardingPostponementData &&
        forwarding_postponement_histories_by_forwarding.length > 0 &&
        permitShowHistoric
      "
    >
      <ForwardingPostponementHistoriesByForwardingCards
        :data="forwarding_postponement_histories_by_forwarding"
      />
    </div>
    <div
      v-if="
        forwardingPostponementData &&
        forwarding_postponement_histories_by_forwarding.length < 1 &&
        permitShowHistoric
      "
    >
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="Não há Histórico de Postergação para esse Encaminhamento."
          type-text="topic-details"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppTextField from "../../components/AppTextField.vue";
import ForwardingPostponementHistoriesByForwardingCards from "../list/ForwardingPostponementHistoriesByForwardingCards.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppTextField,
    ForwardingPostponementHistoriesByForwardingCards,
  },
  data: () => ({
    status: "",
    forwardingData: true,
    forwardingPostponementData: false,
    permitShowHistoric: false,
  }),
  created() {
    this.forwardingAccessHistoric();
  },
  computed: {
    ...mapState({
      forwarding: (state) => state.Forwardings.forwarding,
      inscription: (state) => state.Forwardings.inscription,
      forwarding_policy: (state) => state.Forwardings.forwarding_policy,
      forwarding_status: (state) => state.Forwardings.forwarding_status,
      facility: (state) => state.Forwardings.facility,
      forwarding_return_reason_of_unrealized: (state) =>
        state.Forwardings.forwarding_return_reason_of_unrealized,
      forwarding_user: (state) => state.Forwardings.forwarding_user,
      forwarding_return_user: (state) => state.Forwardings.forwarding_return_user,
      psychosocial_accompaniment: (state) => state.Forwardings.psychosocial_accompaniment,
      forwarding_postponement_histories_by_forwarding: (state) =>
        state.ForwardingPostponementHistories.forwarding_postponement_histories_by_forwarding,
    }),
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    verifyEmail() {
      return this.inscription.student.email ? this.inscription.student.email : "Email não informado";
    },
    verifyEquipment() {
      return this.inscription.student.equipment
        ? this.inscription.student.equipment.name
        : "Equipamento não cadastrado";
    },
    verifyStatus() {
      return this.$util_function.processInscriptionStatuses(this.inscription?.status);
    },
    verifyMeetingHappened() {
      return this.psychosocial_accompaniment.meeting_happened ? "Sim" : "Não";
    },
    forwardingAccessHistoric() {
      this.canAcces("forwarding_postponement_history_by_forwarding").then((resp) => {
        this.permitShowHistoric = resp;
      });
    },
    openTab(typeTab) {
      switch (typeTab) {
        case "forwarding_data":
          this.forwardingData = true;
          this.forwardingPostponementData = false;
          break;
        case "forwarding_postponemente_data":
          this.forwardingData = false;
          this.forwardingPostponementData = true;
          break;
      }
    },
  },
};
</script>
<style>
.active_new {
  background-color: #fff7ed;
  color: #fff;
  font-size: 1.1em;
}
</style>
