<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-4">
        <div class="justify-self-start col-span-3">
          <Label
            v-if="vacancy_request.id"
            text="Editar Solicitação de Vaga"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar Solicitação de Vaga"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="VacancyRequests/closeModalAddVacancyRequest" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Dados cadastrais da Solicitação de Vaga"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            label-text="Nome do Participante"
            show-label
            clear-on-select
            required
            select-placeholder="Digite o nome do participante para busca"
            :multiple-option="false"
            :value="inscription"
            :options="inscriptionsForGeneralMenus"
            :dispatch="setInscription"
            load-dispatch="VacancyRequests/loadInscriptionsForGeneralMenus"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Tipo da Vaga Solicitada"
            label-text="Tipo da Vaga Solicitada"
            :multiple-option="false"
            :value="vacancy_type"
            :options="vacancy_types"
            :dispatch="setVacancyType"
            required
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Município"
            :dispatch="setCity"
            required
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="articulator"
            input-type="text"
            :value="vacancy_request.articulator"
            show-label
            label-text="Articulador(a) Responsável"
            dispatch="VacancyRequests/setArticulator"
            required
          />
        </div>
        <div v-if="vacancy_type?.id === 0">
          <BaseInput
            active
            mask="###.###.###-##"
            input-name="cpf_responsible_young_person"
            input-type="text"
            :value="vacancy_request.cpf_responsible_young_person"
            show-label
            label-text="CPF da Mãe ou Responsável do Jovem"
            dispatch="VacancyRequests/setCpfResponsibleYoungPerson"
          />
        </div>
        <div class="col-span-2" v-if="vacancy_type?.id === 0">
          <BaseInput
            active
            input-name="address_responsible_young_person"
            input-type="text"
            :value="vacancy_request.address_responsible_young_person"
            show-label
            label-text="Endereço da Mãe ou Responsável do Jovem"
            dispatch="VacancyRequests/setAddressResponsibleYoungPerson"
            required
          />
        </div>
        <div v-if="vacancy_type?.id === 0">
          <BaseInput
            active
            input-name="updated_contact_young_person"
            input-type="text"
            :value="vacancy_request.updated_contact_young_person"
            show-label
            mask="(##) #####.####"
            label-text="Contato Atualizado do Jovem"
            dispatch="VacancyRequests/setUpdatedContactYoungPerson"
            required
          />
        </div>
        <div v-if="vacancy_type?.id === 0">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Escola de Interesse"
            label-text="Escola de Interesse"
            :multiple-option="false"
            :value="facility"
            :options="facilities"
            :dispatch="setFacility"
            required
          />
        </div>
        <div v-if="vacancy_type?.id === 0">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Turno de Interesse"
            label-text="Turno de Interesse"
            :multiple-option="false"
            :value="interest_shift"
            :options="interest_shifts"
            :dispatch="setInterestShift"
            required
          />
        </div>
        <div v-if="vacancy_type?.id === 0">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Série/ano de interesse"
            label-text="Série/ano de interesse"
            :multiple-option="false"
            :value="interest_serie"
            :options="interest_series"
            :dispatch="setInterestSerie"
            required
          />
        </div>
        <div class="col-span-2" v-if="vacancy_type?.id === 0">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Rede a que Pertence a Escola"
            label-text="Rede a que Pertence a Escola"
            :multiple-option="false"
            :value="school_network"
            :options="school_networks"
            :dispatch="setSchoolNetwork"
            required
          />
        </div>
        <div class="col-span-2" v-if="vacancy_type?.id === 0">
          <BaseTextArea
            active
            inputName="case_description"
            labelText="Descrição do Caso"
            showLabel
            rows="4"
            :value="vacancy_request.case_description"
            dispatch="VacancyRequests/setCaseDescription"
            required
          />
        </div>
        <div v-if="vacancy_type?.id === 0">
          <BaseTextArea
            active
            inputName="interventions_performed"
            labelText="Intervenções já Realizadas"
            showLabel
            rows="4"
            :value="vacancy_request.interventions_performed"
            dispatch="VacancyRequests/setInterventionsPerformed"
            required
          />
        </div>
        <div v-if="vacancy_type?.id === 0">
          <BaseTextArea
            active
            inputName="interventions_performed"
            labelText="Tipo de Encaminhamento"
            showLabel
            rows="4"
            :value="vacancy_request.forwarding_type"
            dispatch="VacancyRequests/setForwardingType"
            required
          />
        </div>
        <div v-if="vacancy_type?.id === 1">
          <BaseInput
            active
            input-name="full_name_responsible_child"
            input-type="text"
            :value="vacancy_request.full_name_responsible_child"
            show-label
            label-text="Nome Completo do Responsável pela Criança"
            dispatch="VacancyRequests/setFullNameResponsibleChild"
            required
          />
        </div>
        <div class="col-span-2" v-if="vacancy_type?.id === 1">
          <BaseInput
            active
            input-name="child_full_name"
            input-type="text"
            :value="vacancy_request.child_full_name"
            show-label
            label-text="Nome Completo da Criança"
            dispatch="VacancyRequests/setChildFullName"
            required
          />
        </div>
        <div v-if="vacancy_type?.id === 1">
          <BaseInput
            active
            mask="###.###.###-##"
            input-name="cpf_responsible_child"
            input-type="text"
            :value="vacancy_request.cpf_responsible_child"
            show-label
            label-text="CPF da mãe ou Responsável pela Criança"
            dispatch="VacancyRequests/setCpfResponsibleChild"
            required
          />
        </div>
        <div v-if="vacancy_type?.id === 1">
          <BaseInput
            active
            input-name="child_birthdate"
            input-type="date"
            :value="vacancy_request.child_birthdate"
            show-label
            label-text="Data de Nascimento da Criança"
            dispatch="VacancyRequests/setChildBirthdate"
            required
          />
        </div>
        <div class="col-span-2" v-if="vacancy_type?.id === 1">
          <BaseInput
            active
            input-name="address_responsible_child"
            input-type="text"
            :value="vacancy_request.address_responsible_child"
            show-label
            label-text="Endereço do Responsável pela Criança"
            dispatch="VacancyRequests/setAddressResponsibleChild"
            required
          />
        </div>
        <div v-if="vacancy_type?.id === 1">
          <BaseInput
            active
            input-name="contact_responsible_child"
            input-type="text"
            :value="vacancy_request.contact_responsible_child"
            show-label
            mask="(##) #####.####"
            label-text="Contato do Responsável pela Criança"
            dispatch="VacancyRequests/setContactResponsibleChild"
            required
          />
        </div>
        <div v-if="vacancy_type?.id === 1">
          <BaseInput
            active
            input-name="interest_kindergarten"
            input-type="text"
            :value="vacancy_request.interest_kindergarten"
            show-label
            label-text="Creche de Interesse que já foi Articulada"
            dispatch="VacancyRequests/setInterestKindergarten"
            required
          />
        </div>
        <div v-if="vacancy_request.id">
          <AppRadioButton
            :values="[
              {
                name: 'vacancy_request_accepted_request',
                value: true,
                description: 'Sim',
                checked: vacancy_request.accepted_request === true,
              },
              {
                name: 'vacancy_request_accepted_request',
                value: false,
                description: 'Não',
                checked: vacancy_request.accepted_request === false,
              },
            ]"
            labelText="Solicitação já foi atendida?"
            showLabel
            dispatch="VacancyRequests/setAcceptedRequest"
          />
        </div>
        <div class="col-span-2" v-if="vacancy_request.id">
          <BaseTextArea
            active
            inputName="feedback"
            labelText="Devolutiva"
            showLabel
            rows="5"
            :value="vacancy_request.feedback"
            dispatch="VacancyRequests/setFeedback"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="VacancyRequests/closeModalAddVacancyRequest"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="vacancy_request.id"
            text="Salvar"
            typeButton="success"
            dispatch="VacancyRequests/update"
          />
          <AppButtonEmpty v-else text="criar" typeButton="success" dispatch="VacancyRequests/save" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import AppButtonIcon from "../../../components/AppButtonIcon.vue";
import AppRadioButton from "../../../components/AppRadioButton.vue";
import AppButtonEmpty from "../../../components/AppButtonEmpty.vue";
import BaseInput from "../../../components/forms/BaseInput.vue";
import BaseModal from "../../../components/BaseModal.vue";
import BaseTextArea from "../../../components/forms/BaseTextArea.vue";
import Label from "../../../components/Label.vue";
import Multiselect from "../../../components/forms/Multiselect.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AppRadioButton,
    BaseModal,
    BaseInput,
    BaseTextArea,
    Label,
    Multiselect,
    AppButtonIcon,
    AppButtonEmpty,
  },
  data() {
    return {
      input: 1,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const user = JSON.parse(localStorage.userData);
    this.$store.dispatch("VacancyRequests/setUser", user);
  },
  computed: {
    ...mapState({
      vacancy_request: (state) => state.VacancyRequests.vacancy_request,
      inscription: (state) => state.VacancyRequests.inscription,
      inscriptionsForGeneralMenus: (state) => state.VacancyRequests.inscriptionsForGeneralMenus,
      articulators: (state) => state.VacancyRequests.articulators,
      articulator: (state) => state.VacancyRequests.articulator,
      vacancy_types: (state) => state.VacancyRequests.vacancy_types,
      vacancy_type: (state) => state.VacancyRequests.vacancy_type,
      facilities: (state) => state.VacancyRequests.facilities,
      facility: (state) => state.VacancyRequests.facility,
      school_networks: (state) => state.VacancyRequests.school_networks,
      school_network: (state) => state.VacancyRequests.school_network,
      interest_series: (state) => state.VacancyRequests.interest_series,
      interest_serie: (state) => state.VacancyRequests.interest_serie,
      interest_shifts: (state) => state.VacancyRequests.interest_shifts,
      interest_shift: (state) => state.VacancyRequests.interest_shift,
      city: (state) => state.VacancyRequests.city,
      state: (state) => state.VacancyRequests.state,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
    }),
  },
  methods: {
    ...mapActions({
      setVacancyType: "VacancyRequests/setVacancyType",
      setFacility: "VacancyRequests/setFacility",
      setInterestSerie: "VacancyRequests/setInterestSerie",
      setInterestShift: "VacancyRequests/setInterestShift",
      setSchoolNetwork: "VacancyRequests/setSchoolNetwork",
      setInscription: "VacancyRequests/setInscription",
      setCity: "VacancyRequests/setCity",
      setState: "VacancyRequests/setState",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    getLabelCity() {
      return this.state ? "Selecionar Município" : "Selecione o estado para listar os municípios";
    },
  },
};
</script>
