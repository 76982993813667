<template>
  <div class="font-system" v-if="open">
    <div id="tabs-button" class="grid grid-cols-1">
      <div v-if="permitShowPaginate" class="flex justify-end">
        <AppButtonColorful
          id="btn-add-forwarding"
          showIcon
          iconName="add"
          text="Cadastrar Encaminhamento"
          dispatch="Forwardings/toggleModalForwardings"
          typeButton="primary"
          v-if="permitCreate"
        />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-4 justify-between items-end mx-4 my-6 gap-4">
      <div>
        <BaseLabel
          text="Pesquisar por nome do Responsável pelo Cadastro"
          typeText="custom"
          weight="normal"
          :italic="false"
          size="xs"
        />
        <BaseInput
          active
          inputName=""
          inputType="text"
          iconName="search"
          showIcon
          input-placeholder="Pesquisar por nome"
          dispatch="Forwardings/filterListByUserName"
        />
      </div>
      <div>
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          select-placeholder="Selecione a opção"
          label-text="Pesquisar por Política de Encaminhamento"
          :multiple-option="false"
          :value="forwarding_policy_filtered"
          :options="forwarding_policies"
          :dispatch="filterListByForwardingPolicy"
        />
      </div>
      <div>
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          select-placeholder="Selecione a opção"
          label-text="Pesquisar por Status de Encaminhamento"
          :multiple-option="false"
          :value="forwarding_status_filtered"
          :options="forwarding_statuses"
          :dispatch="filterListByForwardingStatus"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="filter_by_initial_date"
          input-type="date"
          :value="forwarding_initial_date"
          show-label
          label-text="Data do Encaminhamento - Data Inicial"
          dispatch="Forwardings/filterListByForwardingInitialDate"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="filter_by_final_date"
          input-type="date"
          :value="forwarding_final_date"
          show-label
          label-text="Data do Encaminhamento - Data Final"
          dispatch="Forwardings/filterListByForwardingFinalDate"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="filter_by_initial_date"
          input-type="date"
          :value="forwarding_expiration_initial_date"
          show-label
          label-text="Data de Expiração - Data Inicial"
          dispatch="Forwardings/filterListByForwardingExpirationInitialDate"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="filter_by_final_date"
          input-type="date"
          :value="forwarding_expiration_final_date"
          show-label
          label-text="Data de Expiração - Data Final"
          dispatch="Forwardings/filterListByForwardingExpirationFinalDate"
        />
      </div>
      <a
        href="javascript:void(0)"
        @click="exportToExcel()"
        v-if="forwardings_by_inscription.length > 0 && permitExportSpreadsheet"
      >
        <img
          class="inline-block"
          src="../../assets/images/logout_black_24dp.svg"
          alt=""
          style="cursor: pointer"
        />
        <BaseText
          text="Exportar planilha"
          typeText="custom"
          :italic="false"
          size="xs"
          color="burnedYellow"
          style="cursor: pointer"
        />
      </a>
      <BaseModal v-if="forwardings_spreadsheet.length > 0 && modalToggleExportForwardingSpreadsheet">
        <template v-slot:header>
          <div class="grid grid-cols-2">
            <div class="justify-self-start">
              <Label
                text="Exportar Planilha de Encaminhamentos"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon dispatch="Forwardings/closeExportSpreadsheetModal" forClose />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="Forwardings/closeExportSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data"
                  :before-finish="closeExportSpreadsheetModal"
                  worksheet="Encaminhamentos"
                  name="encaminhamentos.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetResponse">
        <PreLoading />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-2 my-6">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-forwardings"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Forwardings/loadForwardings"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="forwardings_by_inscription.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-incident_reports"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-forwardings">
      <div class="mx-2">
        <ForwardingsCards :data="forwardings_by_inscription" />
      </div>
      <div id="forwarding-pagination" class="mx-12 my-6 pb-3" v-if="forwardings_by_inscription.length > 0">
        <BasePagination
          :total="totalForwardings"
          :total-per-page="totalPerPage"
          dispatch="Forwardings/setOffset"
        />
      </div>
      <ModalForwarding :open="modalToggleForwardings" />
      <ModalReturnForwarding :open="modalToggleReturnForwardings" />
      <ModalForwardingPostponeExpirationDate :open="modalToggleForwardingPostponeExpirationDate" />
      <ModalViewForwarding :open="modalViewForwarding" />
      <ModalViewForwardingPostponementHistoriesByForwarding :open="modalToggleViewHistoricForwarding" />
      <DeleteForwarding :open="modalConfirmDeleteForwarding" />
    </div>
  </div>
</template>

<script>
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseLabel from "../../components/BaseLabel.vue";
import Multiselect from "../../components/forms/Multiselect.vue";
import ForwardingsCards from "../list/ForwardingsCards.vue";
import BasePagination from "../../components/BasePagination.vue";
import BaseModal from "../../components/BaseModal.vue";
import ModalForwarding from "../ModalForwarding.vue";
import ModalReturnForwarding from "../ModalReturnForwarding.vue";
import ModalForwardingPostponeExpirationDate from "../ModalForwardingPostponeExpirationDate.vue";
import ModalViewForwarding from "../datas/ModalViewForwarding.vue";
import ModalViewForwardingPostponementHistoriesByForwarding from "../datas/ModalViewForwardingPostponementHistoriesByForwarding.vue";
import DeleteForwarding from "../alerts/DeleteForwarding.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import Label from "../../components/Label.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import BaseText from "../../components/BaseText.vue";
import PreLoading from "../../components/PreLoading.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BasePagination,
    BaseLabel,
    BaseModal,
    BaseText,
    AppButtonColorful,
    AppButtonIcon,
    Label,
    AppButtonEmpty,
    ForwardingsCards,
    ModalForwarding,
    ModalReturnForwarding,
    Multiselect,
    ModalViewForwarding,
    ModalViewForwardingPostponementHistoriesByForwarding,
    ModalForwardingPostponeExpirationDate,
    DeleteForwarding,
    BaseInput,
    PreLoading,
  },
  data() {
    return {
      permitCreate: false,
      permit: false,
      permitUp: false,
      currentPage: 1,
      permitShowPaginate: false,
      permitExportSpreadsheet: false,
      json_fields: {
        Área: "student_area",
        "Equipamento de Origem do Jovem": "student_equipment",
        "Nome completo do jovem": "student_name",
        Política: "forwarding_policy",
        "Status do Encaminhamento": "forwarding_status",
        Vulnerabilidade: "situation_risk",
        "Data do Encaminhamento": "forwarding_date",
        "Profissional que fez o encaminhamento": "forwarding_user",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      filterListByForwardingPolicy: "Forwardings/filterListByForwardingPolicy",
      setSeason: "PsychosocialAccompaniments/setSeason",
      filterListByForwardingStatus: "Forwardings/filterListByForwardingStatus",
      closeExportSpreadsheetModal: "Forwardings/closeExportSpreadsheetModal",
    }),
    clearFields() {
      let payload = {
        name: "psychosocial",
      };
      this.$store.dispatch("Forwardings/clearQuery", payload);
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    async loadData() {
      await this.$store.dispatch("Forwardings/loadForwardings");
    },
    async exportToExcel() {
      await this.$store.dispatch("Forwardings/loadForwardingsSpreadsheet");
    },
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.backToTop();
    this.canAcces("forwarding_create").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("forwarding_create").then((resp) => {
      this.permitCreate = resp;
    });
    this.canAcces("forwarding_index").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("forwarding_filter_paginate").then((resp) => {
      this.permitShowPaginate = resp;
    });
    this.canAcces("forwarding_export_spreadsheet").then((resp) => {
      this.permitExportSpreadsheet = resp;
    });
    this.canAcces("forwarding_update").then((resp) => {
      this.permitUp = resp;
    });
  },
  computed: {
    ...mapState({
      forwardings_by_inscription: (state) => state.Forwardings.forwardings_by_inscription,
      forwardings_spreadsheet: (state) => state.Forwardings.forwardings_spreadsheet,
      forwarding_policies: (state) => state.Forwardings.forwarding_policies,
      forwarding_statuses: (state) => state.Forwardings.forwarding_statuses,
      forwarding_policy_filtered: (state) => state.Forwardings.params.forwarding_policy,
      forwarding_status_filtered: (state) => state.Forwardings.params.forwarding_status,
      forwarding_initial_date: (state) => state.Forwardings.params.forwarding_initial_date,
      forwarding_final_date: (state) => state.Forwardings.params.forwarding_final_date,
      forwarding_expiration_initial_date: (state) =>
        state.Forwardings.params.forwarding_expiration_initial_date,
      forwarding_expiration_final_date: (state) => state.Forwardings.params.forwarding_expiration_final_date,

      modalConfirmDeleteForwarding: (state) => state.Forwardings.modalConfirmDeleteForwarding,
      modalToggleForwardings: (state) => state.Forwardings.modalToggleForwardings,
      modalToggleReturnForwardings: (state) => state.Forwardings.modalToggleReturnForwardings,
      modalViewForwarding: (state) => state.Forwardings.modalViewForwarding,
      modalToggleViewHistoricForwarding: (state) => state.Forwardings.modalToggleViewHistoricForwarding,
      modalToggleForwardingPostponeExpirationDate: (state) =>
        state.Forwardings.modalToggleForwardingPostponeExpirationDate,
      modalToggleExportForwardingSpreadsheet: (state) =>
        state.Forwardings.modalToggleExportForwardingSpreadsheet,

      city: (state) => state.City.city,
      hasSpreadsheetResponse: (state) => state.Forwardings.hasSpreadsheetResponse,
      json_data: (state) => state.Forwardings.json_data,

      offset: (state) => state.Forwardings.offset,
      totalPerPage: (state) => state.Forwardings.totalPerPage,
      totalForwardings: (state) => state.Forwardings.forwardingTotal,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Forwardings/loadForwardings");
      this.backToTop();
    },
  },
};
</script>
