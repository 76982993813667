import { getAxios, baseURL } from "./Api";

export async function getCompanies(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `empresas`;
  return api.get(url, { params: filters });
}

export async function getCompaniesForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `empresas/para_menus`;
  return api.get(url, { params: filters });
}

export async function getCompaniesForExport(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `empresas/exportar_planilha`;
  return api.get(url, { params: filters });
}

export async function getCompanyModalities() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `empresas/modalidades`;
  return api.get(url);
}

export async function getCompanyById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`empresas/${_id}`);
}

export async function createCompany(company) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/empresas", company);
}

export async function updateCompany(id, company) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`empresas/${id}`, company);
}

export async function destroyCompany(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`empresas/${id}`);
}
