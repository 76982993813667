import { getAxios, baseURL } from "./Api";

export async function getSegments(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `segmentos`;
  return api.get(url, { params: filters });
}

export async function getSegmentsForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `segmentos/para_menus`;
  return api.get(url, { params: filters });
}

export async function getSegmentById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`segmentos/${_id}`);
}

export async function createSegment(segment) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/segmentos", segment);
}

export async function updateSegment(id, segment) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`segmentos/${id}`, segment);
}

export async function destroySegment(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`segmentos/${id}`);
}
