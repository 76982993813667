<template>
  <div class="flex flex-col items-start font-system">
    <BaseLabel :text="labelText" :required="required" :target="id" v-if="showLabel" />
    <div class="relative w-full">
      <div :class="classes" @click="focusInput">
        <span v-for="(value, index) in values" :key="index" class="tag">
          {{ value }}
          <button @click.stop="removeValue(index)" class="remove-tag">x</button>
        </span>
        <input
          v-if="mask"
          :id="id"
          class="input-tag"
          :type="inputType"
          :name="inputName"
          :placeholder="inputPlaceholder"
          v-model="currentInput"
          v-the-mask="mask"
          @keyup.enter="onEnter"
          @input="onInput"
          :readonly="readonly"
        />
        <textarea
          v-else
          :id="id"
          class="input-tag"
          :name="inputName"
          :placeholder="inputPlaceholder"
          v-model="currentInput"
          :rows="rows"
          @keyup.enter="onEnter"
          @input="onInput"
          :readonly="readonly"
        />
      </div>
      <AppMaterialIcon
        v-tooltip="{ content: tooltipMessage, classes: tooltipClass }"
        v-if="showIcon"
        :iconName="iconName"
        class="icon-input text-quantum cursor-pointer"
      />
      <span v-if="errorMessage" class="text-carmesim-hover text-xs">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import AppMaterialIcon from "../AppMaterialIcon";
import BaseLabel from "../BaseLabel";
import { mask } from "vue-the-mask";

export default {
  directives: {
    "the-mask": mask,
  },

  components: {
    AppMaterialIcon,
    BaseLabel,
  },

  props: {
    id: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: true,
    },
    inputName: {
      type: String,
      require: true,
    },
    inputType: {
      type: String,
      default: "",
    },
    inputPlaceholder: {
      type: String,
      default: "",
    },
    rows: String,
    mask: {
      String,
    },
    value: {
      type: [String, Array],
    },
    required: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: "",
    },
    labelText: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    dispatch: {
      type: String,
    },
    payload: {
      type: Object,
    },
    errorMessage: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    tooltipMessage: {
      type: String,
      default: null,
    },
    tooltipClass: {
      type: String,
      default: "custom-tooltip",
    },
    multiple: {
      type: Boolean,
      default: false, // Define se o componente aceita múltiplos valores
    },
  },

  data() {
    return {
      currentInput: "", // Armazena o valor atual do input
      values: [], // Armazena os valores adicionados quando multiple é true
    };
  },

  computed: {
    classes() {
      let roundBorder = {
        "rounded-xl": true,
        "w-full": true,
        "py-2": true,
        "px-6": true,
        "border-2": true,
        "border-carmesim": this.errorMessage,
        "border-ashes-dark": !this.errorMessage,
        "focus:border-carmesim-hover": this.errorMessage,
        "focus:border-burnedYellow": !this.errorMessage,
        flex: true,
        "flex-wrap": true,
        "items-start": true, // Alinha os itens ao topo
        "gap-2": true,
      };

      let textButton = {
        "text-quantum": true,
        "font-medium": true,
        "text-base": true,
        "focus:outline-none": true,
      };

      let success = {
        "bg-transparent": true,
      };

      let inputInactive = {
        "bg-ashes": true,
        "cursor-not-allowed": true,
      };

      let css = {
        "pl-14": this.showIcon,
      };

      css = Object.assign(css, roundBorder);
      css = Object.assign(css, textButton);

      css = this.active ? Object.assign(css, success) : Object.assign(css, inputInactive);

      return css;
    },
  },
  methods: {
    onEnter() {
      if (this.multiple) {
        if (this.currentInput.trim()) {
          const updatedValues = [...this.values];
          updatedValues.push(this.currentInput.trim());

          this.values = updatedValues;
          this.currentInput = "";
          this.dispatchValues();
        }
      } else {
        this.$emit("input", this.currentInput.trim());
      }
    },
    removeValue(index) {
      const updatedValues = [...this.values];
      updatedValues.splice(index, 1);

      this.values = updatedValues;
      this.dispatchValues();
    },
    focusInput() {
      this.$el.querySelector(".input-tag").focus();
    },
    onInput(event) {
      if (!this.multiple && this.dispatch) {
        this.$store.dispatch(this.dispatch, { value: event.target.value, ...this.payload });
      }
    },
    dispatchValues() {
      if (this.dispatch) {
        this.$store.dispatch(this.dispatch, { values: this.values, ...this.payload });
      }
    },
  },
};
</script>

<style>
.icon-input {
  line-height: 46px !important;
  position: absolute;
  top: 0;
  left: 10px;
  text-align: center;
  width: 36px;
}

.custom-tooltip {
  background-color: #333 !important;
  color: #fff !important;
  font-size: 14px !important;
  padding: 8px !important;
  border-radius: 4px !important;
  z-index: 100 !important;
  max-width: 20rem;
  left: 50%;
}

.custom-tooltip .tooltip-arrow {
  border-top-color: #333 !important;
}

.tag {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
}

.remove-tag {
  background: none;
  border: none;
  color: #ff0000;
  margin-left: 4px;
  cursor: pointer;
}

.input-tag {
  flex-grow: 1;
  border: none;
  outline: none;
  min-width: 100px;
  margin-top: 2px; /* Ajusta o espaçamento para alinhar com as tags */
}
</style>
