<template>
  <div>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4 ml-5">
        <BaseText
          text="Nome"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Módulo"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Cidade"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li
        :key="course.id"
        :data-index="index"
        v-for="(course, index) in data"
        @click.prevent="gotToCourse(course)"
      >
        <div>
          <AppCardCourse :course="course" />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardCourse from "../../components/AppCardCourse.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";
import router from "../../router/index";

export default {
  components: {
    AppCardCourse,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },
  methods: {
    gotToCourse(params) {
      router.push({ name: "curso", params: { id: params.id } });
    },
  },
};
</script>
<style scoped>
.grid-cols-custom {
  grid-template-columns: 27% 24% 25%;
}
</style>
