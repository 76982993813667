<template>
  <div class="m-5">
    <div class="grid grid-cols-2 gap-5 mt-10 pb-8">
      <div class="col-span-2">
        <BaseText text="escolha a edição da qual quer participar" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div>
        <Multiselect
          id="select-season"
          select-placeholder="Selecionar"
          label-text="Edição"
          :value="student.season"
          close-on-select
          show-label
          required
          :error-message="getErrorMessage('season', season_id)"
          :multiple-option="false"
          :options="seasons"
          :dispatch="setSeason"
        />
      </div>
      <div>
        <Multiselect
          id="select-equipment"
          select-placeholder="Selecionar"
          label-text="Equipamento"
          :error-message="getErrorMessage('equipment', equipment)"
          :value="student.equipment"
          close-on-select
          show-label
          :multiple-option="false"
          :options="equipmentAll"
          :dispatch="setEquipment"
        />
      </div>
    </div>
    <div class="grid grid-cols-5 gap-5">
      <div class="col-span-5">
        <BaseText text="dados pessoais" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-5">
        <BaseInput
          active
          input-name="name"
          :value="student.name"
          show-label
          label-text="nome completo"
          required
          :error-message="getErrorMessage('student.name', student.name)"
          dispatch="Student/setName"
        />
      </div>
      <div class="col-span-2">
        <BaseInput
          active
          input-name="socialName"
          :value="student.social_name"
          show-label
          label-text="Nome Social"
          :error-message="getErrorMessage('student.social_name', student.social_name)"
          dispatch="Student/setSocialName"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="rg"
          :value="student.rg"
          input-placeholder="0000000000000"
          show-label
          label-text="rg"
          dispatch="Student/setRg"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="dispatching_agency"
          :value="student.dispatching_agency"
          show-label
          label-text="orgão expeditor"
          dispatch="Student/setDispatchingAgency"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="expedition"
          input-type="date"
          :value="student.expedition"
          show-label
          label-text="expedição"
          dispatch="Student/setExpedition"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="cpf"
          :value="student.cpf"
          input-placeholder="000.000.000-000"
          mask="###.###.###-##"
          show-label
          label-text="cpf"
          :error-message="getErrorMessage('student.cpf', student.cpf)"
          dispatch="Student/setCpf"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="city_of_birth"
          :value="student.city_of_birth"
          show-label
          label-text="cidade onde nasceu"
          dispatch="Student/setCityOfBirth"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="birth_certificate"
          input-placeholder="00000000000000"
          :value="student.birth_certificate"
          show-label
          label-text="certidão de nascimento"
          dispatch="Student/setBirthCertificate"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="book"
          :value="student.book"
          show-label
          label-text="livro"
          dispatch="Student/setBook"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="page"
          :value="student.page"
          show-label
          label-text="folha"
          dispatch="Student/setPage"
        />
      </div>
      <div class="col-span-2">
        <BaseInput
          active
          input-name="email"
          :value="student.email"
          show-label
          :error-message="getErrorMessage('student.email', student.email)"
          label-text="email"
          dispatch="Student/setEmail"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="cellphone_number"
          :value="student.cellphone_number"
          input-placeholder="(85) 90000.0000"
          show-label
          required
          mask="(##) #####.####"
          :error-message="getErrorMessage('student.cellphone_number', student.cellphone_number)"
          label-text="telefone"
          dispatch="Student/setCellphoneNumber"
        />
      </div>
      <div class="col-span-2">
        <BaseInput
          active
          input-name="whatszap"
          :value="student.whatszap"
          :mask="['(##) ####.####', '(##) #####.####']"
          input-placeholder="(85) 90000.0000"
          show-label
          label-text="whatsapp de contato"
          dispatch="Student/setWhatszap"
        />
      </div>
      <div class="col-span-2 flex-col items-end">
        <BaseInput
          active
          input-name="birthdate"
          input-type="date"
          :value="student.birthdate"
          show-label
          label-text="Data de Nascimento"
          required
          :error-message="getErrorMessage('student.birthdate', student.birthdate)"
          dispatch="Student/addBirthdate"
        />
        <!-- <div class="flex items-end">
          <Multiselect
            id="select-day"
            select-placeholder="Dia"
            label-text="Data de Nascimento"
            close-on-select
            required
            show-label
            :multiple-option="false"
            :value="getDay(student.birthdate)"
            :options="days"
            :dispatch="setDay"
            class="mr-4"
          />
          <Multiselect
            id="select-month"
            select-placeholder="Mês"
            close-on-select
            :multiple-option="false"
            :value="getMonth(student.birthdate)"
            :options="months"
            :dispatch="setMonth"
            class="mr-5"
          />
          <Multiselect
            id="select-year"
            select-placeholder="Ano"
            close-on-select
            :multiple-option="false"
            :value="getYear(student.birthdate)"
            :options="years"
            :dispatch="setYear"
          />
        </div>
        <span v-if="getErrorMessage('student.birthdate')" class="text-carmesim-hover text-xs font-system">
          {{ this.getErrorMessage("student.birthdate", student.birthdate) }}
        </span> -->
      </div>
      <div class="col-span-3 flex justify-center items-center">
        <AppRadioButton
          show-label
          label-text="gênero"
          :values="[
            {
              name: 'gender',
              value: 'Feminino',
              description: 'feminino',
              checked: student.gender === 'Feminino',
            },
            {
              name: 'gender',
              value: 'Masculino',
              description: 'masculino',
              checked: student.gender === 'Masculino',
            },
            {
              name: 'gender',
              value: 'Não-Binárie',
              description: 'Não-Binárie',
              checked: student.gender === 'Não-Binárie',
            },
            {
              name: 'gender',
              value: 'Outros',
              description: 'outros',
              checked: student.gender === 'Outros',
            },
            {
              name: 'gender',
              value: 'Prefiro não Falar',
              description: 'Prefiro não Falar',
              checked: student.gender === 'Prefiro não Falar',
            },
          ]"
          dispatch="Student/setGender"
        />
      </div>
      <div class="col-span-3 flex justify-center items-center">
        <AppRadioButton
          show-label
          label-text="Se identifica como LGBTQIAPN+"
          :values="[
            {
              name: 'identifies_as_lgbtqiapn',
              value: 'Sim',
              description: 'Sim',
              checked: student.identifies_as_lgbtqiapn === 'Sim',
            },
            {
              name: 'identifies_as_lgbtqiapn',
              value: 'Não',
              description: 'Não',
              checked: student.identifies_as_lgbtqiapn === 'Não',
            },
            {
              name: 'identifies_as_lgbtqiapn',
              value: 'Prefiro não Falar',
              description: 'Prefiro não Falar',
              checked: student.identifies_as_lgbtqiapn === 'Prefiro não Falar',
            },
          ]"
          dispatch="Student/setIdentifiesAsLgbtqiapn"
        />
      </div>
      <div class="col-span-3 flex justify-center items-center">
        <AppRadioButton
          show-label
          label-text="Pertence a povos originários"
          :values="[
            {
              name: 'belongs_to_indigenous_peoples',
              value: 'Não',
              description: 'Não',
              checked: student.belongs_to_indigenous_peoples === 'Não',
            },
            {
              name: 'belongs_to_indigenous_peoples',
              value: 'Indígena',
              description: 'Indígena',
              checked: student.belongs_to_indigenous_peoples === 'Indígena',
            },
            {
              name: 'belongs_to_indigenous_peoples',
              value: 'Quilombola',
              description: 'Quilombola',
              checked: student.belongs_to_indigenous_peoples === 'Quilombola',
            },
            {
              name: 'belongs_to_indigenous_peoples',
              value: 'Cigano',
              description: 'Cigano',
              checked: student.belongs_to_indigenous_peoples === 'Cigano',
            },
            {
              name: 'belongs_to_indigenous_peoples',
              value: 'Outros',
              description: 'Outros',
              checked: student.belongs_to_indigenous_peoples === 'Outros',
            },
          ]"
          dispatch="Student/setBelongsToIndigenousPeoples"
        />
      </div>
      <div>
        <Multiselect
          id="select-civil-state"
          select-placeholder="Selecionar"
          label-text="Estado Cívil"
          close-on-select
          show-label
          :multiple-option="false"
          :options="civilStates"
          :value="student.civil_state"
          class="mr-5"
          :dispatch="setCivilState"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="nis"
          :value="student.nis"
          show-label
          label-text="nis"
          dispatch="Student/setNis"
        />
      </div>
      <div class="col-span-2">
        <Multiselect
          id="select-deficiencie"
          select-placeholder="Escolha uma ou mais opções"
          label-text="Possui alguma deficiência?"
          show-label
          :options="deficiencies"
          :value="student.deficiencies_attributes"
          :dispatch="setDeficiencies"
          class="mr-5"
        />
      </div>
      <div class="col-span-3">
        <Multiselect
          id="select-race"
          select-placeholder="Selecionar"
          label-text="Cor / Raça"
          close-on-select
          show-label
          :multiple-option="false"
          :options="races"
          :value="student.race"
          class="mr-5"
          :dispatch="setRace"
        />
      </div>
      <div class="col-span-2">
        <Multiselect
          id="select-number-sons"
          select-placeholder="Selecionar"
          label-text="Possui filhos?"
          close-on-select
          :multiple-option="false"
          show-label
          :options="numbersSons"
          :value="student.number_sons"
          class="mr-5"
          :dispatch="setNumberSons"
          :error-message="getErrorMessage('student.number_sons', student.number_sons)"
        />
      </div>
      <div>
        <AppRadioButton
          show-label
          label-text="Está grávida?"
          :values="[
            {
              name: 'is_pregnant',
              value: true,
              description: 'sim',
              checked: student.is_pregnant === true,
            },
            {
              name: 'is_pregnant',
              value: false,
              description: 'não',
              checked: student.is_pregnant === false,
            },
          ]"
          :error-message="getErrorMessage('student.is_pregnant', student.is_pregnant)"
          dispatch="Student/setIsPregnant"
        />
      </div>
      <div class="col-span-2">
        <BaseInput
          active
          input-name="voter_title"
          :value="student.document_attributes.voter_title"
          show-label
          mask="#### #### ####"
          label-text="Título de eleitor (jovens a partir de 16 anos):"
          dispatch="Student/setVoterTitle"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="work_permit"
          :value="student.document_attributes.work_permit"
          mask="###.#####.##-#"
          show-label
          label-text="Carteira de trabalho:"
          dispatch="Student/setWorkPermit"
          width="36px"
        />
      </div>
      <div class="col-span-2">
        <BaseInput
          active
          input-name="reserve_card"
          :value="student.document_attributes.reserve_card"
          show-label
          mask="##.####.######-#"
          label-text="carteira de reservista (homens a partir de 18 anos):"
          dispatch="Student/setReserveCard"
        />
      </div>
    </div>
    <div class="grid grid-cols-4 gap-5 mt-10">
      <div class="col-span-4">
        <BaseText text="Endereço" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div>
        <BaseInput
          active
          input-name="cep"
          :value="student.cep"
          input-placeholder="00000-000"
          show-label
          mask="#####-###"
          label-text="cep"
          :error-message="getErrorMessage('student.cep', student.cep)"
          dispatch="Student/setCep"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="street"
          :value="student.street"
          show-label
          label-text="rua"
          dispatch="Student/setStreet"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="street_number"
          :value="student.street_number"
          show-label
          label-text="número"
          dispatch="Student/setStreetNumber"
        />
      </div>
      <div>
        <Multiselect
          id="select-state"
          select-placeholder="Selecionar"
          label-text="Estado"
          :value="student.state"
          close-on-select
          show-label
          required
          :error-message="getErrorMessage('student.state', student.state)"
          :multiple-option="false"
          :options="states"
          :dispatch="setState"
          class="mr-5"
        />
      </div>
      <div>
        <Multiselect
          id="select-city"
          select-placeholder="Selecionar"
          label-text="Cidade"
          :value="student.city"
          close-on-select
          show-label
          :multiple-option="false"
          :options="cities"
          required
          :error-message="getErrorMessage('student.city', student.city)"
          :dispatch="setCity"
          class="mr-5"
        />
      </div>
      <div>
        <Multiselect
          id="select-neighborhood"
          select-placeholder="Selecionar"
          label-text="Bairro"
          :value="student.neighborhood"
          close-on-select
          show-label
          required
          :error-message="getErrorMessage('student.neighborhood', student.neighborhood)"
          :multiple-option="false"
          :options="neighborhoods"
          :dispatch="setNeighborhood"
          class="mr-5"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="community"
          :value="student.community"
          show-label
          label-text="comunidade"
          dispatch="Student/setCommunity"
        />
      </div>
      <div class="col-span-2">
        <BaseInput
          active
          input-name="complement"
          :value="student.complement"
          show-label
          label-text="complemento (opcional)"
          dispatch="Student/setComplement"
        />
      </div>
    </div>
    <div class="grid grid-cols-4 gap-5 mt-10" v-if="student.id">
      <div class="col-span-4">
        <BaseText text="Dados de Matrícula" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-3" v-if="student.id">
        <Multiselect
          id="select-qualification-status"
          select-placeholder="Selecionar"
          label-text="Selecionar Status Qualificação"
          close-on-select
          show-label
          :multiple-option="false"
          :options="qualificationStatus"
          :value="student.qualification_status"
          :dispatch="setQualificationStatus"
          class="mr-5"
        />
      </div>
    </div>
    <div class="grid grid-cols-6 gap-5 mt-10">
      <div class="col-span-6">
        <BaseText text="dados complementares" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div>
        <AppRadioButton
          show-label
          label-text="frequenta a escola?"
          :values="[
            {
              name: 'studying',
              value: true,
              description: 'sim',
              checked: student.studying === true,
            },
            {
              name: 'studying',
              value: false,
              description: 'não',
              checked: student.studying === false,
            },
          ]"
          required
          :error-message="getErrorMessage('student.studying', student.studying)"
          dispatch="Student/setStudying"
        />
      </div>
      <div class="col-span-2">
        <div v-if="student.studying === true">
          <img class="inline-block" src="../assets/images/icon-alerta.svg" alt="" />
          <span class="font-system" style="font-size: 0.65rem">
            APENAS JOVENS NO SISTEMA SOCIOEDUCATIVO ESTÃO DENTRO DO PRÉ-REQUISITO PARA PARTICIPAR DO PROJETO
            VIRANDO O JOGO. Jovens regularmente matriculados na rede de ensino fora desta exceção não devem
            participar.
          </span>
        </div>
      </div>
      <div>
        <AppRadioButton
          show-label
          label-text="tem ocupação formal?"
          :values="[
            {
              name: 'busy',
              value: true,
              description: 'sim',
              checked: student.busy === true,
            },
            {
              name: 'busy',
              value: false,
              description: 'não',
              checked: student.busy === false,
            },
          ]"
          required
          :error-message="getErrorMessage('student.busy', student.busy)"
          dispatch="Student/setBusy"
        />
      </div>
      <div class="col-span-2">
        <div v-if="student.busy === true">
          <img class="inline-block" src="../assets/images/icon-alerta.svg" alt="" />
          <span class="font-system" style="font-size: 0.65rem">
            Jovens que possuem ocupação formal não estão dentro do pré-requisito para participar do projeto
            virando o jogo.
          </span>
        </div>
      </div>
      <div class="col-span-3">
        <Multiselect
          id="select-completed-series"
          select-placeholder="Selecionar"
          label-text="qual a sua última série concluída?"
          close-on-select
          show-label
          :multiple-option="false"
          :options="completedSeries"
          :value="student.completed_series"
          :dispatch="setCompletedSeries"
          class="mr-5"
        />
      </div>
      <div class="col-span-3">
        <Multiselect
          id="select-professional-qualifications"
          select-placeholder="Escolha uma ou mais opções"
          label-text="Escolha uma ou mais qualificações profissionais de seu interesse"
          :value="student.professional_qualifications_attributes"
          :close-on-select="false"
          show-label
          label="description"
          :options="professionalQualifications"
          :dispatch="setProfessionalQualifications"
          class="mr-5"
        />
      </div>
      <div class="col-span-3">
        <AppCheckBox
          show-label
          label-text="ESCOLHA QUAIS OS TURNOS VOCÊ TEM DISPONÍVEIS PARA PARTICIPAR DO PROJETO"
          :values="[
            {
              name: 'morning-shift',
              value: '2',
              description: 'manhã',
              checked: student.morning_shift,
            },
            {
              name: 'afternoon-shift',
              value: '1',
              description: 'tarde',
              checked: student.afternoon_shift,
            },
            {
              name: 'night-shift',
              value: '0',
              description: 'noite',
              checked: student.night_shift,
            },
          ]"
          dispatch="Student/setShift"
        />
      </div>
      <!--      <div class="col-span-3">-->
      <!--        <Multiselect-->
      <!--          id="select-sports"-->
      <!--          select-placeholder="Escolha uma ou mais opções"-->
      <!--          label-text="Escolha uma ou mais modalidades esportivas de seu interesse"-->
      <!--          :close-on-select="false"-->
      <!--          show-label-->
      <!--          :options="sports"-->
      <!--          :value="student.sports_attributes"-->
      <!--          label="description"-->
      <!--          :dispatch="setSports"-->
      <!--          class="mr-5"-->
      <!--        />-->
      <!--      </div>-->
      <!-- <div>
        <BaseInput
          active
          input-name="height"
          :value="student.height"
          mask="#,##"
          show-label
          label-text="Altura (m)"
          dispatch="Student/setHeight"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="weight"
          :value="student.weight"
          show-label
          label-text="Peso (Kg)"
          dispatch="Student/setWeight"
        />
      </div> -->
      <!--      <div>-->
      <!--        <Multiselect-->
      <!--          id="select-shirt-size"-->
      <!--          select-placeholder="Selecionar"-->
      <!--          label-text="Tamanho da blusa"-->
      <!--          close-on-select-->
      <!--          :multiple-option="false"-->
      <!--          show-label-->
      <!--          :options="shirtSizes"-->
      <!--          :value="student.shirt_size"-->
      <!--          :dispatch="setShirtSize"-->
      <!--          class="mr-5"-->
      <!--        />-->
      <!--      </div>-->
      <!--      <div>-->
      <!--        <Multiselect-->
      <!--          id="select-pants-size"-->
      <!--          select-placeholder="Selecionar"-->
      <!--          label-text="Tamanho da calça"-->
      <!--          close-on-select-->
      <!--          :multiple-option="false"-->
      <!--          show-label-->
      <!--          :options="pantsSizes"-->
      <!--          :value="student.pants_size"-->
      <!--          :dispatch="setPantsSize"-->
      <!--          class="mr-5"-->
      <!--        />-->
      <!--      </div>-->
      <!--      <div>-->
      <!--        <Multiselect-->
      <!--          id="select-shoe-size1"-->
      <!--          select-placeholder="Selecionar"-->
      <!--          label-text="Tamanho do tênis"-->
      <!--          close-on-select-->
      <!--          :multiple-option="false"-->
      <!--          show-label-->
      <!--          :options="shoeSizes"-->
      <!--          :value="student.shoe_size1"-->
      <!--          :dispatch="setShoeSize1"-->
      <!--          class="mr-5"-->
      <!--        />-->
      <!--      </div>-->
      <div class="col-span-3" v-if="student.id">
        <Multiselect
          id="select-institutional-referral"
          select-placeholder="Selecionar"
          label-text="Selecionar Encaminhamento Institucional"
          close-on-select
          show-label
          :multiple-option="false"
          :options="institutionalReferral"
          :value="student.institutional_referral"
          :dispatch="setInstitutionalReferral"
          class="mr-5"
        />
      </div>
      <!-- <div>
        <Multiselect
          id="select-shoe-size2"
          select-placeholder="Selecionar"
          label-text="Tamanho do tênis (outro)"
          close-on-select
          :multiple-option="false"
          show-label
          :options="shoeSizes"
          :value="student.shoe_size2"
          :dispatch="setShoeSize2"
          class="mr-5"
        />
      </div> -->
    </div>
    <div class="grid grid-cols-4 gap-5 mt-10">
      <div class="col-span-4">
        <BaseText text="Dados de Conta Bancária" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-3">
        <Multiselect
          id="select-bank-name"
          select-placeholder="Selecione o seu banco"
          label-text="Nome e código do Banco"
          :value="student.bank_name"
          close-on-select
          show-label
          :multiple-option="false"
          :options="bankNames"
          :error-message="getErrorMessage('student.bank_name', student.bank_name)"
          :dispatch="setBankName"
          class="mr-5"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="agency"
          :value="student.agency"
          input-placeholder="Número da Agência"
          show-label
          label-text="Agência"
          dispatch="Student/setAgency"
        />
      </div>
      <div class="col-span-2">
        <BaseInput
          active
          input-name="account"
          :value="student.account"
          input-placeholder="Informe o número da conta sem o dígito"
          show-label
          label-text="Conta"
          dispatch="Student/setAccount"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="account_digit"
          :value="student.account_digit"
          input-placeholder="Informe o dígito da conta"
          show-label
          label-text="Dígito"
          dispatch="Student/setAccountDigit"
        />
      </div>
      <div>
        <Multiselect
          id="select-account-type"
          select-placeholder="Selecione o tipo da conta"
          label-text="Tipo da Conta"
          :value="student.account_type"
          close-on-select
          show-label
          :multiple-option="false"
          :options="accountTypes"
          :error-message="getErrorMessage('student.account_type', student.account_type)"
          :dispatch="setAccountType"
          class="mr-5"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 mt-10">
      <div>
        <BaseText text="dados do responsável e familiares" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
    </div>
    <div class="mt-5">
      <BaseText type-text="custom" weight="normal" color="quantum" size="base">
        Informações da MÃE e FILHOS (quando possuir) são OBRIGATÓRIOS. Informações de parentes próximos também
        devem ser incluídos.
      </BaseText>
    </div>
    <div :key="index" v-for="(relative, index) in relatives" class="grid grid-cols-4 gap-5 mt-10">
      <div>
        <Multiselect
          id="select-degree-kinship"
          select-placeholder="Selecionar"
          label-text="grau de parentesco"
          close-on-select
          show-label
          :multiple-option="false"
          :options="mountDegreeKinships(index)"
          :value="relative.degree_kinship"
          :error-message="
            getErrorMessage(
              'student.relatives.degree_kinship',
              student.relatives_attributes[index].degree_kinship
            )
          "
          required
          :dispatch="setDegreeKinship"
          class="mr-5"
        />
      </div>
      <div class="flex items-center">
        <AppRadioButton
          :id="'responsible-' + index"
          :values="[
            {
              name: 'responsible',
              value: index,
              description: 'Marcar como responsável',
            },
          ]"
          dispatch="Student/setResponsible"
        />
      </div>
      <div class="flex items-center col-span-2">
        <AppRadioButton
          :id="'reference_person-' + index"
          :values="[
            {
              name: 'reference_person',
              value: index,
              description: 'Marcar como pessoa de referência',
            },
          ]"
          dispatch="Student/setReferencePerson"
        />
      </div>
      <div class="col-span-2">
        <BaseInput
          active
          input-name="name-relative"
          :value="student.relatives_attributes[index].name"
          :error-message="getErrorMessage('student.relatives.name', student.relatives_attributes[index].name)"
          show-label
          required
          label-text="nome completo"
          dispatch="Student/setRelativeName"
          :payload="{ index }"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="rg-relative"
          :value="student.relatives_attributes[index].rg"
          show-label
          input-placeholder="0000000000000"
          label-text="rg"
          dispatch="Student/setRelativeRg"
          :payload="{ index }"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="cpf-relative"
          :value="student.relatives_attributes[index].cpf"
          :error-message="getErrorMessage('student.relatives.cpf', student.relatives_attributes[index].cpf)"
          show-label
          input-placeholder="000.000.000-000"
          mask="###.###.###-##"
          label-text="cpf"
          dispatch="Student/setRelativeCpf"
          :payload="{ index }"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="cellphone_number-relative"
          :value="student.relatives_attributes[index].cellphone_number"
          show-label
          input-placeholder="(85) 90000.0000"
          mask="(##) #####.####"
          label-text="telefone"
          :error-message="
            getErrorMessage(
              'student.relatives.cellphone_number',
              student.relatives_attributes[index].cellphone_number
            )
          "
          dispatch="Student/setRelativeCellphoneNumber"
          :payload="{ index }"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="email-relative"
          :value="student.relatives_attributes[index].email"
          show-label
          input-placeholder="digiteseu@email.com"
          label-text="e-mail"
          :error-message="
            getErrorMessage('student.relatives.email', student.relatives_attributes[index].email)
          "
          dispatch="Student/setRelativeEmail"
          :payload="{ index }"
        />
      </div>
      <div class="flex items-end col-span-2">
        <BaseInput
          active
          input-name="birth-year-relative"
          :value="student.relatives_attributes[index].birth_date"
          show-label
          input-placeholder="Ano"
          label-text="Data de Nascimento"
          input-type="date"
          dispatch="Student/setRelativeBirthDate"
          :payload="{ index }"
        />
        <div style="margin-left: 345px">
          <a
            v-if="index >= 1"
            href="javascript:void(0)"
            class="text-burnedYellow underline"
            @click="deleteRelative"
          >
            <AppMaterialIcon icon-name="delete" icon-size="36" class="align-bottom" />
          </a>
        </div>
      </div>

      <div class="col-span-4 mt-5">
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
    </div>

    <div class="mt-5">
      <a href="javascript:void(0)" class="text-burnedYellow underline" @click="addRelative">
        <AppMaterialIcon icon-name="add_circle" icon-size="36" class="align-middle" />
        <BaseText
          text="Adicionar familiar"
          type-text="custom"
          weight="medium"
          size="sm"
          class="align-middle mr-2"
        />
      </a>
    </div>
  </div>
</template>

<script>
import AppCheckBox from "../components/AppCheckBox.vue";
import AppMaterialIcon from "../components/AppMaterialIcon.vue";
import AppRadioButton from "../components/AppRadioButton.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseText from "../components/BaseText.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    AppCheckBox,
    AppMaterialIcon,
    AppRadioButton,
    BaseInput,
    BaseText,
    Multiselect,
  },

  created() {
    this.$store.dispatch("Student/loadYears");
    this.$store.dispatch("Student/loadCivilStates");
    this.$store.dispatch("Student/loadStates");

    if (this.student.state) {
      this.$store.dispatch("Student/loadCities", this.student.state.id);
    }

    let city_id = localStorage.city_id;
    if (city_id) {
      this.$store.dispatch("Student/loadNeighborhoods", city_id);
      this.$store.dispatch("Student/loadSeasons", city_id);
      this.$store.dispatch("Student/loadEquipment", city_id);
      this.$store.dispatch("Student/loadProfessionalQualifications", city_id);
      this.$store.dispatch("Student/loadSports", city_id);
    }
    this.$store.dispatch("Student/loadCompletedSeries");
    this.$store.dispatch("Student/loadShirtSizes");
    this.$store.dispatch("Student/loadAccountTypes");
    this.$store.dispatch("Student/loadBankNames");
    this.$store.dispatch("Student/loadPantsSizes");
    this.$store.dispatch("Student/loadQualificationStatus");
    this.$store.dispatch("Student/loadShoeSizes");
    this.$store.dispatch("Student/loadDegreeKinships");
    this.$store.dispatch("Student/loadInstitutionalReferrals");
    this.$store.dispatch("Races/loadRaces");
    this.$store.dispatch("Deficiencies/loadDeficiencies");
    this.$store.dispatch("Student/loadNumbersSons");
    this.$store.dispatch("Student/loadMonthPregnants");
  },

  data() {
    return {
      anos: [],
      ano: null,
    };
  },

  computed: {
    ...mapGetters({
      getCivilState: "Student/getCivilState",
      getState: "Student/getState",
      getCity: "Student/getCity",
      getNeighborhood: "Student/getNeighborhood",
      getSeason: "Student/getSeason",
      getEquipament: "Student/getEquipament",
      getCompletedSerie: "Student/getCompletedSerie",
      getProfessionalQualifications: "Student/getProfessionalQualifications",
      getSports: "Student/getSports",
      getShirtSize: "Student/getShirtSize",
      getAccountType: "Student/getAccountType",
      getBankName: "Student/getBankName",
      getQualificationStatus: "Student/getQualificationStatus",
      getPantsSize: "Student/getPantsSize",
      getShoeSize: "Student/getShoeSize",
      getDegreeKinship: "Student/getDegreeKinship",
      getInstitutionalReferral: "Student/getInstitutionalReferral",
      getErrorMessage: "Student/getErrorMessage",
      getDay: "Student/getDay",
      getMonth: "Student/getMonth",
      getYear: "Student/getYear",
      getNumbersSons: "Student/getNumbersSons",
      getMonthPregnant: "Student/getMonthPregnant",
    }),
    ...mapState({
      city: (state) => state.City.city,
      races: (state) => state.Races.races,
      deficiencies: (state) => state.Deficiencies.deficiencies,
      days: (state) => state.Student.days,
      months: (state) => state.Student.months,
      years: (state) => state.Student.years,
      civilStates: (state) => state.Student.civilStates,
      seasons: (state) => state.Seasons.seasons,
      states: (state) => state.Student.states,
      cities: (state) => state.Student.cities,
      neighborhoods: (state) => state.Student.neighborhoods,
      student: (state) => state.Student,
      equipmentAll: (state) => state.Student.equipmentAll,
      equipment: (state) => state.Student.equipment,
      season_id: (state) => state.Student.season_id,
      completedSeries: (state) => state.Student.completedSeries,
      institutionalReferral: (state) => state.Student.institutionalReferral,
      shirtSizes: (state) => state.Student.shirtSizes,
      accountTypes: (state) => state.Student.accountTypes,
      bankNames: (state) => state.Student.bankNames,
      qualificationStatus: (state) => state.Student.qualificationStatus,
      pantsSizes: (state) => state.Student.pantsSizes,
      shoeSizes: (state) => state.Student.shoeSizes,
      professionalQualifications: (state) => state.Student.professionalQualifications,
      sports: (state) => state.Student.sports,
      relatives: (state) => state.Student.relatives_attributes,
      degreeKinships: (state) => state.Student.degreeKinships,
      day: (state) => state.Student.day,
      month: (state) => state.Student.month,
      year: (state) => state.Student.year,
      season: (state) => state.Season.season,
      numbersSons: (state) => state.Student.numbersSons,
      monthPregnant: (state) => state.Student.monthPregnant,
    }),
  },
  methods: {
    ...mapActions({
      setDay: "Student/setDay",
      setMonth: "Student/setMonth",
      setYear: "Student/setYear",
      setCivilState: "Student/setCivilState",
      setRace: "Student/setRace",
      setDeficiencies: "Student/setDeficiencies",
      setState: "Student/setState",
      setCity: "Student/setCity",
      setNeighborhood: "Student/setNeighborhood",
      setSeason: "Student/setSeason",
      setEquipment: "Student/setEquipment",
      setCompletedSeries: "Student/setCompletedSeries",
      setProfessionalQualifications: "Student/setProfessionalQualifications",
      setInstitutionalReferral: "Student/setInstitutionalReferral",
      setSports: "Student/setSports",
      setShirtSize: "Student/setShirtSize",
      setAccountType: "Student/setAccountType",
      setBankName: "Student/setBankName",
      setQualificationStatus: "Student/setQualificationStatus",
      setPantsSize: "Student/setPantsSize",
      setShoeSize1: "Student/setShoeSize1",
      setShoeSize2: "Student/setShoeSize2",
      setDegreeKinship: "Student/setDegreeKinship",
      setNumberSons: "Student/setNumberSons",
      setRelativeBirthDate: "Student/setRelativeBirthDate",
      editFormStudent: "ModalInscription/editFormStudent",
      setMonthPregnant: "Student/setMonthPregnant",
    }),
    addRelative() {
      this.$store.dispatch("Student/addRelative");
    },
    deleteRelative() {
      this.$store.dispatch("Student/deleteRelative");
    },

    mountDegreeKinships(index) {
      return this.degreeKinships.map((degreeKinship) => {
        return { index, ...degreeKinship };
      });
    },
    isSon(index) {
      if (this.student.relatives_attributes[index].degree_kinship != null) {
        let degree_kinship = this.student.relatives_attributes[index].degree_kinship.id;
        if (degree_kinship == "5" || degree_kinship == "6") {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
