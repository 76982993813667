<template>
  <div>
    <BaseModal headerColor="#318d3a" v-if="open">
      <template v-slot:header>
        <div class="flex justify-end">
          <div class="bg-white rounded-full w-7 h-7 flex items-center justify-center">
            <AppButtonIcon for-close dispatch="Default/toggleModalPreInscription" />
          </div>
        </div>
        <div class="px-5 pb-7 text-center md:text-left">
          <img class="w-3/4 md:w-1/2 mx-auto md:mx-0" src="../assets/images/logo-inicio.png" alt="" />
        </div>
      </template>
      <template v-slot:body>
        <div class="container1 h-[80vh] md:h-[500px] w-full overflow-hidden">
          <div class="container2 w-full h-full overflow-y-auto px-4">
            <div class="m-5">
              <BaseText
                text="Inscrição"
                type-text="custom"
                weight="medium"
                mode="capitalize"
                color="burnedYellow"
                size="2xl md:3xl"
                class="text-center md:text-left"
              />
            </div>
            <div class="m-5 text-sm md:text-base leading-relaxed">
              <BaseText type-text="custom" weight="normal" color="quantum" size="base">
                Preencha os dados abaixo e fique atento aos dados obrigatórios. Vale lembrar que os critérios
                para participar do projeto são: Adolescentes e jovens com idade entre 15 e 22 anos, não
                matriculados na rede municipal ou estadual de ensino, que não estejam trabalhando formalmente,
                e que residam em uma das
                <Tooltip
                  :text="getTextTooltip"
                  backgroundColor="#318d3a"
                  textColor="#fff"
                  borderRadius="8px"
                  padding="8px"
                  position="top"
                  maxWidth="500px"
                  minWidth="300px"
                  maxHeight="200px"
                  fontSize="12px"
                  fadeDuration="500"
                >
                  <a href="javascript:void(0)">
                    <BaseText type-text="custom" weight="bold" color="quantum" size="base">
                      áreas de atuação do Projeto.
                    </BaseText>
                  </a>
                </Tooltip>
              </BaseText>
            </div>
            <div class="mr-6 flex justify-center items-center" v-if="false">
              <AppButtonUploadImage
                id="foto"
                text="Fotos"
                typeDocument="cpf"
                name="foto"
                dispatch="Student/setAvatar"
              />
            </div>
            <div class="flex justify-end items-center">
              <div class="mr-5">
                <a
                  href="/docs/Ficha_de_Matricula.pdf"
                  target="_blank"
                  class="text-ashes-dark underline hover:text-burnedYellow"
                >
                  <BaseText
                    text="Imprimir formulário em branco"
                    type-text="custom"
                    weight="medium"
                    size="sm"
                    class="align-middle mr-2"
                  />
                  <AppMaterialIcon icon-name="print" class="align-middle" />
                </a>
              </div>
            </div>
            <FormStudent />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="grid grid-cols-2 gap-4 mx-5">
          <div class="justify-self-start">
            <AppButtonEmpty
              text="descartar"
              typeButton="danger"
              dispatch="Default/toggleModalPreInscription"
            />
          </div>
          <div class="justify-self-end">
            <AppButtonEmpty text="Finalizar" typeButton="success" dispatch="Default/saveInscription" />
          </div>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import FormStudent from "./FormStudent.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonUploadImage from "../components/AppButtonUploadImage.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import AppMaterialIcon from "../components/AppMaterialIcon.vue";
import BaseModal from "../components/BaseModal.vue";
import BaseText from "../components/BaseText.vue";
import Tooltip from "@/components/Tooltip.vue";

export default {
  components: {
    AppButtonEmpty,
    AppButtonIcon,
    AppButtonUploadImage,
    AppMaterialIcon,
    BaseModal,
    BaseText,
    FormStudent,
    Tooltip,
  },
  props: {
    open: {
      type: Boolean,
    },
  },
  computed: {
    getTextTooltip() {
      return (
        "Áreas de atuação do Projeto em cada município:\n\n" +
        "Fortaleza:\n" +
        "Cais do Porto, Mucuripe, Vicente Pinzón, Lagamar, Barra do Ceará, Carlito Pamplona, Moura Brasil,\n" +
        "Antônio Bezerra, Floresta, Quintino Cunha, Vila Velha, Autran Nunes, Bonsucesso, Genibaú, Granja Portugal,\n" +
        "Pici, Bom Jardim, Granja Lisboa, Manoel Sátiro, Siqueira, Canindezinho, Barroso, Conjunto Palmeiras,\n" +
        "Curió, Jangurussu, Messejana, Passaré, Mondubim, Planalto Ayrton Senna, Serrinha, José Walter;\n\n" +
        "Maracanaú:\n" +
        "Pajuçara, Boa Esperança, Pajuçara Park, Parque Tropical, Menino Jesus de Praga, Jardim Bandeirante;\n\n" +
        "Caucaia:\n" +
        "Parque Potira, Marechal Rondon, Conjunto Metropolitano, Araturi;\n\n" +
        "Maranguape:\n" +
        "Outra Banda, Novo Maranguape I, Novo Maranguape II, Centro, Guabiraba;\n\n" +
        "Sobral:\n" +
        "Residencial Nova Caiçara, Terrenos Novos, Vila União, Sumaré, Dom José, Padre Palhano,\n" +
        "parte do Centro (Tamarindo);\n\n" +
        "Itapipoca:\n" +
        "Cruzeiro, Flores, Maranhão, Violete."
      );
    },
  },
};
</script>

<style scoped>
.container1 {
  width: 100%;
}

.container2 {
  width: 100%;
}
</style>
