<template>
  <div class="font-system" v-if="permit">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">Reinserção Escolar</div>
      <div class="text-ashes-hover font-medium text-xl mx-2 col-span-2" v-if="inscriptions.length > 0">
        {{ getTotalRegisters }}
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between p-4 items-end mx-12 my-6 gap-4">
      <div class="w-52">
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          select-placeholder="Selecionar edição"
          :multiple-option="false"
          :value="season"
          :options="seasons"
          label-text="Edição"
          :dispatch="setSeason"
          required
        />
      </div>
    </div>
    <div id="menus_school_reinsertion_main_page" class="mx-16 flex" v-if="season">
      <div>
        <a @click="changeTab('notReinserted')" href="javascript:void(0)">
          <BaseText
            text="Público da Reinserção Escolar"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorNotReinserted"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div>
        <a @click="changeTab('reinserted')" href="javascript:void(0)">
          <BaseText
            text="Reinseridos"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorReinserted"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div>
        <a @click="changeTab('vacancy_request')" href="javascript:void(0)">
          <BaseText
            text="Solicitações de Vagas"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorVacancyRequest"
          />
        </a>
      </div>
      <!--      <div class="border-l mx-10"></div>-->
      <!--      <div>-->
      <!--        <a @click="changeTab('plannedAwarenessActivity')" href="javascript:void(0)">-->
      <!--          <BaseText-->
      <!--            text="Atividades de Sensibilização Planejadas"-->
      <!--            typeText="custom"-->
      <!--            weight="semibold"-->
      <!--            mode="uppercase"-->
      <!--            size="sm"-->
      <!--            :color="setColorPlannedAwarenessActivity"-->
      <!--          />-->
      <!--        </a>-->
      <!--      </div>-->
    </div>
    <div id="list-school-reinsertions">
      <div class="mx-12">
        <NotReinsertedsMainPage :data="inscriptions" :open="toggleSchoolReinsertionNotReinserted" />
        <ReinsertedsMainPage :data="inscriptions" :open="toggleSchoolReinsertionReinserted" />
        <PlannedAwarenessActivityMainPage
          :data="inscriptions"
          :open="toggleSchoolReinsertionPlannedAwarenessActivity"
        />
        <VacancyRequest :open="toggleSchoolReinsertionNotReinsertedVacancyRequest" />
      </div>
    </div>
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import NotReinsertedsMainPage from "../views/school_reinsertion_pages/not_reinserteds/NotReinsertedsMainPage.vue";
import ReinsertedsMainPage from "../views/school_reinsertion_pages/reinserteds_pages/ReinsertedsMainPage.vue";
import PlannedAwarenessActivityMainPage from "../views/school_reinsertion_pages/planned_awareness_activity_pages/PlannedAwarenessActivityMainPage.vue";
import BaseText from "../components/BaseText.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import ProhibitedAccessPage from "../components/ProhibitedAccessPage.vue";
import VacancyRequest from "@/views/school_reinsertion_pages/VacancyRequest.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    NotReinsertedsMainPage,
    PlannedAwarenessActivityMainPage,
    ReinsertedsMainPage,
    Multiselect,
    ProhibitedAccessPage,
    VacancyRequest,
  },
  data() {
    return {
      permitCreate: false,
      permit: false,
      permitUp: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setSeason: "SchoolReinsertions/setSeason",
    }),
    clearFields() {
      this.$store.dispatch("SchoolReinsertions/clearQueryNotReinserteds");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    changeTab(value) {
      this.clearFields();
      this.$store.dispatch("SchoolReinsertions/setTabSchoolReinsertionView", value);
    },
  },
  created() {
    this.$store.dispatch("SchoolReinsertions/loadSeries");
    this.$store.dispatch("SchoolReinsertions/loadTeachingModalities");
    this.$store.dispatch("SchoolReinsertions/loadAdministrativeDependencies");
    this.$store.dispatch("SchoolReinsertions/loadInstitutionalReferrals");
    this.$store.dispatch("SchoolReinsertions/loadFacilities");
    this.$store.dispatch("SchoolReinsertions/loadSeasons");
    this.$store.dispatch("UtilFunctions/loadCities");
    if (sessionStorage.season_information) {
      const season_information = JSON.parse(sessionStorage.season_information);
      this.$store.dispatch("SchoolReinsertions/setSeason", season_information);
      this.$store.dispatch("VacancyRequests/setSeason", season_information);
    }

    const user = JSON.parse(localStorage.userData);
    this.$store.dispatch("SchoolReinsertions/setUser", user);
    this.$store.dispatch("SchoolReinsertions/setUserAreas", user.areas);
    this.$store.dispatch("VacancyRequests/setUserAreas", user.areas);
    this.$store.dispatch("PlannedAwarenessActivities/setUserAreas", user.areas);
    if (user.equipments.length > 0) {
      this.$store.dispatch("SchoolReinsertions/setUserEquipments", user.equipments);
      this.$store.dispatch("PlannedAwarenessActivities/setUserEquipments", user.equipments);
    }
    this.canAcces("school_reinsertion_index").then((resp) => {
      this.permit = resp;
    });
    if (this.season) {
      this.$store.dispatch("SchoolReinsertions/loadInscriptionsForGeneralMenus");
    }
  },

  computed: {
    ...mapState({
      seasons: (state) => state.SchoolReinsertions.seasons,
      season: (state) => state.SchoolReinsertions.season,
      inscriptions: (state) => state.SchoolReinsertions.inscriptions,

      tabSchoolReinsertionView: (state) => state.SchoolReinsertions.tabSchoolReinsertionView,
      toggleSchoolReinsertionNotReinserted: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionNotReinserted,
      toggleSchoolReinsertionReinserted: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionReinserted,
      toggleSchoolReinsertionVacancyRequest: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionVacancyRequest,
      toggleSchoolReinsertionPlannedAwarenessActivity: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionPlannedAwarenessActivity,
      toggleSchoolReinsertionNotReinsertedVacancyRequest: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionNotReinsertedVacancyRequest,

      offset: (state) => state.SchoolReinsertions.offset,
      totalPerPage: (state) => state.SchoolReinsertions.totalPerPage,
      inscriptionsTotal: (state) => state.SchoolReinsertions.inscriptionsTotal,
    }),
    setColorNotReinserted() {
      return this.tabSchoolReinsertionView === "notReinserted" ? "burnedYellow" : "ashes-hover";
    },
    setColorReinserted() {
      return this.tabSchoolReinsertionView === "reinserted" ? "burnedYellow" : "ashes-hover";
    },
    setColorVacancyRequest() {
      return this.tabSchoolReinsertionView === "vacancy_request" ? "burnedYellow" : "ashes-hover";
    },
    setColorPlannedAwarenessActivity() {
      return this.tabSchoolReinsertionView === "plannedAwarenessActivity" ? "burnedYellow" : "ashes-hover";
    },
    getTotalRegisters() {
      return `Quantidade de Registros Encontrados: ${this.inscriptionsTotal}`;
    },
  },
  watch: {
    season: function () {
      this.$store.dispatch("SchoolReinsertions/loadInscriptionsForGeneralMenus");
    },
  },
};
</script>
