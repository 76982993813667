import { getAxios, baseURL } from "./Api";

export async function getArticulatorEquipments() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`equipamentos_por_articulador`);
}

export async function createArticulatorEquipment(articulator_equipment) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/equipamentos_por_articulador", articulator_equipment);
}

export async function updateArticulatorEquipment(id, articulator_equipment) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`equipamentos_por_articulador/${id}`, articulator_equipment);
}

export async function destroyArticulatorEquipment(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`equipamentos_por_articulador/${id}`);
}
