<template>
  <div class="grid auto-grid-auto p-10 gap-5">
    <div>
      <BaseText
        :text="getTitle()"
        typeText="custom"
        weight="semibold"
        mode="capitalize"
        :italic="false"
        size="3xl"
        color="ashes-hover"
      />
    </div>
    <!-- <div>
      <router-link :to="{ name: 'turma', params: { id: meeting.team.id } }">
        <BaseText
          text="<- Voltar"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="capitalize"
          size="sm"
          color="carmesim"
        />
      </router-link>
    </div> -->
    <div>
      <a href="javascript:void(0)" @click="goToTeam()">
        <BaseText
          text="<- Voltar"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="capitalize"
          size="sm"
          color="carmesim"
        />
      </a>
    </div>
    <div class="grid grid-cols-2">
      <div class="grid grid-flow-col auto-cols-max gap-2">
        <div class="place-self-center">
          <div :class="iconClass">
            <AppMaterialIcon
              iconName="book"
              iconType="outlined"
              class="place-self-center text-white"
              iconSize="36"
            />
          </div>
        </div>
        <div class="gridf grid-rols-3">
          <div>
            <BaseText
              :text="'Turma ' + meeting.team.code"
              typeText="custom"
              weight="medium"
              :italic="false"
              size="sm"
              color="ashes-dark"
            />
          </div>
          <div>
            <BaseText
              :text="meeting.name + ' - ' + meeting.team.discipline.name"
              typeText="custom"
              weight="extrabold"
              :italic="false"
              size="2xl"
              color="ashes-dark"
            />
          </div>
          <div class="grid grid-flow-col auto-cols-max gap-2 text-burnedYellow">
            <div>
              <BaseText
                text="Instrutor |"
                typeText="custom"
                weight="medium"
                :italic="false"
                size="sm"
                color="ashes-dark"
              />
            </div>
            <div>
              <BaseText :text="getTeacherName()" typeText="custom" weight="bold" :italic="false" size="sm" />
            </div>
            <div>
              <BaseText
                text="Equipamento |"
                typeText="custom"
                weight="medium"
                :italic="false"
                size="sm"
                color="ashes-dark"
              />
            </div>
            <div>
              <BaseText
                :text="getEquipmentName()"
                typeText="custom"
                weight="bold"
                :italic="false"
                size="sm"
              />
            </div>
            <div>
              <BaseText
                text="Data/Turno |"
                typeText="custom"
                weight="medium"
                :italic="false"
                size="sm"
                color="ashes-dark"
              />
            </div>
            <div>
              <BaseText
                :text="meeting.date + '/' + meeting.shifts.name"
                typeText="custom"
                weight="bold"
                :italic="false"
                size="sm"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-flow-row auto-rows-max">
      <div>
        <BaseText
          text="Descrição"
          typeText="custom"
          weight="semibold"
          mode="uppercase"
          :italic="false"
          size="sm"
          color="burnedYellow"
        />
      </div>
      <div class="border-t bg-ashes-hover"></div>
      <div>
        <BaseText
          v-if="meeting.description"
          :text="meeting.description"
          typeText="custom"
          weight="semibold"
          mode="uppercase"
          :italic="false"
          size="md"
          color="ashes-dark"
        />
      </div>
    </div>
    <PresenceCards :data="presences" />
    <ModalPresenceJustification :open="openJustificationModal" />
    <DeletePresenceConfirmation :open="modalDeletePresenceConfirmationOpen" />
  </div>
</template>

<script>
import BaseText from "../components/BaseText.vue";
import AppMaterialIcon from "../components/AppMaterialIcon.vue";
import PresenceCards from "./list/PresenceCards.vue";
import ModalPresenceJustification from "./ModalPresenceJustification.vue";
import DeletePresenceConfirmation from "./alerts/DeletePresenceConfirmation.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppMaterialIcon,
    PresenceCards,
    ModalPresenceJustification,
    DeletePresenceConfirmation,
  },
  created() {
    let id = this.$route.params.id;
    this.$store.dispatch("Meeting/loadMeeting", id);
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    getTeacherName() {
      if (this.meeting.teacher != null) {
        return this.meeting.teacher.name;
      } else {
        return "Sem Instrutor(a) Cadastrado(a)";
      }
    },
    getTitle() {
      return this.meeting.suspended
        ? this.meeting.name + " - " + this.meeting.team.discipline.name + " [SUSPENSO]"
        : this.meeting.name + " - " + this.meeting.team.discipline.name;
    },
    goToTeam() {
      this.$router.push({
        name: "turma",
        params: { id: this.meeting.team.id },
      });
      this.$store.dispatch("Meeting/goToTeam");
    },
    getEquipmentName() {
      return this.meeting.equipment ? this.meeting.equipment.name : "Equipamento não informado";
    },
  },
  computed: {
    ...mapState({
      presences: (state) => state.Meeting.presences,
      meeting: (state) => state.Meeting.meeting,
      openJustificationModal: (state) => state.Presence.openJustificationModal,
      modalDeletePresenceConfirmationOpen: (state) => state.Presence.modalDeletePresenceConfirmationOpen,
    }),
    iconClass() {
      let css = [];
      let bgColor = "";
      if (this.meeting.team.discipline.id == 1) {
        bgColor = "bg-yellow-300";
      }
      if (this.meeting.team.discipline.id == 2) {
        bgColor = "bg-burnedYellow-classic";
      }
      if (this.meeting.team.discipline.id == 3) {
        bgColor = "bg-green-primary";
      }
      if (this.meeting.team.discipline.id == 4) {
        bgColor = "bg-blue-primary";
      }
      if (this.meeting.team.discipline.id == 5) {
        bgColor = "bg-rose-primary";
      }

      css.push(bgColor);
      css.push("rounded-full");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");

      return css;
    },
  },
};
</script>
