import { getAxios, baseURL } from "./Api";

export async function getPlannedAwarenessActivities(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `atividades_sensibilizacao_planejadas`;
  return api.get(url, { params: filters });
}

export async function getPlannedAwarenessActivityById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`atividades_sensibilizacao_planejadas/${_id}`);
}

export async function createPlannedAwarenessActivity(planned_awareness_activity) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/atividades_sensibilizacao_planejadas", planned_awareness_activity);
}

export async function updatePlannedAwarenessActivity(id, planned_awareness_activity) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`atividades_sensibilizacao_planejadas/${id}`, planned_awareness_activity);
}

export async function destroyPlannedAwarenessActivity(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`atividades_sensibilizacao_planejadas/${id}`);
}
