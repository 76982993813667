import {
  getFacilities,
  getFacilityById,
  createFacility,
  updateFacility,
  destroyFacility,
  getFacilitiesForExportSpreadsheet,
} from "../../service/FacilitiesApi";
import { getForwardingPolicies } from "../../service/ForwardingPoliciesApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

const state = {
  facility: {
    id: null,
    name: null,
    address: null,
    forwarding_policy_id: null,
    city_id: null,
  },
  facilities: [],
  forwarding_policies: [],
  facilities_spreadsheet: [],

  forwarding_policy: null,
  city: null,
  state: null,

  hasSpreadsheetResponse: true,
  json_data: null,

  modalToggleFacilities: false,
  modalToggleViewFacilities: false,
  modalConfirmDeleteFacility: false,
  modalToggleExportFacilitySpreadsheet: false,

  facilityId: 0,

  facilityTotal: 0,
  facilityTotalPerPage: 10,
  facilityOffset: 0,

  params: {
    name: null,
    address: null,
    forwarding_policy: null,
    city: null,
    state: null,
  },
};

const mutations = {
  // --> MUTATIONS - DATA COMING FROM API <--
  setFacilitiesMutation: (state, facilities) => {
    state.facilities = facilities;
  },
  setFacilitiesSpreadsheetMutation: (state, facilities_spreadsheet) => {
    state.facilities_spreadsheet = facilities_spreadsheet;
  },
  setForwardingPoliciesMutation: (state, forwarding_policies) => {
    state.forwarding_policies = forwarding_policies;
  },

  // --> MUTATIONS - FIELDS TO THE TABLE <--
  setIdMutation: (state, id) => {
    state.facility.id = id;
  },
  setNameMutation: (state, name) => {
    state.facility.name = name;
  },
  setAddressMutation: (state, address) => {
    state.facility.address = address;
  },
  setStateMutation: (state, value) => {
    state.state = value;
  },
  setForwardingPolicyMutation: (state, forwarding_policy) => {
    state.facility.forwarding_policy_id = forwarding_policy.id;
    state.forwarding_policy = forwarding_policy;
  },
  setCityMutation: (state, city) => {
    state.city = city;
    state.facility.city_id = city ? city.id : null;
  },

  // --> MUTATIONS - PAGINATION <--
  setFacilityTotalPerPage: (state, facilityTotalPerPage) => {
    state.facilityTotalPerPage = facilityTotalPerPage;
  },
  setFacilityOffset: (state, facilityOffset) => {
    state.facilityOffset = facilityOffset;
  },
  setFacilityTotal: (state, facilityTotal) => {
    state.facilityTotal = facilityTotal;
  },

  // --> MUTATIONS - FILTERS <--
  filterListByName: (state, input) => {
    state.params.name = input.value;
  },
  filterListByAddress: (state, input) => {
    state.params.address = input.value;
  },
  filterListByForwardingPolicy: (state, input) => {
    state.params.forwarding_policy = input;
  },
  filterListByCity: (state, input) => {
    state.params.city = input;
  },
  filterListByState: (state, input) => {
    state.params.state = input;
  },

  // --> MUTATIONS - TOGGLES FROM MODALS AND SCREENS <--
  toggleModalFacilities: (state) => {
    state.modalToggleFacilities = !state.modalToggleFacilities;
  },
  toggleModalViewFactoggleModalFacilities: (state) => {
    state.modalToggleViewFacilities = !state.modalToggleViewFacilities;
  },
  toggleModalDeleteFacility: (state) => {
    state.modalConfirmDeleteFacility = !state.modalConfirmDeleteFacility;
  },
  toggleModalExportFacilitySpreadsheet: (state) => {
    state.modalToggleExportFacilitySpreadsheet = !state.modalToggleExportFacilitySpreadsheet;
  },

  // --> MUTATIONS - DELETION <--
  facilityForDeletion: (state, payload) => {
    if (payload) {
      state.facilityId = payload.id;
    }
  },

  // --> MUTATIONS - SPREADSHEET <--
  setJsonDataMutation: (state, json_data) => {
    state.json_data = json_data;
  },
  hasSpreadsheetResponseMutation: (state, hasSpreadsheetResponse) => {
    state.hasSpreadsheetResponse = hasSpreadsheetResponse;
  },
  setFacilitiesToExportMutation: (state, facilities_to_export) => {
    state.json_data = null;
    const arrayData = new Array();
    facilities_to_export.forEach((element, index, array) => {
      let name = array[index].name ? array[index].name : "";
      let address = array[index].address ? array[index].address : "";
      let forwarding_policy = array[index].forwarding_policy ? array[index].forwarding_policy.name : "";

      const arrayItem = new Array();
      arrayItem["name"] = name;
      arrayItem["address"] = address;
      arrayItem["forwarding_policy"] = forwarding_policy;
      arrayData.push(arrayItem);
    });
    state.json_data = arrayData;
    state.hasSpreadsheetResponse = true;
    state.modalToggleExportFacilitySpreadsheet = true;
  },

  // --> MUTATIONS - CLEARS <--
  clearQuery: (state) => {
    state.params.name = null;
    state.params.address = null;
    state.params.city = null;
    state.params.state = null;
    state.params.forwarding_policy = null;
    state.facilities = [];
  },
  clearFields: (state) => {
    state.facility.id = null;
    state.facility.forwarding_policy_id = null;
    state.facility.city_id = null;
    state.facility.name = null;
    state.facility.address = null;
    state.forwarding_policy = null;
    state.city = null;
    state.state = null;
  },
};

function getLoadFacilitiesParams(rootGetters) {
  const params = {
    name: state.params.name,
    address: state.params.address,
    forwarding_policy_id: state.params.forwarding_policy ? state.params.forwarding_policy.id : null,
    city_id:
      state.params.city && state.params.city.id
        ? state.params.city.id
        : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
        ? rootGetters["UtilFunctions/cityIds"]
        : null,
    perPage: state.facilityTotalPerPage,
    offset: state.facilityOffset,
    by: "facilities.name",
    order: "ASC",
  };
  return params;
}

const actions = {
  // --> ACTIONS & LOADS - DATA COMING FROM API <--
  loadFacilities: ({ commit, rootGetters }, from) => {
    const params = getLoadFacilitiesParams(rootGetters);

    getFacilities(params)
      .then((response) => {
        commit("setFacilitiesMutation", response.data.facilities);
        commit("setFacilityTotal", response.data.facilities_total);
        if (parseInt(response.data.facilities_total, 10) < 1 && from != "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadFacilitiesSpreadsheet: async ({ commit }) => {
    commit("hasSpreadsheetResponseMutation", false);
    const params = getLoadFacilitiesParams();
    await getFacilitiesForExportSpreadsheet(params)
      .then(async (response) => {
        commit("setFacilitiesSpreadsheetMutation", response.data.facilities);
        if (state.facilities_spreadsheet.length > 0) {
          await commit("setFacilitiesToExportMutation", response.data.facilities);
        }
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadForwardingPolicies: ({ commit }) => {
    getForwardingPolicies()
      .then((response) => {
        commit("setForwardingPoliciesMutation", response.data.forwarding_policies);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getFacilityById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.facility.id);
        commit("setNameMutation", response.data.facility.name);
        commit("setCityMutation", response.data.facility.city);
        commit("setAddressMutation", response.data.facility.address);
        commit("setForwardingPolicyMutation", response.data.facility.forwarding_policy);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },

  // --> ACTIONS - FILTERS <--
  filterListByName: ({ commit }, payload) => {
    commit("filterListByName", payload);
  },
  filterListByAddress: ({ commit }, payload) => {
    commit("filterListByAddress", payload);
  },
  filterListByForwardingPolicy: ({ commit }, payload) => {
    commit("filterListByForwardingPolicy", payload);
  },
  filterListByState: ({ commit }, payload) => {
    commit("filterListByState", payload);
  },
  filterListByCity: ({ commit }, payload) => {
    commit("filterListByCity", payload);
  },

  // --> ACTIONS - CLEAR <--
  clearQuery: ({ commit }, payload) => {
    commit("clearQuery", payload);
  },

  // --> ACTIONS - TOGGLES FROM MODALS AND SCREENS <--
  toggleModalFacilities: ({ commit }) => {
    commit("toggleModalFacilities");
  },
  openModalFacilities: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalFacilities");
  },
  closeModalFacilities: ({ commit }) => {
    commit("toggleModalFacilities");
    commit("clearFields");
  },
  openEditModalFacilities: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalFacilities");
  },
  closeEditModalFacilities: ({ commit }) => {
    commit("toggleModalFacilities");
    commit("clearFields");
  },
  toggleModalDeleteFacility: ({ commit }, payload) => {
    commit("toggleModalDeleteFacility");
    if (state.modalConfirmDeleteFacility) {
      commit("facilityForDeletion", payload);
    } else {
      commit("facilityForDeletion", null);
    }
  },
  openExportSpreadsheetModal: ({ commit }) => {
    commit("toggleModalExportFacilitySpreadsheet");
  },
  closeExportSpreadsheetModal: ({ commit }) => {
    commit("toggleModalExportFacilitySpreadsheet");
  },

  // --> ACTIONS - FIELDS TO THE TABLE <--
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setAddress: ({ commit }, payload) => {
    commit("setAddressMutation", payload.value);
  },
  setForwardingPolicy: ({ commit }, payload) => {
    commit("setForwardingPolicyMutation", payload);
  },
  setCity: ({ commit }, payload) => {
    commit("setCityMutation", payload);
  },
  setState: ({ commit }, payload) => {
    commit("setStateMutation", payload);
  },

  // --> ACTIONS - PAGINATION <--
  setFacilityOffset: ({ commit }, payload) => {
    commit("setFacilityOffset", payload.offset);
  },
  setFacilityTotalPerPage: ({ commit }, payload) => {
    commit("setFacilityTotalPerPage", payload.totalPerPage);
  },

  // --> ACTIONS - EXPORT SPREADSHEET <--
  hasSpreadsheetResponse: ({ commit }, payload) => {
    commit("hasSpreadsheetResponseMutation", payload);
  },

  // --> ACTIONS - CRUD <--
  save: ({ commit, dispatch }) => {
    createFacility(state.facility)
      .then(() => {
        Vue.$toast.success("Equipamento cadastrado com sucesso!");
        commit("toggleModalFacilities");
        commit("clearFields");
        dispatch("Facilities/loadFacilities", null, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar o equipamento! " + e.response.data.error);
        console.log(e);
      });
  },
  update: ({ dispatch, commit }) => {
    updateFacility(state.facility.id, state.facility)
      .then(() => {
        Vue.$toast.success("Equipamento atualizado com sucesso!");
        commit("toggleModalFacilities");
        dispatch("Facilities/loadFacilities", null, { root: true });
        commit("clearFields");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyFacility(state.facilityId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Equipamento deletado com sucesso!");
        commit("toggleModalDeleteFacility");
        dispatch("Facilities/loadFacilities", from, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
