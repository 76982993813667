import { getAxios, baseURL } from "./Api";

export async function getOpportunities(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `oportunidades`;
  return api.get(url, { params: filters });
}

export async function getOpportunitiesForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `oportunidades/para_menus`;
  return api.get(url, { params: filters });
}

export async function getOpportunitiesForExport(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `oportunidades/exportar_planilha`;
  return api.get(url, { params: filters });
}

export async function getOpportunityContractingTypes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`oportunidades/tipos_contratacoes`);
}

export async function getOpportunityById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`oportunidades/${_id}`);
}

export async function createOpportunity(opportunity) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/oportunidades", opportunity);
}

export async function updateOpportunity(id, opportunity) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`oportunidades/${id}`, opportunity);
}

export async function destroyOpportunity(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`oportunidades/${id}`);
}
