import { destroyArea, getAreas } from "../../service/AreaApi";
import Vue from "vue";
import router from "../../router/index";

const state = {
  areas: [],
  filterAreas: [],
  modalDeleteAreaConfirmationOpen: false,
  areaIdForDeletion: null,
  areasTotal: 0,
  areasTotalPerPage: 10,
  areasOffset: 0,
  params: {
    name: null,
    state: null,
    city: null,
  },
};

const mutations = {
  setAreas: (state, areas) => {
    state.areas = areas;
    state.filterAreas = areas;
  },
  filterList: (state, input) => {
    let areas = state.areas;
    state.filterAreas = areas.filter((obj) => {
      return obj.name.toLowerCase().indexOf(input.value.toLowerCase()) > -1;
    });
  },
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },
  setFilterCityMutation: (state, city) => {
    state.params.city = city;
  },
  setFilterStateMutation: (state, value) => {
    state.params.state = value;
  },
  toggleModalDeleteAreaConfirmation: (state) => {
    state.modalDeleteAreaConfirmationOpen = !state.modalDeleteAreaConfirmationOpen;
  },
  areaForDeletion: (state, area) => {
    state.areaIdForDeletion = area;
  },
  addArea: (state, area) => {
    state.areas.push(area);
  },
  // --> MUTATIONS - PAGINATION FIELDS <--
  setAreasTotalPerPage: (state, areasTotalPerPage) => {
    state.areasTotalPerPage = areasTotalPerPage;
  },
  setAreasOffset: (state, areasOffset) => {
    state.areasOffset = areasOffset;
  },
  setAreasTotal: (state, areasTotal) => {
    state.areasTotal = areasTotal;
  },
  // --> MUTATIONS - CLEARS <--
  clearQuery: (state) => {
    state.params.name = null;
    state.params.city = null;
    state.params.state = null;
    state.areas = [];
  },
};

const actions = {
  filterList: ({ commit }, payload) => {
    commit("filterList", payload);
  },
  loadAreas: ({ commit }) => {
    const params = {
      name: state.params.name,
      city_id: state.params.city ? state.params.city.id : null,
      per_page: state.areasTotalPerPage,
      offset: state.areasOffset,
    };
    getAreas(params)
      .then((response) => {
        commit("setAreas", response.data.areas);
        commit("setAreasTotal", response.data.total);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  setAreas: ({ commit }, payload) => {
    commit("setAreas", payload);
  },
  addArea: ({ commit }, payload) => {
    commit("addArea", payload);
  },
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  setFilterCity: ({ commit }, payload) => {
    commit("setFilterCityMutation", payload);
  },
  setFilterState: ({ commit }, payload) => {
    commit("setFilterStateMutation", payload);
  },
  openCloseDeleteAreaConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteAreaConfirmation");
    if (state.modalDeleteAreaConfirmationOpen) {
      commit("areaForDeletion", payload.id);
    } else {
      commit("areaForDeletion", null);
    }
  },
  // --> ACTIONS - PAGINATIONS <--
  setAreasTotalPerPage: ({ commit }, payload) => {
    commit("setAreasTotalPerPage", payload.totalPerPage);
  },
  setAreasOffset: ({ commit }, payload) => {
    commit("setAreasOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  deleteArea: ({ commit, state, dispatch, rootState }) => {
    const area_id = state.areaIdForDeletion;

    destroyArea(area_id)
      .then((response) => {
        const areas = state.areas.filter((e) => {
          return e.id !== area_id;
        });
        dispatch("setAreas", areas);
        Vue.$toast.success(response.data.success);
        commit("toggleModalDeleteAreaConfirmation");
        dispatch("Neighborhoods/loadNeighborhoodsWithoutArea", null, { root: true });
        if (rootState.Area.insideArea.id) router.push({ name: "areas" });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
