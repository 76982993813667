import { updatePresence, destroyPresence } from "../../service/PresenceApi";
import Vue from "vue";

const state = {
  openJustificationModal: false,
  justificationEditModal: "",
  presence: {
    id: null,
    presence: null,
    justification: "",
  },
  student: null,
  modalDeletePresenceConfirmationOpen: false,
  presenceForDeletion: null,
};

const mutations = {
  setIdMutation: (state, id) => {
    state.presence.id = id;
  },
  setIsPresentMutation: (state, present) => {
    state.presence.presence = present;
  },
  setJustificationMutation: (state, justification) => {
    state.presence.justification = justification;
  },
  setPresenceMutation: (state, presence) => {
    state.presence = presence;
  },
  setStudentMutation: (state, student) => {
    state.student = student;
  },
  toggleModalMutation: (state) => {
    state.openJustificationModal = !state.openJustificationModal;
  },
  toggleModalDeletePresenceConfirmation: (state) => {
    state.modalDeletePresenceConfirmationOpen = !state.modalDeletePresenceConfirmationOpen;
  },
  // --> MUTATIONS - DELETION <--
  presenceRequestForDeletion: (state, payload) => {
    if (payload) {
      state.presenceForDeletion = payload;
    }
  },
};

const actions = {
  openJustificationModal: ({ commit, dispatch }, payload) => {
    dispatch("ModalInscription/setStudentId", payload.student.id, { root: true });
    dispatch("ModalInscription/setPresenceId", payload.id, { root: true });
    if (payload.student_documents.length > 0) {
      dispatch("ModalInscription/setIdFileStudentJustificationDocument", payload.student_documents[0].id, {
        root: true,
      });
    }
    commit("setStudentMutation", payload.student);
    commit("setPresenceMutation", payload);
    commit("toggleModalMutation");
  },
  closeJustificationModal: ({ commit, dispatch }, payload) => {
    commit("setJustificationMutation", payload);
    dispatch("ModalInscription/setIdFileStudentJustificationDocument", null, {
      root: true,
    });
    commit("toggleModalMutation");
  },
  openCloseDeletePresenceConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeletePresenceConfirmation");
    if (state.modalDeletePresenceConfirmationOpen && payload) {
      commit("presenceRequestForDeletion", payload.id);
    } else {
      commit("presenceRequestForDeletion", null);
    }
  },
  setJustification: ({ commit }, payload) => {
    commit("setJustificationMutation", payload.value);
  },
  setIsPresent: ({ commit }, payload) => {
    commit("setPresenceMutation", payload.presence);
    commit("setIsPresentMutation", payload.value);
    updatePresence(state.presence.id, state.presence)
      .then((response) => {
        Vue.$toast.success(response.data.message);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  setPresence: ({ commit }, payload) => {
    commit("setPresenceMutation", payload);
  },
  updatePresence: async ({ commit, state, dispatch, rootState }) => {
    await updatePresence(state.presence.id, state.presence)
      .then((response) => {
        commit("toggleModalMutation");
        dispatch("ModalInscription/setIdFileStudentJustificationDocument", null, {
          root: true,
        });

        let id = rootState.Meeting.meeting.id;
        dispatch("Meeting/loadMeeting", id, { root: true });

        Vue.$toast.success(response.data.message);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  deletePresence: ({ commit, dispatch, rootState }) => {
    destroyPresence(state.presenceForDeletion)
      .then(() => {
        Vue.$toast.success("Presença deletada com sucesso!");
        commit("toggleModalDeletePresenceConfirmation");
        let id = rootState.Meeting.meeting.id;
        dispatch("Meeting/loadMeeting", id, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
