<template>
  <button type="button" :class="[buttonClass(), iconColor]" @click="onClick">
    <AppMaterialIcon
      :iconName="whatIsMyName()"
      :iconType="iconType"
      :iconSize="iconSize"
      :iconColor="iconColor"
      :hover="hover"
      :title="title"
    />
  </button>
</template>

<script>
import AppMaterialIcon from "./AppMaterialIcon.vue";

export default {
  components: { AppMaterialIcon },

  props: {
    iconName: String,
    iconType: { type: String, default: "material" },
    iconSize: { type: String, default: "24" },
    forClose: { type: Boolean, default: false },
    dispatch: String,
    payload: [String, Number, Object],
    iconColor: { type: String, default: "" },
    hover: { type: String, default: "" },
    title: { type: String, default: "" },
  },

  methods: {
    buttonClass() {
      let css = {
        "focus:outline-none": true,
        "rounded-full": true,
        "leading-3": true,
        "text-ashes-dark": true,
      };

      if (!this.forClose || (this.forClose && this.iconSize === "36")) {
        css = Object.assign(css, {
          "h-10": true,
          "w-10": true,
        });
      } else {
        css = Object.assign(css, {
          "h-7": true,
          "w-7": true,
        });
      }

      let bgAshes = { "hover:bg-ashes": true };
      let textBurnedYellow = { "hover:text-burnedYellow": true };

      css = this.forClose ? Object.assign(css, bgAshes) : Object.assign(css, textBurnedYellow);

      return css;
    },
    onClick(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("on-click", event);
      if (this.dispatch) {
        this.$store.dispatch(this.dispatch, this.payload);
      }
    },
    whatIsMyName() {
      return this.forClose ? "close" : this.iconName;
    },
  },
};
</script>
