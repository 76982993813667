import {
  createMatrixRiskStratification,
  getMatrixRiskStratificationsByInscription,
  destroyMatrixRiskStratification,
} from "../../service/MatrixRiskStratificationsApi";
import { getMatrixSituationsForMenus } from "../../service/MatrixSituationsApi";
import { getMatrixProblemsForMenus } from "../../service/MatrixProblemsApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

const state = {
  matrix_risk_stratification: {
    id: null,
    psychosocial_accompaniment_id: null,
    matrix_situation_id: null,
    inscription_id: null,
    token_group: null,
  },
  matrix_risk_stratifications_by_inscription: [],
  matrix_risk_stratifications_by_inscription_update: null,
  matrix_situations: [],
  matrix_problems: [],
  matrix_situations_array: [],
  matrix_risk_stratifications_by_inscription_filtered: [],
  matrix_to_delete: [],

  matrix_del: [],

  psychosocial_accompaniment: null,
  matrix_situtation: null,

  modalToggleMatrixRiskStratifications: false,
  modalToggleViewMatrixRiskStratifications: false,
  modalConfirmDeleteMatrixRiskStratification: false,
  isUpdate: false,

  matrixRiskStratificationByInscriptionTotal: 0,
  matrixRiskStratificationByInscriptionTotalPerPage: 10,
  matrixRiskStratificationByInscriptionOffset: 0,
};

const mutations = {
  // --> MUTATIONS - DATA COMING FROM API <--
  setMatrixRiskStratificationsByInscriptionMutation: (state, matrix_risk_stratifications_by_inscription) => {
    state.matrix_risk_stratifications_by_inscription = matrix_risk_stratifications_by_inscription;
  },
  setMatrixRiskStratificationsByInscriptionFilteredMutation: (
    state,
    matrix_risk_stratifications_by_inscription_filtered
  ) => {
    state.matrix_risk_stratifications_by_inscription_filtered =
      matrix_risk_stratifications_by_inscription_filtered;
  },
  setMatrixRiskStratificationsByInscriptionUpdateMutation: (
    state,
    matrix_risk_stratifications_by_inscription_update
  ) => {
    state.matrix_risk_stratifications_by_inscription_update =
      matrix_risk_stratifications_by_inscription_update;
  },
  setMatrixSituationsMutation: (state, matrix_situations) => {
    state.matrix_situations = matrix_situations;
  },
  setMatrixProblemsMutation: (state, matrix_problems) => {
    state.matrix_problems = matrix_problems;
  },
  setMatrixToDeleteMutation: (state, matrix_to_delete) => {
    state.matrix_to_delete = matrix_to_delete;
  },

  // --> MUTATIONS - FIELDS TO THE TABLE <--
  setIdMutation: (state, id) => {
    state.matrix_risk_stratification.id = id;
  },
  setInscriptionIdMutation: (state, inscription_id) => {
    state.matrix_risk_stratification.inscription_id = inscription_id;
  },
  setTokenGroupMutation: (state, token_group) => {
    state.matrix_risk_stratification.token_group = token_group;
  },
  setPsychosocialAccompanimentMutation: (state, psychosocial_accompaniment) => {
    state.psychosocial_accompaniment = psychosocial_accompaniment;
    if (psychosocial_accompaniment) {
      state.matrix_risk_stratification.psychosocial_accompaniment_id = psychosocial_accompaniment.id;
    }
  },
  setMatrixSituationMutation: (state, matrix_situation) => {
    state.matrix_situation = matrix_situation;
    if (matrix_situation) {
      state.matrix_risk_stratification.matrix_situation_id = matrix_situation.id;
    }
  },
  setResetMatrixSituationsArrayMutation: (state) => {
    state.matrix_situations_array = [];
    state.matrix_problems.forEach((item) => {
      item.matrix_situations.forEach((element) => {
        element["checked"] = false;
      });
    });
  },
  setMatrixSituationsArrayMutation: (state, payload) => {
    if (payload.requested) {
      state.matrix_to_delete = state.matrix_to_delete.filter((item) => item.data.id !== payload.data.id);
      state.matrix_situations_array.push(payload.data);
    } else if (payload.matrix_risk_stratifications) {
      payload.matrix_risk_stratifications.forEach((element) => {
        state.matrix_problems.forEach((item) => {
          if (element.matrix_problem_id === item.id) {
            item.matrix_situations.forEach((value) => {
              if (value.id === element.situation_id) {
                value.checked = true;
              }
            });
          }
        });
      });
    } else {
      state.matrix_to_delete = state.matrix_to_delete.map((item) => {
        return item.data.id !== payload.data.id ? item : payload;
      });
      if (!state.matrix_to_delete.some((item) => item.data.id === payload.data.id)) {
        state.matrix_to_delete.push(payload);
      }

      state.matrix_problems.forEach((item) => {
        item.matrix_situations.forEach((element) => {
          state.matrix_situations_array.forEach((value) => {
            if (element.id === value.id) {
              element.checked = false;
            }
          });
        });
      });
      state.matrix_situations_array = state.matrix_situations_array.filter(
        (item) => item.id != payload.data.id
      );
    }

    state.matrix_situations_array.forEach((item) => {
      item.checked = true;
    });
    state.matrix_problems.forEach((item) => {
      item.matrix_situations.forEach((element) => {
        state.matrix_situations_array.forEach((value) => {
          if (element.id === value.id) {
            element.checked = true;
          }
        });
      });
    });
  },

  // --> MUTATIONS - PAGINATION <--
  setMatrixRiskStratificationByInscriptionTotalPerPage: (
    state,
    matrixRiskStratificationByInscriptionTotalPerPage
  ) => {
    state.matrixRiskStratificationByInscriptionTotalPerPage =
      matrixRiskStratificationByInscriptionTotalPerPage;
  },
  setMatrixRiskStratificationByInscriptionOffset: (state, matrixRiskStratificationByInscriptionOffset) => {
    state.matrixRiskStratificationByInscriptionOffset = matrixRiskStratificationByInscriptionOffset;
  },
  setMatrixRiskStratificationByInscriptionsTotal: (state, matrixRiskStratificationByInscriptionTotal) => {
    state.matrixRiskStratificationByInscriptionTotal = matrixRiskStratificationByInscriptionTotal;
  },

  // --> MUTATIONS - FILTERS <--

  // --> MUTATIONS - TOGGLES FROM MODALS AND SCREENS <--
  toggleModalMatrixRiskStratifications: (state) => {
    state.modalToggleMatrixRiskStratifications = !state.modalToggleMatrixRiskStratifications;
  },
  toggleModalViewMatrixRiskStratifications: (state) => {
    state.modalToggleViewMatrixRiskStratifications = !state.modalToggleViewMatrixRiskStratifications;
  },
  toggleModalDeleteMatrixRiskStratification: (state) => {
    state.modalConfirmDeleteMatrixRiskStratification = !state.modalConfirmDeleteMatrixRiskStratification;
  },

  // --> MUTATIONS - DELETION <--
  matrixRiskStratificationForDeletion: (state, payload) => {
    if (payload && payload.matrix_risk_stratifications) {
      state.matrix_del = payload.matrix_risk_stratifications;
    }
  },

  // --> MUTATIONS - SPREADSHEET <--

  // --> MUTATIONS - CLEARS <--
  clearQuery: (state) => {
    state.matrix_risk_stratifications_by_inscription = [];
  },
  clearFields: (state) => {
    state.matrix_risk_stratification.id = null;
    state.matrix_risk_stratification.psychosocial_accompaniment_id = null;
    state.matrix_risk_stratification.matrix_situation_id = null;
    state.matrix_risk_stratification.inscription_id = null;
    state.matrix_situations_array = [];
    state.matrix_to_delete = [];
    state.matrix_risk_stratifications_by_inscription_update = null;
  },

  // --> MUTATIONS - OTHERS <--
  isUpdate: (state, payload) => {
    state.isUpdate = payload;
  },
};

const actions = {
  // --> ACTIONS & LOADS - DATA COMING FROM API <--
  loadMatrixRiskStratificationsByInscription: async ({ commit }) => {
    const params = {
      id: sessionStorage.inscription_id,
      perPage: state.matrixRiskStratificationByInscriptionTotalPerPage,
      offset: state.matrixRiskStratificationByInscriptionOffset,
    };
    await getMatrixRiskStratificationsByInscription(params)
      .then(async (response) => {
        let matrix = [];
        if (response.data.psychosocial_accompaniments.length > 0) {
          response.data.psychosocial_accompaniments.forEach((item) => {
            if (item.matrix_risk_stratifications.length > 0) {
              matrix.push(item);
            }
          });
        }
        await commit(
          "setMatrixRiskStratificationsByInscriptionMutation",
          response.data.psychosocial_accompaniments
        );
        await commit(
          "setMatrixRiskStratificationByInscriptionsTotal",
          response.data.psychosocial_accompaniments_count
        );
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadMatrixSitutations: ({ commit }) => {
    getMatrixSituationsForMenus()
      .then((response) => {
        commit("setMatrixSituationsMutation", response.data.matrix_situations);
      })
      .catch((e) => {
        console.error("error", e);
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadMatrixProblems: ({ commit }) => {
    getMatrixProblemsForMenus()
      .then((response) => {
        response.data.matrix_problems.forEach((item) => {
          item.matrix_situations.forEach((element) => {
            element["checked"] = false;
          });
        });
        commit("setMatrixProblemsMutation", response.data.matrix_problems);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },

  // --> ACTIONS - FILTERS <--

  // --> ACTIONS - CLEAR <--
  clearQuery: ({ commit }, payload) => {
    commit("clearQuery", payload);
  },

  // --> ACTIONS - TOGGLES FROM MODALS AND SCREENS <--
  toggleModalMatrixRiskStratifications: ({ commit }) => {
    commit("toggleModalMatrixRiskStratifications");
  },
  openEditModalMatrixRiskStratification: async ({ commit, dispatch }, payload) => {
    await dispatch("loadMatrixRiskStratificationsByInscription");
    if (payload) {
      await commit("setMatrixSituationsArrayMutation", payload);
      await commit("isUpdate", true);
      await commit("setMatrixRiskStratificationsByInscriptionUpdateMutation", payload);
    }
    commit("toggleModalMatrixRiskStratifications");
  },
  closeEditModalMatrixRiskStratification: ({ commit }) => {
    commit("toggleModalMatrixRiskStratifications");
  },
  descartEditModalMatrixRiskStratification: ({ commit }) => {
    commit("toggleModalMatrixRiskStratifications");
    commit("setResetMatrixSituationsArrayMutation");
  },
  openModalViewMatrixRiskStratification: async ({ commit }, payload) => {
    await commit("setMatrixRiskStratificationsByInscriptionFilteredMutation", payload);
    commit("toggleModalViewMatrixRiskStratifications");
  },
  closeModalViewMatrixRiskStratification: ({ commit }) => {
    commit("toggleModalViewMatrixRiskStratifications");
  },
  toggleModalDeleteMatrixRiskStratification: ({ commit }, payload) => {
    commit("toggleModalDeleteMatrixRiskStratification");
    if (state.modalConfirmDeleteMatrixRiskStratification && payload) {
      commit("matrixRiskStratificationForDeletion", payload);
    } else {
      commit("matrixRiskStratificationForDeletion", null);
    }
  },

  // --> ACTIONS - FIELDS TO THE TABLE <--
  setPsychosocialAccompaniment: ({ commit }, payload) => {
    commit("setPsychosocialAccompanimentMutation", payload);
  },
  setMatrixSituationsArray: ({ commit }, payload) => {
    commit("setMatrixSituationsArrayMutation", payload);
  },
  setResetMatrixSituationsArray: ({ commit }) => {
    commit("setResetMatrixSituationsArrayMutation");
  },

  // --> ACTIONS - PAGINATION <--
  setMatrixRiskStratificationByInscriptionOffset: ({ commit }, payload) => {
    commit("setMatrixRiskStratificationByInscriptionOffset", payload.offset);
  },
  setMatrixRiskStratificationByInscriptionTotalPerPage: ({ commit }, payload) => {
    commit("setMatrixRiskStratificationByInscriptionTotalPerPage", payload.totalPerPage);
  },

  // FUNCTION THAT GEN
  generateToken: () => {
    return new Promise((resolve) => {
      const array = new Uint8Array(16);
      crypto.getRandomValues(array);

      const token = Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join("");

      resolve(token);
    });
  },

  // --> ACTIONS - EXPORT SPREADSHEET <--

  // --> ACTIONS - CRUD <--
  save: async ({ commit, dispatch, rootState }) => {
    const token = await dispatch("generateToken");
    if (state.matrix_situations_array.length > 0) {
      state.matrix_situations_array.forEach((item) => {
        commit("setTokenGroupMutation", token);
        commit("setMatrixSituationMutation", item);
        commit("setInscriptionIdMutation", sessionStorage.inscription_id);
        createMatrixRiskStratification(state.matrix_risk_stratification)
          .then(() => {
            dispatch("loadMatrixRiskStratificationsByInscription");
          })
          .catch((e) => {
            Vue.$toast.error(
              "Não foi possivel cadastrar a situação de vulnerabilidade! " + e.response.data.error
            );
            console.log(e);
          });
      });
    }
    if (state.modalToggleMatrixRiskStratifications) {
      dispatch("descartEditModalMatrixRiskStratification");
    }
    if (
      !rootState.PsychosocialAccompaniments.modalToggleFirstPsychosocialAccompaniment &&
      !rootState.PsychosocialAccompaniments.modalTogglePsychosocialIndividualMonitoring &&
      !rootState.PsychosocialAccompaniments.modalTogglePsychosocialHomeVisit
    ) {
      Vue.$toast.success("Matriz cadastrada com sucesso!");
    }
    commit("clearFields");
  },
  update: async ({ state, commit, dispatch }) => {
    if (state.matrix_situations_array.length > 0) {
      let isDiferent = true;
      state.matrix_situations_array.forEach((item) => {
        state.matrix_risk_stratifications_by_inscription_update.matrix_risk_stratifications.forEach(
          (element) => {
            if (item.situation_id === element.id) {
              isDiferent = false;
            }
          }
        );
        if (isDiferent) {
          commit("setPsychosocialAccompanimentMutation", {
            id: state.matrix_risk_stratifications_by_inscription_update.psychosocial_accompaniment_id,
          });
          commit("setMatrixSituationMutation", item);
          commit("setInscriptionIdMutation", sessionStorage.inscription_id);
          commit(
            "setTokenGroupMutation",
            state.matrix_risk_stratifications_by_inscription_update.token_group
          );
          createMatrixRiskStratification(state.matrix_risk_stratification)
            .then(() => {
              dispatch("loadMatrixRiskStratificationsByInscription");
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    }
    if (state.matrix_to_delete.length > 0) {
      state.matrix_risk_stratifications_by_inscription_update.matrix_risk_stratifications.forEach(
        (element) => {
          state.matrix_to_delete.forEach((item) => {
            if (element.situation_id === item.data.id) {
              destroyMatrixRiskStratification(element.id)
                .then(() => {
                  dispatch("loadMatrixRiskStratificationsByInscription");
                })
                .catch((e) => {
                  Vue.$toast.error(e.response.data.error + "!");
                });
            }
          });
        }
      );
    }
    dispatch("closeEditModalMatrixRiskStratification");
    commit("clearFields");
    commit("clearQuery");
    commit("setResetMatrixSituationsArrayMutation");
    commit("isUpdate", false);
    Vue.$toast.success("Matriz atualizada com sucesso!");
  },
  delete: ({ dispatch, commit }) => {
    if (state.matrix_del.length > 0) {
      state.matrix_del.forEach((item) => {
        destroyMatrixRiskStratification(item.id)
          .then(() => {
            dispatch("loadMatrixRiskStratificationsByInscription");
          })
          .catch((e) => {
            Vue.$toast.error(e.response.data.error + "!");
          });
      });
    }
    dispatch("loadMatrixRiskStratificationsByInscription");
    commit("toggleModalDeleteMatrixRiskStratification");
    Vue.$toast.success("Matriz deletada com sucesso!");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
