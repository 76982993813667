<template>
  <div class="grid auto-grid-auto p-10" v-if="permitList">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-6 my-6">
      <div class="text-ashes-hover font-medium text-2xl col-span-2">
        <Label
          text="Equipamentos"
          :icon="null"
          :iconType="null"
          type="text-3xl"
          weight="semibold"
          normal
          mode="normal-case"
          :class="title"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          text="Adicionar equipamento"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="Equipment/openModalEquipment"
          v-if="permit"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-6 mb-2">
      <FilterComponent>
        <div class="col-span-4">
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="nameFiltered"
            label-text="Pesquisar por Nome do Equipamento"
            input-placeholder="Pesquisar por Nome do Equipamento"
            dispatch="EquipmentAll/filterName"
          />
        </div>
        <div class="col-span-4">
          <Multiselect
            id="filterArea"
            selectPlaceholder="Selecionar Área"
            label-text="Selecionar Área"
            class="mr-5"
            closeOnSelect
            show-label
            :multipleOption="false"
            :options="areas"
            :value="filteredArea"
            :dispatch="setAreas"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setCity"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            id="filterState"
            selectPlaceholder="Selecionar Bairro"
            :label-text="getLabelNeighborhoodFilter()"
            class="mr-5"
            closeOnSelect
            show-label
            :multipleOption="false"
            :options="neighborhoods_for_menus"
            :value="filteredNeighborhood"
            :dispatch="setNeighborhoods"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-6 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permitListAll"
          id="btn-consult-equipments"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="EquipmentAll/loadEquipment"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="equipments.length > 0">
        <a href="javascript:void(0)" @click="clearFilters()">
          <AppButtonColorful
            id="btn-clear-equipments"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-areas">
      <div class="mx-6"><EquipmentCards :data="equipments" /></div>
      <div id="areas-pagination" class="mx-12 my-6 pb-3" v-if="equipments.length > 0">
        <BasePagination
          :total="totalEquipments"
          :total-per-page="totalPerPage"
          dispatch="EquipmentAll/setEquipmentsOffset"
        />
      </div>
      <ModalEquipment :open="openModalEquipment" />
      <DeleteEquipmentConfirmation :open="openModalDelete" />
    </div>
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import EquipmentCards from "./list/EquipmentCards.vue";
import ModalEquipment from "./ModalEquipment.vue";
import DeleteEquipmentConfirmation from "./alerts/DeleteEquipmentConfirmation.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import ProhibitedAccessPage from "../components/ProhibitedAccessPage.vue";
import { mapState, mapActions } from "vuex";
import BaseInput from "@/components/forms/BaseInput.vue";
import BasePagination from "@/components/BasePagination.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import Label from "@/components/Label.vue";

export default {
  components: {
    BasePagination,
    FilterComponent,
    BaseInput,
    Label,
    Multiselect,
    AppButtonColorful,
    EquipmentCards,
    ModalEquipment,
    DeleteEquipmentConfirmation,
    ProhibitedAccessPage,
  },

  data() {
    return {
      permitList: false,
      permit: false,
    };
  },

  created() {
    this.$store.dispatch("UtilFunctions/loadAreasForMenus");
    this.$store.dispatch("Neighborhoods/loadNeighborhoods");
    this.$store.dispatch("EquipmentAll/loadEquipment");
    this.$store.dispatch("Equipment/clearEquipment");
    this.$store.dispatch("UtilFunctions/loadCities");
    this.permitChangeEquip();
    this.permitListAll();
  },

  computed: {
    ...mapState({
      areas: (state) => state.UtilFunctions.areas_for_menus,
      neighborhoods_for_menus: (state) => state.UtilFunctions.neighborhoods_for_menus,
      equipments: (state) => state.EquipmentAll.equipmentAll,
      filteredArea: (state) => state.EquipmentAll.filters.area,
      filteredNeighborhood: (state) => state.EquipmentAll.filters.neighborhood,
      openModalDelete: (state) => state.Equipment.modalDeleteEquipmentConfirmationOpen,
      openModalEquipment: (state) => state.Equipment.modalEquipmentOpen,
      nameFiltered: (state) => state.EquipmentAll.filters.name,
      city: (state) => state.EquipmentAll.filters.city,
      state: (state) => state.EquipmentAll.filters.state,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
      offset: (state) => state.EquipmentAll.equipmentsOffset,
      totalPerPage: (state) => state.EquipmentAll.equipmentsTotalPerPage,
      totalEquipments: (state) => state.EquipmentAll.equipmentsTotal,
    }),
    title() {
      return {
        "text-ashes-hover": true,
      };
    },
  },
  methods: {
    ...mapActions({
      setNeighborhoods: "EquipmentAll/filterNeighborhood",
      setAreas: "EquipmentAll/filterArea",
      setCity: "EquipmentAll/filterCity",
      setState: "EquipmentAll/filterState",
      canAcces: "AccessControl/canAcces",
    }),
    permitListAll() {
      this.canAcces("equipment_index").then((resp) => {
        this.permitList = resp;
      });
    },
    permitChangeEquip() {
      this.canAcces("equipment_create").then((resp) => {
        this.permit = resp;
      });
    },
    clearFilters() {
      this.$store.dispatch("EquipmentAll/clearFilters");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
    getLabelNeighborhoodFilter() {
      return this.city ? "Filtrar por Bairro" : "Selecione o município para listar os bairros";
    },
  },
  watch: {
    offset: function () {
      this.$store.dispatch("EquipmentAll/loadEquipment");
      this.backToTop();
    },
    city: function () {
      if (this.city != null) {
        this.$store.dispatch("UtilFunctions/loadNeighborhoodsForMenus", this.city);
      }
    },
  },
};
</script>
