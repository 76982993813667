import { getAxios, baseURL } from "./Api";

export async function getPsychosocialAccompaniments() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial`);
}

export async function getPsychosocialAccompanimentsTypes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial/tipos_acompanhamentos`);
}

export async function getPsychosocialAccompanimentsStatus() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial/status_acompanhamentos`);
}

export async function getPsychosocialHousingBuildingSituations() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial/situacoes_imoveis`);
}

export async function getPsychosocialHousingBuildingMaterials() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial/estruturas_imoveis`);
}

export async function getPsychosocialHousingElectricities() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial/eletricidade_imoveis`);
}

export async function getPsychosocialHousingWaterSupplies() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial/agua_imoveis`);
}

export async function getPsychosocialHousingGasSupplies() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial/gas_imoveis`);
}

export async function getPsychosocialHousingBathroomDrainTypes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial/tipos_escoamento_imoveis`);
}

export async function getPsychosocialMeetingNotHappenedReasons() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial/motivos_nao_realizacao_encontro`);
}

export async function getPsychosocialSocioEducationalMeasures() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial/medidas_socioeducativas`);
}

export async function getPsychosocialGenders() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial/generos`);
}

export async function getIdentityGendersOptions() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial/identificacao_generos`);
}

export async function getPsychosocialAccompanimentById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`acompanhamentos_psicossocial/${_id}`);
}

export async function getPsychosocialAccompanimentByInscription(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `acompanhamentos_psicossocial/filtro_por_inscricao`;
  return api.get(url, { params: filters });
}

export async function getPsychosocialAccompanimentByInscriptionSpreadsheet(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `acompanhamentos_psicossocial/exportar_planilha_acompanhamentos_por_inscricao`;
  return api.get(url, { params: filters });
}

export async function getLastService(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `acompanhamentos_psicossocial/filtrar_ultimo_atendimento`;
  return api.get(url, { params: filters });
}

export async function createPsychosocialAccompaniment(psychosocial_accompaniment) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/acompanhamentos_psicossocial", psychosocial_accompaniment);
}

export async function updatePsychosocialAccompaniment(id, psychosocial_accompaniment) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`acompanhamentos_psicossocial/${id}`, psychosocial_accompaniment);
}

export async function destroyPsychosocialAccompaniment(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`acompanhamentos_psicossocial/${id}`);
}
