<template>
  <div>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4 ml-5">
        <BaseText
          text="Descrição"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li :key="matrix_problem.id" :data-index="index" v-for="(matrix_problem, index) in data">
        <div>
          <AppCardMatrixProblem :matrix_problem="matrix_problem" />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardMatrixProblem from "../../components/AppCardMatrixProblem.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";

export default {
  components: {
    AppCardMatrixProblem,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 30% 17% 16%;
}
</style>
