import Vue from "vue";
import JsonExcel from "vue-json-excel";
import { getCitiesForMenus } from "@/service/CityApi";
import { getStates } from "@/service/StateApi";
import { getAreasForMenus } from "@/service/AreaApi";
import { getAbilityProfilesForMenus } from "@/service/AbilityProfileApi";
import { getTeachersForMenus } from "@/service/TeacherApi";
import { getNeighborhoodsForMenus } from "@/service/NeighborhoodsApi";
import { getScholarshipMatricesForMenus } from "@/service/ScholarshipMatricesApi";
import {
  getPaymentSheetInstallments,
  getPaymentSheetRemittances,
  getPaymentSheetStatus,
  getPaymentSheetTypes,
} from "@/service/PaymentSheetsApi";
import { getTeamsForMenus } from "@/service/TeamApi";
Vue.component("downloadExcel", JsonExcel);

const state = {
  cities: [],
  cities_all: [],
  states: [],
  areas_for_menus: [],
  neighborhoods_for_menus: [],
  ability_profiles_for_menus: [],
  teachers_for_menus: [],
  user_areas: [],
  user_equipments: [],
  teams_for_menus: [],
  scholarship_matrices_for_menus: [],
  payment_sheet_installment_options: [],
  payment_sheet_remittance_options: [],
  payment_sheet_statuses_options: [],
  payment_sheet_types_options: [],
  finished_after_login: false,
  positive_negative_options: [
    {
      id: 1,
      name: "Bolsa",
    },
    {
      id: 2,
      name: "Não",
    },
  ],
  beneficiaries_status_options: [
    {
      id: 0,
      name: "Aguardando",
    },
    {
      id: 1,
      name: "Pago",
    },
    {
      id: 2,
      name: "Erro",
    },
  ],
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadCities: (state, cities) => {
    state.cities_all = cities;
  },
  loadStates: (state, states) => {
    state.states = states;
  },
  loadAreasForMenus: (state, areas_for_menus) => {
    state.areas_for_menus = areas_for_menus;
  },
  loadNeighborhoodsForMenus: (state, neighborhoods_for_menus) => {
    state.neighborhoods_for_menus = neighborhoods_for_menus;
  },
  loadAbilityProfilesForMenus: (state, ability_profiles_for_menus) => {
    state.ability_profiles_for_menus = ability_profiles_for_menus;
  },
  loadTeamsForMenus: (state, teams_for_menus) => {
    state.teams_for_menus = teams_for_menus;
  },
  loadTeachersForMenus: (state, teachers_for_menus) => {
    state.teachers_for_menus = teachers_for_menus;
  },
  loadScholarshipMatricesForMenus: (state, scholarship_matrices_for_menus) => {
    state.scholarship_matrices_for_menus = scholarship_matrices_for_menus;
  },
  loadPaymentSheetInstallmentOptions: (state, payment_sheet_installment_options) => {
    state.payment_sheet_installment_options = payment_sheet_installment_options;
  },
  loadPaymentSheetRemittanceOptions: (state, payment_sheet_remittance_options) => {
    state.payment_sheet_remittance_options = payment_sheet_remittance_options;
  },
  loadPaymentSheetStatusesOptions: (state, payment_sheet_statuses_options) => {
    state.payment_sheet_statuses_options = payment_sheet_statuses_options;
  },
  loadPaymentSheetTypesOptions: (state, payment_sheet_types_options) => {
    state.payment_sheet_types_options = payment_sheet_types_options;
  },
  setUserAreas: (state, user_areas) => {
    state.user_areas = user_areas;
  },
  setUserCities: (state, cities) => {
    state.cities = cities;
  },
  setUserEquipments: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },
  setFinishedAfterLogin: (state, finished_after_login) => {
    state.finished_after_login = finished_after_login;
  },
  clearFields: (state) => {
    state.cities = [];
    state.states = [];
    state.areas_for_menus = [];
    state.ability_profiles_for_menus = [];
    state.teachers_for_menus = [];
  },
};
const actions = {
  clearFields: ({ commit }) => {
    commit("clearFields");
  },
  // ACTIONS - LOADS DATA FROM API
  loadAbilityProfilesForMenus: ({ commit }) => {
    getAbilityProfilesForMenus()
      .then((response) => {
        commit("loadAbilityProfilesForMenus", response.data.ability_profile);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadAreasForMenus: ({ commit }, payload) => {
    let city_ids;
    if (payload) {
      city_ids = Array.isArray(payload) ? payload.map((city) => city.id) : [payload.id];
    }
    const params = {
      city_id: city_ids ? city_ids : null,
    };
    getAreasForMenus(params)
      .then((response) => {
        commit("loadAreasForMenus", response.data.areas);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadTeachersForMenus: ({ commit }) => {
    getTeachersForMenus()
      .then((response) => {
        commit("loadTeachersForMenus", response.data.teams);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadTeamsForMenus: ({ commit }) => {
    const season = JSON.parse(localStorage.getItem("season"));
    const params = {
      season_id: season?.id,
      in_progress: true,
    };
    getTeamsForMenus(params)
      .then((response) => {
        commit("loadTeamsForMenus", response.data.teams);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadNeighborhoodsForMenus: ({ commit }, payload) => {
    const params = {
      city_id: payload ? payload.id : null,
    };
    getNeighborhoodsForMenus(params)
      .then((response) => {
        commit("loadNeighborhoodsForMenus", response.data.neighborhoods);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadStates: ({ commit }) => {
    getStates()
      .then((response) => {
        commit("loadStates", response.data.states);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadCities: ({ commit }) => {
    const params = {
      state_id: 1,
    };
    getCitiesForMenus(params)
      .then((response) => {
        commit("loadCities", response.data.cities);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadScholarshipMatricesForMenus: ({ commit }) => {
    getScholarshipMatricesForMenus()
      .then((response) => {
        commit("loadScholarshipMatricesForMenus", response.data.scholarship_matrices);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadPaymentSheetInstallmentOptions: ({ commit }) => {
    getPaymentSheetInstallments()
      .then((response) => {
        commit("loadPaymentSheetInstallmentOptions", response.data.installment);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadPaymentSheetRemittanceOptions: ({ commit }) => {
    getPaymentSheetRemittances()
      .then((response) => {
        commit("loadPaymentSheetRemittanceOptions", response.data.remittance);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadPaymentSheetStatusesOptions: ({ commit }) => {
    getPaymentSheetStatus()
      .then((response) => {
        commit("loadPaymentSheetStatusesOptions", response.data.status);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadPaymentSheetTypesOptions: ({ commit }) => {
    return getPaymentSheetTypes()
      .then((response) => {
        console.log("response", response);
        commit("loadPaymentSheetTypesOptions", response.data.payment_sheet_type);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  setSelectFieldsToExportFields: async ({ dispatch }, payload) => {
    if (payload) {
      await dispatch("SelectFieldsToExport/setTitleModal", payload.title, { root: true });
      await dispatch("SelectFieldsToExport/setDispatchToCloseModal", payload.closeModalDispatch, {
        root: true,
      });
      await dispatch("SelectFieldsToExport/setSheetTabName", payload.sheetTabName, { root: true });
      await dispatch("SelectFieldsToExport/setFileName", payload.fileName, { root: true });
      await dispatch("SelectFieldsToExport/setJsonFields", payload.jsonFields, { root: true });
      await dispatch("SelectFieldsToExport/setDispatchOpenModal", payload.openModalDispatch, { root: true });
      await dispatch("SelectFieldsToExport/openModalSelectFieldsToExport", null, { root: true });
    }
  },
  setUserAreas: ({ commit }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    commit("setUserAreas", user.areas ? user.areas : []);
  },
  setUserEquipments: ({ commit }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    commit("setUserEquipments", user.equipments ? user.equipments : []);
  },
  setUserCities: ({ commit }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    commit("setUserCities", user.cities ? user.cities : []);
  },
  setFinishedAfterLogin: ({ commit }, payload) => {
    commit("setFinishedAfterLogin", payload);
  },
};
const getters = {
  cityIds: (state, getters, rootState) => {
    const cities = rootState.UtilFunctions.cities;
    return cities.length > 0 ? cities.map((city) => city.id) : null;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
