import { getAxios, baseURL } from "./Api";

export async function getVacancyRequests(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `solicitacoes_vagas`;
  return api.get(url, { params: filters });
}

export async function getVacancyRequestById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`solicitacoes_vagas/${_id}`);
}

export async function getVacancyRequestByInscription(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `solicitacoes_vagas/filtro_por_inscricao`;
  return api.get(url, { params: filters });
}

export async function getVacancyRequestsTypes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`solicitacoes_vagas/tipos_vagas`);
}

export async function getVacancyRequestsInterestShifts() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`solicitacoes_vagas/turnos_interesse`);
}

export async function getVacancyRequestsInterestSeries() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`solicitacoes_vagas/series_interesse`);
}

export async function getVacancyRequestsSchoolsNetwork() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`solicitacoes_vagas/redes_escolares`);
}

export async function createVacancyRequest(vacancy_request) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/solicitacoes_vagas", vacancy_request);
}

export async function updateVacancyRequest(id, vacancy_request) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`solicitacoes_vagas/${id}`, vacancy_request);
}

export async function destroyVacancyRequest(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`solicitacoes_vagas/${id}`);
}
