import { getStudentsPhaseOne, getStudentsPhaseTwo, getStudentsPhaseThree } from "../../service/AllocationApi";
import { updateTeam, removeTeamStudent, transferTeamStudent } from "../../service/TeamApi";
import { getMeetingByTeam } from "../../service/MeetingApi";
import Vue from "vue";

const state = {
  toggleModal: false,
  allTeams: [],
  meetings: [],
  teams: {
    students_attributes: [],
  },
  students: [],
  phaseOneStudents: [],
  filteredphaseOneStudents: [],
  filteredphaseOneStudentsCopy: [],
  phaseTwoStudents: [],
  phaseThreeStudents: [],
  filter: {
    area: {},
    shift: {},
    equipment: {},
  },
};

const mutations = {
  toggleModalMutation: (state) => {
    state.toggleModal = !state.toggleModal;
  },
  setStudentsMutation: (state, student) => {
    state.students = student;
  },
  setEquipmentMutation: (state, equipment) => {
    state.filter.equipment = equipment;
  },
  cleanStudentsAttributesMutation: (state, student) => {
    state.teams.students_attributes = student;
  },
  addStudentMutation: (state, student) => {
    if (typeof state.teams.students_attributes == "undefined") {
      state.teams.students_attributes = [];
    }
    state.teams.students_attributes.push(student);
  },
  removeStudentMutation: (state, student) => {
    state.teams.students_attributes = state.teams.students_attributes.filter((obj) => {
      return obj.id !== student.id;
    });
  },
  filterPhaseOneStudentsByAreaMutation: (state, area) => {
    let students = state.phaseOneStudents;
    state.filteredphaseOneStudents = students.filter((obj) => {
      return obj.neighborhood.area.id === area.id;
    });
    state.filteredphaseOneStudentsCopy = students.filter((obj) => {
      return obj.neighborhood.area.id === area.id;
    });
  },
  filterPhaseOneStudentsByShiftMutation: (state, shift) => {
    let students = state.phaseOneStudents;
    switch (shift.id) {
      case 0: {
        state.filteredphaseOneStudents = students.filter((obj) => {
          return obj.morning_shift === true;
        });
        state.filteredphaseOneStudentsCopy = students.filter((obj) => {
          return obj.morning_shift === true;
        });
        break;
      }
      case 1: {
        state.filteredphaseOneStudents = students.filter((obj) => {
          return obj.afternoon_shift === true;
        });
        state.filteredphaseOneStudentsCopy = students.filter((obj) => {
          return obj.afternoon_shift === true;
        });
        break;
      }
      case 2: {
        state.filteredphaseOneStudents = students.filter((obj) => {
          return obj.night_shift === true;
        });
        state.filteredphaseOneStudentsCopy = students.filter((obj) => {
          return obj.night_shift === true;
        });
        break;
      }
      default: {
        state.filteredphaseOneStudents = [];
        state.filteredphaseOneStudentsCopy = [];
      }
    }
  },
  setFilterAreaMutation: (state, area) => {
    state.filter.area = area;
  },
  setFilterShiftMutation: (state, shift) => {
    state.filter.shift = shift;
  },
  setPhaseOneStudentsMutation: (state, students) => {
    state.phaseOneStudents = students;
    state.filteredphaseOneStudents = students;
    state.filteredphaseOneStudentsCopy = students;
  },
  setPhaseOneFilteredStudentsMutation: (state, students) => {
    state.filteredphaseOneStudents = students;
    state.filteredphaseOneStudentsCopy = students;
  },
  setAllTeamsMutation: (state, payload) => {
    state.allTeams = payload;
  },
  setMeetings: (state, meetings) => {
    state.meetings = meetings;
  },
  filterList: (state, input) => {
    if (input.value != "") {
      state.filteredphaseOneStudents = state.filteredphaseOneStudents.filter((obj) => {
        let name = obj.name.toLowerCase().indexOf(input.value.toLowerCase());
        return name > -1;
      });
    } else {
      state.filteredphaseOneStudents = state.filteredphaseOneStudentsCopy;
    }
  },
  transferStudentToTeamMutation: (state, payload) => {
    const idSource = payload.source_team.id;
    const idTarget = payload.target_team.id;
    const studentId = payload.inscription.student.id;

    transferTeamStudent(studentId, idSource, idTarget)
      .then((response) => {
        Vue.$toast.success(response.data.status);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  removeStudentFromTeamMutation: (state, payload) => {
    const idSource = payload.id;
    removeTeamStudent(payload.inscription.student.id, idSource)
      .then((response) => {
        Vue.$toast.success(response.data.status);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
};
const actions = {
  filterList: ({ commit }, payload) => {
    commit("filterList", payload);
  },
  cleanStudentsAttributesMutation: ({ commit }) => {
    commit("cleanStudentsAttributesMutation");
  },
  openModal: ({ commit }) => {
    commit("toggleModalMutation");
  },
  closeModal: ({ commit }) => {
    commit("toggleModalMutation");
  },
  filterPhaseOneStudentsByArea: ({ commit }, payload) => {
    commit("setFilterAreaMutation", payload);
    commit("filterPhaseOneStudentsByAreaMutation", payload);
  },
  filterPhaseOneStudentsByShift: ({ commit }, payload) => {
    commit("setFilterShiftMutation", payload);
    commit("filterPhaseOneStudentsByShiftMutation", payload);
  },
  setAllTeams: ({ commit }, payload) => {
    commit("setAllTeamsMutation", payload);
  },
  loadMeetings: ({ commit }, id) => {
    getMeetingByTeam(id)
      .then((response) => {
        commit("setMeetings", response.data.meetings);
      })
      .catch((e) => {
        console.log("error", e);
      });
  },
  setEquipment: ({ commit }, payload) => {
    let students = state.phaseOneStudents;
    if (payload != null) {
      let equipment_id = payload.id;
      let students_filtered = students.filter((obj) => {
        return obj.equipment.id === equipment_id;
      });
      students = students_filtered;
      commit("setEquipmentMutation", payload);
      commit("setPhaseOneFilteredStudentsMutation", students);
    } else {
      commit("setEquipmentMutation", {});
      commit("setPhaseOneFilteredStudentsMutation", students);
    }
  },
  setArea: ({ commit }, payload) => {
    let students = state.phaseOneStudents;
    if (payload != null) {
      let area_id = payload.id;
      let students_filtered = students.filter((obj) => {
        return obj.neighborhood.area.id === area_id;
      });
      students = students_filtered;
      commit("setFilterAreaMutation", payload);
      commit("setPhaseOneFilteredStudentsMutation", students);
    } else {
      commit("setFilterAreaMutation", {});
      commit("setPhaseOneFilteredStudentsMutation", students);
    }
  },
  addStudent: ({ commit }, payload) => {
    commit("addStudentMutation", payload);
  },
  setStudents: ({ commit }, payload) => {
    commit("setStudentsMutation", payload);
  },
  removeStudent: ({ commit }, payload) => {
    commit("removeStudentMutation", payload);
  },
  loadStudentsForAllocation: ({ dispatch, rootState }) => {
    const seasonId = rootState.Team.season.id;
    const phaseId = rootState.Team.discipline.phase.id;

    switch (phaseId) {
      case 1:
        dispatch("loadPhaseOneStudents", { season_id: seasonId, phase_id: phaseId });
        break;
      case 2:
        dispatch("loadPhaseTwoStudents", { season_id: seasonId });
        break;
      case 3:
        dispatch("loadPhaseThreeStudents", { season_id: seasonId });
        break;
    }
  },
  loadPhaseOneStudents: ({ commit }, payload) => {
    getStudentsPhaseOne(payload.season_id, payload.phase_id)
      .then((response) => {
        commit("setPhaseOneStudentsMutation", response.data.students);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadPhaseTwoStudents: ({ commit, rootState }, payload) => {
    const disciplineId = rootState.Team.discipline.id;

    getStudentsPhaseTwo(payload.season_id, disciplineId)
      .then((response) => {
        commit("setPhaseOneStudentsMutation", response.data.students);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },

  loadPhaseThreeStudents: ({ commit, rootState }, payload) => {
    const disciplineId = rootState.Team.discipline.id;
    getStudentsPhaseThree(payload.season_id, disciplineId)
      .then((response) => {
        commit("setPhaseOneStudentsMutation", response.data.students);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  allocatePhaseOneStudents: ({ commit, rootState, dispatch, state }) => {
    const id = rootState.Team.id;
    if (
      typeof state.teams.students_attributes !== "undefined" &&
      state.teams.students_attributes.length > 0
    ) {
      const anterior = state.students;
      anterior.forEach((element) => {
        commit("addStudentMutation", element);
      });
      updateTeam(id, state.teams)
        .then((response) => {
          Vue.$toast.success(response.data.message);
          commit("toggleModalMutation");
          commit("setStudentsMutation", state.teams.students_attributes);
          commit("cleanStudentsAttributesMutation");
          dispatch("loadStudentsForAllocation");
        })
        .catch((e) => {
          let mess_error = "Não foi possivel alocar o aluno na turma!";
          if (e.response.data.exception.indexOf("ForeignKeyViolation")) {
            mess_error += "\nJá foram gerados benefícios para esta turma...";
          }
          Vue.$toast.error(mess_error);
        });
    } else {
      commit("cleanStudentsAttributesMutation");
      let mess_error = "Nenhum estudante selecionado...";
      Vue.$toast.error(mess_error);
    }
  },
  removeStudentFromTeam: ({ dispatch }, payload) => {
    const teamIdToRemove = payload.team.id;
    const studentId = payload.inscription.student.id;
    removeTeamStudent(studentId, teamIdToRemove)
      .then((response) => {
        Vue.$toast.success(response.data.status);
        dispatch("Inscription/load", payload.inscription, { root: true });
        dispatch("ModalInscription/updateInscription", {}, { root: true });
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  transferStudentToTeam: ({ dispatch }, payload) => {
    const idSource = payload.source_team.id;
    const idTarget = payload.target_team.id;
    const studentId = payload.inscription.student.id;

    transferTeamStudent(studentId, idSource, idTarget)
      .then((response) => {
        Vue.$toast.success(response.data.status);
        dispatch("Inscription/load", payload.inscription, { root: true });
        dispatch("ModalInscription/updateInscription", {}, { root: true });
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
