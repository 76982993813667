<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="teacher.id"
            text="Editar Instrutor(a)"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar Instrutor(a)"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Teachers/closeModalTeacher" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2">
          <BaseInput
            id="name"
            input-name="name"
            input-placeholder="nome"
            input-type="text"
            show-label
            label-text="nome"
            required
            :value="teacher.name"
            :error-message="getErrorMessage('name', teacher.name)"
            dispatch="Teacher/setName"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Município"
            :dispatch="setCity"
            required
          />
        </div>
        <div>
          <BaseInput
            id="cpf"
            input-name="cpf"
            input-placeholder="000.000.000-00"
            input-type="text"
            show-label
            label-text="cpf"
            required
            mask="###.###.###-##"
            :value="teacher.cpf"
            :error-message="getErrorMessage('cpf', teacher.cpf)"
            dispatch="Teacher/setCpf"
          />
        </div>
        <div>
          <BaseInput
            id="cellphone_number"
            input-name="cellphone_number"
            input-placeholder="(00) 00000-0000"
            input-type="text"
            show-label
            label-text="telefone"
            required
            mask="(##) #####.####"
            :value="teacher.cellphone_number"
            :error-message="getErrorMessage('phone_number', teacher.cellphone_number)"
            dispatch="Teacher/setCellphoneNumber"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            id="email"
            input-name="email"
            input-placeholder="exemplo@email.com"
            input-type="text"
            show-label
            label-text="E-mail"
            required
            :value="teacher.email"
            :error-message="getErrorMessage('email', teacher.email)"
            dispatch="Teacher/setEmail"
          />
        </div>
        <div v-if="!teacher.id">
          <BaseInput
            id="password"
            input-name="password"
            input-type="password"
            show-label
            label-text="Senha"
            required
            :value="teacher.password"
            :error-message="getErrorMessage('password', teacher.password)"
            dispatch="Teacher/setPassword"
          />
        </div>
        <div v-if="!teacher.id">
          <BaseInput
            id="password_confirmation"
            input-name="password"
            input-type="password"
            show-label
            label-text="Confirmação de Senha"
            required
            :value="teacher.password"
            :error-message="getErrorMessage('password', teacher.password_confirmation)"
            dispatch="Teacher/setPasswordConfirmation"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Teachers/closeModalTeacher" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="teacher.id"
            text="Salvar"
            typeButton="success"
            dispatch="Teacher/updateTeacher"
          />
          <AppButtonEmpty v-else text="criar" typeButton="success" dispatch="Teacher/saveTeacher" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import Multiselect from "@/components/forms/Multiselect.vue";

export default {
  components: {
    Multiselect,
    BaseModal,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("UtilFunctions/loadStates");
  },
  computed: {
    ...mapGetters({
      getErrorMessage: "Teacher/getErrorMessage",
    }),

    ...mapState({
      teacher: (state) => state.Teacher,
      name: (state) => state.Teacher.name,
      state: (state) => state.Teacher.state,
      city: (state) => state.Teacher.city,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
    }),
  },
  methods: {
    ...mapActions({
      setCity: "Teacher/setCity",
      setState: "Teacher/setState",
    }),
    getLabelCity() {
      return this.state ? "Município" : "Selecione o estado para listar os municípios";
    },
  },
};
</script>
