import {
  createMeeting,
  updateMeeting,
  getMeeting,
  suspend,
  suspendMeetingsByTeamAndDate,
  disableSuspension,
} from "../../service/MeetingApi";
import Vue from "vue";

const state = {
  presences: null,

  toggleModal: false,
  modalSuspendMeetingConfirmationOpen: false,
  modalDisableSuspensionMeetingConfirmationOpen: false,
  modalSuspendMeetingsByTeamAndDateConfirmationOpen: false,
  modalDisableSuspensionMeetingsByTeamAndDateConfirmationOpen: false,
  meetingId: null,
  teacher: null,
  equipment: null,
  shift: null,
  meeting: {
    id: null,
    name: null,
    description: null,
    date: null,
    shift: null,
    equipment_id: null,
    teacher_id: null,
    team_id: null,
    shifts: {
      name: "",
    },
    teacher: {
      id: null,
      name: "",
    },
    equipment: {
      id: null,
      name: "",
    },
    team: {
      id: null,
      code: "",

      discipline: {
        id: null,
        name: "",
      },
    },
  },
  emptyMeeting: {
    id: null,
    name: null,
    description: null,
    date: null,
    shift: null,
    equipment_id: null,
    teachers_id: null,
    team_id: null,
    shifts: {
      name: "",
    },
    teacher: {
      id: null,
      name: "",
    },
    equipment: {
      id: null,
      name: "",
    },
    team: {
      id: null,
      code: "",

      discipline: {
        id: null,
        name: "",
      },
    },
  },
  errorMessages: [],
};

const mutations = {
  setName: (state, name) => {
    state.meeting.name = name.value;
  },
  setDescription: (state, description) => {
    state.meeting.description = description.value;
  },
  setDate: (state, date) => {
    state.meeting.date = date.value;
  },
  toggleModal: (state) => {
    state.toggleModal = !state.toggleModal;
  },
  toggleModalSuspendMeeting: (state) => {
    state.modalSuspendMeetingConfirmationOpen = !state.modalSuspendMeetingConfirmationOpen;
  },
  toggleModalDisableSuspensionMeeting: (state) => {
    state.modalDisableSuspensionMeetingConfirmationOpen =
      !state.modalDisableSuspensionMeetingConfirmationOpen;
  },
  toggleModalSuspendMeetingsByTeamAndDate: (state) => {
    state.modalSuspendMeetingsByTeamAndDateConfirmationOpen =
      !state.modalSuspendMeetingsByTeamAndDateConfirmationOpen;
  },
  toggleModalDisableSuspensionMeetingsByTeamAndDate: (state) => {
    state.modalDisableSuspensionMeetingsByTeamAndDateConfirmationOpen =
      !state.modalDisableSuspensionMeetingsByTeamAndDateConfirmationOpen;
  },
  setMeeting: (state, meeting) => {
    state.meeting = Object.assign(state.meeting, meeting);
  },
  clearMeetingMutation: (state) => {
    state.meeting = Object.assign(state.meeting, state.emptyMeeting);
  },
  setTeam: (state, team) => {
    state.meeting.team_id = team;
  },
  setTeacher: (state, teacher) => {
    state.meeting.teacher_id = teacher;
  },
  setShift: (state, shift) => {
    state.meeting.shift = shift;
  },
  setEquipment: (state, equipment) => {
    state.meeting.equipment_id = equipment;
  },
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
  setPresencesMutation: (state, presences) => {
    state.presences = presences;
  },

  // --> MUTATIONS - DELETION <--
  meetingForSuspension: (state, payload) => {
    if (payload) {
      state.meetingId = payload.id;
    }
  },
};

const actions = {
  setName: ({ commit }, payload) => {
    commit("setName", payload);
  },
  setDescription: ({ commit }, payload) => {
    commit("setDescription", payload);
  },
  setDate: ({ commit }, payload) => {
    commit("setDate", payload);
  },
  setShift: ({ commit }, payload) => {
    commit("setShift", payload);
  },
  setEquipment: ({ commit }, payload) => {
    commit("setEquipment", payload);
  },
  setTeacher: ({ commit }, payload) => {
    commit("setTeacher", payload);
  },
  toggleModal: ({ commit }) => {
    commit("toggleModal");
  },
  toggleModalSuspendMeeting: ({ commit }, payload) => {
    commit("toggleModalSuspendMeeting");
    if (state.modalSuspendMeetingConfirmationOpen && payload) {
      commit("meetingForSuspension", payload);
    } else {
      commit("meetingForSuspension", null);
    }
  },
  toggleModalDisableSuspensionMeeting: ({ commit }, payload) => {
    commit("toggleModalDisableSuspensionMeeting");
    if (state.modalDisableSuspensionMeetingConfirmationOpen && payload) {
      commit("meetingForSuspension", payload);
    } else {
      commit("meetingForSuspension", null);
    }
  },
  closeModal: ({ commit }) => {
    commit("clearMeetingMutation");
    commit("setErrorMessagesMutation", []);
    commit("toggleModal");
  },
  goToTeam: ({ commit }) => {
    commit("clearMeetingMutation");
    commit("setErrorMessagesMutation", []);
  },
  openModal: ({ commit }) => {
    commit("setErrorMessagesMutation", []);
    commit("toggleModal");
  },
  editModal: ({ commit }, payload) => {
    commit("setMeeting", payload);
    commit("toggleModal");
  },
  openModalSuspendMeetingsByTeamAndDate: ({ commit }) => {
    commit("setErrorMessagesMutation", []);
    commit("toggleModalSuspendMeetingsByTeamAndDate");
  },
  closeModalSuspendMeetingsByTeamAndDate: ({ commit, dispatch }) => {
    commit("setErrorMessagesMutation", []);
    commit("toggleModalSuspendMeetingsByTeamAndDate");
    dispatch("ModalExportTeamPresence/setTeamsExport", [], { root: true });
  },
  setMeeting: ({ commit }, payload) => {
    commit("setMeeting", payload);
  },
  saveMeeting: ({ commit, state, dispatch, rootState }) => {
    commit("setTeam", rootState.Team.id);
    commit("setShift", rootState.Team.shift.id);
    commit("setTeacher", rootState.Team.teacher.id);
    commit("setEquipment", rootState.Team.equipment.id);
    createMeeting(state.meeting)
      .then((response) => {
        dispatch("closeModal");
        Vue.$toast.success("Encontro realizado com sucesso!");
        dispatch("Meetings/addMeeting", response.data, { root: true });
        dispatch("Meetings/loadMeetings", rootState.Team.id, { root: true });
      })
      .catch((e) => {
        commit("setErrorMessagesMutation", e.response.data.errors);
        Vue.$toast.error(e.response.data.message);
      });
  },
  updateMeeting: ({ commit, state, dispatch, rootState }) => {
    commit("setTeam", rootState.Team.id);
    commit("setShift", rootState.Team.shift.id);
    commit("setTeacher", rootState.Team.teacher.id);
    commit("setEquipment", rootState.Team.equipment.id);
    updateMeeting(state.meeting.id, state.meeting)
      .then((response) => {
        dispatch("closeModal");
        Vue.$toast.success("Encontro atualizado com sucesso!");
        dispatch("Meetings/loadMeetings", response.data.id, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.message);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  loadMeeting: ({ commit }, payload) => {
    getMeeting(payload)
      .then(async (response) => {
        await commit("setPresencesMutation", response.data.meeting.presences);
        commit("setMeeting", response.data.meeting);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  suspend: ({ commit, state, dispatch, rootState }) => {
    const params = {
      id: state.meetingId,
    };
    suspend(params)
      .then(() => {
        Vue.$toast.success("Encontro suspenso com sucesso!");
        commit("toggleModalSuspendMeeting");
        dispatch("loadMeeting", state.meetingId);
        dispatch("Meetings/loadMeetings", rootState.Team.id, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel suspender o encontro! " +
            "Por favor verifique se o encontro está de acordo para ser suspenso."
        );

        console.log(e.response.data.errors);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  suspendMeetingsByTeamAndDate: ({ commit, dispatch, rootState }) => {
    const teams = rootState.ModalExportTeamPresence.teamsExport;
    const initial_date = rootState.ModalExportTeamPresence.initial_date;
    const final_date = rootState.ModalExportTeamPresence.final_date;
    if (teams.length > 0) {
      teams.forEach((team) => {
        suspendMeetingsByTeamAndDate(team?.id, initial_date, final_date)
          .then(() => {
            Vue.$toast.success("Encontros suspensos com sucesso!");
          })
          .catch((e) => {
            Vue.$toast.error(
              "Não foi possivel suspender os encontros! " +
                "Por favor verifique se os encontros estão de acordo para serem suspensos."
            );

            console.log(e.response.data.errors);
            commit("setErrorMessagesMutation", e.response.data.errors);
          });
      });
      commit("toggleModalSuspendMeetingsByTeamAndDate");
      dispatch("Meetings/loadMeetings", rootState.Team.id, { root: true });
    }
  },
  disableSuspension: ({ commit, state, dispatch, rootState }) => {
    const params = {
      id: state.meetingId,
    };
    disableSuspension(params)
      .then(() => {
        Vue.$toast.success("Suspensão desativada com sucesso!");
        commit("toggleModalDisableSuspensionMeeting");
        dispatch("loadMeeting", state.meetingId);
        dispatch("Meetings/loadMeetings", rootState.Team.id, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel reverter a suspensão! " +
            "Por favor verifique se o encontro está de acordo para reverter a suspensão."
        );

        console.log(e.response.data.errors);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      if (error.type === "blank") {
        return error.message;
      } else {
        return error.message;
      }
    } else {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
