import { getAxios, baseURL } from "./Api";

export async function getSchoolReinsertions() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`reinsercoes_escolares`);
}

export async function getSchoolReinsertionById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`reinsercoes_escolares/${_id}`);
}

export async function getSchoolReinsertionByInscription(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `reinsercoes_escolares/filtro_por_inscricao`;
  return api.get(url, { params: filters });
}

export async function getSchoolReinsertionsActivityTypes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`reinsercoes_escolares/tipos_atividades`);
}

export async function getSchoolReinsertionsResultTypes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`reinsercoes_escolares/tipos_resultados`);
}

export async function getSchoolReinsertionsSeries() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`reinsercoes_escolares/series`);
}

export async function getSchoolReinsertionsShifts() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`reinsercoes_escolares/turnos`);
}

export async function getSchoolReinsertionsTeachingModalities() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`reinsercoes_escolares/modalidades_ensino`);
}

export async function getSchoolReinsertionsAdministrativeDependencies() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`reinsercoes_escolares/dependencias_administrativas`);
}

export async function createSchoolReinsertion(school_reinsertion) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/reinsercoes_escolares", school_reinsertion);
}

export async function updateSchoolReinsertion(id, school_reinsertion) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`reinsercoes_escolares/${id}`, school_reinsertion);
}

export async function destroySchoolReinsertion(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`reinsercoes_escolares/${id}`);
}
