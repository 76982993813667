<template>
  <div class="flex ml-20">
    <div class="w-168">
      <div class="flex flex-col justify-center h-screen">
        <div>
          <BaseText type-text="custom" weight="semibold" size="3xl" color="herbal">
            <p>Olá instrutor(a)!</p>
            <p>Bem-vindo(a) ao sistema</p>
          </BaseText>
          <BaseText type-text="custom" text="Virando o Jogo!" weight="extrabold" size="3xl" color="herbal" />
          <div class="mt-5">
            <BaseText type-text="custom" text="Vamos começar?" weight="normal" size="lg" color="herbal" />
          </div>
        </div>
      </div>
    </div>
    <div class="background w-screen"></div>
  </div>
</template>

<script>
import BaseText from "../components/BaseText.vue";

export default {
  components: {
    BaseText,
  },
};
</script>

<style scoped>
.background {
  background-image: url("../assets/images/background-home-teacher.png");
  background-repeat: no-repeat;
}

.height {
  min-height: 100vh;
}
</style>
