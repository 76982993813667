<template>
  <div class="font-system">
    <div class="grid auto-grid-auto p-10">
      <div>
        <BaseText
          :text="city.name + '| Equipamento'"
          typeText="custom"
          weight="semibold"
          :italic="false"
          size="3xl"
          color="ashes-hover"
        />
      </div>
      <div class="my-8">
        <router-link to="/equipamentos">
          <BaseText
            text="<- Voltar"
            typeText="custom"
            weight="medium"
            :italic="false"
            mode="capitalize"
            size="sm"
            color="carmesim"
          />
        </router-link>
      </div>
      <div>
        <BaseText
          :text="equipment.name + ' - ' + equipment.city.name"
          typeText="custom"
          weight="semibold"
          :italic="false"
          mode="capitalize"
          size="3xl"
          color="ashes-dark"
        />
        <AppButtonIcon
          iconName="edit"
          :payload="equipment"
          dispatch="Equipment/openEditModalEquipment"
          v-if="permitUp"
        />
        <AppButtonIcon
          iconName="delete"
          :payload="equipment"
          dispatch="Equipment/openCloseDeleteEquipmentConfirmation"
          v-if="permitDes"
        />
      </div>
      <div class="border-b border-ashes-hover my-8">
        <BaseText
          text="Áreas Vinculadas"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="md"
          color="carmesim"
        />
      </div>
      <ul>
        <li v-for="area in this.equipment.areas_attributes" :key="area.id">
          -
          <BaseText
            :text="area.name"
            typeText="custom"
            weight="medium"
            :italic="false"
            mode="capitalize"
            size="md"
          />
        </li>
      </ul>
      <div class="border-b border-ashes-hover my-8">
        <BaseText
          text="Endereço"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="md"
          color="carmesim"
        />
      </div>
      <div class="grid grid-rols-auto">
        <div class="grid grid-cols-2">
          <div class="grid grid-rols-2">
            <div>
              <BaseText
                text="CEP"
                typeText="custom"
                weight="bold"
                :italic="false"
                mode="uppercase"
                size="sm"
                color="ashes-dark"
              />
            </div>
            <div>
              <BaseText
                :text="equipment.cep"
                typeText="custom"
                weight="medium"
                :italic="false"
                mode="capitalize"
                size="base"
              />
            </div>
          </div>
          <div class="grid grid-rols-2">
            <div>
              <BaseText
                text="Geoposicionamento"
                typeText="custom"
                weight="bold"
                :italic="false"
                mode="uppercase"
                size="sm"
                color="ashes-dark"
              />
            </div>
            <div>
              <BaseText
                :text="equipment.latitude + ', ' + equipment.longitude"
                typeText="custom"
                weight="medium"
                :italic="false"
                mode="capitalize"
                size="base"
              />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2">
          <div class="grid grid-rols-2">
            <div>
              <BaseText
                text="Rua"
                typeText="custom"
                weight="bold"
                :italic="false"
                mode="uppercase"
                size="sm"
                color="ashes-dark"
              />
            </div>
            <div>
              <BaseText
                :text="equipment.street"
                typeText="custom"
                weight="medium"
                :italic="false"
                mode="capitalize"
                size="base"
              />
            </div>
          </div>
          <div class="grid grid-rols-2">
            <div>
              <BaseText
                text="Número"
                typeText="custom"
                weight="bold"
                :italic="false"
                mode="uppercase"
                size="sm"
                color="ashes-dark"
              />
            </div>
            <div>
              <BaseText
                :text="equipment.street_number"
                typeText="custom"
                weight="medium"
                :italic="false"
                mode="capitalize"
                size="base"
              />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2">
          <div class="grid grid-rols-2">
            <div>
              <BaseText
                text="Bairro"
                typeText="custom"
                weight="bold"
                :italic="false"
                mode="uppercase"
                size="sm"
                color="ashes-dark"
              />
            </div>
            <div>
              <BaseText
                :text="equipment.neighborhood.name"
                typeText="custom"
                weight="medium"
                :italic="false"
                mode="capitalize"
                size="base"
              />
            </div>
          </div>
          <div class="grid grid-rols-2">
            <div>
              <BaseText
                text="Comunidade"
                typeText="custom"
                weight="bold"
                :italic="false"
                mode="uppercase"
                size="sm"
                color="ashes-dark"
              />
            </div>
            <div>
              <BaseText
                :text="equipment.community"
                typeText="custom"
                weight="medium"
                :italic="false"
                mode="capitalize"
                size="base"
              />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2">
          <div class="grid grid-rols-2">
            <div>
              <BaseText
                text="Municipio"
                typeText="custom"
                weight="bold"
                :italic="false"
                mode="uppercase"
                size="sm"
                color="ashes-dark"
              />
            </div>
            <div>
              <BaseText
                :text="equipment.city.name"
                typeText="custom"
                weight="medium"
                :italic="false"
                mode="capitalize"
                size="base"
              />
            </div>
          </div>
          <div class="grid grid-rols-2">
            <div>
              <BaseText
                text="Estado"
                typeText="custom"
                weight="bold"
                :italic="false"
                mode="uppercase"
                size="sm"
                color="ashes-dark"
              />
            </div>
            <div>
              <BaseText
                :text="equipment.state.name"
                typeText="custom"
                weight="medium"
                :italic="false"
                mode="capitalize"
                size="base"
              />
            </div>
          </div>
        </div>
        <div class="grid grid-rols-2">
          <div>
            <BaseText
              text="Complemento"
              typeText="custom"
              weight="bold"
              :italic="false"
              mode="uppercase"
              size="sm"
              color="ashes-dark"
            />
          </div>
          <div>
            <BaseText
              :text="equipment.complement"
              typeText="custom"
              weight="medium"
              :italic="false"
              mode="capitalize"
              size="base"
            />
          </div>
        </div>
      </div>
    </div>
    <ModalEquipment :open="openModalEquipment" />
    <DeleteEquipmentConfirmation :open="openModalDelete" />
  </div>
</template>

<script>
import ModalEquipment from "./ModalEquipment.vue";
import DeleteEquipmentConfirmation from "./alerts/DeleteEquipmentConfirmation.vue";
import BaseText from "../components/BaseText.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    ModalEquipment,
    DeleteEquipmentConfirmation,
    BaseText,
    AppButtonIcon,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
    };
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    equipmentDelete() {
      this.canAcces("equipment_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    equipmentChange() {
      this.canAcces("equipment_update").then((resp) => {
        this.permitUp = resp;
      });
    },
  },
  created() {
    let id = this.$route.params.id;
    this.$store.dispatch("Equipment/showEquiment", id);
    this.canAcces("equipment_update").then((resp) => {
      this.permit = resp;
    });
  },
  computed: {
    ...mapState({
      equipment: (state) => state.Equipment.insideEquipment,
      city: (state) => state.City.city,
      openModalDelete: (state) => state.Equipment.modalDeleteEquipmentConfirmationOpen,
      openModalEquipment: (state) => state.Equipment.modalEquipmentOpen,
    }),
  },
};
</script>
