<template>
  <div :class="classText">
    <BaseText :text="text" :color="color" :weight="weight" :mode="mode" />
    <hr />
  </div>
</template>

<script>
import BaseText from "./BaseText";

export default {
  components: {
    BaseText,
  },

  props: {
    text: {
      type: [String, Number],
      default: "Permissões Insuficientes para Visualização dessa Tela",
    },
    classText: {
      type: String,
    },
    mode: {
      type: String,
      default: "capitalize",
    },
    color: {
      type: String,
    },
    weight: {
      type: String,
      default: "normal",
    },
  },
};
</script>

<style></style>
