import { getAxios, baseURL } from "./Api";

export async function getSpontaneousProductiveInsertions(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `insercoes_produtivas_espontaneas`;
  return api.get(url, { params: filters });
}

export async function getSpontaneousProductiveInsertionsForExport(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `insercoes_produtivas_espontaneas/exportar_planilha`;
  return api.get(url, { params: filters });
}

export async function getSpontaneousProductiveInsertionContractingTypes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`insercoes_produtivas_espontaneas/tipos_contratacoes`);
}

export async function getSpontaneousProductiveInsertionInsertionTypes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`insercoes_produtivas_espontaneas/tipos_insercoes`);
}

export async function getSpontaneousProductiveInsertionById(_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`insercoes_produtivas_espontaneas/${_id}`);
}

export async function createSpontaneousProductiveInsertion(spontaneous_productive_insertion) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/insercoes_produtivas_espontaneas", spontaneous_productive_insertion);
}

export async function updateSpontaneousProductiveInsertion(id, spontaneous_productive_insertion) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`insercoes_produtivas_espontaneas/${id}`, spontaneous_productive_insertion);
}

export async function destroySpontaneousProductiveInsertion(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`insercoes_produtivas_espontaneas/${id}`);
}
