import { getAxios, baseURL } from "./Api";

// RETORNA LISTA DE TODOS OS DOCUMENTOS DO ESTUDANTE
export async function getStudentDocumentsByIdStudent(id_student) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/student_documents/?id_student=${id_student}`);
}
// RETORNA UM TIPO DE DOCUMENTO DO ESTUDANTE
export async function getStudentDocumentByDocumentType(id_student, document_type) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/student_documents/?id_student=${id_student}&document_type=${document_type}`);
}

export async function destroyStudentDocument(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`/student_documents/${id}`);
}

export async function createStudentDocument(document) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/student_documents", document);
}

export async function updateStudentDocument(id, document) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`/student_documents/${id}`, { document });
}
// RETORNA OS DADOS DE UM DOCUMENTO ESPECÍFICO DO ESTUDANTE, IDENTIFICADO PELO ID ÚNICO
export async function showStudentDocument(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/student_documents/${id}`);
}
