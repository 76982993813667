<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid 4">
        <div class="justify-self-start">
          <Label
            text="Atualizar Status de Todos os Beneficiários"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="PaymentSheets/closeModalAddPaymentSheet" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Selecionar Status"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            label-text="Status"
            show-label
            clear-on-select
            required
            select-placeholder="Selecione o status"
            :multiple-option="false"
            :value="status_for_update_all"
            :options="beneficiaries_status_options"
            :dispatch="setStatusForUpdateAll"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="Beneficiaries/closeModalUpdateAllBeneficiariesStatuses"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            text="Salvar"
            typeButton="success"
            dispatch="Beneficiaries/saveUpdateAllBeneficiariesStatuses"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";
import Label from "@/components/Label.vue";
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import Multiselect from "@/components/forms/Multiselect.vue";

export default {
  components: {
    Multiselect,
    BaseModal,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      payment_sheet: (state) => state.PaymentSheets.payment_sheet,
      scholarship_matrix: (state) => state.PaymentSheets.scholarship_matrix,
      installment: (state) => state.PaymentSheets.installment,
      remittance: (state) => state.PaymentSheets.remittance,
      status_for_update_all: (state) => state.Beneficiaries.status_for_update_all,
      beneficiaries_status_options: (state) => state.UtilFunctions.beneficiaries_status_options,
    }),
    getTitle() {
      let title = "";
      title = this.payment_sheet
        ? `Atualizar Status de Todos os Beneficiários -  Folha de pagamento - Remessa [${this.remittance?.name}] da Parcela [${this.installment?.name}`
        : "Carregando";
      return title;
    },
    ...mapGetters({
      getErrorMessage: "PaymentSheets/getErrorMessage",
    }),
  },
  methods: {
    ...mapActions({
      setStatusForUpdateAll: "Beneficiaries/setStatusForUpdateAll",
    }),
  },
};
</script>
