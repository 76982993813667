<template>
  <div>
    <div
      class="mr-8 font-system uppercase font-medium text-xs text-ashes-dark"
      v-if="data && data?.length > 0"
    >
      <input class="mr-2" type="checkbox" v-model="allChecked" @change="onCheckAll" />
      <label for="checkAll">Marcar todas as turmas</label>
    </div>

    <div id="tabs-button" class="my-6 tabs-button-container">
      <FilterComponent>
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          label-text="Filtrar por Cidade"
          select-placeholder="Filtrar por Cidade"
          :multiple-option="false"
          :value="params_teams.city"
          :options="cities"
          :dispatch="setFilterTeamCity"
        />
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          label-text="Filtrar por Área"
          select-placeholder="Filtrar por Área"
          :multiple-option="false"
          :value="params_teams.area"
          :options="user_areas"
          :dispatch="setFilterTeamArea"
        />
        <BaseInput
          active
          input-name="code"
          input-type="text"
          icon-name="search"
          showIcon
          show-label
          :value="params_teams.code"
          label-text="Filtrar pelo Código da Turma"
          input-placeholder="Filtrar pelo Código da Turma"
          dispatch="Advantages/setFilterTeamCode"
        />
      </FilterComponent>
      <AppButtonColorful
        id="btn-consult-teams-benefits"
        showIcon
        iconName="search"
        text="Filtrar Turmas"
        dispatch="Advantages/loadTeams"
        typeButton="primary"
      />
    </div>

    <BaseStaggeredFade :duration="10" tag="ul">
      <li :key="team.id" v-for="(team, index) in data" class="mb-4">
        <BaseCard :class="liClass">
          <div class="flex justify-between items-center pt-2">
            <div class="flex items-center">
              <input
                :id="team.code"
                type="checkbox"
                name="teamsAllocations"
                :value="team.id"
                :checked="isTeamFullySelected(team)"
                @click="onCheck(team, $event)"
                class="mr-4"
              />
              <div :class="iconClass">
                <AppMaterialIcon
                  iconName="book"
                  iconType="outlined"
                  class="place-self-center text-white"
                  iconSize="36"
                />
              </div>
              <BaseText
                :text="team.code"
                typeText="custom"
                mode="underline"
                weight="bold"
                size="xl"
                class="mr-1"
              />
              <span class="text-sm text-gray-600 ml-2">
                {{ selectedCount(team) }} de {{ team.students_actives.length }} alunos selecionados
              </span>
            </div>
            <button @click="toggleTeamDetails(index)" class="focus:outline-none">
              <AppMaterialIcon
                :iconName="expandedTeams.includes(index) ? 'remove' : 'add'"
                iconType="outlined"
                class="text-black"
                iconSize="24"
              />
            </button>
          </div>
          <transition name="expand">
            <ul
              v-show="expandedTeams.includes(index)"
              class="bg-light-blue p-4 rounded-lg mt-2 overflow-hidden team-details"
            >
              <li
                v-for="student in team.students_actives"
                :key="student.teams_student_id"
                class="flex items-center mb-2"
              >
                <input
                  type="checkbox"
                  :value="student.teams_student_id"
                  v-model="selectedStudents"
                  @change="onStudentCheck(student, team)"
                  class="mr-2"
                />
                <span>{{ student.student_name }}</span>
              </li>
            </ul>
          </transition>
        </BaseCard>
      </li>
    </BaseStaggeredFade>
    <div v-if="getLoadingVariablesState">
      <PreLoading />
    </div>
  </div>
</template>

<script>
import BaseText from "@/components/BaseText.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import BaseStaggeredFade from "@/components/BaseStaggeredFade.vue";
import AppMaterialIcon from "@/components/AppMaterialIcon.vue";
import BaseCard from "@/components/BaseCard.vue";
import PreLoading from "@/components/PreLoading.vue";
import { mapActions, mapState } from "vuex";
import Multiselect from "@/components/forms/Multiselect.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import AppButtonColorful from "@/components/AppButtonColorful.vue";

export default {
  components: {
    AppButtonColorful,
    FilterComponent,
    Multiselect,
    BaseInput,
    BaseText,
    BaseStaggeredFade,
    AppMaterialIcon,
    BaseCard,
    PreLoading,
  },
  computed: {
    ...mapState({
      params_teams: (state) => state.Advantages.params.team,
      cities: (state) => state.UtilFunctions.cities,
      user_areas: (state) => state.UtilFunctions.user_areas,
      loading_load_teams: (state) => state.Advantages.loading_variables.load_teams,
    }),
    liClass() {
      return {
        "w-full": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        "p-6": true,
        "bg-white": true,
      };
    },
    iconClass() {
      return [
        "bg-burnedYellow-classic",
        "rounded-full",
        "w-12",
        "h-12",
        "grid",
        "mr-3",
        "items-center",
        "justify-center",
        "min-w-1",
      ];
    },
    getLoadingVariablesState() {
      return this.loading_load_teams;
    },
  },
  data() {
    return {
      allChecked: false,
      selectedItems: [],
      selectedStudents: [],
      expandedTeams: [],
    };
  },
  props: {
    data: {
      type: Array,
    },
  },
  methods: {
    toggleTeamDetails(index) {
      const position = this.expandedTeams.indexOf(index);
      if (position === -1) {
        this.expandedTeams.push(index);
      } else {
        this.expandedTeams.splice(position, 1);
      }
    },
    onCheck(team, event) {
      if (event.target.checked) {
        team.students_actives.forEach((student) => {
          if (!this.selectedStudents.includes(student.teams_student_id)) {
            this.selectedStudents.push(student.teams_student_id);
            this.$store.commit("PaymentSheets/addStudentId", student.teams_student_id);
          }
        });
      } else {
        team.students_actives.forEach((student) => {
          const index = this.selectedStudents.indexOf(student.teams_student_id);
          if (index !== -1) {
            this.selectedStudents.splice(index, 1);
            this.$store.commit("PaymentSheets/removeStudentId", student.teams_student_id);
          }
        });
      }
      this.emitSelectionChange();
    },
    onStudentCheck(student, team) {
      if (this.selectedStudents.includes(student.teams_student_id)) {
        this.$store.commit("PaymentSheets/addStudentId", student.teams_student_id);
      } else {
        this.$store.commit("PaymentSheets/removeStudentId", student.teams_student_id);
        const teamIndex = this.selectedItems.indexOf(team.id);
        if (teamIndex !== -1) {
          this.selectedItems.splice(teamIndex, 1);
        }
      }
      this.emitSelectionChange();
    },
    onCheckAll() {
      if (this.allChecked) {
        this.selectedItems = this.data.map((item) => item.id);
        this.data.forEach((item) => {
          item.students_actives.forEach((student) => {
            if (!this.selectedStudents.includes(student.teams_student_id)) {
              this.selectedStudents.push(student.teams_student_id);
              this.$store.commit("PaymentSheets/addStudentId", student.teams_student_id);
            }
          });
        });
      } else {
        this.data.forEach((item) => {
          item.students_actives.forEach((student) => {
            const index = this.selectedStudents.indexOf(student.teams_student_id);
            if (index !== -1) {
              this.selectedStudents.splice(index, 1);
              this.$store.commit("PaymentSheets/removeStudentId", student.teams_student_id);
            }
          });
        });
        this.selectedItems = [];
      }
      this.emitSelectionChange();
    },
    emitSelectionChange() {
      this.$emit("selection-change", this.selectedStudents.length > 0);
    },
    isTeamFullySelected(team) {
      return team.students_actives.every((student) =>
        this.selectedStudents.includes(student.teams_student_id)
      );
    },
    selectedCount(team) {
      return team.students_actives.filter((student) =>
        this.selectedStudents.includes(student.teams_student_id)
      ).length;
    },
    ...mapActions({
      setFilterTeamCity: "Advantages/setFilterTeamCity",
      setFilterTeamArea: "Advantages/setFilterTeamArea",
      setFilterTeamEquipment: "Advantages/setFilterTeamEquipment",
    }),
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 56% 18% 26% 0%;
}
.tabs-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.team-details {
  position: relative;
  z-index: 10;
}
.align-right {
  margin-left: auto;
}
.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.3s ease, opacity 0.3s ease;
}
.expand-enter,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}
.bg-light-blue {
  background-color: #f0f8ff;
}
</style>
