<template>
  <div class="m-5">
    <div id="person-data" class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-5">
        <BaseText text="Informações da Situação" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="Problema"
          :text="matrix_problem ? matrix_problem.name : '-'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="Situação"
          :text="matrix_situation.situation ? matrix_situation.situation : '-'"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="Nível da Situação"
          :text="situation_risk ? situation_risk.name : '-'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="Impacto"
          :text="matrix_situation.impact ? matrix_situation.impact : '-'"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="Nível do Impacto"
          :text="impact_level ? impact_level.name : '-'"
          type-text="topic-details"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppTextField from "../../components/AppTextField.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppTextField,
  },
  computed: {
    ...mapState({
      matrix_situation: (state) => state.MatrixSituation.matrix_situation,
      matrix_problem: (state) => state.MatrixSituation.matrix_problem,
      situation_risk: (state) => state.MatrixSituation.situation_risk,
      impact_level: (state) => state.MatrixSituation.impact_level,
    }),
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
    }),
  },
};
</script>
<style>
.active_new {
  background-color: #fff7ed;
  color: #fff;
  font-size: 1.1em;
}
</style>
