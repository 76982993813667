import { getAxios, baseURL } from "./Api";

export async function getBeneficiaries(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `beneficiarios`;
  return api.get(url, { params: filters });
}

export async function getBeneficiariesForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `beneficiarios/para_menus`;
  return api.get(url, { params: filters });
}

export async function getBeneficiariesForExport(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `beneficiarios/exportar_planilha`;
  return api.get(url, { params: filters });
}

export async function getBeneficiaryStatus() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`beneficiarios/status`);
}

export async function getBeneficiaryById(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`beneficiarios/${id}`);
}

export async function createBeneficiary(beneficiary) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/beneficiarios", beneficiary);
}

export async function updateBeneficiary(id, beneficiary) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`beneficiarios/${id}`, beneficiary);
}

export async function destroyBeneficiary(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`beneficiarios/${id}`);
}
