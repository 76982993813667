<template>
  <div class="font-system">
    <div class="mx-12 my-6">
      <Breadcrumbs :breadcrumbs="breadcrumbs" />
    </div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-2xl mx-2 col-span-2" v-html="getTitle"></div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate && !payment_sheet.closed"
          id="btn-add-payment_sheet"
          showIcon
          iconName="add"
          text="Adicionar Beneficiários"
          dispatch="PaymentSheets/openModalAddBeneficiariesScholarship"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Status do Pagamento"
            select-placeholder="Selecione o status"
            :multiple-option="false"
            :value="beneficiary_params.status"
            :options="beneficiaries_status_options"
            :dispatch="setFilterStatus"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Município"
            select-placeholder="Selecione o município"
            :multiple-option="false"
            :value="beneficiary_params.city"
            :options="cities"
            :dispatch="setFilterCity"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Área"
            select-placeholder="Selecione a área"
            :multiple-option="false"
            :value="beneficiary_params.area"
            :options="user_areas"
            :dispatch="setFilterArea"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            :label-text="getLabelEquipmentFilter()"
            select-placeholder="Selecione o equipamento"
            :multiple-option="false"
            :value="beneficiary_params.equipment"
            :options="equipments"
            :dispatch="setFilterEquipment"
          />
        </div>
        <div class="col-span-4">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label="code"
            label-text="Turma"
            select-placeholder="Selecione a Turma"
            :multiple-option="false"
            :value="beneficiary_params.team"
            :options="teams_for_menus"
            :dispatch="setFilterTeam"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="beneficiary_params.name"
            label-text="Pesquisar por Nome"
            input-placeholder="Pesquisar Nome"
            dispatch="Beneficiaries/setFilterName"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="cpf"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="beneficiary_params.cpf"
            label-text="Pesquisar por CPF"
            input-placeholder="Pesquisar CPF"
            dispatch="Beneficiaries/setFilterCpf"
          />
        </div>
      </FilterComponent>
      <div class="flex mt-2">
        <div v-if="permit_export_spreadsheet && beneficiaries.length" class="mr-4">
          <a href="javascript:void(0)" @click="exportToExcel('beneficiaries_spreadsheet')">
            <img
              class="inline-block"
              src="../../../../assets/images/logout_black_24dp.svg"
              alt=""
              style="cursor: pointer"
            />
            <BaseText
              text="Exportar Folha de Pagamento"
              typeText="custom"
              :italic="false"
              size="xs"
              color="burnedYellow"
              style="cursor: pointer"
            />
          </a>
        </div>
        <div v-if="permitDownloadPdf && beneficiaries.length">
          <a href="javascript:void(0)" @click="exportToPdf()">
            <img
              class="inline-block"
              src="../../../../assets/images/pdf_icon.svg"
              alt=""
              style="cursor: pointer"
            />
            <BaseText
              text="Exportar Folha de Pagamento em PDF"
              typeText="custom"
              :italic="false"
              size="xs"
              color="burnedYellow"
              style="cursor: pointer"
            />
          </a>
        </div>
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-4 items-center mx-12 my-6">
      <div class="col-span-3 flex space-x-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-payment_sheets"
          showIcon
          iconName="search"
          text="Consultar Beneficiários"
          dispatch="Beneficiaries/loadBeneficiaries"
          payload="load"
          typeButton="primary"
        />
        <AppButtonMinimal
          v-if="beneficiaries.length > 0"
          id="btn-update-all-statuses"
          text="Atualizar Todos os Status"
          iconName="update"
          size="small"
          variant="filled"
          color="secondary"
          dispatch="Beneficiaries/openModalUpdateAllBeneficiariesStatuses"
          compact
        />
        <AppButtonMinimal
          v-if="beneficiaries.length > 0"
          id="btn-update-all-values"
          text="Valor Padrão"
          iconName="payments"
          size="small"
          variant="filled"
          color="warning"
          dispatch="Beneficiaries/openModalUpdateAllBeneficiariesValues"
          compact
        />
        <AppButtonMinimal
          v-if="beneficiaries.length > 0 && !payment_sheet.closed"
          id="btn-close-payment-sheet"
          text="Fechar Folha de Pagamento"
          iconName="cancel"
          size="small"
          variant="filled"
          color="danger"
          dispatch="PaymentSheets/closePaymentSheet"
          compact
        />
        <AppButtonMinimal
          v-if="payment_sheet.closed"
          id="btn-close-payment-sheet"
          text="Abrir Folha de Pagamento"
          iconName="check"
          size="small"
          variant="filled"
          color="success"
          dispatch="PaymentSheets/openPaymentSheet"
          compact
        />
      </div>
      <div class="flex justify-end">
        <a href="javascript:void(0)" @click="clearFields()" v-if="beneficiaries.length > 0">
          <AppButtonColorful
            id="btn-consult-payment_sheets"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-payment_sheets">
      <div class="mx-12">
        <TransportationVoucherBeneficiariesCards :data="beneficiaries" />
      </div>
      <div id="payment_sheets-pagination" class="mx-12 my-6 pb-3" v-if="beneficiaries.length > 0">
        <BasePagination
          :total="totalBeneficiaries"
          :total-per-page="totalPerPage"
          dispatch="Beneficiaries/setBeneficiariesOffset"
        />
        <div class="info-box">
          <div class="info-text">
            Total de Beneficiários: <span class="info-value">{{ payment_sheet.total_beneficiaries }}</span>
          </div>
          <div class="info-text">
            Valor Total:
            <span class="info-value"
              >R$
              {{
                payment_sheet.total_payment_value.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="getLoadingVariablesState">
      <PreLoading />
    </div>
    <ModalAddBeneficiaries :open="modalToggleAddBeneficiariesScholarship" />
    <ModalUpdateAllBeneficiariesStatuses :open="modalToggleUpdateAllBeneficiaresStatuses" />
    <ModalUpdateAllBeneficiariesValues :open="modalToggleUpdateAllBeneficiaresValues" />
    <ConfirmUpdateAllBeneficiariesStatuses :open="modalToggleConfirmUpdateAllBeneficiaresStatuses" />
    <ModalExportSpreadsheets
      :dataSpreadsheet="dataSpreadsheet"
      :modalToggleExport="modalToggleExport"
      :titleModal="titleModal"
      :dispatchToCloseModal="dispatchToCloseModal"
      :json_data="json_data"
      :sheetTabName="sheetTabName"
      :fileName="fileName"
      :variablePreloading="variablePreloading"
      :json_fields="json_fields"
      :dispatchOpenModal="dispatchOpenModal"
    />
  </div>
</template>

<script>
import BasePagination from "@/components/BasePagination.vue";
import AppButtonColorful from "@/components/AppButtonColorful.vue";
import AppButtonMinimal from "@/components/AppButtonMinimal.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";
import Multiselect from "@/components/forms/Multiselect.vue";
import ModalUpdateAllBeneficiariesStatuses from "@/views/benefits/gerenal_modals/ModalUpdateAllBeneficiariesStatuses.vue";
import ModalUpdateAllBeneficiariesValues from "@/views/benefits/gerenal_modals/ModalUpdateAllBeneficiariesValues.vue";
import ConfirmUpdateAllBeneficiariesStatuses from "@/views/alerts/ConfirmUpdateAllBeneficiariesStatuses.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import TransportationVoucherBeneficiariesCards from "@/views/list/TransportationVoucherBeneficiariesCards.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import ModalAddBeneficiaries from "@/views/benefits/gerenal_modals/ModalAddBeneficiaries.vue";
import BaseText from "@/components/BaseText.vue";
import ModalExportSpreadsheets from "@/components/ModalExportSpreadsheets.vue";
import PreLoading from "@/components/PreLoading.vue";

export default {
  components: {
    ModalExportSpreadsheets,
    BaseText,
    Breadcrumbs,
    TransportationVoucherBeneficiariesCards,
    FilterComponent,
    Multiselect,
    BasePagination,
    AppButtonColorful,
    AppButtonMinimal,
    BaseInput,
    ModalAddBeneficiaries,
    ModalUpdateAllBeneficiariesStatuses,
    ModalUpdateAllBeneficiariesValues,
    ConfirmUpdateAllBeneficiariesStatuses,
    PreLoading,
  },
  data() {
    return {
      json_fields_beneficiaries: {
        Nª: "number",
        "Nome Completo": "name",
        CPF: "cpf",
        "Valor do Crédito": "value",
        "Status do Pagamento": "status",
      },
      type_export: null,
      permit: false,
      permitCreate: false,
      permitDownloadPdf: false,
      permit_export_spreadsheet: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterStatus: "Beneficiaries/setFilterStatus",
      setFilterCity: "Beneficiaries/setFilterCity",
      setFilterArea: "Beneficiaries/setFilterArea",
      setFilterEquipment: "Beneficiaries/setFilterEquipment",
      setFilterTeam: "Beneficiaries/setFilterTeam",
      clearFields() {
        this.$store.dispatch("Beneficiaries/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    getLabelEquipmentFilter() {
      return this.user_areas_filtered
        ? "Selecionar Equipamento"
        : "Selecione a área para listar os equipamentos";
    },
    async exportToPdf() {
      await this.$store.dispatch("PaymentSheets/exportPaymentSheetPdf");
    },
    async exportToExcel(export_type) {
      this.type_export = export_type;
      let modalOptions = {};
      switch (export_type) {
        case "beneficiaries_spreadsheet":
          modalOptions = {
            title: "Exportar Folha de Pagamento",
            closeModalDispatch: "Beneficiaries/closeExportBeneficiariesSpreadsheetModal",
            sheetTabName: "folha_de_pagamento",
            fileName: "folha_de_pagamento.xls",
            jsonFields: this.json_fields_beneficiaries,
            openModalDispatch: "Beneficiaries/loadBeneficiariesForExport",
          };
          break;
      }
      await this.$store.dispatch("UtilFunctions/setSelectFieldsToExportFields", modalOptions);
    },
  },
  async created() {
    this.backToTop();
    this.canAcces("payment_sheet_list_beneficiaries").then((response) => {
      this.permit = response;
    });
    this.canAcces("payment_sheet_add_beneficiaries").then((response) => {
      this.permitCreate = response;
    });
    this.canAcces("payment_sheet_export_pdf").then((response) => {
      this.permitDownloadPdf = response;
    });
    this.canAcces("payment_sheet_export_beneficiaries_spreadsheet").then((resp) => {
      this.permit_export_spreadsheet = resp;
    });
    const season = JSON.parse(localStorage.getItem("season"));
    if (season) {
      await this.$store.dispatch("Advantages/setSeason", season);
    }
    await this.$store.dispatch("UtilFunctions/loadCities");
    await this.$store.dispatch("UtilFunctions/loadTeamsForMenus");
  },
  async mounted() {
    const payload = {
      id: this.$route.params.id,
    };
    await this.$store.dispatch("PaymentSheets/loadData", payload);
    await this.$store.dispatch("Beneficiaries/loadBeneficiaries");
  },
  computed: {
    ...mapState({
      beneficiaries: (state) => state.Beneficiaries.beneficiaries,
      beneficiary_params: (state) => state.Beneficiaries.params,
      area: (state) => state.Beneficiaries.params.area,
      payment_sheet: (state) => state.PaymentSheets.payment_sheet,
      installment: (state) => state.PaymentSheets.installment,
      remittance: (state) => state.PaymentSheets.remittance,
      equipments: (state) => state.Beneficiaries.equipments,
      offset: (state) => state.Beneficiaries.beneficiariesOffset,
      totalPerPage: (state) => state.Beneficiaries.beneficiariesTotalPerPage,
      totalBeneficiaries: (state) => state.Beneficiaries.beneficiariesTotal,
      beneficiaries_status_options: (state) => state.UtilFunctions.beneficiaries_status_options,
      teams_for_menus: (state) => state.UtilFunctions.teams_for_menus,
      user_areas: (state) => state.UtilFunctions.user_areas,
      cities: (state) => state.UtilFunctions.cities,
      modalToggleUpdateAllBeneficiaresStatuses: (state) =>
        state.Beneficiaries.modalToggleUpdateAllBeneficiaresStatuses,
      modalToggleUpdateAllBeneficiaresValues: (state) =>
        state.Beneficiaries.modalToggleUpdateAllBeneficiaresValues,
      modalToggleConfirmUpdateAllBeneficiaresStatuses: (state) =>
        state.Beneficiaries.modalToggleConfirmUpdateAllBeneficiaresStatuses,
      modalToggleAddBeneficiariesScholarship: (state) =>
        state.PaymentSheets.modalToggleAddBeneficiariesScholarship,
      beneficiaries_spreadsheet: (state) => state.Beneficiaries.beneficiaries_spreadsheet,
      hasSpreadsheetBeneficiariesResponse: (state) => state.Beneficiaries.hasSpreadsheetBeneficiariesResponse,
      modalToggleExportBeneficiariesSpreadsheet: (state) =>
        state.Beneficiaries.modalToggleExportBeneficiariesSpreadsheet,
      json_data_beneficiary: (state) => state.Beneficiaries.json_data_beneficiary,

      loading_add_beneficiaries: (state) => state.PaymentSheets.loading_variables.add_beneficiaries,
      loading_export_pdf: (state) => state.PaymentSheets.loading_variables.export_pdf,
      loading_load_beneficiaries: (state) => state.Beneficiaries.loading_variables.load_beneficiaries,
      loading_update_all_beneficiaries_statuses: (state) =>
        state.Beneficiaries.loading_variables.update_all_beneficiaries_statuses,

      dataSpreadsheet: (state) => state.SelectFieldsToExport.dataSpreadsheet,
      modalToggleExport: (state) => state.SelectFieldsToExport.modalToggleExport,
      titleModal: (state) => state.SelectFieldsToExport.titleModal,
      dispatchToCloseModal: (state) => state.SelectFieldsToExport.dispatchToCloseModal,
      json_data: (state) => state.SelectFieldsToExport.json_data,
      sheetTabName: (state) => state.SelectFieldsToExport.sheetTabName,
      fileName: (state) => state.SelectFieldsToExport.fileName,
      variablePreloading: (state) => state.SelectFieldsToExport.variablePreloading,
      json_fields: (state) => state.SelectFieldsToExport.json_fields,
      dispatchOpenModal: (state) => state.SelectFieldsToExport.dispatchOpenModal,
    }),
    getTitle() {
      let title = "";
      if (this.payment_sheet && this.payment_sheet.reference && this.payment_sheet.process_number) {
        title = `Folha de pagamento - Referência [${this.payment_sheet.reference}]  - Nº do Processo [${this.payment_sheet.process_number}]`;
        if (this.payment_sheet.closed) {
          title += ` <span class="text-red-500">[Fechada]</span>`;
        }
      } else {
        title = "Carregando";
      }
      return title;
    },
    breadcrumbs() {
      return [
        { name: "Home", path: "/admin" },
        { name: "Vale-Transporte", path: "/vale_transporte" },
        { name: "Detalhes", path: this.$route.path },
      ];
    },
    getLoadingVariablesState() {
      return (
        this.loading_add_beneficiaries ||
        this.loading_load_beneficiaries ||
        this.loading_export_pdf ||
        this.loading_update_all_beneficiaries_statuses
      );
    },
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Beneficiaries/loadBeneficiaries");
      this.backToTop();
    },
    area: function () {
      this.$store.dispatch("Beneficiaries/loadEquipments");
    },
    beneficiaries_spreadsheet: {
      async handler(newValue) {
        if (this.type_export === "beneficiaries_spreadsheet") {
          await this.$store.dispatch("SelectFieldsToExport/setDataSpreadsheet", newValue);
        }
      },
      deep: true,
    },
    json_data_beneficiary: {
      async handler(newValue) {
        if (this.type_export === "beneficiaries_spreadsheet") {
          await this.$store.dispatch("SelectFieldsToExport/setJsonData", newValue);
        }
      },
      deep: true,
    },
    modalToggleExportBeneficiariesSpreadsheet: async function () {
      if (this.type_export === "beneficiaries_spreadsheet") {
        await this.$store.dispatch(
          "SelectFieldsToExport/setModalToggleExport",
          this.modalToggleExportBeneficiariesSpreadsheet
        );
      }
    },
    hasSpreadsheetBeneficiariesResponse: async function () {
      if (this.type_export === "beneficiaries_spreadsheet") {
        await this.$store.dispatch(
          "SelectFieldsToExport/setVariablePreloading",
          this.hasSpreadsheetBeneficiariesResponse
        );
      }
    },
  },
};
</script>
<style scoped>
.filters-container {
  margin-top: 10px;
}
.info-box {
  text-align: right;
  color: #4a4a4a;
  font-weight: 500;
  font-size: 1.125rem;
}
.info-text {
  margin-bottom: 0.5rem;
}
.info-value {
  color: #f97316;
}
</style>
