<template>
  <div v-if="open">
    <!-- <div id="tabs-button" class="grid grid-cols-2 items-center">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-psychosocial_accompaniments"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="MatrixRiskStratifications/loadMatrixRiskStratificationsByInscription"
          typeButton="primary"
        />
      </div>
      <div
        class="mx-2 items-center justify-self-end"
        v-if="matrix_risk_stratifications_by_inscription.length > 0"
      >
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-psychosocial_accompaniments"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div> -->
    <div id="tabs-button" class="grid grid-cols-2 justify-between p-4 items-end gap-4">
      <div>
        <BaseText
          text="Histórico de Atualizações"
          type-text="custom"
          weight="normal"
          color="carmesim"
          size="sm"
        />
      </div>
      <div class="justify-self-end" v-if="permitCreate">
        <AppButtonColorful
          text="Matriz"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="MatrixRiskStratifications/openEditModalMatrixRiskStratification"
        />
      </div>
    </div>
    <hr class="border-t-0 border-b-2 border-ashes opacity-50 mt-5" />
    <div id="list-psychosocials">
      <div class="mx-12">
        <PsychosocialAccompanimentVulnerabilityByInscriptionCards
          :data="matrix_risk_stratifications_by_inscription"
        />
      </div>
      <div
        id="matrix_risk_stratifications_by_inscriptio_pagination"
        class="mx-12 my-6 pb-3"
        v-if="matrix_risk_stratifications_by_inscription.length > 0"
      >
        <BasePagination
          :total="matrixRiskStratificationByInscriptionTotal"
          :total-per-page="matrixRiskStratificationByInscriptionTotalPerPage"
          dispatch="MatrixRiskStratifications/setMatrixRiskStratificationByInscriptionOffset"
        />
      </div>
    </div>
    <ModalViewMatrixRisKStratificationByInscription :open="modalToggleViewMatrixRiskStratifications" />
    <ModalMatrixRiskStratifications :open="modalToggleMatrixRiskStratifications" />
    <DeleteMatrixRiskStratificationConfirmation :open="modalConfirmDeleteMatrixRiskStratification" />
  </div>
</template>

<script>
import PsychosocialAccompanimentVulnerabilityByInscriptionCards from "../list/PsychosocialAccompanimentVulnerabilityByInscriptionCards.vue";
import ModalViewMatrixRisKStratificationByInscription from "../datas/ModalViewMatrixRisKStratificationByInscription.vue";
import BasePagination from "../../components/BasePagination.vue";
import ModalMatrixRiskStratifications from "../../views/ModalMatrixRiskStratifications.vue";
import BaseText from "../../components/BaseText.vue";
import DeleteMatrixRiskStratificationConfirmation from "../alerts/DeleteMatrixRiskStratificationConfirmation.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AppButtonColorful,
    BasePagination,
    BaseText,
    DeleteMatrixRiskStratificationConfirmation,
    ModalViewMatrixRisKStratificationByInscription,
    ModalMatrixRiskStratifications,
    PsychosocialAccompanimentVulnerabilityByInscriptionCards,
  },
  data() {
    return {
      currentPage: 1,
      permitCreate: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setSeason: "PsychosocialAccompaniments/setSeason",
      filterListByTypeAttendance: "PsychosocialAccompaniments/filterListByTypeAttendance",
      closeExportPsychosocialAccompanimentEvolutionSpreadsheetModal:
        "PsychosocialAccompaniments/closeExportPsychosocialAccompanimentEvolutionSpreadsheetModal",
    }),
    async clearFields() {
      await this.$store.dispatch("MatrixRiskStratifications/clearQuery");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.canAcces("matrix_risk_stratification_create").then((response) => {
      this.permitCreate = response;
    });
    this.$store.dispatch("MatrixRiskStratifications/loadMatrixRiskStratificationsByInscription");
    this.backToTop();
  },

  computed: {
    ...mapState({
      psychosocial_accompaniments: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniments,
      matrix_risk_stratifications_by_inscription: (state) =>
        state.MatrixRiskStratifications.matrix_risk_stratifications_by_inscription,
      modalToggleViewMatrixRiskStratifications: (state) =>
        state.MatrixRiskStratifications.modalToggleViewMatrixRiskStratifications,

      matrixRiskStratificationByInscriptionTotal: (state) =>
        state.MatrixRiskStratifications.matrixRiskStratificationByInscriptionTotal,
      matrixRiskStratificationByInscriptionTotalPerPage: (state) =>
        state.MatrixRiskStratifications.matrixRiskStratificationByInscriptionTotalPerPage,
      matrixRiskStratificationByInscriptionOffset: (state) =>
        state.MatrixRiskStratifications.matrixRiskStratificationByInscriptionOffset,
      modalToggleMatrixRiskStratifications: (state) =>
        state.MatrixRiskStratifications.modalToggleMatrixRiskStratifications,
      modalConfirmDeleteMatrixRiskStratification: (state) =>
        state.MatrixRiskStratifications.modalConfirmDeleteMatrixRiskStratification,
    }),
  },
  watch: {
    matrixRiskStratificationByInscriptionOffset: function () {
      this.$store.dispatch("MatrixRiskStratifications/loadMatrixRiskStratificationsByInscription");
      this.backToTop();
    },
  },
};
</script>
