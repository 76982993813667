<template>
  <BaseCard :class="liClass">
    <div class="grid grid-cols-custom gap-10 items-center pt-2">
      <div>
        <div class="flex justify-start items-center">
          <div :class="iconClass">
            <AppMaterialIcon
              iconName="book"
              iconType="outlined"
              class="place-self-center text-white"
              iconSize="36"
            />
          </div>
          <BaseText
            :text="team.code"
            typeText="custom"
            mode="underline"
            weight="bold"
            size="base"
            class="x1"
          />
          <BaseText text="|" typeText="custom" weight="normal" size="base" class="mr-1" />
          <div>
            <BaseText
              :text="
                team.discipline.phase.id == 2
                  ? team.discipline.name + ' - ' + team.course.name
                  : team.discipline.name
              "
              typeText="custom"
              mode="underline"
              weight="bold"
              size="sm"
              class="mr-1"
            />
          </div>
          <div class="flex items-center ml-1 pr-1 col-span-2 tooltip" v-if="team.has_unregistered_meeting">
            <button ref="btnRef" v-on:mouseenter="toggleTooltip()" v-on:mouseleave="toggleTooltip()">
              <span class="material-icons mt-2 text-yellow-400">
                <span class="material-icons"> warning </span>
              </span>
            </button>
            <div
              ref="tooltipRef"
              v-bind:class="{ hidden: !tooltipShow, block: tooltipShow }"
              class="bg-white border border-black-600 mr-3 block font-normal leading-normal text-sm text-left break-words rounded-lg tooltiptext shadow-xl w-auto"
            >
              <div class="w-full">
                <div class="text-red-500 font-semibold p-2">
                  <span class="tolltiptext-position"> Encontro(s) com presenças pendentes de registro! </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <BaseText :text="getQtdStudents()" typeText="custom" size="sm" color="ashes-dark" />
      </div>
      <div>
        <BaseText :text="`${team.start} - ${team.closing}`" typeText="custom" size="sm" color="ashes-dark" />
      </div>
      <div>
        <BaseText :text="getTeacherName()" typeText="custom" size="sm" color="ashes-dark" />
      </div>
      <div>
        <BaseText :text="getEquipmentName()" typeText="custom" size="sm" color="ashes-dark" />
      </div>
      <div v-if="permitUp && season_date_manual_execution_end && team.in_progress">
        <AppButtonIcon iconName="edit" dispatch="Team/openEditModal" :payload="team" />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import AppMaterialIcon from "./AppMaterialIcon.vue";
import { createPopper } from "@popperjs/core";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
    AppMaterialIcon,
  },

  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
      tooltipShow: false,
      season_date_manual_execution_end: true,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    team: {
      type: Object,
    },
    disciplinecoursename: {
      type: String,
    },
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    permitEdit() {
      this.canAcces("team_update").then((resp) => {
        this.permitUp = resp;
      });
    },
    disciplinecourse: function (team) {
      if (team) {
        if (team.phase.id === 2) {
          this.disciplinecoursenames = team.discipline.name + " - " + team.course.name;
        } else {
          this.disciplinecoursenames = team.discipline.name;
        }
      }
    },
    getQtdStudents() {
      if (this.team.students) {
        if (this.team.students.length > 0) {
          return this.team.students.length;
        } else {
          return "0";
        }
      } else {
        if (this.team.qtd_students > 0) {
          return this.team.qtd_students;
        } else {
          return "0";
        }
      }
    },
    getEquipmentName() {
      return this.team.equipment ? this.team.equipment.name : "Equipamento não informado";
    },
    getTeacherName() {
      return this.team.teacher ? this.team.teacher.name : "Instrutor não informado";
    },
    toggleTooltip: function () {
      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "top",
        });
      }
    },
    getSeasonDateManualExecutionEnd() {
      this.season_date_manual_execution_end =
        localStorage.getItem("season_date_manual_execution_end") === "null" ? true : false;
    },
  },

  computed: {
    ...mapState({
      season: (state) => state.Seasons.season,
    }),
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        "p-6": true,
        "cursor-pointer": true,
      };
    },
    iconClass() {
      let css = [];
      let bgColor = "";
      if (this.team.discipline.id == 1) {
        bgColor = "bg-yellow-300";
      }
      if (this.team.discipline.id == 2) {
        bgColor = "bg-burnedYellow-classic";
      }
      if (this.team.discipline.id == 3) {
        bgColor = "bg-green-primary";
      }
      if (this.team.discipline.id == 4) {
        bgColor = "bg-blue-primary";
      }
      if (this.team.discipline.id == 5) {
        bgColor = "bg-rose-primary";
      }

      css.push(bgColor);
      css.push("rounded-full");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");
      css.push("mr-3");
      css.push("items-center");
      css.push("justify-center");
      css.push("min-w-1");

      return css;
    },
  },

  created() {
    this.permitEdit();
    this.getQtdStudents();
    this.getSeasonDateManualExecutionEnd();
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 30% 15% 10% 15% 10% 5%;
}
.min-w-1 {
  min-width: 3rem;
}
.bg-rose-primary {
  background-color: #f04d63;
}
.bg-green-primary {
  background-color: #2e8535;
}
.bg-blue-primary {
  background-color: #65c6db;
}
</style>
