<template>
  <div>
    <BaseModal :headerColor="getStyles('headerColor')" v-if="open">
      <template v-slot:header>
        <div class="grid grid-cols-2">
          <div class="mb-3 justify-self-start">
            <div id="options" class="md:w-full lg:w-full">
              <div id="name-psychosocial-accompaniments">
                <BaseText
                  :text="getStyles('title')"
                  type-text="custom"
                  weight="bold"
                  mode="capitalize"
                  color="white"
                  size="xl"
                />
              </div>
            </div>
          </div>
          <div class="justify-self-end">
            <AppButtonIcon for-close dispatch="PsychosocialAccompaniments/closeViewModal" />
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <div>
            <PsychosocialAccompanimentByInscriptionData />
          </div>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "../../components/BaseModal.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import BaseText from "../../components/BaseText.vue";
import PsychosocialAccompanimentByInscriptionData from "./PsychosocialAccompanimentByInscriptionData.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonIcon,
    PsychosocialAccompanimentByInscriptionData,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      accompaniments_type: (state) => state.PsychosocialAccompaniments.accompaniments_type,
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
    }),
  },
  methods: {
    getStyles(type) {
      let title = "";
      let headerColor = "";
      switch (this.accompaniments_type.name) {
        case "Acolhimento Inicial":
          title = "Informações do Acolhimento Inicial";
          headerColor = "#475569";
          break;
        case "Atendimento Individual":
          title = "Informações do Atendimento Individual";
          headerColor = "#10B981";
          break;
        case "Visita Domiciliar":
          title = "Informações da Visita Domiciliar";
          headerColor = "#06b6d4";
          break;
      }
      if (type === "title") return title;
      if (type === "headerColor") return headerColor;
    },
  },
};
</script>

<style scoped>
.container1 {
  height: 600px;
  width: 100%;
  overflow: visible;
}

.container2 {
  width: 105%;
  height: 100%;
  overflow: auto;
  padding-right: 4%;
  padding-left: 4px;
}
</style>
<style>
.buttons-insc {
  margin-top: -10%;
}
.show {
  display: block;
}
.hide {
  display: none;
}
</style>
