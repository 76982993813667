import { getAxios, baseURL } from "./Api";

export async function getTeachersByCityId(city_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`cidades/${city_id}/instrutores`);
}

export async function getTeachers(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/instrutores`;
  return api.get(url, { params: filters });
}

export async function getTeachersForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `instrutores/para_menus`;
  return api.get(url, { params: filters });
}

export async function getTeacher(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/instrutores/${id}`);
}

export async function getTeacherByUserId(user_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/instrutores`;
  return api.get(url, { params: { user_id: user_id } });
}

export async function createTeacher(teacher) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.post("/instrutores", { teachers: teacher });
}

export async function destroyTeacher(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`instrutores/${id}`);
}

export async function updateTeacher(id, teacher) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`instrutores/${id}`, { teachers: teacher });
}

export async function resetPassword(id, password, password_confirmation) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post(`usuarios/${id}/reset_password`, {
    password: password,
    password_confirmation: password_confirmation,
  });
}
