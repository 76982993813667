<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="meeting.id"
            text="Editar encontro"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar encontros"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Meeting/closeModal" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-rols-5 gap-5">
        <div class="grid grid-rols-2">
          <div>
            <BaseText text="informações gerais" typeText="title-forms" :italic="false" />
          </div>
          <div class="border border-ashes-hover w-full"></div>
        </div>
        <div>
          <BaseInput
            id="nome"
            active
            inputName="nome"
            inputType="text"
            required
            labelText="Nome"
            showLabel
            :value="meeting.name"
            dispatch="Meeting/setName"
            :error-message="getErrorMessage('name')"
          />
        </div>
        <div>
          <BaseTextArea
            active
            inputName="nome"
            required
            labelText="Descrição"
            showLabel
            rows="4"
            :value="meeting.description"
            dispatch="Meeting/setDescription"
            :error-message="getErrorMessage('description')"
          />
        </div>
        <div class="grid grid-cols-2 gap-5">
          <div>
            <BaseInput
              id="dateMeeting"
              active
              inputName="nome"
              inputType="date"
              required
              labelText="Data"
              showLabel
              :value="formatDate(meeting.date)"
              dispatch="Meeting/setDate"
              :error-message="getErrorMessage('date')"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Meeting/closeModal" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="meeting.id"
            text="Editar"
            typeButton="success"
            dispatch="Meeting/updateMeeting"
          />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="Meeting/saveMeeting" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseTextArea from "../components/forms/BaseTextArea.vue";
import Label from "../components/Label.vue";
import BaseText from "../components/BaseText.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    BaseModal,
    BaseInput,
    BaseTextArea,
    Label,
    BaseText,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
    },
  },
  created() {
    this.$store.dispatch("Teachers/loadTeachers");
  },
  computed: {
    ...mapState({
      teachers: (state) => state.Teachers.teachers,
      equipments: (state) => state.Meeting.equipments,
      meeting: (state) => state.Meeting.meeting,
    }),
    ...mapGetters({
      getErrorMessage: "Meeting/getErrorMessage",
    }),
  },
  methods: {
    ...mapActions({
      setTeacher: "Meeting/setTeacher",
      setShift: "Meeting/setShift",
      setEquipment: "Meeting/setEquipment",
    }),
    formatDate(date) {
      let splitAr = date?.split("/");
      return splitAr?.reverse().join("-");
    },
  },
};
</script>
