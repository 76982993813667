import {
  getArticulators,
  getArticulatorById,
  createArticulator,
  updateArticulator,
  destroyArticulator,
} from "../../service/ArticulatorsApi";
import {
  createArticulatorEquipment,
  destroyArticulatorEquipment,
} from "../../service/ArticulatorEquipmentsApi";
import { getAreasByCity } from "../../service/AreaApi";
import { getEquipmentByAreaId } from "../../service/EquipmentApi";
import { createUser, createUserArea, updateUser, destroyUserArea } from "../../service/UserApi";
import Vue from "vue";

const state = {
  articulator: {
    id: null,
    name: null,
    reference_name: null,
    cpf: null,
    cellphone_number: null,
    email: null,
    city_id: localStorage.city_id,
    user_id: null,
    areas_attributes: [],
    equipments_attributes: [],
    password: null,
    password_confirmation: null,
  },
  articulators: [],
  filterArticulators: [],
  areas: [],
  equipments: [],
  user_areas_to_edit: [],
  articulator_equipments_to_edit: [],

  articulatorId: null,

  modalToggleAddArticulator: false,
  modalToggleDeleteArticulator: false,
  params: {
    name: null,
    cpf: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadArticulators: (state, articulators) => {
    state.articulators = articulators;
  },
  loadAreas: (state, areas) => {
    state.areas = areas;
  },
  loadEquipments: (state, equipments) => {
    state.equipments = equipments;
  },

  // MUTATIONS - FIELDS TO API
  setIdMutation: (state, id) => {
    state.articulator.id = id;
  },
  setNameMutation: (state, name) => {
    state.articulator.name = name;
  },
  setReferenceNameMutation: (state, reference_name) => {
    state.articulator.reference_name = reference_name;
  },
  setCpfMutation: (state, cpf) => {
    state.articulator.cpf = cpf;
  },
  setCellphoneNumberMutation: (state, cellphone_number) => {
    state.articulator.cellphone_number = cellphone_number;
  },
  setEmailMutation: (state, email) => {
    state.articulator.email = email;
  },
  setAreasAttributesMutation: (state, areas_attributes) => {
    state.articulator.areas_attributes = areas_attributes;
  },
  setUserAreasToEditMutation: (state, user_areas_to_edit) => {
    state.user_areas_to_edit = user_areas_to_edit;
  },
  setEquipmentsAttributesMutation: (state, equipments_attributes) => {
    state.articulator.equipments_attributes = equipments_attributes;
  },
  setArticulatorEquipmentsToEditMutation: (state, articulator_equipments_to_edit) => {
    state.articulator_equipments_to_edit = articulator_equipments_to_edit;
  },
  setPasswordMutation: (state, password) => {
    state.articulator.password = password;
  },
  setPasswordConfirmationMutation: (state, password_confirmation) => {
    state.articulator.password_confirmation = password_confirmation;
  },
  setUserIdMutation: (state, user_id) => {
    state.articulator.user_id = user_id;
  },

  // --> MUTATIONS - DELETION <--
  articulatorForDeletion: (state, payload) => {
    if (payload) {
      state.articulatorId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },
  setFilterCpfMutation: (state, cpf) => {
    state.params.cpf = cpf;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddArticulator: (state) => {
    state.modalToggleAddArticulator = !state.modalToggleAddArticulator;
  },
  toggleModalDeleteArticulator: (state) => {
    state.modalToggleDeleteArticulator = !state.modalToggleDeleteArticulator;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    state.articulator.id = null;
    state.articulator.name = null;
    state.articulator.reference_name = null;
    state.articulator.cpf = null;
    state.articulator.cellphone_number = null;
    state.articulator.email = null;
    state.articulator.city_id = localStorage.city_id;
    state.articulator.user_id = null;
    state.articulator.areas_attributes = [];
    state.articulator.equipments_attributes = [];
    state.user_areas_to_edit = [];
    state.articulator_equipments_to_edit = [];
    state.articulator.password = null;
    state.articulator.password_confirmation = null;
  },
};

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadArticulators: ({ commit }) => {
    const params = {
      name: state.params.name,
      cpf: state.params.cpf,
      city_id: localStorage.city_id,
    };
    getArticulators(params)
      .then((response) => {
        commit("loadArticulators", response.data.articulators);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadAreas: ({ commit }) => {
    const city_id = localStorage.city_id;
    getAreasByCity(city_id)
      .then((response) => {
        commit("loadAreas", response.data.areas);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadEquipments: ({ commit }) => {
    const ids = [];
    state.articulator.areas_attributes.forEach((item) => {
      ids.push(item.id);
    });
    const params = {
      city_id: localStorage.city_id,
      area_id: ids,
    };
    getEquipmentByAreaId(params)
      .then((response) => {
        commit("loadEquipments", response.data.equipment);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getArticulatorById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.articulator.id);
        commit("setNameMutation", response.data.articulator.name);
        commit("setReferenceNameMutation", response.data.articulator.reference_name);
        commit("setCpfMutation", response.data.articulator.cpf);
        commit("setEmailMutation", response.data.articulator.email);
        commit("setCellphoneNumberMutation", response.data.articulator.cellphone_number);
        commit("setUserIdMutation", response.data.articulator.user.id);
        if (response.data.articulator.articulator_equipments.length > 0) {
          commit("setEquipmentsAttributesMutation", response.data.articulator.articulator_equipments);
          commit("setArticulatorEquipmentsToEditMutation", response.data.articulator.articulator_equipments);
        }
        if (response.data.articulator.areas.length > 0) {
          commit("setAreasAttributesMutation", response.data.articulator.areas);
          commit("setUserAreasToEditMutation", response.data.articulator.areas);
        }
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setReferenceName: ({ commit }, payload) => {
    commit("setReferenceNameMutation", payload.value);
  },
  setCpf: ({ commit }, payload) => {
    commit("setCpfMutation", payload.value);
  },
  setCellphoneNumber: ({ commit }, payload) => {
    commit("setCellphoneNumberMutation", payload.value);
  },
  setEmail: ({ commit }, payload) => {
    commit("setEmailMutation", payload.value);
  },
  setAreasAttributes: ({ commit, dispatch }, payload) => {
    commit("setAreasAttributesMutation", payload);
    dispatch("loadEquipments");
  },
  setEquipmentsAttributes: ({ commit }, payload) => {
    commit("setEquipmentsAttributesMutation", payload);
  },
  setPassword: ({ commit }, payload) => {
    commit("setPasswordMutation", payload.value);
  },
  setPasswordConfirmation: ({ commit }, payload) => {
    commit("setPasswordConfirmationMutation", payload.value);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddArticulator: ({ commit }) => {
    commit("toggleModalAddArticulator");
  },
  closeModalAddArticulator: ({ commit }) => {
    commit("toggleModalAddArticulator");
    commit("clearFields");
  },
  openModalEdit: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddArticulator");
  },
  closeModalEdit: ({ commit }) => {
    commit("toggleModalAddArticulator");
    commit("clearFields");
  },
  toggleModalDeleteArticulator: ({ commit }, payload) => {
    commit("toggleModalDeleteArticulator");
    if (state.modalToggleDeleteArticulator && payload) {
      commit("articulatorForDeletion", payload);
    } else {
      commit("articulatorForDeletion", null);
    }
  },

  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  setFilterCpf: ({ commit }, payload) => {
    commit("setFilterCpfMutation", payload.value);
  },

  //ACTIONS - CRUD
  saveArticulator: ({ commit, dispatch }) => {
    const objUser = {
      name: state.articulator.name,
      cpf: state.articulator.cpf,
      active: true,
      phone_number: state.articulator.cellphone_number,
      city_id: localStorage.city_id,
      email: state.articulator.email,
      confirmed_at: new Date(),
      password: state.articulator.password,
      ability_profile_id: 8,
    };
    createUser(objUser)
      .then((response) => {
        commit("setUserIdMutation", response.data.data.id);
        if (state.articulator.areas_attributes.length > 0) {
          let user_areas = {
            id: null,
            user_id: null,
            area_id: null,
          };
          state.articulator.areas_attributes.forEach((item) => {
            user_areas.user_id = response.data.data.id;
            user_areas.area_id = item.id;

            createUserArea(user_areas)
              .then(() => {})
              .catch((e) => {
                console.log(e.response);
              });
          });
        }
        createArticulator(state.articulator)
          .then((response_articulator) => {
            if (state.articulator.equipments_attributes.length > 0) {
              let articulator_equipments = {
                id: null,
                articulator_id: null,
                equipment_id: null,
              };
              state.articulator.equipments_attributes.forEach((item) => {
                articulator_equipments.articulator_id = response_articulator.data.data.id;
                articulator_equipments.equipment_id = item.id;

                createArticulatorEquipment(articulator_equipments)
                  .then(() => {})
                  .catch((e) => {
                    console.log(e.response);
                  });
              });
            }

            Vue.$toast.success("Articulador(a) cadastrado com sucesso!");
            dispatch("loadArticulators");
            dispatch("closeModalAddArticulator");
          })
          .catch((e) => {
            Vue.$toast.error("Não foi possivel cadastrar o articulador! ");
            console.log(e.response);
          });
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar o usuário(a)!" + e.response.data.errors.full_messages);
      });
  },
  updateArticulator: ({ dispatch }) => {
    const objUser = {
      id: state.articulator.user_id,
      name: state.articulator.name,
      cpf: state.articulator.cpf,
      active: true,
      phone_number: state.articulator.cellphone_number,
      city_id: localStorage.city_id,
      email: state.articulator.email,
      password: state.articulator.password,
      ability_profile_id: 8,
    };
    updateUser(objUser.id, objUser)
      .then((response) => {
        if (state.articulator.areas_attributes.length > 0) {
          let user_areas = {
            id: null,
            user_id: null,
            area_id: null,
          };
          state.articulator.areas_attributes.forEach((item) => {
            if (!("id_user_area" in item)) {
              user_areas.id = item.id;
              user_areas.user_id = response.data.id;
              user_areas.area_id = item.id;
              createUserArea(user_areas)
                .then(() => {})
                .catch((e) => {
                  console.log(e.response);
                });
            }
          });
        }
        const objDestroy = state.user_areas_to_edit.filter(
          (edit) =>
            !state.articulator.areas_attributes.find((item) => item.id_user_area === edit.id_user_area)
        );
        if (objDestroy.length > 0) {
          objDestroy.forEach((item) => {
            destroyUserArea(item.id_user_area)
              .then(() => {})
              .catch((e) => {
                console.log(e);
              })
              .catch((e) => {
                Vue.$toast.error(e.response.data.error + "!");
              });
          });
        }
        updateArticulator(state.articulator.id, state.articulator)
          .then((response_articulator) => {
            if (state.articulator.equipments_attributes.length > 0) {
              let articulator_equipments = {
                id: null,
                articulator_id: null,
                equipment_id: null,
              };
              state.articulator.equipments_attributes.forEach((item) => {
                if (!("id_articulator_equipment" in item)) {
                  articulator_equipments.id = item.id;
                  articulator_equipments.articulator_id = response_articulator.data.data.id;
                  articulator_equipments.equipment_id = item.id;
                  createArticulatorEquipment(articulator_equipments)
                    .then(() => {})
                    .catch((e) => {
                      console.log(e.response);
                    });
                }
              });
            }
            const objDestroyArticulatorEquipament = state.articulator_equipments_to_edit.filter(
              (edit) =>
                !state.articulator.equipments_attributes.find(
                  (item) => item.id_articulator_equipment === edit.id_articulator_equipment
                )
            );
            if (objDestroyArticulatorEquipament.length > 0) {
              objDestroyArticulatorEquipament.forEach((item) => {
                destroyArticulatorEquipment(item.id_articulator_equipment)
                  .then(() => {})
                  .catch((e) => {
                    console.log(e);
                  })
                  .catch((e) => {
                    Vue.$toast.error(e.response.data.error + "!");
                  });
              });
            }
            Vue.$toast.success("Informações do Articulador(a) atualizadas com sucesso!");
            dispatch("loadArticulators");
            dispatch("closeModalEdit");
          })
          .catch((e) => {
            Vue.$toast.error("Não foi possivel atualizar as informações do articulador(a)! ");
            console.log(e.response);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  },
  deleteArticulator: ({ commit, dispatch }) => {
    destroyArticulator(state.articulatorId)
      .then(() => {
        Vue.$toast.success("Articulador(a) deletado com sucesso!");
        commit("toggleModalDeleteArticulator");
        dispatch("loadArticulators");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
