<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="articulator.id"
            text="Editar Articulador(a)"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar Articulador(a)"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Articulators/closeModalAddArticulator" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2">
          <BaseInput
            id="name"
            input-name="name"
            input-placeholder="nome"
            input-type="text"
            show-label
            label-text="nome"
            required
            :value="articulator.name"
            dispatch="Articulators/setName"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            id="reference_name"
            input-name="reference_name"
            input-placeholder="nome de referência"
            input-type="text"
            show-label
            label-text="nome de referência"
            :value="articulator.reference_name"
            dispatch="Articulators/setReferenceName"
          />
        </div>
        <div>
          <BaseInput
            id="cpf"
            input-name="cpf"
            input-placeholder="000.000.000-00"
            input-type="text"
            show-label
            label-text="cpf"
            required
            mask="###.###.###-##"
            :value="articulator.cpf"
            dispatch="Articulators/setCpf"
          />
        </div>
        <div>
          <BaseInput
            id="cellphone_number"
            input-name="cellphone_number"
            input-placeholder="(00) 00000-0000"
            input-type="text"
            show-label
            label-text="telefone"
            required
            mask="(##) #####.####"
            :value="articulator.cellphone_number"
            dispatch="Articulators/setCellphoneNumber"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            id="email"
            input-name="email"
            input-placeholder="exemplo@email.com"
            input-type="text"
            show-label
            label-text="E-mail"
            required
            :value="articulator.email"
            dispatch="Articulators/setEmail"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            id="areas"
            select-placeholder="Selecione as opções"
            label-text="Vincular Áreas"
            :close-on-select="false"
            show-label
            :value="articulator.areas_attributes"
            :options="areas"
            :dispatch="setAreasAttributes"
            required
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            id="equipamentos"
            select-placeholder="Selecione as opções"
            label-text="Vincular Equipamentos"
            :close-on-select="false"
            show-label
            :value="articulator.equipments_attributes"
            :options="equipments"
            :dispatch="setEquipmentsAttributes"
            required
          />
        </div>
        <div v-if="!articulator.id">
          <BaseInput
            id="password"
            input-name="password"
            input-type="password"
            show-label
            label-text="Senha"
            required
            :value="articulator.password"
            dispatch="Articulators/setPassword"
          />
        </div>
        <div v-if="!articulator.id">
          <BaseInput
            id="password_confirmation"
            input-name="password"
            input-type="password"
            show-label
            label-text="Confirmação de Senha"
            required
            :value="articulator.password_confirmation"
            dispatch="Articulators/setPasswordConfirmation"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="Articulators/closeModalAddArticulator"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="articulator.id"
            text="Salvar"
            typeButton="success"
            dispatch="Articulators/updateArticulator"
          />
          <AppButtonEmpty v-else text="criar" typeButton="success" dispatch="Articulators/saveArticulator" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseModal,
    Multiselect,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.$store.dispatch("Articulators/loadAreas");
  },

  computed: {
    ...mapState({
      articulator: (state) => state.Articulators.articulator,
      areas: (state) => state.Articulators.areas,
      equipments: (state) => state.Articulators.equipments,
    }),
  },
  methods: {
    ...mapActions({
      setAreasAttributes: "Articulators/setAreasAttributes",
      setEquipmentsAttributes: "Articulators/setEquipmentsAttributes",
    }),
  },
};
</script>
