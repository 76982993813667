<template>
  <BaseModal width="w-full" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start" v-if="!isUpdate">
          <Label
            text="Cadastrar Matriz de Problema-Impacto"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-carmesim"
          />
        </div>
        <div class="justify-self-start" v-else>
          <Label
            text="Atualizar Matriz de Problema-Impacto"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-carmesim"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon
            dispatch="MatrixRiskStratifications/descartEditModalMatrixRiskStratification"
            forClose
          />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Guia de Avaliação de Problema-Impacto"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div
        :key="index"
        v-for="(matrix_problem, index) in matrix_problems"
        class="table w-full border-b-4 pb-2"
      >
        <div class="table-header-group">
          <div class="table-row">
            <div class="table-cell">
              <BaseText
                text="Problema Identificado?"
                typeText="custom"
                weight="bold"
                mode="capitalize"
                :italic="false"
                size="xs"
                color="ashes-dark"
              />
            </div>
            <div class="table-cell">
              <BaseText
                text="Situação"
                typeText="custom"
                weight="bold"
                mode="capitalize"
                :italic="false"
                size="xs"
                color="ashes-dark"
              />
            </div>
            <div class="table-cell">
              <BaseText
                text="Impacto"
                typeText="custom"
                weight="bold"
                mode="capitalize"
                :italic="false"
                size="xs"
                color="ashes-dark"
              />
            </div>
          </div>
        </div>
        <div class="table-row-group">
          <div class="table-row">
            <div class="table-cell text-left w-0 pb-4">
              <BaseText
                class="flex flex-wrap w-64"
                :text="matrix_problem.name"
                typeText="custom"
                weight="semibold"
                :italic="false"
                size="xs"
                color="ashes-dark"
              />
            </div>
            <div class="table-cell">
              <div
                :key="index"
                v-for="(situations, index) in matrix_problem.matrix_situations"
                class="table-row-group"
              >
                <div class="table-row">
                  <div class="table-cell pb-2">
                    <div class="inp-left">
                      <input
                        :id="checkboxId(situations.situation, index)"
                        type="checkbox"
                        name="students"
                        @click="onCheck($event, situations, index)"
                        :value="situations.id"
                        :checked="situations.checked"
                      />
                    </div>
                    <div class="text-r">
                      <BaseText
                        class="flex flex-wrap w-68"
                        :text="situations.situation"
                        typeText="custom"
                        weight="semibold"
                        :italic="false"
                        size="xs"
                        color="ashes-dark"
                        :target="checkboxId(situations.situation, index)"
                      />
                    </div>
                  </div>
                  <div class="table-cell pb-5" :class="chooseRiskSit(situations.situation_risk)">
                    <BaseText
                      class="flex flex-wrap"
                      :text="situations.situation_risk"
                      typeText="custom"
                      weight="semibold"
                      :italic="false"
                      size="xs"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="table-cell text-left">
              <div
                :key="index"
                v-for="(impacts, index) in matrix_problem.matrix_situations"
                class="table-row-group"
              >
                <div class="table-row">
                  <div class="table-cell text-left pb-2">
                    <BaseText
                      class="flex flex-wrap w-68"
                      :text="impacts.impact"
                      typeText="custom"
                      weight="semibold"
                      :italic="false"
                      size="xs"
                      color="ashes-dark"
                    />
                  </div>
                  <div class="table-cell text-left pb-5" :class="chooseRiskImp(impacts.impact_level)">
                    <BaseText
                      class="flex flex-wrap"
                      :text="impacts.impact_level"
                      typeText="custom"
                      weight="semibold"
                      :italic="false"
                      size="xs"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="MatrixRiskStratifications/descartEditModalMatrixRiskStratification"
          />
        </div>
        <div class="justify-self-end" v-if="!isUpdate">
          <AppButtonEmpty text="Salvar" typeButton="success" :dispatch="getDispatchType" />
        </div>
        <div class="justify-self-end" v-else>
          <AppButtonEmpty text="Atualizar" typeButton="success" dispatch="MatrixRiskStratifications/update" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseText from "../components/BaseText.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseModal,
    BaseText,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("MatrixRiskStratifications/loadMatrixSitutations");
    this.$store.dispatch("MatrixRiskStratifications/loadMatrixProblems");
  },
  computed: {
    ...mapState({
      matrix_risk_stratification: (state) => state.MatrixRiskStratifications.matrix_risk_stratification,
      matrix_situations: (state) => state.MatrixRiskStratifications.matrix_situations,
      matrix_problems: (state) => state.MatrixRiskStratifications.matrix_problems,
      isUpdate: (state) => state.MatrixRiskStratifications.isUpdate,
      modalToggleFirstPsychosocialAccompaniment: (state) =>
        state.PsychosocialAccompaniments.modalToggleFirstPsychosocialAccompaniment,
      modalTogglePsychosocialIndividualMonitoring: (state) =>
        state.PsychosocialAccompaniments.modalTogglePsychosocialIndividualMonitoring,
      modalTogglePsychosocialHomeVisit: (state) =>
        state.PsychosocialAccompaniments.modalTogglePsychosocialHomeVisit,
    }),
    getDispatchType() {
      if (
        !this.modalToggleFirstPsychosocialAccompaniment &&
        !this.modalTogglePsychosocialIndividualMonitoring &&
        !this.modalTogglePsychosocialHomeVisit
      ) {
        return "MatrixRiskStratifications/save";
      } else {
        return "MatrixRiskStratifications/closeEditModalMatrixRiskStratification";
      }
    },
  },

  methods: {
    ...mapActions({
      checkboxId(name, index) {
        return `${name}-${index}`;
      },
      onCheck(event, item, data, index) {
        if (item.target.checked) {
          let requested = true;
          let payload = { data: data, requested: requested, index: index };
          this.$store.dispatch("MatrixRiskStratifications/setMatrixSituationsArray", payload);
        } else {
          let requested = false;
          let payload = { data: data, requested: requested, index: index };
          this.$store.dispatch("MatrixRiskStratifications/setMatrixSituationsArray", payload);
        }
      },
    }),
    chooseRiskImp(risk) {
      if (risk == "Alto") {
        return "red";
      }
      if (risk == "Médio") {
        return "yelow";
      }
      if (risk == "Baixo") {
        return "green";
      }
    },
    chooseRiskSit(risk) {
      if (risk == "Gravíssima") {
        return "red";
      }
      if (risk == "Grave") {
        return "yelow";
      }
      if (risk == "Moderada") {
        return "green";
      }
    },
  },
};
</script>

<style>
.inp-left {
  margin-top: -3px;
  width: 10px !important;
  position: absolute;
  padding-right: 10px;
}

.text-r {
  margin-left: 20px !important;
  position: relative;
}

.red {
  color: rgb(250, 16, 16) !important;
  padding-left: 5%;
}

.green {
  color: rgb(8, 92, 8) !important;
  padding-left: 5%;
}

.yelow {
  color: rgb(206, 206, 7) !important;
  padding-left: 5%;
}
</style>
