<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-4">
        <div class="justify-self-start col-span-3">
          <Label
            :text="getTitleName"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="PsychosocialAccompaniments/closeModalAddAttendance" forClose />
        </div>
        <div class="justify-self-start col-span-2">
          <BaseText
            :text="getUserName"
            typeText="custom"
            weight="semibold"
            color="herbal"
            size="sm"
            class="mr-1"
          />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2 gap-4">
        <div v-if="hasRealizedFirstPsychosocialAccompaniment">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Tipo de Atendimento"
            label-text="Tipo"
            :multiple-option="false"
            :value="accompaniments_type"
            :dispatch="setPsychosocialAccompanimentsType"
            :options="acommpaniment_options_if_has_realized_first_psychosocial_accompaniment"
            required
          />
        </div>
        <div v-else>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Tipo de Atendimento"
            label-text="Tipo"
            :multiple-option="false"
            :value="accompaniments_type"
            :options="getPsychosocialAccompanimentsTypes"
            required
            :dispatch="setPsychosocialAccompanimentsType"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="birthdate"
            input-type="date"
            :value="date_accompaniment"
            show-label
            :label-text="accompaniment_type === 3 ? 'Data da Justificativa' : 'Data do Encontro'"
            required
            dispatch="PsychosocialAccompaniments/setDateAccompaniment"
            :readonly="dateIsOnlyRead"
          />
        </div>
        <div v-if="verifyDate()" class="text-carmesim font-medium text-sm decoration-yellow-600 col-span-2">
          A data informada é maior que a data atual, portanto o atendimento será classificado com o status de
          Agendado.
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="PsychosocialAccompaniments/closeModalAddAttendance"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="verifyDate()"
            text="Agendar"
            typeButton="success"
            dispatch="PsychosocialAccompaniments/save"
          />
          <AppButtonEmpty
            v-else
            text="Avançar"
            typeButton="success"
            dispatch="PsychosocialAccompaniments/chooseModalToOpen"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import Label from "../../components/Label.vue";
import Multiselect from "../../components/forms/Multiselect.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseModal,
    BaseInput,
    BaseText,
    Label,
    Multiselect,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateIsOnlyRead: false,
      status: {
        id: 0,
        name: "Acolhimento Inicial",
      },
      acommpaniment_options_if_has_realized_first_psychosocial_accompaniment: [
        {
          id: 0,
          name: "Acolhimento Inicial",
        },
        {
          id: 3,
          name: "Justificar Não Acolhimento",
        },
      ],
    };
  },
  async created() {
    const inscription_id = sessionStorage.inscription_id;
    await this.$store.dispatch(
      "PsychosocialAccompaniments/loadInscriptionByIdForPsychosocialAccompaniment",
      inscription_id
    );

    const userData = JSON.parse(localStorage.userData);
    await this.$store.dispatch("PsychosocialAccompaniments/setUser", userData);

    await this.$store.dispatch("PsychosocialAccompaniments/loadPsychosocialAccompanimentsTypes");
    this.verifyDate();
  },
  computed: {
    ...mapState({
      inscription: (state) => state.PsychosocialAccompaniments.inscription,
      psychosocial_accompaniments_types: (state) =>
        state.PsychosocialAccompaniments.psychosocial_accompaniments_types,
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
      accompaniment_type: (state) =>
        state.PsychosocialAccompaniments.psychosocial_accompaniment.accompaniment_type,
      accompaniments_type: (state) => state.PsychosocialAccompaniments.accompaniments_type,
      date_accompaniment: (state) =>
        state.PsychosocialAccompaniments.psychosocial_accompaniment.date_accompaniment,
      user: (state) => state.PsychosocialAccompaniments.user,
      modalToggleAddAttendance: (state) => state.PsychosocialAccompaniments.modalToggleAddAttendance,
    }),
    getPsychosocialAccompanimentsTypes() {
      let accompaniment_types = [];
      this.psychosocial_accompaniments_types.forEach((item) => {
        if (item.id !== 0 && item.id !== 3) {
          accompaniment_types.push(item);
        }
      });
      return accompaniment_types;
    },
    getTitleName() {
      if (this.inscription.psychosocial_accompaniments_total < 1) {
        return "Adicionar Acolhimento Inicial ou Justificar Não Acolhimento";
      } else {
        return "Adicionar Atendimento";
      }
    },
    getUserName() {
      if (this.user.userName) {
        return `Usário(a) responsável pelo cadastro: ${this.user.userName}`;
      } else {
        return `Usário(a) responsável pelo cadastro: ${this.user.name}`;
      }
    },
    hasRealizedFirstPsychosocialAccompaniment() {
      if (this.inscription.psychosocial_accompaniments_total < 1) {
        return true;
      } else {
        return !this.inscription.has_first_service;
      }
    },
  },
  methods: {
    ...mapActions({
      setDateAccompaniment: "PsychosocialAccompaniments/setDateAccompaniment",
      setPsychosocialAccompanimentsType: "PsychosocialAccompaniments/setPsychosocialAccompanimentsType",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    verifyMeetingHappened() {
      return this.psychosocial_accompaniment.meeting_happened ? "Sim" : "Não";
    },
    verifyDate() {
      let data = Date.parse(this.date_accompaniment);
      const today = Date.now();
      return data > today;
    },
  },
  watch: {
    date_accompaniment: function () {
      this.verifyDate();
    },
    accompaniment_type: function () {
      if (this.accompaniment_type === 3) {
        let yourDate = new Date();

        const offset = yourDate.getTimezoneOffset();
        yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
        this.$store.commit(
          "PsychosocialAccompaniments/setDateAccompanimentMutation",
          yourDate.toISOString().split("T")[0]
        );
        this.dateIsOnlyRead = true;
      } else {
        if (this.modalToggleAddAttendance) {
          this.dateIsOnlyRead = false;
          this.$store.commit("PsychosocialAccompaniments/setDateAccompanimentMutation", null);
        }
      }
    },
  },
};
</script>
