import {
  getInscriptionsForExportProductiveInsertion,
  getInscriptionsForProductiveInsertion,
} from "@/service/ProfileSearchsApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import { getEquipmentByAreaId } from "@/service/EquipmentApi";
import { getNeighborhoodsForMenus } from "@/service/NeighborhoodsApi";
import { getCourses } from "@/service/CourseApi";
import { getQualificationStatus } from "@/service/StudentApi";
import { util_function } from "@/util_functions";
import { getInscriptionForGeneralMenus } from "@/service/InscriptionApi";
Vue.component("downloadExcel", JsonExcel);

const state = {
  incriptions_status: [
    {
      id: 0,
      name: "Desligado(a)",
    },
    {
      id: 3,
      name: "Ativo(a)",
    },
    {
      id: 5,
      name: "Concludente",
    },
  ],
  forwardead_options: [
    {
      id: 1,
      name: "Sim",
    },
    {
      id: 2,
      name: "Não",
    },
  ],
  has_resume_options: [
    {
      id: 1,
      name: "Sim",
    },
    {
      id: 2,
      name: "Não",
    },
  ],
  schooling_options: [
    {
      id: 1,
      name: "Fundamental Incompleto",
    },
    {
      id: 2,
      name: "Fundamental Completo",
    },
    {
      id: 3,
      name: "Médio Completo",
    },
    {
      id: 4,
      name: "Médio Incompleto",
    },
  ],
  inscriptions: [],
  neighborhoods: [],
  courses: [],
  equipments_profile_searchs: [],
  user_areas: [],
  user_equipments: [],
  qualification_statuses: [],
  profile_searchs_spreadsheet: [],
  inscriptionsForGeneralMenus: [],

  errorMessages: [],
  city: null,
  state: null,
  inscription: null,
  neighborhood: null,
  course: null,
  season: null,
  user_areas_filtered: null,
  user_equipments_filtered: null,
  qualification_status: null,

  modalToggleAddProfileSearch: false,
  modalToggleDeleteProfileSearch: false,
  modalToggleViewProfileSearch: false,
  modalToggleExportProfileSearchsSpreadsheet: false,

  hasSpreadsheetProfileSearchsResponse: true,
  json_data_profile_search: null,

  profile_searchsTotal: 0,
  profile_searchsTotalPerPage: 10,
  profile_searchsOffset: 0,

  params: {
    inscription_status: null,
    qualification_status: null,
    area: null,
    city: null,
    state: null,
    equipment: null,
    neighborhood: null,
    course: null,
    inscription: null,
    minimum_age: null,
    maximum_age: null,
    forwarded: null,
    schooling: null,
    has_resume: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadInscriptions: (state, inscriptions) => {
    state.inscriptions = inscriptions;
  },
  loadNeighborhoods: (state, neighborhoods) => {
    state.neighborhoods = neighborhoods;
  },
  loadCourses: (state, courses) => {
    state.courses = courses;
  },
  loadQualificationStatuses: (state, qualification_statuses) => {
    state.qualification_statuses = qualification_statuses;
  },
  loadInscriptionsForGeneralMenus: (state, inscriptionsForGeneralMenus) => {
    state.inscriptionsForGeneralMenus = inscriptionsForGeneralMenus;
  },
  setUserAreasMutation: (state, user_areas) => {
    state.user_areas = user_areas;
  },
  setUserEquipmentsMutation: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },
  setEquipmentsMutation: (state, equipments_profile_searchs) => {
    state.equipments_profile_searchs = equipments_profile_searchs;
  },

  // MUTATIONS - FIELDS TO/FROM API
  setIdMutation: (state, id) => {
    state.profile_search.id = id;
  },
  setStateMutation: (state, value) => {
    state.state = value;
  },
  setCityMutation: (state, city) => {
    state.city = city;
  },
  setInscriptionMutation: (state, inscription) => {
    state.inscription = inscription;
  },
  setSeasonMutation: (state, season) => {
    state.season = season;
  },

  // --> MUTATIONS - FILTERLIST
  setFilterInscriptionStatusMutation: (state, inscription_status) => {
    state.params.inscription_status = inscription_status;
  },
  setFilterQualificationStatusMutation: (state, qualification_status) => {
    state.params.qualification_status = qualification_status;
  },
  setFilterAreaMutation: (state, area) => {
    state.params.area = area;
  },
  setFilterCityMutation: (state, city) => {
    state.params.city = city;
  },
  setFilterStateMutation: (state, value) => {
    state.params.state = value;
  },
  setFilterNeighborhoodMutation: (state, neighborhood) => {
    state.params.neighborhood = neighborhood;
  },
  setFilterSchoolingMutation: (state, schooling) => {
    state.params.schooling = schooling;
  },
  setFilterCourseMutation: (state, course) => {
    state.params.course = course;
  },
  setFilterForwardedMutation: (state, forwarded) => {
    state.params.forwarded = forwarded;
  },
  setFilterHasResumeMutation: (state, has_resume) => {
    state.params.has_resume = has_resume;
  },
  setFilterInscriptionMutation: (state, inscription) => {
    state.params.inscription = inscription;
  },
  setUserAreasFilteredMutation: (state, user_areas_filtered) => {
    state.user_areas_filtered = user_areas_filtered;
  },
  setFilterEquipmentMutation: (state, user_equipments_filtered) => {
    state.user_equipments_filtered = user_equipments_filtered;
  },
  setFilterMinimumAgeMutation: (state, minimum_age) => {
    state.params.minimum_age = minimum_age;
  },
  setFilterMaximumAgeMutation: (state, maximum_age) => {
    state.params.maximum_age = maximum_age;
  },

  toggleModalToggleExportProfileSearchsSpreadsheet: (state) => {
    state.modalToggleExportProfileSearchsSpreadsheet = !state.modalToggleExportProfileSearchsSpreadsheet;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetProfileSearchsResponseMutation: (state, hasSpreadsheetProfileSearchsResponse) => {
    state.hasSpreadsheetProfileSearchsResponse = hasSpreadsheetProfileSearchsResponse;
  },

  setProfileSearchsPageToExportMutation: (state, forwarding_productive_insertion) => {
    state.json_data_profile_search = null;

    const arrayData = [];
    forwarding_productive_insertion.forEach((element, index, array) => {
      let equipment = array[index].equipment ? array[index].equipment.name : "-";
      let area =
        array[index].neighborhood && array[index].neighborhood.area
          ? array[index].neighborhood.area.name
          : "-";
      let participant_name = array[index].student.name ? array[index].student.name : null;
      let participant_status = util_function.processInscriptionStatuses(array[index].status);
      let qualification_status = array[index].student.qualification_status
        ? array[index].student.qualification_status.name
        : null;
      let neighborhood = array[index].neighborhood ? array[index].neighborhood.name : "-";
      let age = array[index].student.age ? array[index].student.age : "-";
      let completed_series = array[index].student.completed_series
        ? array[index].student.completed_series.name
        : "-";
      let course =
        array[index].student.last_team && array[index].student.last_team.course
          ? array[index].student.last_team.course.name
          : "-";
      let forwardings_quantity =
        array[index].forwardings_quantity > 0 ? array[index].forwardings_quantity : "Não";
      let resume_document = array[index].resume_document_id ? "Sim" : "Não";

      const arrayItem = [];
      arrayItem["participant_status"] = participant_status;
      arrayItem["qualification_status"] = qualification_status;
      arrayItem["area"] = area;
      arrayItem["equipment"] = equipment;
      arrayItem["neighborhood"] = neighborhood;
      arrayItem["participant_name"] = participant_name;
      arrayItem["age"] = age;
      arrayItem["completed_series"] = completed_series;
      arrayItem["course"] = course;
      arrayItem["forwardings_quantity"] = forwardings_quantity;
      arrayItem["resume_document"] = resume_document;
      arrayData.push(arrayItem);
    });
    state.json_data_profile_search = arrayData;
    state.hasSpreadsheetProfileSearchsResponse = true;
    state.modalToggleExportProfileSearchsSpreadsheet = true;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setProfileSearchsTotalPerPage: (state, profile_searchsTotalPerPage) => {
    state.profile_searchsTotalPerPage = profile_searchsTotalPerPage;
  },
  setProfileSearchsOffset: (state, profile_searchsOffset) => {
    state.profile_searchsOffset = profile_searchsOffset;
  },
  setProfileSearchsTotal: (state, profile_searchsTotal) => {
    state.profile_searchsTotal = profile_searchsTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    state.inscription = null;
  },
  clearQuery: (state) => {
    state.params.inscription_status = null;
    state.params.qualification_status = null;
    state.params.area = null;
    state.params.equipment = null;
    state.params.inscription = null;
    state.params.user = null;
    state.params.city = null;
    state.params.state = null;
    state.params.minimum_age = null;
    state.params.maximum_age = null;
    state.params.neighborhood = null;
    state.params.course = null;
    state.params.forwarded = null;
    state.params.schooling = null;
    state.params.has_resume = null;
    state.user_areas_filtered = null;
    state.user_equipments_filtered = null;
    state.inscriptions = [];
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setProfileSearchsSpreadsheetMutation: (state, profile_searchs_spreadsheet) => {
    state.profile_searchs_spreadsheet = profile_searchs_spreadsheet;
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

// --> HELPER METHODS <--
function getUserAndEquipments() {
  let user_areas_ids = [];
  let user_equipments_ids = [];
  if (state.user_areas) {
    state.user_areas.forEach((item) => {
      user_areas_ids.push(item.id);
    });
  }
  if (state.user_equipments) {
    state.user_equipments.forEach((item) => {
      user_equipments_ids.push(item.id);
    });
  }

  if (state.user_areas_filtered) {
    user_areas_ids = state.user_areas_filtered.id;
  }
  if (state.user_equipments_filtered) {
    user_equipments_ids = state.user_equipments_filtered.id;
  }
  return { user_areas_ids, user_equipments_ids };
}

function getFilterParams(user_areas_ids, user_equipments_ids, rootGetters) {
  return {
    participant_name: state.params.inscription ? state.params.inscription.name : null,
    inscription_status: state.params.inscription_status ? state.params.inscription_status.id : null,
    qualification_status_id: state.params.qualification_status ? state.params.qualification_status.id : null,
    city_id:
      state.params.city && state.params.city.id
        ? state.params.city.id
        : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
        ? rootGetters["UtilFunctions/cityIds"]
        : null,
    area_id: user_areas_ids,
    equipment: user_equipments_ids,
    season_id: JSON.parse(localStorage.season).id,
    neighborhood_id: state.params.neighborhood ? state.params.neighborhood.id : null,
    forwarded_id: state.params.forwarded ? state.params.forwarded.id : null,
    course_id: state.params.course ? state.params.course.id : null,
    schooling_id: state.params.schooling ? state.params.schooling.id : null,
    minimum_age: state.params.minimum_age,
    maximum_age: state.params.maximum_age,
    perPage: state.profile_searchsTotalPerPage,
    offset: state.profile_searchsOffset,
    has_resume: state.params.has_resume ? state.params.has_resume.id : null,
  };
}

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadInscriptions: ({ commit, rootGetters }, from) => {
    let { user_areas_ids, user_equipments_ids } = getUserAndEquipments();
    const params = getFilterParams(user_areas_ids, user_equipments_ids, rootGetters);
    getInscriptionsForProductiveInsertion(params)
      .then((response) => {
        commit("loadInscriptions", response.data.inscriptions);
        commit("setProfileSearchsTotal", response.data.inscriptionsTotal);
        if (from && parseInt(response.data.inscriptionsTotal, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadInscriptionsForGeneralMenus: ({ commit, rootState }, value) => {
    let { user_areas_ids } = getUserAndEquipments();
    const params = {
      participant_name: value,
      area_id: user_areas_ids,
      season_id: rootState.ProductiveInsertions?.season?.id,
      perPage: 10,
      is_filter: true,
    };
    getInscriptionForGeneralMenus(params)
      .then((response) => {
        commit("loadInscriptionsForGeneralMenus", response.data.inscriptions);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadProfileSearchsForExport: ({ commit }) => {
    commit("hasSpreadsheetProfileSearchsResponseMutation", false);
    let { user_areas_ids, user_equipments_ids } = getUserAndEquipments();
    const params = getFilterParams(user_areas_ids, user_equipments_ids);
    getInscriptionsForExportProductiveInsertion(params)
      .then(async (response) => {
        commit("setProfileSearchsSpreadsheetMutation", response.data.inscriptions);
        if (state.profile_searchs_spreadsheet && state.profile_searchs_spreadsheet.length > 0) {
          await commit("setProfileSearchsPageToExportMutation", response.data.inscriptions);
        } else {
          commit("hasSpreadsheetProfileSearchsResponseMutation", true);
          commit("toggleModalToggleExportProfileSearchsSpreadsheet");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadNeighborhoods: ({ commit, rootGetters }) => {
    const params = {
      city_id:
        state.params.city && state.params.city.id
          ? state.params.city.id
          : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
          ? rootGetters["UtilFunctions/cityIds"]
          : null,
    };
    getNeighborhoodsForMenus(params)
      .then((response) => {
        commit("loadNeighborhoods", response.data.neighborhoods);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadCourses: ({ commit }) => {
    getCourses()
      .then((response) => {
        commit("loadCourses", response.data.courses);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadQualificationStatuses: ({ commit }) => {
    getQualificationStatus()
      .then((response) => {
        commit("loadQualificationStatuses", response.data.qualification_status);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadEquipments: ({ commit }) => {
    const params = {
      area_id: state.user_areas_filtered ? state.user_areas_filtered.id : null,
    };
    if (state.user_areas_filtered) {
      getEquipmentByAreaId(params)
        .then((response) => {
          let equipments = [];
          if (state.user_equipments.length > 0) {
            response.data.equipment.forEach((item) => {
              state.user_equipments.forEach((element) => {
                if (item.id === element.id) {
                  equipments.push(item);
                }
              });
            });
          } else {
            equipments = response.data.equipment;
          }
          commit("setEquipmentsMutation", equipments);
        })
        .catch((e) => {
          Vue.$toast.error(e.response.data.error);
        });
    }
  },

  // ACTIONS - FIELDS TO API
  setInscription: ({ commit }, payload) => {
    commit("setInscriptionMutation", payload);
  },
  setUser: ({ commit }, payload) => {
    commit("setUserMutation", payload);
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },

  // --> ACTIONS - FILTERLIST
  setFilterInscription: ({ commit }, payload) => {
    commit("setFilterInscriptionMutation", payload);
  },
  setFilterInscriptionStatus: ({ commit }, payload) => {
    commit("setFilterInscriptionStatusMutation", payload);
  },
  setFilterQualificationStatus: ({ commit }, payload) => {
    commit("setFilterQualificationStatusMutation", payload);
  },
  setFilterArea: ({ commit }, payload) => {
    commit("setFilterAreaMutation", payload);
  },
  setFilterCity: ({ commit }, payload) => {
    commit("setFilterCityMutation", payload);
  },
  setFilterState: ({ commit }, payload) => {
    commit("setFilterStateMutation", payload);
  },
  setFilterEquipment: ({ commit }, payload) => {
    commit("setFilterEquipmentMutation", payload);
  },
  setFilterUser: ({ commit }, payload) => {
    commit("setFilterUserMutation", payload);
  },
  setFilterNeighborhood: ({ commit }, payload) => {
    commit("setFilterNeighborhoodMutation", payload);
  },
  setFilterSchooling: ({ commit }, payload) => {
    commit("setFilterSchoolingMutation", payload);
  },
  setFilterCourse: ({ commit }, payload) => {
    commit("setFilterCourseMutation", payload);
  },
  setFilterForwarded: ({ commit }, payload) => {
    commit("setFilterForwardedMutation", payload);
  },
  setFilterHasResume: ({ commit }, payload) => {
    commit("setFilterHasResumeMutation", payload);
  },
  setFilterMinimumAge: ({ commit }, payload) => {
    commit("setFilterMinimumAgeMutation", payload.value);
  },
  setFilterMaximumAge: ({ commit }, payload) => {
    commit("setFilterMaximumAgeMutation", payload.value);
  },
  setNeighborhoods: ({ commit }, payload) => {
    commit("loadNeighborhoods", payload);
  },
  setUserAreasFiltered: ({ commit }, payload) => {
    commit("setUserAreasFilteredMutation", payload);
    if (!payload) {
      commit("setEquipmentsMutation", []);
      commit("setFilterEquipmentMutation", null);
    }
  },

  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportProfileSearchsSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportProfileSearchsSpreadsheet");
  },
  closeExportProfileSearchsSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportProfileSearchsSpreadsheet");
  },

  // --> ACTIONS - PAGINATIONS <--
  setProfileSearchsTotalPerPage: ({ commit }, payload) => {
    commit("setProfileSearchsTotalPerPage", payload.totalPerPage);
  },
  setProfileSearchsOffset: ({ commit }, payload) => {
    commit("setProfileSearchsOffset", payload.offset);
  },

  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  setUserAreas: ({ commit }, payload) => {
    commit("setUserAreasMutation", payload);
  },
  setUserEquipments: ({ commit }, payload) => {
    commit("setUserEquipmentsMutation", payload);
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
