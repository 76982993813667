import {
  getOccupations,
  getOccupationById,
  createOccupation,
  updateOccupation,
  destroyOccupation,
} from "../../service/OccupationsApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

const state = {
  occupation: {
    id: null,
    name: null,
  },

  occupations: [],
  errorMessages: [],
  occupationId: null,

  modalToggleAddOccupation: false,
  modalToggleDeleteOccupation: false,
  modalToggleViewOccupation: false,

  occupationsTotal: 0,
  occupationsTotalPerPage: 10,
  occupationsOffset: 0,

  params: {
    name: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadOccupations: (state, occupations) => {
    state.occupations = occupations;
  },

  // MUTATIONS - FIELDS TO API
  setIdMutation: (state, id) => {
    state.occupation.id = id;
  },
  setNameMutation: (state, name) => {
    state.occupation.name = name;
  },

  // --> MUTATIONS - DELETION <--
  occupationForDeletion: (state, payload) => {
    if (payload) {
      state.occupationId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddOccupation: (state) => {
    state.modalToggleAddOccupation = !state.modalToggleAddOccupation;
  },
  toggleModalDeleteOccupation: (state) => {
    state.modalToggleDeleteOccupation = !state.modalToggleDeleteOccupation;
  },
  toggleModalViewOccupation: (state) => {
    state.modalToggleViewOccupation = !state.modalToggleViewOccupation;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setOccupationsTotalPerPage: (state, occupationsTotalPerPage) => {
    state.occupationsTotalPerPage = occupationsTotalPerPage;
  },
  setOccupationsOffset: (state, occupationsOffset) => {
    state.occupationsOffset = occupationsOffset;
  },
  setOccupationsTotal: (state, occupationsTotal) => {
    state.occupationsTotal = occupationsTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.occupation) {
      state.occupation[prop] = null;
    }
  },
  clearQuery: (state) => {
    state.params.name = null;
    state.occupations = [];
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadOccupations: ({ commit }, from) => {
    const params = {
      name: state.params.name,
      per_page: state.occupationsTotalPerPage,
      offset: state.occupationsOffset,
    };
    getOccupations(params)
      .then((response) => {
        commit("loadOccupations", response.data.occupations);
        commit("setOccupationsTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getOccupationById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.occupations.id);
        commit("setNameMutation", response.data.occupations.name);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddOccupation: async ({ commit }) => {
    commit("toggleModalAddOccupation");
  },
  closeModalAddOccupation: ({ commit }) => {
    commit("toggleModalAddOccupation");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openEditModalAddOccupation: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddOccupation");
  },
  closeEditModalAddOccupation: ({ commit }) => {
    commit("toggleModalAddOccupation");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewOccupation");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewOccupation");
    commit("clearFields");
  },
  toggleModalDeleteOccupation: ({ commit }, payload) => {
    commit("toggleModalDeleteOccupation");
    if (state.modalToggleDeleteOccupation && payload) {
      commit("occupationForDeletion", payload);
    } else {
      commit("occupationForDeletion", null);
    }
  },

  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },

  // --> ACTIONS - PAGINATIONS <--
  setOccupationsTotalPerPage: ({ commit }, payload) => {
    commit("setOccupationsTotalPerPage", payload.totalPerPage);
  },
  setOccupationsOffset: ({ commit }, payload) => {
    commit("setOccupationsOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },

  //ACTIONS - CRUD
  save: ({ commit, dispatch }) => {
    createOccupation(state.occupation)
      .then(() => {
        Vue.$toast.success("Ocupação cadastrada com sucesso!");
        dispatch("closeModalAddOccupation");
        dispatch("loadOccupations");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar a ocupação! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  update: ({ commit, dispatch, state }) => {
    updateOccupation(state.occupation.id, state.occupation)
      .then(() => {
        dispatch("loadOccupations");
        dispatch("closeEditModalAddOccupation");
        Vue.$toast.success("Ocupação atualizada com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar a ocupação! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyOccupation(state.occupationId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Ocupação deletada com sucesso!");
        commit("toggleModalDeleteOccupation");
        dispatch("loadOccupations", from);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
