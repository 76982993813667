<template>
  <div>
    <div class="grid grid-cols-custom gap-10 items-center mt-4">
      <div class="ml-20">
        <BaseText
          text="Nome"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div class="ml-3">
        <BaseText
          text="Período"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div class="ml-1">
        <BaseText
          text="Instrutor"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div class="ml-0">
        <BaseText
          text="Equipamento"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div class="flex">&nbsp;</div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li
        :key="meeting.id"
        :data-index="index"
        v-for="(meeting, index) in data"
        @click.prevent="goToMeeting(meeting)"
      >
        <div>
          <AppCardMeeting :meeting="meeting" />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
    <DeleteMeetingConfirmation :open="openModalDelete" />
  </div>
</template>

<script>
import AppCardMeeting from "../../components/AppCardMeeting.vue";
import BaseText from "../../components/BaseText.vue";
import DeleteMeetingConfirmation from "../alerts/DeleteMeetingConfirmation.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";
import router from "../../router/index";
import { mapState } from "vuex";

export default {
  components: {
    AppCardMeeting,
    BaseText,
    BaseStaggeredFade,
    DeleteMeetingConfirmation,
  },

  props: {
    data: {
      type: Array,
    },
  },
  computed: {
    ...mapState({
      area: (state) => state.Area.insideArea,
      openModalDelete: (state) => state.Meetings.modalDeleteMeetingConfirmationOpen,
      season: (state) => state.Seasons.season,
    }),
  },
  methods: {
    goToMeeting(params) {
      router.push({ name: "encontro", params: { id: params.id } });
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 25% 21.5% 21.5% 25%;
}
</style>
