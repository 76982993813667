import Vue from "vue";
import { updateInscription } from "../../service/InscriptionApi";
import {
  createStudentDocument,
  getStudentDocumentsByIdStudent,
  destroyStudentDocument,
  showStudentDocument,
} from "../../service/StudentsDocumentsApi";
import { getInscriptionByFilters } from "../../service/InscriptionApi";
import { createStudentEducationalStatusHistory } from "../../service/StudentEducationalStatusHistoryApi";
import { undoState } from "../UndoState";

const state = {
  modalInscriptionStatusUnderAnalysisConfirmation: false,
  modalInscriptionStatusAcceptedConfirmation: false,
  modalInscriptionStatusNotAcceptedConfirmation: false,
  modalInscriptionStatusOpenConfirmation: false,
  modalInscriptionStatusGraduateConfirmation: false,
  modalInscriptionStatusArchivedConfirmation: false,
  modalInscriptionAlertStatus: false,
  showFormEditInscription: false,
  modalOpen: false,
  idFileStudentRG: null,
  idFileStudentCPF: null,
  //idFileStudentCN: null,
  idFileStudentCE: null,
  idFileStudentDeclaracao: null,
  idFileStudentBankAccount: null,
  idFileStudentResume: null,
  idFileStudentSchoolDeclaration: null,
  idFileStudentJustificationDocument: null,
  fileRG: null,
  fileCPF: null,
  fileCN: null,
  fileDeclaracao: null,
  fileBankAccount: null,
  fileResume: null,
  fileSchoolDeclaration: null,
  fileJustificationDocument: null,
  studentId: null,
  presence_id: null,
  student: null,
  modalDeleteDocumentConfirmationOpen: false,
};

const mutations = {
  toggleShowFormEditInscription: (state) => {
    state.showFormEditInscription = !state.showFormEditInscription;
  },
  toggleModalInscriptionStatusUnderAnalysisConfirmation: (state) => {
    state.modalInscriptionStatusUnderAnalysisConfirmation =
      !state.modalInscriptionStatusUnderAnalysisConfirmation;
  },
  toggleModalInscriptionStatusAcceptedConfirmation: (state) => {
    state.modalInscriptionStatusAcceptedConfirmation = !state.modalInscriptionStatusAcceptedConfirmation;
  },
  toggleModalInscriptionStatusNotAcceptedConfirmation: (state) => {
    state.modalInscriptionStatusNotAcceptedConfirmation =
      !state.modalInscriptionStatusNotAcceptedConfirmation;
  },
  toggleModalInscriptionStatusOpenConfirmation: (state) => {
    state.modalInscriptionStatusOpenConfirmation = !state.modalInscriptionStatusOpenConfirmation;
  },
  toggleModalInscriptionStatusGraduateConfirmation: (state) => {
    state.modalInscriptionStatusGraduateConfirmation = !state.modalInscriptionStatusGraduateConfirmation;
  },
  toggleModalInscriptionStatusArchivedConfirmation: (state) => {
    state.modalInscriptionStatusArchivedConfirmation = !state.modalInscriptionStatusArchivedConfirmation;
  },
  toggleModalInscriptionAlertStatus: (state) => {
    state.modalInscriptionAlertStatus = !state.modalInscriptionAlertStatus;
  },
  toggleModalMutation: (state) => {
    state.modalOpen = !state.modalOpen;
  },
  setStudentId: (state, studentId) => {
    state.studentId = studentId;
  },
  setPresenceId: (state, presence_id) => {
    state.presence_id = presence_id;
  },
  setStudent: (state, student) => {
    state.student = student;
  },
  setIdFileStudentRG: (state, idDocument) => {
    state.idFileStudentRG = idDocument;
  },
  setIdFileStudentCPF: (state, idDocument) => {
    state.idFileStudentCPF = idDocument;
  },
  setIdFileStudentCE: (state, idDocument) => {
    state.idFileStudentCE = idDocument;
  },
  setIdFileStudentDECLARACAO: (state, idDocument) => {
    state.idFileStudentDeclaracao = idDocument;
  },
  setIdFileStudentBankAccount: (state, idDocument) => {
    state.idFileStudentBankAccount = idDocument;
  },
  setIdFileStudentResume: (state, idDocument) => {
    state.idFileStudentResume = idDocument;
  },
  setIdFileStudentSchoolDeclaration: (state, idDocument) => {
    state.idFileStudentSchoolDeclaration = idDocument;
  },
  setIdFileStudentJustificationDocument: (state, idDocument) => {
    state.idFileStudentJustificationDocument = idDocument;
  },
  setStatus: (state, status) => {
    state.inscription.status = status;
  },
  setStatusInInscription: (state, status) => {
    state.inscription.status = status;
  },
  setDocumentInscriptionRG: (state, document) => {
    state.fileRG = document;
  },
  setDocumentInscriptionCPF: (state, document) => {
    state.fileCPF = document;
  },
  /*setDocumentInscriptionCN: (state, document) => {
    state.fileCN = document;
  },*/
  setDocumentInscriptionCE: (state, document) => {
    state.fileCE = document;
  },
  setDocumentInscriptionDECLARACAO: (state, document) => {
    state.fileDeclaracao = document;
  },
  setDocumentInscriptionBankAccount: (state, document) => {
    state.fileBankAccount = document;
  },
  setDocumentInscriptionResume: (state, document) => {
    state.fileResume = document;
  },
  setDocumentInscriptionSchoolDeclaration: (state, document) => {
    state.fileSchoolDeclaration = document;
  },
  setDocumentInscriptionJustificationDocument: (state, document) => {
    state.fileJustificationDocument = document;
  },
  undoState: () => {
    undoState.undoState();
  },
  setJustification: (state, justification) => {
    state.inscription.justification = justification;
  },
  closeFormEditInscriptionMutation: (state) => {
    state.showFormEditInscription = false;
  },
  toggleModalDeleteDocumentConfirmation: (state) => {
    state.modalDeleteDocumentConfirmationOpen = !state.modalDeleteDocumentConfirmationOpen;
  },
  documentForDeletion: (state, document_id) => {
    state.documentIdForDeletion = document_id;
  },
  clearIdFileStudentJustificationDocument: (state) => {
    state.idFileStudentJustificationDocument = null;
  },
};

const actions = {
  editFormStudent: ({ commit, dispatch }, payload) => {
    commit("toggleShowFormEditInscription");
    dispatch("Student/load", payload, { root: true });
  },
  toggleModalInscriptionStatus: ({ commit }, payload) => {
    commit(payload.mutation);
  },
  setStatusAndCloseModal: ({ commit, dispatch }, payload) => {
    dispatch("Inscription/setStatus", payload.status, { root: true });
    commit(payload.mutation);
  },
  setStudent: ({ commit }, payload) => {
    commit("setStudent", payload);
  },
  setStudentId: ({ commit }, payload) => {
    commit("setStudentId", payload);
  },
  setPresenceId: ({ commit }, payload) => {
    commit("setPresenceId", payload);
  },
  setIdFileStudentJustificationDocument: ({ commit }, payload) => {
    commit("setIdFileStudentJustificationDocument", payload);
  },
  downloadResume: ({ commit, dispatch }, payload) => {
    commit("setIdFileStudentResume", payload);
    if (state.idFileStudentResume) {
      dispatch("showStudentDocumentResume");
    }
  },
  clearIdFileStudentJustificationDocument: ({ commit }) => {
    commit("clearIdFileStudentJustificationDocument");
  },
  setStatusAcceptedAndCloseModal: ({ commit, dispatch, rootState }, payload) => {
    if (rootState.Inscription.date_face_to_face_service) {
      dispatch("Inscription/setStatus", payload.status, { root: true });
      commit(payload.mutation);
    } else {
      commit("toggleModalInscriptionStatusAcceptedConfirmation");
      commit("toggleModalInscriptionAlertStatus");
    }
  },
  changeInscriptionStatus: ({ commit, dispatch, state }) => {
    updateInscription(state.inscription.id, { status: state.inscription.status })
      .then((response) => {
        commit("setStatusInInscription", response.data.inscription.status);

        switch (true) {
          case state.modalInscriptionStatusUnderAnalysisConfirmation:
            commit("toggleModalInscriptionStatusUnderAnalysisConfirmation");
            break;
          case state.modalInscriptionStatusAcceptedConfirmation:
            commit("toggleModalInscriptionStatusAcceptedConfirmation");
            break;
          case state.modalInscriptionStatusNotAcceptedConfirmation:
            commit("toggleModalInscriptionStatusNotAcceptedConfirmation");
            break;
          case state.modalInscriptionStatusOpenConfirmation:
            commit("toggleModalInscriptionStatusOpenConfirmation");
            break;
          case state.modalInscriptionStatusGraduateConfirmation:
            commit("toggleModalInscriptionStatusGraduateConfirmation");
            break;
          case state.modalInscriptionStatusArchivedConfirmation:
            commit("toggleModalInscriptionStatusArchivedConfirmation");
            break;
          default:
            break;
        }

        dispatch("Season/loadDataSeason", {}, { root: true });
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  saveInscriptionFrm: ({ dispatch, rootState, rootGetters }) => {
    let inscription = {
      season_id: rootState.Student.season?.id,
      equipment_id: rootState.Student.equipment?.id,
      ...rootState.Inscription,
      student_attributes: rootGetters["Student/getStudentForAPI"],
    };
    delete inscription.student;

    if (state.showFormEditInscription) {
      dispatch("toogleFrmEditStudent");
    }
  },
  saveInscription: ({ dispatch, rootState, rootGetters }) => {
    let inscription = {
      season_id: rootState.Student.season?.id,
      equipment_id: rootState.Student.equipment?.id,
      ...rootState.Inscription,
      student_attributes: rootGetters["Student/getStudentForAPI"],
      active: rootState.Inscription.status === "accepted" ? true : null,
    };

    delete inscription.student;
    updateInscription(inscription.id, inscription)
      .then(() => {
        dispatch("closeModal");
        dispatch("Season/loadDataSeason", {}, { root: true });
        if (
          inscription.student_attributes.educational_status !== undefined &&
          inscription.student_attributes.educational_status !== null &&
          state.student.educational_status
        ) {
          let previousEducationalStatusValue = parseInt(state.student.educational_status?.id);
          let currentEducationalStatusValue = parseInt(inscription.student_attributes.educational_status);
          if (previousEducationalStatusValue !== currentEducationalStatusValue) {
            let previousEducationalStatus = {
              student_id: inscription.student_attributes?.id,
              educational_status_history: previousEducationalStatusValue,
            };
            createStudentEducationalStatusHistory(previousEducationalStatus)
              .then(() => {})
              .catch((e) => {
                console.log("error", e.response); //TODO: tratar esse error
              });
          }
        }
        Vue.$toast.success(`Inscrição atualizada com sucesso.`);
      })
      .catch((e) => {
        let params = {
          cpf: null,
          email: null,
        };
        if (e.response.data.errors) {
          let objPropertys = Object.values(e.response.data.errors);
          let errors = [];
          Object.values(objPropertys).forEach(([value]) => {
            errors.push(value);
          });
          errors.forEach((item) => {
            if (
              item.includes("CPF informado já está em uso") ||
              item.includes("E-mail informado já está em uso")
            ) {
              if (item === "CPF informado já está em uso") {
                params.cpf = inscription.student_attributes.cpf
                  .replace(".", "")
                  .replace("-", "")
                  .replace(".", "");
                getInscriptionByFilters(params)
                  .then((response) => {
                    let inscriptionConflict = response.data.inscriptions[0];
                    Vue.$toast.error(
                      `O CPF de Nº ${inscription.student_attributes.cpf} já foi cadastrado no seguinte registro:
                    \n Nome Aluno(a): ${inscriptionConflict.student.name}\n Edição: ${inscriptionConflict.season.name}`
                    );
                  })
                  .catch((e) => {
                    console.log("error", e.response); //TODO: tratar esse error
                  });
              }
              if (item === "E-mail informado já está em uso") {
                params.email = inscription.student_attributes.email;
                getInscriptionByFilters(params)
                  .then((response) => {
                    let inscriptionConflict = response.data.inscriptions[0];
                    Vue.$toast.error(
                      `O E-mail ${inscription.student_attributes.email} já foi cadastrado no seguinte registro:
                        \n Nome Aluno(a): ${inscriptionConflict.student.name}\n Edição: ${inscriptionConflict.season.name}`
                    );
                  })
                  .catch((e) => {
                    console.log("error", e.response); //TODO: tratar esse error
                  });
              }
            } else {
              Vue.$toast.error(item);
            }
          });
        }
      });
  },
  updateInscription: ({ dispatch, rootState, rootGetters }) => {
    let inscription = {
      season_id: rootState.Student.season?.id,
      equipment_id: rootState.Student.equipment?.id,
      ...rootState.Inscription,
      student_attributes: rootGetters["Student/getStudentForAPI"],
    };
    updateInscription(inscription.id, inscription)
      .then(() => {
        dispatch("Season/loadDataSeason", {}, { root: true });
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  setDocumentInscriptionRG: ({ commit, rootState }, payload) => {
    commit("setDocumentInscriptionRG", payload);
    if (state.fileRG != null) {
      const params = {
        id_student: rootState.Student.id,
        document_type: "RG",
        file_mime_type: state.fileRG.type,
        file_name: state.fileRG.name,
        file_data: state.fileRG,
      };
      let formData = new FormData();
      Object.entries(params).forEach(([key, value]) => formData.append(key, value));
      createStudentDocument(formData)
        .then((response) => {
          let id = response.data.id;
          Vue.$toast.success(`RG enviado com sucesso.`);
          commit("setIdFileStudentRG", id);
          commit("setDocumentInscriptionRG", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel enviar RG: " + e.response.data.errors);
          console.log("error", e.response.data.errors);
        });
    }
  },
  setDocumentInscriptionCPF: ({ commit, rootState }, payload) => {
    commit("setDocumentInscriptionCPF", payload);
    if (state.fileCPF != null) {
      const params = {
        id_student: rootState.Student.id,
        document_type: "CPF",
        file_mime_type: state.fileCPF.type,
        file_name: state.fileCPF.name,
        file_data: state.fileCPF,
      };
      let formData = new FormData();
      Object.entries(params).forEach(([key, value]) => formData.append(key, value));
      createStudentDocument(formData)
        .then((response) => {
          let id = response.data.id;
          Vue.$toast.success(`CPF enviado com sucesso.`);
          commit("setIdFileStudentCPF", id);
          commit("setDocumentInscriptionCPF", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel enviar CPF: " + e.response.data.errors);
          console.log("error", e.response.data.errors);
        });
    }
  },
  /*setDocumentInscriptionCN: ({ commit, rootState }, payload) => {
    commit("setDocumentInscriptionCN", payload);
    if (state.fileCN != null) {
      const params = {
        id_student: rootState.Student.id,
        document_type: "CN",
        file_mime_type: state.fileCN.type,
        file_name: state.fileCN.name,
        file_data: state.fileCN,
      };
      let formData = new FormData();
      Object.entries(params).forEach(([key, value]) => formData.append(key, value));
      createStudentDocument(formData)
        .then((response) => {
          let id = response.data.id;
          Vue.$toast.success(`CE enviado com sucesso.`);
          commit("setIdFileStudentCE", id);
          commit("setDocumentInscriptionCE", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel enviar CE: " + e.response.data.errors);
          console.log("error", e.response.data.errors);
        });
    }
  },*/
  setDocumentInscriptionCE: ({ commit, rootState }, payload) => {
    commit("setDocumentInscriptionCE", payload);
    if (state.fileCE != null) {
      const params = {
        id_student: rootState.Student.id,
        document_type: "CE",
        file_mime_type: state.fileCE.type,
        file_name: state.fileCE.name,
        file_data: state.fileCE,
      };
      let formData = new FormData();
      Object.entries(params).forEach(([key, value]) => formData.append(key, value));
      createStudentDocument(formData)
        .then((response) => {
          let id = response.data.id;
          Vue.$toast.success(`CE enviado com sucesso.`);
          commit("setIdFileStudentCE", id);
          commit("setDocumentInscriptionCE", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel enviar CE: " + e.response.data.errors);
          console.log("error", e.response.data.errors);
        });
    }
  },
  setDocumentInscriptionDECLARACAO: ({ commit, rootState }, payload) => {
    commit("setDocumentInscriptionDECLARACAO", payload);
    if (state.fileDeclaracao != null) {
      const params = {
        id_student: rootState.Student.id,
        document_type: "DECLARACAO",
        file_mime_type: state.fileDeclaracao.type,
        file_name: state.fileDeclaracao.name,
        file_data: state.fileDeclaracao,
      };
      let formData = new FormData();
      Object.entries(params).forEach(([key, value]) => formData.append(key, value));
      createStudentDocument(formData)
        .then((response) => {
          let id = response.data.id;
          Vue.$toast.success(`DECLARACAO enviada com sucesso.`);
          commit("setIdFileStudentDECLARACAO", id);
          commit("setDocumentInscriptionDECLARACAO", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel enviar DECLARACAO: " + e.response.data.errors);
          console.log("error", e.response.data.errors);
        });
    }
  },
  setDocumentInscriptionBankAccount: ({ commit, rootState }, payload) => {
    commit("setDocumentInscriptionBankAccount", payload);
    if (state.fileBankAccount != null) {
      const params = {
        id_student: rootState.Student.id,
        document_type: "BankAccount",
        file_mime_type: state.fileBankAccount.type,
        file_name: state.fileBankAccount.name,
        file_data: state.fileBankAccount,
      };
      let formData = new FormData();
      Object.entries(params).forEach(([key, value]) => formData.append(key, value));
      createStudentDocument(formData)
        .then((response) => {
          let id = response.data.id;
          Vue.$toast.success(`Comprovante de conta bancária enviado com sucesso.`);
          commit("setIdFileStudentBankAccount", id);
          commit("setDocumentInscriptionBankAccount", null);
        })
        .catch((e) => {
          Vue.$toast.error(
            "Não foi possivel enviar Comprovante de Conta Bancária: " + e.response.data.errors
          );
          console.log("error", e.response.data.errors);
        });
    }
  },
  setDocumentInscriptionResume: ({ commit, rootState }, payload) => {
    commit("setDocumentInscriptionResume", payload);
    if (state.fileResume != null) {
      const params = {
        id_student: rootState.Student.id,
        document_type: "Resume",
        file_mime_type: state.fileResume.type,
        file_name: state.fileResume.name,
        file_data: state.fileResume,
      };
      let formData = new FormData();
      Object.entries(params).forEach(([key, value]) => formData.append(key, value));
      createStudentDocument(formData)
        .then((response) => {
          let id = response.data.id;
          Vue.$toast.success(`Currículo enviado com sucesso.`);
          commit("setIdFileStudentResume", id);
          commit("setDocumentInscriptionResume", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel enviar Currículo: " + e.response.data.errors);
          console.log("error", e.response.data.errors);
        });
    }
  },
  setDocumentInscriptionSchoolDeclaration: ({ commit, rootState }, payload) => {
    commit("setDocumentInscriptionSchoolDeclaration", payload);
    if (state.fileSchoolDeclaration != null) {
      const params = {
        id_student: rootState.Student.id,
        document_type: "SchoolDeclaration",
        file_mime_type: state.fileSchoolDeclaration.type,
        file_name: state.fileSchoolDeclaration.name,
        file_data: state.fileSchoolDeclaration,
      };
      let formData = new FormData();
      Object.entries(params).forEach(([key, value]) => formData.append(key, value));
      createStudentDocument(formData)
        .then((response) => {
          let id = response.data.id;
          Vue.$toast.success(`Declaração Escolar enviada com sucesso.`);
          commit("setIdFileStudentSchoolDeclaration", id);
          commit("setDocumentInscriptionSchoolDeclaration", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel enviar a Declaração Escolar: " + e.response.data.errors);
          console.log("error", e.response.data.errors);
        });
    }
  },
  setDocumentInscriptionJustificationDocument: ({ commit }, payload) => {
    commit("setDocumentInscriptionJustificationDocument", payload);
    if (state.fileJustificationDocument != null) {
      const params = {
        id_student: state.studentId,
        presence_id: state.presence_id,
        document_type: "JustificationDocument",
        file_mime_type: state.fileJustificationDocument.type,
        file_name: state.fileJustificationDocument.name,
        file_data: state.fileJustificationDocument,
      };
      let formData = new FormData();
      Object.entries(params).forEach(([key, value]) => formData.append(key, value));
      createStudentDocument(formData)
        .then((response) => {
          let id = response.data.id;
          Vue.$toast.success(`Justificativa anexada com sucesso.`);
          commit("setIdFileStudentJustificationDocument", id);
          commit("setDocumentInscriptionJustificationDocument", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel anexar a Justificativa: " + e.response.data.errors);
          console.log("error", e.response.data.errors);
        });
    }
  },
  undoState: ({ commit }) => {
    commit("undoState");
  },
  toggleModalInscriptionAlertStatus: ({ commit }) => {
    commit("toggleModalInscriptionAlertStatus");
  },
  setJustification: ({ commit }, payload) => {
    commit("setJustification", payload);
  },
  openModal: ({ commit }, payload) => {
    commit("setStudentId", payload.id);
    commit("toggleModalMutation");
  },
  closeModal: ({ commit }) => {
    commit("toggleModalMutation");
    commit("closeFormEditInscriptionMutation");
  },
  toogleFrmEditStudent: ({ commit }) => {
    commit("toggleShowFormEditInscription");
  },
  openCloseDeleteDocumentConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteDocumentConfirmation");
    if (state.modalDeleteDocumentConfirmationOpen) {
      commit("documentForDeletion", payload.id);
    } else {
      commit("documentForDeletion", null);
    }
  },
  updateAllIdDocumentStudent: ({ commit }, payload) => {
    getStudentDocumentsByIdStudent(payload)
      .then((response) => {
        let rg = response.data.filter((item) => {
          return item.document_type.indexOf("RG") > -1;
        });
        let cpf = response.data.filter((item) => {
          return item.document_type.indexOf("CPF") > -1;
        });
        let ce = response.data.filter((item) => {
          return item.document_type.indexOf("CE") > -1;
        });
        let declaracao = response.data.filter((item) => {
          return item.document_type.indexOf("DECLARACAO") > -1;
        });
        let bankAccount = response.data.filter((item) => {
          return item.document_type.indexOf("BankAccount") > -1;
        });
        let resume = response.data.filter((item) => {
          return item.document_type.indexOf("Resume") > -1;
        });
        let schoolDeclaration = response.data.filter((item) => {
          return item.document_type.indexOf("SchoolDeclaration") > -1;
        });
        let justificationDocument = response.data.filter((item) => {
          return item.document_type.indexOf("JustificationDocument") > -1;
        });
        let idRG = parseInt(rg.length) ? rg[0].id : null;
        let idCPF = parseInt(cpf.length) ? cpf[0].id : null;
        //let idCN = parseInt(cn.length) ? cn[0].id : null;
        let idCE = parseInt(ce.length) ? ce[0].id : null;
        let idDECLARACAO = parseInt(declaracao.length) ? declaracao[0].id : null;
        let idBankAccount = parseInt(bankAccount.length) ? bankAccount[0].id : null;
        let idResume = parseInt(resume.length) ? resume[0].id : null;
        let idSchoolDeclaration = parseInt(schoolDeclaration.length) ? schoolDeclaration[0].id : null;
        let idJustificationDocument = parseInt(justificationDocument.length)
          ? justificationDocument[0].id
          : null;
        commit("setIdFileStudentRG", idRG);
        commit("setIdFileStudentCPF", idCPF);
        //commit("setIdFileStudentCN", idCN);
        commit("setIdFileStudentCE", idCE);
        commit("setIdFileStudentDECLARACAO", idDECLARACAO);
        commit("setIdFileStudentBankAccount", idBankAccount);
        commit("setIdFileStudentResume", idResume);
        commit("setIdFileStudentSchoolDeclaration", idSchoolDeclaration);
        commit("setIdFileStudentJustificationDocument", idJustificationDocument);
        commit("setDocumentInscriptionRG", null);
        commit("setDocumentInscriptionCPF", null);
        //commit("setDocumentInscriptionCN", null);
        commit("setDocumentInscriptionCE", null);
        commit("setDocumentInscriptionDECLARACAO", null);
        commit("setDocumentInscriptionBankAccount", null);
        commit("setDocumentInscriptionResume", null);
        commit("setDocumentInscriptionSchoolDeclaration", null);
        commit("setDocumentInscriptionJustificationDocument", null);
      })
      .catch((e) => {
        console.log("error", e);
      });
  },
  deleteStudentDocumentRG: ({ commit, state }) => {
    const idDocument = state.idFileStudentRG;
    if (parseInt(idDocument)) {
      destroyStudentDocument(idDocument)
        .then(() => {
          Vue.$toast.success(`RG excluído com sucesso.`);
          commit("setIdFileStudentRG", null);
          commit("setDocumentInscriptionRG", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel excluir RG!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("RG não cadastrado!");
    }
  },
  deleteStudentDocumentCPF: ({ commit, state }) => {
    const idDocument = state.idFileStudentCPF;
    if (parseInt(idDocument)) {
      destroyStudentDocument(idDocument)
        .then(() => {
          Vue.$toast.success(`CPF excluído com sucesso.`);
          commit("setIdFileStudentCPF", null);
          commit("setDocumentInscriptionCPF", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel excluir CPF!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("CPF não cadastrado!");
    }
  },
  /*deleteStudentDocumentCN: ({ commit, state }) => {
    const idDocument = state.idFileStudentCN;
    if (parseInt(idDocument)) {
      destroyStudentDocument(idDocument)
        .then(() => {
          Vue.$toast.success(`CN excluída com sucesso.`);
          commit("setIdFileStudentCN", null);
          commit("setDocumentInscriptionCN", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel excluir CN!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("CN não cadastrada!");
    }
  },*/
  deleteStudentDocumentCE: ({ commit, state }) => {
    const idDocument = state.idFileStudentCE;
    if (parseInt(idDocument)) {
      destroyStudentDocument(idDocument)
        .then(() => {
          Vue.$toast.success(`CE excluída com sucesso.`);
          commit("setIdFileStudentCE", null);
          commit("setDocumentInscriptionCE", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel excluir CE!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("CE não cadastrada!");
    }
  },
  deleteStudentDocumentDECLARACAO: ({ commit, state }) => {
    const idDocument = state.idFileStudentDeclaracao;
    if (parseInt(idDocument)) {
      destroyStudentDocument(idDocument)
        .then(() => {
          Vue.$toast.success(`DECLARAÇÃO excluída com sucesso.`);
          commit("setIdFileStudentDECLARACAO", null);
          commit("setDocumentInscriptionDECLARACAO", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel excluir DECLARAÇÃO!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("DECLARAÇÃO não cadastrada!");
    }
  },
  deleteStudentDocumentBankAccount: ({ commit, state }) => {
    const idDocument = state.idFileStudentBankAccount;
    if (parseInt(idDocument)) {
      destroyStudentDocument(idDocument)
        .then(() => {
          Vue.$toast.success(`Comprovante de conta bancária excluído com sucesso.`);
          commit("setIdFileStudentBankAccount", null);
          commit("setDocumentInscriptionBankAccount", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel excluir Comprovante de Conta Bancária!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("Comprovante de Conta Bancária não cadastrado!");
    }
  },
  deleteStudentDocumentResume: ({ commit, state }) => {
    const idDocument = state.idFileStudentResume;
    if (parseInt(idDocument)) {
      destroyStudentDocument(idDocument)
        .then(() => {
          Vue.$toast.success(`Currículo excluído com sucesso.`);
          commit("setIdFileStudentResume", null);
          commit("setDocumentInscriptionResume", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel excluir Currículo!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("Currículo não cadastrado!");
    }
  },
  deleteStudentDocumentSchoolDeclaration: ({ commit, state }) => {
    const idDocument = state.idFileStudentSchoolDeclaration;
    if (parseInt(idDocument)) {
      destroyStudentDocument(idDocument)
        .then(() => {
          Vue.$toast.success(`Declaração Escolar excluída com sucesso.`);
          commit("setIdFileStudentSchoolDeclaration", null);
          commit("setDocumentInscriptionSchoolDeclaration", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel excluir a Declaração Escolar!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("Declaração Escolar não cadastrada!");
    }
  },
  deleteStudentDocumentJustificationDocument: ({ commit, state }) => {
    const idDocument = state.idFileStudentJustificationDocument;
    if (parseInt(idDocument)) {
      destroyStudentDocument(idDocument)
        .then(() => {
          Vue.$toast.success(`Anexo da Justificativa excluído com sucesso.`);
          commit("setIdFileStudentJustificationDocument", null);
          commit("setDocumentInscriptionJustificationDocument", null);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel excluir o Anexo da Justificativa!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("Anexo da Justificativa não cadastrado!");
    }
  },
  showStudentDocumentRG: ({ state }) => {
    const idDocument = state.idFileStudentRG;
    if (parseInt(idDocument)) {
      showStudentDocument(idDocument)
        .then((response) => {
          let file_url = response.data.document.file_url;
          window.open(file_url);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel baixar RG!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("RG não cadastrado!");
    }
  },
  showStudentDocumentCPF: ({ state }) => {
    const idDocument = state.idFileStudentCPF;
    if (parseInt(idDocument)) {
      showStudentDocument(idDocument)
        .then((response) => {
          let file_url = response.data.document.file_url;
          window.open(file_url);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel baixar CPF!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("CPF não cadastrado!");
    }
  },
  showStudentDocumentCN: ({ state }) => {
    const idDocument = state.idFileStudentCN;
    if (parseInt(idDocument)) {
      showStudentDocument(idDocument)
        .then((response) => {
          let file_url = response.data.document.file_url;
          window.open(file_url);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel baixar CN:");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("CN não cadastrada!");
    }
  },
  showStudentDocumentDECLARACAO: ({ state }) => {
    const idDocument = state.idFileStudentDeclaracao;
    if (parseInt(idDocument)) {
      showStudentDocument(idDocument)
        .then((response) => {
          let file_url = response.data.document.file_url;
          window.open(file_url);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel baixar DECLARAÇÃO!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("DECLARAÇÃO não cadastrada!");
    }
  },
  showStudentDocumentBankAccount: ({ state }) => {
    const idDocument = state.idFileStudentBankAccount;
    if (parseInt(idDocument)) {
      showStudentDocument(idDocument)
        .then((response) => {
          let file_url = response.data.document.file_url;
          window.open(file_url);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel baixar Comprovante de Conta Bancária!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("Comprovante de Conta Bancária não cadastrado!");
    }
  },
  showStudentDocumentResume: ({ state }) => {
    const idDocument = state.idFileStudentResume;
    if (parseInt(idDocument)) {
      showStudentDocument(idDocument)
        .then((response) => {
          let file_url = response.data.document.file_url;
          window.open(file_url);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel baixar Currículo!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("Currículo não cadastrado!");
    }
  },
  showStudentDocumentSchoolDeclaration: ({ state }) => {
    const idDocument = state.idFileStudentSchoolDeclaration;
    if (parseInt(idDocument)) {
      showStudentDocument(idDocument)
        .then((response) => {
          let file_url = response.data.document.file_url;
          window.open(file_url);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel baixar a Declaração Escolar!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("Declaração Escolar não cadastrada!");
    }
  },
  // eslint-disable-next-line no-empty-pattern
  showStudentDocumentJustificationDocument: ({}, payload) => {
    if (parseInt(payload)) {
      showStudentDocument(payload)
        .then((response) => {
          let file_url = response.data.document.file_url;
          window.open(file_url);
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel baixar o Anexo da Justificativa!");
          console.log("error", e.response.data.errors);
        });
    } else {
      Vue.$toast.error("Anexo da Justificativa não cadastrado!");
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
