<template>
  <div class="signin-login relative">
    <div class="top-36 left-28 absolute">
      <router-link class="text-herbal underline hover:text-herbal-hover" to="/">
        <BaseText text="< Voltar" type-text="custom" weight="medium" />
      </router-link>
    </div>
    <div class="flex flex-col justify-center items-center">
      <div class="my-28">
        <img src="../assets/images/logo-name-green.png" alt="" />
      </div>
      <div class="mt-20 mb-10">
        <BaseText
          text="Área do Instrutor"
          type-text="custom"
          weight="semibold"
          class="text-color-admin underline"
          size="xl"
        />
      </div>
      <div>
        <BaseText
          text="Faça seu login"
          type-text="custom"
          weight="semibold"
          class="text-color-admin"
          size="xl"
        />
      </div>
      <div class="w-96 my-2">
        <AppInputRound
          id="username"
          iconName="person"
          labelText="E-mail"
          textColor="herbal"
          borderColor="herbal"
          dispatch="Session/setUsername"
        />
      </div>
      <div class="w-96 my-2">
        <AppInputRound
          id="password"
          iconName="visibility_off"
          labelText="Senha"
          textColor="herbal"
          borderColor="herbal"
          hiddenValue
          dispatch="Session/setPassword"
        />
      </div>
      <div class="flex justify-start">
        <div class="pl-5 w-96 mt-0 mb-3">
          <router-link class="text-herbal underline hover:text-herbal-hover" to="#">
            <BaseText
              text="Esqueceu a senha?"
              type-text="custom"
              weight="normal"
              class="text-color-admin"
              size="xs"
            />
          </router-link>
        </div>
      </div>
      <div class="my-2">
        <AppButtonColorful
          text="Acessar"
          typeButton="primary"
          dispatch="Session/validateUser"
          :payload="{ access: ['Instrutor'] }"
          class="w-96"
        />
      </div>
    </div>
    <div class="bot w-full absolute bottom-0"></div>
  </div>
</template>

<script>
import BaseText from "../components/BaseText.vue";
import AppInputRound from "../components/AppInputRound.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";

export default {
  components: {
    BaseText,
    AppInputRound,
    AppButtonColorful,
  },

  created() {
    const url = this.$route.query.redirect || "/instrutor";
    this.$store.dispatch("Session/setRedirectUrl", url);
  },
};
</script>

<style scoped>
.signin-login {
  background-image: url("../assets/images/background-sigin-teacher.png");
  background-color: #ffe09d;
  width: 100%;
  min-height: 100vh;
}

.bot {
  background-image: url("../assets/images/img-rodape.png");
  height: 55px;
}

.text-color-admin {
  color: #328239;
}
</style>
