<template>
  <section class="font-system">
    <table class="table-auto w-full text-ashes-dark border-collapse">
      <thead>
        <BaseTableHeaderCell :header-columns="header" />
      </thead>
      <tbody>
        <BaseTableDataCellStudent :data-columns="body" :header-columns="header" />
      </tbody>
    </table>
    <BasePagination :total="total" :total-per-page="totalPerPage" :dispatch="dispatch" />
  </section>
</template>

<script>
import BaseTableHeaderCell from "./BaseTableHeaderCell.vue";
import BaseTableDataCellStudent from "./BaseTableDataCellStudent.vue";
import BasePagination from "./BasePagination.vue";

export default {
  name: "BaseTableStudent",

  props: {
    header: {
      type: Array,
    },
    body: {
      type: Array,
    },
    total: {
      type: Number,
    },
    totalPerPage: {
      type: Number,
      default: 4,
    },
    dispatch: {
      type: String,
    },
  },

  components: {
    BaseTableHeaderCell,
    BaseTableDataCellStudent,
    BasePagination,
  },
};
</script>
